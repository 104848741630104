export class AssetLibrary {
    public assetLibraryId: string;
    public name: string;
    public isSubcription: boolean;
    public productCollection: AssetLibraryProduct[];
}

export class AssetLibraryProduct {
    public assetLibraryId: string;
    public productId: string
    public name: string;
    public imageURL: string;
}

export class AssetLibraryContent {
    public name: String;
    public contentId: String;
    public imageUrl: String;
    public providedBy: string;
    public children: Array<AssetLibraryContent>;
    public column: number;
    public isCompleted: boolean = false;
    public completedDate: string = "";
}
export class AssetLibraryContentSummary {
    public learningPaths: Array<AssetLibraryContent>;
    public workfows: Array<AssetLibraryContent>;
    public cheatSheets: Array<AssetLibraryContent>;
    public videos: Array<AssetLibraryContent>;
}
export class SubscriptionAssetSummary {
    public workflowCount: number;
    public cheatSheetCount: number;
    public videoCount: number;
    public learningPathCount: number;
}

export class AssetLibraryProductVersion {
    public assetLibraryId: string;
    public productId: string;
    public productName: String;
    public versionId: string;
    public versionName: string;
    public name: string;
    public imageURL: string;
    public seq: number;
}
