import { Injectable } from '@angular/core';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { LearningCenterCourseViewerComponent } from '../../learningcentercourseviewer/learningcentercourseviewer.component';

@Injectable()
export class CanDeactivateLearningCenterCourseViewer implements CanDeactivate<LearningCenterCourseViewerComponent> {

    // Prevent a user from using the navbar to navigate away
    // from in-progress SCORM content until the SCORM viewer
    // component has a chance to sync with the wrapper in blob
    // storage and persist the data model to the database.
    canDeactivate(component: LearningCenterCourseViewerComponent,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState?: RouterStateSnapshot
    ): boolean | Observable<boolean> | Promise<boolean> {
        return new Promise((resolve) => {

            // Allow navigation if the SCORM viewer component doesn't exist.
            if (component == null || component.scormContent == null) resolve(true);

            // Give the SCORM viewer component time to exit.
            component.scormContent.safelyForceComplete()
                .then(_ => {
                    // The SCORM viewer finished saving; we can allow navigation.
                    resolve(true);
                })
                .catch(_ => {
                    // The SCORM viewer had an issue, but that's a problem
                    // for another time; we can allow navigation.
                    resolve(true);
                });
        });
    }
}