import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AppComponent } from '../app.component';
import { PermissionUser, PinnacleLiteUser, User } from './../apiService/classFiles/class.users';
import { ProdGenApi } from './../apiService/prodgen.api';
import { AppControlService, MsgBxStringVals } from './../AppControlService';
import { BrowserAuthenticationService } from './../BrowserAuthenticationService';

declare var $: any;

@Component({
  selector: 'pinnaclelite-manangeusers',
    templateUrl: './pinnaclelite-manageusers.component.html',
    styleUrls: ['./pinnaclelite-manageusers.component.css'],
  providers: [ProdGenApi],
  encapsulation: ViewEncapsulation.None,
})
export class PinnacleLiteManageUsersComponent implements OnInit {
    isAuthenticated: boolean = false;
    users: Array<SelectablePermissionUser> = new Array<SelectablePermissionUser>();
    allUsers: Array<SelectablePermissionUser> = new Array<SelectablePermissionUser>();

    clickedUser: PinnacleLiteUser = new PinnacleLiteUser();
    emptyUser: PinnacleLiteUser = new PinnacleLiteUser();
    selectAllCheck: boolean = false;
    filterText: string = "";
    isLoaded: boolean = false;
    maxAllowed: number = 0;
    currentUser: User = new User();

    static staticThis: PinnacleLiteManageUsersComponent;
        
  
    constructor(private pinnacleService: ProdGenApi, private router: Router, private authenticationService: BrowserAuthenticationService, private changeRef: ChangeDetectorRef, private controlService: AppControlService) {
        PinnacleLiteManageUsersComponent.staticThis = this;
        if (authenticationService.AuthenticatePage() == true) {
            this.isAuthenticated = true;
        }
    } 

    ngOnInit() {
        window.scroll(0, 0);

        this.loadData();
    }

    loadData() {
        this.emptyUser = new PinnacleLiteUser();

        this.pinnacleService.getMaxTenantPinnacleLiteUsersAllowed().subscribe(res => {
            this.maxAllowed = +res;
            this.changeRef.detectChanges();
        });

        this.pinnacleService.getCurrentUser().subscribe(u => { this.currentUser = u; });

        this.pinnacleService.GetCurrentPartner().subscribe(partner => {

            this.pinnacleService.getUsersWithPermissions().subscribe(res => {
                this.allUsers = new Array<SelectablePermissionUser>();
                this.users = new Array<SelectablePermissionUser>();


                for (let u of res) {
                    if (u.name != partner.name) {
                        this.allUsers.push(u as SelectablePermissionUser);
                    }
                }

                for (let u of this.allUsers) {
                    u.isSelected = false;
                }

                this.filterTextChanged();
                this.changeRef.detectChanges();

                this.isLoaded = true;
            });

        });


    }

    onUserClick(user: PermissionUser) {
        let liteUser = new PinnacleLiteUser();
        liteUser.userId = user.userId;
        liteUser.userEmail = user.email;
        liteUser.userName = user.name;
        liteUser.isAdmin = user.permissions.administrator;
        this.clickedUser = liteUser;
    }

    onUserAdded(event: any) {
        this.loadData();
    }

    onUserModified(event: any) {
        this.loadData();
    }

    areUsersSelected(): boolean {
        if (this.users.find(u => u.isSelected == true) != null) {
            return true;
        }
        return false;
    }

    // coming back from message box so have to use static instance for calls
    onDeleteUsers() {
        let triedToDeleteSelf = false;

        for (var i = 0; i < PinnacleLiteManageUsersComponent.staticThis.allUsers.length; i++){
            let u = PinnacleLiteManageUsersComponent.staticThis.allUsers[i];
            if (u.isSelected == true) {
                if (u.userId == PinnacleLiteManageUsersComponent.staticThis.currentUser.userId) {
                    triedToDeleteSelf = true;
                    u.isSelected = false;
                }
                else {

                    u.isSelected = false;
                    PinnacleLiteManageUsersComponent.staticThis.allUsers.splice(i, 1);
                    i--;
                    PinnacleLiteManageUsersComponent.staticThis.pinnacleService.deletePinnacleLiteUser(u.userId).subscribe(

                    );
                }
            }
        }
        if (triedToDeleteSelf == true) {

            var v_Msg = new MsgBxStringVals();
            v_Msg.body = "You are not able to delete yourself.";
            v_Msg.title = ``;
            PinnacleLiteManageUsersComponent.staticThis.controlService.openMessageBoxPopUp(v_Msg);
            
        }
        PinnacleLiteManageUsersComponent.staticThis.filterTextChanged();
        PinnacleLiteManageUsersComponent.staticThis.changeRef.detectChanges();

    }

    // coming back from message box so have to use static instance for calls
    onEmailUsers() {
        for (let u of PinnacleLiteManageUsersComponent.staticThis.users) {
            if (u.isSelected == true) {
                u.isSelected = false;
                PinnacleLiteManageUsersComponent.staticThis.pinnacleService.sendEmailToPinnacleLiteUser(u.userId).subscribe();
            }
        }
    }

    onSelectAll() {
        for (let u of this.users) {
            u.isSelected = this.selectAllCheck;
        }
    }

    get isPinnacleLite() {
        return AppComponent.isPinnacleLite;
    }


    // the filter text has changed. We update our filter items array checking if the filter text is used in each member. 
    // if the filter is empty, we just make a copy of the master list.
    filterTextChanged() {
        this.users.length = 0;
        if (this.filterText.length == 0) {
            this.users = this.users.concat(this.allUsers);
            return;
        }

        for (let c of this.allUsers) {
            let filter = this.filterText.toLowerCase();
            if ((c.name != null && c.name.toLowerCase().indexOf(filter) >= 0) ||
                (c.email != null && c.email.toLowerCase().indexOf(filter) >= 0) 
            ) {
                this.users.push(c);
            }
        }
    }


}

export class SelectablePermissionUser extends PermissionUser{
    isSelected: boolean = false;
}

