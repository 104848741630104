import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ProdGenApi } from '../apiService/prodgen.api';

@Injectable()
export class LoadedStorageService {

    private data: Object = new Object();

    constructor(private pinnacleService: ProdGenApi) {

    }

    //GetData(key: string, params: Array<object>): Observable<Object> {
    GetData(key: string): Observable<Object> {



        if (this.data[key]) {
            return this.data[key];
        }
        else if (this.pinnacleService[key]) {
            this.data[key] = this.pinnacleService[key]();

            return this.data[key];
        }

        return null;
    }

    GetDataWithParam(key: string, param: Array<Object>): Observable<Object> {


        if (this.data[key]) {
            return this.data[key];
        }
        else if (this.pinnacleService[key]) {

            var v_ParamsCount = param.length;

            let v_Param1 = new Object();
            let v_Param2 = new Object();
            let v_Param3 = new Object();
            let v_Param4 = new Object();
            let v_Param5 = new Object();





            this.data[key] = this.pinnacleService[key](param);
          

            return this.data[key];
        }

        return null;
    }

    //this method requires any child methods of action to be static, this includes any variables. This is because in this callback method "this" is undefined
    GetDataWithDelegate(action: () => any) : Observable<Object> {

        return action();

    }


}