import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
//import { LearningcenterheaderComponent } from '../templates/learningcenterheader/learningcenterheader.component';
import { ProdGenApi } from './../apiService/prodgen.api';
import { Enrollment, EnrollmentFilter_T, EnrollmentStatus_T, QuizTrackingItem, QuizSessionItem, QuizSessionTracking } from './../apiService/classFiles/class.enrollments';
import { Course, Content, ContentType_T, DescriptionFilter_T, QuizStatus_T, Quiz } from './../apiService/classFiles/class.content';

import { ActivatedRoute, Router, Route, Params } from '@angular/router';
import { BrowserAuthenticationService } from './../BrowserAuthenticationService';
import { SearchComponent } from './../search/search.component';
import { TranslationService } from '../services/TranslationService';
import { LiveEventCalendarComponent } from '../../app/liveeventcalendar/liveeventcalendar.component';
import { User, WidgetContainerInfo } from '../apiService/classFiles/class.users';
import { WidgetLibraryService } from '../services/widget-library.service';
declare var $: any; // JQuery workaround

@Component({
    selector: 'app-learningcenter',
    templateUrl: './learningcenter.component.html',
    styleUrls: ['./learningcenter.component.css'],
    providers: [ProdGenApi, Enrollment]
})


export class LearningcenterComponent implements OnInit, AfterViewInit {

    currentEnrollments: Array<Enrollment> = new Array<Enrollment>();
    completedEnrollments: Array<Enrollment> = new Array<Enrollment>(); 
    condensedEnrollments: Array<Enrollment> = new Array<Enrollment>();
    relatedCourses: Array<Content> = new Array<Content>();
    searchTerms: string = "";
    showingAllCompleted: boolean = false;
    completedEnrollmentsLoaded: boolean = false;
    enrollmentsLoaded: boolean = false;
    isAuthenticated: boolean = false;
    selectedSort: string = "sortLP";
    courseFinishedStatus: string = "";
    sortStatusLP: number = 0;
    sortStatusCourse: number = 0;
    sortStatusStatus: number = 0;
    sortStatusDate: number = 1;
    sortStatusCert: number = 0;
    navMessageToolTip: string = "";

    useWidgets: boolean = false;
    lccontainer: WidgetContainerInfo = new WidgetContainerInfo();

    showStaticLiveEventComponent: boolean = false;
    @ViewChild("staticLiveEventComponent", { static: false }) liveEventComponent: LiveEventCalendarComponent;

    isExternal: boolean = false;
    courseImage: string = "";

    constructor(private pinnacleService: ProdGenApi, private route: ActivatedRoute, private router: Router, private authenticationService: BrowserAuthenticationService, private translationService: TranslationService, private widgetLibSvc: WidgetLibraryService) {
        if (localStorage.getItem("showLiveEventCalendarComponent") == "true") {
            this.showStaticLiveEventComponent = true;
        }

        if (ProdGenApi.GetUserIsExternal() == true) {
            this.isExternal = true;            
        }

        let cImg = ProdGenApi.getLargeDefaultImageFromType(ContentType_T.course);
        if (cImg.indexOf("default") == -1) {
            this.courseImage = cImg;
            }
        else {
            this.courseImage = "../../assets/images/" + cImg;
            }

    }

    ngAfterViewInit(): void {
        
    }

    ngOnInit() {

        if (this.authenticationService.AuthenticatePage() == true) {
            this.pinnacleService.getSettingByName("USE_WIDGETS").subscribe(res => {
                let widgetSetting = res;
                if (widgetSetting) {
                    this.useWidgets = widgetSetting.settingValue.toString().toLowerCase() == 'true';
                }
                else {
                    this.useWidgets = false;
                }
                this.widgetLibSvc.setIsUsingWidget(this.useWidgets);
            });

            this.isAuthenticated = true;

            this.navMessageToolTip = this.translationService.getTranslationFileData("LEARNINGCENTER.NavMoreInfo");

            this.pinnacleService.getCurrentUserEnrollments([EnrollmentFilter_T.assigned, EnrollmentFilter_T.inProgress]).subscribe(res => {
                this.enrollmentsLoaded = true;
                for (var i = 0; i < res.length; i++) {
                    if (res[i].imageURL == null || res[i].imageURL == "") {
                        res[i].imageURL = "../assets/images/default_course.jpg";
                    }

                    // get related items for the course
                    if (i < 2) {
                        this.pinnacleService.GetContentRelatedItems(res[i].courseId, "course", -1, 0, DescriptionFilter_T.unformatted).subscribe(rel => {
                            for (let c of rel) {
                                if (this.relatedCourses.length < 4) {
                                    this.relatedCourses.push(c);
                                }
                            }
                        });
                    }
                }
                this.currentEnrollments = res;
                //copy and sort the array. flag the lowest squences with the number of other items with the same learning path. remove non-lowest sequence.
                //copy and sort the array.
                this.CondenseEnrollments();

            });

            this.pinnacleService.getCurrentUserEnrollments([EnrollmentFilter_T.completed, EnrollmentFilter_T.dropped]).subscribe(res => {
                for (var i = 0; i < res.length; i++) {
                    if (res[i].imageURL == null || res[i].imageURL == "") {
                        res[i].imageURL = "../assets/images/default_course.jpg";
                    }
                }
                this.completedEnrollments = res.sort((r1, r2) => {
                    if (r1.statusDate < r2.statusDate) {
                        return 1;
                    }
                    else if (r1.statusDate > r2.statusDate) {
                        return -1;
                    }
                    return 0;
                });
                this.completedEnrollmentsLoaded = true;

                this.selectedSort = sessionStorage.getItem("courseSort");

                if (this.selectedSort == null) { this.selectedSort = "sortLP"; }

                this.SortChange();
            });

        }

        scroll(0, 0);

        try {
            this.useWidgets = localStorage.getItem("usewidgets" + ProdGenApi.getCurrentTenantId()).toString().toLowerCase() == 'true';
        } catch (e) {
            this.useWidgets = false;
        }

        this.pinnacleService.getWidgetContainerForUser("LEARNING_CENTER_CONTAINER").subscribe(res => {
            this.lccontainer = res;
        });
    }

    onPrintCertficate() {
        this.pinnacleService.getEnrollmentCertificate(this.sc.enrollmentId).subscribe(res => {
            let url: string = res.replace("http://", "https://");
            if (url.length > 0 && (url.toString() != "FailQuiz" && url.toString() != "NotComplete")) {
                this.hasCertificate = true;
                this.certificateURL = url;
            }
            else if (url.length > 0 && url.toString() == "FailQuiz") {
                this.hasCertificate = false;
                this.certificateURL = this.translationService.getTranslationFileData("LEARNINGCENTER.NC_QuizFailed");
            }
            else if (url.length > 0 && url.toString() == "NotComplete") {
                this.hasCertificate = false;
                this.certificateURL = this.translationService.getTranslationFileData("LEARNINGCENTER.NC_ItemsSkipped");
            }
            else {
                this.hasCertificate = false;
                this.certificateURL = this.translationService.getTranslationFileData("LEARNINGCENTER.NC_NoCertificate");
            }
        },
            err => {
                this.hasCertificate = false;
                this.certificateURL = this.translationService.getTranslationFileData("LEARNINGCENTER.NC_Error");
            });
    }

    onPrintTranscript() {
        this.pinnacleService.getUserTranscript().subscribe(res => {
            let url: string = res.replace("http://", "https://");
            if (url.length > 0) {
                window.location.href = url;
            }
            else {
            }
        });
    }

    onBrowseAllClicked() {
        let url: string = "";
        url += "search?contentType=PS_LearningPath&contentType=PS_Course";
        this.router.navigateByUrl(url);
    }

    onCourseDropped(event) {
        let e: Enrollment = event.enrollment;

        if (e != null) {
            let index = this.currentEnrollments.findIndex(i => i.enrollmentId == e.enrollmentId);
            if (index != -1) {
                this.currentEnrollments.splice(index, 1);
                this.CondenseEnrollments();
            }
        }

    }

    getStatusText(status: EnrollmentStatus_T): string {
        if (status == EnrollmentStatus_T.dropped) {
            return "Dropped"
        }
        else if (status == EnrollmentStatus_T.completed) {
            return "Completed"
        }
        return "";
    }

    getRowClass(e: Enrollment): string {
        if (e.enrollmentStatus == EnrollmentStatus_T.dropped) {
            return "danger";
        }
        else if (e.enrollmentStatus == EnrollmentStatus_T.completed) {

            return "success";
        }

        return "";
    }

    onSearch(): void {
        let url: string = "";
        if (this.searchTerms.trim().length > 0) {
            url += "search?searchTerms=" + SearchComponent.encodeSearchParameters(this.searchTerms.trim()) + "&contentType=PS_LearningPath&contentType=PS_Course";
            this.router.navigateByUrl(url);
        }
    }

    onKeySearchPress(event: any) {
        if (event.keyCode == 13) {
            this.onSearch();
        }
    }
    onKeySearchInput(event: any) {
        if (event.data == null && event.composed != null && event.composed == false) {
            this.onSearch();
        }
    }

    toggleShowAllCompleted() {
        this.showingAllCompleted = !this.showingAllCompleted;
    }

    SortChange(): void {
        //this.currentEnrollments[0]
        switch (this.selectedSort) {
            case "sortDueDT_EtoL": {
                sessionStorage.setItem("courseSort", this.selectedSort);
                this.condensedEnrollments.sort((a: any, b: any) => {
                    var x = a.hasDueDate;
                    var y = b.hasDueDate;
                    var w = a.dueDate;
                    var z = b.dueDate;
                    if (x < y) { return 1; }
                    if (x > y) { return -1; }
                    if (x == y) { //if the values are equal do a second level sort 

                        if (w < z) { return -1; }
                        if (w > z) { return 1; }
                        return 0;
                    }
                });
                break;
            }
            case "sortDueDT_LtoE": {
                sessionStorage.setItem("courseSort", this.selectedSort);
                this.condensedEnrollments.sort((a: any, b: any) => {
                    var x = a.hasDueDate;
                    var y = b.hasDueDate;
                    var w = a.dueDate;
                    var z = b.dueDate;
                    if (x < y) { return 1; }
                    if (x > y) { return -1; }
                    if (x == y) {

                        if (w < z) { return 1; }
                        if (w > z) { return -1; }
                        return 0;
                    }
                });
                break;
            }
            case "sortLP": {
                sessionStorage.setItem("courseSort", this.selectedSort);
                this.condensedEnrollments.sort((a: any, b: any) => {
                    var x = a.learningPathName;
                    var y = b.learningPathName;
                    var w = a.sequence;
                    var z = b.sequence;
                    if (x < y) { return -1; }
                    if (x > y) { return 1; }
                    if (x == y) {

                        if (w < z) { return -1; }
                        if (w > z) { return 1; }
                        return 0;
                    }
                });
                break;
            }
            case "sortPerCom_LtoM": {
                sessionStorage.setItem("courseSort", this.selectedSort);
                this.condensedEnrollments.sort((a: any, b: any) => {
                    var x = a.percentComplete;
                    var y = b.percentComplete;
                    if (x < y) { return -1; }
                    if (x > y) { return 1; }
                    return 0;
                });
                break;
            }
            case "sortPerCom_MtoL": {
                sessionStorage.setItem("courseSort", this.selectedSort);
                this.condensedEnrollments.sort((a: any, b: any) => {
                    var x = a.percentComplete;
                    var y = b.percentComplete;
                    if (x < y) { return 1; }
                    if (x > y) { return -1; }
                    return 0;
                });
                break;
            }
            default:
        }
    }

    headerClick(type: string) {

        //default sort is by date

        switch (type) {
            case "LP": {
                switch (this.sortStatusLP) {
                    case 0:
                    case 2:
                        {//set the sort to decending
                            this.sortStatusLP = 1;
                            this.sortStatusStatus = 0;
                            this.sortStatusDate = 0;
                            this.sortStatusCourse = 0;
                            this.sortStatusCert = 0;

                            this.completedEnrollments.sort((a: any, b: any) => {
                                var x = a.learningPathName;
                                var y = b.learningPathName;
                                if (x < y) { return -1; }
                                if (x > y) { return 1; }
                                return 0;
                            });
                            break;
                        }
                    case 1: { //set the sort to acsending
                        this.sortStatusLP = 2;
                        this.sortStatusStatus = 0;
                        this.sortStatusDate = 0;
                        this.sortStatusCourse = 0;
                        this.sortStatusCert = 0;

                        this.completedEnrollments.sort((a: any, b: any) => {
                            var x = a.learningPathName;
                            var y = b.learningPathName;
                            if (x < y) { return 1; }
                            if (x > y) { return -1; }
                            return 0;
                        });
                        break;

                    }
                    //case 2: { //third click, set back to the default date sorting
                    //  this.sortStatusLP = 0;
                    //  this.sortStatusStatus = 0;
                    //  this.sortStatusDate = 1;
                    //  this.sortStatusCourse = 0;
                    //  this.sortStatusCert = 0;

                    //  this.completedEnrollments.sort((a, b) => {
                    //    var x = a.statusDate;
                    //    var y = b.statusDate;
                    //    if (x < y) { return 1; }
                    //    if (x > y) { return -1; }
                    //    return 0;
                    //  });
                    //  break;
                    //}
                    default:
                }
                break;
            }
            case "C": {
                switch (this.sortStatusCourse) {
                    case 0:
                    case 2:
                        {//set the sort to decending
                            this.sortStatusLP = 0;
                            this.sortStatusStatus = 0;
                            this.sortStatusDate = 0;
                            this.sortStatusCourse = 1;
                            this.sortStatusCert = 0;

                            this.completedEnrollments.sort((a: any, b: any) => {
                                var x = a.courseName;
                                var y = b.courseName;
                                if (x < y) { return -1; }
                                if (x > y) { return 1; }
                                return 0;
                            });
                            break;
                        }
                    case 1: { //set the sort to acsending
                        this.sortStatusLP = 0;
                        this.sortStatusStatus = 0;
                        this.sortStatusDate = 0;
                        this.sortStatusCourse = 2;
                        this.sortStatusCert = 0;

                        this.completedEnrollments.sort((a: any, b: any) => {
                            var x = a.courseName;
                            var y = b.courseName;
                            if (x < y) { return 1; }
                            if (x > y) { return -1; }
                            return 0;
                        });
                        break;

                    }
                    //case 2: { //third click, set back to the default date sorting
                    //  this.sortStatusLP = 0;
                    //  this.sortStatusStatus = 0;
                    //  this.sortStatusDate = 1;
                    //  this.sortStatusCourse = 0;
                    //  this.sortStatusCert = 0;

                    //  this.completedEnrollments.sort((a, b) => {
                    //    var x = a.statusDate;
                    //    var y = b.statusDate;
                    //    if (x < y) { return 1; }
                    //    if (x > y) { return -1; }
                    //    return 0;
                    //  });
                    //  break;
                    //}
                    default:
                }
                break;
            }
            case "S": {
                switch (this.sortStatusStatus) {
                    case 0:
                    case 2:
                        { //set the sort to decending
                            this.sortStatusLP = 0;
                            this.sortStatusStatus = 1;
                            this.sortStatusDate = 0;
                            this.sortStatusCourse = 0;
                            this.sortStatusCert = 0;

                            this.completedEnrollments.sort((a: any, b: any) => {
                                var x = a.enrollmentStatus;
                                var y = b.enrollmentStatus;
                                if (x < y) { return -1; }
                                if (x > y) { return 1; }
                                return 0;
                            });
                            break;
                        }
                    case 1: { //set the sort to acsending
                        this.sortStatusLP = 0;
                        this.sortStatusStatus = 2;
                        this.sortStatusDate = 0;
                        this.sortStatusCourse = 0;
                        this.sortStatusCert = 0;

                        this.completedEnrollments.sort((a: any, b: any) => {
                            var x = a.enrollmentStatus;
                            var y = b.enrollmentStatus;
                            if (x < y) { return 1; }
                            if (x > y) { return -1; }
                            return 0;
                        });
                        break;

                    }
                    //case 2: { //third click, set back to the default date sorting
                    //  this.sortStatusLP = 0;
                    //  this.sortStatusStatus = 0;
                    //  this.sortStatusDate = 1;
                    //  this.sortStatusCourse = 0;
                    //  this.sortStatusCert = 0;

                    //  this.completedEnrollments.sort((a, b) => {
                    //    var x = a.statusDate;
                    //    var y = b.statusDate;
                    //    if (x < y) { return 1; }
                    //    if (x > y) { return -1; }
                    //    return 0;
                    //  });
                    //  break;
                    //}
                    default:
                }
                break;
            }
            case "DT": {
                switch (this.sortStatusDate) {
                    case 0:
                    case 2:
                        {//set the sort to decending
                            this.sortStatusLP = 0;
                            this.sortStatusStatus = 0;
                            this.sortStatusDate = 1;
                            this.sortStatusCourse = 0;
                            this.sortStatusCert = 0;

                            this.completedEnrollments.sort((a: any, b: any) => {
                                var x = a.statusDate;
                                var y = b.statusDate;
                                if (x < y) { return 1; }
                                if (x > y) { return -1; }
                                return 0;
                            });
                            break;
                        }
                    case 1: { //set the sort to acsending
                        this.sortStatusLP = 0;
                        this.sortStatusStatus = 0;
                        this.sortStatusDate = 2;
                        this.sortStatusCourse = 0;
                        this.sortStatusCert = 0;

                        this.completedEnrollments.sort((a: any, b: any) => {
                            var x = a.statusDate;
                            var y = b.statusDate;
                            if (x < y) { return -1; }
                            if (x > y) { return 1; }
                            return 0;
                        });
                        break;

                    }
                    //case 2: { //third click, set back to the default date sorting
                    //  this.sortStatusLP = 0;
                    //  this.sortStatusStatus = 0;
                    //  this.sortStatusDate = 1;
                    //  this.sortStatusCourse = 0;
                    //  this.sortStatusCert = 0;

                    //  this.completedEnrollments.sort((a, b) => {
                    //    var x = a.statusDate;
                    //    var y = b.statusDate;
                    //    if (x < y) { return 1; }
                    //    if (x > y) { return -1; }
                    //    return 0;
                    //  });
                    //  break;
                    //}
                    default:
                }
                break;
            }
            case "Cert": {
                switch (this.sortStatusCert) {
                    case 0:
                    case 2:
                        {//set the sort to decending
                            this.sortStatusLP = 0;
                            this.sortStatusStatus = 0;
                            this.sortStatusDate = 0;
                            this.sortStatusCourse = 0;
                            this.sortStatusCert = 1;

                            this.completedEnrollments.sort((a: any, b: any) => {
                                var x = a.enrollmentStatus;
                                var y = b.enrollmentStatus;
                                if (x < y) { return -1; }
                                if (x > y) { return 1; }
                                return 0;
                            });
                            break;
                        }
                    case 1: { //set the sort to acsending
                        this.sortStatusLP = 0;
                        this.sortStatusStatus = 0;
                        this.sortStatusDate = 0;
                        this.sortStatusCourse = 0;
                        this.sortStatusCert = 2;

                        this.completedEnrollments.sort((a: any, b: any) => {
                            var x = a.enrollmentStatus;
                            var y = b.enrollmentStatus;
                            if (x < y) { return 1; }
                            if (x > y) { return -1; }
                            return 0;
                        });
                        break;

                    }
                    //case 2: { //third click, set back to the default date sorting
                    //  this.sortStatusLP = 0;
                    //  this.sortStatusStatus = 0;
                    //  this.sortStatusDate = 1;
                    //  this.sortStatusCourse = 0;
                    //  this.sortStatusCert = 0;

                    //  this.completedEnrollments.sort((a, b) => {
                    //    var x = a.statusDate;
                    //    var y = b.statusDate;
                    //    if (x < y) { return 1; }
                    //    if (x > y) { return -1; }
                    //    return 0;
                    //  });
                    //  break;
                    //}
                    default:
                }
                break;
            }
            default:
        }


        //this.completedEnrollments.sort((a: any, b: any) => {
        //  var x = a.percentComplete;
        //  var y = b.percentComplete;
        //  if (x < y) { return -1; }
        //  if (x > y) { return 1; }
        //  return 0;
        //});

    }

    CondenseEnrollments() {
        let copiedEnrollment: Enrollment = new Enrollment();

        //console.log("condensed enrollments");
        //console.log(this.condensedEnrollments);

        this.condensedEnrollments.splice(0);
        for (var i = 0; i < this.currentEnrollments.length; i++) {
            copiedEnrollment = this.currentEnrollments[i];
            copiedEnrollment.childCourses = 0;
            this.condensedEnrollments.push(copiedEnrollment);
        }

        //console.log("condensed enrollments");
        //console.log(this.condensedEnrollments);


        this.condensedEnrollments.sort((a: any, b: any) => {
            var x = a.learningPathId;
            var y = b.learningPathId;
            var w = a.sequence;
            var z = b.sequence;
            if (x < y) { return 1; }
            if (x > y) { return -1; }
            if (x == y) { //if the values are equal do a second level sort 

                if (w < z) { return -1; }
                if (w > z) { return 1; }
                return 0;
            }
        });

        // flag the lowest squences with the number of other items with the same learning path.

        for (var n = 0; n < this.condensedEnrollments.length; n++) {
            let d: number = 1;
            let childCourses: number = 1;
            if (n == 0) { //first item
                if (this.condensedEnrollments.length == 1) {
                    childCourses = 1;
                }
                else {
                    
                    while ((n + d) < this.condensedEnrollments.length && this.condensedEnrollments[n].learningPathId == this.condensedEnrollments[n + d].learningPathId) {
                        
                        childCourses++;
                        d++;
                    }
                }
            }
            else if ((n == this.condensedEnrollments.length - 1) && (this.condensedEnrollments[n].learningPathId != this.condensedEnrollments[n - 1].learningPathId)) { //last item. this is either a part of another path and has already been accounted for. otherwise it is its own
                childCourses = 1;
            }
            else if (this.condensedEnrollments[n].learningPathId == this.condensedEnrollments[n - 1].learningPathId) {
                childCourses = 0;
            }
            else {

                while ((n + d) < this.condensedEnrollments.length && this.condensedEnrollments[n].learningPathId == this.condensedEnrollments[n + d].learningPathId) {

                    childCourses++;
                    d++;
                }
            }

            this.condensedEnrollments[n].childCourses = childCourses;
        }



        //remove remove non-lowest sequences (i.e. those with no child courses).
        for (var e = 0; e < this.condensedEnrollments.length; e++) {
            if (this.condensedEnrollments[e].childCourses == 0) {
                this.condensedEnrollments.splice(e, 1);
                e--;
            }
        }



        this.selectedSort = sessionStorage.getItem("courseSort");

        if (this.selectedSort == null) { this.selectedSort = "sortLP"; }

        this.SortChange();
    }


    /// Course Review Modal Start
    trackingItemsLoaded: boolean = false;
    trackingItemsError: boolean = false;

    successColor = "rgb(223, 240, 216)";
    failureColor = "rgb(252, 229, 229)";
    correctColor = "darkgreen";
    incorrectColor = "darkred";
    neutralColor = "rgb(219, 219, 219)";
    sectionBorder = "1px solid #dbdbdb";
    separatorBorder = "1px solid";

    hasCertificate = false;
    certificateURL = "";

    sc = new Enrollment();
    scAssignedBy = "";
    scPercentComplete = "0%";
    scStatusColor = this.neutralColor;
    scPercentBorder = "none";

    passMessage = "";

    ss = new QuizSessionItem();

    quizTrackingItems = new Array<QuizTrackingItem>();
    quizSessionItems: { [key: string]: QuizSessionItem; } = {};

    answerExplanations: { [key: string]: string; } = {};

    currentUser: User = null;
    currentUserLoaded: boolean = false;

    selectCourse(event) {

        this.trackingItemsLoaded = false;
        this.trackingItemsError = false;

        let scIndex = this.completedEnrollments.findIndex(e => {
            return e.enrollmentId == event.target.id as string;
        });

        if (!this.currentUserLoaded) {
            this.pinnacleService.getCurrentUser(false).subscribe(res => {
                this.currentUser = res;
                this.currentUserLoaded = true;
            });
        }

        if (scIndex != -1) {
            this.sc = this.completedEnrollments[scIndex];
            this.getCourseOverview();

            this.quizTrackingItems = new Array<QuizTrackingItem>(); // Clear previous enrollment content

            this.pinnacleService.getAllQuizEnrollmentTracking(this.sc.enrollmentId as string).subscribe(res => {

                for (let r of res) {
                    this.quizTrackingItems.push(r);

                    for (let s of r.quizSessionItem) {
                        this.quizSessionItems[s.quizSessionId as string] = s; // Easier to store a reference list of sessions than to navigate HTML to find out which session was clicked
                    }
                }

                this.trackingItemsLoaded = true;
            });

            // Bootstrap adds the class 'modal-open' to the body when showing
            // a modal in order to prevent scrolling content behind the modal.
            // Since we may possibly open 2 modals, we add our own version
            // to prevent the second modal from prematurely reenabling scrolling
            // when closing.
            (document.body as HTMLBodyElement).classList.add("my-modal-open");
            $("#courseReviewModal").on("hide.bs.modal", function (e) {
                $("body").removeClass("my-modal-open");
            });

            // Firefox apparently cares about scrollable elements behind the
            // focused element, so we remove the ability to scroll the course
            // review modal when the quiz details modal opens, and restore 
            // when the quiz details modal closes (button or clicking outside)
            $("#quizDetailModal").on("shown.bs.modal", function (e) {
                $("#courseReviewBody").css("overflow-y", "hidden");
            });

            $("#quizDetailModal").on("hide.bs.modal", function (e) {
                $("#courseReviewBody").css("overflow-y", "auto");
                $("#detailsModalBody").scrollTop(0); // Also scroll back to the top
            });

            // Used in a title, cannot be translated in html
            this.passMessage = this.translationService.getTranslationFileData("LEARNINGCENTER.PassMessage");
        }
        else {
            this.trackingItemsError = true;
        }
    }

    getCourseOverview() {
        this.pinnacleService.getUser(this.sc.assignerUserId).subscribe(res => {
            this.scAssignedBy = res.name;
        });

        this.scPercentComplete = this.sc.percentComplete.toString() + "%";
        if (this.sc.percentComplete > 0
            && this.sc.percentComplete < 100) {
            this.scPercentBorder = this.separatorBorder;
        }
        else {
            this.scPercentBorder = "none";
        }

        if (this.sc.enrollmentStatus == EnrollmentStatus_T.dropped) {
            this.scStatusColor = this.failureColor;
            this.hasCertificate = false;
            this.certificateURL = this.translationService.getTranslationFileData("LEARNINGCENTER.NC_CourseDropped");
        }
        else {
            this.scStatusColor = this.successColor;
            this.onPrintCertficate();
            this.certificateURL = this.translationService.getTranslationFileData("LEARNINGCENTER.Loading") + "...";
        }
    }

    getQuizStatusText(status): string {
        if (status == QuizStatus_T.pass
            || status == "PASS") {
            return this.translationService.getTranslationFileData("LEARNINGCENTER.Pass");
        }
        if (status == QuizStatus_T.fail
            || status == "FAIL") {
            return this.translationService.getTranslationFileData("LEARNINGCENTER.Fail");
        }
        return "";
    }

    getStatusColor(status: any): string {
        if (status == "PASS"
            || status == EnrollmentStatus_T.completed
            || status == QuizStatus_T.pass) {
            return this.successColor;
        }
        if (status == "FAIL"
            || status == EnrollmentStatus_T.dropped
            || status == QuizStatus_T.fail) {
            return this.failureColor;
        }
        return "";
    }

    getScorePercentage(correct: number, possible: number): string {
        if (possible == null || possible < 1)
            return "0%";
        return ((correct / possible) * 100).toFixed(2).toString() + "%";
    }

    getCorrectBorder(correct: number, pass: number): string {
        if (correct > 0 && correct < pass) {
            return this.separatorBorder;
        }
        return "none";
    }

    getPassWidth(correct: number, possible: number, pass: number): string {
        return (((pass - correct) / possible) * 100).toFixed(2).toString() + "%";
    }

    getPassPercentage(q: QuizSessionItem): string {
        if (q.quizAnswersPossible == null || q.quizAnswersPossible < 1)
            return "0%";
        return ((q.quizAnswersPass / q.quizAnswersPossible) * 100).toFixed(2).toString() + "%";
    }

    toggleIcon($event) {
        let childElement = (event.target as HTMLElement).querySelector('i');
        if (childElement != null) {
            if (childElement.classList.contains("glyphicon-menu-down")) {
                childElement.classList.add("glyphicon-menu-up");
                childElement.classList.remove("glyphicon-menu-down");
            }
            else if (childElement.classList.contains("glyphicon-menu-up")) {
                childElement.classList.add("glyphicon-menu-down");
                childElement.classList.remove("glyphicon-menu-up");
            }
        }
        
    }

    selectQuizSession(event) {
        this.pinnacleService.getEmailLanguagePreference(this.currentUser.email, ProdGenApi.getCurrentTenantId()).subscribe(res => {
            let langPref = res;
            this.ss = this.quizSessionItems[event.target.id];
            for (let q of this.ss.quizSessionTracking) {
                if (q.explanation != null && q.explanation != "") {
                    if (langPref != "en") {
                        this.pinnacleService.translateText(q.explanation as string, "en", false).subscribe(res => {
                            this.answerExplanations[q.explanation as string] = res as string;
                        });
                    }
                    else {
                        this.answerExplanations[q.explanation as string] = q.explanation as string;
                    }
                }
            }
        });
    }

    detectNavigation(ans: string): boolean {
        if (ans != null && (ans.indexOf("quizQ")!=-1)) {
            return true;
        }
        return false;
    }

    splitMultiAnswer(ans: string, isFillInBlank: boolean, isCorrectAns: boolean): string[] {
        let answers = ans.split('|');
        if (answers.length > 0) {
            if (!isFillInBlank
                || (isFillInBlank && !isCorrectAns && answers.length > 1)) {
                for (let i = 0; i < answers.length; i++) {
                    answers[i] = "- " + answers[i];
                }
            }
            if (isFillInBlank && isCorrectAns) {
                let pipedAns = "";
                for (let a of answers) {
                    pipedAns += (a + " | ");
                }
                pipedAns = pipedAns.slice(0, -3);
                answers = [pipedAns];
            }
        }
        return answers;
    }
    /// Course Review Modal End

    showLiveEventComponent(): void {
        if (this.showStaticLiveEventComponent) {
            this.liveEventComponent.setShowComponent(false);
            this.showStaticLiveEventComponent = false;
        }
        else {
            this.liveEventComponent.setShowComponent(true);
            this.liveEventComponent.loadLiveEvents();
            this.showStaticLiveEventComponent = true;
        }
    }
}