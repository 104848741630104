import { Component, OnInit, Input, ViewChild, ChangeDetectorRef} from '@angular/core';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { WidgetContainerComponent } from '../widget-container/widget-container.component';
import { WidgetProperties, WidgetPropertyOptions } from '../../apiService/classFiles/class.users';
import { ProdGenApi } from '../../apiService/prodgen.api';
import { Router } from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-widget',
  templateUrl: './widget.component.html',
  styleUrls: ['./widget.component.css']
})
export class WidgetComponent implements OnInit {
    widgetID: string = "";
    widgetDesc: string = "";
    widgetName: string = "";
    widgetHeight: number = 2;
    widgetWidth: number = 2;
    mobileSequence: string = "0";
    displayLocation: string = "primary";

    displayType: string = "panel";
    widgetTitle: string = "";

    widgetProps: Array<WidgetProperties> = new Array<WidgetProperties>();
    widgetRenderSize = "lg";


    widgetContainer: WidgetContainerComponent;
    isLoaded: boolean=false;
    OverlayHTML: HTMLInputElement;

    mobileHeight: number;
    useMobileHeight: boolean = false;

    overlayDisplayClass = "widget-overlay-style-page";
    overlayDisplayLinkClass = "";

    constructor(private pinnacleservice: ProdGenApi, private changeDetectorRef: ChangeDetectorRef) {
        this.OverlayHTML = document.createElement("input");
        this.OverlayHTML.type = "button";
        this.OverlayHTML.value = "TEST";
        this.OverlayHTML.addEventListener("click", this.displayconsole);

    }

    ngOnInit() {
        this.calcWidgetRenderSize();
}

    

    Load() {
        this.loadProperties();
    }

    displayconsole() {
        $("#myModal").modal({ backdrop: 'static', keyboard: false });
    }

    loadProperties() {
        this.pinnacleservice.getWidgetProperties(this.widgetID).subscribe(props => {
            for (let p of props) {
                let index = this.widgetProps.findIndex(x => x.propID == p.propID);
                if (index != -1) {
                    this.widgetProps[index].propValue = p.propValue;
                }
            }
            
            this.isLoaded = true;
            this.calcWidgetRenderSize();

            let mobileIndex = this.widgetProps.findIndex(x => x.propID == "MOBILE_SEQUENCE");
            if (this.widgetProps[mobileIndex].propValue == "0") {
                this.widgetProps[mobileIndex].propValue = (this.widgetContainer.containerWidgets.findIndex(y => y.id == this.widgetID) + 1).toString();
                this.mobileSequence = (this.widgetContainer.containerWidgets.findIndex(y => y.id == this.widgetID) + 1).toString();
            }
            this.onPropsChanged(props, false, false);
        });
    }

    onPropsChanged(newprops: Array<WidgetProperties>, writeSettings: boolean = false, doresequence: boolean = true) {

        //Required Properties
        if (newprops.findIndex(x => x.propName == "Height") != -1) {
            this.widgetHeight = +newprops[newprops.findIndex(x => x.propName == "Height")].propValue;
        }
        if (newprops.findIndex(x => x.propName == "Width") != -1) {
            this.widgetWidth = +newprops[newprops.findIndex(x => x.propName == "Width")].propValue;
        }

        //Required Mobile Properties
        if (newprops.findIndex(x => x.propID == "MOBILE_SEQUENCE") != -1) {
            let orig = this.mobileSequence;
            this.mobileSequence = newprops[newprops.findIndex(x => x.propID == "MOBILE_SEQUENCE")].propValue;
            if (doresequence == true && +this.mobileSequence != -1) {
                this.widgetContainer.reSequenceWidgets(this.widgetID, +this.mobileSequence, +orig);
            }
        }

        if (newprops.findIndex(x => x.propID == "DISPLAY_LOCATION") != -1) {
            this.displayLocation = newprops[newprops.findIndex(x => x.propID == "DISPLAY_LOCATION")].propValue;
        }


        //Common Properties
        if (newprops.findIndex(x => x.propID == "DISPLAY_TYPE") != -1) {
            this.displayType = newprops[newprops.findIndex(x => x.propID == "DISPLAY_TYPE")].propValue;
        }

        if (newprops.findIndex(x => x.propID == "TRANSLATE_TITLE") != -1) {
            this.widgetTitle = newprops[newprops.findIndex(x => x.propID == "TRANSLATE_TITLE")].propValue;
        }


        
    }

    onPropsChangedLocalStorage() {
        
        //Common Properties
        try {
            localStorage.setItem(this.widgetID + "_displayType", JSON.stringify(this.displayType));
        }
        catch (err) {
            console.log(err);
        }

        try {
            localStorage.setItem(this.widgetID + "_translateTitle", JSON.stringify(this.widgetTitle));
        }
        catch (err) {
            console.log(err);
        }

        //Required Mobile Properties
        try {
            localStorage.setItem(this.widgetID + "_mobileSequence", JSON.stringify(this.mobileSequence));
        }
        catch (err) {
            console.log(err);
        }
        try {
            localStorage.setItem(this.widgetID + "_displayLocation", JSON.stringify(this.displayLocation));
        }
        catch (err) {
            console.log(err);
        }
    }

    onResize() {
        this.calcWidgetRenderSize();
    }

    onWidgetResized() {

        this.calcWidgetRenderSize();
    }

    onWidgetMoved(x: number, y: number, selectedlayout:string) {
        this.overlayDisplayClass = "widget-overlay-style-page";
        this.overlayDisplayLinkClass = "";
        if ((selectedlayout == "left" || selectedlayout == "both") && x < 2) {
            this.overlayDisplayClass = "widget-overlay-style-left";
            this.overlayDisplayLinkClass = "widget-overlay-style-link-left";
        }
        else if ((selectedlayout == "right" || selectedlayout == "both") && x > 9) {
            this.overlayDisplayClass = "widget-overlay-style-right";
            this.overlayDisplayLinkClass = "widget-overlay-style-link-right";
        }
    }

    calcWidgetRenderSize() {
        let actualWidth = this.widgetContainer.calcWidgetWidth(this.widgetWidth);
        if (actualWidth < 300) {
            this.widgetRenderSize = "xs";
        }
        else if (actualWidth < 600) {
            this.widgetRenderSize = "sm";
        }
        else if (actualWidth < 900) {
            this.widgetRenderSize = "md";
        }
        else {
            this.widgetRenderSize = "lg";
        }
    }

    removeWidget(id: string) {

        this.widgetContainer.widgetsToDelete.push(id);
        this.widgetContainer.containerInfo.widgets.splice(this.widgetContainer.containerInfo.widgets.findIndex(x => x.id == id), 1);
        this.widgetContainer.containerWidgets.splice(this.widgetContainer.containerWidgets.findIndex(x => x.id == id), 1);

        if (window.location.href.includes("dashboard") || window.location.href.includes("workcenter")) {
            this.pinnacleservice.removeWidgetFromContainer(id).subscribe(res => { }, err => { console.log(err); });
        }
    }

    createProperties() {
            //let w: WidgetProperties = new WidgetProperties();
            //w.propID = "WIDTH";
            //w.propName = "Width";
            //w.propType = "number";
            //w.propValue = this.widgetWidth.toString();

            //this.widgetProps.push(w);

            //let h: WidgetProperties = new WidgetProperties();
            //h.propID = "HEIGHT";
            //h.propName = "Height";
            //h.propType = "number";
            //h.propValue = this.widgetHeight.toString();

            //this.widgetProps.push(h);

    }

    widgetArePropertiesLoaded() {
        if (window.location.href.includes("#/home")){
            this.widgetContainer.widgetsHaveLoadedProperties();
        }
    }

    widgetMobileHeightCalucaled() {
        if (window.location.href.includes("#/home")){
            this.widgetContainer.widgetMobileHeightCal();
        }
    }

    createPropertiesDisplayType(defaultOptionValue: string = "") {
        if (defaultOptionValue != "") {
            this.displayType = defaultOptionValue;
        }

        let displayType = new WidgetProperties();
        let option1: WidgetPropertyOptions = new WidgetPropertyOptions();
        option1.opName = "Panel";
        option1.opValue = "panel";
        displayType.propOptions.push(option1);

        let option2: WidgetPropertyOptions = new WidgetPropertyOptions();
        option2.opName = "Overlay";
        option2.opValue = "overlay";
        displayType.propOptions.push(option2);


        displayType.propName = "Display Style"
        displayType.propValue = this.displayType;
        displayType.propType = "select";
        displayType.propID = "DISPLAY_TYPE";
        this.widgetProps.push(displayType);
    }

    createPropertiesWidgetTitle(defaultValue: string = "") {
        let displayTitle = new WidgetProperties();
        displayTitle.propName = "Title";
        displayTitle.propValue = this.widgetTitle;
        displayTitle.propType = "string";
        displayTitle.propID = "TRANSLATE_TITLE";

        if (defaultValue != "") {
            displayTitle.propDefaultValue = defaultValue;
        }
        this.widgetProps.push(displayTitle);
    }

    createPropertiesMobileOptions() {
        //Mobile Label Section
        let mLabelSection = new WidgetProperties();
        mLabelSection.propName = "Mobile Label Section";
        mLabelSection.propValue = "Mobile Configuration Options";
        mLabelSection.propType = "dividerLabel";
        mLabelSection.propID = "MOBILE_LABEL_SECTION";
        this.widgetProps.push(mLabelSection);

        //Mobile Sequence
        let mSortOrder = new WidgetProperties();
        mSortOrder.propName = "Display & Sequence";
        mSortOrder.propValue = this.mobileSequence;
        mSortOrder.propType = "mobileSequence";
        mSortOrder.propID = "MOBILE_SEQUENCE";
        this.widgetProps.push(mSortOrder);


        //Mobile Flyout Option
        let mobileDisplayLocationOption = new WidgetProperties();

        let wdlOption1: WidgetPropertyOptions = new WidgetPropertyOptions();
        wdlOption1.opName = "Primary View";
        wdlOption1.opValue = "primary";
        mobileDisplayLocationOption.propOptions.push(wdlOption1);

        let wdlOption2: WidgetPropertyOptions = new WidgetPropertyOptions();
        wdlOption2.opName = "Flyout View";
        wdlOption2.opValue = "flyout";
        mobileDisplayLocationOption.propOptions.push(wdlOption2);


        mobileDisplayLocationOption.propName = "Display Location"
        mobileDisplayLocationOption.propValue = this.displayLocation;
        mobileDisplayLocationOption.propType = "select";
        mobileDisplayLocationOption.propID = "DISPLAY_LOCATION";
        this.widgetProps.push(mobileDisplayLocationOption);

    }

}
