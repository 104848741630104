import { Component, OnInit, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { WidgetComponent } from '../../widget/widget.component';

import { WidgetProperties, WidgetPropertyOptions } from '../../../apiService/classFiles/class.users'; 
import { ProdGenApi } from '../../../apiService/prodgen.api';
import { Course, Content, ContentType_T, DescriptionFilter_T} from './../../../apiService/classFiles/class.content';
import { Enrollment, EnrollmentFilter_T, EnrollmentStatus_T} from './../../../apiService/classFiles/class.enrollments';

// TODO - Change the name of the selector and setup to use your widget html and css files
@Component({
  selector: 'related-learning-widget-component',
  templateUrl: './related-learning-widget.component.html',
    styleUrls: ['./related-learning-widget.component.css']
})


// TODO - Rename the component name and set the key variable to be the string representation of the component name
export class RelatedLearningWidgetComponent extends WidgetComponent implements OnInit, AfterViewInit {
    static key = "RelatedLearningWidgetComponent";     // this key must be set to the name of the Angular component
    relatedCourses: Array<Content> = new Array<Content>();
    classList: string = "";

    widgetLoaded: boolean = false;
    

    constructor(private pinnacleService: ProdGenApi, private changedetectorref: ChangeDetectorRef) {
        // call the base Widget class
        super(pinnacleService, changedetectorref);

        // set defaults for your height and width.
        // these are logical units of the container
        // container is 12 units across and 1 unit of height is ~25px
        this.widgetWidth = 2;
        this.widgetHeight = 4;
        this.widgetTitle = "Related Learning";

        // define the name and description for the Widget.
        // these will be used by the Widget Library
        this.widgetName = "Related Learning"
        this.widgetDesc = "Display additional learning of interest based on courses in which you are currently enrolled."; 
    }

    ngOnInit() {
        // call the base Widget class
        super.ngOnInit();

        // create widget specific properties that can be configured
        this.createProperties();

        // load the data needed for the widget
        this.Load(); 
    }

    ngAfterViewInit() {
        this.widgetContainer.incrementRenderedWidget();
    }

    Load() {
        // call the base Widget class
        super.Load();

        this.classList = this.getClassList();

        this.loadRelatedItems();
    }

    loadRelatedItems() {
        this.pinnacleService.getCurrentUserEnrollments([EnrollmentFilter_T.assigned, EnrollmentFilter_T.inProgress]).subscribe(res => {
            for (var i = 0; i < res.length; i++) {
                if (res[i].imageURL == null || res[i].imageURL == "") {
                    res[i].imageURL = "../assets/images/default_course.jpg";
                }

                // get related items for the course
                if (i < 4) {
                    this.pinnacleService.GetContentRelatedItems(res[i].courseId, "course", -1, 0, DescriptionFilter_T.unformatted).subscribe(rel => {
                        for (let c of rel) {
                            if (this.relatedCourses.length < 9) {
                                this.relatedCourses.push(c);
                            }
                        }
                    });
                }
            }
        });
    }


    onPropsChanged(newprops: Array<WidgetProperties>, writeSettings: boolean = false, doresequence = true) {
        super.onPropsChanged(newprops, writeSettings, doresequence);

        

        // write the new properties to the database
        if (writeSettings) {
            this.pinnacleService.setWidgetProperties(this.widgetContainer.containerInfo.containerInstanceId, this.widgetID, newprops, RelatedLearningWidgetComponent.key).subscribe(res => {/*return stuff. might not need to do anything here*/ });
        }
        super.onPropsChangedLocalStorage();

        // if there are other properties we care about, retrieve them here
        // do the work this widget needs to do based on the changed properties (set values for bound variables, etc.)
        this.widgetLoaded = true;
        super.widgetArePropertiesLoaded();

    }



    createProperties() {

        // call widget base class
        super.createProperties();

        super.createPropertiesDisplayType();

        super.createPropertiesWidgetTitle("Related Learning");

        super.createPropertiesMobileOptions();
    }

    //END WIDGET REQUIRED CALLS
    onWidgetResized() {
        super.onWidgetResized();
        this.classList = this.getClassList();
    }

    onResize() {
        super.onResize();
        this.classList = this.getClassList();
    }

    getContentImage(r: Content): string {
        let image: string = "";

        image = "assets/images/";

        let img = ProdGenApi.getLargeDefaultImageFromType(r.contentType);

        if (img.indexOf("default") == -1) {
            image = img;
        }
        else {
            image += img;

        }


        return image;
    }

    getContentUrl(r: Content): string {
        let url: string = "#";
        if (r.contentType == ContentType_T.workflow) {
            url += "/workflowviewer?id=" + r.contentId + "&contentType=workflow";
        }
        else if (r.contentType == ContentType_T.process) {
            url += "/workflowviewer?id=" + r.contentId + "&contentType=process";
        }
        else if (r.contentType == ContentType_T.task) {
            url += "/workflowviewer?id=" + r.contentId + "&contentType=task";
        }
        else if (r.contentType == ContentType_T.step) {
            url += "/workflowviewer?id=" + r.contentId + "&contentType=step";
        }
        else if (r.contentType == ContentType_T.cheatsheet) {
            url += "/cheatsheetviewer?id=" + r.contentId;
        }
        else if (r.contentType == ContentType_T.video) {
            url += "/videoviewer?id=" + r.contentId;
        }
        else if (r.contentType == ContentType_T.learningpath) {
            url += "/learningcenter/series?learningPathId=" + r.contentId;
        }
        else if (r.contentType == ContentType_T.course) {
            url += "/learningcenter/series?courseId=" + r.contentId;
        }
        return url;
    }

    getClassList(): string {
        let classes: string = "col-xs-12 col-sm-4 col-md-4 col-lg-4 ";

        if (this.widgetRenderSize == "xs") {
            classes = "col-xs-12";
        }
        else if (this.widgetRenderSize == "sm") {
            classes = "col-xs-12";
        }
        else if (this.widgetRenderSize == "md") {
            classes = "col-xs-6";
        }
        else if (this.widgetRenderSize == "lg") {
            classes = "col-xs-4";
        }
        return classes;
    }


}
