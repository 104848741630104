import { Component, HostListener, ChangeDetectorRef, Input, Output, EventEmitter } from '@angular/core';
import { OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { ProdGenApi } from './../../apiService/prodgen.api';
import {
    Content,
    ContentType_T,
    Process,
    Workflow,
    DescriptionFilter_T,
    DiagramObject,
    DiagramPoint,
    DiagramLine,
    DiagramView,
    DiagramAlignHorz_T,
    DiagramAlignVert_T,
    DiagramTextSize_T,
    DiagramTextOrient_T
} from "./../../apiService/classFiles/class.content"
import { Text } from '@angular/compiler/src/i18n/i18n_ast';
import { Enrollment } from '../../apiService/classFiles/class.enrollments';
import { DiagramTreeService } from './../../services/diagram-tree.service';
import { Subscription } from 'rxjs';

declare function roundPathCorners(pathString: string, radius: number, useFractionalRadius: boolean): string;
declare var $: any;

@Component({
  selector: 'diagram-view',
  templateUrl: './diagram-view.component.html',
  styleUrls: ['./diagram-view.component.css'],
    providers: [ProdGenApi]
})

export class DiagramViewComponent implements OnInit {
    readonly DiagramTextOrient_T = DiagramTextOrient_T;
  
    @Input('diagramType') diagramType: string;
    @Input('diagramId') diagramId: string;
  @Input('initialSelectionId') initialSelectionId: string;
  @Input('initiateStepSelection') initialStepId: string;
    @Input('enrollmentId') enrollmentId?: string;
    @Input('workflowId') workflowId: string;
    @Input('isVisible') isVisible: boolean;
  @Output('workflowTreeItems') workflowTreeItems = new EventEmitter<any>();
  @Output('hierarchySelection') treeSelectedId = new EventEmitter<any>();
  //@Output('viewDiagram') viewDiagram = new EventEmitter<any>();
    @Output('selectionChanged') selectionChanged = new EventEmitter<any>();
    selection: Content = new Content();


    MENU_IMAGE_WIDTH: number = 50;
    MENU_IMAGE_HEIGHT: number = 50; 
    MENU_IMAGE_OFFSETX: number = 0;
    MENU_IMAGE_OFFSETY: number = 0;

  selectedItem: DiagramObject = new DiagramObject();
  selecedWFItem: DiagramMenu = new DiagramMenu();
    diagramView: DiagramView = new DiagramView();
    extentsTL: DiagramPoint = new DiagramPoint();
    extentsBR: DiagramPoint = new DiagramPoint();
    menuImageX: number = this.MENU_IMAGE_OFFSETX;
    menuImageY: number = this.MENU_IMAGE_OFFSETY;
    menuImageWidth: number = this.MENU_IMAGE_WIDTH;
  menuImageHeight: number = this.MENU_IMAGE_HEIGHT;

   workflowItems: DiagramMenu = new DiagramMenu();


    viewBoxText: string = "-300 -300 1800 1800";
    panRate: number = 20;
    zoomRate: number = 1.1;

    mouseDown: boolean = false;
    mouseDownX: number = 0;
    mouseDownY: number = 0;

    touchDist: number = 1;

  isDragging: boolean = false;
    WFId: String;

    wrappedNames: Map<string, Array<string>> = new Map<string, Array<string>>();
    wrappedAssigned: Map<string, Array<string>> = new Map<string, Array<string>>();
    errorMessage: String;
  parentworkflowItems: Array<HierarchyList>;
  subscription: Subscription;
  //view: DiagramView = new DiagramView();

  constructor(private pinnacleService: ProdGenApi, private cdr: ChangeDetectorRef, private route: ActivatedRoute, private router: Router, private diagramTreeInteractionService: DiagramTreeService) { }

  ngOnInit() {
      //this.getDiagramView(this.diagramId, this.diagramType);
      // also, get list of processes
      //this.getWorkflowItems(this.diagramId);
    
  }

  ngOnChanges(changes: SimpleChanges) {


    try { this.diagramId = changes['diagramId'].currentValue } catch (err) { };
    try { this.diagramType = changes['diagramType'].currentValue } catch (err) { };
    try { this.initialSelectionId = changes['initialSelectionId'].currentValue } catch (err) { };
      try { this.initialStepId = changes['initiateStepSelection'].currentValue } catch (err) { };
      try { this.isVisible = changes['isVisible'].currentValue } catch (err) { };

    if ((this.diagramType != ContentType_T.task.toString()) && (this.diagramType != ContentType_T.step.toString())) {
      this.getDiagramView(this.diagramId, this.diagramType);
    }
    else {
      this.selectedObject(this.initialSelectionId, this.diagramView)
    }

    
    // also, get list of processes
    //this.getWorkflowItems(this.diagramId);

    if (this.enrollmentId != null) {
      //this.subscription = this.diagramTreeInteractionService.selectContent$.subscribe(content => {
      //  this.selection = content;

      //  this.selectionChanged.emit({
      //    selectedItem: this.selection
      //  })
      //});
        this.selection = this.diagramTreeInteractionService.GetSelectContent();
      if (Object.keys(this.selection).length) {
      //  this.selection.contentId = this.diagramId;

      //  if (this.diagramType == "5" || this.diagramType == "workflow") {
      //    this.selection.contentType = ContentType_T.workflow;
      //  }
      //  else if (this.diagramType == "6" || this.diagramType == "process") {
      //    this.selection.contentType = ContentType_T.process;
      //  }
        this.selectionChanged.emit({
          selectedItem: this.selection
        });
      }
        
    }

  }

  //ngOnDestroy() {
  //  // prevent memory leak when component destroyed
  //  this.subscription.unsubscribe();
  //}
  

  getWorkflowItems(id: string): void {
      if (id == null || id.length == 0)
      {
          return;
      }
      if (this.enrollmentId == null) {
        if (this.diagramType == "workflow") {
              this.WFId = id;
              // get the hierarchy
              //this.pinnacleService.GetWorkflowHierarchy(id).subscribe(res => {
              //  this.startSpinner();
              //  this.createNestedList(res);
              //  this.endSpinner();
              //});
              // get the name of the workflow
              this.pinnacleService.GetWorkflow(id).subscribe(w => {
                  // get the processes
                  this.pinnacleService.GetWorkflowProcesses(id,-1,0, DescriptionFilter_T.none).subscribe(p => {
                      this.workflowItems = new DiagramMenu();
                      this.workflowItems.name = w.name as string;
                      this.workflowItems.contentId = w.workflowId as string;
                      this.workflowItems.children = new Array<DiagramMenu>();
                      for (var i = 0; i < p.length; i++) {
                          let process = new DiagramMenu();
                          process.name = (i + 1) + ".0 - " + p[i].name as string;
                          process.contentId = p[i].processId as string;

                          this.workflowItems.children.push(process);
                      }
                  });


              });
          }
          else {
          this.pinnacleService.GetProcess(id).subscribe(op => {
                  this.WFId = op.workflowId;
                  // get the hierarchy
                  //this.pinnacleService.GetWorkflowHierarchy(op.workflowId).subscribe(res => {
                  //  this.startSpinner();
                  //  this.createNestedList(res);
                  //  this.endSpinner();
                  //});
                  // get the name of the workflow
                  this.pinnacleService.GetWorkflow(op.workflowId).subscribe(w => {
                      // get the processes
                      this.pinnacleService.GetWorkflowProcesses(op.workflowId).subscribe(p => {
                          this.workflowItems = new DiagramMenu();
                          this.workflowItems.name = w.name as string;
                          this.workflowItems.contentId = w.workflowId as string;
                          this.workflowItems.children = new Array<DiagramMenu>();
                          for (var i = 0; i < p.length; i++) {
                              let process = new DiagramMenu();
                              process.name = (i + 1) + ".0 - " + p[i].name as string;
                              process.contentId = p[i].processId as string;

                              this.workflowItems.children.push(process);
                          }
                      });


                  });
              });

          }
      }
      else
      {
          // tied to enrollment
          if (this.diagramType == "workflow") {
              // get the name of the workflow
              this.pinnacleService.getEnrollmentWorkflow(this.enrollmentId, id).subscribe(w => {
                  // get the processes
                  this.pinnacleService.getEnrollmentWorkflowProcesses(this.enrollmentId, id).subscribe(p => {
                      this.workflowItems = new DiagramMenu();
                      this.workflowItems.name = w.name as string;
                      this.workflowItems.contentId = w.workflowId as string;
                      this.workflowItems.children = new Array<DiagramMenu>();
                      for (var i = 0; i < p.length; i++) {
                          let process = new DiagramMenu();
                          process.name = (i + 1) + ".0 - " + p[i].name as string;
                          process.contentId = p[i].processId as string;

                          this.workflowItems.children.push(process);
                      }
                  });


              });
          }
          else {
              this.pinnacleService.getEnrollmentProcess(this.enrollmentId, id).subscribe(op => {
                  // get the name of the workflow
                  this.pinnacleService.getEnrollmentWorkflow(this.enrollmentId, op.workflowId).subscribe(w => {
                      // get the processes
                      this.pinnacleService.getEnrollmentWorkflowProcesses(this.enrollmentId, op.workflowId).subscribe(p => {
                          this.workflowItems = new DiagramMenu();
                          this.workflowItems.name = w.name as string;
                          this.workflowItems.contentId = w.workflowId as string;
                          this.workflowItems.children = new Array<DiagramMenu>();
                          for (var i = 0; i < p.length; i++) {
                              let process = new DiagramMenu();
                              process.name = (i + 1) + ".0 - " + p[i].name as string;
                              process.contentId = p[i].processId as string;

                              this.workflowItems.children.push(process);
                          }
                      });


                  });
              });

          }
      }       
  }

  createNestedList(hierarchyItems: HierarchyContent) {
    let parentWorkflow = new HierarchyList();
    this.parentworkflowItems = new Array<HierarchyList>();
    parentWorkflow.contentId = this.WFId.toString();
    parentWorkflow.name = hierarchyItems.Name;
    parentWorkflow.indent = 0;
      parentWorkflow.type = "Workflow";

    if (hierarchyItems.Children.length > 0)
        parentWorkflow.hasChildren = true;
    this.parentworkflowItems.push(parentWorkflow);
    let procChilds = hierarchyItems.Children;
    
    for (var j = 0; j < procChilds.length; j++) {
      let processContent = new HierarchyList();
      processContent.contentId = procChilds[j].ContentId;
      processContent.name = procChilds[j].Name;
      processContent.type = "Process";
      processContent.indent = 1;

      if (procChilds[j].Children.length > 0)
          processContent.hasChildren = true;
      this.parentworkflowItems.push(processContent);
      let taskChilds = procChilds[j].Children;
      
      for (var k = 0; k < taskChilds.length; k++) {
        let taskContent = new HierarchyList();
        taskContent.contentId = taskChilds[k].ContentId;
        taskContent.name = taskChilds[k].Name;
        taskContent.type = "Task";
        taskContent.indent = 2;

        if (taskChilds[k].Children.length > 0)
            taskContent.hasChildren = true;
        this.parentworkflowItems.push(taskContent);
        let stepChilds = taskChilds[k].Children;

        for (var i = 0; i < stepChilds.length; i++) {
          let stepContent = new HierarchyList();
          stepContent.name = stepChilds[i].Name;
          stepContent.type = "Step";
          stepContent.indent = 3;
          this.parentworkflowItems.push(stepContent);
        }
      }

    }


  }


  displayToggleResults(r: Array<HierarchyList>, index: number): string {
    var stringArr = new Array<string>();

    if (r[index].indent == 0) {
      for (var i = index + 1; i < r.length; i++) {
        stringArr.push("#tree" + i);
      }
      return stringArr.join();
    }

    if (r[index].indent == 1) {
      for (var i = index + 1; i < r.length; i++) {
        if (r[i].indent == 1)
          break;
        stringArr.push("#tree" + i);
      }
      return stringArr.join();
    }

    if (r[index].indent == 2) {
      for (var i = index + 1; i < r.length; i++) {
        if (r[i].indent == 2 || r[i].indent == 1)
          break;
        stringArr.push("#tree" + i);
      }
      return stringArr.join();
    }
  }

  startSpinner()
  {
      $('#spinner').css('display', 'inline');
  }

  endSpinner()
  {
      $('#spinner').css('display', 'none');

  }

  getDiagramView(id: string, diagram_type: string): void {
    
      if (id == null || id.length == 0)
      {
          return;
      }
      
      if (diagram_type == "workflow") {
          this.startSpinner();
          this.pinnacleService.GetWorkflowDiagram(id)
              .subscribe(view => {
                  this.diagramView = view;
                  this.prepareView();
                  this.endSpinner();
              });

      }
      else {
          this.startSpinner();
          this.pinnacleService.GetProcessDiagram(id)
              .subscribe(view => {
                  this.diagramView = view;
                  this.prepareView();
                  this.endSpinner();
              });

      }

  }

  prepareView(): void {
    let view: DiagramView = this.diagramView;
      this.wrappedNames.clear();
      this.wrappedAssigned.clear();

      //this.viewDiagram.emit({
      //  selectedItem: this.view
      //});
    
      // adjust the points and sizes

      for (var i = 0; i < view.objects.length; i++) {

          view.objects[i].basePtX *= 100.0;
          view.objects[i].basePtY *= 100.0;
          view.objects[i].sizeX *= 100.0;
          view.objects[i].sizeY *= 100.0;

        if (view.objects[i].contentId == this.initialSelectionId)
          {
            this.selectedItem = view.objects[i];
            this.selection.contentId = view.objects[i].contentId;
            this.selection.contentType = view.objects[i].contentType;

            


          }
          // store the wrapped name
        this.wrappedNames[view.objects[i].contentId] = this.getWrappedName(view.objects[i]);
        this.wrappedAssigned[view.objects[i].contentId] = this.getWrappedAssigned(view.objects[i]);


          if (i == 0) {
            this.extentsTL.x = view.objects[i].basePtX;
            this.extentsTL.y = view.objects[i].basePtY;
            this.extentsBR.x = view.objects[i].basePtX + view.objects[i].sizeX;
            this.extentsBR.y = view.objects[i].basePtY + view.objects[i].sizeY;;
          }
          else {
            if (view.objects[i].basePtX < this.extentsTL.x)
              this.extentsTL.x = view.objects[i].basePtX;
            if (view.objects[i].basePtY < this.extentsTL.y)
              this.extentsTL.y = view.objects[i].basePtY;
            if ((view.objects[i].basePtX + view.objects[i].sizeX) > this.extentsBR.x)
              this.extentsBR.x = view.objects[i].basePtX + view.objects[i].sizeX;
            if ((view.objects[i].basePtY + view.objects[i].sizeY) > this.extentsBR.y)
              this.extentsBR.y = view.objects[i].basePtY + view.objects[i].sizeY;
          }

      }

    for (var i = 0; i < view.lines.length; i++) {
      let curline: DiagramLine = view.lines[i];

          for (var j = 0; j < curline.arrowPoints.length; j++) {
              let p: DiagramPoint = curline.arrowPoints[j];
              p.x *= 100.0;
              p.y *= 100.0;
          }

          for (var j = 0; j < curline.linePoints.length; j++) {
              let p: DiagramPoint = curline.linePoints[j];
              p.x *= 100.0;
              p.y *= 100.0;

              //Labelpoints and labelAnchor will not come from server so we are setting it to default here.
              curline.labelPoints = new DiagramPoint();
              curline.labelAnchor = "start"; 
              this.setLabelPoint(curline);
        }
      }
      this.zoomExtents();
  }

  selectedObject(id: string, digView: DiagramView): void {

    for (var i = 0; i < digView.objects.length; i++) {
      if (digView.objects[i].contentId == id) {
          this.selectedItem = digView.objects[i];
          this.verifySelectionInView();

        break;
      }
      }
  }

  setLabelPoint(line: DiagramLine): void {
    if (line.label != "") {
      let LabelTop = 0;
      let LabelBottom = 1;
      let LabelLeft = 2;
      let LabelRight = 3;
      let nSide = LabelRight;

      // determine side
      if (Math.abs(line.linePoints[0].x - line.linePoints[1].x) < .0001) {
        // x's are the same, vertical
        if (line.linePoints[0].y > line.linePoints[1].y)
          nSide = LabelTop;
        else
          nSide = LabelBottom;
      }
      else {
        // y's are the same, horz
        if (line.linePoints[0].x > line.linePoints[1].x)
          nSide = LabelLeft;
        else
          nSide = LabelRight;
      }

      switch (nSide) {
        case LabelTop:
        case LabelRight:
          line.labelAnchor = "start";
          line.labelPoints.x = line.linePoints[0].x + 5;
          line.labelPoints.y = line.linePoints[0].y - line.label.length - 5;
          break;
        case LabelBottom:
          line.labelAnchor = "start";
          line.labelPoints.x = line.linePoints[0].x + 5;
          line.labelPoints.y = line.linePoints[0].y + 20;
          break;
        case LabelLeft:
          line.labelAnchor = "end";
          line.labelPoints.x = line.linePoints[0].x - 5;
          line.labelPoints.y = line.linePoints[0].y - line.label.length - 5;
          break;
        default:
          break;
      }
    }
    }

  getNameText(obj: DiagramObject): Array<string> {
      return this.wrappedNames[obj.contentId];
  }

  getAssignedToText(obj: DiagramObject): Array<string> {
      return this.wrappedAssigned[obj.contentId];
  }

  getWrappedName(obj: DiagramObject): Array<string> {
      let NameWrap: Array<string> = new Array<string>();

      // for word wrap, we need to do some work
      let full_name: string = obj.seqPrefix + obj.seq + " -" + obj.name;

      let parts: string[] = full_name.split(" ");
      let cur_string: string = "";
      for (var j = 0; j < parts.length; j++) {
          if (this.getWidthOfText(cur_string + " " + parts[j], "Arial", 9) > (obj.sizeX - 30)) {
              NameWrap.push(cur_string);
              cur_string = "";
              // check if the next item by itself is longer than we want
              if (this.getWidthOfText(cur_string, "Arial", 9) > (obj.sizeX - 30)) {
                  // if it is, just add that single item and go on to next line
                  NameWrap.push(parts[j]);
                  cur_string = "";
              }

          }
          if (j > 0)
              cur_string += " ";
          cur_string += parts[j];

      }
      NameWrap.push(cur_string);
      return NameWrap;
  }

    getWrappedAssigned(obj: DiagramObject): Array<string> {
        let NameWrap: Array<string> = new Array<string>();

        // for word wrap, we need to do some work
        let full_name: string = obj.assignedTo;

        let parts: string[] = full_name.split(" ");
        let cur_string: string = "";
        for (var j = 0; j < parts.length; j++) {
            if (this.getWidthOfText(cur_string + " " + parts[j], "Arial", 9) > (obj.sizeX - 30)) {
                NameWrap.push(cur_string);
                cur_string = "";
                // check if the next item by itself is longer than we want
                if (this.getWidthOfText(cur_string, "Arial", 9) > (obj.sizeX - 30)) {
                    // if it is, just add that single item and go on to next line
                    NameWrap.push(parts[j]);
                    cur_string = "";
                }

            }
            if (j > 0)
                cur_string += " ";
            cur_string += parts[j];

        }
        NameWrap.push(cur_string);
        return NameWrap;
    }

    clientPointToDiagramPoint(x: number, y: number): DiagramPoint {

        var svg:any = document.getElementById('diagramsvg');
        var pt = svg.createSVGPoint(), svgP;

        pt.x = x;
        pt.y = y;
        svgP = pt.matrixTransform(svg.getScreenCTM().inverse());

        let returnPt: DiagramPoint = new DiagramPoint();
        returnPt.x = svgP.x;
        returnPt.y = svgP.y;

        return returnPt;
    }

    selectedObjectClientTL(): DiagramPoint{
        let pt = new DiagramPoint;
        if (this.selectedItem != null && this.isVisible==true) {
            var obj: any = document.getElementById('object_' + this.selectedItem.contentId);
            if (obj != null) {
                pt.x = obj.getBoundingClientRect().left;
                pt.y = obj.getBoundingClientRect().top;
            }

        }

        return pt;

    }

    selectedObjectClientBR(): DiagramPoint {
        let pt = new DiagramPoint;
        this.selectedItem

        if (this.selectedItem != null && this.isVisible == true) {
            var obj: any = document.getElementById('object_' + this.selectedItem.contentId);
            if (obj != null) {
                pt.x = obj.getBoundingClientRect().right;
                pt.y = obj.getBoundingClientRect().bottom;
            }

        }
        return pt;
    }

    verifySelectionInView() {
        if (this.isVisible == true) {

            var svg = document.getElementById('diagramsvg');
            if (svg != null) {
                let clientRect = svg.getBoundingClientRect();

                if (this.selectedItem != null) {
                    let TL: DiagramPoint = this.selectedObjectClientTL();
                    let BR: DiagramPoint = this.selectedObjectClientBR();
                    // check that it is visible on screen. if not, center it.
                    if (TL.x < clientRect.left || BR.x > clientRect.right ||
                        TL.y < clientRect.top || BR.y > clientRect.bottom) {

                        let centerObjX = (TL.x + BR.x) * .5;
                        let centerObjY = (TL.y + BR.y) * .5;
                        let centerViewX = (clientRect.left + clientRect.right) * .5;
                        let centerViewY = (clientRect.top + clientRect.bottom) * .5;

                        // keep the same zoom level, but change the center
                        // determine how much to pan
                        let panX = centerViewX - centerObjX;
                        let panY = centerViewY - centerObjY;
                        this.customPanBy({ x: panX, y: panY });

                    }
                    else {

                    }

                }
            }
            

        }

        

    }

    setViewBox(x: number, y: number, width: number, height: number) {
        let svg: HTMLElement = document.getElementById('diagramsvg');

        //we don't want to set values for box if no diagram
        if (svg == null) {
            return false;
        };

      let zoomLevel: number = svg.clientWidth / width;
      let adjustedHeight = width / svg.clientWidth * svg.clientHeight;
      let adjustedY: number = y - ((adjustedHeight - height) / 2);

      this.viewBoxText = x + " " + y + " " + width + " " + height;
      this.menuImageX = x + (this.MENU_IMAGE_OFFSETX / zoomLevel);
      this.menuImageY = adjustedY + (this.MENU_IMAGE_OFFSETY / zoomLevel);
      this.menuImageWidth = (this.MENU_IMAGE_WIDTH / zoomLevel);
        this.menuImageHeight = (this.MENU_IMAGE_HEIGHT / zoomLevel);
  }

  public zoomExtents() {
      let sizeX: number = (this.extentsBR.x - this.extentsTL.x);
      let sizeY: number = (this.extentsBR.y - this.extentsTL.y);

      this.setViewBox((this.extentsTL.x - sizeX * .05), (this.extentsTL.y - sizeY * .05), sizeX * 1.1, sizeY * 1.1);
      //this.viewBoxText = (this.extentsTL.x - sizeX * .05) + " " + (this.extentsTL.y - sizeY * .05) + " " + sizeX * 1.1 + " " + sizeY * 1.1;
  }

  menuClick() {

  }

  clearSelection() {
      this.selectedItem = new DiagramObject();
      this.selection = new Content();
      this.selectionChanged.emit({
          selectedItem: this.selection
      });
  }

  objectClick(obj: DiagramObject, event) {
      if (this.isDragging == false) {
          this.selectedItem = obj;
          this.selection.contentId = obj.contentId;
          this.selection.contentType = obj.contentType;
          event.stopPropagation();

          if (this.diagramType != "workflow") {
              this.treeSelectedId.emit({
                  selectedItem: this.selection
              });
          }

          this.selectionChanged.emit({
              selectedItem: this.selection
          });

        if (this.enrollmentId != null) {
          this.diagramTreeInteractionService.SetObjectClickId(obj.contentId);
          if (this.diagramType != "workflow") {
            this.diagramTreeInteractionService.SetSelectContent(this.selection);
          }
        }

      }
  }

  isSelected(obj: DiagramObject): boolean {
    //this.selection.contentId = obj.contentId;
    //this.selection.contentType = ContentType_T.process;
    //this.selection.description = "task";

    

    
      return this.selectedItem.contentId == obj.contentId;
  }


  @HostListener('touchstart', ['$event'])
  public handleTouchStartt(event_orig: any): void {
      let event: TouchEvent = event_orig as TouchEvent;
      if (event.touches != null && event.touches.length == 1) {
          this.mouseDown = true;
          this.mouseDownX = event.touches[0].clientX;
          this.mouseDownY = event.touches[0].clientY;
      }
      else if (event.touches != null && event.touches.length == 2) {
          this.mouseDown = false;
          this.touchDist = Math.sqrt((event.touches[0].clientX - event.touches[1].clientX) * (event.touches[0].clientX - event.touches[1].clientX) +
              (event.touches[0].clientY - event.touches[1].clientY) * (event.touches[0].clientY - event.touches[1].clientY));
      }
  }
  @HostListener('mousedown', ['$event'])
  public handleMouseDownEvent(event: MouseEvent): void {

      this.mouseDown = true;
      this.mouseDownX = event.clientX;
      this.mouseDownY = event.clientY;
  }

  @HostListener('mouseup', ['$event'])
  @HostListener('touchend', ['$event'])
  @HostListener('touchcancel', ['$event'])
  public handleMouseUpEvent(event: MouseEvent): void {
      this.mouseDown = false;
      this.isDragging = false;

  }

  @HostListener('mousewheel', ['$event'])
  @HostListener('DOMMouseScroll', ['$event'])
  public handleMousWheelEvent(event: MouseWheelEvent): void {

      //if (event.shiftKey == false) {
      //    return;
      //}
      event.stopPropagation();
      event.preventDefault();

      let parts: string[] = this.viewBoxText.split(" ");
      let numparts: number[] = [+parts[0], +parts[1], +parts[2], +parts[3]];

      let prevWidth: number = numparts[2];
      let prevHeight: number = numparts[3];
      if (event.deltaY > 0 || event.detail < 0) {
          // zoom in
          numparts[2] /= this.zoomRate;
          numparts[3] /= this.zoomRate;

      }
      else if (event.deltaY < 0 || event.detail > 0) {
          // zoom in
          numparts[2] *= this.zoomRate;
          numparts[3] *= this.zoomRate;

      }

      // since we want the zoom to happen from the center of the viewbox, change the x and y also
      numparts[0] += (prevWidth - numparts[2]) * .5;
      numparts[1] += (prevHeight - numparts[3]) * .5;

      this.mouseDownX = event.clientX;
      this.mouseDownY = event.clientY;
      //        this.viewBoxText = numparts.join(" ");
      this.setViewBox(numparts[0], numparts[1], numparts[2], numparts[3]);

  }

  @HostListener('touchmove', ['$event'])
  public handleTouchEvent(event_orig: any): void {
      let event: TouchEvent = event_orig as TouchEvent;
      if (event.touches != null && event.touches.length == 1) {
          if (this.mouseDown == true && ((this.mouseDownX != event.touches[0].clientX) || (this.mouseDownY != event.touches[0].clientY))) {
              event.stopPropagation();
              event.preventDefault();

              this.isDragging = true;
              let parts: string[] = this.viewBoxText.split(" ");
              let numparts: number[] = [+parts[0], +parts[1], +parts[2], +parts[3]];

              var svg = document.getElementById('diagramsvg');
            let zoomLevel: number = svg.clientWidth / numparts[2];

            if (zoomLevel == 0) {
              zoomLevel = 1;
            }
              if (numparts[2] < numparts[3]) {
                  zoomLevel = svg.clientHeight / numparts[3];
              }


              numparts[0] -= (event.touches[0].clientX - this.mouseDownX) / zoomLevel;
              numparts[1] -= (event.touches[0].clientY - this.mouseDownY) / zoomLevel;

              this.mouseDownX = event.touches[0].clientX;
              this.mouseDownY = event.touches[0].clientY;
              //this.viewBoxText = numparts.join(" ");
              this.setViewBox(numparts[0], numparts[1], numparts[2], numparts[3]);

          }
      }
      else if (event.touches != null && event.touches.length == 2) {
          event.stopPropagation();
          event.preventDefault();

          let parts: string[] = this.viewBoxText.split(" ");
          let numparts: number[] = [+parts[0], +parts[1], +parts[2], +parts[3]];

          let prevWidth: number = numparts[2];
          let prevHeight: number = numparts[3];

          let dist: number = Math.sqrt((event.touches[0].clientX - event.touches[1].clientX) * (event.touches[0].clientX - event.touches[1].clientX) +
              (event.touches[0].clientY - event.touches[1].clientY) * (event.touches[0].clientY - event.touches[1].clientY));

          numparts[2] *= this.touchDist / dist;
          numparts[3] *= this.touchDist / dist;

          // since we want the zoom to happen from the center of the viewbox, change the x and y also
          numparts[0] += (prevWidth - numparts[2]) * .5;
          numparts[1] += (prevHeight - numparts[3]) * .5;



          //if (((this.touchDist > dist)))
          //{
          //    // zoom in
          //    numparts[2] *= this.zoomRate;
          //    numparts[3] *= this.zoomRate;
          //}
          //else
          //{
          //    // zoom in
          //    numparts[2] /= this.zoomRate;
          //    numparts[3] /= this.zoomRate;

          //}
          this.touchDist = dist;
          this.setViewBox(numparts[0], numparts[1], numparts[2], numparts[3]);
      }
  }

  @HostListener('document:mousemove', ['$event'])
  public handleMouseMoveEvent(event: MouseEvent): void {
      if (this.mouseDown && ((this.mouseDownX != event.clientX) || (this.mouseDownY != event.clientY))) {
          event.stopPropagation();
          event.preventDefault();

          this.isDragging = true;
          let parts: string[] = this.viewBoxText.split(" ");
          let numparts: number[] = [+parts[0], +parts[1], +parts[2], +parts[3]];

          var svg = document.getElementById('diagramsvg');
        let zoomLevel: number = svg.clientWidth / numparts[2];

        if (zoomLevel == 0) {
          zoomLevel = 1;
        }
          if (numparts[2] < numparts[3]) {
              zoomLevel = svg.clientHeight / numparts[3];
          }


          numparts[0] -= (event.clientX - this.mouseDownX) / zoomLevel;
          numparts[1] -= (event.clientY - this.mouseDownY) / zoomLevel;

          this.mouseDownX = event.clientX;
          this.mouseDownY = event.clientY;
          //this.viewBoxText = numparts.join(" ");
          this.setViewBox(numparts[0], numparts[1], numparts[2], numparts[3]);

      }
  }



  diagramPositionX(): number {
      return this.MENU_IMAGE_OFFSETX;
  }

  diagramPositionY(): number {
      var svg = document.getElementById('diagramsvg');
      return -svg.clientHeight + this.MENU_IMAGE_OFFSETY;
  }



  getWidthOfText(txt: string, fontname: string, fontsize: number): number {
      // Create a dummy canvas (render invisible with css)
      var c = document.createElement('canvas');
      // Get the context of the dummy canvas
      var ctx = c.getContext('2d');
      // Set the context.font to the font that you are using
      ctx.font = fontsize + fontname;
      // Measure the string 
      // !!! <CRUCIAL>  !!!
      var length = ctx.measureText(txt).width;
      // !!! </CRUCIAL> !!!
      // Return width
      return length;
  }

    getSwimlaneTextAnchor(obj: DiagramObject): string {
      if (obj.textOrient === DiagramTextOrient_T.vertical) {
        switch (obj.textAlignVert) {
          case DiagramAlignVert_T.top:
            return 'end';
          case DiagramAlignVert_T.center:
            return 'middle';
          case DiagramAlignVert_T.bottom:
            return 'start';
        }
      } else {
        switch (obj.textAlignHorz) {
          case DiagramAlignHorz_T.left:
            return "start";
          case DiagramAlignHorz_T.center:
            return "middle";
          case DiagramAlignHorz_T.right:
            return "end";

        }
      }
  }

  getSwimlaneTextBaseline(obj: DiagramObject): string {
    if (obj.textOrient === DiagramTextOrient_T.vertical) {
      switch (obj.textAlignHorz) {
        case DiagramAlignHorz_T.left:
          return 'text-before-edge';
        case DiagramAlignHorz_T.center:
          return 'middle';
        case DiagramAlignHorz_T.right:
          return 'text-after-edge';
      }
    } else {
      return 'middle';
    }
  }

  getSwimlaneTextPtY(obj: DiagramObject): number {
      if (obj.textOrient === DiagramTextOrient_T.vertical) {
          switch (obj.textAlignVert) {
              case DiagramAlignVert_T.top:
                  return obj.basePtY + (this.getSwimlaneTextSizeNumber(obj) * 1.25);
              case DiagramAlignVert_T.center:
                  return obj.basePtY + (obj.sizeY / 2);
              case DiagramAlignVert_T.bottom:
                  return obj.basePtY + obj.sizeY - (this.getSwimlaneTextSizeNumber(obj) * 1.25);
          }
      }
      else {
          switch (obj.textAlignVert) {
              case DiagramAlignVert_T.top:
                  return obj.basePtY + (this.getSwimlaneTextSizeNumber(obj) * 1.25);
              case DiagramAlignVert_T.center:
                  return obj.basePtY + (obj.sizeY / 2) - (this.getSwimlaneTextSizeNumber(obj) * 0.25);
              case DiagramAlignVert_T.bottom:
                  return obj.basePtY + obj.sizeY - (this.getSwimlaneTextSizeNumber(obj) * 1.25);
          }
      }
  }

    getSwimlaneTextPtX(obj: DiagramObject): number {
        if (obj.textOrient === DiagramTextOrient_T.vertical) {
            switch (obj.textAlignHorz) {
                case DiagramAlignHorz_T.left:
                    return obj.basePtX + (this.getSwimlaneTextSizeNumber(obj) * 0.25);
                case DiagramAlignHorz_T.center:
                    return obj.basePtX + (obj.sizeX / 2);
                case DiagramAlignHorz_T.right:
                    return obj.basePtX + obj.sizeX - (this.getSwimlaneTextSizeNumber(obj) * 0.75);
            }
        }
        else {
            switch (obj.textAlignHorz) {
                case DiagramAlignHorz_T.left:
                    return obj.basePtX + (this.getSwimlaneTextSizeNumber(obj) * .25);
                case DiagramAlignHorz_T.center:
                    return obj.basePtX + (obj.sizeX / 2);
                case DiagramAlignHorz_T.right:
                    return obj.basePtX + obj.sizeX - (this.getSwimlaneTextSizeNumber(obj) * .25);
            }
        }
    }

    public getSwimlaneTextSizeNumber(obj: DiagramObject): number {
        switch (obj.textSize) {
            case DiagramTextSize_T.small:
                return 24;
            case DiagramTextSize_T.medium:
                return 32;
            case DiagramTextSize_T.large:
                return 54;
            case DiagramTextSize_T.largest:
                return 72;
            default:
                return 32;
        }
    }


    getSwimlaneTextSize(obj: DiagramObject): string {
      return this.getSwimlaneTextSizeNumber(obj) + "pt";      
  }

  isTask(obj: DiagramObject): boolean {
      return obj.contentType == ContentType_T.task && obj.isMilestone == false;
  }

  isMilestone(obj: DiagramObject): boolean {
      return obj.contentType == ContentType_T.task && obj.isMilestone == true;
  }

  isProcess(obj: DiagramObject): boolean {
      return obj.contentType == ContentType_T.process;
  }

    onWFClick(wf: DiagramMenu, event) {
      this.getDiagramView(wf.contentId, "workflow");

      this.selection.contentId = wf.contentId;
      this.selection.contentType = ContentType_T.workflow;
      this.selection.description = "workflow";

      this.diagramId = wf.contentId;
    this.diagramType = "workflow";

    this.treeSelectedId.emit({
      selectedItem: this.selection
    });

      this.selectionChanged.emit({
          selectedItem: this.selection
      });

  }

  onProcessClick(pr: DiagramMenu, event) {
      this.getDiagramView(pr.contentId, "process");

      this.selection.contentId = pr.contentId;
      this.selection.contentType = ContentType_T.process;
      this.selection.description = "process";

      this.diagramId = pr.contentId;
      this.diagramType = "process";

    this.treeSelectedId.emit({
      selectedItem: this.selection
    });
      this.selectionChanged.emit({
          selectedItem: this.selection
      });
  }

  //onTreeClick(item: HierarchyList, event) {

  //  let diagramTypeDesc: string = "";
  //  let ItemType: ContentType_T;

  //  if (item.type == "Workflow") {
  //    diagramTypeDesc = "workflow";
  //    ItemType = ContentType_T.workflow;
  //  }

  //  else if (item.type == "Process") {
  //    diagramTypeDesc = "process";
  //    ItemType = ContentType_T.process;
  //  }

  //  else if (item.type == "Task") {
  //    diagramTypeDesc = "task";
  //    ItemType = ContentType_T.task;
  //  }

  //  else if (item.type == "Step") {
  //    diagramTypeDesc = "step";
  //    ItemType = ContentType_T.task;
  //  }
      

  //  if (ItemType == ContentType_T.workflow || ItemType == ContentType_T.process) 
  //      this.getDiagramView(item.contentId, diagramTypeDesc);

  //  this.selection.contentId = item.contentId;
  //  this.selection.contentType = ItemType;
  //  this.selection.description = diagramTypeDesc;

  //  this.diagramId = item.contentId;
  //  this.diagramType = diagramTypeDesc;

  //  this.selectionChanged.emit({
  //    selectedItem: this.selection
  //  }); 
  //}

  decisionGeom(obj: DiagramObject): string {
      let geom: string = "";
      geom = obj.basePtX + "," + (obj.basePtY + obj.sizeY / 2) + "   " +
          (obj.basePtX + obj.sizeX / 2) + "," + obj.basePtY + "   " +
          (obj.basePtX + obj.sizeX) + "," + (obj.basePtY + obj.sizeY / 2) + "   " +
          (obj.basePtX + obj.sizeX / 2) + "," + (obj.basePtY + obj.sizeY) + "   ";
      return geom;
  }

  processGeom(obj: DiagramObject): string {
      let geom: string = "";
      let r: number = 15.0;
      let left = obj.basePtX;
      let right = obj.basePtX + obj.sizeX;
      let top = obj.basePtY + obj.sizeY;
      let bottom = obj.basePtY;

      geom = (left + r) + "," + top + "   " +
          (right - r) + "," + top + "   " +
          (right) + "," + (top - r) + "   " +
          (right) + "," + (bottom + r) + "   " +
          (right - r) + "," + (bottom) + "   " +
          (left + r) + "," + (bottom) + "   " +
          (left) + "," + (bottom + r) + "   " +
          (left) + "," + (top - r) + "   ";

      return geom;
  }

  lineGeom(line: DiagramLine): string {
      let geom: string = "";

      for (var i = 0; i < line.linePoints.length; i++) {
          if (i == 0) {
              geom = "M " + line.linePoints[i].x + " " + line.linePoints[i].y + " ";
          }
          else {
              geom += "L " + line.linePoints[i].x + " " + line.linePoints[i].y + " ";
          }
      }

      return roundPathCorners(geom, 10, false);
  }

  arrowGeom(line: DiagramLine): string {
      let geom: string = "";

      for (var i = 0; i < line.arrowPoints.length; i++) {
          geom += line.arrowPoints[i].x + ", " + line.arrowPoints[i].y + "  ";
      }

      return geom;
  }
  toColor(num: number) {
      num >>>= 0;
      var r = num & 0xFF,
          g = (num & 0xFF00) >>> 8,
          b = (num & 0xFF0000) >>> 16,
          a = ((num & 0xFF000000) >>> 24) / 255;
      return "#" + this.rgb2hex(r, g, b);
  }

  toRGBColor(num: number) {
      num >>>= 0;
      var r = num & 0xFF,
          g = (num & 0xFF00) >>> 8,
          b = (num & 0xFF0000) >>> 16,
          a = ((num & 0xFF000000) >>> 24) / 255;
      return "rgb(" + r + "," + g + "," + b + ")";
  }

  gradientStopColor(color: number): string {
      let str: string = "";
      str = "'stop-color':" + this.toRGBColor(color);
      return str;
  }


  rgb2hex(r: number, g: number, b: number): string {
      if (g !== undefined)
          return Number(0x1000000 + r * 0x10000 + g * 0x100 + b).toString(16).substring(1);
      else
          return Number(0x1000000 + r[0] * 0x10000 + r[1] * 0x100 + r[2]).toString(16).substring(1);
  }

  objectTextHTML(task: DiagramObject): string {
      let strText: string = "";
      strText = `
        <text dominant- baseline="middle" text- anchor="middle" attr.y = "` + task.basePtY + (task.sizeY / 2) + `"
        style = "width:150px; font-size:20px; font-family:Georgia, serif; fill:green;" >
            <tspan  attr.x="` + task.basePtX + (task.sizeX / 2) + `" > ` + task.name + `
    }</tspan>
        < tspan attr.x = "` + task.basePtX + (task.sizeX / 2) + `" dy= "16" > Line 2< /tspan>
            < /text>`;
      return strText;
  }

    getWfView() {
        if (this.workflowId != "" && this.workflowId != null) {
            this.getDiagramView(this.workflowId.toString(), "workflow");
            this.selection.contentId = this.workflowId.toString();
            this.selection.contentType = ContentType_T.workflow;
            this.selection.description = "workflow";

            this.diagramId = this.workflowId;
            this.diagramType = "workflow";

            this.treeSelectedId.emit({
                selectedItem: this.selection
            });

            this.selectionChanged.emit({
                selectedItem: this.selection
            });
        }
    }

    objectDblClick(obj: DiagramObject, event) {
        if (this.diagramType == "workflow" && this.isDragging == false) {
            this.selectedItem = obj;
            this.selection.contentId = obj.contentId;
            this.selection.contentType = obj.contentType;
            event.stopPropagation();

            this.getDiagramView(obj.contentId, "process");

            this.selection.contentId = obj.contentId;
            this.selection.contentType = ContentType_T.process;
            this.selection.description = "process";

            this.diagramId = obj.contentId;
            this.diagramType = "process";

            this.treeSelectedId.emit({
                selectedItem: this.selection
            });

            this.selectionChanged.emit({
                selectedItem: this.selection
            });

            if (this.enrollmentId != null) {
                this.diagramTreeInteractionService.SetObjectClickId(obj.contentId);
            }
        }
    }

  customPanBy(amount) { // {x: 1, y: 2}
    var animationTime = 500 // ms
        , animationStepTime = 20 // one frame per 30 ms
        , animationSteps = animationTime / animationStepTime
        , animationStep = 0
        , intervalID = null
        , stepX = amount.x / animationSteps
        , stepY = amount.y / animationSteps


      let index = 1;
      while (animationStep++ < animationSteps) {
          setTimeout(() => this.relativePanBy({ x: stepX, y: stepY }), animationStepTime * index++);
        } 
    }

    relativePanBy(amount) {
        let parts: string[] = this.viewBoxText.split(" ");
        let numparts: number[] = [+parts[0], +parts[1], +parts[2], +parts[3]];

        var svg = document.getElementById('diagramsvg');
        let zoomLevel: number = svg.clientWidth / numparts[2];

      if (zoomLevel == 0) {
          return;
        }

        if (numparts[2] < numparts[3]) {
            zoomLevel = svg.clientHeight / numparts[3];
        }


        numparts[0] -= (amount.x) / zoomLevel;
        numparts[1] -= (amount.y) / zoomLevel;
        this.setViewBox(numparts[0], numparts[1], numparts[2], numparts[3]);
    }


}

export class DiagramMenu {
    contentId: string = "";
    name: string = "";
    children: Array<DiagramMenu> = new Array<DiagramMenu>();
}

export class HierarchyContent {
  ContentId: string = "";
  Name: string = "";
  Children: Array<HierarchyContent> = new Array<HierarchyContent>();
}

export class HierarchyList {
  contentId: string = "";
  name: string = "";
  type: string = "";
  indent: number;
  hasChildren: boolean = false;
}

