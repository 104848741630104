import { Component, EventEmitter, Output, Input, OnInit, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';


@Component({
	selector: 'modal-message',
	templateUrl: 'modalmessage.component.html',
	styleUrls: ['modalmessage.component.css'],
	moduleId: module.id
})
export class ModalMessageComponent implements OnInit {

	@Input('title') title: string;
	@Input('body') body: string;
	@Input('actionText') actionText: string;
	@Input('negativeText') negativeText: string;
    @Input('cancelText') cancelText: string;
    @Input('actionClick') actionClickEvent: any;

	public hasActive: boolean = false;
	public hasNegative: boolean = false;
  public hasCancel: boolean = false;
  public hasTools: boolean = true;
	public pinnacleTools: string = "https://pinnacle.blob.core.windows.net/web-portal/downloads/pinnacletools.msi";

    constructor(private _sanitizer: DomSanitizer) {
		
	}

	ngOnInit(): void {
		
	}

    ngOnChanges(changes: SimpleChanges) {
        try { this.title = changes['title'].currentValue; } catch (err) { };
		try { this.body = changes['body'].currentValue; } catch (err) { };
		try { this.actionText = changes['actionText'].currentValue; } catch (err) { };
		try { this.negativeText = changes['negativeText'].currentValue; } catch (err) { };
        try { this.cancelText = changes['cancelText'].currentValue; } catch (err) { };

        if (this.actionText != "") {
			this.hasActive = true;
		}
		if (this.negativeText != "") {
			this.hasNegative = true;
		}
		if (this.cancelText != "") {
			this.hasCancel = true;
        }

        if (this.body == "toolModal") {
            this.hasTools = false;
        }
	}

	actionClick() : void
    {
        if (this.actionClickEvent != null) {
            this.actionClickEvent();
        }
	}

	negativeClick(): void {

	}

	cancelClick(): void {

    }

    getBodyHTML(): SafeHtml {
        return this._sanitizer.bypassSecurityTrustHtml(this.body as string);
    }


}