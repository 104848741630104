import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router, Route, Params } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import cssVars from 'css-vars-ponyfill';

import { ProdGenApi } from '../../apiService/prodgen.api';
import { BrowserAuthenticationService } from '../../BrowserAuthenticationService';
import { TranslationService } from '../../services/TranslationService';
import { LiveEventSurvey, LiveEventProperty, LiveEventWrapper, LiveEventBrowserCalendarStatus, LiveEventPropertyType, LiveEventRegistrant, LiveEventRegistrationType } from '../../apiService/classFiles/class.liveevents';
import { AppComponent } from '../../../app/app.component';
import { ContentPrerequisite, PSType_1310 } from '../../../app/apiService/classFiles/class.content';
import { UserOrgInfoObject } from '../../../app/apiService/classFiles/class.authorization';
import { PinnacleDateTimePipe } from "../../pipes/pinnacleDateTime";
import { User } from '../../apiService/classFiles/class.users';
import { LifecycleHooks } from '@angular/compiler/src/lifecycle_reflector';

declare var $: any; // JQuery workaround

@Component({
    selector: 'external-liveeventsurvey',
    templateUrl: 'liveeventsurvey.component.html',
    styleUrls: ['liveeventsurvey.component.css'],
    providers: [ProdGenApi]
})

export class LiveEventSurveyComponent implements OnInit, AfterViewInit {

    currentDate: Date = new Date(Date.now());
    userOrgInfo: UserOrgInfoObject;
    linkInfo: string = "";
    platformName: string = "Pinnacle Series";
    loadingLogo: string = "../../../assets/images/ps_browser_logo.png";
    pageStatus: LESurveyPageStatus = LESurveyPageStatus.Loading;
    eventDateString: string = "";
    datepipe: DatePipe;
    liveEvent: LiveEventWrapper;
    surveyResult: boolean = false;
    hasSurveyProperties: boolean = false;
    userRegistrationInfo: LiveEventRegistrant = new LiveEventRegistrant();
    currentUser: User;

    constructor(private pinnacleService: ProdGenApi,
        private route: ActivatedRoute,
        private router: Router,
        private translationService: TranslationService,
        private sanitizer: DomSanitizer,
        private authenticationService: BrowserAuthenticationService) {
    }

    ngOnInit(): void {
        if (this.authenticationService.AuthenticatePage() == true) {
            try {
                if (window.location.href.toLowerCase().includes("productivitynow")) {
                    this.platformName = "ProductivityNOW";
                    this.loadingLogo = "../../../assets/images/pnow_logo.png";
                }

                this.route.queryParams.subscribe(params => {
                    if (params["p_linkInfo"] == undefined
                        && params["sessionId"] == undefined) {
                        this.linkInfo = "";
                    }
                    else if (params["p_linkInfo"] != undefined) {
                        this.linkInfo = params["p_linkInfo"];
                        this.tryGetSurveyInfo();
                    }
                    else if (params["sessionId"] != undefined) {
                        this.linkInfo = params["sessionId"];
                        this.tryGetSurveyInfo();
                    }

                    if (params["productivitynow"] != undefined && params["productivitynow"] == "true") {
                        this.loadingLogo = "../../../assets/images/pnow_pinnacle.jpg";
                        this.platformName = "ProductivityNOW";
                    }
                });
            }
            catch (e) {
            }
        }
    }

    ngAfterViewInit(): void {
    }

    tryGetSurveyInfo(): void {
        try {
            this.pinnacleService.leGetSurveyInfo(this.linkInfo).subscribe(res => {
                this.liveEvent = res;
                this.liveEvent.startDate = PinnacleDateTimePipe.toLocalDateTime(new Date(this.liveEvent.startDate));
                this.liveEvent.registrationStartDate = PinnacleDateTimePipe.toLocalDateTime(new Date(this.liveEvent.registrationStartDate));
                this.liveEvent.registrationEndDate = PinnacleDateTimePipe.toLocalDateTime(new Date(this.liveEvent.registrationEndDate));

                if (this.liveEvent.eventID == "00000000-0000-0000-0000-000000000000" || this.liveEvent.sessionID == "00000000-0000-0000-0000-000000000000") {
                    this.pageStatus = LESurveyPageStatus.ErrorLoading;
                }
                else if (this.liveEvent.startDate > this.currentDate) {
                    this.pageStatus = LESurveyPageStatus.NotYetAvailable;
                }
                else {
                    this.pinnacleService.getCurrentUser().subscribe(usr => {
                        this.currentUser = usr;
                        this.pinnacleService.leGetUserRegistrationInfo(this.liveEvent.sessionID, this.currentUser.userId, [], false).subscribe(reg => {
                            this.userRegistrationInfo = reg;
                            if (this.userRegistrationInfo.surveyComplete) {
                                this.pageStatus = LESurveyPageStatus.Revisit;
                            }
                            else {
                                this.liveEvent.startDate = PinnacleDateTimePipe.toLocalDateTime(new Date(res.startDate));
                                this.liveEvent.endDate = PinnacleDateTimePipe.toLocalDateTime(new Date(res.endDate));
                                this.liveEvent.registrationStartDate = PinnacleDateTimePipe.toLocalDateTime(new Date(res.registrationStartDate));
                                this.liveEvent.registrationEndDate = PinnacleDateTimePipe.toLocalDateTime(new Date(res.registrationEndDate));
                                this.eventDateString = PinnacleDateTimePipe.transform(this.liveEvent.startDate, [{ range: this.liveEvent.endDate }]);

                                for (let i = 0; i < this.liveEvent.properties.length; i++) {
                                    if (this.liveEvent.properties[i].propertyType == LiveEventPropertyType.SurveyField) {
                                        this.hasSurveyProperties = true;
                                        break;
                                    }
                                }
                                this.pageStatus = LESurveyPageStatus.Survey;
                            }
                        });
                    });
                }
            });
        }
        catch (e) {
            this.pageStatus = LESurveyPageStatus.ErrorLoading;
            console.log(e);
        }
    }
    
    submitSurvey(): void {
        let userResponses = new Array<LiveEventProperty>();
        for (let i: number = 0; i < this.liveEvent.properties.length; i++) {
            if (this.liveEvent.properties[i].propertyType == LiveEventPropertyType.SurveyField) {
                userResponses.push(this.liveEvent.properties[i]);
            }
        }

        if (this.surveyResult == false) {
            this.pinnacleService.leSumbitSurvey(this.liveEvent.sessionID, userResponses).subscribe(res => {
                this.surveyResult = res;
                if (this.surveyResult) {
                    this.pageStatus = LESurveyPageStatus.Completed;
                }
                else {
                    this.pageStatus = LESurveyPageStatus.ErrorSurvey;
                    window.scroll(0, 0);
                }
            });
        }
    }

    redirectToLogin(): void {
        AppComponent.thisFromOutside.isExternalPage = false;
        this.router.navigateByUrl("/#/home");
        //window.location.href = ("http://" + window.location.hostname + (window.location.port != undefined ? ":" + window.location.port : "") + "/#/login");
    }
}

enum LESurveyPageStatus {
    Loading,
    ErrorLoading,
    Survey,
    ErrorSurvey,
    Completed,
    Revisit,
    NotYetAvailable
}