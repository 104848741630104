// Angular imports
import { Component, OnInit, Input, HostListener, OnDestroy, Output, EventEmitter, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SafeUrl, DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';

// Pinnacle imports
import { ProdGenApi } from '../../apiService/prodgen.api';
import { BrowserAuthenticationService } from '../../../app/BrowserAuthenticationService';
import { Course, ScormDataModel, ScormDataModel2004, ContentType_T } from '../../apiService/classFiles/class.content';
import { Enrollment } from '../../apiService/classFiles/class.enrollments';
import { TranslationService } from '../../services/TranslationService';
import { SessionTimeoutService } from '../../services/session-timeout.service';

// JQuery workaround
declare var $: any;


@Component({
    selector: 'template-scormviewer',
    templateUrl: 'scormviewer.component.html',
    styleUrls: ['scormviewer.component.css'],
    providers: [ProdGenApi]
})
export class ScormViewer implements OnInit, OnDestroy {

    // We want to listen for messages from the iframe's JS window object
    // so that we can use this (more secure) environment to send data back
    // to the Pinnacle API
    @HostListener('window:message', ['$event'])
    onmessage(event) {
        // Reject messages coming from sources other than blob storage
        if (event.origin != "https://pinnacle.blob.core.windows.net") { return; }

        switch (event.data.command) {
            case 'ready':
                if (event.data.items != undefined && event.data.items.length > 0) {
                    this.navigationItems = event.data.items[0].children;
                    if (this.currentItemIndex == -1) {
                        this.currentItemId = this.navigationItems[0].identifier;
                        this.currentItemIndex = 0;
                        this.currentChildId = "";
                        this.currentChildIndex = -1;
                    }
                    this.setNavigationAccess();
                    if (this.navigationItems.length > 1)
                        this.showNavigationControls = true;
                }
                this.sendMessage({ command: "initialize", dataModel: this.dataModel, dataModel2004: this.dataModel2004 }, event.origin);
                this.packageResult.emit({ begin: true });
                if (this.isFirstFullscreenAttempt && !this.isFullscreen) {
                    this.toggleFullscreen(null);
                    this.isFirstFullscreenAttempt = false;
                }
                break;
            case 'LMSCommit':
            case 'Commit':
                if (event.data.dataModel != undefined) {
                    if (this.enrollment != undefined) {
                        // Update local copy of data model and confirm commit
                        this.dataModel = event.data.dataModel;
                        this.dataModel.committed = true;
                        this.sendMessage({ command: 'commit', dataModel: this.dataModel }, event.origin);
                    }
                    else {
                        // Confirm commit in preview mode
                        this.sendMessage({ command: 'commit' }, event.origin);
                    }

                }
                if (event.data.dataModel2004 != undefined) {
                    if (this.enrollment != undefined) {
                        // Update local copy of data model and confirm commit
                        this.dataModel2004 = event.data.dataModel2004;
                        this.dataModel2004.committed = true;
                        this.sendMessage({ command: 'commit2004', dataModel2004: this.dataModel2004 }, event.origin);
                    }
                    else {
                        // Confirm commit in preview mode
                        this.sendMessage({ command: 'commit2004' }, event.origin);
                    }
                }
                break;
            case 'LMSFinish':
            case 'Terminate':
                if (event.data.dataModel != undefined) {
                    this.attemptingForceComplete = false;
                    this.dataModel = event.data.dataModel;
                    this.dataModel.terminated = true;
                    this.sendMessage({ message: "terminate", dataModel: this.dataModel }, event.origin);
                    if (!this.attemptingNavigation)
                        this.commitDataModels();
                }
                if (event.data.dataModel2004 != undefined) {
                    this.attemptingForceComplete = false;
                    this.dataModel2004 = event.data.dataModel2004;
                    this.dataModel2004.terminated = true;
                    this.sendMessage({ message: "terminate", dataModel2004: this.dataModel2004 }, event.origin);
                    if (!this.attemptingNavigation)
                        this.commitDataModels();
                }
                break;
            default:
                console.warn('ScormViewerComponent received an unknown command from a valid source. Details:', JSON.stringify(event.data));
                break;
        }
    }

    @Input() enrollmentId: string = "";
    @Input() courseId: string = "";
    @Input() isComplete: boolean = false;
    @Output() packageResult: EventEmitter<any> = new EventEmitter<any>();

    private storageUrl: string = "https://pinnacle.blob.core.windows.net";
    packageMargin: string = "0px";
    packageSource: SafeUrl;
    course: Course;
    enrollment: Enrollment;
    debugLog: boolean = false;

    dataModel: any;
    dataModel2004: any;
    trackingItemWritten: boolean = false;

    showLoadingMsg: boolean = true;
    showErrorLoadingMsg: boolean = false;
    attemptingForceComplete: boolean = false;
    attemptingNavigation: boolean = false;

    navigationItems = [];
    currentItemId = "";
    currentChildId = "";
    currentItemIndex = -1;
    currentChildIndex = -1;
    allowPreviousNavigationItem: boolean = true;
    allowNextNavigationItem: boolean = true;
    isFullscreen: boolean = false;
    showNavigationControls: boolean = false;

    isFirstFullscreenAttempt: boolean = true;

    constructor(private route: ActivatedRoute,
        private router: Router,
        private pinnacleService: ProdGenApi,
        private authenticator: BrowserAuthenticationService,
        private domSanitizer: DomSanitizer,
        private http: HttpClient,
        public translationService: TranslationService,
        private timeoutService: SessionTimeoutService) {
    }

    ngOnInit(): void {
        if (this.authenticator.AuthenticatePage() == true) {

            if (window.location.href.indexOf("preview") != -1) { this.packageMargin = "60px"; } else { this.packageMargin = "0px"; }

            this.route.queryParams.subscribe(params => {
                if (this.enrollmentId == "" && params["enrollmentId"] != null)
                    this.enrollmentId = params["enrollmentId"];
                if (this.courseId == "" && params["courseId"] != null)
                    this.courseId = params["courseId"];

                if (this.enrollmentId != "") {
                    this.start();
                }
                else if (this.courseId != "") {
                    this.startWithoutEnrollment();
                }

                // Consider SCORM to be video content since we
                // have no guarantee the package won't consume
                // any events we attempt to listen to.
                this.timeoutService.videoPlaying = true;
                this.timeoutService.stop();
            });
        }
    }

    ngOnDestroy(): void {
        if (!this.trackingItemWritten)
            this.commitDataModels();

        // SCORM content has finished and we can resume
        // the timer.
        this.timeoutService.videoPlaying = false;
        this.timeoutService.start();
    }

    commitDataModels(): void {
        try {
            this.checkCompleted();

            if (this.dataModel.committed
                || this.dataModel.initialized) {
                let dataModel = new ScormDataModel().set(this.dataModel);
                this.packageResult.emit({ destroyed: true, completed: this.isComplete });
                this.pinnacleService.lpSetScormModel(this.enrollmentId, dataModel).subscribe(result => {
                    this.packageResult.emit({ writeTracking: true, completed: this.isComplete });
                    this.trackingItemWritten = true;
                    if (this.safeCompleteResolve != null) {
                        this.safeCompleteResolve(true);
                        this.safeCompleteResolve = null;
                        this.safeCompleteReject = null;
                    }
                });
            }
            if (this.dataModel2004.committed
                || this.dataModel2004.initialized) {
                this.addSessionTimeToTotal();
                let dataModel2004 = new ScormDataModel2004().set(this.dataModel2004);
                this.packageResult.emit({ destroyed: true, completed: this.isComplete });
                this.pinnacleService.lpSetScormModel2004(this.enrollmentId, dataModel2004).subscribe(result => {
                    this.packageResult.emit({ writeTracking: true, completed: this.isComplete });
                    this.trackingItemWritten = true;
                    if (this.safeCompleteResolve != null) {
                        this.safeCompleteResolve(true);
                        this.safeCompleteResolve = null;
                        this.safeCompleteReject = null;
                    }
                });
            }
        }
        catch (err) {
            if (this.safeCompleteReject != null) {
                this.safeCompleteReject(err);
                this.safeCompleteReject = null;
                this.safeCompleteResolve = null;
            }
        }
    }

    finishCommit() {
        this.packageResult.emit({ writeTracking: true, completed: this.isComplete });
    }

    start(): void {
        this.pinnacleService.GetEnrollment(this.enrollmentId).subscribe(enrollment => {
            this.enrollment = enrollment;
            this.courseId = this.enrollment.courseId as string;
            this.startWithoutEnrollment();
        });
    }

    startWithoutEnrollment(): void {
        // Attempt to fetch the course to determine SCORM package location in blob storage
        this.pinnacleService.GetCourse(this.courseId).subscribe(course => {
            this.course = course;
            // If the course has SCORM content associated with it, attempt to open the blob location
            if (course.isScormCourse) {
                this.http.get(course.scormPackagePath, { responseType: 'text' }).subscribe(
                    // If the request succeeds, we know the blob is valid and we can begin loading
                    result => {
                        if (this.enrollmentId != "")
                            this.load();
                        else {
                            this.dataModel = new ScormDataModel();
                            this.dataModel.cmi.core.student_id = "00000000-0000-0000-0000-000000000000";
                            this.dataModel.cmi.core.student_name = "Course Preview";
                            this.dataModel.cmi.core.lesson_mode = "browse";

                            this.dataModel2004 = new ScormDataModel2004();
                            this.dataModel2004.cmi.learner_id = "00000000-0000-0000-0000-000000000000";
                            this.dataModel2004.cmi.learner_name = "Course Preview";
                            this.dataModel2004.cmi.mode = "browse";

                            $("#confirmPreviewModal").modal("show");
                        }
                    },
                    // If the request fails, we know the blob does not exist and we should abort
                    error => {
                        console.warn(error);
                        this.course = null;
                        this.enrollment = null;
                        this.packageSource = null;
                        this.showLoadingMsg = false;
                        this.showErrorLoadingMsg = true;
                        console.log(error);
                    }
                );
            }
        });
    }

    load(): void {
        if (this.enrollmentId != "") {
            this.pinnacleService.lpGetScormModel(this.enrollment.enrollmentId as string).subscribe(result => {
                this.dataModel = result;
                this.pinnacleService.lpGetScormModel2004(this.enrollment.enrollmentId as string).subscribe(result2004 => {
                    this.dataModel2004 = result2004;
                    if (this.isComplete
                        || this.dataModel.cmi.core.credit == "credit"
                        || this.dataModel.cmi.core.lesson_status == "passed"
                        || this.dataModel.cmi.core.lesson_status == "completed"
                        || this.dataModel.cmi.core.lesson_status == "failed"
                        || this.dataModel2004.cmi.credit == "credit"
                        || this.dataModel2004.cmi.completion_status == "completed"
                        || this.dataModel2004.cmi.success_status == "passed"
                    ) {
                        $("#confirmResetModal").modal("show");
                    }
                    else {
                        this.restart();
                    }
                },
                    error2004 => {
                        this.dataModel2004 = null;
                        if (this.isComplete
                            || this.dataModel.cmi.core.credit == "credit"
                            || this.dataModel.cmi.core.lesson_status == "passed"
                            || this.dataModel.cmi.core.lesson_status == "completed"
                            || this.dataModel.cmi.core.lesson_status == "failed"
                        ) {
                            $("#confirmResetModal").modal("show");
                        }
                        else {
                            this.restart();
                        }
                    });
            },
                error => {
                    this.dataModel = null;
                    this.pinnacleService.lpGetScormModel2004(this.enrollment.enrollmentId as string).subscribe(result2004 => {
                        this.dataModel2004 = result2004;
                        if (this.isComplete
                            || this.dataModel2004.dataModel2004.cmi.credit == "credit"
                            || this.dataModel2004.cmi.completion_status == "completed"
                            || this.dataModel2004.cmi.success_status == "passed"
                        ) {
                            $("#confirmResetModal").modal("show");
                        }
                        else {
                            this.restart();
                        }
                    });
                });
        }
    }

    checkCompleted(): boolean {

        // Check SCORM 1.X data model
        if (this.dataModel != undefined
            && this.dataModel.cmi != undefined
            && this.dataModel.cmi.core != undefined) {

            if (this.dataModel.cmi.core.credit == "credit"
                || this.dataModel.cmi.core.lesson_status == "passed"
                || this.dataModel.cmi.core.lesson_status == "completed") {

                this.isComplete = true;
                return true;
            }
        }
            
        // Check SCORM 2004 data model
        if (this.dataModel2004 != undefined
            && this.dataModel2004.cmi != undefined) {

            if (this.dataModel2004.cmi.credit == "credit"
                || this.dataModel2004.cmi.completion_status == "completed"
                || this.dataModel2004.cmi.success_status == "passed") {

                this.isComplete = true;
                return true;
            }
        }

        this.isComplete = false;
        return false;
    }

    checkScore(scoreRequired: number): boolean {
        // Chcek SCORM 1.X data model
        if (this.dataModel != undefined
            && this.dataModel.cmi != undefined
            && this.dataModel.cmi.core != undefined
            && this.dataModel.cmi.core.score != undefined
            && (this.dataModel.cmi.core.score.raw / this.dataModel.cmi.core.score.max * 100) >= scoreRequired) {
            return true;
        }

        // Check SCORM 2004 data model
        if (this.dataModel2004 != undefined
            && this.dataModel2004.cmi != undefined
            && this.dataModel2004.cmi.score != undefined
            && (this.dataModel2004.cmi.score.raw / this.dataModel2004.cmi.score.max * 100) >= scoreRequired) {
            return true;
        }
        return false;
    }

    addSessionTimeToTotal() {
        if (this.dataModel2004 != undefined
            && this.dataModel2004.cmi != undefined
            && this.dataModel2004.cmi.session_time != undefined
            && this.dataModel2004.cmi.total_time != undefined) {
            try {
                let s = this.timespanToMs(this.dataModel2004.cmi.session_time);
                let t = this.timespanToMs(this.dataModel2004.cmi.total_time);
                let r = this.msToTimespan(s + t);
                this.dataModel2004.cmi.total_time = r;
            }
            catch (err) { console.warn("An error occurred when combining SCORM session time and total time. Details: " + err); }
        }
    }

    timespanToMs(span: string): number {
        span = span.toUpperCase().replace("P", "");
        let ms = 0;
        let tokens = span.split("T");
        if (tokens.length > 0) {
            let date = tokens[0];
            let years = /\d+Y/.exec(date);
            if (years != undefined) {
                ms += (Number.parseInt(/\d+/.exec(years[0])[0]) * 365 * 24 * 60 * 60 * 1000);
            }
            let months = /\d+M/.exec(date);
            if (months != undefined) {
                ms += (Number.parseInt(/\d+/.exec(months[0])[0]) * 30 * 24 * 60 * 60 * 1000);
            }
            let days = /\d+D/.exec(date);
            if (days != undefined) {
                ms += (Number.parseInt(/\d+/.exec(days[0])[0]) * 24 * 60 * 60 * 1000);
            }
        }
        if (tokens.length > 1) {
            let time = tokens[1];
            let hours = /\d+H/.exec(time);
            if (hours != undefined) {
                ms += (Number.parseInt(/\d+/.exec(hours[0])[0]) * 60 * 60 * 1000);
            }
            let minutes = /\d+M/.exec(time);
            if (minutes != undefined) {
                ms += (Number.parseInt(/\d+/.exec(minutes[0])[0]) * 60 * 1000);
            }
            let seconds = /\d+(\.\d+)?S/.exec(time);
            if (seconds != undefined) {
                ms += (Number.parseInt(/\d+/.exec(seconds[0])[0]) * 1000);
            }
        }
        return ms;
    }

    msToTimespan(ms: number): string {
        let date = "P";
        let years = Math.floor(ms / (365 * 24 * 60 * 60 * 1000));
        if (years > 0) {
            ms = ms % (365 * 24 * 60 * 60 * 1000);
            date += years.toString() + "Y";
        }
        let months = Math.floor(ms / (30 * 24 * 60 * 60 * 1000));
        if (months > 0) {
            ms = ms % (30 * 24 * 60 * 60 * 1000);
            date += months.toString() + "M";
        }
        let days = Math.floor(ms / (24 * 60 * 60 * 1000));
        if (days > 0) {
            ms = ms % (24 * 60 * 60 * 1000);
            date += days.toString() + "D";
        }
        let time = "T";
        let hours = Math.floor(ms / (60 * 60 * 1000));
        if (hours > 0) {
            ms = ms % (60 * 60 * 1000);
            time += hours.toString() + "H";
        }
        let minutes = Math.floor(ms / (60 * 1000));
        if (minutes > 0) {
            ms = ms % (60 * 1000);
            time += minutes.toString() + "M";
        }
        let seconds = Math.floor(ms / 1000);
        if (seconds > 0)
            time += seconds.toString() + "S";
        let span = date;
        if (time != "T")
            span += time;
        if (span == "P")
            span = "PT0S";
        return span;
    }

    restart(): void {
        this.showLoadingMsg = false;
        //this.packageResult.emit({ reset: true });
        this.packageSource = this.domSanitizer.bypassSecurityTrustResourceUrl(this.course.scormPackagePath);
    }

    forceComplete(): void {
        if (this.showErrorLoadingMsg)
            this.packageResult.emit({ failForceClose: true });
        else {
            this.sendMessage({ command: 'destroy' }, this.storageUrl);
            this.attemptingForceComplete = true;
            setTimeout(() => { this.recoverForceComplete(); }, 1000);
        }
    }

    safeCompleteResolve: any = null;
    safeCompleteReject: any = null;
    safelyForceComplete(): Promise<boolean> {
        setTimeout(() => {
            if (this.safeCompleteResolve != null) {
                this.safeCompleteResolve(true);
                this.safeCompleteResolve = null;
                this.safeCompleteReject = null;
            }
        }, 2000);
        return new Promise((resolve, reject) => {
            this.safeCompleteResolve = resolve;
            this.safeCompleteReject = reject;
            this.sendMessage({ command: 'destroy' }, this.storageUrl);
        });
    }

    recoverForceComplete(): void {
        if (this.attemptingForceComplete) {
            console.warn('Attempted to close SCORM package but encountered an error. Enable package debugging for more information.');
            this.commitDataModels();
            //this.packageResult.emit({ destroyed: true });
        }
    }

    continueToNext(): void {
        if (!this.trackingItemWritten) {
            this.packageResult.emit({ continue: true });
        }
    }

    exitPreview(): void {
        this.router.navigateByUrl("/home");
    }

    sendMessage(message: any, destination: any): void {
        (document.getElementById('pinnacleSCORMFrame') as HTMLIFrameElement).contentWindow.postMessage(message, destination);
    }

    toggleDebug(): void {
        this.debugLog = !this.debugLog;
        if (this.debugLog) {
            this.sendMessage({ command: 'debug' }, this.storageUrl);
        }
        else {
            this.sendMessage({ command: 'nodebug' }, this.storageUrl);
        }
    }

    navigateToItem(item) {
        if (item.identifier == this.currentItemId)
            return;

            this.currentItemId = item.identifier;
            this.currentChildId = "";
            this.setCurrentItemAndChildIndex();
            this.setNavigationAccess();

            this.attemptingNavigation = true;
            this.sendMessage({ command: "destroy" }, "https://pinnacle.blob.core.windows.net");
            setTimeout(this.reboot.bind(this), 1000);
    }

    navigateToChild(item, child) {
        if (item.identifier == this.currentItemId
            && child.identifier == this.currentChildId)
            return;

        this.currentItemId = item.identifier;
        this.currentChildId = child.identifier;
        this.setCurrentItemAndChildIndex();
        this.setNavigationAccess();

        this.attemptingNavigation = true;
        this.sendMessage({ command: "destroy" }, "https://pinnacle.blob.core.windows.net");
        setTimeout(this.reboot.bind(this), 1000);
    }

    navigateToPreviousItem() {
        // Move to previous child if possible, previous item if no previous child exists
        if (this.currentItemIndex != -1) {
            if (this.currentChildIndex > 0) {
                this.currentChildId = this.navigationItems[this.currentItemIndex].children[--this.currentChildIndex].identifier;
            }
            else if (this.currentItemIndex > 0) {
                this.currentItemId = this.navigationItems[--this.currentItemIndex].identifier;
                this.currentChildId = "";
            }

            this.setNavigationAccess();
            this.attemptingNavigation = true;
            this.sendMessage({ command: "destroy" }, "https://pinnacle.blob.core.windows.net");
            setTimeout(this.reboot.bind(this), 1000);
        }
    }

    navigateToNextItem() {
        // Move to next child if possible, next item if no next child exists
        if (this.currentItemIndex != -1) {
            if (this.currentChildIndex < this.navigationItems[this.currentItemIndex].children.length - 1) {
                this.currentChildId = this.navigationItems[this.currentItemIndex].children[++this.currentChildIndex].identifier;
            }
            else if (this.currentItemIndex < this.navigationItems.length - 1) {
                this.currentItemId = this.navigationItems[++this.currentItemIndex].identifier;
                this.currentChildId = "";
            }

            this.setNavigationAccess();
            this.attemptingNavigation = true;
            this.sendMessage({ command: "destroy" }, "https://pinnacle.blob.core.windows.net");
            setTimeout(this.reboot.bind(this), 1000);
        }
    }

    reboot() {
        this.attemptingNavigation = false;
        if (this.currentItemIndex != -1) {
            if (this.currentChildIndex != -1) {
                this.dataModel.cmi.launch_data = this.navigationItems[this.currentItemIndex].children[this.currentChildIndex].dataFromLms || "";
                this.dataModel2004.cmi.launch_data = this.navigationItems[this.currentItemIndex].children[this.currentChildIndex].dataFromLms || "";
            }
            else {
                this.dataModel.cmi.launch_data = this.navigationItems[this.currentItemIndex].dataFromLms || "";
                this.dataModel2004.cmi.launch_data = this.navigationItems[this.currentItemIndex].dataFromLms || "";
            }

        }
        this.dataModel.cmi.suspend_data = "";
        this.dataModel.cmi.core.lesson_location = "";
        this.dataModel.committed = false;
        this.dataModel.initialized = false;
        this.dataModel.terminated = false;
        this.packageSource = "";
        this.restart();
    }

    setNavigationAccess() {
        if (this.currentItemIndex > 0 || this.currentChildIndex > 0)
            this.allowPreviousNavigationItem = true;
        else
            this.allowPreviousNavigationItem = false;

        if ((this.currentItemIndex < this.navigationItems.length - 1)
            || (this.currentItemIndex != -1 && (this.currentChildIndex < this.navigationItems[this.currentItemIndex].children.length - 1)))
            this.allowNextNavigationItem = true;
        else
            this.allowNextNavigationItem = false;
    }

    setCurrentItemAndChildIndex() {
        this.currentItemIndex = -1;
        this.currentChildIndex = -1;
        for (let i = 0; i < this.navigationItems.length; i++) {
            if (this.navigationItems[i].identifier == this.currentItemId) {
                this.currentItemIndex = i;
                for (let j = 0; j < this.navigationItems[i].children.length; j++) {
                    if (this.navigationItems[i].children[j].identifier == this.currentChildId) {
                        this.currentChildIndex = j;
                        break;
                    }
                }
                break;
            }
        }
    }

    // requestFullscreen() returns a Promise
    // but doesn't support callbacks. (:
    cleanUpFullscreenToggle() {
        if ((document as any).fullscreenElement
            || (document as any).mozFullScreenElement
            || (document as any).webkitFullscreenElement
            || (document as any).msFullscreenElement) {
            this.isFullscreen = true;
        }
        else {
            this.isFullscreen = false;
        }
    }

    toggleFullscreen(element) {

        // Exit fullscreen if active
        if ((document as any).fullscreenElement
            || (document as any).mozFullScreenElement
            || (document as any).webkitFullscreenElement
            || (document as any).msFullscreenElement) {

            if ((document as any).exitFullscreen) {
                (document as any).exitFullscreen();
                this.isFullscreen = false;
            }
            else if ((document as any).mozCancelFullScreen) {
                (document as any).mozCancelFullScreen();
                this.isFullscreen = false;
            }
            else if ((document as any).webkitExitFullscreen) {
                (document as any).webkitExitFullscreen();
                this.isFullscreen = false;
            }
            else if ((document as any).msExitFullscreen) {
                (document as any).msExitFullscreen();
                this.isFullscreen = false;
            }
        }
        // Enter fullscreen if not active
        else {
            try {
                if ((document.getElementById("pinnacleSCORM") as Element).requestFullscreen) {
                    ((document.getElementById("pinnacleSCORM") as Element)).requestFullscreen();
                    this.isFullscreen = true;
                }
                else if ((document.getElementById("pinnacleSCORM") as any).mozRequestFullScreen) {
                    (document.getElementById("pinnacleSCORM") as any).mozRequestFullScreen();
                    this.isFullscreen = true;
                }
                else if ((document.getElementById("pinnacleSCORM") as any).webkitRequestFullscreen) {
                    (document.getElementById("pinnacleSCORM") as any).webkitRequestFullscreen();
                    this.isFullscreen = true;
                }
                else if ((document.getElementById("pinnacleSCORM") as any).msRequestFullscreen) {
                    (document.getElementById("pinnacleSCORM") as any).msRequestFullscreen();
                    this.isFullscreen = true;
                }
            }
            catch (err) {
                this.isFullscreen = false;
                console.warn("Unable to make Pinnacle SCORM content frame fullscreen. Details: ", JSON.stringify(err));
                alert("Sorry, it doesn't look like your browser supports making this frame fullscreen.");
            }
        }

        setTimeout(this.cleanUpFullscreenToggle.bind(this), 100);
    }
}