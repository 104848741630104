import { Component, OnInit, ChangeDetectorRef, AfterViewInit  } from '@angular/core';
import { WidgetComponent } from '../../widget/widget.component';
import { ProdGenApi } from './../../../apiService/prodgen.api';
import { CompanyNewsItem } from '../../../apiService/classFiles/class.organizations';

import { WidgetProperties, WidgetPropertyOptions, User } from '../../../apiService/classFiles/class.users';
declare var $: any;


@Component({
  selector: 'app-company-news-widget',
  templateUrl: './company-news-widget.component.html',
  styleUrls: ['./company-news-widget.component.css']
})
export class CompanyNewsWidgetComponent extends WidgetComponent implements OnInit, AfterViewInit {
    static key = "CompanyNewsWidgetComponent";

    widgetLoaded: boolean = false;

    companyInfo: Array<CompanyNewsItem> = new Array<CompanyNewsItem>();
    companyInfoImage: string = "";
    companyInfoLoaded: boolean = false;
    uniqueImageQuery: number = Date.now();



    widgetItemHeight: number = 0;

    

    newsheight: number = 0;

    constructor(private pinnacleService: ProdGenApi, private changedetectorref: ChangeDetectorRef) {
        // call the base Widget class
        super(pinnacleService, changedetectorref);

        //Widget Defaults
        this.widgetHeight = 6;
        this.widgetWidth = 2;
        this.widgetTitle= "";

        this.widgetName = "Company News"
        this.widgetDesc = "Displays company news.";
        //End Widget Defaults

    }

    ngOnInit() {
        super.ngOnInit();

        this.widgetItemHeight = this.widgetContainer.calcWidgetHeight(this.widgetHeight);
        try {
            this.displayType = JSON.parse(localStorage.getItem(this.widgetID + "_displayType"));
        }
        catch (err) {
        }

        // if it was not in storage, reert to default
        if (this.displayType == null) {
            this.displayType = "overlay";
        }

        this.createProperties();
        this.Load();


        this.pinnacleService.GetCurrentTenantSettings().subscribe(res => {
            let infoIndex = res.findIndex(w => w.name == "COMPANY_NEWS");
            if (infoIndex != -1) {
                this.companyInfoImage = res[infoIndex].settingValue as string;

                try {
                    localStorage.setItem(ProdGenApi.getCurrentTenantId() + "_company_news_image", JSON.stringify(this.companyInfoImage));
                }
                catch (err) {
                    console.log(err);
                }
            }
            this.companyInfoLoaded = true;

            //this.adjustSize();
        });

        try {
            let i = JSON.parse(localStorage.getItem(ProdGenApi.getCurrentTenantId() + "_company_news_image"));
            if (i != null) {
                this.companyInfoImage = i;
            }
        }
        catch (err) {
            console.log(err)
        }

        this.pinnacleService.GetCurrentTenantNews().subscribe(res => {
            this.companyInfo = res;
            this.newsheight = this.calcNewsItemHeight();
            //this.adjustSize();
            try {
                localStorage.setItem(ProdGenApi.getCurrentTenantId() + "_company_news", JSON.stringify(this.companyInfo));
            }
            catch (err) {
                console.log(err);
            }
        });

        //try {
        //    let i = JSON.parse(localStorage.getItem(ProdGenApi.getCurrentTenantId() + "_company_news"));
        //    if (i != null) {
        //        this.companyInfo = i;
        //        this.newsheight = this.calcNewsItemHeight();
        //    }
        //}
        //catch (err) {
        //    console.log(err)
        //}

    }

    ngAfterViewInit() {
        this.widgetContainer.incrementRenderedWidget();
    }

    adjustSize() {
        //this.widgetContainer.onWidgetExpand(this.widgetID); //old way

        let item = document.getElementById("widget_" + this.widgetID + "_" + this.widgetContainer.seq);

        let overflowamt = item.scrollHeight - item.clientHeight;
        if (overflowamt > 0) {
            //let image = document.getElementById("coNewsImg_" + this.widgetID);
            //image.style.height = (image.clientHeight - overflowamt) + "px"; //leave this line to not keep ratio but resize height
            //image.style.width = "auto"; // leave this line and the one above it to retain ratio
            //image.style.display = "none";// leave this line to not display the image
            
        }

    }

    //START WIDGET REQUIRED CALLS
    onPropsChanged(newprops: Array<WidgetProperties>, writeSettings: boolean = false, doresequence = true) {
        super.onPropsChanged(newprops, writeSettings, doresequence);

        //The rest of the onPropsChanged method is widget specific, following the template of the two IF statements above.
       

        if (writeSettings) {
            this.pinnacleService.setWidgetProperties(this.widgetContainer.containerInfo.containerInstanceId, this.widgetID, newprops, CompanyNewsWidgetComponent.key).subscribe(res => {/*return stuff. might not need to do anything here*/ });
        }

        super.onPropsChangedLocalStorage();
       
        //this.widgetContainer.UpdateWidgetDimensions(this.widgetID, this.widgetWidth, this.widgetHeight);
        this.widgetLoaded = true;
        super.widgetArePropertiesLoaded();

    }



    Load() {
        super.Load();

        //The rest of the load function will be widget specific

    }

    createProperties() {

        super.createProperties();

        //The rest of the createProperties function will be widget specific, following the general format of the below commented code for each widget property

        super.createPropertiesDisplayType("overlay");
        super.createPropertiesWidgetTitle("Company News");

        super.createPropertiesMobileOptions();
    }

    //END WIDGET REQUIRED CALLS

    getUniqueImageSource(source: string): string {
        let newSource = "";
        if (source != null) {
            if (source.length > 0) {
                if (source.indexOf("?") == -1) {
                    newSource = source + "?" + this.uniqueImageQuery;
                }
                else {
                    newSource = source + "&" + this.uniqueImageQuery;
                }
            }
        }
        return newSource;
    }

    onWidgetMoved(x: number, y: number, selectedlayout: string = this.widgetContainer.selectedLayout) {
        super.onWidgetMoved(x, y, selectedlayout);
    }

    calcNewsItemHeight(): number {
        return ($("#coNews_"+ this.widgetID).outerHeight() - ($("#coNewsImg_" + this.widgetID).outerHeight() + $("#coNewsTitle_" + this.widgetID).outerHeight()) - 20);//20 is a bottom margin for the panel class we need to account for
    }

    onResize() {        
        super.onResize();
        this.widgetItemHeight = this.widgetContainer.calcWidgetHeight(this.widgetHeight);
    }

    getLinkTarget(linkUrl: string): string {
        let target = "";

        if (linkUrl.includes(window.location.origin)) {
            target = "_self";
        }
        else {
            target = "_blank";
        }

        return target;
    }
}
