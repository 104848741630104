import { AfterViewInit, ApplicationRef, ChangeDetectorRef, Component, ComponentFactoryResolver, EventEmitter, Injector, Input, NgZone, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, Type } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslationService } from '../../../app/services/TranslationService';
import { apiContainerWidgets, ContainerWidgets, WidgetContainerInfo, WidgetProperties } from '../../apiService/classFiles/class.users';
import { ProdGenApi } from '../../apiService/prodgen.api';
import { AppComponent } from '../../app.component';
import { WidgetLibraryEntry, WidgetLibraryService } from '../../services/widget-library.service';

declare var $: any;

@Component({
  selector: 'app-widget-container',
  templateUrl: './widget-container.component.html',
    styleUrls: ['./widget-container.component.css'],
})
export class WidgetContainerComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
    @Input('containerInstanceID') instanceID: string;
    @Input('containerID') containerID: string;
    @Input('bindingType') bindingType: string = "user";
    @Input('bindingId') bindingId: string;
    @Input('editmode') isEditMode: boolean = false;
    @Input('sequence') seq: number = 0;
    @Input('scale') scale: number = 1.0;
    @Input('isWorkGroupOwner') isWorkGroupOwner: boolean = false;
    @Output() widgetsOnGrid: EventEmitter<any> = new EventEmitter();
    columnCounts: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    rowCounts: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
                           21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
                           41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60,
                           61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80,
                           81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100];
    neededRows: number = 0;
    //isEditMode: boolean = false;
    draggeditem: HTMLElement;
    baseBlockWidth: number = 1;
    baseBlockHeight: number = 1;
    index: number = 0;
    dragflag = true;
    gridUnitHeight = 50;
    selectedLayout: string = "blank";
    graveyardwidgetid: string = "";
    tempbool: boolean = true;

    allowdropFirstThrough: boolean = true;
    initialWidgetLoading: boolean = false;
    initialWidgetLoadingCount: number = 0;
    widgetCalMobileHeight: boolean = false;
    widgetCalMobileHeightCount: number = 0;

    containerInfo: WidgetContainerInfo = new WidgetContainerInfo();
    containerWidgets: Array<ContainerWidgets> = new Array<ContainerWidgets>();
    MastercontainerWidgets: Array<ContainerWidgets> = new Array<ContainerWidgets>();
    widgetProps: Array<WidgetProperties> = new Array<WidgetProperties>();
    widgetLibrary: Array<WidgetLibraryEntry> = new Array<WidgetLibraryEntry>();
    clonedwidgetLibrary: Array<WidgetLibraryEntry> = new Array<WidgetLibraryEntry>();
    prevGridCoords: number[]=[0,0];
    prevDragCoords: number[] = [0, 0];
    prevRenderSize: string = "lg";
    renderSize: string = "lg";
    dragOffsetY: number = 0;
    dragOffsetX: number = 0;

    widgetsToDelete: Array<string> = new Array<string>();
    widgetsToAdd: Array<string> = new Array<string>();

    imageUploadProp: WidgetProperties;
    imageUploadWidgetID: string;

    sortedWidgetArray: Array<ContainerWidgets> = new Array<ContainerWidgets>();
    shouldResort: boolean = true;

    workingwidget: ContainerWidgets = new ContainerWidgets();

    filterText: string = "";

    showEdit: boolean = false;

    data: any;
    data2: any;

    flyoutTitle: string ="";

    private callback_allowDrop: any;

    expandedMovedWidgets: Array<ExpandCollisionAffectedWidgets> = new Array<ExpandCollisionAffectedWidgets>();
    //widgetExpandedAmounts: Array<WidgetExpandAmount> = new Array<WidgetExpandAmount>();

    renderedWidgets: number = 0;

    static thisFromOutside: WidgetContainerComponent = null;

    constructor(private pinnacleservice: ProdGenApi,
        private widgetLibraryService: WidgetLibraryService,
        private resolver: ComponentFactoryResolver,
        private injector: Injector,
        private app: ApplicationRef,
        private changeDetector: ChangeDetectorRef,
        private _sanitizer: DomSanitizer,
        private router: Router,
        private translationService: TranslationService,
        private zone: NgZone

    ) {
        WidgetContainerComponent.thisFromOutside = this;
    }

    ngOnInit() {  
        this.prevRenderSize = "lg";
        this.renderSize = "lg";
        try {

            this.callback_allowDrop = this.allowDrop.bind(this);

            if (this.router.url.includes("dashboard") || (this.router.url.includes("workcenter") && this.isWorkGroupOwner)) {
                this.showEdit = true;
            }
            else {
                this.showEdit = false;
            }
            
            this.widgetLibrary = this.widgetLibraryService.getWidgetLibrary([this.bindingType]);
            for (var i = 0; i < this.widgetLibrary.length; i++) {
                let item = new WidgetLibraryEntry();
                item = this.widgetLibrary[i];

                if (this.clonedwidgetLibrary.findIndex(i => i.name == item.name) == -1 )
                {
                    this.clonedwidgetLibrary.push(item);
                }

            }
        }
        catch (e) {
            console.log(e);
        }

    }

    ngAfterViewInit() {
        if (window.location.href.includes("#/home") && window.innerWidth < 993) {
            document.getElementById("GridDiv" + this.seq).hidden = true;
        }

        if (this.showEdit) {
            this.outsideDivSetup(true);
        }

        this.loadContainerFramework();

    }

    loadContainerFramework() {
        try {
            if (this.bindingId != null && this.bindingId.length > 0 && this.instanceID != null && this.instanceID.length > 0) {
                //Once database is hooked up with widget info we can use the below calls to get widgets in a container.

                this.pinnacleservice.getContainerInfo(this.instanceID, this.containerID, this.bindingType, this.bindingId).subscribe(res => {//this call should return the array of widget id's that are in this container
                    //from here we can do a call to get the widget information for display on screen
                    this.clearWidgets();
                    this.containerInfo = res; //container info including an array of apiContainerWidgets
                    this.containerInfo.bindingId = this.bindingId;
                    this.containerInfo.bindingType = this.bindingType;
                    this.containerInfo.containerid = this.containerID;
                    this.containerInfo.containerInstanceId = this.instanceID;

                    this.selectedLayout = this.containerInfo.layout;
                    if (this.selectedLayout == "" || this.selectedLayout == null) {
                        this.selectedLayout = "blank";
                    }
                    if (this.seq != 999) {
                        this.flyoutTitle = this.containerInfo.flyoutTitle;
                        AppComponent.thisFromOutside.flyoutTitle = this.flyoutTitle;
                    }

                    this.changeDetector.detectChanges();

                    if (this.containerInfo.widgets != null) {
                        let largestY = 0;
                        for (let w of this.containerInfo.widgets) {
                            let w1 = new ContainerWidgets();
                            w1.id = w.id
                            w1.component = w.component;
                            w1.width = w1.lg_width = w.width;
                            w1.height = w1.lg_height = w.height;
                            w1.x = w1.lg_x = w.x;
                            w1.y = w1.lg_y = w.y;
                            if ((w1.y + w1.height) > largestY) {
                                largestY = w1.y + w1.height;
                            }

                            
                            this.containerWidgets.push(w1);
                        }

                        // create the responsive layout sizes

                        this.setWidgetLayout();
                        if (this.router.url.includes("home") || this.router.url.includes("learningcenter")) {
                            //this.rowCounts.splice(largestY + 2, this.rowCounts.length - (largestY + 2));
                        }


                        this.createwidgets();
                        if (this.router.url.includes("companybranding") == true) {
                            let widgetdisabledclasses = document.getElementsByClassName("outerwidgetwrapper");
                            for (var i = 0; i < widgetdisabledclasses.length; i++) {
                                widgetdisabledclasses[i].classList.add("widgetdisable");
                                widgetdisabledclasses[i].classList.remove("widgetenable");
                            }
                        }
                        else {
                            let widgetdisabledclasses = document.getElementsByClassName("outerwidgetwrapper");
                            for (var i = 0; i < widgetdisabledclasses.length; i++) {
                                widgetdisabledclasses[i].classList.remove("widgetdisable");
                                widgetdisabledclasses[i].classList.add("widgetenable");
                            }
                        }

                    }

                    this.copyWidgetstoMaster();
                    this.populateMainandFlyout();
                    
                });

            }
            else {//FOR DASHBOARD                
                //Once database is hooked up with widget info we can use the below calls to get widgets in a container.
                this.pinnacleservice.getContainerInfo(ProdGenApi.EMPTY_GUID, this.containerID, this.bindingType, this.bindingId).subscribe(res => {//this call should return the array of widget id's that are in this container
                    //from here we can do a call to get the widget information for display on screen
                    this.containerInfo = res; //container info including an array of apiContainerWidgets
                    this.containerInfo.bindingId = this.bindingId;
                    this.containerInfo.bindingType = this.bindingType;
                    this.containerInfo.containerid = this.containerID;
                    this.containerInfo.containerInstanceId = this.instanceID;

                    this.selectedLayout = this.containerInfo.layout;
                    if (this.selectedLayout == "" || this.selectedLayout == null) {
                        this.selectedLayout = "blank";
                    }
                    this.flyoutTitle = this.containerInfo.flyoutTitle;
                    AppComponent.thisFromOutside.flyoutTitle = this.flyoutTitle;
                    this.changeDetector.detectChanges();
                    if (this.containerInfo.widgets != null) {
                        for (let w of this.containerInfo.widgets) {

                            let w1 = new ContainerWidgets();
                            w1.id = w.id
                            w1.component = w.component;
                            w1.width = w.width;
                            w1.height = w.height;
                            w1.x = w.x;
                            w1.y = w.y;
                            this.containerWidgets.push(w1);


                        }

                        // create the responsive layout sizes

                        this.setWidgetLayout();

                        this.createwidgets();
                    }


                    this.copyWidgetstoMaster();
                    this.populateMainandFlyout();
                   
                });
            }

            
            this.trimRows();
        }
        catch (e){
            console.log(e);
        }
    }

    CheckChildren() {
    }


    widgetsCreatedCount: number = 0;
    widgetsNeededCount: number = 0;

    createwidgets() {
        this.widgetsCreatedCount = 0;
        this.widgetsNeededCount = this.containerWidgets.length;

        try {
            for (let w of this.containerWidgets) {
                if (w.id != "editbtn") {
                    this.createWidgetComponent(w, false);
                }
            }

            //this.widgetsOnGrid.emit(null);

        } catch (e) {
            console.log(e);
            this.widgetsNeededCount--;
        }
    }

    //Dashboard Edit Mode
    DashEnableEditMode() {
        try {
            var editButton = <HTMLInputElement>document.getElementById("btnedit" + this.seq);

            if (this.isEditMode == true) {
                this.isEditMode = false;
                this.closeNav();
                editButton.value = this.translationService.getTranslationFileData("WIDGETS.EditLayout");
                let gridsections = document.getElementsByClassName("gridsection");
                for (var i = 0; i < gridsections.length; i++) {
                    (<HTMLElement>gridsections[i]).style.background = "rgba(255,255,255,0)";
                    (<HTMLElement>gridsections[i]).style.border = "0px solid white";
                }
                let widgets = document.getElementsByClassName("widget");
                for (var i = 0; i < widgets.length; i++) {
                    widgets[i].setAttribute("draggable", "false");
                }
                this.onResize(null);

                $(".ongrid").resizable({ disabled: true });
                $(".ongrid").resizable("option", "disabled", true);

                //remove border from elements that are on the grid
                let ongridWidgets = document.getElementsByClassName("widget ongrid " + this.seq);
                for (var i = 0; i < ongridWidgets.length; i++) {
                    (<HTMLElement>ongridWidgets[i]).style.border = "0px solid darkgray";
                }
                for (var i = 0; i < ongridWidgets.length; i++) {
                    for (var j = 0; j < ongridWidgets[i].children.length; j++) {
                        if (!(ongridWidgets[i].children[j].classList.contains("widgeteditbutton") || ongridWidgets[i].children[j].classList.contains("ui-resizable-handle"))) {
                            $(ongridWidgets[i].children[j]).css('pointer-events', 'auto');
                        }
                    }
                }
            }
            else {
                this.isEditMode = true;
                this.openNav();
                editButton.value = this.translationService.getTranslationFileData("WIDGETS.DoneEditing");
                let gridsections = document.getElementsByClassName("gridsection");
                for (var i = 0; i < gridsections.length; i++) {
                    (<HTMLElement>gridsections[i]).style.background = "rgba(180,180,180,.25)";
                    (<HTMLElement>gridsections[i]).style.border = "1px dashed white";
                }
                let widgets = document.getElementsByClassName("widget");
                for (var i = 0; i < widgets.length; i++) {
                    widgets[i].setAttribute("draggable", "true");
                }
                this.onResize(null);

                $(".ongrid").resizable({ disabled: true });
                $(".ongrid").resizable("option", "disabled", false);

                //add border to elements that are on the grid
                let ongridWidgets = document.getElementsByClassName("widget ongrid " + this.seq);
                for (var i = 0; i < ongridWidgets.length; i++) {
                    (<HTMLElement>ongridWidgets[i]).style.border = "3px solid darkgray";
                }
                for (var i = 0; i < ongridWidgets.length; i++) {
                    for (var j = 0; j < ongridWidgets[i].children.length; j++) {
                        if (!(ongridWidgets[i].children[j].classList.contains("widgeteditbutton") || ongridWidgets[i].children[j].classList.contains("ui-resizable-handle"))) {
                            $(ongridWidgets[i].children[j]).css('pointer-events', 'none');
                        }
                    }
                }
            }

            for (let w of this.containerWidgets) {
                if (w.obj != null) {
                    w.obj.onResize();
                }
            }
            
        } catch (e) {
            console.log(e);
        }
    }

    //Branding Edit Mode
    EnableEditMode() { 
        try {
            if (this.isEditMode == false) {

                let gridsections = document.getElementsByClassName("gridsection");
                for (var i = 0; i < gridsections.length; i++) {
                    (<HTMLElement>gridsections[i]).style.background = "rgba(255,255,255,0)";
                    (<HTMLElement>gridsections[i]).style.border = "0px solid white";
                }
                let widgets = document.getElementsByClassName("widget");
                for (var i = 0; i < widgets.length; i++) {
                    widgets[i].setAttribute("draggable", "false");
                }
                this.onResize(null);

                $(".ongrid").resizable({ disabled: true });
                $(".ongrid").resizable("option", "disabled", true);

                //remove border from elements that are on the grid
                let ongridWidgets = document.getElementsByClassName("widget ongrid " + this.seq);
                for (var i = 0; i < ongridWidgets.length; i++) {
                    (<HTMLElement>ongridWidgets[i]).style.border = "0px solid darkgray";
                }



                //this.saveWidgetPositions();
            }
            else {

                let gridsections = document.getElementsByClassName("gridsection");
                for (var i = 0; i < gridsections.length; i++) {
                    (<HTMLElement>gridsections[i]).style.background = "rgba(180,180,180,.25)";
                    (<HTMLElement>gridsections[i]).style.border = "1px dashed white";
                }
                let widgets = document.getElementsByClassName("widget");
                for (var i = 0; i < widgets.length; i++) {
                    widgets[i].setAttribute("draggable", "true");
                }
                this.onResize(null);


                $(".ongrid").resizable({ disabled: true });
                $(".ongrid").resizable("option", "disabled", false);

                $("#widget_editbtn" + this.seq).resizable("option", "disabled", true);

                //add border to elements that are on the grid
                let ongridWidgets = document.getElementsByClassName("widget ongrid " + this.seq);
                for (var i = 0; i < ongridWidgets.length; i++) {
                    (<HTMLElement>ongridWidgets[i]).style.border = "3px solid darkgray";
                }

                let widgetdisabledclasses = document.getElementsByClassName("widgetflag");
                for (var i = 0; i < widgetdisabledclasses.length; i++) {
                    widgetdisabledclasses[i].classList.add("widgetdisable");
                    widgetdisabledclasses[i].classList.remove("widgetenable");
                }

            }

            for (let w of this.containerWidgets) {
                if (w.obj != null) {
                    w.obj.onResize();
                }
            }

            

        } catch (e) {
            console.log(e);
        }
        
        
    }

    dragEnd(ev) {
        this.dragleave(ev);
    }

    dragleave(ev) {
        // remove our dragover event listener
        this.zone.runOutsideAngular(() => {
            document.removeEventListener('dragover', this.callback_allowDrop);
            });

        let mouseX = ev.clientX;
        let mouseY = ev.clientY;
        let griddiv = $('#GridDiv' + this.seq);
        let containerLeft = griddiv.offset().left;
        let containerTop = griddiv.offset().top;
        let containerRight = griddiv.offset().left + griddiv.width();
        let containerBottom = griddiv.offset().top + griddiv.height();

        if ((mouseX < containerLeft || mouseX > containerRight) || (mouseY < containerTop || mouseY > containerBottom)) {
            let widgetPlaceholder = $('#widgetplaceholder' + this.seq);
            let graveyarddiv = $('#graveyarddiv' + this.seq);
            if (graveyarddiv && widgetPlaceholder) {
                graveyarddiv.append(widgetPlaceholder);
            }
        }

    }


    allowDrop(ev) {

        ev.preventDefault();

        if (ev.clientX != this.prevDragCoords[0] || ev.clientY != this.prevDragCoords[1]) {

            let gridcoords = this.xyPositiontoGridSquare(ev.clientX, ev.clientY, this.dragOffsetX, this.dragOffsetY);

            if (gridcoords[0] != this.prevGridCoords[0] || gridcoords[1] != this.prevGridCoords[1]) {
                this.prevGridCoords[0] = gridcoords[0];
                this.prevGridCoords[1] = gridcoords[1];

                try {

                    let widgetPlaceholder = document.getElementById("widgetplaceholder" + this.seq);
                    if (this.dragflag == true || this.allowdropFirstThrough == true) {

                        let graveyarddivchildren = document.getElementById("graveyarddiv" + this.seq).children;
                        let graveyarddiv = document.getElementById("graveyarddiv" + this.seq);

                        for (var i = 0; i < graveyarddivchildren.length; i++) {//look at what is in the graveyard (which is only the widgetplaceholder and whatever widget is being drug) and set the placeholder width and height to equal the graveyard widget
                            if (graveyarddivchildren[i].id.includes("_GRAVEYARD")) {
                                widgetPlaceholder.style.zIndex = "11000";
                                widgetPlaceholder.style.width = (<HTMLElement>graveyarddivchildren[i]).style.width;
                                widgetPlaceholder.style.height = (<HTMLElement>graveyarddivchildren[i]).style.height;
                                widgetPlaceholder.style.position = "absolute";
                                widgetPlaceholder.style.top = "-1px";
                                widgetPlaceholder.style.left = "-1px";

                                graveyarddiv.removeChild(graveyarddivchildren[i]);
                                this.dragflag = false; 
                            }
                        }
                        this.allowdropFirstThrough = false;
                    }

                    if (!ev.target.id.includes("WidgetLib") || !ev.target.id.includes("WidgetComponent")) {
                        let gridsquare = document.getElementById(this.seq + "row" + gridcoords[0] + "col" + gridcoords[1]);
                        if (gridsquare != null) {
                            gridsquare.appendChild(widgetPlaceholder); //place the now correctly sized placeholder on the grid.
                        }
                    }

                } catch (e) {
                    console.log(e);
                }
            }
        }
       
    }

    drag(ev) {


        this.zone.runOutsideAngular(() => {
            document.addEventListener('dragover', this.callback_allowDrop);
        });

        try {

            if (this.isEditMode == false) {
                ev.preventDefault();
            }
            else {
                let dragid = document.getElementById(ev.target.id).getAttribute("data-widgetid");
                let dragid2 = document.getElementById(ev.target.id).id;
                ev.dataTransfer.setData("text", dragid);
                this.data = ev.dataTransfer.getData("text");
                ev.dataTransfer.setData("text", dragid2);
                this.data2 = ev.dataTransfer.getData("text")

                if (ev.dataTransfer.setDragImage) {
                    var img = new Image();
                    ev.dataTransfer.setDragImage(img, 0, 0);
                }
                else {//IE SPECIFIC WORKAROUND TO .setDragImage
                    var initialDisplay = ev.srcElement.style.display;
                    ev.srcElement.style.display = "none";
                    setTimeout(() => {
                        ev.srcElement.style.display = initialDisplay;
                    });
                }

                

                if (this.data2.includes("widget_")) { //we are dragging an existing div, so make a copy of it and move the copy to the graveyard
                    let ghostcopy = (<HTMLElement>ev.target).cloneNode();
                    (<HTMLElement>ghostcopy).id = (<HTMLElement>ghostcopy).id + "_GRAVEYARD";
                    document.getElementById("graveyarddiv" + this.seq).appendChild(ghostcopy);

                    let grabcoords = this.xyPositiontoGridSquare(ev.clientX, ev.clientY, 0, 0);
                    let grabsquare = document.getElementById(this.seq + "row" + grabcoords[0] + "col" + grabcoords[1]);
                    this.dragOffsetY = (ev.target.parentElement.offsetTop - (grabsquare.offsetTop + 10));
                    this.dragOffsetX = (ev.target.parentElement.offsetLeft - grabsquare.offsetLeft);
                }
                else { //we are dragging a new div, so create the component and the wrapper div in the graveyard
                    this.dragOffsetY = -60;
                    this.dragOffsetX = 0;
                    let w1 = new ContainerWidgets();
                    w1.id = Guid.MakeNew().toString() + "_GRAVEYARD";
                    this.graveyardwidgetid = w1.id;
                    w1.component = this.data;
                    w1.x = +location[1];
                    w1.y = +location[0];
                    this.createWidgetComponent(w1, true);
                }

            }
        } catch (e) {
            console.log(e);
        }
       
    }
    drop(ev) {
        try {
            // remove our dragover event listener
            this.zone.runOutsideAngular(() => {
                document.removeEventListener('dragover', this.callback_allowDrop);

            });

            ev.preventDefault();
            let node: HTMLElement;
            let clonednode: any;
            if (this.graveyardwidgetid != "") {
                //this.pinnacleservice.removeWidgetFromContainer(this.graveyardwidgetid.replace("widget_", "").replace("_GRAVEYARD", "").replace("_"+this.seq,"")).subscribe(res => {
                //    this.graveyardwidgetid = "";
                //});

                this.graveyardwidgetid = "";

            }

            if (document.getElementById("company" + this.data2)) {
                this.draggeditem = document.getElementById("company" + this.data2);
            }
            else if (document.getElementById("partner" + this.data2)) {
                this.draggeditem = document.getElementById("partner" + this.data2);
            }
            else if (document.getElementById("lite" + this.data2)) {
                this.draggeditem = document.getElementById("lite" + this.data2);
            }
            else {
                this.draggeditem = document.getElementById(this.data2);
            }

            if ((ev.target.offsetParent.id != this.draggeditem.id)) {

                let location = this.xyPositiontoGridSquare(ev.clientX, ev.clientY, this.dragOffsetX, this.dragOffsetY);

                if (this.draggeditem.className.includes("widget ongrid " + this.seq)) {//code for dragging a widget already on the grid
                    let widgetnum = this.containerWidgets.findIndex(x => x.id == this.draggeditem.getAttribute("data-widgetid"));
                    if (widgetnum != -1) {

                        location = this.calcWidgetContainerOverflow(location, this.containerWidgets[widgetnum]);

                        this.containerWidgets[widgetnum].x = +location[1];
                        this.containerWidgets[widgetnum].y = +location[0];

                        if (this.draggeditem.getAttribute("data-widgetid") == "editbtn") {
                            this.containerInfo.edit_x = +location[1];
                            this.containerInfo.edit_y = +location[0];
                        }

                        let gridsquare = document.getElementById(this.seq + "row" + this.containerWidgets[widgetnum].y + "col" + this.containerWidgets[widgetnum].x);
                        if (gridsquare != null) {
                            gridsquare.appendChild(this.draggeditem);
                            this.workingwidget = this.containerWidgets[widgetnum];
                            this.calcCollisionDetection(this.draggeditem.id, null);
                        }
                       

                    }

                }
                else {//code for dragging a new widget from the library


                    let w1 = new ContainerWidgets();
                    w1.id = Guid.MakeNew().toString();
                    
                    w1.component = this.data;
                    w1.x = +location[1];
                    w1.y = +location[0];

                    let newlength = this.containerWidgets.push(w1);
                    this.createWidgetComponent(w1, true);
                    if (this.router.url.includes("companybranding") == true) {
                        let widgetdisabledclasses = document.getElementsByClassName("outerwidgetwrapper");
                        for (var i = 0; i < widgetdisabledclasses.length; i++) {
                            widgetdisabledclasses[i].classList.add("widgetdisable");
                            widgetdisabledclasses[i].classList.remove("widgetenable");
                        }
                    }
                    else {
                        let widgetdisabledclasses = document.getElementsByClassName("outerwidgetwrapper");
                        for (var i = 0; i < widgetdisabledclasses.length; i++) {
                            widgetdisabledclasses[i].classList.remove("widgetdisable");
                            widgetdisabledclasses[i].classList.add("widgetenable");
                        }
                    }

                    w1.obj.widgetID = w1.id;
                    this.workingwidget = w1;
                    this.calcCollisionDetection(w1.id, null);

                    //at this point determine the sequencing of every other widget on the page
                    let sequencing = new Array<number>();
                    for (var i = 0; i < this.containerWidgets.length; i++) {
                        if (this.containerWidgets[i].obj.widgetProps.findIndex(x => x.propID == "MOBILE_SEQUENCE") != -1) {
                            sequencing.push(+this.containerWidgets[i].obj.widgetProps[this.containerWidgets[i].obj.widgetProps.findIndex(x => x.propID == "MOBILE_SEQUENCE")].propValue);
                        }
                        
                    }
                    sequencing.sort();



                    var newpropArray = new Array<WidgetProperties>()
                    var newprop = new WidgetProperties();

                    newprop.propName = "Display & Sequence";
                    if (sequencing.length == 0) {
                        newprop.propValue = "1";
                    }
                    else {
                        newprop.propValue = (sequencing[sequencing.length - 1] + 1).toString();
                    }
                    newprop.propType = "mobileSequence";
                    newprop.propID = "MOBILE_SEQUENCE";

                    newpropArray.push(newprop);

                    //this.containerWidgets[newlength - 1].obj.widgetProps[this.containerWidgets[newlength - 1].obj.widgetProps.findIndex(x => x.propID == "MOBILE_SEQUENCE")].propValue = (sequencing[sequencing.length - 1] + 1).toString();

                    this.containerWidgets[newlength - 1].obj.onPropsChanged(newpropArray, true, false);
                    //Done determining widget squence


                    this.setWidgetLayout();

                    let widgetelem = (<HTMLElement>document.getElementById("widget_" + w1.id + "_" + this.seq));
                    if (widgetelem!= null) {
                        widgetelem.style.border = "3px solid darkgray";
                    }


                    let widgetdisabledclasses = document.getElementsByClassName("widgetflag");
                    for (var i = 0; i < widgetdisabledclasses.length; i++) {
                        widgetdisabledclasses[i].classList.add("widgetdisable");
                        widgetdisabledclasses[i].classList.remove("widgetenable");
                    }

                    this.widgetsToAdd.push(w1.id);
                    //update generic props in DB
                    //this.pinnacleservice.setWidgetProperties(this.containerID, w1.id, this.widgetProps, w1.component).subscribe(res => {/*return stuff. might not need to do anything here*/ });


                }


            }
            else {//code for dragging an existing grid widget ontop of itself
                //calcs to handle a blind drop
                let gridcoords = this.xyPositiontoGridSquare(ev.clientX, ev.clientY, this.dragOffsetX, this.dragOffsetY);
                let gridsquare = document.getElementById(this.seq + "row" + gridcoords[0] + "col" + gridcoords[1]);
                if (gridsquare != null) {
                    gridsquare.appendChild(this.draggeditem);
                    this.workingwidget = this.containerWidgets[this.containerWidgets.findIndex(x => x.id == this.draggeditem.id.replace("widget_", "").replace("_" + this.seq, ""))];
                    this.calcCollisionDetection(this.draggeditem.id, null);

                }







                //let updateprops = new Array<WidgetProperties>();

                //let propsx = new WidgetProperties();
                //propsx.propID = Guid.MakeNew().toString();
                //propsx.propName = "x";
                //propsx.propType = "number";
                //propsx.propValue = gridcoords[1].toString();
                //updateprops.push(propsx);
                //let propsy = new WidgetProperties();
                //propsy.propID = Guid.MakeNew().toString();
                //propsy.propName = "y";
                //propsy.propType = "number";
                //propsy.propValue = gridcoords[0].toString();
                //updateprops.push(propsy);
                //this.pinnacleservice.setWidgetProperties(this.containerID, this.draggeditem.getAttribute("data-widgetid"), updateprops, data).subscribe(res => {/*return stuff. might not need to do anything here*/ });


            }

            //empty the graveyard div, place the widgetplaceholder back in the graveyard div, and reset the dragflag
            let graveyard = document.getElementById("graveyarddiv" + this.seq);

            for (var i = 0; i < graveyard.children.length; i++) {
                if (graveyard.children[i].id.includes("_GRAVEYARD")) {

                    graveyard.removeChild(graveyard.children[i]);
                }
            }
            graveyard.appendChild(document.getElementById("widgetplaceholder" + this.seq));


            this.dragflag = true;
            this.dragOffsetY = 0;
            this.dragOffsetX = 0;

            //this.copyWidgetstoMaster();
            if (this.workingwidget != null) {
                this.workingwidget.obj.onWidgetMoved(this.workingwidget.x, this.workingwidget.y, this.selectedLayout)
            }

            if (this.showEdit == true) {
                this.saveWidgetPositions();
            }
            this.allowdropFirstThrough = true;

            this.trimRows();
        } catch (e) {
            console.log(e);
        }
       
    }

    updateImageUploadProp(uploadProperty: WidgetProperties, widgetIDStr: string) {
        this.imageUploadProp = uploadProperty;
        this.imageUploadWidgetID = widgetIDStr;
    }

   

    openNav() {
        try {
            let sidebarelem = document.getElementById("mySidebar" + this.seq);
            let outsidedivelem = document.getElementById("outsideDiv" + this.seq);
            sidebarelem.style.width = "400px";
            let rightedge = $("#outsideDiv" + this.seq).offset().left + $("#outsideDiv" + this.seq).outerWidth();
            let pagerightedge = $(".generalPage").offset().left + $(".generalPage").outerWidth();
            let margincalc = 400 - (pagerightedge - rightedge);
            if (margincalc < 400) {
                outsidedivelem.style.marginRight = margincalc + "px";
            }
            else if (margincalc <= 0) {
                outsidedivelem.style.marginRight = "0px";
            }
            else {
                outsidedivelem.style.marginRight = "400px";

            }
        } catch (e) {
            console.log(e);
        }
       
    }

    closeNav() {
        try {
            document.getElementById("mySidebar" + this.seq).style.width = "0";
            document.getElementById("outsideDiv" + this.seq).style.marginRight = "0";
        } catch (e) {
            console.log(e);
        }

    }

    getRenderSize(): string {
        try {
            // get the width of the container object itself
            let renderSize = "lg";
            let container: HTMLElement = document.getElementById("GridDiv" + this.seq);
            if (container != null) {
                let actualWidth = window.innerWidth;
                    //container.offsetWidth;
                

                if (actualWidth < 768) {
                    renderSize = "xs";
                }
                else if (actualWidth < 992) {
                    renderSize = "sm";
                }
                else if (actualWidth < 1199) {
                    renderSize = "md";
                }
                else {
                    renderSize = "lg";
                }
            }

            return renderSize;
        } catch (e) {
            console.log(e);
        }
      
    }

    setWidgetLayout() {
        try {
            let widgets = this.containerWidgets.sort((a, b) => {
                if (a.y < b.y) return -1;
                if (a.y > b.y) return 1;
                if (a.x < b.x) return -1;
                if (a.x > b.x) return 1;
                return 0;
            });

            // add some quick logic if they are displaying columns to through the left and right columns at the end of the array
            //if (this.selectedLayout == "left" || this.selectedLayout == "both") {
            //    let firstPush = "";
            //    for (let i = 0; i < widgets.length; i++) {
            //        if (firstPush === widgets[i].id) {
            //            break;
            //        }

            //        if (widgets[i].x < 2) {
            //            widgets.push(widgets.splice(i, 1)[0]);

            //            if (firstPush.length == 0) {
            //                firstPush = widgets[i].id;
            //            }
            //        }
            //    }
            //}
            //if (this.selectedLayout == "right" || this.selectedLayout == "both") {
            //    let firstPush = "";
            //    for (let i = 0; i < widgets.length; i++) {
            //        if (firstPush === widgets[i].id) {
            //            break;
            //        }

            //        if (widgets[i].x >= 10) {
            //            widgets.push(widgets.splice(i, 1)[0]);

            //            if (firstPush.length == 0) {
            //                firstPush = widgets[i].id;
            //            }
            //        }
            //    }
            //}

            let curY = 0;
            let neededRows = this.rowCounts.length;

            for (let i = 0; i < widgets.length; i++) {
                let w = widgets[i];

                w.lg_height = w.height;
                w.lg_width = w.width;
                w.lg_x = w.x;
                w.lg_y = w.y;
            }


            // adjust for sm (widths)
            for (let i = 0; i < widgets.length; i++) {
                let w = widgets[i];

                // initialize default value
                w.sm_height = w.lg_height;
                w.sm_width = w.lg_width;
                w.sm_x = w.lg_x;
                w.sm_y = w.lg_y;

                if (w.sm_width >= 8) {
                    w.sm_width = 12;
                }
                else if (w.sm_width > 4) {
                    w.sm_width = 6;
                }
                else {
                    w.sm_width = 4;
                }
            }

            // now loop back through sm and set the y's (the x's should be either 12, 6 or 4)
            curY = 0;
            let rowWidth = 0;
            let rowHeight = 0;
            let rowY = -1;
            for (let i = 0; i < widgets.length; i++) {
                let w = widgets[i];

                if (i == 0) {
                    curY = w.sm_y;
                }

                if (w.sm_y == rowY || w.sm_y != rowY) {
                    if (rowWidth + w.sm_width <= 12) {
                        // room for this widget on the row
                        w.sm_x = rowWidth;
                        rowWidth += w.sm_width;
                    }
                    else {
                        curY += rowHeight;
                        w.sm_x = 0;
                        rowWidth = w.sm_width;
                        rowHeight = 0;
                    }
                }
                else {
                    curY += rowHeight;
                    w.sm_x = 0;
                    rowWidth = w.sm_width;
                    rowHeight = 0;
                }
                w.sm_y = curY;
                rowY = w.sm_y;
                rowHeight = Math.max(rowHeight, w.sm_height);
            }


            curY = 0;
            // adjust for xs
            for (let i = 0; i < widgets.length; i++) {
                let w = widgets[i];

                w.xs_height = w.height;
                w.xs_width = w.width;
                w.xs_x = w.x;
                w.xs_y = w.y;

                if (i == 0) {
                    curY = w.xs_y;
                }
                else {
                    w.xs_y = curY;
                }

                if (w.xs_width < 12) {
                    w.xs_width = 12
                }
                w.xs_x = 0;
                curY += w.xs_height; 
                if (curY > neededRows) {
                    neededRows = curY;
                }
            }
            this.neededRows = neededRows;
            if (neededRows > this.rowCounts.length) {
                this.addRows(neededRows - this.rowCounts.length);
                this.changeDetector.detectChanges();
            }

        } catch (e) {
            console.log(e);
        }

        this.renderSize = this.getRenderSize();
        this.adjustLayout();

    }

    adjustLayout() {
        try {
            for (var i = 0; i < this.rowCounts.length; i++) {
                $('#' + this.seq + 'Row' + i).css('display', 'block');
            }
            let widgetElements = document.getElementsByClassName("widget ongrid " + this.seq);
            let widgetElmArray = new Array<Element>();

            for (let w of this.containerWidgets) {
                if (this.renderSize == "xs") {
                    w.width = w.xs_width;
                    w.height = w.xs_height;
                    w.x = w.xs_x;
                    w.y = w.xs_y;
                }
                else if (this.renderSize == "sm") {
                    w.width = w.sm_width;
                    w.height = w.sm_height;
                    w.x = w.sm_x;
                    w.y = w.sm_y;
                }
                else {
                    w.width = w.lg_width;
                    w.height = w.lg_height;
                    w.x = w.lg_x;
                    w.y = w.lg_y;
                }

            }

            for (let i = 0; i < widgetElements.length; i++) {
                widgetElmArray.push(widgetElements[i]);
            }

            for (let w of widgetElmArray) {
                if (w.id != "widget_edit") {

                    let id = w.getAttribute("data-widgetid");
                    // lookup the widget that this is
                    let widget = this.containerWidgets.find(x => x.id == id);
                    if (widget) {
                        let gridid = this.seq + "row" + widget.y + "col" + widget.x;
                        let el = document.getElementById(gridid);
                        if (el != null) {
                            el.appendChild(w);
                        }
                        widget.obj.onWidgetMoved(widget.x, widget.y, this.selectedLayout);
                    }


                }
            }
            
        } catch (e) {
            console.log(e);
        }
        

    }

    onResize(ev: Event) {
        
        if (document.getElementById("outsideDiv999")) {
            document.getElementById("outsideDiv999").style.visibility = "visible";
        }
        
        this.outsideDivSetup(false);
    }

    outsideDivSetup(ignorePrevRenderSize: boolean) {
        let left: HTMLElement = document.getElementById("three-panel-left-column" + this.seq);
        let right: HTMLElement = document.getElementById("three-panel-right-column" + this.seq);
        let outsideDiv: HTMLElement = document.getElementById("outsideDiv" + this.seq);
        if (this.seq != 999) {    //not the non-widget homepage
            try {
                this.renderSize = this.getRenderSize();

                this.baseBlockHeight = this.calcWidgetHeight(1);
                this.baseBlockWidth = this.calcWidgetWidth(1);
                if ((this.renderSize != this.prevRenderSize || ignorePrevRenderSize) && (this.renderSize != "sm" && this.renderSize != "xs")) {
                    this.adjustLayout();
                    outsideDiv.style.visibility = "visible";
                    this.prevRenderSize = this.renderSize;
                    setTimeout(() => { this.resizeHelper(left, right); });
                    return;
                }
                else if ((this.renderSize != this.prevRenderSize || ignorePrevRenderSize) && (this.renderSize == "sm" || this.renderSize == "xs"))//handling small and xs sizes differently
                {   
                    this.populateMainandFlyout();
                    outsideDiv.style.visibility = "visible";
                    this.prevRenderSize = this.renderSize;
                    setTimeout(() => { this.resizeHelper(left, right); });
                }

                this.resizeHelper(left, right);

            } catch (e) {
                console.log(e);
            }

        }
        else {
            try {
                if (left) {
                    left.style.visibility = "hidden";
                }
                if (right) {
                    right.style.visibility = "hidden";
                }
            } catch (e) {
                console.log(e);
            }

        }

    }

    resizeHelper(left:HTMLElement, right:HTMLElement) {
        let widgetElements = document.getElementsByClassName("widget ongrid " + this.seq);

        let homecell: HTMLElement = document.getElementById(this.seq + "row0col0");
        if (left != null) {
            left.style.width = (2 * this.baseBlockWidth) + "px";
            if ((this.selectedLayout == "left" || this.selectedLayout == "both") && (this.renderSize != "sm" && this.renderSize != "xs")) {
                left.style.visibility = "visible";
            }
            else {
                left.style.visibility = "hidden";
            }
        }
        if (right != null) {
            right.style.width = (2 * this.baseBlockWidth) + "px";
            right.style.left = (homecell.offsetLeft + (10 * this.baseBlockWidth)) + "px";
            if ((this.selectedLayout == "right" || this.selectedLayout == "both") && (this.renderSize != "sm" && this.renderSize != "xs")) {
                right.style.visibility = "visible";
            }
            else {
                right.style.visibility = "hidden";
            }

        }

        for (var i = 0; i < widgetElements.length; i++) {
            let id = widgetElements[i].getAttribute("data-widgetid");
            // lookup the widget that this is
            let widget = this.containerWidgets.find(x => x.id == id);
            if (widget != null) {
                (<HTMLElement>widgetElements[i]).style.height = this.calcWidgetHeight(widget.height) + "px";
                (<HTMLElement>widgetElements[i]).style.width = this.calcWidgetWidth(widget.width) + "px";
            }
            else if (id == "editbtn") {
                (<HTMLElement>widgetElements[i]).style.height = this.calcWidgetHeight(1) + "px";
                (<HTMLElement>widgetElements[i]).style.width = this.calcWidgetWidth(1) + "px";

            }
        }
        if (this.renderSize != "sm" && this.renderSize != "xs") {
            this.trimRows();
        }
    }

    populateMainandFlyout() {
        var orderedFlyoutWidgets = new Array<ContainerWidgets>();
        var orderedMainWidgets = new Array<ContainerWidgets>();
        var orderedHiddenWidgets = new Array<ContainerWidgets>();

        if (this.renderSize == "sm" || this.renderSize == "xs") {

            for (var i = 0; i < this.containerWidgets.length; i++) {
                var location = "primary";
                var widgetPriority = 0;
                for (var j = 0; j < this.containerWidgets[i].obj.widgetProps.length; j++) {
                    if (this.containerWidgets[i].obj.widgetProps[j].propID == "DISPLAY_LOCATION") {
                        location = this.containerWidgets[i].obj.widgetProps[j].propValue;
                    }
                    if (this.containerWidgets[i].obj.widgetProps[j].propID == "MOBILE_SEQUENCE") {
                        widgetPriority = +this.containerWidgets[i].obj.widgetProps[j].propValue;
                    }

                }
                if (widgetPriority == -1) {
                    orderedHiddenWidgets.push(this.containerWidgets[i]);
                }
                else {
                    if (location == "primary") {
                        orderedMainWidgets.push(this.containerWidgets[i]);
                    }
                    else if (location == "flyout") {
                        orderedFlyoutWidgets.push(this.containerWidgets[i]);
                    }
                }
            }


            if (orderedFlyoutWidgets.length > 0) {
                AppComponent.thisFromOutside.isFlyoutVisible = true;
            }
            else {
                AppComponent.thisFromOutside.isFlyoutVisible = false;
            }
            AppComponent.thisFromOutside.detectChanges();

            orderedFlyoutWidgets.sort((a, b) => {
                var itemA = a.obj.widgetProps[a.obj.widgetProps.findIndex(x => x.propID == "MOBILE_SEQUENCE")];
                var itemB = b.obj.widgetProps[b.obj.widgetProps.findIndex(x => x.propID == "MOBILE_SEQUENCE")];
                if (itemA && itemB) {
                    if (+itemA.propValue > +itemB.propValue) {
                        return 1;
                    }
                    if (+itemA.propValue < +itemB.propValue) {
                        return -1;
                    }
                }
                return 0;
            });

            orderedMainWidgets.sort((a, b) => {
                var itemA = a.obj.widgetProps[a.obj.widgetProps.findIndex(x => x.propID == "MOBILE_SEQUENCE")];
                var itemB = b.obj.widgetProps[b.obj.widgetProps.findIndex(x => x.propID == "MOBILE_SEQUENCE")];
                if (itemA && itemB) {
                    if (+itemA.propValue > +itemB.propValue) {
                        return 1;
                    }
                    if (+itemA.propValue < +itemB.propValue) {
                        return -1;
                    }
                }
                return 0;
            });

            //now re-add the widgets to the containers in the sequenced order
            let widgetElements = document.getElementsByClassName("widget ongrid " + this.seq);
            let widgetElmArray = new Array<Element>();

            for (var i = 0; i < widgetElements.length; i++) {
                widgetElmArray.push(widgetElements[i]);
            }
            let rownumMain = 0;
            for (var a = 0; a < orderedMainWidgets.length; a++) {

                orderedMainWidgets[a].width = 12;
                if (orderedMainWidgets[a].obj.useMobileHeight == true) {
                    orderedMainWidgets[a].height = orderedMainWidgets[a].obj.mobileHeight;
                }
                for (let w of widgetElmArray) {
                    if (w.id != "widget_edit") {
                        let id = w.getAttribute("data-widgetid");

                        if (id == orderedMainWidgets[a].id) {//move this widgets dom object to the main
                            if (a != 0) {
                                rownumMain = rownumMain + orderedMainWidgets[a - 1].height;
                            }
                            else {
                                rownumMain = 0;
                            }
                            orderedMainWidgets[a].y = rownumMain;
                            let gridid = this.seq + "row" + rownumMain + "col" + "0";
                            let el = document.getElementById(gridid);
                            if (el != null) {
                                el.appendChild(w);
                            }
                        }
                    }
                }
            }
            let rownumFly = 0;

            let flyoutdiv = $('#GridDiv999');
            let flyoutWidgetstoClear = flyoutdiv.find('.widget');
            for (var i = 0; i < flyoutWidgetstoClear.length; i++) {
                flyoutWidgetstoClear[i].remove();
            }

            for (var i = 0; i < orderedFlyoutWidgets.length; i++) {
                orderedFlyoutWidgets[i].width = 12;
                if (orderedFlyoutWidgets[i].obj.useMobileHeight == true) {
                    orderedFlyoutWidgets[i].height = orderedFlyoutWidgets[i].obj.mobileHeight;
                }
                for (let w of widgetElmArray) {
                    if (w.id != "widget_edit") {
                        let id = w.getAttribute("data-widgetid");

                        if (id == orderedFlyoutWidgets[i].id) {//move this widgets dom object to the flyout
                            if (i != 0) {
                                rownumFly = rownumFly + orderedFlyoutWidgets[i - 1].height;
                            }
                            else {
                                rownumFly = 0;
                            }
                            orderedFlyoutWidgets[i].y = rownumFly;
                            let gridid = "999" + "row" + rownumFly + "col" + "0";
                            let el = document.getElementById(gridid);
                            if (el != null) {
                                el.appendChild(w);
                            }
                            orderedFlyoutWidgets[i].obj.onWidgetMoved(rownumFly, 0, "none");
                        }
                    }
                }
            }

            //TODO
            //manually trim rows from the flyout since at this level we don't have direct acess to the flyout's container component to call trimrows()
            ///////////////
            ///////////////
            ///////////////
            this.trimRows(orderedMainWidgets);
            this.trimRows(orderedFlyoutWidgets, 999);


            var flyoutGraveyard = document.getElementById("graveyarddiv999");
            for (var i = 0; i < orderedHiddenWidgets.length; i++) {
                for (let w of widgetElmArray) {
                    if (w.id != "widget_edit") {
                        let id = w.getAttribute("data-widgetid");

                        if (id == orderedHiddenWidgets[i].id) {//move this widgets dom object to the graveyard

                            if (flyoutGraveyard != null) {
                                flyoutGraveyard.appendChild(w);
                            }
                        }
                    }
                }
            }
        }
    }

    widgetsHaveLoadedProperties() {
        if (this.initialWidgetLoadingCount <= this.containerWidgets.length && !this.initialWidgetLoading && window.location.href.includes("#/home") && window.innerWidth < 993) {
            this.initialWidgetLoadingCount++;
            if (this.initialWidgetLoadingCount >= this.containerWidgets.length && !this.initialWidgetLoading) {
                document.getElementById("GridDiv" + this.seq).hidden = false;
                document.getElementById("GridDiv" + 999).hidden = false;

                this.onResize(null);

                this.populateMainandFlyout();
                this.initialWidgetLoading = true;
            }
        }
    }

    widgetMobileHeightCal() { // Currently not used        
        if (window.location.href.includes("#/home") && !this.widgetCalMobileHeight) {
            this.widgetCalMobileHeightCount++;

            let numWidgetUsingCustomHeight = 0;

            for (var i = 0; i < this.containerWidgets.length; i++) {
                if (this.containerWidgets[i].obj.useMobileHeight == true) {
                    numWidgetUsingCustomHeight++;
                }
            }
            if (this.widgetCalMobileHeightCount >= numWidgetUsingCustomHeight) {
                //this.populateMainandFlyout(); - 
                this.widgetCalMobileHeight = true;
            }
        }
        
    }

    UpdateWidgetDimensions(id: string, width: number, height: number) {
        try {
            let index = this.containerWidgets.findIndex(x => x.id == id);
            if (index != -1) {

                this.containerWidgets[index].width = width;
                this.containerWidgets[index].height = height;
                if (this.containerWidgets[index].obj != null) {
                    this.containerWidgets[index].obj.widgetWidth = width;
                    this.containerWidgets[index].obj.widgetHeight = height;
                }
            }

            if ((this.isEditMode == true) && ((this.widgetsToAdd.findIndex(x => x == id) == -1) || !this.router.url.includes("companybranding"))) {//if we are in edit mode and the widget is not a brand new widget in the widgetsToAdd array
                this.copyWidgetstoMaster();
            }


            setTimeout(() => {
                this.onResize(null);
                if (this.containerWidgets[index].obj) {
                    this.containerWidgets[index].obj.onResize();
                }
            }, 0);//set to 400 after trial and error
        } catch (e) {
            console.log(e);
        }
       
    }

    createWidgetComponent(widget: ContainerWidgets, useDefaultSize: boolean) {
        try {
            let factory: any;
            let ref: any;
            // create the div that will house the component
            let wrapperdiv = document.createElement("div");
            let internalwrapper = document.createElement("div");
            internalwrapper.setAttribute("class", "widgetenable outerwidgetwrapper");
            wrapperdiv.appendChild(internalwrapper);
            wrapperdiv.setAttribute("class", "widget ongrid " + this.seq);
            if (widget.component == "MyCoursesWidgetComponent" ) {
                wrapperdiv.setAttribute("style", "z-index:5;overflow:visible;padding:5px;padding-left:7px;");
                internalwrapper.setAttribute("style", "z-index:5;overflow:visible;");
            }
            else if (widget.component == "MyAssetsWidgetComponent" || widget.component == "FrequentlyUsedWidgetComponent" || widget.component == "TrendingWidgetComponent" || widget.component == "AssociatedLearningWidgetComponent" ) {
                wrapperdiv.setAttribute("style", "z-index:5;overflow:visible;padding:5px;padding-left:7px;");
            }
            else {
                wrapperdiv.setAttribute("style", "z-index:5;overflow:hidden;padding:5px;padding-left:7px;");
                internalwrapper.setAttribute("style", "z-index:5;overflow:visible;");
            }
            wrapperdiv.setAttribute("draggable", "true");
            wrapperdiv.style.position = "absolute";
            wrapperdiv.style.top = "-1px";
            wrapperdiv.style.left = "-1px";
            //internalwrapper.style.position = "absolute";
            //internalwrapper.style.top = "-1px";
            //internalwrapper.style.left = "-1px";


            wrapperdiv.addEventListener("dragstart", (event) => { this.drag(event); });
            wrapperdiv.addEventListener("click", () => { $(".widget").css("z-index", "5"); wrapperdiv.style.zIndex = "1041"; });
            wrapperdiv.id = "widget_" + widget.id + "_" + this.seq;
            if (widget.component.includes(this.seq.toString())) {
                factory = this.getComponentFactory(widget.component.replace(this.seq.toString(), ""));
            }
            else {
                factory = this.getComponentFactory(widget.component);
            }
            ref = factory.create(this.injector, [], internalwrapper);
            widget.obj = ref._component;
            widget.obj.widgetContainer = this;

            if (useDefaultSize) {
                widget.width = widget.obj.widgetWidth;
                widget.height = widget.obj.widgetHeight;
                widget.lg_width = widget.obj.widgetWidth;
                widget.lg_height = widget.obj.widgetHeight;
            }
            else {
                widget.obj.widgetWidth = widget.width;
                widget.obj.widgetHeight = widget.height;
            }

            let widthcalc = this.calcWidgetWidth(widget.width);
            let heightcalc = this.calcWidgetHeight(widget.height);
            wrapperdiv.style.width = widthcalc.toString() + "px";
            wrapperdiv.style.height = heightcalc.toString() + "px";
            internalwrapper.style.width = "100%";
            internalwrapper.style.height = "100%";
            wrapperdiv.setAttribute("data-widgetid", widget.id);

            // get the grid square based on location
            if (wrapperdiv.id.includes("_GRAVEYARD")) {
                document.getElementById("graveyarddiv" + this.seq).appendChild(wrapperdiv);
            }
            else {

                let returnedlocation = this.calcWidgetContainerOverflow([widget.y, widget.x], widget);
                widget.x = returnedlocation[1];
                widget.y = returnedlocation[0];

                // if they are placing the component, set this as the lg size also
                // if not, we do not want to do this in case we are just getting the widgets on mobile.
                if (useDefaultSize == true) {
                    widget.lg_x = returnedlocation[1];
                    widget.lg_y = returnedlocation[0];
                }

                let widgetelem = document.getElementById("widget_" + widget.id + "_" + this.seq);
                let gridsquare = document.getElementById(this.seq + "row" + widget.y + "col" + widget.x);
                if (!widgetelem) {
                    if (gridsquare != null) {
                        gridsquare.appendChild(wrapperdiv);
                    }
                }
            }


            setTimeout(() => {
                widget.obj.widgetID = widget.id;
                //widget.object.widgetHeight = widget.height;
                //widget.object.widgetWidth = widget.width;

                if (widget.id != "editbtn" + this.seq) {
                    this.zone.runOutsideAngular(() => {

                        $("#widget_" + widget.id + "_" + this.seq).resizable({
                            disabled: !this.isEditMode,
                            resize: (event, ui) => { this.resizeFix(event, ui); },
                            stop: (event, ui) => {  this.onResizeStop(event, ui); }
                        });
                    });
                }
                this.app.attachView(ref.hostView);

                if (widget.obj != null) {
                    setTimeout(() => {
                        widget.obj.onWidgetMoved(widget.x, widget.y, this.selectedLayout);
                        widget.obj.onWidgetResized();
                    });
                }
                this.widgetsCreatedCount++;

                if (this.widgetsCreatedCount == this.widgetsNeededCount) {
                    setTimeout(() => {
                        this.prevRenderSize = "initial";
                        this.onResize(null);
                    });
                }
            });
        } catch (e) {
            console.log(e);
            this.widgetsNeededCount--;

            if (this.widgetsCreatedCount == this.widgetsNeededCount) {
                setTimeout(() => { this.onResize(null); });
            }

        }
       

    }

    resizeFix(event, ui) {
        var changeWidth = ui.size.width - ui.originalSize.width; // find change in width
        var newWidth = ui.originalSize.width + changeWidth / this.scale; // adjust new width by our zoomScale

        var changeHeight = ui.size.height - ui.originalSize.height; // find change in height
        var newHeight = ui.originalSize.height + changeHeight / this.scale; // adjust new height by our zoomScale

        ui.size.width = newWidth;
        ui.size.height = newHeight;

}

    onResizeStop(event, ui) {
        try {
            let widgetid = event.target.id.replace("widget_", "").replace("_" + this.seq, "");
            let seqlength = this.seq.toString().length;
            //widgetid = widgetid.substring(0, widgetid.length - seqlength);

            let parentid = event.target.parentElement.id;

            parentid = parentid.replace(this.seq + "row", "");
            parentid = parentid.replace("col", "-");
            let parentcords = parentid.split("-");

            let resizecords = this.xyPositiontoGridSquare(event.clientX, event.clientY, 0, 0);

            let newwidth = 0;
            let newheight = 0;

            let originalsize = ui.originalSize;
            let size = ui.size;
            if (originalsize.width == size.width) {
                if (this.router.url.includes("companybranding") == true) {
                    newheight = (resizecords[0] - parentcords[0]) + 1;
                }
                else {
                    newheight = (resizecords[0] - parentcords[0]) + 2;
                }


                //this.containerWidgets[this.containerWidgets.findIndex(x => x.id == widgetid)].height = newheight;

                this.UpdateWidgetDimensions(widgetid, this.containerWidgets[this.containerWidgets.findIndex(x => x.id == widgetid)].width, newheight);
            }
            if (originalsize.height == size.height) {
                if (this.router.url.includes("companybranding") == true) {
                    newwidth = (resizecords[1] - parentcords[1]) + 1;
                }
                else {
                    newwidth = (resizecords[1] - parentcords[1]) + 1;
                }


                //this.containerWidgets[this.containerWidgets.findIndex(x => x.id == widgetid)].width = newwidth;
                this.UpdateWidgetDimensions(widgetid, newwidth, this.containerWidgets[this.containerWidgets.findIndex(x => x.id == widgetid)].height);
            }
            if (originalsize.height != size.height && originalsize.width != size.width) {
                if (this.router.url.includes("companybranding") == true) {
                    newwidth = (resizecords[1] - parentcords[1]) + 1;
                    newheight = (resizecords[0] - parentcords[0]) + 1;
                }
                else {
                    newwidth = (resizecords[1] - parentcords[1]) + 1;
                    newheight = (resizecords[0] - parentcords[0]) + 2;
                }


                //this.containerWidgets[this.containerWidgets.findIndex(x => x.id == widgetid)].height = newheight;
                //this.containerWidgets[this.containerWidgets.findIndex(x => x.id == widgetid)].width = newwidth;

                this.UpdateWidgetDimensions(widgetid, newwidth, newheight);
            }
            this.workingwidget = this.containerWidgets[this.containerWidgets.findIndex(x => x.id == widgetid)];
            this.calcCollisionDetection(widgetid, null);

            this.workingwidget.obj.onWidgetResized();

            if (this.showEdit == true && ((this.widgetsToAdd.findIndex(x => x == widgetid) == -1) || !this.router.url.includes("companybranding"))) {
                this.saveWidgetPositions();
            }
        } catch (e) {
            console.log(e);
        }
       
    }

    getComponentFactory(type: string) {
        try {
            const factories = Array.from<any>(this.resolver['_factories'].keys());
            const factoryClass = <Type<any>>factories.find((x: any) => x.key === type);
            if (factoryClass != null) {
                return this.resolver.resolveComponentFactory(factoryClass);
            }

            //for (let f of factories) {
            //    let con = f.prototype.constructor.toString().split("(")[0];
            //    if (con.indexOf(type) != -1) {
            //        return this.resolver.resolveComponentFactory(f);
            //    }
            //}

            return null;
        } catch (e) {
            console.log(e);
        }
       
    }

    addWidgetToLibrary(lib: Array<WidgetLibraryEntry>, name: string, imageUrl:string, desc: string, component: string) {
        try {
            let e1 = new WidgetLibraryEntry();
            e1.name = name;
            e1.desc = desc;
            e1.component = component;
            e1.imageUrl = imageUrl;
            lib.push(e1);
        } catch (e) {
            console.log(e);
        }
       
    }

    onLayoutChanged() {
        try {
            this.onResize(null);
            this.containerInfo.layout = this.selectedLayout;
            if (this.router.url.includes("workcenter") == true) {//only set the info if we are in the workcenter. the branding page does this on the save click
                this.pinnacleservice.setContainerInfo(this.containerInfo).subscribe();
            }
        } catch (e) {
            console.log(e);
        }
        
    }

    loadWidgetLibrary(): Array<WidgetLibraryEntry> {
        try {
            let lib = new Array<WidgetLibraryEntry>();

            this.addWidgetToLibrary(lib,
                "My Assets",
                "../../assets/images/widgetlib/my-assets.png",
                "Displays a list of content that has been shared to 'My Assets'",
                "MyAssetsWidgetComponent");

            this.addWidgetToLibrary(lib,
                "My Work Groups",
                "../../assets/images/widgetlib/workgroup-icon.png",
                "Displays all the cards for the Work Groups of which you are currently a member.",
                "MyWorkgroupsWidgetComponent");

            //this.addWidgetToLibrary(lib,
            //    "Resource Usage",
            //    "../../assets/images/widgetlib/resource-usage.png",
            //    "View the types of resources you use the most.",
            //    "PersonalResourceAccessWidgetComponent");

            this.addWidgetToLibrary(lib,
                "Download Addons",
                "../../assets/images/widgetlib/download-icon.png",
                "Download additional add-ons for Pinnacle.",
                "DownloadAddonsWidgetComponent");

            this.addWidgetToLibrary(lib,
                "User Profile",
                "../../assets/images/default_user.jpg",
                "Display information about the current user.",
                "ProfilePanelWidgetComponent");

            this.addWidgetToLibrary(lib,
                "Search Assets",
                "../../assets/images/widgetlib/search_icon.png",
                "Allows the user to search Pinnacle content.",
                "SearchWidgetComponent");

            this.addWidgetToLibrary(lib,
                "My Courses",
                "../../assets/images/icon_course.png",
                "Displays all the cards for the Courses in which you are enrolled.",
                "MyCoursesWidgetComponent");

            this.addWidgetToLibrary(lib,
                "Frequently Used",
                "../../assets/images/widgetlib/favorites-icon.png",
                "Displays frequently accessed content.",
                "FrequentlyUsedWidgetComponent");

            this.addWidgetToLibrary(lib,
                "Trending Assets",
                "../../assets/images/widgetlib/trending.png",
                "Displays trending content.",
                "TrendingWidgetComponent");

            this.addWidgetToLibrary(lib,
                "Partner News",
                "../../assets/images/widgetlib/news-icon.png",
                "Displays partner news items.",
                "PartnerNewsWidgetComponent");

            this.addWidgetToLibrary(lib,
                "Company News",
                "../../assets/images/widgetlib/news-icon.png",
                "Displays company news items.",
                "CompanyNewsWidgetComponent");

            this.addWidgetToLibrary(lib,
                "Asset Library",
                "../../assets/images/widgetlib/asset-library.png",
                "Displays the asset library.",
                "AssetLibraryWidgetComponent");

            this.addWidgetToLibrary(lib,
                "RSS Feed",
                "../../assets/images/widgetlib/usage-reports-icon.png",
                "Delivers the specified RSS feed",
                "RssFeedWidgetComponent");

            this.addWidgetToLibrary(lib,
                "Available Work Groups",
                "../../assets/images/widgetlib/news-icon.png",
                "Lists publicly available workgroups",
                "PublicWorkgroupsComponent");

            this.addWidgetToLibrary(lib,
                "Enrollment History",
                "../../assets/images/widgetlib/news-icon.png",
                "Review your enrollment history",
                "EnrollmentHistoryWidgetComponent");

            this.addWidgetToLibrary(lib,
                "Related Learning",
                "../../assets/images/widgetlib/news-icon.png",
                "Display additional learning of interest based on courses in which you are currently enrolled",
                "RelatedLearningWidgetComponent");


            return lib.sort((a, b) => { if (a.name < b.name) return -1; return 1; });
        } catch (e) {
            console.log(e);
        }
        
    }


    loadContainerWidgets(): Array<ContainerWidgets> {
        try {
            let widgets = new Array<ContainerWidgets>();

            return widgets;
        } catch (e) {
            console.log(e);
        }
      

    }

  

    //Utilities
    convertPXtoDimensions(width: number, height: number): Array<number> {//converts pixel dimensions to grid unit dimensions
        try {
            let calcWidth = 0;
            let calcHeight = 0;

            let cornerSquare = document.getElementById(this.seq + "row0col0");
            let rect = cornerSquare.getBoundingClientRect();
            let unitheight = rect.height;
            let unitwidth = rect.width;

            calcWidth = Math.floor(width / unitwidth);
            calcHeight = Math.floor(height / unitheight);

            return [calcWidth, calcHeight];
        } catch (e) {
            console.log(e);
        }
       
    }

    // x and y should be in windows coords
    // offsets should be in container coords
    xyPositiontoGridSquare(x: number, y: number, xOffset: number, yOffset: number): Array<number> {//takes x and y coordinates and converts them to grid coordinates
        try {
            
            let cornerSquare = document.getElementById(this.seq + "row0col0");

            // since these are windows coordinates, we must convert them to be relative to the container if scaled
            x = x / this.scale;
            y = y / this.scale;

            x = x - (cornerSquare.offsetWidth / 2);
            y = y - (cornerSquare.offsetHeight * .75);

            let unitheight = cornerSquare.offsetHeight;
            let unitwidth = cornerSquare.offsetWidth;

            // set the container offsets (relative to the containers parent)
            let containerOffsetTop = cornerSquare.offsetTop;
            let containerOffsetLeft = cornerSquare.offsetLeft;


            // get the bounding rect - these will be in window coordinates
            let offsets = document.getElementById("GridDiv" + this.seq).getBoundingClientRect();

            // make sure to scale the positions
            let droptop = y - ((offsets.top/this.scale - 10) - yOffset);
            let dropleft = x - ((offsets.left / this.scale + 3) - xOffset);

            let rownum = Math.floor((droptop - containerOffsetTop) / (unitheight));
            let colnum = Math.floor((dropleft - containerOffsetLeft) / (unitwidth));

            if (rownum < 0) {
                rownum = 0;
            }
            if (colnum < 0) {
                colnum = 0;
            }

            return [rownum, colnum];
        } catch (e) {
            console.log(e);
        }
       
    }

    calcWidgetHeight(widgetHeight: number): number {//converts the widget height in grid units to pixels
        try {
            let returnheight = 0;
            let container = document.getElementById("GridDiv" + this.seq);
            if (container != null) {
                let containerheight = container.clientHeight;
                let heightunit = this.gridUnitHeight;
                returnheight = heightunit * widgetHeight;
            }
            return returnheight;
        } catch (e) {
            console.log(e);
        }
       
    }
    calcWidgetWidth(widgetWidth: number): number {//converts the widget width in grid units to pixels
        try {
            let returnwidth = 0;

            let container = document.getElementById("GridDiv" + this.seq);
            if (container != null) {
                let containerwidth = container.clientWidth;
                let widthunit = containerwidth / 12;
                returnwidth = widthunit * widgetWidth;
            }
            return returnwidth;
        } catch (e) {
            console.log(e);
        }
       
    }

    calcCollisionDetection(activeitemid: string, affectedWidgetIds: Array<string>, allowWidgetPositionSwap = true, nestedCallLevel = 0) {
        if (nestedCallLevel > 20) {
            return;
        }

        try {
            let actionArray = new Array<string>();

            this.sortedWidgetArray.splice(0, this.sortedWidgetArray.length);
            for (let item of this.containerWidgets) {
                this.sortedWidgetArray.push(item);
            }
            this.sortedWidgetArray.sort((a, b) => {
                if (a.y > b.y) {//sort by y coordinates
                    return 1;
                }
                else if (a.y < b.y) {
                    return -1;
                }
                else {
                    if (a.x > b.x) {//then sort by x coordinates
                        return 1;
                    }
                    else if (a.x < b.x) {
                        return -1;
                    }
                    else {
                        return 0;
                    }
                }
            });



            //let seqlength = this.seq.toString().length;
            //if (this.seq == 0) {
            //    seqlength = 0;
            //}
            //let thisid = activeitemid.substring(0, activeitemid.length - seqlength).replace("widget_", "");
            let thisid = activeitemid.replace("widget_", "").replace("_" + this.seq, "");

            var activeItem: any;
            if (this.sortedWidgetArray.findIndex(x => x.id == "company" + thisid) != -1) {
                activeItem = this.sortedWidgetArray[this.sortedWidgetArray.findIndex(x => x.id == "company" + thisid)];
            }
            else if (this.sortedWidgetArray.findIndex(x => x.id == "partner" + thisid) != -1) {
                activeItem = this.sortedWidgetArray[this.sortedWidgetArray.findIndex(x => x.id == "partner" + thisid)];
            }
            else if (this.sortedWidgetArray.findIndex(x => x.id == "lite" + thisid) != -1) {
                activeItem = this.sortedWidgetArray[this.sortedWidgetArray.findIndex(x => x.id == "lite" + thisid)];
            }
            else {
                activeItem = this.sortedWidgetArray[this.sortedWidgetArray.findIndex(x => x.id == thisid)];
            }


            for (var i = 0; i < this.sortedWidgetArray.length; i++) {
                if (this.sortedWidgetArray[i].id != thisid) {

                    let boolOverlap = !(activeItem.x + activeItem.width <= this.sortedWidgetArray[i].x ||
                        activeItem.x >= this.sortedWidgetArray[i].x + this.sortedWidgetArray[i].width ||
                        activeItem.y + activeItem.height <= this.sortedWidgetArray[i].y ||
                        activeItem.y >= this.sortedWidgetArray[i].y + this.sortedWidgetArray[i].height);

                    //move the widget if boolOverlap equals true. Then recursevly call calcCollisionDetection with the moved widget id
                    if (boolOverlap == true) {
                        if (activeItem.y > this.sortedWidgetArray[i].y && activeItem.y + activeItem.height < this.sortedWidgetArray[i].y + this.sortedWidgetArray[i].height ||
                            activeItem.y == this.sortedWidgetArray[i].y && activeItem.y + activeItem.height == this.sortedWidgetArray[i].y + this.sortedWidgetArray[i].height) {                          
                            if (this.sortedWidgetArray[i].id == this.workingwidget.id) {//the following lines will move the active widget below the sortedWidgetArray item, which is the originaly moved widget. we should then re-run collsion detection on the active widget
                                activeItem.y = this.sortedWidgetArray[i].y + this.sortedWidgetArray[i].height;
                                this.sortedWidgetArray[this.sortedWidgetArray.findIndex(x => x.id == activeItem.id)].y = activeItem.y;
                                let index = this.containerWidgets.findIndex(x => x.id == activeItem.id);
                                this.containerWidgets[index].y = activeItem.y;
                                let gridsquare = document.getElementById(this.seq + "row" + this.containerWidgets[index].y + "col" + this.containerWidgets[index].x);
                                if (gridsquare != null) {
                                    gridsquare.appendChild(document.getElementById("widget_" + this.containerWidgets[index].id + "_" + this.seq));
                                    //this.calcCollisionDetection(activeItem.id);
                                    actionArray.push(activeItem.id);
                                }

                            }
                            else {
                                //the following 4 lines move the sortedWidgetArray item to immediatly below the active widget
                                this.sortedWidgetArray[i].y = activeItem.y + activeItem.height;
                                let index = this.containerWidgets.findIndex(x => x.id == this.sortedWidgetArray[i].id);
                                this.containerWidgets[index].y = activeItem.y + activeItem.height;
                                let gridsquare = document.getElementById(this.seq + "row" + this.containerWidgets[index].y + "col" + this.containerWidgets[index].x);
                                if (gridsquare != null) {
                                    gridsquare.appendChild(document.getElementById("widget_" + this.containerWidgets[index].id + "_" + this.seq));
                                    actionArray.push(this.sortedWidgetArray[i].id);
                                }
                            }
                        }
                        else if (activeItem.y + activeItem.height >= this.sortedWidgetArray[i].y && activeItem.y + activeItem.height < this.sortedWidgetArray[i].y + this.sortedWidgetArray[i].height) {//bottom of dragged widget is over top of other widget
                            if (this.sortedWidgetArray[i].id == this.workingwidget.id) {//the following lines will move the active widget below the sortedWidgetArray item, which is the originaly moved widget. we should then re-run collsion detection on the active widget
                                activeItem.y = this.sortedWidgetArray[i].y + this.sortedWidgetArray[i].height;
                                this.sortedWidgetArray[this.sortedWidgetArray.findIndex(x => x.id == activeItem.id)].y = activeItem.y;
                                let index = this.containerWidgets.findIndex(x => x.id == activeItem.id);
                                this.containerWidgets[index].y = activeItem.y;
                                let gridsquare = document.getElementById(this.seq + "row" + this.containerWidgets[index].y + "col" + this.containerWidgets[index].x);
                                if (gridsquare != null) {
                                    gridsquare.appendChild(document.getElementById("widget_" + this.containerWidgets[index].id + "_" + this.seq));
                                    //this.calcCollisionDetection(activeItem.id);
                                    actionArray.push(activeItem.id);
                                }
                            }
                            else {
                                //the following 4 lines move the sortedWidgetArray item to immediatly below the active widget
                                this.sortedWidgetArray[i].y = this.sortedWidgetArray[i].y + ((activeItem.y + activeItem.height) - this.sortedWidgetArray[i].y);
                                let index = this.containerWidgets.findIndex(x => x.id == this.sortedWidgetArray[i].id);
                                this.containerWidgets[index].y = this.containerWidgets[index].y + ((activeItem.y + activeItem.height) - this.containerWidgets[index].y);
                                let gridsquare = document.getElementById(this.seq + "row" + this.containerWidgets[index].y + "col" + this.containerWidgets[index].x);
                                if (gridsquare != null) {
                                    gridsquare.appendChild(document.getElementById("widget_" + this.containerWidgets[index].id + "_" + this.seq));
                                    actionArray.push(this.sortedWidgetArray[i].id);
                                }
                            }
                        }
                        else if (activeItem.y <= this.sortedWidgetArray[i].y + this.sortedWidgetArray[i].height && activeItem.y > this.sortedWidgetArray[i].y) {// top of dragged widget is over bottom of other widget
                            if (((this.sortedWidgetArray[i].y + (activeItem.y - (this.sortedWidgetArray[i].y + this.sortedWidgetArray[i].height)) < 0)) || allowWidgetPositionSwap == false) {
                                if (this.sortedWidgetArray[i].id == this.workingwidget.id) {//the following lines will move the active widget below the sortedWidgetArray item, which is the originaly moved widget. we should then re-run collsion detection on the active widget
                                    activeItem.y = this.sortedWidgetArray[i].y + this.sortedWidgetArray[i].height;
                                    this.sortedWidgetArray[this.sortedWidgetArray.findIndex(x => x.id == activeItem.id)].y = activeItem.y;
                                    let index = this.containerWidgets.findIndex(x => x.id == activeItem.id);
                                    this.containerWidgets[index].y = activeItem.y;
                                    let gridsquare = document.getElementById(this.seq + "row" + this.containerWidgets[index].y + "col" + this.containerWidgets[index].x);
                                    if (gridsquare != null) {
                                        gridsquare.appendChild(document.getElementById("widget_" + this.containerWidgets[index].id + "_" + this.seq));
                                        //this.calcCollisionDetection(activeItem.id);
                                        actionArray.push(activeItem.id);
                                    }
                                }
                                else {//the following 4 lines move the sortedWidgetArray item to immediatly below the active widget
                                    this.sortedWidgetArray[i].y = activeItem.y + activeItem.height;
                                    let index = this.containerWidgets.findIndex(x => x.id == this.sortedWidgetArray[i].id);
                                    this.containerWidgets[index].y = activeItem.y + activeItem.height;
                                    let gridsquare = document.getElementById(this.seq + "row" + this.containerWidgets[index].y + "col" + this.containerWidgets[index].x);
                                    if (gridsquare != null) {
                                        gridsquare.appendChild(document.getElementById("widget_" + this.containerWidgets[index].id + "_" + this.seq));
                                        actionArray.push(this.sortedWidgetArray[i].id);
                                    }
                                }

                            }
                            else {
                                //the following 4 lines move the sortedWidgetArray item to immediatly above the active widget
                                this.sortedWidgetArray[i].y = this.sortedWidgetArray[i].y + (activeItem.y - (this.sortedWidgetArray[i].y + this.sortedWidgetArray[i].height));
                                let index = this.containerWidgets.findIndex(x => x.id == this.sortedWidgetArray[i].id);
                                this.containerWidgets[index].y = this.containerWidgets[index].y + (activeItem.y - (this.containerWidgets[index].y + this.containerWidgets[index].height));
                                let gridsquare = document.getElementById(this.seq + "row" + this.containerWidgets[index].y + "col" + this.containerWidgets[index].x);
                                if (gridsquare != null) {
                                    gridsquare.appendChild(document.getElementById("widget_" + this.containerWidgets[index].id + "_" + this.seq));
                                    actionArray.push(this.sortedWidgetArray[i].id);
                                }
                            }

                        }
                        else {
                            this.sortedWidgetArray[i].y = activeItem.y + activeItem.height;
                            let index = this.containerWidgets.findIndex(x => x.id == this.sortedWidgetArray[i].id);
                            this.containerWidgets[index].y = activeItem.y + activeItem.height;
                            if (this.containerWidgets[index].y > this.rowCounts[this.rowCounts.length - 1]) {
                                this.calcWidgetContainerOverflow([this.containerWidgets[index].y, this.containerWidgets[index].x], this.containerWidgets[index]);
                                setTimeout(() => {
                                    let gridsquare = document.getElementById(this.seq + "row" + this.containerWidgets[index].y + "col" + this.containerWidgets[index].x);
                                    if (gridsquare != null) {
                                        gridsquare.appendChild(document.getElementById("widget_" + this.containerWidgets[index].id + "_" + this.seq));
                                    }
                                }, 0);
                            }
                            else {
                                this.calcWidgetContainerOverflow([this.containerWidgets[index].y, this.containerWidgets[index].x], this.containerWidgets[index]);

                                let gridsquare = document.getElementById(this.seq + "row" + this.containerWidgets[index].y + "col" + this.containerWidgets[index].x);
                                if (gridsquare != null) {
                                    gridsquare.appendChild(document.getElementById("widget_" + this.containerWidgets[index].id + "_" + this.seq));
                                }

                            }

                            actionArray.push(this.sortedWidgetArray[i].id);
                        }

                        //this.calcCollisionDetection(this.sortedWidgetArray[i].id);
                    }

                }
                else {
                    //do nothing
                }
            }

            for (var j = 0; j < actionArray.length; j++) {

                if (affectedWidgetIds != null) {
                    if (affectedWidgetIds.findIndex(x => x == actionArray[j]) == -1)
                        affectedWidgetIds.push(actionArray[j])
                }

                this.calcCollisionDetection(actionArray[j], affectedWidgetIds, allowWidgetPositionSwap, nestedCallLevel+1);


            }

            if (this.isEditMode == true) {
                //this.copyWidgetstoMaster();
            }
        } catch (e) {
            console.log(e);
        }
        

    }

    ngOnDestroy() {
        AppComponent.thisFromOutside.destroyContainerFlyoutAdjustments();

        this.zone.runOutsideAngular(() => {
            document.removeEventListener('dragover', this.callback_allowDrop);
            
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        let mustReload = false;
        try {
            if (changes['instanceID'] && changes['instanceID'].currentValue != changes['instanceID'].previousValue && changes['instanceID'].firstChange == false) {

                mustReload = true;

                this.instanceID = changes['instanceID'].currentValue;
            }
            if (changes['containerID'] && changes['containerID'].currentValue != changes['containerID'].previousValue && changes['containerID'].firstChange == false) {
                mustReload = true;
                this.containerID = changes['containerID'].currentValue;
            }
            if (changes['bindingId'] && changes['bindingId'].currentValue != changes['bindingId'].previousValue && changes['bindingId'].firstChange == false) {
                mustReload = true;
                this.bindingId = changes['bindingId'].currentValue;
            }
            if (changes['isEditMode'] && changes['isEditMode'].currentValue != changes['isEditMode'].previousValue) {
                this.isEditMode = changes['isEditMode'].currentValue;
                this.EnableEditMode();
            }
            if (changes['bindingType'] && changes['bindingType'].currentValue != changes['bindingType'].previousValue && changes['bindingType'].firstChange == false) {
                mustReload = true;
                this.bindingType = changes['bindingType'].currentValue;
            }
            if (changes['seq'] && changes['seq'].currentValue != changes['seq'].previousValue && changes['seq'].firstChange == false) {                mustReload = true;
                this.seq = changes['seq'].currentValue;
            }
            if (changes['scale'] && changes['scale'].currentValue != changes['scale'].previousValue) {
                this.scale = changes['scale'].currentValue;
                //this.gridUnitHeight = 50 * this.scale;

            }
            if (changes['isWorkGroupOwner'] && changes['isWorkGroupOwner'].currentValue != changes['isWorkGroupOwner'].previousValue) {

                if (this.router.url.includes("dashboard") || (this.router.url.includes("workcenter") && this.isWorkGroupOwner)) {
                    this.showEdit = true;
                }
                else {
                    this.showEdit = false;
                }
            }

        } catch (err) {
            console.log(err);
        };
         
        if (mustReload == true) {
            this.clearWidgets();
            //if (this.instanceID != null) {
                this.loadContainerFramework(); 
            //}
        }


    }
    clearWidgets() {
        try {
            
            let graveyarddiv = document.getElementById("graveyarddiv" + this.seq);
            for (var i = 0; i < this.containerWidgets.length; i++) {
                let widgetelem = document.getElementById("widget_" + this.containerWidgets[i].id + "_" + this.seq);
                if (widgetelem != null) {
                    graveyarddiv.appendChild(widgetelem);
                    graveyarddiv.removeChild(widgetelem);
                }

            }


            this.containerInfo = new WidgetContainerInfo();
            this.containerInfo.widgets = new Array<apiContainerWidgets>();
            this.containerWidgets = new Array<ContainerWidgets>();
            this.MastercontainerWidgets = new Array<ContainerWidgets>();
            this.widgetProps = new Array<WidgetProperties>();
            this.sortedWidgetArray = new Array<ContainerWidgets>();
        } catch (e) {
            console.log(e);
        }
       
    }

    calcWidgetContainerOverflow(coordinates: number[], widget: ContainerWidgets): number[] {
        try {
            if (+coordinates[1] + widget.width > this.columnCounts[this.columnCounts.length - 1]) {
                coordinates[1] = +coordinates[1] - ((+coordinates[1] + widget.width) - this.columnCounts[this.columnCounts.length - 1]);
            }

            if (+coordinates[0] + widget.height > this.rowCounts[this.rowCounts.length - 1]) {

                let overflowAMT = (+coordinates[0] + widget.height) - this.rowCounts[this.rowCounts.length - 1];
                for (var i = 0; i < overflowAMT; i++) {
                    this.rowCounts.push(this.rowCounts[this.rowCounts.length - 1] + 1);
                }

                setTimeout(() => {
                    if (this.isEditMode == true) {
                        let gridsections = document.getElementsByClassName("gridsection");
                        for (var i = 0; i < gridsections.length; i++) {
                            (<HTMLElement>gridsections[i]).style.background = "rgba(180,180,180,.25)";
                            (<HTMLElement>gridsections[i]).style.border = "1px dashed white";
                        }
                    }

                }, 1);

            }

            return coordinates;
        } catch (e) {
            console.log(e);
        }
       
    }

    addRows(amt: number) {
        try {
            for (var i = 0; i < amt; i++) {
                this.rowCounts.push(this.rowCounts[this.rowCounts.length - 1] + 1);
            }

            setTimeout(() => {
                if (this.isEditMode) {
                    let gridsections = document.getElementsByClassName("gridsection");
                    for (var i = 0; i < gridsections.length; i++) {
                        (<HTMLElement>gridsections[i]).style.background = "rgba(180,180,180,.25)";
                        (<HTMLElement>gridsections[i]).style.border = "1px dashed white";
                    }
                }
            }, 1);
        } catch (e) {
            console.log(e);
        }
       
    }

    saveWidgetPositions() {
        try {
            this.containerInfo.widgets.splice(0, this.containerInfo.widgets.length);

            for (var i = 0; i < this.containerWidgets.length; i++) {
                //do something that gets the current list of container widgets into the containerinfo widgets array
                let wid = new apiContainerWidgets();
                wid.component = this.containerWidgets[i].component.replace(this.seq.toString(), "");
                wid.height = this.containerWidgets[i].height;
                wid.id = this.containerWidgets[i].id;
                wid.width = this.containerWidgets[i].width;
                wid.x = this.containerWidgets[i].x;
                wid.y = this.containerWidgets[i].y;
                this.containerInfo.widgets.push(wid);
            }

            this.containerInfo.bindingId = this.bindingId;
            this.containerInfo.bindingType = this.bindingType;
            this.containerInfo.containerid = this.containerID;
            this.containerInfo.containerInstanceId = this.instanceID;
            this.containerInfo.flyoutTitle = this.flyoutTitle;
            this.setWidgetLayout();
            this.pinnacleservice.setContainerInfo(this.containerInfo).subscribe(res => {
                //for (let widget of this.containerWidgets) {
                //    this.pinnacleservice.setWidgetProperties(this.containerInfo.containerInstanceId, widget.id, widget.obj.widgetProps, widget.component).subscribe(res => {/*return stuff. might not need to do anything here*/ });
                //}
            });
            this.copyWidgetstoMaster();
            this.widgetsToAdd.splice(0);
        } catch (e) {
            console.log(e);
        }
       
    }

    filterTextChanged() {
        try {
            this.clonedwidgetLibrary = new Array<WidgetLibraryEntry>();
            for (var i = 0; i < this.widgetLibrary.length; i++) {
                let item = new WidgetLibraryEntry();
                item = this.widgetLibrary[i];
                if (item.name.toLowerCase().includes(this.filterText.toLowerCase()) || item.desc.toLowerCase().includes(this.filterText.toLowerCase())) {
                    this.clonedwidgetLibrary.push(item);
                }

            }
        } catch (e) {
            console.log(e);
        }
       
    }
    determineOverflow(id: string): number {
        let item = document.getElementById("widget_" + id + "_" + this.seq);

        if (item.scrollHeight > item.clientHeight) {
            let diff = item.scrollHeight - item.clientHeight;
            return diff;
        }
        else {
            return 0;
        }
    }

    onWidgetExpand(id: string) { 
        for (var i = 0; i < this.rowCounts.length; i++) {
            $('#' + this.seq + 'Row' + i).css('display', 'block');
        }
        try {
            if (this.renderSize != "xs" && this.renderSize != "sm") {
                let widgetElements = document.getElementsByClassName("widget ongrid " + this.seq);

                let item = document.getElementById("widget_" + id + "_" + this.seq);
                if (item != null) {
                    let newHeight = Math.floor((item.scrollHeight + this.gridUnitHeight - 1) / this.gridUnitHeight) * this.gridUnitHeight;
                    let newdimensions = this.convertPXtoDimensions(0, newHeight);
                    if (newHeight > 0) {
                        let widget = this.containerWidgets.find(x => x.id == id);
                        for (var i = 0; i < widgetElements.length; i++) {
                            if (id == widgetElements[i].getAttribute("data-widgetid")) {

                                if (widget != null) {
                                    (<HTMLElement>widgetElements[i]).style.height = this.calcWidgetHeight(newdimensions[1]) + "px";
                                    (<HTMLElement>widgetElements[i]).style.width = this.calcWidgetWidth(widget.width) + "px";
                                }
                                else if (id == "editbtn") {
                                    (<HTMLElement>widgetElements[i]).style.height = this.calcWidgetHeight(1) + "px";
                                    (<HTMLElement>widgetElements[i]).style.width = this.calcWidgetWidth(1) + "px";

                                }
                            }
                        }
                        this.UpdateWidgetDimensions(id, widget.width, newdimensions[1]);
                        this.calcWidgetContainerOverflow([widget.y, widget.x], widget);

                        let expandedItem = new ExpandCollisionAffectedWidgets();
                        expandedItem.affectedWidgetIds = new Array<string>();
                        expandedItem.widgetId = id;

                        let originalHeight = this.calcWidgetHeight(this.MastercontainerWidgets[this.MastercontainerWidgets.findIndex(x => x.id == id)].height);
                        expandedItem.expandAmt = newHeight - originalHeight;

                        this.calcCollisionDetection(id, expandedItem.affectedWidgetIds, false);
                        // remove all existing information for this widget
                        for (let i = 0; i < this.expandedMovedWidgets.length; i++) {
                            if (this.expandedMovedWidgets[i].widgetId == id) {
                                this.expandedMovedWidgets.splice(i, 1);
                                i--;
                            }
                        }

                        this.expandedMovedWidgets.push(expandedItem);
                        this.setWidgetLayout();
                        this.trimRows();
                    }
                }
            }
            else {
                //logic for small sized expand
                let item = document.getElementById("widget_" + id + "_" + this.seq);
                if (item != null) {
                    let newHeight = Math.floor((item.scrollHeight + this.gridUnitHeight - 1) / this.gridUnitHeight) * this.gridUnitHeight;
                    let newdimensions = this.convertPXtoDimensions(0, newHeight);
                    this.containerWidgets[this.containerWidgets.findIndex(x => x.id == id)].obj.useMobileHeight = true;
                    this.containerWidgets[this.containerWidgets.findIndex(x => x.id == id)].obj.mobileHeight = newdimensions[1];
                    this.populateMainandFlyout();
                    this.onResize(null);
                }
            }

        } catch (e) {
            console.log(e);
        }
       
    }
    onWidgetCollapse(id: string) {
        try {
            if (this.renderSize != "xs" && this.renderSize != "sm") {
                let widgetElements = document.getElementsByClassName("widget ongrid " + this.seq);
                let widget = this.MastercontainerWidgets.find(x => x.id == id);

                let expandedIndex = this.expandedMovedWidgets.findIndex(x => x.widgetId == id);
                let adjustmentUnits = (this.convertPXtoDimensions(0, this.expandedMovedWidgets[expandedIndex].expandAmt))[1];

                //Resize the collapsed widget
                let widgetelem = document.getElementById("widget_" + widget.id + "_" + this.seq);

                if (widgetelem != null) {
                    widgetelem.style.height = this.calcWidgetHeight(widget.height) + "px";
                    widgetelem.style.width = this.calcWidgetWidth(widget.width) + "px";

                }

                //move up all affected widgets
                for (let item of this.expandedMovedWidgets[expandedIndex].affectedWidgetIds) {
                    let containerIndex = this.containerWidgets.findIndex(x => x.id == item);
                    this.containerWidgets[containerIndex].y = this.containerWidgets[containerIndex].y - adjustmentUnits;
                    let gridsquare = document.getElementById(this.seq + "row" + this.containerWidgets[containerIndex].y + "col" + this.containerWidgets[containerIndex].x);
                    let appendwidgetelem = document.getElementById("widget_" + this.containerWidgets[containerIndex].id + "_" + this.seq);
                    if (gridsquare != null && appendwidgetelem != null) {
                        gridsquare.appendChild(appendwidgetelem);
                    }


                }
                this.expandedMovedWidgets.splice(expandedIndex, 1);

                let item = document.getElementById("widget_" + id + "_" + this.seq);
                let newHeight = Math.floor((item.clientHeight + this.gridUnitHeight - 1) / this.gridUnitHeight) * this.gridUnitHeight;
                let newdimensions = this.convertPXtoDimensions(0, newHeight);//50 is the height of one grid square. this calc rounds to the nearest 50, so 122 -> 100 and 126 -> 150
                let widget2 = this.containerWidgets.find(x => x.id == id);
                for (var a = 0; a < widgetElements.length; a++) {
                    if (id == widgetElements[a].getAttribute("data-widgetid")) {
                        if (widget2 != null) {
                            (<HTMLElement>widgetElements[a]).style.height = this.calcWidgetHeight(newdimensions[1]) + "px";
                            (<HTMLElement>widgetElements[a]).style.width = this.calcWidgetWidth(widget2.width) + "px";
                        }
                        else if (id == "editbtn") {
                            (<HTMLElement>widgetElements[a]).style.height = this.calcWidgetHeight(1) + "px";
                            (<HTMLElement>widgetElements[a]).style.width = this.calcWidgetWidth(1) + "px";

                        }
                    }
                }
                this.UpdateWidgetDimensions(id, widget2.width, newdimensions[1]);
                // TODO - Use the array to ofset all the affected widgets

                //this.calcCollisionDetection(id, null, false);
                this.setWidgetLayout();
                this.trimRows();
            }
            else {
                //logic for small sized collapse
                let item = document.getElementById("widget_" + id + "_" + this.seq);
                if (item != null) {
                    let widget = this.MastercontainerWidgets.find(x => x.id == id);
                    this.containerWidgets[this.containerWidgets.findIndex(x => x.id == id)].obj.mobileHeight = widget.obj.widgetHeight;
                    this.containerWidgets[this.containerWidgets.findIndex(x => x.id == id)].height = widget.height;
                    this.containerWidgets[this.containerWidgets.findIndex(x => x.id == id)].obj.widgetHeight = widget.obj.widgetHeight;
                    this.containerWidgets[this.containerWidgets.findIndex(x => x.id == id)].obj.useMobileHeight = false;
                    let widgetelem = document.getElementById("widget_" + widget.id + "_" + this.seq);

                    if (widgetelem != null) {
                        widgetelem.style.height = this.calcWidgetHeight(widget.height) + "px";

                    }
                    this.populateMainandFlyout();
                }
            }
           
        } catch (e) {
            console.log(e);
        }
        
    }

    copyWidgetstoMaster() {
        try {
            this.MastercontainerWidgets = new Array<ContainerWidgets>();
            for (var i = 0; i < this.containerWidgets.length; i++) {
                let item = new ContainerWidgets;
                item.component = this.containerWidgets[i].component;
                item.height = this.containerWidgets[i].height;
                item.id = this.containerWidgets[i].id;
                item.lg_height = this.containerWidgets[i].lg_height;
                item.lg_width = this.containerWidgets[i].lg_width;
                item.lg_x = this.containerWidgets[i].lg_x;
                item.lg_y = this.containerWidgets[i].lg_y;
                item.md_height = this.containerWidgets[i].md_height;
                item.md_width = this.containerWidgets[i].md_width;
                item.md_x = this.containerWidgets[i].md_x;
                item.md_y = this.containerWidgets[i].md_y;
                item.sm_height = this.containerWidgets[i].sm_height;
                item.sm_width = this.containerWidgets[i].sm_width;
                item.sm_x = this.containerWidgets[i].sm_x;
                item.sm_y = this.containerWidgets[i].sm_y;
                item.xs_height = this.containerWidgets[i].xs_height;
                item.xs_width = this.containerWidgets[i].xs_width;
                item.xs_x = this.containerWidgets[i].xs_x;
                item.xs_y = this.containerWidgets[i].xs_y;
                item.obj = this.containerWidgets[i].obj;
                item.width = this.containerWidgets[i].width;
                item.x = this.containerWidgets[i].x;
                item.y = this.containerWidgets[i].y;
                this.MastercontainerWidgets.push(item);
            }
        } catch (e) {
            console.log(e);
        }
        
    }

    copyMastertoWidgets() {
        try {
            this.containerWidgets = new Array<ContainerWidgets>();
            for (var i = 0; i < this.MastercontainerWidgets.length; i++) {
                let item = new ContainerWidgets;
                item.component = this.MastercontainerWidgets[i].component;
                item.height = this.MastercontainerWidgets[i].height;
                item.id = this.MastercontainerWidgets[i].id;
                item.lg_height = this.MastercontainerWidgets[i].lg_height;
                item.lg_width = this.MastercontainerWidgets[i].lg_width;
                item.lg_x = this.MastercontainerWidgets[i].lg_x;
                item.lg_y = this.MastercontainerWidgets[i].lg_y;
                item.md_height = this.MastercontainerWidgets[i].md_height;
                item.md_width = this.MastercontainerWidgets[i].md_width;
                item.md_x = this.MastercontainerWidgets[i].md_x;
                item.md_y = this.MastercontainerWidgets[i].md_y;
                item.sm_height = this.MastercontainerWidgets[i].sm_height;
                item.sm_width = this.MastercontainerWidgets[i].sm_width;
                item.sm_x = this.MastercontainerWidgets[i].sm_x;
                item.sm_y = this.MastercontainerWidgets[i].sm_y;
                item.xs_height = this.MastercontainerWidgets[i].xs_height;
                item.xs_width = this.MastercontainerWidgets[i].xs_width;
                item.xs_x = this.MastercontainerWidgets[i].xs_x;
                item.xs_y = this.MastercontainerWidgets[i].xs_y;
                item.obj = this.MastercontainerWidgets[i].obj;
                item.width = this.MastercontainerWidgets[i].width;
                item.x = this.MastercontainerWidgets[i].x;
                item.y = this.MastercontainerWidgets[i].y;
                this.containerWidgets.push(item);
            }
        } catch (e) {
            console.log(e);
        }
        
    }
    resetWidgetContainer() {
        try {
            let graveyard = document.getElementById("graveyarddiv" + this.seq);
            for (let masterwidget of this.MastercontainerWidgets) {
                let widgetElm = document.getElementById("widget_" + masterwidget.id + "_" + this.seq);
                if (widgetElm != null) {
                    widgetElm.style.height = this.calcWidgetHeight(masterwidget.height) + "px";
                    widgetElm.style.width = this.calcWidgetWidth(masterwidget.width) + "px";
                }
                let gridsquare = document.getElementById(this.seq + "row" + masterwidget.y + "col" + masterwidget.x);
                if (gridsquare != null) {
                    gridsquare.appendChild(widgetElm);
                }
                
            }
            for (let addwidget of this.widgetsToAdd) {
                let widgetElm = document.getElementById("widget_" + addwidget + "_" + this.seq);

                if (widgetElm != null) {
                    graveyard.appendChild(widgetElm);
                    graveyard.removeChild(widgetElm);

                }
            }
            this.copyMastertoWidgets();
            this.trimRows();
        } catch (e) {
            console.log(e);
        }
        
    }

    DeleteWidgetCleanup() {
        try {
            let graveyard = document.getElementById("graveyarddiv" + this.seq);
            for (var i = 0; i < this.widgetsToDelete.length; i++) {
                let elementToRemove = document.getElementById("widget_" + this.widgetsToDelete[i] + "_" + this.seq);
                if (elementToRemove != null) {
                    graveyard.appendChild(elementToRemove);
                    graveyard.removeChild(elementToRemove);
                }
                this.pinnacleservice.removeWidgetFromContainer(this.widgetsToDelete[i]).subscribe(res => { }, err => { console.log(err); });
            }
            this.widgetsToDelete.splice(0, this.widgetsToDelete.length);
        } catch (e) {
            console.log(e);
        }
       


    }


    DiscardCleanup() {
        try {
            let graveyard = document.getElementById("graveyarddiv" + this.seq);
            for (var i = 0; i < this.widgetsToAdd.length; i++) {
                let elementToRemove = document.getElementById("widget_" + this.widgetsToAdd[i] + "_" + this.seq);
                if (elementToRemove != null) {
                    graveyard.appendChild(elementToRemove);
                    graveyard.removeChild(elementToRemove);
                }
                this.pinnacleservice.removeWidgetFromContainer(this.widgetsToAdd[i]).subscribe(res => { }, err => { console.log(err); });
            }
            this.widgetsToAdd.splice(0, this.widgetsToAdd.length);
        } catch (e) {
            console.log(e);
        }
    }

    trimRows(selectedarray:Array<ContainerWidgets> = this.containerWidgets, seq:number = this.seq) {
        let largestY = 0;
        for (let w of selectedarray) {
            if (w.y + w.height > largestY) {
                largestY = w.y + w.height;
            }
        }
        let htmlRows = document.getElementsByClassName("rowWidgetGrid" + seq);
        let largestRow = 0;

        for (var i = 0; i < htmlRows.length; i++) {
            if ((<HTMLElement>htmlRows[i]).style.display != "none") {
                largestRow = largestRow + 1;
            }
        }
        if (largestY > 0) {

            if (largestY >= largestRow) {
                //we need to add rows
                let rowstoadd = largestY - largestRow;
                for (var i = 0; i < rowstoadd + 2; i++) {
                    $('#' + seq + 'Row' + (largestRow + i)).css('display', 'block');
                }
            }
            else if (largestY < largestRow) {
                //we need to remove rows
                //this.rowCounts.splice(largestY + 2);
                if (largestY < 15) {
                    largestY = 15;
                }
                for (var i = largestY + 2; i < this.rowCounts.length; i++) {
                    $('#' + seq + 'Row' + i).css('display', 'none');
                }
            }
            //if (!this.changeDetector['destroyed']) {
                //this.changeDetector.detectChanges();
            //}
            
            
        }
    }

    reSequenceWidgets(widgetid: string, seqOrder: number, oldSeqOrder:number) {
        //this method takes a widget id and its corresponding index number. it then goes through all containerWidgets, looks for duplicate sequences, and renumbers them accordingly

        for (var i = 0; i < this.containerWidgets.length; i++) {
            let widgetitem = this.containerWidgets[i];
            if (widgetitem.id != widgetid) {
                if (+widgetitem.obj.widgetProps[widgetitem.obj.widgetProps.findIndex(x => x.propID == "MOBILE_SEQUENCE")].propValue == seqOrder) {
                    if (seqOrder > oldSeqOrder) {//the widget has moved down the order, all widgets between need to be moved up
                        //widgets are being moved up so the movement direction is -1
                        for (var j = 0; j < this.containerWidgets.length; j++) {
                            let loopeditem = this.containerWidgets[j];
                            if (loopeditem.id != widgetid) {
                                let loopedpropval = +loopeditem.obj.widgetProps[loopeditem.obj.widgetProps.findIndex(x => x.propID == "MOBILE_SEQUENCE")].propValue;
                                if (this.compareSequencing(loopedpropval, oldSeqOrder, seqOrder) == true) {

                                    let newpropArray = this.createNewSeqProp(loopedpropval,-1);                                   
                                    //the newpropArray will only have one item in it, being the mobile_sequence
                                    loopeditem.obj.widgetProps[loopeditem.obj.widgetProps.findIndex(x => x.propID == "MOBILE_SEQUENCE")].propValue = (newpropArray[0].propValue).toString();

                                    loopeditem.obj.onPropsChanged(newpropArray, true, false);
                                }
                            }
                        }
                    }
                    else if (seqOrder < oldSeqOrder) {//the widget has moved up the order, all widgets between need moved down
                        //widgets are being moved up so the movement direction is +1
                        for (var j = 0; j < this.containerWidgets.length; j++) {
                            let loopeditem = this.containerWidgets[j];
                            if (loopeditem.id != widgetid) {
                                let loopedpropval = +loopeditem.obj.widgetProps[loopeditem.obj.widgetProps.findIndex(x => x.propID == "MOBILE_SEQUENCE")].propValue;
                                if (this.compareSequencing(loopedpropval, seqOrder, oldSeqOrder) == true) {

                                    let newpropArray = this.createNewSeqProp(loopedpropval, 1);   
                                    //the newpropArray will only have one item in it, being the mobile_sequence
                                    loopeditem.obj.widgetProps[loopeditem.obj.widgetProps.findIndex(x => x.propID == "MOBILE_SEQUENCE")].propValue = (newpropArray[0].propValue).toString();

                                    loopeditem.obj.onPropsChanged(newpropArray, true, false);
                                }
                            }
                        }
                    }
                    else if (seqOrder == oldSeqOrder) {//the order hasnt changed so we dont need to do anything
                        //widgets have the same order so no movement needs to occur
                    }
                }
            }
        }
    }



    compareSequencing(checkVal: number, checkSeqA: number, checkSeqB: number): boolean {
        if (checkVal >= checkSeqA && checkVal <= checkSeqB) {
            return true;
        }
        else {
            return false;
        }
    }

    createNewSeqProp(propval:number,propMovementVal: number): Array<WidgetProperties> {
        let newpropArray = new Array<WidgetProperties>();
        let newprop = new WidgetProperties();
        newprop.propName = "Display & Sequence";
        newprop.propValue = (propval + propMovementVal).toString();
        newprop.propType = "mobileSequence";
        newprop.propID = "MOBILE_SEQUENCE";
        newpropArray.push(newprop);
        return newpropArray;
    }

    incrementRenderedWidget() {
        this.renderedWidgets++;
        if (this.renderedWidgets == this.containerWidgets.length) {
            this.widgetsOnGrid.emit(null);
            this.renderedWidgets = 0;
        }
        if (this.renderedWidgets > this.containerWidgets.length) {
            this.renderedWidgets = 0;
        }
    }
}


export class CollisionSizingReference {
    itemID: string = "";
    itemWidth: number = 0;
    itemHeight: number = 0;
    gridLocation: string = "";
    gridCoverage: Array<string> = new Array<string>();
}


export class Guid {
    constructor(public guid: string) {
        this._guid = guid;
    }

    private _guid: string;

    public toString(): string {
        return this.guid;
    }

    // Static member
    static MakeNew(): Guid {
        var result: string;
        var i: string;
        var j: number;

        result = "";
        for (j = 0; j < 32; j++) {
            if (j == 8 || j == 12 || j == 16 || j == 20)
                result = result + '-';
            i = Math.floor(Math.random() * 16).toString(16).toUpperCase();
            result = result + i;
        }
        return new Guid(result);
    }
}

export class ExpandCollisionAffectedWidgets {
    widgetId: string = "";
    affectedWidgetIds: Array<string> = new Array<string>();
    expandAmt: number = 0;
}

//export class WidgetExpandAmount {
//    widgetId: string = "";
//    expandAmt: number = 0;
//}

