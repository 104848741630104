// Angular imports
import { AfterViewInit, Component, OnInit, ViewChild, EventEmitter, Output, Input } from '@angular/core';
import { ActivatedRoute, Router, Route, Params } from '@angular/router';
import { DatePipe } from '@angular/common';

// DevExpress imports
import { DxSchedulerModule, DxSchedulerComponent } from 'devextreme-angular';
import DevExpress from 'devextreme/bundles/dx.all';

// Pinnacle imports
import { ProdGenApi } from '../../apiService/prodgen.api';
import { BrowserAuthenticationService } from '../../BrowserAuthenticationService';
import { TranslationService } from '../../services/TranslationService';
import { LiveEventWrapper, LiveEventBrowserCalendarStatus, LiveEventProperty, LiveEventPropertyType } from '../../../app/apiService/classFiles/class.liveevents';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { LiveEventRegistrationForm } from '../liveeventregistrationform/liveeventregistrationform.component';
import { currenttheme } from '../../app.component';
import { ContentPrerequisite } from '../../apiService/classFiles/class.content';
import { User } from '../../apiService/classFiles/class.users';
import { PinnacleDateTimePipe } from '../../pipes/pinnacleDateTime'

// JQuery workaround
declare var $: any;

@Component({
    selector: 'template-upcomingeventspanel',
    templateUrl: 'upcomingeventspanel.component.html',
    styleUrls: ['upcomingeventspanel.component.css'],
    providers: [ProdGenApi]
})

export class UpcomingEventsPanelComponent implements OnInit, AfterViewInit {

    dateRangeString: string = "";
    datetimeOffset: string;
    currentUser: User;
    currentDateTime: Date = new Date(Date.now());
    datepipe: DatePipe;
    @ViewChild(LiveEventRegistrationForm, { static: false }) registrationForm: LiveEventRegistrationForm;
    @Input() allLiveEvents: Array<LiveEventWrapper> = new Array<LiveEventWrapper>();
    @Output() allLiveEventsChange: EventEmitter<Array<LiveEventWrapper>> = new EventEmitter<Array<LiveEventWrapper>>();
    @Output() allEventsLoaded: EventEmitter<any> = new EventEmitter<any>();
    filteredLiveEvents: Array<LiveEventWrapper> = new Array<LiveEventWrapper>();
    selectedEvent: LiveEventWrapper = new LiveEventWrapper(
        "00000000-0000-0000-0000-000000000000",         // Event ID
        "",                                         // Event name
        "",                                         // Simple description
        "",                                         // Formatted description
        "",                                         // Keywords
        new Date(1970, 0, 1, 0, 0, 0, 0),           // Previous recording date
        "00000000-0000-0000-0000-000000000000",         // Previous recording video ID
        "00000000-0000-0000-0000-000000000000",         // Folder ID
        false,                                      // Is deleted
        "00000000-0000-0000-0000-000000000000",         // Provided by
        0,                                          // Duration (mins)
        new Array<ContentPrerequisite>(),           // Prerequisites
        new Array<LiveEventProperty>(),             // Properties
        "00000000-0000-0000-0000-000000000000",         // Session ID
        "00000000-0000-0000-0000-000000000000",         // Presenter ID
        "",                                         // Presenter display name
        "00000000-0000-0000-0000-000000000000",         // Scheduled by
        new Date(1970, 0, 1, 0, 0, 0, 0),           // Session datetime (unused)
        "",                                         // Session URL
        "",                                         // Session location
        LiveEventBrowserCalendarStatus.Internal,    // Session status
        new Date(1970, 0, 1, 0, 0, 0, 0),           // Session start datetime
        new Date(1970, 0, 1, 0, 0, 0, 0),           // Session end datetime
        false,                                      // All day session (unused)
        false,                                      // Use registration dates
        new Date(1970, 0, 1, 0, 0, 0, 0),           // Registration start date
        new Date(1970, 0, 1, 0, 0, 0, 0),           // Registration end date
        "00000000-0000-0000-0000-000000000000",
        false                                       // Session attended
    );
    cancelButtonText: string = "Close";
    componentHeight: string = "100%";
    upcomingEventsHeadingRadius: string = "0px";
    eventsLoaded: boolean = false;
    eventFilterType: string = "upcomingevents";
    hasExternalEvents: boolean = false;
    @Input() calendarPresent: boolean = false;
    @Output() selectedEventChange: EventEmitter<LiveEventWrapper> = new EventEmitter<LiveEventWrapper>();
    @Input() panelFillType: string = "home"; // home, learningcenter, widget
    @Input() widgetDisplayType: string = "panel"; // panel, overlay
    @Input() widgetTitle: string = "Upcoming Live Events"; // home, learningcenter, widget

    constructor(private pinnacleService: ProdGenApi,
                private route: ActivatedRoute,
                private router: Router,
                private authenticationService: BrowserAuthenticationService,
                private translationService: TranslationService,
                private sanitizer: DomSanitizer) {

        this.datepipe = new DatePipe("en-US");
        let d: number = new Date(Date.now()).getTimezoneOffset();
        this.datetimeOffset = "";
        if (d < 0) {
            this.datetimeOffset += "+";
        }
        else {
            this.datetimeOffset += "-";
        }
        if (d < 1000) {
            this.datetimeOffset += "0";
        }
        this.datetimeOffset += d.toString();
        
        if (currenttheme.properties["--panel-border-radius"] != "0px") {
            let value: number = +(currenttheme.properties["--panel-border-radius"] as string).slice(0, -2); // "15px" (slice)-> "15" (+)-> 15
            value -= +(currenttheme.properties["--panel-border-width"] as string).slice(0, -2);
            if (value < 0) {
                value = 0;
            }
            this.upcomingEventsHeadingRadius = value + "px";
        }
        this.pinnacleService.getCurrentUser().subscribe(res => {
            this.currentUser = res;
        },
            e => {
                this.currentUser = new User();
            });
    }

    ngOnInit(): void {
        //console.log(this.panelFillType);
        if (this.panelFillType == "home") {
            this.componentHeight = "200px";
        }
        if (this.panelFillType == "learningcenter") {
            this.componentHeight = "393px";
        }
        if (this.panelFillType == "widget") {
            this.componentHeight = "70%";
        }

        if (!this.calendarPresent) {
            let v_propertyTypeWhiteList = [LiveEventPropertyType.EventSessionProperty];
            this.pinnacleService.leGetLiveEventSessionWrappersInRange(new Date(Date.now()), new Date(Date.now() + 5184000000 /* 60 days from now */), [], v_propertyTypeWhiteList).subscribe(res => {
                for (let i: number = 0; i < res.length; i++) {
                    let w: LiveEventWrapper = res[i];

                    if (w.sessionStatus == LiveEventBrowserCalendarStatus.External) {
                        this.hasExternalEvents = true;
                    }

                    if (w.name != "SESSION_RESTRICTED") {
                        w.startDate = PinnacleDateTimePipe.toLocalDateTime(new Date(w.startDate));
                        w.endDate = PinnacleDateTimePipe.toLocalDateTime(new Date(w.endDate));
                        w.registrationStartDate = PinnacleDateTimePipe.toLocalDateTime(new Date(w.registrationStartDate));
                        w.registrationEndDate = PinnacleDateTimePipe.toLocalDateTime(new Date(w.registrationEndDate));
                        this.allLiveEvents.push(w);
                        this.filteredLiveEvents.push(w);
                    }
                }
                this.eventsLoaded = true;
                this.allEventsLoaded.emit();
            });
        }
    }

    ngAfterViewInit(): void {

    }

    filterEvents(): void {
        this.filteredLiveEvents = [];
        for (let i: number = 0; i < this.allLiveEvents.length; i++) {
            let w: LiveEventWrapper = this.allLiveEvents[i];
            w.startDate = new Date(w.startDate);
            w.endDate = new Date(w.endDate);
            if (w.endDate > this.currentDateTime) {
                this.filteredLiveEvents.push(w);
            }
        }

        // liveeventcalendar will sort for us if present
        if (!this.calendarPresent) {
            this.filteredLiveEvents.sort((a, b) => {
                if (a.startDate == b.startDate)
                    return a.endDate.getTime() - b.endDate.getTime();
                return a.startDate.getTime() - b.startDate.getTime();
            });
        }
        this.eventsLoaded = true;
    }

    setSelectedSession(event): void {
        this.selectedEvent = event;
        this.selectedEvent.endDate = new Date(event.endDate);
        this.selectedEvent.properties = event.properties;
        this.registrationForm.selectedEvent = this.selectedEvent;
        this.registrationForm.selectedEvent.properties = this.selectedEvent.properties;
        this.registrationForm.showForm();
        this.registrationForm.selectedEventChange.subscribe(event => this.emitSelectedEventChange(event));
    }

    emitSelectedEventChange(event): void {
        event.startDate = new Date(event.startDate);
        event.endDate = new Date(event.endDate);
        this.selectedEventChange.emit(event);
        this.allLiveEventsChange.emit(this.allLiveEvents);
    }

    getLiveEventStatusString(liveEvent: LiveEventWrapper): string {
        if (liveEvent.sessionStatus == LiveEventBrowserCalendarStatus.Required) {
            return "[Assigned] " + liveEvent.name;
        }
        return "[" + LiveEventBrowserCalendarStatus[liveEvent.sessionStatus] + "] " + liveEvent.name;
    }

    getLiveEventStatusColorString(liveEvent: LiveEventWrapper): string {
        return "liveEvent" + LiveEventBrowserCalendarStatus[liveEvent.sessionStatus] + "Color";
    }

    changeEventFilterType(filterType: string) {
        this.eventFilterType = filterType;
        switch (filterType) {
            case "myevents":
                this.filteredLiveEvents = new Array<LiveEventWrapper>();
                for (let i = 0; i < this.allLiveEvents.length; i++) {
                    if (this.allLiveEvents[i].sessionStatus == LiveEventBrowserCalendarStatus.Registered
                        || this.allLiveEvents[i].sessionStatus == LiveEventBrowserCalendarStatus.Invited
                        || this.allLiveEvents[i].sessionStatus == LiveEventBrowserCalendarStatus.Required
                        || this.allLiveEvents[i].presenterID == this.currentUser.userId) {
                        this.filteredLiveEvents.push(this.allLiveEvents[i]);
                    }
                }
                break;
            case "allevents":
                this.filteredLiveEvents = this.allLiveEvents;
                break;
            case "externalevents":
                this.filteredLiveEvents = new Array<LiveEventWrapper>();
                for (let i = 0; i < this.allLiveEvents.length; i++) {
                    if (this.allLiveEvents[i].sessionStatus == LiveEventBrowserCalendarStatus.External) {
                        this.filteredLiveEvents.push(this.allLiveEvents[i]);
                    }
                }
                break;
            case "internalevents":
                this.filteredLiveEvents = new Array<LiveEventWrapper>();
                for (let i = 0; i < this.allLiveEvents.length; i++) {
                    if (this.allLiveEvents[i].sessionStatus == LiveEventBrowserCalendarStatus.Internal) {
                        this.filteredLiveEvents.push(this.allLiveEvents[i]);
                    }
                }
                break;
            case "registeredevents":
                this.filteredLiveEvents = new Array<LiveEventWrapper>();
                for (let i = 0; i < this.allLiveEvents.length; i++) {
                    if (this.allLiveEvents[i].sessionStatus == LiveEventBrowserCalendarStatus.Registered) {
                        this.filteredLiveEvents.push(this.allLiveEvents[i]);
                    }
                }
                break;
            case "assignedevents":
                this.filteredLiveEvents = new Array<LiveEventWrapper>();
                for (let i = 0; i < this.allLiveEvents.length; i++) {
                    if (this.allLiveEvents[i].sessionStatus == LiveEventBrowserCalendarStatus.Required) {
                        this.filteredLiveEvents.push(this.allLiveEvents[i]);
                    }
                }
                break;
            case "invitedevents":
                this.filteredLiveEvents = new Array<LiveEventWrapper>();
                for (let i = 0; i < this.allLiveEvents.length; i++) {
                    if (this.allLiveEvents[i].sessionStatus == LiveEventBrowserCalendarStatus.Invited) {
                        this.filteredLiveEvents.push(this.allLiveEvents[i]);
                    }
                }
                break;
            case "upcomingevents":
                this.filteredLiveEvents = new Array<LiveEventWrapper>();
                for (let i = 0; i < this.allLiveEvents.length; i++) {
                    if (this.allLiveEvents[i].endDate > this.currentDateTime) {
                        this.filteredLiveEvents.push(this.allLiveEvents[i]);
                    }
                }
                break;
            case "previousevents":
                this.filteredLiveEvents = new Array<LiveEventWrapper>();
                for (let i = 0; i < this.allLiveEvents.length; i++) {
                    if (this.allLiveEvents[i].endDate < this.currentDateTime) {
                        this.filteredLiveEvents.push(this.allLiveEvents[i]);
                    }
                }
                break;
            case "presentingevents":
                this.filteredLiveEvents = new Array<LiveEventWrapper>();
                for (let i = 0; i < this.allLiveEvents.length; i++) {
                    if (this.allLiveEvents[i].presenterID == this.currentUser.userId) {
                        this.filteredLiveEvents.push(this.allLiveEvents[i]);
                    }
                }
            default:
                break;
        }
    }
}