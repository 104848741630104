import { Injectable } from '@angular/core';
import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Content, ContentType_T } from './../../apiService/classFiles/class.content';
import { WorkgroupExternalMembers, WorkgroupExternalGroup } from './../../apiService/classFiles/class.workgroups';
import { ProdGenApi } from './../../apiService/prodgen.api';

declare var $: any;

@Component({
    selector: 'workgroupexternalusers',
    templateUrl: './workgroupexternalusers.html',
    styleUrls: ['./workgroupexternalusers.css'],
    providers: [ProdGenApi]
})
export class WorkgroupExternalUsersComponent implements OnInit {

    @Input('workgroupId') workgroupId: string;

    externalUsersLoadedFull: boolean = true;
    externalMemberEditMode: boolean = false;

    externalGroupList: Array<WorkgroupExternalGroup> = new Array<WorkgroupExternalGroup>();

    expandedGroupSet: Object = {};
    externalemail: string = "";
    externalgroupid: string = "";
    externalIsManager: boolean = false;
    externaldisplayname: string = "";
    externalimageurl: string = "";

    externalgroupselectorisgroup: boolean = true;
    externalgroupName: string = "";

    selectedGroupID: string = "";

    constructor(private service: ProdGenApi)
    {

    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
        try {
            if (changes['workgroupId'] != null) {
                this.workgroupId = changes['workgroupId'].currentValue;
                
                this.loadData();
            }
        } catch (err) {
            console.log(err);
        };
    }

    loadData() {
        this.externalUsersLoadedFull = false;
        if (this.workgroupId != null) {
            this.service.GetWorkgroupExternalGroups(this.workgroupId).subscribe(res => {




                for (var i = 0; i < res.length; i++) {

                    for (var j = 0; j < res[i].members.length; j++) {
                        if (res[i].members[j].imageURL == "") {
                            res[i].members[j].imageURL = "../assets/images/default_user.jpg"
                        }
                    }
                }

                this.externalGroupList = res;


                for (let g of res) {


                    this.expandedGroupSet[g.id] = true;
                }

                this.externalUsersLoadedFull = true;
            });
        }
    }

    toggleExternalEditMode() {
        this.externalMemberEditMode = !this.externalMemberEditMode;
    }

    amIExternalGroupEditor(g: WorkgroupExternalGroup) {
        return this.externalMemberEditMode;
    }

    onExternalGroupFacetHeaderClicked(g: WorkgroupExternalGroup) {

        var isExpanded = $("#image_" + g.id).hasClass("fa-minus");
        $("#image_" + g.id).toggleClass("fa-minus");
        $("#image_" + g.id).toggleClass("fa-plus");


        if (isExpanded) {
            this.expandedGroupSet[g.id] = true;
        }
        else {
            this.expandedGroupSet[g.id] = false;
        }
        
        
    }



    addMembersClick(g: WorkgroupExternalGroup) {
        

    }

    removeExternalMember(g: WorkgroupExternalGroup, u: WorkgroupExternalMembers)
    {
        if (g.members.length > 1) {
            this.service.DeleteExternalWorkgroupMember(this.workgroupId, g.id, u.id).subscribe(m => { this.loadData(); });
        }
        else
        {
            this.service.DeleteExternalWorkgroup(this.workgroupId, g.id).subscribe(m => { this.loadData(); });
        }
    }


    addExternalMembersClick(g: WorkgroupExternalGroup) {
        this.externalemail = "";
        this.externalgroupid = g.id;
        this.externalIsManager = false;
        this.externaldisplayname = "";
        this.externalimageurl = "";
        this.externalgroupselectorisgroup = false;
    }

    finishAddingMembers() {

        this.service.IsExistingUser(this.externalemail).subscribe(val => {

            if (val == true) {
                this.service.SetExternalWorkgoupMember(this.workgroupId, this.externalemail, this.externalgroupid, this.externalIsManager).subscribe(res => {

                    this.loadData();
                });
            }
            else {

                /*
                this.service.addNewExternalUser(this.externalemail, this.externaldisplayname, this.externalimageurl).subscribe(res => {
                    this.service.SetExternalWorkgoupMember(this.workgroupId, this.externalemail, this.externalgroupid, this.externalIsManager).subscribe(res => {

                        this.loadData();
                    });
                });
                */
                //$('#addNewExternalMembersModal').modal('show');
                this.finishAddingNewUser();
            }

        });

    }

    finishAddingNewUser() {
        this.service.addNewExternalUser(this.externalemail, this.externaldisplayname, this.externalimageurl).subscribe(res => {
            /*
            if (this.externalgroupselectorisgroup == true) {
                this.finishAddingGroup();
            }
            else {
                this.finishAddingMembers();
            }
            */           

            if (this.selectedGroupID == "") {
                this.service.SetExternalWorkgoup(this.workgroupId, this.externalemail, false).subscribe(res2 => {
                    this.service.SetExternalWorkgoupMember(this.workgroupId, this.externalemail, res2.id, this.externalIsManager).subscribe(res3 => {

                        this.loadData();
                    });
                });
            }
            else {
                this.service.SetExternalWorkgoupMember(this.workgroupId, this.externalemail, this.selectedGroupID, this.externalIsManager).subscribe(res => {

                    this.loadData();
                });
            }
        });
    }

    changeIsManagerSetting() {
        this.externalIsManager = !this.externalIsManager;
    }

    addExternalGroupClick() {
        this.externalemail = "";
        this.externalgroupid = "";
        this.externalIsManager = false;
        this.externaldisplayname = "";
        this.externalimageurl = "";
        this.externalgroupName = "";
        this.externalgroupselectorisgroup = true;

        //console.log(this.externalgroupselectorisgroup);
    }

    finishAddingGroup() {
        if (this.externalgroupselectorisgroup == true) {
            this.service.SetExternalWorkgoup(this.workgroupId, this.externalgroupName, true).subscribe(res => {
                this.loadData();
            });
        }
        else {
            this.service.IsExistingUser(this.externalemail).subscribe(val => {
                if (val == true) {
                    this.service.SetExternalWorkgoup(this.workgroupId, this.externalemail, false).subscribe(res => {
                        this.service.SetExternalWorkgoupMember(this.workgroupId, this.externalemail, res.id, this.externalIsManager).subscribe(res2 => {

                            this.loadData();
                        });
                    });
                }
                else {

                }
                
            });
        }

    }

    groupChanged(event) {

    }

    filterGroups() {
        return this.externalGroupList.filter(x => x.multimember == true);
    }

}
