import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, Route, Params } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { ProdGenApi } from './../apiService/prodgen.api';
import { DescriptionFilter_T, LearningPath, Course, CourseWithContent, Content, ContentType_T, ContentUsedProduct, ContentUsedProductVersion, ContentPrerequisiteInfo, ContentAuthoring} from './../apiService/classFiles/class.content';
import { Enrollment, EnrollmentFilter_T, EnrollmentStatus_T } from './../apiService/classFiles/class.enrollments';
import { BrowserAuthenticationService } from './../BrowserAuthenticationService';
import { TranslationService } from './../services/TranslationService';

declare var $: any;

@Component({
  selector: 'app-learningcenterseries',
  templateUrl: './learningcenterseries.component.html',
  styleUrls: ['./learningcenterseries.component.css']
})
export class LearningcenterseriesComponent implements OnInit {
  learningPath: LearningPath = new LearningPath();
  courses: Array<Course> = new Array<Course>();
  coursesAndContent: Array<CourseWithContent> = new Array<CourseWithContent>();

  currentEnrollments: Array<Enrollment> = new Array<Enrollment>();
  completedEnrollments: Array<Enrollment> = new Array<Enrollment>();
  showFullDescription: boolean = false;

	initialCourseId: string = "";
  isAuthenticated: boolean = false;
  usedProducts: Array<string> = new Array<string>();
  productVersions: Array<ContentUsedProductVersion> = new Array<ContentUsedProductVersion>();
    products: Array<ContentUsedProduct> = new Array<ContentUsedProduct>();
  noErrs: boolean = true;
    friendlyMsg: SafeHtml;
    contentAuthoring: ContentAuthoring = new ContentAuthoring();

	constructor(private pinnacleService: ProdGenApi, private route: ActivatedRoute, private router: Router, private _sanitizer: DomSanitizer, private authenticationService: BrowserAuthenticationService, private translationService: TranslationService) {
    }

    ngOnInit() {
        if (this.authenticationService.AuthenticatePage() == true) {
            this.isAuthenticated = true;
            let courseId: string = ""
            let lpId: string = ""

            this.pinnacleService.getCurrentUserEnrollments([EnrollmentFilter_T.assigned, EnrollmentFilter_T.inProgress]).subscribe(res => {
                this.currentEnrollments = res;
            });

            this.pinnacleService.getCurrentUserEnrollments([EnrollmentFilter_T.completed]).subscribe(res => {
                this.completedEnrollments = res.sort((a, b) => (a.statusDate < b.statusDate) ? 1 : -1);
            });

            this.route.queryParams.subscribe(queryParams => {
                if (queryParams['courseId'] != null) {
                    courseId = queryParams['courseId'];
                }

                if (queryParams['learningPathId'] != null) {
                    lpId = queryParams['learningPathId'];
                }

                if (courseId.length == 0 && lpId.length == 0) {
                    // invalid...navigate back to learning center
                }
                else {
                    if (lpId.length > 0 && courseId.length == 0) {
                        this.loadLearningPath(lpId);
                        this.getAuthoringContent(lpId);
                    }

                    if (courseId.length > 0) {
                        // load up the course
                        this.initialCourseId = courseId;
                        // get the lpid for the course
                        // then load the learning path;
                        this.pinnacleService.GetCourse(courseId, DescriptionFilter_T.none).subscribe(c => {
                            this.loadLearningPath(c.learningPathId);
                            this.getAuthoringContent(c.learningPathId as string);

                        });

                    }
                }

            });
        }
  }
    
  loadLearningPath(lpId: String)
  {
      let tempArray = new Array<CourseWithContent>();
      this.coursesAndContent = new Array<CourseWithContent>();
      this.usedProducts = new Array<string>();
      // load up the learning path
      this.pinnacleService.GetLearningPath(lpId, DescriptionFilter_T.formatted).subscribe(lp => {
          this.pinnacleService.GetContentUsedProducts(lp.learningPathId, ContentType_T.learningpath.toString()).subscribe(products => {
              for (var i = 0; i < products.length; i++) {
                  this.products = products;
                  this.productVersions = this.products[i].Versions;

                  if (this.productVersions.length > 0) {
                      for (var j = 0; j < this.productVersions.length; j++) {
                        this.usedProducts.push(this.products[i].Name + " " + this.productVersions[j].Name);
                      }
                  }
                  else {
                    this.usedProducts.push(this.products[i].Name.toString());
                  }
              }
          });
          this.learningPath = lp;
          // now, get the courses
          this.pinnacleService.GetLearningPathCourses(lp.learningPathId, -1, 0, DescriptionFilter_T.none).subscribe(c => {
              this.courses = c;
              let courseIndex = 0;

              for (let course of this.courses) {
                  //// content items and description will be loaded on demand
                  
                  // create a courses with content 
                  let stuff = new CourseWithContent();
                  stuff.courseId = course.courseId;
                  stuff.description = course.description;
                  stuff.learningPathId = course.learningPathId;
                  stuff.name = course.name;
                  stuff.providedBy = course.providedBy;
                  stuff.sequence = course.sequence;
                  stuff.version = course.version;
                  //stuff.content = content;

                  tempArray.push(stuff);
                  if (tempArray.length == this.courses.length)
                  {
                      // we got them all...sort by the seq
                      tempArray.sort((r1, r2) => { return r1.sequence - r2.sequence });
                  }
              }
              this.coursesAndContent = tempArray;
          });
      }  , err => {
              //display friendly message to user so they are aware of what happened (& have no access to asset if they shouldn't)
              this.noErrs = false;
              this.learningPath.name = this.translationService.getTranslationFileData("LEARNINGCENTERCOURSEVIEWER.TS_Error");

              if (err.status == 400) {
                  //bad request caused by 0 data rows and/or no content ids for user - user has no access
                  this.friendlyMsg = this._sanitizer.bypassSecurityTrustHtml(this.translationService.getTranslationFileData("APP.AssetViewing0DRs"));
              }
              else {
                  //for now, let's give generic error as there are multiple errors possibly thrown by services (for which I added most common to translate file in case a specific one is wanted)
                  this.friendlyMsg = this._sanitizer.bypassSecurityTrustHtml(this.translationService.getTranslationFileData("APP.AssetViewingError"));
              }
          }
      );
  }

  getActiveDesc(): SafeHtml {
      let strDesc = this.learningPath.description;
      if (strDesc == null)
      {
          strDesc = "Loading...";
      }
      return this._sanitizer.bypassSecurityTrustHtml(strDesc as string);
  }

  toggleShowFullDescription() {
      this.showFullDescription = !this.showFullDescription;
    }

    getAuthoringContent(c: string) {
        if (c != null) {
            this.pinnacleService.getContentAuthoringData(c, ContentType_T.learningpath.toString()).subscribe(d => {
                if (d != null && d.lastModBy != "") {
                    this.contentAuthoring.contentId = d.contentId;
                    this.contentAuthoring.lastModBy = d.lastModBy;
                    this.contentAuthoring.lastModDate = d.lastModDate;
                    this.contentAuthoring.lastModDateStr = d.lastModDateStr;
                }
            });
        }
        else {
            this.contentAuthoring = new ContentAuthoring();
        }
    }
}
