import { AfterViewInit, Directive, ElementRef, HostListener, Input, OnChanges, OnDestroy, Renderer2, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AppControlService } from './AppControlService';

declare function doesFontExist(fontName: string): boolean;

@Directive({
	selector: '[resizeText]'

})



export class ResizeTextDirective implements AfterViewInit, OnChanges {

	@Input() resizeText: string;


	constructor(private el: ElementRef, private renderer: Renderer2, private domSanitizer: DomSanitizer, private router: Router, private controlService: AppControlService) {
		
	}


	ngAfterViewInit() {
		
	}

	ngOnChanges(changes) {

		this.resizeElementText();

	}


	resizeElementText() {

		let v_Height = this.el.nativeElement.offsetHeight;
		let v_Width = this.el.nativeElement.offsetWidth;
		let v_FontSizeText = this.el.nativeElement.style.fontSize as string;
		var v_FontMultiplier = 1;

		if (v_FontSizeText.search("px") != -1) {
			v_FontSizeText = v_FontSizeText.replace("px", "");
		}
		else if (v_FontSizeText.search("em") != -1) {
			v_FontMultiplier = 16;
			v_FontSizeText = v_FontSizeText.replace("em", "");
		}

		if (v_FontSizeText == "") {
			v_FontSizeText = "8";
		}

		var v_FontSizeNum = +v_FontSizeText;
		v_FontSizeNum = v_FontSizeNum * v_FontMultiplier;

		
		if (this.resizeText.length * v_FontSizeNum > v_Width) {


			var v_NewFontSize = Math.floor((v_Width - .5) / this.resizeText.length);

			if (v_NewFontSize == 0)
			{
				v_NewFontSize = v_FontSizeNum;
			}

			this.el.nativeElement.style.setProperty('font-size', (v_NewFontSize * 2).toString() + 'px')
			//this.el.nativeElement.style.setProperty('font-size', '15' + 'px')

			
		}


		

		this.el.nativeElement.innerText = this.resizeText;
         

		

	}

    /*
	getTextAdjustedSize(p_Length: number, p_TextSize: number, p_MaxWidth: number) {
		if (p_Length * p_TextSize < p_MaxWidth) {

		}
		else {

		}
	}
    */
}