import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { YesNoSelectionList, RequiredYesNoSelectionList } from 'src/app/apiService/classFiles/v2-externallearning';

@Component({
    selector: 'app-dynamic-attribute-input-field',
    templateUrl: './dynamic-attribute-input-field.component.html',
    styleUrls: ['./dynamic-attribute-input-field.component.css']
})
export class DynamicActivityInputFieldComponent {
    @Input() activityAttribute: DynamicInputBase<any>;
    @Input() form: FormGroup;

    yesNoList: Array<string> = YesNoSelectionList;
    requiredYesNoList: Array<string> = RequiredYesNoSelectionList;

    setAttributeValue(attribute: DynamicInputBase<any>, newValue: any): void {
        this.form.get(attribute.key).setValue(newValue);
        this.form.markAsDirty();
    }

    getAttributeValue(attribute: DynamicInputBase<any>): any {
        return this.form.get(attribute.key).value;
    }

    validatekeyDown(key: string) {
        if (!this.validCharacter(key)) {
            event.preventDefault();
        }
    }

    onPaste(event: ClipboardEvent) {
        let clipboardData = event.clipboardData;
        let pastedText = clipboardData.getData('text');

        let pastedCharacters = Array.from(pastedText);

        if (pastedCharacters.length > 0) {
            for (var index = 0; index < pastedCharacters.length; index++) {
                if (!this.validCharacter(pastedCharacters[index])) {
                    event.preventDefault();
                }
            }
        }
    }

    validatePositiveIntegerInput(event: KeyboardEvent, activityAttribute: DynamicInputBase<any>) {
        if (this.isInvalidNumberInput(event.keyCode)) {
            event.preventDefault();
        }

        let valueBeforeNewInput = this.form.get(activityAttribute.key).value;

        //don't allow more than one *LEADING* zeros.
        if (String(valueBeforeNewInput) == '0' &&
            event.key == '0') {
            event.preventDefault();
        }

        //If entering a number greater than 0 while we have a leading 0, replace the 0 with the number entered.
        if (String(valueBeforeNewInput) == '0' &&
            event.key != '0') {
            this.form.get(activityAttribute.key).setValue(Number(event.key));
            event.preventDefault();
        }

        if (Number(String(valueBeforeNewInput ? valueBeforeNewInput : '') + event.key) > 2147483647 ||
            Number(String(valueBeforeNewInput ? valueBeforeNewInput : '') + event.key) < 0) {
            event.preventDefault();
        }
    }

    validateRatingInput(event: KeyboardEvent, activityAttribute: DynamicInputBase<any>) {
        if (this.isInvalidNumberInput(event.keyCode)) {
            event.preventDefault();
        }

        let valueBeforeNewInput = this.form.get(activityAttribute.key).value;
        if (Number(String(valueBeforeNewInput ? valueBeforeNewInput : '') + event.key) > 5 ||
            Number(String(valueBeforeNewInput ? valueBeforeNewInput : '') + event.key) < 1) {
            event.preventDefault();
        }
    }

    validateFullIntegerInput(event: KeyboardEvent, activityAttribute: DynamicInputBase<any>) {
        if (this.isInvalidNumberInput(event.keyCode) &&
            !this.isNegativeSymbol(event.keyCode)) {
            event.preventDefault();
        }

        let valueBeforeNewInput = this.form.get(activityAttribute.key).value;

        //don't allow more than one *LEADING* zeros.
        if (String(valueBeforeNewInput) == '0' &&
            event.key == '0') {
            event.preventDefault();
        }

        //If entering a number greater than 0 while we have a leading 0, replace the 0 with the number entered.
        if (String(valueBeforeNewInput) == '0' &&
            event.key != '0') {
            this.form.get(activityAttribute.key).setValue(Number(event.key));
            event.preventDefault();
        }

        if (Number(String(valueBeforeNewInput ? valueBeforeNewInput : '') + event.key) > 2147483647 ||
            Number(String(valueBeforeNewInput ? valueBeforeNewInput : '') + event.key) < -2147483648) {
            event.preventDefault();
        }

        //If the user ever hits the minus key as to make a number negative,
        //we will check to see if there is any current, valid value and if there is,
        //put the negative at the beginning of the number. If the number is already negative,
        //ignore any minus sign inputs.
        if (this.isNegativeSymbol(event.keyCode)) {
            if (Number(valueBeforeNewInput ? valueBeforeNewInput : '') > 0) {
                this.form.get(activityAttribute.key).setValue(Number('-' + valueBeforeNewInput));
            }
            event.preventDefault();
        }
    }


    validateDecimalInput(event: KeyboardEvent, activityAttribute: DynamicInputBase<any>) {
        //0 - 2147483647
        if (this.isInvalidDecimalInput(event.keyCode) || this.isNegativeSymbol(event.keyCode)) {

            event.preventDefault();
        }

        let valueBeforeNewInput = this.form.get(activityAttribute.key).value;

        if (Number(String(valueBeforeNewInput ? valueBeforeNewInput : '') + event.key) > 2147483647 ||
            Number(String(valueBeforeNewInput ? valueBeforeNewInput : '') + event.key) < 0) {

            event.preventDefault();
        }
    }




    validatePercentageInput(event: KeyboardEvent, activityAttribute: DynamicInputBase<any>) {
        let valueBeforeNewInput = this.form.get(activityAttribute.key).value;

        if (this.isInvalidNumberInput(event.keyCode) ||
            this.isNegativeSymbol(event.keyCode)) {
            event.preventDefault();
        }

        //don't allow more than one *LEADING* zeros.
        if (String(valueBeforeNewInput) == '0' &&
            event.key == '0') {
            event.preventDefault();
        }

        //If entering a number greater than 0 while we have a leading 0, replace the 0 with the number entered.
        if (String(valueBeforeNewInput) == '0' &&
            event.key != '0') {
            this.form.get(activityAttribute.key).setValue(Number(event.key));
            event.preventDefault();
        }

        if (Number(String(valueBeforeNewInput ? valueBeforeNewInput : '') + event.key) > 100 ||
            Number(String(valueBeforeNewInput ? valueBeforeNewInput : '') + event.key) < 0) {
            event.preventDefault();
        }
    }

    onIntegerPaste(event: ClipboardEvent) {
        event.preventDefault();
    }

    private isInvalidNumberInput(keyCode: number): boolean {
        if (keyCode === 101 || // 'e'
            keyCode === 69 || // 'E'
            keyCode === 43 || // '+'
            keyCode === 187 || // '+' Plus Sign
            keyCode === 189 || // '-' Minus Sign
            keyCode === 109 || // '-' Minus Sign
            keyCode === 107 || // '+' Plus Sign
            keyCode === 110 || // '.' Period
            keyCode === 190) { // '.' Period
            return true;
        }

        return false;
    }


    private isInvalidDecimalInput(keyCode: number): boolean {
        //Numpad 0-90 is 96 - 105
        //Main numbers 0-9 is 48 - 57
        if (//keyCode === 101 || // 'e' --> Nope it's that is 5 on NumPad
            keyCode === 69 || // 'E'
            keyCode === 43 || // '+'
            keyCode === 187 || // '+' Plus Sign
            keyCode === 189 || // '-' Minus Sign
            keyCode === 109 || // '-' Minus Sign
            keyCode === 107    // '+' Plus Sign
            //keyCode === 110 || // '.' Period
            //keyCode === 190// '.' Period
        ) {
            return true;
        }
        return false;
    }

    private isNumber(value: string | number): boolean {
        return ((value != null) &&
            (value !== '') &&
            !isNaN(Number(value)));
    }

    private isNegativeSymbol(keyCode: number): boolean {
        if (keyCode == 189 || // '-' MINUS
            keyCode == 109 || // '-' NumpadSubtract
            keyCode == 45) { // '-' Hyphen
            return true;
        }

        return false;
    }

    private validCharacter(char): boolean {
        //check for alphanumeric and special characters
        var regex = new RegExp(/[ A-Za-z0-9]/);
        let isAlphanumeric: boolean = regex.test(char);

        regex = new RegExp(/[`!@#$%^&*()_+\-=\[\]{};\':"\\|,.<>\/?~]/);
        let isSpecialCharacter: boolean = regex.test(char);

        return (isAlphanumeric || isSpecialCharacter);
    }
}

export class DynamicInputBase<T> {
    value: T | null;
    key: string;
    label: string;
    required: boolean;
    sequence: number;
    controlType: 'LongFormText' | 'Text' | 'Date' | 'PositiveIntegerNumber' | 'SelectionSet' | 'FullIntegerNumber' | 'YesNo' | 'RatingIntegerNumber' | 'Percentage' | 'Decimal';
    inputType: string;
    selectionSetItems: Array<string>;
    canEdit: boolean = true;

    constructor(options: {
        value?: T | null;
        key?: string;
        label?: string;
        required?: boolean;
        sequence?: number;
        controlType?: 'LongFormText' | 'Text' | 'Date' | 'PositiveIntegerNumber' | 'SelectionSet' | 'FullIntegerNumber' | 'YesNo' | 'RatingIntegerNumber' | 'Decimal';
        selectionSetItems?: Array<string>;
        canEdit?: boolean;
    } = {}) {

        this.value = options.value;
        this.key = options.key || '';
        this.label = options.label || '';
        this.required = options.required;
        this.sequence = options.sequence === undefined ? 1 : options.sequence;
        this.controlType = options.controlType;
        this.selectionSetItems = options.selectionSetItems || [];
        this.canEdit = options.canEdit;
    }
}

export class DateInput extends DynamicInputBase<Date>{

    constructor(sequence: number, key: string, label: string, isRequired: boolean, canEdit: boolean) {
        super();
        this.key = key;
        this.label = label;
        this.sequence = sequence;
        this.controlType = 'Date';
        this.required = isRequired;
        this.canEdit = canEdit;
    }

}

export class NumberInput extends DynamicInputBase<number>{

    constructor(sequence: number, key: string, label: string, isRequired: boolean, canEdit: boolean, controlType: 'FullIntegerNumber' | 'PositiveIntegerNumber' | 'RatingIntegerNumber' | 'Decimal' | 'Percentage' = 'FullIntegerNumber') {
        super();
        this.key = key;
        this.label = label;
        this.sequence = sequence;
        this.controlType = controlType;
        this.required = isRequired;
        this.canEdit = canEdit;
    }

}

export class StringInput extends DynamicInputBase<string>{

    constructor(sequence: number, key: string, label: string, isRequired: boolean, canEdit: boolean, controlType: 'Text' | 'LongFormText' | 'YesNo' = 'Text') {
        super();
        this.key = key;
        this.label = label;
        this.sequence = sequence;
        this.required = isRequired;
        this.controlType = controlType;
        this.canEdit = canEdit;
    }

}

export class SelectionSetInput extends DynamicInputBase<string>{

    constructor(sequence: number, key: string, label: string, selectionSetItems: Array<string>, isRequired: boolean, canEdit: boolean) {
        super();
        this.key = key;
        this.label = label;
        this.sequence = sequence;
        this.controlType = 'SelectionSet';
        this.selectionSetItems = selectionSetItems;
        this.required = isRequired;
        this.canEdit = canEdit;
    }

}
