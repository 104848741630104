import { QuizStatus_T, ContentType_T, Content } from './class.content';
import { UserMultiSelect} from './class.users';
import { Observable } from 'rxjs';


export enum EnrollmentStatus_T {
    assigned = 0,
    inProgress = 1,
    completed = 2,
    dropped = 3
}

export enum EnrollmentFilter_T {
    all = 0,
    assigned = 1,
    inProgress = 2,
    completed = 3,
    dropped = 4
}

export class Enrollment {
    public enrollmentId: String;
    public courseId: String;
    public imageURL: String;
    public courseName: String;
    public learningPathName: String;
    public userId: String;
    public percentComplete: number;
    public enrollmentStatus: EnrollmentStatus_T;
    public statusDate: Date;
    public hasDueDate: Boolean;
    public dueDate: Date;
    public assignerUserId: String;
    public assignerDisplayName: string;
    public assignedDate: Date;
    public description: string;
    public isSelfEnrolled: boolean;
    public learningPathId: String;
    public subscriptionId: String;
    public sequence: number;
    public isCustom: boolean;
    public childCourses: number = 0;
}

export interface CurrentEnrollment {
    learningPathName: string,
    courseName: string,
    userId: string,
    employeeID: string,
    userName: string,
    userEmail: string,
    enrollmentStatus: string,
    enrollmentProgress: number,
    enrollmentDueDate: Date,
    assignedByUser: string
}

export class EnrollmentPost {
    courseId: String;
    userId: String;
    hasDueDate: boolean;
    dueDate: Date;
}

export class EnrollmentTrackingItem {
    enrollmentId: String;
    contentId: String;
    contentType: ContentType_T;
    name: String;
    isComplete: boolean;
    classSessionDate: Date;
    classRegId: String;
    classSessionId: String;
    isClassRegistered: boolean;
    isCustomPassed: boolean;
    sequence: number;
    URL: string;
    isTreeCollapsed: boolean = false;
    AllowQuizRetakes: boolean = false;
    MaxQuizAttempts: number = 1;
    ForceViewSequential: boolean = false;
    EnrollAttemptCount: number = 0;
    requireQuizzesPassed: boolean = false;
    quizStatus: string;
    quizQuestionsCorrect: number;
    quizQuestionsPass: number;
    quizQuestionsPossible: number;
    scormRequireComplete: boolean;
    scormRequireScore: boolean;
    scormScoreToComplete: number;
    contentObject: Content = new Content();
}

export class ClassSession {
    public sessionId: String;
    public classId: String;
    public className: string;
    public classDescription: string;
    public sessionDateTime: Date;
    public sessionURL: string;
    public sessionLocation: string;
    public sessionTenantId: String;
}

export class ClassRegistrant {
    public regId: String;
    public sessionId: String;
    public userId: String;
    public regTenantId: String;
    public orgName: String;
    public orgId: String;
    public displayName: String;
    public email: String;
    public attended: Boolean;
}
export class ClassRegistrantPost {
    public sessionId: String;
    public attended: Boolean;
}

export class VideoTrackingItem {
	enrollmentId: String;
	contentId: String;
    lastVideoPosition: number;
    isComplete: boolean;
}

export class QuizTrackingItem {
    name: String;
    enrollmentId: String;
    contentId: String;
    isCorrect: boolean;
    quizAnswersCorrect: number;
    quizAnswersPossible: number;
    quizAnswersPass: number;
    quizStatus: QuizStatus_T;
    completedDate: Date;
    quizSessionItem: Array<QuizSessionItem> = new Array<QuizSessionItem>(); //any;
    AllowQuizRetakes: boolean;
    MaxQuizAttempts: number;
    EnrollAttemptCount: number;
}

export class QuizSessionItem {
    quizSessionId: String; //any
    quizId: String;
    enrollId: String;// 
    userId: String;
    startDatetime: Date;
    endDatetime: Date;
    isComplete: Boolean;
    quizAnswersCorrect: number;
    quizAnswersPossible: number;
    quizAnswersPass: number;
    quizStatus: String;
    tenantId: String;
    quizSessionTracking: Array<QuizSessionTracking> = new Array<QuizSessionTracking>();
}

export class QuizSessionTracking {
    quizTrackId: String;
    quizSessionId: String; //
    questionId: String;
    tenantId: String;
    questionType: String;
    isCorrect: Boolean;
    questionText: String;
    givenAnswer: String;
    correctAnswer: String;
    seq: number;
    isAnswered: Boolean;
    answerDatetime: Date;
    explanation: String;
}

export class EnrollmentTrackingItemPost {
    contentId: String = "";
    contentType: ContentType_T;
    isComplete: boolean = false;
    startDate: Date;
    endDate: Date;
}

export class EnrollmentQuizTrackingItemPost {
    contentId: String = "";
    isComplete: boolean = false;
    startDate: Date;
    endDate: Date;
    quizAnswersCorrect: number = 0;
    quizSessionItem: any; //quizSessionItem: Array<QuizSessionItem> = new Array<QuizSessionItem>(); 
}
export class EnrollmentVideoTrackingItemPost {
    contentId: String = "";
    isComplete: boolean = false;
    startDate: Date;
    endDate: Date;
    vidLength: number = 0;
    viewDuration: number = 0;
    lastVideoPosition: number = 0;
}

export class EnrollmentInBulk {
    bulkContent: Array<Content> = new Array<Content>();
    bulkUsers: Array<UserMultiSelect> = new Array<UserMultiSelect>();
    hasDueDate: boolean = false;
    dueDateStr: String = "";
}

export class BulkNotEnrolled {
    assignedToUserId: String = "";
    userName: String = "";
    reason: String = "";
    courseId: String = "";
    name: String = "";
}

export class CreateEnrollmentFromAssessmentRequest {
    public assignmentId: number;
    public assessmentId: number;
    public assessmentName: string;
    public bHasDueDate: boolean;
    public dueDate: Date;
    public templateMappings: Array<KSMappedAssessment>;
}


export class AssignTrainingForAssessmentCompletionRequest {
    public assignmentId: number;
    public assessmentId: number;
    public assessmentName: string;
    public bHasDueDate: boolean;
    public dueDate: Date;
    public templateMappings: Array<KSMappedAssessment>;

}


export class KSMappedAssessment {
    public ksAssessmentId: number;
    public ksAssessmentName: string;
    public templateId: string;
    public templatePathAndName: string;
    public templateType: ContentType_T;
    public templateSubId: string;
    public templatePath: string;
}
