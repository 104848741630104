import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ProdGenApi } from '../apiService/prodgen.api';

@Injectable()
export class TranslationService {

	dataLoaded: boolean = false;
	data: Object;

	constructor(private http: HttpClient, private pinnacleService: ProdGenApi) {

	}

    getTranslationFileData(key: string) {
        //console.log("Data Check");
        //console.log(this.dataLoaded == true);
        //console.log(this.data);
		if (this.dataLoaded == true) {
            try {
                if (key == "HOME.PreMemberOfCount") {

                }
                
				return this.data[key];
			}
			catch (err) {
				console.log(err);
				return "FAIL";
			}
		}
		else {
            let v_SessionData = localStorage.getItem("translationData");
			if (v_SessionData != null) {
				this.data = JSON.parse(v_SessionData) as Object[];
				this.dataLoaded = true;
			}
			else {
				//this.loadTranslationFileData();
			}
		}
    }

    loadTranslationDataFromText(p_Text: string) {
        console.log("Translation Data");
        //console.log(this.data);

        console.log(p_Text);

        var v_Data = JSON.parse(p_Text) as Object[];
        this.data = this.flattenJSONFile(v_Data);
        localStorage.setItem("translationData", JSON.stringify(this.data));
        this.dataLoaded = true;
        
    }

  loadTranslationFileData() {

  


		this.pinnacleService.getTranslatedFile().subscribe(res => {
			if (res != null) {

				this.data = this.flattenJSONFile(res);
				this.dataLoaded = true;
				localStorage.setItem("translationData", JSON.stringify(this.data));

			}
			else {
				this.loadTranslationFileLocal().subscribe(local => {
					this.loadTranslationFileDataFromVariable(local);
				}, localerr => {
					console.log(localerr);
				});
			}
		}, err => {
			console.log(err);
			this.loadTranslationFileLocal().subscribe(local => {
				this.loadTranslationFileDataFromVariable(local);
			}, localerr => {
				console.log(localerr);
			});
		});
	}

	loadTranslationFileDataFromVariable(passedData: Object[])
	{

		if (passedData != null) {
			this.data = this.flattenJSONFile(passedData);
			this.dataLoaded = true;
            localStorage.setItem("translationData", JSON.stringify(this.data));

            //console.log("translationDataLocal:");
           // console.log(localStorage.getItem("translationData"));
            //console.log(this.data);

		}
      else {
        this.loadTranslationFileLocal().subscribe(res => {
          this.loadTranslationFileDataFromVariable(res);

        }
          );
		}
	}

	loadTranslationFileLocal(): Observable<Object[]> {
		let v_Headers = new HttpHeaders({ 'Content-Type': 'application/json' });
		let v_TranslationData = this.http.get <Object[]>("../../assets/translate_en.json" + "?" + ProdGenApi.getSessionUnique(), { headers: v_Headers });
         
		return v_TranslationData;
	}

	private flattenJSONFile(origin: Object[]): Object {

		let v_Array = {};

		for (let key of Object.keys(origin)) {
			var val = origin[key];

			var subKeys = Object.keys(val);
			if (subKeys.length > 0) {
				for (let subKey of subKeys) {
					let fullKey = `${key}.${subKey}`;
					v_Array[fullKey] = val[subKey];
				}
			}
			else {
				v_Array[key] = val;
			}
		}
		return v_Array;
	}




}
