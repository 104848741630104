import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import cssVars from 'css-vars-ponyfill';
import { CompanyNewsItem } from '../apiService/classFiles/class.organizations';
import { User } from '../apiService/classFiles/class.users';
import { PermissionLevel_T, Workgroup } from '../apiService/classFiles/class.workgroups';
import { AppComponent, currenttheme, defpreviewtheme } from '../app.component';
import { PartnerNewsItem } from './../apiService/classFiles/class.partners';
import { ProdGenApi } from './../apiService/prodgen.api';
import { BrowserAuthenticationService } from './../BrowserAuthenticationService';

declare var $: any;

//declare function doesFontExist(fontName: string): boolean;

@Component({
    selector: 'app-externalhome',
    templateUrl: './external-home.component.html',
    styleUrls: ['./external-home.component.css'],
    providers: [ProdGenApi],
    encapsulation: ViewEncapsulation.None,
})

export class ExternalHomeComponent implements OnInit {
    workGroups: Array<Workgroup> = new Array<Workgroup>();
    curWorkgroupId: string = "";

    selectedWorkgroup: Workgroup = new Workgroup();
    //workgroupMembers: Array<WorkgroupMember> = new Array<WorkgroupMember>();

    //categoriesTenant: Array<Category> = new Array<Category>();
    currentUser: User = new User();

    companyInfo: Array<CompanyNewsItem> = new Array<CompanyNewsItem>();
    recommendedAddons: Array<CompanyNewsItem> = new Array<CompanyNewsItem>();
    companyInfoImage: string = "";
    companyInfoLoaded: boolean = false;

    partnerInfo: Array<PartnerNewsItem> = new Array<PartnerNewsItem>();
    recommendedPartnerAddons: Array<PartnerNewsItem> = new Array<PartnerNewsItem>();
    partnerInfoImage: string = "";
    partnerInfoLoaded: boolean = false;

    workgroupsLoaded: boolean = false;
    isAuthenticated: boolean = false;

    currentUserPermissionsLevel: String = "user";

    ownerWorkGroups: Array<Workgroup> = new Array<Workgroup>();
    editorWorkGroups: Array<Workgroup> = new Array<Workgroup>();


    canViewDownloadAddOns: boolean = true;
    wgcardcount: number = 0;

    previewtheme = defpreviewtheme;

    constructor(private pinnacleService: ProdGenApi, private route: ActivatedRoute, private router: Router, private _sanitizer: DomSanitizer, private authenticationService: BrowserAuthenticationService) {
        
        if (authenticationService.AuthenticatePage() == true) {
            this.isAuthenticated = true;
            this.companyInfoLoaded = false;

            this.currentUser.imageURL = "../assets/images/default_user.jpg";

            this.pinnacleService.GetCurrentTenant().subscribe(
                res => {
                    ProdGenApi.setCurrentTenantId(res.tenantId as string);

                });

            this.pinnacleService.getCurrentUser().subscribe(u => {


                if (u.imageURL == "") {
                    u.imageURL = "../assets/images/default_user.jpg";
                }
                this.currentUser = u;

                try {
                    localStorage.setItem(this.currentUser.userId + "_user_image", JSON.stringify(this.currentUser.imageURL));
                }
                catch (err) {
                    console.log(err);
                }


                try {
                    let i = JSON.parse(localStorage.getItem(ProdGenApi.getCurrentTenantId() + "_company_news"));
                    if (i != null) {
                        this.companyInfo = i;
                    }
                }
                catch (err) {
                    console.log(err)
                }

                try {
                    let i = JSON.parse(localStorage.getItem(ProdGenApi.getCurrentTenantId() + "_company_news_image"));
                    if (i != null) {
                        this.companyInfoImage = i;
                    }
                }
                catch (err) {
                    console.log(err)
                }


                try {
                    let i = JSON.parse(localStorage.getItem(ProdGenApi.getCurrentTenantId() + "_partner_news"));
                    if (i != null) {
                        this.partnerInfo = i;
                    }
                }
                catch (err) {
                    console.log(err)
                }

                try {
                    let i = JSON.parse(localStorage.getItem(ProdGenApi.getCurrentTenantId() + "_partner_news_image"));
                    if (i != null) {
                        this.partnerInfoImage = i;
                    }
                }
                catch (err) {
                    console.log(err)
                }


                try {
                    this.workGroups = JSON.parse(localStorage.getItem(this.currentUser.userId + "_workgroups"));
                }
                catch (err) {
                    console.log(err)
                }



                this.pinnacleService.getCurrentUserWorkgroups([PermissionLevel_T.owner, PermissionLevel_T.editor, PermissionLevel_T.user], 10).subscribe(res => {
                    for (var i = 0; i < res.length; i++) {

                        if (res[i].imageURL == null || res[i].imageURL == "") {
                            //get default workgroup image from storage
                            let defaultwgimage = "";

                            try {
                                defaultwgimage = localStorage.getItem(ProdGenApi.getCurrentTenantId() + "_defWorkgroupImage");
                            }
                            catch (err) {
                            }

                            if (defaultwgimage != null && defaultwgimage != "") {
                                res[i].imageURL = defaultwgimage;
                            }
                            else {
                                res[i].imageURL = "../assets/images/default_project.jpg";
                            }
                        }
                    }
                    this.workgroupsLoaded = true;
                    this.workGroups = res;

                    let selectId = "";

                    this.route.queryParams.subscribe(queryParams => {
                        if (queryParams['id'] != null) {
                            selectId = queryParams['id'];
                        }
                    });

                    if (/*this.selectedWorkgroup.workgroupId == null || this.selectedWorkgroup.workgroupId.length == 0*/true) {
                        if (this.workGroups.length > 0) {
                            //console.log("workgroups");
                            //console.log(this.workGroups);
                            this.selectedWorkgroup = this.workGroups.find(f => f.workgroupId == selectId);

                            if (this.selectedWorkgroup == null) {
                                //console.log("id found");
                                this.selectedWorkgroup = this.workGroups[0];
                            }
                        }

                        /*
                        if (this.selectedWorkgroup.workgroupId != null && this.selectedWorkgroup.workgroupId.length > 0) {
                            this.loadMembers();
                        }
                        */
                    }


                    /// write to Local Storage for this user
                    try {
                        localStorage.setItem(this.currentUser.userId + "_workgroups", JSON.stringify(this.workGroups))
                    }
                    catch (err) {
                        console.log(err);
                    }

                    this.pinnacleService.getCurrentUserWorkgroups([PermissionLevel_T.owner, PermissionLevel_T.editor]).subscribe(res2 => {
                        this.editorWorkGroups = res2;

                        this.pinnacleService.getCurrentUserWorkgroups([PermissionLevel_T.owner]).subscribe(res3 => {
                            this.ownerWorkGroups = res3;



                            if (this.selectedWorkgroup != null && this.selectedWorkgroup.workgroupId != null && this.selectedWorkgroup.workgroupId.length > 0) {
                                this.currentUserPermissionsLevel = "user";


                                if (this.ownerWorkGroups.findIndex(i => i.workgroupId == this.selectedWorkgroup.workgroupId) != -1) {
                                    this.currentUserPermissionsLevel = "owner";

                                }
                                else if (this.editorWorkGroups.findIndex(i => i.workgroupId == this.selectedWorkgroup.workgroupId) != -1) {

                                    this.currentUserPermissionsLevel = "editor";
                                }

                            }
                        });



                    });

                }
                );
            });

        }
    }

    ngOnInit() {
        window.scroll(0, 0);

    }

    get isPinnacleLite() {
        return AppComponent.isPinnacleLite;
    }

    /*

    loadMembers() {
        this.currentUserPermissionsLevel = "user";

        let workgroup = this.selectedWorkgroup;
        if (workgroup != null && workgroup.workgroupId != null) {
            this.workgroupMembers = new Array<WorkgroupMember>();
            this.curWorkgroupId = workgroup.workgroupId as string;

            this.pinnacleService.GetWorkgroupMembers(workgroup.workgroupId, -1, 0).subscribe(res => {
                let tempMembers = res;
                this.pinnacleService.GetWorkgroupExternalGroups(this.curWorkgroupId).subscribe(groups => {

                    for (let g of groups) {
                        for (let em of g.members) {
                            let m = new WorkgroupMember();
                            m.email = em.email;
                            m.userId = em.id;
                            m.imageURL = em.imageURL;
                            m.permissionLevel = "external";
                            m.workgroupId = g.id;
                            m.name = em.name;
                            

                            tempMembers.push(m);
                        }
                    }

                    for (var i = 0; i < tempMembers.length; i++) {
                        if (tempMembers[i].userId == this.currentUser.userId) {
                            this.currentUserPermissionsLevel = tempMembers[i].permissionLevel;
                        }

                        if (tempMembers[i].imageURL == "") {
                            tempMembers[i].imageURL = "../../assets/images/default_user.jpg";
                        }
                    }
                    this.workgroupMembers = tempMembers;
                });
            });
        }
    }
    */

    onMembersUpdated() {
        //this.loadMembers();
    }

    hideLoadingGif(someId: string) {
        document.getElementById(someId).style.display = "none";
    }

    getSafeHtml(htmlText: string): SafeHtml {
        if (htmlText == null) {
            htmlText = "";
        }
        return this._sanitizer.bypassSecurityTrustHtml(htmlText);
    }

    onWorkgroupChanged(event) {
        this.router.navigateByUrl("external-home?id=" + event.selectedItem);
        this.selectedWorkgroup = this.workGroups.find(w => w.workgroupId == event.selectedItem);

        this.currentUserPermissionsLevel = "user";

        if (this.ownerWorkGroups.findIndex(i => i.workgroupId == this.selectedWorkgroup.workgroupId) != -1) {
            this.currentUserPermissionsLevel = "owner";
        }
        else if (this.editorWorkGroups.findIndex(i => i.workgroupId == this.selectedWorkgroup.workgroupId) != -1) {
            this.currentUserPermissionsLevel = "editor";
        }
       // this.loadMembers();
    }

    checkcssVars() {
        cssVars({
            rootElement: document,
            variables: {
                "--preview-font-face": this.previewtheme.properties["--preview-font-face"],
                "--preview-page-background-color": this.previewtheme.properties["--preview-page-background-color"],
                "--preview-page-text-color": this.previewtheme.properties["--preview-page-text-color"],
                "--preview-page-separator-color": this.previewtheme.properties["--preview-page-separator-color"],
                "--preview-page-separator-width": this.previewtheme.properties["--preview-page-separator-width"],
                "--preview-navbar-background-color": this.previewtheme.properties["--preview-navbar-background-color"],
                "--preview-navbar-foreground-color": this.previewtheme.properties["--preview-navbar-foreground-color"],
                "--preview-hsl-background-color": this.previewtheme.properties["--preview-hsl-background-color"],
                "--preview-hsl-foreground-color": this.previewtheme.properties["--preview-hsl-foreground-color"],
                "--preview-hsl-border-color": this.previewtheme.properties["--preview-hsl-border-color"],
                "--preview-hsl-border-width": this.previewtheme.properties["--preview-hsl-border-width"],
                "--preview-hsr-background-color": this.previewtheme.properties["--preview-hsr-background-color"],
                "--preview-hsr-foreground-color": this.previewtheme.properties["--preview-hsr-foreground-color"],
                "--preview-hsr-border-color": this.previewtheme.properties["--preview-hsr-border-color"],
                "--preview-hsr-border-width": this.previewtheme.properties["--preview-hsr-border-width"],
                "--preview-large-card-background-color1": this.previewtheme.properties["--preview-large-card-background-color1"],
                "--preview-large-card-foreground-color1": this.previewtheme.properties["--preview-large-card-foreground-color1"],
                "--preview-large-card-background-color2": this.previewtheme.properties["--preview-large-card-background-color2"],
                "--preview-large-card-foreground-color2": this.previewtheme.properties["--preview-large-card-foreground-color2"],
                "--preview-large-card-background-color3": this.previewtheme.properties["--preview-large-card-background-color3"],
                "--preview-large-card-foreground-color3": this.previewtheme.properties["--preview-large-card-foreground-color3"],
                "--preview-large-card-border-color": this.previewtheme.properties["--preview-large-card-border-color"],
                "--preview-large-card-border-width": this.previewtheme.properties["--preview-large-card-border-width"],
                "--preview-large-card-border-radius": this.previewtheme.properties["--preview-large-card-border-radius"],
                "--preview-small-card-background-color1": this.previewtheme.properties["--preview-small-card-background-color1"],
                "--preview-small-card-foreground-color1": this.previewtheme.properties["--preview-small-card-foreground-color1"],
                "--preview-small-card-background-color2": this.previewtheme.properties["--preview-small-card-background-color2"],
                "--preview-small-card-foreground-color2": this.previewtheme.properties["--preview-small-card-foreground-color2"],
                "--preview-small-card-background-color3": this.previewtheme.properties["--preview-small-card-background-color3"],
                "--preview-small-card-foreground-color3": this.previewtheme.properties["--preview-small-card-foreground-color3"],
                "--preview-small-card-border-color": this.previewtheme.properties["--preview-small-card-border-color"],
                "--preview-small-card-border-width": this.previewtheme.properties["--preview-small-card-border-width"],
                "--preview-small-card-border-radius": this.previewtheme.properties["--preview-small-card-border-radius"],
                "--preview-panel-title-background-color": this.previewtheme.properties["--preview-panel-title-background-color"],
                "--preview-panel-title-foreground-color": this.previewtheme.properties["--preview-panel-title-foreground-color"],
                "--preview-panel-body-background-color": this.previewtheme.properties["--preview-panel-body-background-color"],
                "--preview-panel-body-foreground-color": this.previewtheme.properties["--preview-panel-body-foreground-color"],
                "--preview-panel-border-color": this.previewtheme.properties["--preview-panel-border-color"],
                "--preview-panel-border-width": this.previewtheme.properties["--preview-panel-border-width"],
                "--preview-panel-border-radius": this.previewtheme.properties["--preview-panel-border-radius"],
                "--preview-button-background": this.previewtheme.properties["--preview-button-background"],
                "--preview-button-foreground": this.previewtheme.properties["--preview-button-foreground"],

                "--font-face": currenttheme.properties["--font-face"],
                "--page-background-color": currenttheme.properties["--page-background-color"],
                "--page-text-color": currenttheme.properties["--page-text-color"],
                "--page-separator-color": currenttheme.properties["--page-separator-color"],
                "--page-separator-width": currenttheme.properties["--page-separator-width"],
                "--navbar-background-color": currenttheme.properties["--navbar-background-color"],
                "--navbar-foreground-color": currenttheme.properties["--navbar-foreground-color"],
                "--hsl-background-color": currenttheme.properties["--hsl-background-color"],
                "--hsl-foreground-color": currenttheme.properties["--hsl-foreground-color"],
                "--hsl-border-color": currenttheme.properties["--hsl-border-color"],
                "--hsl-border-width": currenttheme.properties["--hsl-border-width"],
                "--hsr-background-color": currenttheme.properties["--hsr-background-color"],
                "--hsr-foreground-color": currenttheme.properties["--hsr-foreground-color"],
                "--hsr-border-color": currenttheme.properties["--hsr-border-color"],
                "--hsr-border-width": currenttheme.properties["--hsr-border-width"],
                "--large-card-background-color1": currenttheme.properties["--large-card-background-color1"],
                "--large-card-foreground-color1": currenttheme.properties["--large-card-foreground-color1"],
                "--large-card-background-color2": currenttheme.properties["--large-card-background-color2"],
                "--large-card-foreground-color2": currenttheme.properties["--large-card-foreground-color2"],
                "--large-card-background-color3": currenttheme.properties["--large-card-background-color3"],
                "--large-card-foreground-color3": currenttheme.properties["--large-card-foreground-color3"],
                "--large-card-border-color": currenttheme.properties["--large-card-border-color"],
                "--large-card-border-width": currenttheme.properties["--large-card-border-width"],
                "--large-card-border-radius": currenttheme.properties["--large-card-border-radius"],
                "--small-card-background-color1": currenttheme.properties["--small-card-background-color1"],
                "--small-card-foreground-color1": currenttheme.properties["--small-card-foreground-color1"],
                "--small-card-background-color2": currenttheme.properties["--small-card-background-color2"],
                "--small-card-foreground-color2": currenttheme.properties["--small-card-foreground-color2"],
                "--small-card-background-color3": currenttheme.properties["--small-card-background-color3"],
                "--small-card-foreground-color3": currenttheme.properties["--small-card-foreground-color3"],
                "--small-card-border-color": currenttheme.properties["--small-card-border-color"],
                "--small-card-border-width": currenttheme.properties["--small-card-border-width"],
                "--small-card-border-radius": currenttheme.properties["--small-card-border-radius"],
                "--panel-title-background-color": currenttheme.properties["--panel-title-background-color"],
                "--panel-title-foreground-color": currenttheme.properties["--panel-title-foreground-color"],
                "--panel-body-background-color": currenttheme.properties["--panel-body-background-color"],
                "--panel-body-foreground-color": currenttheme.properties["--panel-body-foreground-color"],
                "--panel-border-color": currenttheme.properties["--panel-border-color"],
                "--panel-border-width": currenttheme.properties["--panel-border-width"],
                "--panel-border-radius": currenttheme.properties["--panel-border-radius"],
                "--button-background": currenttheme.properties["--button-background"],
                "--button-foreground": currenttheme.properties["--button-foreground"]
            }
        });
    }

    memberSelfDelete() {


        
            var v_Index = this.workGroups.indexOf(this.selectedWorkgroup);

            if (this.workGroups.length - 1 > 0) {
                let v_NewIndex = 0;
                if (v_Index == this.workGroups.length) {
                    v_NewIndex = v_Index - 1;
                }
                else {
                    v_NewIndex = v_Index + 1;
                }


            }
            else {
               
                    //signout

                    sessionStorage.clear();
                    AppComponent.thisFromOutside.isLoggedIn = false;
                    AppComponent.thisFromOutside.companyLogo = "";
                    AppComponent.isPinnacleLite = false;
                    AppComponent.thisFromOutside.isPinnacleLiteLocal = false;
                    this.pinnacleService.EndSession();


                    localStorage.setItem("rememberMe", "false");
                    localStorage.setItem("longLivedToken", "");

                    sessionStorage.setItem("ChatActive", "false");
                    localStorage.setItem("lastUsedTenant", "");
                    localStorage.setItem("isAuthenicated", "");



                    sessionStorage.setItem("workGroupSortBy", "Asset Name");
                    sessionStorage.setItem("volume", "7");
                    this.router.navigate(['/login']);
                    return;
                
            }

        this.workGroups.splice(v_Index, 1);
        

    }

    selectedWorkGroupClicked() {
        $('.toggleViewWorkGroupArea').collapse('show');
        $('.toggleSelectWorkGroupArea').collapse('hide');

    }
}

