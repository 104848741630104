import { Component, OnInit, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { WidgetComponent } from '../../widget/widget.component';

import { WidgetProperties, WidgetPropertyOptions } from '../../../apiService/classFiles/class.users'; 
import { ProdGenApi } from '../../../apiService/prodgen.api';

import { Enrollment, EnrollmentFilter_T, EnrollmentStatus_T, QuizTrackingItem, QuizSessionItem, QuizSessionTracking } from './../../../apiService/classFiles/class.enrollments';
import { Course, Content, DescriptionFilter_T, QuizStatus_T, Quiz } from './../../../apiService/classFiles/class.content';
import { User } from './../../../apiService/classFiles/class.users';
import { TranslationService } from '../../../services/TranslationService';
import { Apiv2Service } from 'src/app/apiService/apiv2.service';
import { EL_Record, EL_ActivityAttribute, EL_Attribute, EL_AttributeDataType, EL_RecordStatus } from 'src/app/apiService/classFiles/v2-externallearning';
import { ExportToCsv } from 'export-to-csv';

declare var $: any; // JQuery workaround



@Component({
    selector: 'external-learning-records-widget-component',
    templateUrl: './external-learning-records-widget.component.html',
    styleUrls: ['./external-learning-records-widget.component.css']
})


// TODO - Rename the component name and set the key variable to be the string representation of the component name
export class ExternalLearningRecordsWidgetComponent extends WidgetComponent implements OnInit, AfterViewInit {
    static key = "ExternalLearningRecordsWidgetComponent";     // this key must be set to the name of the Angular component


    currentUser: User = null;
    currentUserLoaded: boolean = false;

    showingAllCompleted: boolean = false;
    recordList: Array<EL_Record> = new Array<EL_Record>();
    recordListLoaded: boolean = false;
    showingAllRecords: boolean = false;

    selectedCol: 'Title' | 'Status' | 'Date'; //Title, Status, Date
    sortDesc: boolean = true;
    selectedRecord: EL_Record;

    widgetLoaded: boolean = false;
    csvExportHeaders: string[] = [];
    dataToExport: any;
    headerAttributeList: Array<EL_Attribute>;
    
    

    constructor(private pinnacleService: ProdGenApi, private v2Service: Apiv2Service, private changedetectorref: ChangeDetectorRef, private translationService: TranslationService) {
        // call the base Widget class
        super(pinnacleService, changedetectorref);
        // set defaults for your height and width.
        // these are logical units of the container
        // container is 12 units across and 1 unit of height is ~25px
        this.widgetWidth = 8;
        this.widgetHeight = 8;

        // define the name and description for the Widget.
        this.widgetTitle = "External Learning Records";
        this.widgetName = "External Learning Records"
        this.widgetDesc = "Review external learning records history for the user."; 
    }

    ngOnInit() {
        // call the base Widget class
        super.ngOnInit();

        // create widget specific properties that can be configured
        this.createProperties();

        // load the data needed for the widget
        this.Load(); 
    }

    ngAfterViewInit() {
        this.widgetContainer.incrementRenderedWidget();
    }

    Load() {
        // call the base Widget class
        super.Load();
        this.loadExternalLearningRecords();
        this.getAllAttributes();

    }


    onPropsChanged(newprops: Array<WidgetProperties>, writeSettings: boolean = false, doresequence = true) {
        super.onPropsChanged(newprops, writeSettings, doresequence);

        
        // write the new properties to the database
        if (writeSettings) {
            this.pinnacleService.setWidgetProperties(this.widgetContainer.containerInfo.containerInstanceId, this.widgetID, newprops, ExternalLearningRecordsWidgetComponent.key).subscribe(res => {/*return stuff. might not need to do anything here*/ });
        }

        // if there are other properties we care about, retrieve them here
        // do the work this widget needs to do based on the changed properties (set values for bound variables, etc.)
        super.onPropsChangedLocalStorage();

        this.widgetLoaded = true;
        super.widgetArePropertiesLoaded();
    }

    loadExternalLearningRecords() {
        

        this.pinnacleService.getCurrentUser(false).subscribe(res => {
            this.currentUser = res;
            this.currentUserLoaded = true;

            //Dustin's userid: 06A15232-DFEB-4A2C-BA7B-70C58591107A for testing
            //this.currentUser.userId
            this.v2Service.getExternalLearningUserRecords(this.currentUser.userId).subscribe(res => {

                //sort the attributes by their sequence number from the attributes tab in the admin portal
                res.forEach(attr => {
                    attr.attributes = attr.attributes.sort((a, b) => a.attribute.sequenceNumber - b.attribute.sequenceNumber);
                });

                this.recordList = res; 
                this.sortCol('Date');
                this.recordListLoaded = true;
            })
        });
        
    }

    getTrainingTitleValue(item: EL_Record): string {
        let attribute = item.attributes.find(x => x.attribute.attributeName === "Training Title" && x.attribute.attributeDataType == EL_AttributeDataType.Text);
        if (attribute != null)
            return attribute.attributeStringValue;

        return "";
    }


    getStatusValue(item: EL_Record): string {
        //This is translated too
        if (item.status == EL_RecordStatus.Pending) {
            return this.translationService.getTranslationFileData("WIDGET_EXTERNALLEARNINGRECORD.Pending");
        }
        else if (item.status == EL_RecordStatus.Approved) {
            return this.translationService.getTranslationFileData("WIDGET_EXTERNALLEARNINGRECORD.Approved");
        }
        else if (item.status == EL_RecordStatus.Denied) {
            return this.translationService.getTranslationFileData("WIDGET_EXTERNALLEARNINGRECORD.Denied");
        }

        return '';
    }


    getCompletedDateValue(item: EL_Record): Date {
        let attribute = item.attributes.find(x => x.attribute.attributeName === "Completed Date" && x.attribute.attributeDataType == EL_AttributeDataType.Date);
        //This should never happen, but we have some bad data currently...
        if (attribute == undefined) {
            return null;
        }
        return attribute.attributeDateValue;
    }

    showDeniedExplanation(item: EL_Record): boolean {

        if (item.status == EL_RecordStatus.Denied) {
            return true;
        }

        return false;
    }

    toggleShowAllRecords() {
        this.showingAllRecords = !this.showingAllRecords;

        setTimeout(() => {

            if (this.showingAllRecords) {
                this.widgetContainer.onWidgetExpand(this.widgetID);
            }
            else {
                this.widgetContainer.onWidgetCollapse(this.widgetID);
            }
        }, 0);
    }

    sortCol(col: 'Title' | 'Status' | 'Date') {

        if (this.selectedCol == col) {
            this.recordList.reverse();
            this.sortDesc = !this.sortDesc;
        }

        else {
            this.selectedCol = col;
            this.sortDesc = true;
            switch (col) {
                case "Title": {
                    this.recordList.sort((a, b) => {
                        var x = this.getTrainingTitleValue(a).toLowerCase();
                        var y = this.getTrainingTitleValue(b).toLowerCase();

                        if (x < y) { return -1; }
                        if (x > y) { return 1; }
                        return 0;
                    });
                    break;
                }
                case "Status": {
                    this.recordList.sort((a, b) => {
                        var x = a.status;
                        var y = b.status;
                        //Should be pending, approved, denied

                        if (x !== EL_RecordStatus.Denied && y === EL_RecordStatus.Denied ) { return -1; }
                        if (x === EL_RecordStatus.Denied && y !== EL_RecordStatus.Denied ) { return 1; }

                        //approved
                        if (x !== EL_RecordStatus.Approved && y === EL_RecordStatus.Approved) { return -1; }
                        if (x === EL_RecordStatus.Approved && y !== EL_RecordStatus.Approved) { return 1; }


                        if (x == y) {
                            //If equal Status go by completionDate
                            var v = new Date(this.getCompletedDateValue(a)).getTime();
                            var w = new Date(this.getCompletedDateValue(b)).getTime();
                            if (v < w) { return -1; }
                            if (v > w) { return 1; }
                            return 0;
                        }                       

                        return 0;
                    });
                    break;
                }
                case "Date": {
                    this.recordList.sort((a, b) => {

                        var x = new Date(this.getCompletedDateValue(a)).getTime();
                        var y = new Date(this.getCompletedDateValue(b)).getTime();

                        if (x < y) { return -1; }
                        if (x > y) { return 1; }
                        return 0;
                    });
                    break;
                }
            }
        }
    }

    exportExternalLearningRecords() {
        //  method to generate the csv file of the records
        this.generateHeaders();
        this.getTheExportData();
        const options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            filename: 'External_Learning_Records',
            showLabels: true,
            showTitle: false,
            title: '',
            useTextFile: false,
            useBom: true,
            headers: this.csvExportHeaders,
        };

        const csvExporter = new ExportToCsv(options);
        csvExporter.generateCsv(this.dataToExport);
    }

    getAllAttributes() {

        this.v2Service.getExternalLearningAttributes().subscribe(res => {
            this.headerAttributeList = res;
        })
    }

    generateHeaders() {

        //output headers: title, status, completed date, other custom attributes, file
        let headerStrings: string[] = [];

        headerStrings.push("Status");
        headerStrings.push("User");
        headerStrings.push("Email");

        for (let i = 0; i < this.headerAttributeList.length; i++) {
            if (this.headerAttributeList[i].attributeName !== "Status" && this.headerAttributeList[i].attributeName !== "User" && this.headerAttributeList[i].attributeName !== "Email") {
                headerStrings.push(this.headerAttributeList[i].attributeName);
            }
        }
        headerStrings.push(this.translationService.getTranslationFileData("WIDGET_EXTERNALLEARNINGRECORD.File"));
        this.csvExportHeaders = headerStrings;
    }

    // method to get the users external records and export it to a csv file
    getTheExportData() {
        const csv: any[] = [];
        //spin through all of the selectedRecords, this could be 1 or many. For each selectedRecord, loop through the csvExportHeaders and
        //output a record for each selectedRecord with all the columns defined in csvExportHeaders.
        for (let i = 0; i < this.recordList.length; i++) {
            var csvobject: any = {};
            for (let h = 0; h < this.csvExportHeaders.length; h++) {
                if (this.csvExportHeaders[h] == "Status") {   
                    if (this.recordList[i].status == EL_RecordStatus.Pending) {
                        csvobject["Status"] = "Pending";
                    }
                    else if (this.recordList[i].status == EL_RecordStatus.Approved) {
                        csvobject["Status"] = "Approved" + " by " + this.recordList[i].statusUser.displayName + ' on ' + this.formatDate(this.recordList[i].statusDate);
                    }
                    else if (this.recordList[i].status == EL_RecordStatus.Denied) {
                        csvobject["Status"] = "Denied" + " by " + this.recordList[i].statusUser.displayName + ' on ' + this.formatDate(this.recordList[i].statusDate);
                    }
                }
                else if (this.csvExportHeaders[h] == "User") {
                     csvobject["User"] = this.recordList[i].recordUser.displayName;
                }
                else if (this.csvExportHeaders[h] == "Email") {
                    csvobject["Email"] = this.recordList[i].recordUser.email;
                }
                else if (this.csvExportHeaders[h] == "File") {
                    if (this.recordList[i].fileUrl.toString() == "N/A" || this.recordList[i].fileUrl.toString() == "") {
                        csvobject["File"] = this.recordList[i].fileUrl;
                    }
                    else {
                        csvobject["File"] = this.tryGetFileName(this.recordList[i].fileUrl);
                    }
                }
                else {
                    //this is a custom prop
                    let indexofprop = this.recordList[i].attributes.findIndex(x => x.attribute.attributeName == this.csvExportHeaders[h]);
                    if (indexofprop != -1) {
                        switch (this.recordList[i].attributes[indexofprop].attribute.attributeDataType) {
                            case 0://text
                                if (this.recordList[i].attributes[indexofprop].attributeStringValue == null) {
                                    csvobject[this.csvExportHeaders[h]] = "";
                                    break;
                                }
                                else {
                                    csvobject[this.csvExportHeaders[h]] = this.recordList[i].attributes[indexofprop].attributeStringValue;
                                    break;
                                }
                            case 1://number
                                if (this.recordList[i].attributes[indexofprop].attributeIntValue == null) {
                                    csvobject[this.csvExportHeaders[h]] = "";
                                    break;
                                }
                                else {
                                    csvobject[this.csvExportHeaders[h]] = this.recordList[i].attributes[indexofprop].attributeIntValue;
                                    break;
                                }
                            case 2://Date
                                if (this.recordList[i].attributes[indexofprop].attributeDateValue == null) {
                                    csvobject[this.csvExportHeaders[h]] = "";
                                    break;
                                }
                                else {
                                    //csvobject[this.csvExportHeaders[h]] = this.recordList[i].attributes[indexofprop].attributeDateValue.toDateString();
                                    csvobject[this.csvExportHeaders[h]] = this.formatDate(this.recordList[i].attributes[indexofprop].attributeDateValue);
                                    break;
                                }
                            case 3://Percent
                                if (this.recordList[i].attributes[indexofprop].attributeIntValue == null) {
                                    csvobject[this.csvExportHeaders[h]] = "";
                                    break;
                                }
                                else {
                                    csvobject[this.csvExportHeaders[h]] = this.recordList[i].attributes[indexofprop].attributeIntValue;
                                    break;
                                }
                            case 4://Rating
                                if (this.recordList[i].attributes[indexofprop].attributeIntValue == null) {
                                    csvobject[this.csvExportHeaders[h]] = "";
                                    break;
                                }
                                else {
                                    csvobject[this.csvExportHeaders[h]] = this.recordList[i].attributes[indexofprop].attributeIntValue;
                                    break;
                                }
                            case 5://Long Form Text
                                if (this.recordList[i].attributes[indexofprop].attributeStringValue == null) {
                                    csvobject[this.csvExportHeaders[h]] = "";
                                    break;
                                }
                                else {
                                    csvobject[this.csvExportHeaders[h]] = this.recordList[i].attributes[indexofprop].attributeStringValue;
                                    break;
                                }
                            case 6://Minutes
                                if (this.recordList[i].attributes[indexofprop].attributeIntValue == null) {
                                    csvobject[this.csvExportHeaders[h]] = "";
                                    break;
                                }
                                else {
                                    csvobject[this.csvExportHeaders[h]] = this.recordList[i].attributes[indexofprop].attributeIntValue;
                                    break;
                                }
                            case 7://Yes/No
                                if (this.recordList[i].attributes[indexofprop].attributeStringValue == null) {
                                    csvobject[this.csvExportHeaders[h]] = "";
                                    break;
                                }
                                else {
                                    csvobject[this.csvExportHeaders[h]] = this.recordList[i].attributes[indexofprop].attributeStringValue;
                                    break;
                                }
                            case 8://Selection Set
                                if (this.recordList[i].attributes[indexofprop].attributeStringValue == null) {
                                    csvobject[this.csvExportHeaders[h]] = "";
                                    break;
                                }
                                else {
                                    csvobject[this.csvExportHeaders[h]] = this.recordList[i].attributes[indexofprop].attributeStringValue;
                                    break;
                                }
                            case 9://Decimal
                                if (this.recordList[i].attributes[indexofprop].attributeFloatValue == null) {
                                    csvobject[this.csvExportHeaders[h]] = "";
                                    break;
                                }
                                else {
                                    csvobject[this.csvExportHeaders[h]] = this.recordList[i].attributes[indexofprop].attributeFloatValue;
                                    break;
                                }
                            default:
                                if (this.recordList[i].attributes[indexofprop].attributeStringValue == null) {
                                    csvobject[this.csvExportHeaders[h]] = "";
                                    break;
                                }
                                else {
                                    csvobject[this.csvExportHeaders[h]] = this.recordList[i].attributes[indexofprop].attributeStringValue;
                                    break;
                                }
                        }
                    }
                    else {
                        csvobject[this.csvExportHeaders[h]] = "";
                    }
                }
            }
            csv.push(csvobject);
        }
        this.dataToExport = csv;
        return this.dataToExport;
    }

    public tryGetFileName(fileUrl: string): string {
        if (!fileUrl || fileUrl === '' || fileUrl == 'N/A') {
            return '';
        }

        let fileSections: Array<string> = fileUrl.split('?');
        fileSections = fileSections[0].split('/');

        let originalFileName: string = fileSections[fileSections.length - 1];
        //Put the original spaces back in rather than showing a bunch of "%20"s.
        originalFileName = originalFileName.replace(new RegExp('%20', 'g'), " ");

        return originalFileName;
    }

    public getFileImageUrl(fileUrl: string): string {
        let fileName: string = this.tryGetFileName(fileUrl);

        let fileNameParts = fileName.split('.');

        let fileExt = fileNameParts[fileNameParts.length - 1];

        switch (fileExt) {
            case 'pdf':
                return 'assets/images/PDFDoc.svg';
            case 'docx':
                return 'assets/images/WordDoc.svg';
            case 'png':
            case 'jpeg':
            case 'jpg':
                return 'assets/images/ImageFileWhiteBg.svg';
            default:
                return '';
        }
    }

    openRecordFile(){
        alert('This feature is in an coming User Story');
    }


    clickRow(item: EL_Record) {

        this.selectedRecord = item;
        $('#elRecordReviewModal_' + this.widgetID).modal('show');
    }

    excludedAttributes(item: EL_ActivityAttribute): boolean {
        //We don't want the table attributes to be repeated
        if (item.attribute.attributeName == "Training Title" && item.attribute.attributeDataType == EL_AttributeDataType.Text) {
            return false;
        }

        else if (item.attribute.attributeName == "Completed Date" && item.attribute.attributeDataType == EL_AttributeDataType.Date) {
            return false;
        }
        else if (item.attribute.attributeName == "Email" && item.attribute.attributeDataType == EL_AttributeDataType.Text) {
            return false;
        }
        else {
            return true;
        }
    }

    //---------------------------------------------------------------------------------------------
    createProperties() {

        // call widget base class
        super.createProperties();

        // the width and height properties are already created by the base class.
        //The rest of the createProperties function will be widget specific, following the general format of the below commented code for each widget property

        
        super.createPropertiesDisplayType("overlay");

        super.createPropertiesWidgetTitle("External Learning Records");

        super.createPropertiesMobileOptions();
    }

    formatDate(dateString) {

        if (dateString != null && dateString != this.translationService.getTranslationFileData('WIDGET_EXTERNALLEARNINGRECORD.invalid-date')) {
            let temptDate = new Date(dateString);
            let returndate = temptDate.toISOString().split('T')[0];
            return returndate;
        }

        else {
            return this.translationService.getTranslationFileData('WIDGET_EXTERNALLEARNINGRECORD.invalid-date');
        }
    }
    //END WIDGET REQUIRED CALLS
}


