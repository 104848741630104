import { AfterViewInit, Directive, ElementRef, HostListener, Input, OnChanges, OnDestroy, Renderer2, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';


@Directive({
	selector: '[ContentHTML]'
	
})

export class ContentHtmlDirective implements AfterViewInit, OnChanges {
	private htmlLoaded: boolean;
	private htmlUrl: String;

	listenClickFunc: Function;

	@Input() ContentHTML: any;

	constructor(private el: ElementRef, private renderer: Renderer2, private domSanitizer: DomSanitizer, private router: Router) {
		this.htmlLoaded = false;
	}

	ngAfterViewInit() {
		this.el.nativeElement.innerHTML = this.domSanitizer.sanitize(SecurityContext.HTML, this.ContentHTML);
		this.htmlUrl = window.location.href;

		this.createLinkListeners();
	}

	ngOnChanges(changes) {

		try {
			if (changes.ContentHTML.currentValue.changingThisBreaksApplicationSecurity == changes.ContentHTML.previousValue.changingThisBreaksApplicationSecurity) {
				return;
			}
		}
		catch(err){
			return;
		}
		this.htmlLoaded = false;
		
		
        /*
	    if (this.htmlUrl != window.location.href) {
			this.htmlLoaded = false;
			this.el.nativeElement.innerHTML = this.domSanitizer.sanitize(SecurityContext.HTML, this.ContentHTML);
		}
        */
		if (this.htmlLoaded == false) {
        //if(1==1){
			/*
			try {

			}
			catch (Exception) { }
            */
			

			if (changes.ContentHTML.currentValue.changingThisBreaksApplicationSecurity.startsWith('<!DOCTYPE')) {
				//this.htmlUrl = window.location.href;


				//this.el.nativeElement.innerHTML = this.domSanitizer.sanitize(SecurityContext.HTML,this.ContentHTML);
                this.el.nativeElement.innerHTML = this.domSanitizer.sanitize(SecurityContext.HTML,this.ContentHTML);

				this.htmlLoaded = true;

				this.createLinkListeners();	

			}
			
		}
		
	}

	createLinkListeners() {		

		const navigationElements = Array.prototype.slice.call(this.el.nativeElement.querySelectorAll('a[href]'));
		navigationElements.forEach(elem => {
			this.listenClickFunc = this.renderer.listen(elem, 'click', (event) => {
				if (elem.getAttribute('href').toLowerCase().startsWith('pinnacle:')) {

					let href_url = elem.getAttribute('href').replace('pinnacle://', '');
					let url_array = href_url.split(':');

					let url = "#";

					if (url_array[0] == "PS_Cheat") {
						window.location.href = url += "/cheatsheetviewer?id=" + url_array[1];
					}
					else if (url_array[0] == "PS_VidTutorial" || url_array[0] == "PS_VidArchive" || url_array[0] == "PS_VidUser") {
						window.location.href = url += "/videoviewer?id=" + url_array[1];
					}
					else if (url_array[0] == "PS_LearningPath") {
						window.location.href = url += "/learningcenter/series?learningPathId=" + url_array[1];
					}
					else if (url_array[0] == "PS_Course") {
						window.location.href = url += "/learningcenter/series?courseId=" + url_array[1];
					}
					else if (url_array[0] == "PS_Workflow" || url_array[0] == "PS_Process" || url_array[0] == "PS_Task" || url_array[0] == "PS_Step" || url_array[0] == "PS_Milestone") {
						let contentType = "";
						if (url_array[0] == "PS_Workflow") {
							contentType = "workflow";
						}
						else if (url_array[0] == "PS_Process") {
							contentType = "process";
						}
                        else if (url_array[0] == "PS_Task" || url_array[0] == "PS_Milestone") {
							contentType = "task";
						}
						else if (url_array[0] == "PS_Step") {
							contentType = "step";
						}
						//this.router.navigate([url += "/workflowviewer?id=" + url_array[1] + "&contentType=" + contentType]);
						let full_url = url += "/workflowviewer?id=" + url_array[1] + "&contentType=" + contentType;
						window.location.href = full_url;
					}
					event.preventDefault();
				}

			});
		});	
	}


	ngOnDestroy() {
		this.listenClickFunc;
	}
}