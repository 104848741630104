export type RGB = { r: number; g: number; b: number };

export function hexToRgb(hexColor: string): RGB {
    if (!hexColor || !hexColor.startsWith('#')) {
      return;
    }
  
    var color = hexColor.charAt(0) === '#' ? hexColor.substring(1, 7) : hexColor;
    return {
      r: parseInt(color.substring(0, 2), 16),
      g: parseInt(color.substring(2, 4), 16),
      b: parseInt(color.substring(4, 6), 16),
    };
  }