import { Injectable, EventEmitter } from '@angular/core';


@Injectable()
export class BrandingUpdateServiceService {

    newsitemsemitter = new EventEmitter<any>();
    logoimgemitter = new EventEmitter<any>();
    newsimgemitter = new EventEmitter<any>();
    profilepictureemitter = new EventEmitter<any>();

    docimgemitter = new EventEmitter<any>();
    mydocimgemitter = new EventEmitter<any>();
    vidimgemitter = new EventEmitter<any>();
    courseimgemitter = new EventEmitter<any>();
    lpimageemitter = new EventEmitter<any>();
    wfimageemitter = new EventEmitter<any>();
    processimgemitter = new EventEmitter<any>();
    quizimgemitter = new EventEmitter<any>();
    defworkgroupemitter = new EventEmitter<any>();
    enrollmentemitter = new EventEmitter<any>();

  constructor() { }

    emitLogoChangeEvent(url: string) {
        console.log("in event at service");
        this.logoimgemitter.emit(url)
    }
    getLogoChangeEmitter() {
        return this.logoimgemitter;
    }
}
