import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Content } from '../apiService/classFiles/class.content';

@Injectable()
export class DiagramTreeService {

  private diagramIdSource = new Subject<string>();
  private diagramTypeSource = new Subject<string>();
  private objectClickSource = new Subject<string>();
  private selectContentSource = new Subject<Content>();

  diagramId$ = this.diagramIdSource.asObservable();
  diagramType$ = this.diagramTypeSource.asObservable();
  objectClickId$ = this.objectClickSource.asObservable();
  selectContent$ = this.selectContentSource.asObservable();

  selection: Content = new Content();

  constructor() {

  }


  SetDiagramId(value: string): void {
    this.diagramIdSource.next(value);
  }

  SetDiagramType(value: string): void {
    this.diagramTypeSource.next(value);
  }

  SetObjectClickId(value: string): void {
    this.objectClickSource.next(value);
  }

  SetSelectContent(value: Content): void {
      this.selectContentSource.next(value);

    this.selection = value;
  }
    public GetSelectContent(): Content {

    return this.selection;
  }

  //public GetDiagramType(): string {

  //  return this.diagramType;
  //}

}
