import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Apiv2Service } from '../apiService/apiv2.service';
import { SharedService } from '../apiService/loginservice.service';

@Injectable()
export class SessionTimeoutService {

    // The name of the setting used to determine
    // the default timeout value.
    private readonly timeoutSettingName = 'DEFAULT_PRODGENBROWSER_TIMEOUT';

    // The instance of the JS interval we need to
    // perform set/clear operations.
    private intervalId: NodeJS.Timer | null;

    // The max number of minutes the timer can be set for. The value is initially null and the code will read the gl_settings
    // for 'DEFAULT_PRODGENBROWSER_TIMEOUT' to get the default value. If the record is found, but the nvalue=0 or the strvalue=false
    // this will set the session timeout logic off.
    private timeout: number | null = null;
    private defaultMaxTimeout: number | null = 2147483647;  

    // The use of the time out logic.
    private usetimeoutlogic: boolean | null = null;

    public loggedOut: boolean = true;
    public videoPlaying: boolean = false;

    // Variables to help track how many minutes
    // are currently remaining.
    private _minutesRemainingSource = 60;
    private _minutesRemainingSubject = new BehaviorSubject<number>(this._minutesRemainingSource);
    public debugTimeout: boolean = false;

    /**
     * The number of minutes remaining until the
     * timer runs out.
     */
    public minutesRemaining = this._minutesRemainingSubject.asObservable();

    constructor(
        private apiService: Apiv2Service,
        private loginService: SharedService
    ) { }

    public start(): void {
        this.debugTimeout = sessionStorage.getItem("debugtimeoutlogic") ? (sessionStorage.getItem("debugtimeoutlogic").toLowerCase() === 'true') : false;
        $('#session-timeout-modal').modal('hide');      //close the "Are you there modal if currently open"
        if (this.debugTimeout) {
            console.log("*Start, SessionStorage debugtimeoutlogic flag is set to: " + this.debugTimeout);
            console.log("        usetimeoutlogic value is: " + this.usetimeoutlogic);
            console.log("        timeout is: " + this.timeout);
            console.log("        is video playing = " + this.videoPlaying);
            console.log("        is loggedOut = " + this.loggedOut);
        }

        if (this.videoPlaying || this.loggedOut) return;

        if (this.timeout == null) {
            this.apiService.getGlobalSetting(this.timeoutSettingName).subscribe(setting => {
                if (setting) {
                    if (setting.stringValue.toLowerCase() === 'true') {
                        this.usetimeoutlogic = true;
                        if (setting.intValue > 0) {
                            this.timeout = setting.intValue;
                        }
                        else if (setting.intValue === 0) {
                            this.timeout = this.defaultMaxTimeout;
                        }
                    }
                    else {
                        this.usetimeoutlogic = false;
                        this.timeout = this.defaultMaxTimeout;
                    }
                }
                else {
                    this.usetimeoutlogic = false;
                    this.timeout = this.defaultMaxTimeout;
                }
                if (this.debugTimeout) console.log("*Start, timeout null logic, timeout value is: " + this.timeout);
                // Restart the timer with the fetched value.
                if (this.timeout != null && this.timeout != 0) {
                    this.start();
                }
            });
        }

        if (this.debugTimeout) console.log("*Start, timeout value is: " + this.timeout);
        // Reset any existing timer.
        if (this.debugTimeout) console.log("*Start, call stop(timer) to reset any existing timer running.");
        this.stop();
        this._minutesRemainingSource = this.timeout;
        this._minutesRemainingSubject.next(this._minutesRemainingSource);

        // Start a timer that ticks every minute(ish).
        // https://html.spec.whatwg.org/multipage/timers-and-user-prompts.html#timers
        // "This API does not guarantee that timers will run exactly on schedule."
        this.intervalId = setInterval(() => { this.doTick(); }, 60000);
    }

    public stop(): void {
        if (this.debugTimeout) console.log("*Stop (" + new Date() + ")");  

        if (this.intervalId) {
            clearInterval(this.intervalId);
            this.intervalId = null;
        }
    }

    private doTick(): void {
        if (--this._minutesRemainingSource >= 0) {
            this._minutesRemainingSubject.next(this._minutesRemainingSource);
            if (this.debugTimeout) console.log("*doTick, minutes remaining is: " + this._minutesRemainingSource + " (" + new Date() + ")");   
        }
        else {
            if (this.debugTimeout) console.log("*doTick, call Stop from doTick end of session timeout");   
            this.stop();
        }
    }
}