import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { ProdGenApi } from './../apiService/prodgen.api';
import { Router, ActivatedRoute } from '@angular/router';
import { BrowserAuthenticationService } from './../BrowserAuthenticationService';
import { Apiv2Service } from './../apiService/apiv2.service';


declare var $: any;

@Component({
    selector: 'zendesk_redirect',
    templateUrl: './zendesk_redirect.component.html'
})
export class ZendeskRedirectComponent implements OnInit {


    constructor(private pinnacleService: ProdGenApi, private APIv2: Apiv2Service, private route: ActivatedRoute, private router: Router, private authenticationService: BrowserAuthenticationService) {
        if (authenticationService.AuthenticatePage() == true) {
            //check settings
            this.pinnacleService.getChatSettings().subscribe(res => {
                if (res.useZendeskSupport == true) {
                    this.APIv2.getZendeskRedirectURL().subscribe(res2 => {
                        window.location.href = res2.redirectURL;
                    }, err => {
                        router.navigate(['/home']);
                    })
                }
                else {
                    router.navigate(['/home']);
                }
            })
        }
    }

    ngOnInit() {

    }




}
