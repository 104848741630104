import { Component, OnInit, OnChanges, SimpleChanges, SimpleChange, Input, ElementRef, ViewChild  } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { Content, ContentType_T, DescriptionFilter_T, QuizStatus_T, ContentPrerequisite } from './../../../apiService/classFiles/class.content';
import { ProdGenApi } from './../../../apiService/prodgen.api';
import { LearningPath, Course, CourseWithContent } from './../../../apiService/classFiles/class.content';
import { Enrollment, EnrollmentFilter_T, EnrollmentStatus_T, QuizTrackingItem, QuizSessionItem, KSMappedAssessment, AssignTrainingForAssessmentCompletionRequest } from './../../../apiService/classFiles/class.enrollments';
import { IDatePickerConfig, DatePickerComponent } from 'ng2-date-picker'
import { PlaylistContent, Playlist } from '../../../apiService/classFiles/class.users';
import { ContentPrerequisiteInfo } from '../../../apiService/classFiles/class.content';
import { TranslationService } from '../../../services/TranslationService';
import { Workgroup, PermissionLevel_T } from './../../../apiService/classFiles/class.workgroups';
import { Apiv2Service } from './../../../apiService/apiv2.service';
import { AssignAssessmentRequest, KSUser, KnowledgeSmartConfiguration } from './../../../apiService/classFiles/v2-organizations';
import { AppComponent } from '../../../app.component';

declare var $: any;

@Component({
  selector: 'template-syllabustemplate',
  templateUrl: './syllabustemplate.component.html',
  styleUrls: ['./syllabustemplate.component.css']
})
export class SyllabustemplateComponent implements OnInit {
    @Input('courses') courses: Array<CourseWithContent> = new Array<CourseWithContent>();
    @Input('currentEnrollments') currentEnrollments: Array<Enrollment> = new Array<Enrollment>();
    @Input('completedEnrollments') completedEnrollments: Array<Enrollment> = new Array<Enrollment>();
    @Input('initialCourseId') initialCourseId: string = "";
    @ViewChild('shareGlyph', { static: false }) shareDropdownDiv: ElementRef;
    //@Input('learningPath') learningPath: LearningPath;
    initiallyExpanded = false;
    activeCourse: Course = new Course();
    currentDate: Date = new Date(Date.now()+ (30 * 1000*60*60*24));
    selectedDate: string;
    userplaylist: Playlist = new Playlist();
    enrollID: string = "";
    contentPrereqInfo: ContentPrerequisiteInfo = new ContentPrerequisiteInfo();
    prereqCourses: Array<ContentPrerequisite> = new Array<ContentPrerequisite>();
    prereqLearningPaths: Array<ContentPrerequisite> = new Array<ContentPrerequisite>();
    invokeMe: boolean = false;
    preReqNotice: boolean = false;
    loading: boolean = true;
    browserRoot: string = window.location.protocol + "//" + window.location.host + "/#/";

    selectedEnrollment = new Enrollment();
    seAssignedBy: string;
    seLoaded: boolean;

    hasCertificate: boolean = false;
    certificateURL: string = "";

    quizTrackingItems = new Array<QuizTrackingItem>();
    quizSessionItems: { [key: string]: QuizSessionItem; } = {};
    trackingItemsLoaded: boolean = false;
    ss = new QuizSessionItem();
    answerExplanations: { [key: string]: string; } = {};
    availibleWorkgroups: Array<Workgroup> = new Array<Workgroup>();

    shareDropdownState: boolean = false;
    shareDropdownStateList: Array<Boolean> = new Array<Boolean>();
    shareDropdownAdjustedHeight: string = "";

    selectedCourse: Course = new Course();

    courseTiedToAssessment: boolean = false;
    enrollmentOptions: string = "enroll";
    expireDate: string;
    mappedAssessments: Array<KSMappedAssessment>;

    ksAssignmentId: number = -1;
    ksconfigs: KnowledgeSmartConfiguration = Apiv2Service.KnowlegeSmartConfigs;
    
    public datePickerConfig: IDatePickerConfig = {
        format: "MM/DD/YYYY"
        
    };

    // Initialized to specific date (09.10.2018)
    model: any = { date: { year: 2018, month: 10, day: 9 } };


    constructor(private pinnacleService: ProdGenApi, private apiV2Service:Apiv2Service, private router: Router, private _sanitizer: DomSanitizer, private translationService: TranslationService) {
        this.selectedDate = this.currentDate.getFullYear() + "-" + this.pad(this.currentDate.getMonth()+1, 2) + "-" + this.pad(this.currentDate.getDate(), 2);
        this.expireDate = this.currentDate.getFullYear() + "-" + this.pad(this.currentDate.getMonth() + 1, 2) + "-" + this.pad(this.currentDate.getDate(), 2);
    }

    pad(num: number, size: number): string {
        let s = num + "";
        while (s.length < size) s = "0" + s;
        return s;
    }

    ngOnInit() {
        //need to grab the workgroups quick for the share buttons on the page
        this.pinnacleService.getCurrentUserWorkgroups([PermissionLevel_T.owner, PermissionLevel_T.editor]).subscribe(res => {
            this.availibleWorkgroups = res;
        });
        this.apiV2Service.getKSIntegrationConfigInfo().subscribe(res => {
            this.ksconfigs = res.knowledgeSmartConfiguration;
        }); 
        
    }

    loadCourseDetails(c: Course) {
        if (c.description == null || c.description == "" || c.description == "Loading...") {
            c.description = "Loading...";
            this.pinnacleService.GetCourse(c.courseId, DescriptionFilter_T.formatted).subscribe(resCourse => {
                c.description = resCourse.description;

                this.pinnacleService.GetCourseContentItems(c.courseId, -1, 0, DescriptionFilter_T.none).subscribe(content => {
                    let index = this.courses.findIndex(srch => srch.courseId == c.courseId);
                    if (index != -1)
                    {
                        this.courses[index].content = content;
                    }
                });

            });
        }
    }

  ngOnChanges(changes: SimpleChanges) {

      try {
          this.courses = changes['courses'].currentValue;

      } catch (err) { };
      try {
          this.initialCourseId = changes['initialCourseId'].currentValue;

      } catch (err) { };

      if (this.initialCourseId != null && this.initialCourseId != "" && this.courses != null) {
          for (let c of this.courses) {
              if (c.courseId == this.initialCourseId) {
                  this.loadCourseDetails(c);
              }
          }
      }

      if (this.shareDropdownStateList.length == 0) {
          for (let c of this.courses) {
              this.shareDropdownStateList.push(false);
          }          
      }
    }

  onCourseClicked(c: Course) {
      this.loadCourseDetails(c);
  }

  expandAllCourses() {
      for (let c of this.courses) {
          
          $('#collapse' + c.courseId).collapse("show");
          this.loadCourseDetails(c);
      }
  }

  collapseAllCourses() {
      for (let c of this.courses) {

          $('#collapse' + c.courseId).collapse("hide");
      }
  }

  onSetActiveCourse(course: Course)
  {

      this.selectedCourse = course;
      this.pinnacleService.getCurrentUser(false).subscribe(user => {
          this.pinnacleService.GetPrerequisitesForContentNotCompleted(this.selectedCourse.courseId, user.userId, 1).subscribe(cpi => {
              this.contentPrereqInfo = cpi;


              if (this.contentPrereqInfo.prerequisites != null && this.contentPrereqInfo.prerequisites.length > 0) {
                  this.preReqNotice = true;
                  this.invokeMe = false;

                  //if (this.contentPrereqInfo.prerequisites.length > 0) {

                  this.prereqCourses = new Array<ContentPrerequisite>();
                  this.prereqLearningPaths = new Array<ContentPrerequisite>();

                  for (let prs of this.contentPrereqInfo.prerequisites) {
                      if (prs.prereqType == 16)//LP
                      {
                          this.prereqLearningPaths.push(prs);
                      }

                      if (prs.prereqType == 17)//Crs
                      {
                          this.prereqCourses.push(prs);
                      }
                  }

                  this.prereqCourses.sort(function (a, b) {
                      if (a.prereqName < b.prereqName) { return -1; }
                      if (a.prereqName > b.prereqName) { return 1; }
                      return 0;
                  });
                  this.prereqLearningPaths.sort(function (a, b) {
                      if (a.prereqName < b.prereqName) { return -1; }
                      if (a.prereqName > b.prereqName) { return 1; }
                      return 0;
                  });

                  //}
              }
              else {
                  this.preReqNotice = false;
                  this.invokeMe = true;

                  this.activeCourse = course;
                  this.loadCourseDetails(course);
              }

              this.loading = false;
          });
      });
      
      this.apiV2Service.getAssessmentsFromContent(course.courseId.toString()).subscribe(res => {
          this.mappedAssessments = res.templateMappings;
          if (res.templateMappings.length > 0) {
              this.courseTiedToAssessment = true;
          }
          else {
              this.courseTiedToAssessment = false;
          }
      });
      

      /*this.activeCourse = course;
      this.loadCourseDetails(course);*/
  }

  onContinueCourse(course: Course)
  {
      // find the enrollment for the course
      let index = this.currentEnrollments.findIndex(f => f.courseId == course.courseId);
      if (index != -1)
      {
          let url: string = "learningcenter/courseviewer?enrollmentId=" + this.currentEnrollments[index].enrollmentId;

		  this.router.navigateByUrl(url).then(i => {
			  if (i == false) {
			  }
		  });
      }
  }

  onEnroll()
  {
      if (this.activeCourse != null) {
          let courseId = this.activeCourse.courseId;

          this.pinnacleService.SetCourseEnrollment(courseId,
              ($('#assignDueDateCheckboxCourse').is(":checked")),
              new Date(this.selectedDate)).subscribe(res =>
              {
                  if (res.courseId != "00000000-0000-0000-0000-000000000000") {
                    this.currentEnrollments.push(res);
              }
          });
          this.activeCourse = new Course();
      }
  }

  onDrop() {
      if (this.activeCourse != null)
      {
          let courseId = this.activeCourse.courseId;
          // get the enrollment if for the course
          for (let e of this.currentEnrollments) {
              if (e.courseId == courseId) {
                  this.pinnacleService.DeleteCourseEnrollment(e.enrollmentId).subscribe(res => {
                      this.currentEnrollments.splice(this.currentEnrollments.findIndex(i=>i.courseId == courseId),1);
                  });
              }
          }
          this.activeCourse = new Course();
      }

  }
    
  isEnrolled(courseId: string): boolean {

      for (let e of this.currentEnrollments)
      {
          if (e.courseId == courseId)
          {
              return true;
          }
      }
      return false;
    }


    isCourseCompleted(courseId: string): boolean {

        for (let e of this.completedEnrollments) {
            if (e.courseId == courseId) {
                return true;
            }
        }
        return false;
    }

    getCourseCompletedDate(courseId: string): Date {

        for (let e of this.completedEnrollments) {
            if (e.courseId == courseId) {
                return e.statusDate;
            }
        }
        return new Date();
    }


    courseReview(course: Course) {
        this.selectedEnrollment = new Enrollment;
        this.seAssignedBy = "";
        this.hasCertificate = false;
        
        this.seLoaded = false;
        //this.quizTrackingItems = new Array<QuizTrackingItem>(); // Clear previous enrollment content
        this.trackingItemsLoaded = false; 

        for (let e of this.completedEnrollments) {
            if (e.courseId == course.courseId) {
                this.selectedEnrollment = e;
                break;
            }
        }

        this.pinnacleService.getUser(this.selectedEnrollment.assignerUserId).subscribe(res => {
            this.seAssignedBy = res.name;
            this.seLoaded = true;
        });

            
            this.onPrintCertficate();
            this.certificateURL = this.translationService.getTranslationFileData("LEARNINGCENTER.Loading") + "...";
        

        this.pinnacleService.getAllQuizEnrollmentTracking(this.selectedEnrollment.enrollmentId as string).subscribe(res => {
            this.quizTrackingItems = new Array<QuizTrackingItem>(); // Clear previous enrollment content

            for (let r of res) {
               this.quizTrackingItems.push(r);

                for (let s of r.quizSessionItem) {
                    this.quizSessionItems[s.quizSessionId as string] = s; // Easier to store a reference list of sessions than to navigate HTML to find out which session was clicked
                }
            }
            
            this.trackingItemsLoaded = true;
        });
        // Bootstrap adds the class 'modal-open' to the body when showing
        // a modal in order to prevent scrolling content behind the modal.
        // Since we may possibly open 2 modals, we add our own version
        // to prevent the second modal from prematurely reenabling scrolling
        // when closing.
        (document.body as HTMLBodyElement).classList.add("my-modal-open");
        $("#courseReviewModal").on("hide.bs.modal", function (e) {
            $("body").removeClass("my-modal-open");
        });

        // Firefox apparently cares about scrollable elements behind the
        // focused element, so we remove the ability to scroll the course
        // review modal when the quiz details modal opens, and restore 
        // when the quiz details modal closes (button or clicking outside)
        $("#quizDetailModal").on("shown.bs.modal", function (e) {
            $("#courseReviewBody").css("overflow-y", "hidden");
        });

        $("#quizDetailModal").on("hide.bs.modal", function (e) {
            $("#courseReviewBody").css("overflow-y", "auto");
            $("#detailsModalBody").scrollTop(0); // Also scroll back to the top
        });

        // Used in a title, cannot be translated in html
        //this.passMessage = this.translationService.getTranslationFileData("LEARNINGCENTER.PassMessage");

    }

    prClick(item: ContentPrerequisite) {
        let url: string = "";

        if (item.prereqType == 16)//LP
        {   url = "learningcenter/series?learningPathId=" + item.prereqID;
        }
        else
        {   url = "learningcenter/series?learningPathId=" + item.prereqLPID + "&courseId=" + item.prereqID;
        }

        this.router.navigateByUrl(url).then(i => {
            if (i == false) {
                console.log("unable to navigate");
            }
        });
    }

    getScorePercentage(correct: number, possible: number): string {
        if (possible == null || possible < 1)
            return "0%";
        return ((correct / possible) * 100).toFixed(2).toString() + "%";
    }

    getQuizStatusText(status): string {
        if (status == QuizStatus_T.pass
            || status == "PASS") {
            return this.translationService.getTranslationFileData("LEARNINGCENTER.Pass");
        }
        if (status == QuizStatus_T.fail
            || status == "FAIL") {
            return this.translationService.getTranslationFileData("LEARNINGCENTER.Fail");
        }
        return "";
    }

    toggleIcon($event) {
        let childElement = (event.target as HTMLElement).querySelector('i');
        if (childElement != null) {
            if (childElement.classList.contains("glyphicon-menu-down")) {
                childElement.classList.add("glyphicon-menu-up");
                childElement.classList.remove("glyphicon-menu-down");
            }
            else if (childElement.classList.contains("glyphicon-menu-up")) {
                childElement.classList.add("glyphicon-menu-down");
                childElement.classList.remove("glyphicon-menu-up");
            }
        }

    }

    selectQuizSession(event) {

        this.pinnacleService.getCurrentUser(false).subscribe(user => {


            this.pinnacleService.getEmailLanguagePreference(user.email, ProdGenApi.getCurrentTenantId()).subscribe(res => {
                let langPref = res;
                this.ss = this.quizSessionItems[event.target.id];
                for (let q of this.ss.quizSessionTracking) {
                    if (q.explanation != null && q.explanation != "") {
                        if (langPref != "en") {
                            this.pinnacleService.translateText(q.explanation as string, "en", false).subscribe(res => {
                                this.answerExplanations[q.explanation as string] = res as string;
                            });
                        }
                        else {
                            this.answerExplanations[q.explanation as string] = q.explanation as string;
                        }
                    }
                }
            });
        });
    }

    detectNavigation(ans: string): boolean {
        if (ans != null && (ans.indexOf("quizQ") != -1)) {
            return true;
        }
        return false;
    }
   
    onPrintCertficate() {
        this.pinnacleService.getEnrollmentCertificate(this.selectedEnrollment.enrollmentId).subscribe(res => {
            let url: string = res.replace("http://", "https://");
            if (url.length > 0 && (url.toString() != "FailQuiz" && url.toString() != "NotComplete")) {
                this.hasCertificate = true;
                this.certificateURL = url;
            }
            else if (url.length > 0 && url.toString() == "FailQuiz") {
                this.hasCertificate = false;
                this.certificateURL = "Quiz Failed";
                this.certificateURL = this.translationService.getTranslationFileData("LEARNINGCENTER.NC_QuizFailed");
            }
            else if (url.length > 0 && url.toString() == "NotComplete") {
                this.hasCertificate = false;
                this.certificateURL = "Not Complete";
                this.certificateURL = this.translationService.getTranslationFileData("LEARNINGCENTER.NC_ItemsSkipped");
            }
            else {
                this.hasCertificate = false;
                this.certificateURL = "NoCertificate";
                this.certificateURL = this.translationService.getTranslationFileData("LEARNINGCENTER.NC_NoCertificate");
            }
        },
            err => {
                this.hasCertificate = false;
                this.certificateURL = "Error";
                this.certificateURL = this.translationService.getTranslationFileData("LEARNINGCENTER.NC_Error");
            });
    }


  getActiveDesc(course: Course): SafeHtml {
      if (course == null)
          return this._sanitizer.bypassSecurityTrustHtml("");

      return this._sanitizer.bypassSecurityTrustHtml(course.description as string);
  }

  getDefaultExpandedClass(courseId: string): string {
      if (this.initialCourseId.length > 0 && this.initiallyExpanded==false) {
          if (courseId == this.initialCourseId) {
              this.initiallyExpanded = true;
              $('#collapse' + courseId).collapse("show");
              this.scrollToObject('#collapse' + courseId);
          }
      }
      return "";
  }

  isDatePickerDisabled() {
      if ($('#assignDueDateCheckboxCourse').is(":checked") == true) {
          $('#datepickerCourse').prop('disabled', false);
      }
      else {
          $('#datepickerCourse').prop('disabled', true);
      }
  }

  getExpandedIconClass(elmId: string ):string
  {
      var isExpanded = $(elmId).hasClass("in");
      if (isExpanded == true)
      {
          return "fa fa-minus";
      }
      return "fa fa-plus";

  }


  getContentImage(r: Content): string {
      let image: string = "";

      //image = "assets/images/";

      //if (r.docURL != "" && r.docURL != null && r.contentType == ContentType_T.cheatsheet) {
      //    if (r.docURL.includes(".pdf")) {
      //        image += "PDF Icon.png"
      //    }
      //    else if (r.docURL.includes(".doc")) {
      //        image += "Word Icon.png"
      //    }
      //    else if (r.docURL.includes(".xls")) {
      //        image += "Excel Icon.png"
      //    }
      //    else if (r.docURL.includes(".ppt")) {
      //        image += "PowerPoint Icon.png"
      //    }
      //    else if (r.docURL.includes(".htm")) {
      //        image += "HTML Icon.png"
      //    }
      //}
      //else {
      //    image += ProdGenApi.getIconImageFromType(r.contentType);
      //}
      image += ProdGenApi.getIconImageFromType(r.contentType);

      return image;
  }

  typeCount(contentType: string, course: CourseWithContent): number {
      let count: number = 0;
      if (course == null)
          return 0;

      for (let c of this.courses) {
          if (course.courseId == c.courseId) {
              for (let content of c.content) {
                  if (content.contentType == ContentType_T[contentType]) {
                      count++;
                  }
              }
          }
      }

      return count;
  }

  scrollToObject(obj: string) {
      $('html, body').animate({
          scrollTop: $(obj).offset().top-120
      }, 1000);
  }

  AddtoPlaylist(plcourse : CourseWithContent) {

    let index = this.currentEnrollments.findIndex(f => f.courseId == plcourse.courseId);
    if (index != -1) {
      this.enrollID = this.currentEnrollments[index].enrollmentId.toString();
    }

    this.pinnacleService.getCurrentUser().subscribe(user => {
      this.pinnacleService.GetPlaylist(user.userId).subscribe(res => {
        this.userplaylist = res;
      
        let plcontent = new PlaylistContent();

        plcontent.addeddatetime = new Date();
        plcontent.bisdownloadable = true;
        plcontent.contentId = plcourse.courseId.toString();
        plcontent.contentType = 2;
        plcontent.plid = this.userplaylist.plid;
        plcontent.tenantid = this.userplaylist.tenantid;

        this.pinnacleService.AddtoPlaylist(this.userplaylist.plid, plcourse.courseId.toString(), plcontent, this.enrollID).subscribe(res => {
          let plcontentID = res;
        });
      });
    });
    }

    onCancel()
    {
        $('#enrollCourseModalSyll').modal('hide');
        this.preReqNotice = false;
        this.invokeMe = false;
    }

    shareClick(courseId:string, contentId:string) {
        var elContentContainer = document.getElementById('stContentDiv' + courseId);
        var elContentItem = document.getElementById('stContentItem' + contentId);

        var elDifference = (elContentContainer.offsetTop + elContentContainer.offsetHeight) - (elContentItem.offsetTop + elContentItem.offsetHeight);
        var elAddition = 0;

        if (elDifference < 355) {//350 is the max height of the share dropdown. 5 has been added for padding
            elAddition = 355 - elDifference;
        }

        this.shareDropdownAdjustedHeight = (elContentContainer.offsetHeight + elAddition) + "px";

    }


    continueEnrollmentProgress() {


        if (!this.courseTiedToAssessment || (this.courseTiedToAssessment && this.enrollmentOptions == "enroll")) {

            this.onEnroll();
        }

        else if (this.courseTiedToAssessment && this.enrollmentOptions != "enroll") {
            $("#CourseSpinnerModal").modal("show");

            let selectedKSAssessmentId = $("#selectedAssessmentsCourse").val()

            let selectedMapAssessment = new KSMappedAssessment();
            selectedMapAssessment = this.mappedAssessments.find(x => x.ksAssessmentId == selectedKSAssessmentId)


            let templateMapList = new Array<KSMappedAssessment>();
            templateMapList.push(selectedMapAssessment);

            let requestBody = new AssignAssessmentRequest();
            requestBody.expiryDate = new Date(this.expireDate);
            requestBody.displayName = ProdGenApi.currentUser.name;
            requestBody.hasExpiryDate = $('#assignExpireDateCheckboxCourse').is(":checked"); //It returns true is disabled. (That's why the '!' is there).
            requestBody.status = "";
            requestBody.testID = selectedMapAssessment.ksAssessmentId;
            requestBody.userEmail = ProdGenApi.currentUser.email;            

            this.apiV2Service.assignAssessment(requestBody).subscribe(res => {

                let temp: number = res.assessmentId;
                this.ksAssignmentId = temp;


                let requestObj = new AssignTrainingForAssessmentCompletionRequest();
                requestObj.assessmentId = this.ksAssignmentId;
                requestObj.assessmentName = selectedMapAssessment.ksAssessmentName;
                requestObj.assignmentId = selectedMapAssessment.ksAssessmentId;
                requestObj.bHasDueDate = false;
                requestObj.dueDate = new Date();
                requestObj.templateMappings = templateMapList;

                AppComponent.thisFromOutside.updateKSNavChild();


                this.pinnacleService.assignTrainingForAssessmentCompletion(requestObj).subscribe(res2 => {

                    //this.showSpinner = false;
                    
                    $("#assignedToAssessmentModalCourse").modal("show");
                    $("#CourseSpinnerModal").modal("hide");
                });

            });
        }
    }

    isExpireDatePickerDisabled() {       
        if ($('#assignExpireDateCheckboxCourse').is(":checked")) {
            $('#expiredatepickerCourse').prop('disabled', false);
        }
        else {
            $('#expiredatepickerCourse').prop('disabled', true);
        }
    }

    assessmentRedirect() {

        let KSuserInfo: KSUser = null;

        this.apiV2Service.getAssessmentUserFromEmail(ProdGenApi.currentUser.email).subscribe(res => {

            KSuserInfo = res;

            if (KSuserInfo == null) {
                //modal?? sorry you can't?
                //something went wrong...
                return;
            }

            //get the SSO Login Token
            this.apiV2Service.requestLoginToken(KSuserInfo.userID).subscribe(login => {


                let resultlinkURL: string = "https://online.knowledgesmart.net/trafficcop.aspx?" + "userid=" + KSuserInfo.userID +
                    "&logintoken=" + login + "&destination=taketest&inviteID=" + this.ksAssignmentId;

                window.open(resultlinkURL, '_blank').focus();

                this.router.navigate(['/home']);

            })

        });
    }

}
