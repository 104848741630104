import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter, ViewChild } from '@angular/core';
import { Enrollment, EnrollmentTrackingItem, QuizTrackingItem } from '../../apiService/classFiles/class.enrollments';
import { Content, ContentType_T } from '../../apiService/classFiles/class.content';
import { ProdGenApi } from './../../apiService/prodgen.api';
import { LearningCenterCourseViewerComponent } from '../../learningcentercourseviewer/learningcentercourseviewer.component';
import { AutoplayVidService } from './../../AutoplayVidService';
import { PermissionLevel_T, Workgroup } from '../../apiService/classFiles/class.workgroups';
import { get } from 'https';
import { DiagramTreeService } from './../../services/diagram-tree.service';
import { WorkflowtreeComponent } from './../../templates/workflowtree/workflowtree.component';
import { ShareComponent } from '../share/share.component';


declare var $: any;

@Component({
    selector: 'template-courseviewersyllabus',
    templateUrl: './courseviewersyllabus.component.html',
    styleUrls: ['./courseviewersyllabus.component.css']
})
export class CourseViewerSyllabusComponent implements OnInit {
    editorWorkgroups: Array<Workgroup> = new Array<Workgroup>();
    @Input('enrollment') enrollment: Enrollment;
    @Input('activeItem') activeItem?: string = "";
    @Output('activeItemChanged') activeItemChanged?= new EventEmitter<any>();
    @ViewChild('wfComponent', { static: false }) wfComponent: WorkflowtreeComponent;

    contentItems: Array<EnrollmentTrackingItem> = new Array<EnrollmentTrackingItem>();
    newcontentItems: Array<EnrollmentTrackingItem> = new Array<EnrollmentTrackingItem>();
    isHideCustomComplete: boolean = false;
    isNextActiveItem: boolean;
    isPrevActiveItem: boolean;
    isInitialLoad: boolean = true;
    allowAutoPlay: boolean = false;
    enrollmentcontent: Content = new Content();
    isMarked: boolean = false;
    markedContent: string = "";
    iterateMarkedValue: any = 0;
    finishCourseSequential: boolean = false;
    nextPossibleIndex: number = 0;

    nextPossibleID: string = "";
    shareDropdownState: boolean = false;

    m_owner: LearningCenterCourseViewerComponent = null;
    allowContinue: boolean = true;

    courseItems: Array<Content> = new Array<Content>();

    constructedContent: Content = new Content();
    currentHover: string = "";
    shareClickId: string = "";
    shareAdjustmentX: string = '0';
    shareAdjustmentY: string = '0';

    public owner(p_owner: LearningCenterCourseViewerComponent) {
        this.m_owner = p_owner
    }

    public getNextID() {
        return this.nextPossibleID;
    }
    public setNextID(p_ID: string) {
        this.nextPossibleID = p_ID;
    }

    constructor(private pinnacleService: ProdGenApi, private autoplaysidservice: AutoplayVidService, private diagramTreeInteractionService: DiagramTreeService) {

        //this.loadTrackingItems();

    }

    ngOnInit() {
        this.pinnacleService.getCurrentUserWorkgroups([PermissionLevel_T.owner, PermissionLevel_T.editor]).subscribe(res => {
            this.editorWorkgroups = res;
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        try {
            let tmpEnrollment = changes['enrollment'].currentValue;
            let msg1 = tmpEnrollment.enrollmentId;
            let msg2 = this.enrollment.enrollmentId;


            if (tmpEnrollment.enrollmentId != this.enrollment.enrollmentId || this.contentItems.length == 0) {
                this.enrollment = changes['enrollment'].currentValue;

                this.loadTrackingItems();

                if (this.enrollment != null && this.enrollment.courseId != null && this.enrollment.courseId != "") {

                    this.pinnacleService.addUserHistory(this.enrollment.courseId as string, ContentType_T.course).subscribe();
                }
            }

            this.enrollmentcontent.contentId = this.enrollment.courseId;
            this.enrollmentcontent.contentType = ContentType_T.course;
            this.enrollmentcontent.description = this.enrollment.description;
            this.enrollmentcontent.imageURL = this.enrollment.imageURL;
            this.enrollmentcontent.name = this.enrollment.courseName;

        } catch (err) { };

        try {
            this.activeItem = changes['activeItem'].currentValue;

            for (let i = 0; i < this.contentItems.length; i++) {
                if (this.activeItem == this.contentItems[i].contentId && i > this.nextPossibleIndex) {
                    this.nextPossibleIndex = i;
                    this.nextPossibleID = this.contentItems[i].contentId as string;
                }
            }
        } catch (err) { };
    }


    loadTrackingItems() {
        this.newcontentItems.splice(0);
        if (this.enrollment == null ||
            this.enrollment.enrollmentId == null ||
            this.enrollment.enrollmentId.length == 0) {
            return;
        }


        this.pinnacleService.GetEnrollmentTrackingItems(this.enrollment.enrollmentId as string).subscribe(res => {
            
            this.pinnacleService.GetCourseContentItems(this.enrollment.courseId).subscribe(res2 => {
                this.courseItems = res2;

                for (let i = 0; i < res.length; i++) {
                    let index = this.courseItems.findIndex(x => x.contentId == res[i].contentId);
                    if (index != -1) {
                        res[i].contentObject = this.courseItems[index];

                    }
                    else {
                        // we need just a simple object instance for the Finish Course Item content object to avoid running into problems later
                        let finishCourseContent = new Content();
                        finishCourseContent.contentId = "";
                        finishCourseContent.name = "";
                        finishCourseContent.bisDownloadable = false;
                        
                        res[i].contentObject = finishCourseContent;
                    } 

                }
                this.contentItems = res;                           
            
            //IF the enrollment item is flagged as Knowlegesmart//
            if (this.enrollment.isCustom == true) {

                //check if the enrollment tracking items are knowlegesmart deficiencies.
                //for testing, create a fake list of knowlegesmart passed Tracking Items
                //var i = 0;
                //for (let ti of this.contentItems) {
                //  if (i == 2 || i == 3 || i == 4) {
                //    this.contentItems[i].isCustomPassed = true;
                //  }
                //  else {
                //    this.contentItems[i].isCustomPassed = false;
                //  }
                //  i++;
                //}

                this.pinnacleService.GetSkillsGapItems(this.enrollment.enrollmentId as string).subscribe(gaps => {

                    for (let ti of this.contentItems) {
                        ti.isCustomPassed = true;

                        for (let g of gaps) {

                            if (g == ti.contentId || ti.name == "Finish Course") {
                                ti.isCustomPassed = false;

                            }
                            else {
                                //ti.isComplete = true;
                            }

                        }
                        this.newcontentItems.push(ti);
                    }

                    // check if we were passed active item
                    // if (this.activeItem == null || this.activeItem.length == 0) {
                    // if not, we will go to the first incomplete item
                    //for (let c of res)
                    for (let c of this.newcontentItems) {
                        if (c.isComplete == false && c.isCustomPassed == false) {
                            this.activeItem = c.contentId as string;
                            this.onActiveItemChanged(this.activeItem);
                            break;
                        }
                    }
                    //}
                });

            }
            else {

                // check if we were passed active item
                //if (this.activeItem == null || this.activeItem.length == 0) {
                // if not, we will go to the first incomplete item
                //for (let c of res)

                //Finish Course idx 
                let finCrse = this.contentItems.length - 1;

                for (let c of this.contentItems) {
                    if (c.isComplete == false) {

                        /*FINISH COURSE NEEDS DISABLING when forceSequential as well......(ALSO when NEXT, ensure PREV correct)*/
                        /*classList.toggle/remove*/

                        this.activeItem = c.contentId as string;
                        this.onActiveItemChanged(this.activeItem);
                        break;
                    }
                }
                // }
            }
            //If they are NOT deficiencies then flag them as knowlegesmart passed.//
            $('#CourseButton_' + this.enrollment.enrollmentId).attr('style', 'display:none');
            $('#courseSyllabus').attr('style', 'display:normal');

            this.m_owner.checkQuizzesStatus();
            });
        });

    }

    public getPercentComplete(): number {

        let num: number = 0;

        if (this.enrollment.isCustom == true) {
            if (this.newcontentItems.length > 2) {
                for (let c of this.newcontentItems) {
                    if (c.contentType != ContentType_T.course && (c.isComplete == true || c.isCustomPassed == true)) {
                        num++;
                    }
                }
                return Math.round((100.0 * num / (this.newcontentItems.length - 1)) + .499);
            }
            return 0;
        }
        else {
            if (this.contentItems.length > 2) {
                for (let c of this.contentItems) {
                    if (c.contentType != ContentType_T.course && (c.isComplete == true || c.isCustomPassed == true)) {
                        num++;
                    }
                }
                return Math.round((100.0 * num / (this.contentItems.length - 1)) + .499);
            }
            return 0;
        }

    }


    public areAllItemsComplete() {

        if (this.enrollment.isCustom == true) {
            for (let c of this.newcontentItems) {

                if (c.contentType != ContentType_T.course && (c.isComplete == false && c.isCustomPassed == false)) {
                    return false;
                }
            }
            return true;
        }
        else {
            for (let c of this.contentItems) {
                if (c.contentType != ContentType_T.course && (c.isComplete == false && c.isCustomPassed == false)) {
                    return false;
                }
            }
            return true;
        }

    }

    public isFirstItemActive() {

        if (this.contentItems.length > 0) {
            return this.isActiveItem(this.contentItems[0].contentId as string);
        }
        return true;
    }

    getBackgroundColor(contentId: string, isCustomPassed: boolean): string {

        //add background color for Knowlegesmart passed

        if (this.isActiveItem(contentId) == true) {
            //return "lightgray";
            //return "whitesmoke";
            //return "gainsboro";
            return "rgb(230,230,230)";
        }
        if (isCustomPassed == true) {
            return "darkgray";
        }
        return "white";
    }

    isActiveItem(contentId: string): boolean {

        return (contentId == this.activeItem);
    }

    public onNextItem() {

        $('.btn').blur();

        let index = this.contentItems.findIndex(i => i.contentId == this.activeItem);



        if (index != -1 && index < this.contentItems.length - 1) {

            if (this.contentItems[index].contentType == 5 || this.contentItems[index].contentType == 6) {

                let v_ID = this.diagramTreeInteractionService.GetSelectContent().contentId;


                this.wfComponent.NextItem();

                return;
            }

            this.nextItemShared(index);

        }

    }

    nextItem() {
        let index = this.contentItems.findIndex(i => i.contentId == this.activeItem);


        this.nextItemShared(index);

    }

    private nextItemShared(index: number) {
        if (index != -1 && index < this.contentItems.length - 1) {
            do {
                if (this.contentItems[index + 1].isCustomPassed == true) {

                    index++;
                    this.isNextActiveItem = true;
                }
                else {
                    this.onActiveItemChanged(this.contentItems[index + 1].contentId as string);
                    this.isNextActiveItem = false;
                }
            } while (this.isNextActiveItem == true);
        }
    }

    public onPrevItem() {

        $('.btn').blur();
        let index = this.contentItems.findIndex(i => i.contentId == this.activeItem);

        if (index != -1 && index > 0) {

            if (this.contentItems[index].contentType == 5 || this.contentItems[index].contentType == 6) {

                let v_ID = this.diagramTreeInteractionService.GetSelectContent().contentId;


                this.wfComponent.PrevItem();

                return;
            }

            this.PrevItemShared(index);
        }

    }

    public PrevItem() {
        let index = this.contentItems.findIndex(i => i.contentId == this.activeItem);
        this.PrevItemShared(index);
    }

    private PrevItemShared(index: number) {

        if (index != -1 && index > 0) {
            do {
                if (this.contentItems[index - 1].isCustomPassed == true) {
                    index = index - 1;
                    this.isPrevActiveItem = true;
                }
                else {
                    this.onActiveItemChanged(this.contentItems[index - 1].contentId as string);
                    this.isPrevActiveItem = false;
                }
            } while (this.isPrevActiveItem == true);
        }

    }

    public onClickOffItem() {



        let index = this.contentItems.findIndex(i => i.contentId == this.activeItem);

        if (index != -1 && index < this.contentItems.length - 1) {
            do {
                if (this.contentItems[index + 1].isCustomPassed == true) {
                    index++;
                    this.isNextActiveItem = true;
                }
                else {
                    this.onActiveItemChanged(this.contentItems[index + 1].contentId as string);
                    this.isNextActiveItem = false;
                }
            } while (this.isNextActiveItem == true);

        }


    }

    onActiveItemChanged(contentId: string) {



        let index = this.contentItems.findIndex(i => i.contentId == contentId);

        /*FINISH COURSE NEEDS DISABLING......(ALSO when NEXT, ensure PREV correct)*/

        if (index != -1) {

            // Before we change, check if the current item is a SCORM Course.
            // If it is, we should award credit if the Course doesn't require
            // a certain score to pass (and by extension doesn't require the
            // package to be completed).
            //if (this.contentItems[index].contentType == ContentType_T.scormcourse
            //    && !this.contentItems[index].scormRequireScore
            //    && !this.contentItems[index].scormRequireComplete) {
            //    this.contentItems[index].isComplete = true;
            //}
            // UPDATE Jan 12, 2021 - We're now requiring that pacakges be complete before awarding credit

            if (index != (this.contentItems.length - 1)) {
                if (this.contentItems[index].contentType == 4) { //both this item and the next item are videos, enable autoplay //10-11-18 opted to make videos auto-advance to the next item, regardless of what that item is.
                    //this.allowAutoPlay = true;
                    this.autoplaysidservice.SetAutoplayNext(true);
                }
                else {
                    //this.allowAutoPlay = false;

                    this.autoplaysidservice.SetAutoplayNext(false);
                }
            }

            this.activeItem = contentId;

            this.activeItemChanged.emit({
                activeItem: this.contentItems[index],
                update: true
            });

            if (this.contentItems[index].contentType == 5 || this.contentItems[index].contentType == 6) {
                let v_Content = new Content();
                v_Content.contentId = this.contentItems[index].contentId;
                v_Content.contentType = this.contentItems[index].contentType;
                v_Content.name = this.contentItems[index].name;

                this.diagramTreeInteractionService.SetSelectContent(v_Content);
                this.diagramTreeInteractionService.SetObjectClickId(v_Content.contentId as string);
                //this.diagramTreeInteractionService.SetDiagramId(v_Content.contentId as string);


                for (var i = 0; i < this.contentItems.length; i++) {
                    if (index == i) {
                        this.contentItems[index].isTreeCollapsed = true;
                    }
                    else {
                        this.contentItems[i].isTreeCollapsed = false;
                    }
                }
            }
            else {
                for (var i = 0; i < this.contentItems.length; i++) {
                    this.contentItems[i].isTreeCollapsed = false;
                }
            }

        }
    }

    getContentImage(r: EnrollmentTrackingItem): string {

        let image: string = "";

        image = ProdGenApi.getIconImageFromType(r.contentType);

        return image;
    }

    changeValue(value: boolean): any {
        this.isHideCustomComplete = value;
    }

    onCheck(contentId: string): void {
        this.isMarked = true;
        this.markedContent = contentId;
        if (this.contentItems.length > 0 && !this.contentItems[0].ForceViewSequential) {
            let index = this.contentItems.findIndex(i => i.contentId == contentId);
            if (this.contentItems[index].contentType != ContentType_T.quiz
                && this.contentItems[index].contentType != ContentType_T.scormcourse) {
                this.contentItems[index].isComplete = false;
            }
        }
    }

    stopProgress() {
        this.allowContinue = false;

        if (
            this.activeItem != this.contentItems[this.contentItems.length - 1].contentId // Not on Finish Course
            && this.contentItems.length > 1 // AND items before Finish Course
        ) {
            let index = this.contentItems.length - 2;
            for (index; index > -1; index--) {
                if (this.m_owner.failedQuizzes.indexOf(this.contentItems[index].contentId.toString()) != -1) {
                    this.nextPossibleID = this.contentItems[index].contentId.toString();
                    break;
                }
            }

            this.activeItem = this.nextPossibleID;

            this.activeItemChanged.emit({
                activeItem: this.contentItems[index],
                update: false
            });
        }
    }

    resetFailedQuizzes() {
        this.m_owner.resetFailedQuizzes();
    }


    //For Share DropDown
    buildItem(itemID: string, itemName: string, itemType: ContentType_T): Content {
        let constructedContent = new Content();
        constructedContent.contentId = itemID;
        constructedContent.name = itemName;
        constructedContent.contentType = itemType;

        return constructedContent;
    }

    //For Share DropDown
    hoverElem(content: Content) {
        this.constructedContent = this.buildItem(content.contentId.toString(), content.name.toString(), content.contentType);
        this.currentHover = content.contentId.toString();

        if (this.currentHover != this.shareClickId) {
            this.shareClickId = "";
        }
    }

    //For Share DropDown
    shareClick(content: Content) {
        this.constructedContent = this.buildItem(content.contentId.toString(), content.name.toString(), content.contentType);
        if (this.shareClickId == content.contentId.toString()) {
            this.shareClickId = "";
        }
        else {
            this.shareClickId = content.contentId.toString();
        }

        let tempdiv = document.getElementById('contentScrollArea');

        let box = { left: 0, top: 0 };
        box = tempdiv.getBoundingClientRect();


        let reservedSpotShare = document.getElementById('shareIconPlacment' + content.contentId);

        let box2 = { left: 0, top: 0 };
        box2 = reservedSpotShare.getBoundingClientRect();

        let x = (box.left - box2.left);
        let y = (box.top - box2.top);
        //console.log('x : ' + x + '   y : ' + y);


        if (x > -250) {
            this.shareAdjustmentX = (20) + 'px';
        }
        else {
            this.shareAdjustmentX = ((-1 * x) - 220) + 'px';
        }

        this.shareAdjustmentY = ((-1 * y) + 20) + 'px';

    }

    //For Share DropDown
    onHideShareDropDown(event) {
        this.shareClickId = "";
    }

    //For Share DropDown
    exitHoverElem() {
        if (this.shareClickId == "") {
            this.currentHover = "";
        }
    }
}

