import { Component, OnInit, AfterViewInit, Input, OnChanges, SimpleChanges, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { ProdGenApi } from './../../apiService/prodgen.api';
import { ContentType_T, DescriptionFilter_T, Video, Content } from './../../apiService/classFiles/class.content';

declare function makeAblePlayerReady(): void;
declare var $: any;
declare var AblePlayer: any;


@Component({
	selector: 'video-modal',
	templateUrl: './videomodal.component.html',
	styleUrls: ['./videomodal.component.css']
})
export class VideoModalComponent implements OnInit, AfterViewInit {
	@Input('contentId') contentId: string;
	@Input('enrollmentId') enrollmentId?: string;
	@Input('openCount') openCount?: number;
	renderer: Renderer2 = null;
	isModal: boolean = true;
	hasLoaded: boolean = false;
	title: string = "";

	constructor(private service: ProdGenApi, private _sanitizer: DomSanitizer, elementRef: ElementRef, renderer: Renderer2) {
		this.renderer = renderer;
		this.loadData();
	}

	ngOnInit() {
	}

	ngAfterViewInit() {
	}

	ngOnChanges(changes: SimpleChanges) {
        try {
            if (changes['contentId'] != null) {

				this.contentId = changes['contentId'].currentValue;

				if (this.contentId != "") {

					this.service.GetContentItem(this.contentId, DescriptionFilter_T.none).subscribe(res => {
                        this.title = res.name as string;
                        this.writeHistory();
					});
				}
            }

		} catch (err) { console.log(err) };

	}


	loadData() {
        this.hasLoaded = false;
		if (this.contentId != null && this.contentId != "") {
			if (this.enrollmentId == null || this.enrollmentId == "") {
				this.service.GetVideo(this.contentId, DescriptionFilter_T.formatted).subscribe(v => {
                    this.title = v.name.toString();
                    this.writeHistory();
				});
			}
			else {
				this.service.getEnrollmentVideo(this.enrollmentId, this.contentId, DescriptionFilter_T.formatted).subscribe(v => {
                    this.title = v.name.toString();
                    this.writeHistory();
				});
			}
		}
	}


    btnCloseClick() {
        try {
            AblePlayer.lastCreated.pauseMedia();
        } catch (err) { }
  }

  btnGoToVidClick() {
    try {
      AblePlayer.lastCreated.pauseMedia();
      location.href = "#/videoviewer?id=" + this.contentId;
    } catch (err) { }
    }

    writeHistory() {
        this.service.addUserHistory(this.contentId as string, ContentType_T.video).subscribe();
    }
}
