import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'ep-star-rating',
    templateUrl: 'star-rating.component.html',
    styleUrls: ['star-rating.component.css']
})

export class EpStarRatingComponent implements OnInit {

    @Input() viewBox: string = "0 0 110 30";
    @Input() height: string = "23.083px";
    @Input() width: string = "154.27px";

    @Input() allowEditing: boolean = true;
    @Input() color: string = "#6391FF";
    @Input() background: string = "rgba(1,1,1,0)";
    @Input() outline: string = "#6391FF";

    // Number 0-5 representing the number of stars to show
    @Input() value: number = 0;
    @Output() valueChange: EventEmitter<number> = new EventEmitter<number>();

    //static isIE: boolean = false;

    constructor() {
        /*
        if (!("classList" in document.createElementNS("http://www.w3.org/2000/svg", "g"))) {
            // IE does not natively support the .classList property for SVG elements. 
            EPStarRatingComponent.isIE = true;
            var descr = Object.getOwnPropertyDescriptor(HTMLElement.prototype, 'classList');
            Object.defineProperty(SVGElement.prototype, 'classList', descr);
        }
        */
    }

    ngOnInit(): void {
        if (typeof (this.value) != "number"
            || this.value < 0
            || this.value > 10) {
            this.value = 0;
        }
        if (this.allowEditing == undefined) {
            this.allowEditing = false;
        }
        /*
        if (EPStarRatingComponent.isIE) {
            // IE does not like having CSS variables passed in through Angular bindings for some reason...
            if (this.background == "var(--page-background-color)") {
                //this.background = AppComponent.pageBackgroundColor;
            }
            if (this.color == "var(--panel-body-foreground-color)") {
                //this.color = AppComponent.panelBodyForegroundColor;
            }
            if (this.outline = "var(--panel-body-foreground-color)") {
                //this.outline = AppComponent.panelBodyForegroundColor;
            }
        }
        */
    }

    submitValue(value: number): void {
        if (this.allowEditing) {

            //If the user clicks the same star
            //that was already filled, we are going
            //to clear all stars;
            if (this.value === value) {
                value = 0;
            }

            this.value = value;
            this.valueChange.emit(this.value);
        }
    }
}