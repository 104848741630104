export class UserDataGridInfo {
    UserId: string = "";
    DisplayName: string = "";
    EmailAddress: string = "";
    GroupNameString: string = "";
    GroupIdString: string = "";
    IsExternal: boolean = false;

    // Not used in the browser.
    private IsSelected: boolean = false;
}