import { Injectable } from '@angular/core';
import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeValue } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { IDatePickerConfig, DatePickerComponent } from 'ng2-date-picker'

import cssVars from 'css-vars-ponyfill';
import { Content, ContentType_T } from './../../apiService/classFiles/class.content';
import { Enrollment } from './../../apiService/classFiles/class.enrollments';
import { ProdGenApi } from './../../apiService/prodgen.api';
declare var $: any;

@Component({
  selector: 'template-enrollment-card-small',
    templateUrl: './enrollment-card-small.component.html',
    styleUrls: ['./enrollment-card-small.component.css'],
  providers: [ProdGenApi]
})
export class EnrollmentCardSmallComponent implements OnInit {

    @Input('enrollment') item: Enrollment;
    @Input('enrollments') items: Array<Enrollment>;
    @Input('themeRotation') themeRotation: number;
    @Output('enrollmentDropped') enrollmentDropped?= new EventEmitter<any>();
    currentDate: Date = new Date(Date.now() + (30 * 1000 * 60 * 60 * 24));
    selectedDate: string;
    popoverfill: string = "";
    hasDueDate: boolean = false;
    showingDueDateModal = false;
    lpEnrollments: Array<String> = new Array<String>();

    public datePickerConfig: IDatePickerConfig = {
        format: "MM/DD/YYYY"
    };

    constructor(private pinnacleService: ProdGenApi, private router: Router, private _sanitizer: DomSanitizer) {
        this.selectedDate = this.currentDate.getFullYear() + "-" + this.pad(this.currentDate.getMonth() + 1, 2) + "-" + this.pad(this.currentDate.getDate(), 2);
    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
        try {
            if (changes['item'] != null) {
                this.item = changes['item'].currentValue;

                this.hasDueDate = this.item.hasDueDate as boolean;
                if (this.item.hasDueDate == true) {
                    let dueDate: Date = new Date(this.item.dueDate);
                    this.selectedDate = dueDate.getFullYear() + "-" + this.pad(dueDate.getMonth() + 1, 2) + "-" + this.pad(dueDate.getDate(), 2);
                }
            }
        } catch (err) { console.log(err); };
        try {
            this.themeRotation = changes['themeRotation'].currentValue;

        } catch (err) { };
    }

    onShowingDueDateModal() {
        this.showingDueDateModal = true;
    }

    getClassList(): string {
        let classes: string = "col-xs-12 col-sm-4 col-md-4 col-lg-4  wg-card clearfix ";
        let themeNum: number = (this.themeRotation % 3);

        //if (this.themeRotation < 3)
        //{
        //    themeNum = ((this.themeRotation + 1) % 3);
        //}
        //else
        //{
        //}
        //themeNum += 1;

        if (themeNum == 0) {
            themeNum = 1;
        }
        else if (themeNum == 1) {
            themeNum = 2;
        }
        else if (themeNum == 2) {
            themeNum = 3;
        }

        classes += "smallcardcolortheme" + themeNum;

        if ($('#options_' + this.item.enrollmentId) != null) {
            $('#options_' + this.item.enrollmentId).css('background-color', $('#enrollment_card_' + this.item.enrollmentId).css('background-color'));
        }

        if (this.item.childCourses > 1) {
            //$('#enrollment_card_' + this.item.enrollmentId).css('box-shadow', '5px 5px var(--large-card-background-color1), 6px 6px var(--large-card-border-color), 10px 10px var(--large-card-background-color3), 11px 11px var(--large-card-border-color), 15px 15px var(--large-card-background-color2), 16px 16px var(--large-card-border-color)');
            $('#enrollment_card_' + this.item.enrollmentId).addClass("stackedcard");
        }


        return classes;
    }

    onDrop() {
        if (this.item != null) {
            this.pinnacleService.DeleteCourseEnrollment(this.item.enrollmentId).subscribe(res => {
                if (this.enrollmentDropped != null) {
                    this.enrollmentDropped.emit({ enrollment: this.item });
                }
            });
        }
    }

    onChangeDueDate() {
        if (this.item != null) {
            let hasDueDate = ($('#assignDueDate_' + this.item.enrollmentId).is(":checked"));
            this.pinnacleService.setEnrollmentDueDate(this.item.enrollmentId,
                hasDueDate,
                new Date(this.selectedDate)).subscribe(res => {

                    if (res.enrollmentId != "00000000-0000-0000-0000-000000000000") {
                        this.hasDueDate = hasDueDate;
                        this.item.hasDueDate = hasDueDate;
                        this.item.dueDate = new Date(this.selectedDate);
                    }
                });
        }
    }

    isDatePickerDisabled(){
        if ($('#assignDueDate_' + this.item.enrollmentId).is(":checked")) {
            $('#datepicker').prop('disabled', false);
        }
        else {
            $('#datepicker').prop('disabled', true);
        }  
    }

    onClickItem() {

        for (var i = 0; i < this.items.length; i++) {
            if (this.items[i].learningPathId != this.item.learningPathId) {
                this.items.splice(i, 1);
                i--;
            }
        }


        let url: string = "learningcenter/courseviewer?enrollmentId=" + this.item.enrollmentId;


        this.router.navigateByUrl(url).then(i => {
            if (i == false) {
                console.log("unable to navigate");
            }
        });
    }

    onClickMenuItem(children: Enrollment) {

        let url: string = "learningcenter/courseviewer?enrollmentId=" + children.enrollmentId;


        this.router.navigateByUrl(url).then(i => {
            if (i == false) {
                console.log("unable to navigate");
            }
        });
    }


    pad(num: number, size: number): string {
        let s = num + "";
        while (s.length < size) s = "0" + s;
        return s;
    }

    getItemName() {
        return this.item.courseName;
    }

    onDropAll() {
        if (this.item != null) {
            this.lpEnrollments = new Array<String>();
            for (var i = 0; i < this.items.length; i++) {
                if (this.items[i].learningPathId == this.item.learningPathId) {
                    this.lpEnrollments.push(this.items[i].enrollmentId);
                }
            }
            let enrollIds = this.lpEnrollments.join('_').toString();

            this.pinnacleService.DropLPEnrollment(this.item.enrollmentId, this.item.learningPathId, enrollIds).subscribe(res => {
                for (var j = 0; j < this.lpEnrollments.length; j++) {
                    for (var i = 0; i < this.items.length; i++) {
                        if (this.items[i].enrollmentId == this.lpEnrollments[j] && this.enrollmentDropped != null) {
                            this.enrollmentDropped.emit({ enrollment: this.items[i] });
                        }
                    }
                }
            });
        }
    }
}
