import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'ep-file-upload-button',
    templateUrl: './file-upload-button.component.html',
    styleUrls: ['./file-upload-button.component.css']
})
export class FileUploadButtonComponent {

    @Input() selectMultiple: boolean = false;
    @Input() buttonTextColor: string = '#485674'
    @Input() buttonText: string = 'Upload File';
    @Output() filesSelected: EventEmitter<File[]> = new EventEmitter<File[]>();

    buttonStyles = {
        color: this.buttonTextColor
    }

    constructor() {

    }
}
