import { Injectable, SecurityContext } from '@angular/core';
import { Router } from '@angular/router';
import { Component, OnInit, Input, OnChanges, SimpleChanges, SimpleChange, Output, EventEmitter } from '@angular/core';
import { Content, ContentType_T } from './../../apiService/classFiles/class.content';
import { Workgroup, WorkgroupMember, PermissionLevel_T } from './../../apiService/classFiles/class.workgroups';
import { ProdGenApi } from './../../apiService/prodgen.api';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'public-workgroup-card',
    templateUrl: './public-workgroup-card.component.html',
    styleUrls: ['./public-workgroup-card.component.css'],
    providers: [ProdGenApi]
})

export class PublicWorkgroupCardComponent implements OnInit {

    @Input('workgroup') item: Workgroup;
    //@Input('classInput') classInput: string;


    constructor(private pinnacleService: ProdGenApi, private router: Router, private sanitizer: DomSanitizer) {
        if (this.item != null) {

        }
    }

    ngOnInit() {
    }
}