import { Component, OnInit, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { WidgetComponent } from '../../widget/widget.component';
import { ProdGenApi } from '../../../apiService/prodgen.api';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-second-test-widget',
  templateUrl: './second-test-widget.component.html',
    styleUrls: ['./second-test-widget.component.css'],
    providers: [{ provide: WidgetComponent, useExisting: SecondTestWidgetComponent }]
})
export class SecondTestWidgetComponent extends WidgetComponent implements OnInit, AfterViewInit {
    static key = "SecondTestWidgetComponent";

    widgetID = "02";

    constructor(private pinnacleService: ProdGenApi, private changedetectorref: ChangeDetectorRef) {
        // call the base Widget class
        super(pinnacleService, changedetectorref);
    }

    ngOnInit() {
        super.ngOnInit();

        this.widgetName = "Second Test Widget"
        this.widgetDesc = "Early example of creating a widget.";


        this.Load();
        this.widgetID = "02";


    }

    ngAfterViewInit() {
        this.widgetContainer.incrementRenderedWidget();
    }

    Load() {
        super.Load();

    }
}
