import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, Route, Params } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import cssVars from 'css-vars-ponyfill';
import { ProdGenApi } from '../../apiService/prodgen.api';
import { BrowserAuthenticationService } from '../../BrowserAuthenticationService';
import { TranslationService } from '../../services/TranslationService';

import { Workgroup, WorkgroupExternalInviteAcceptance} from './../../apiService/classFiles/class.workgroups'


declare var $: any; // JQuery workaround

@Component({
    selector: 'external-workgroup-accept-invite',
    templateUrl: 'workgroupacceptinvite.component.html',
    styleUrls: ['workgroupacceptinvite.component.css'],
    providers: [ProdGenApi]
})

export class WorkgroupAcceptInvite implements OnInit, AfterViewInit {


    linkInfo: string = "";
    inviteDetailsWG: Workgroup = new Workgroup();
    inviteAcceptance: WorkgroupExternalInviteAcceptance = new WorkgroupExternalInviteAcceptance();
    externalEmail: string = "";
    statusShow: string = "Loading";
    message: string = "LoadingPage";

    emailPattern: RegExp = new RegExp("^[a-zA-Z]{1}[a-zA-Z0-9.-_\-']*@[a-zA-Z0-9]{1}[a-zA-Z.0-9-]*[.][a-zA-Z]{2,}$"); 

    constructor(private pinnacleService: ProdGenApi,
        private route: ActivatedRoute,
        private router: Router,
        private translationService: TranslationService,
        private sanitizer: DomSanitizer,
        private authenticationService: BrowserAuthenticationService) {
    }

    ngOnInit(): void {
        cssVars({
            rootElement: document,
            variables: {
                "--page-background-color": '#FFFFFF'
            }
        });


        try {
            //SAMPLE: http://localhost:4200/#/workgroupinvite?id=c4c2d078-7693-435d-822b-848b51f0ee01
            this.route.queryParams.subscribe(params => {
                if (params["id"] == undefined) {
                    this.linkInfo = "";
                    this.statusShow = "Error";
                    this.message = "NoWorkgroup";
                }
                else if (params["id"] != undefined) {
                    this.linkInfo = params["id"];
                    //get the workgroup info
                    this.getWorkgroupInfo();


                }
            });
        }
        catch (e) {
        }
    }

    ngAfterViewInit(): void {


    }

    getWorkgroupInfo() {

        this.pinnacleService.GetWorkgroupExternalinvite(this.linkInfo).subscribe(res => {
            this.inviteDetailsWG = res;

            if (this.inviteDetailsWG.tenantid == "00000000-0000-0000-0000-000000000000") {
                this.statusShow = "Error";
                this.message = "NoWorkgroup";
            }

            else if (this.inviteDetailsWG.isArchived) {
                this.statusShow = "Error";
                this.message = "ArchivedWorkgroup";
            }

            else {
                this.statusShow = 'EnterEmail'
            }          

          }
        );
    }


    acceptInviteClick() {
        this.statusShow = "Loading";
        this.message = "AcceptingInvite";
        this.pinnacleService.AcceptWorkgroupExternalinvite(this.linkInfo, this.externalEmail).subscribe(res => {

            this.inviteAcceptance = res;

            if (!this.inviteAcceptance.inviteFound) {
                //email is not on the list
                this.statusShow = "Error"
                this.message = "noInvite"
            }
            else if (this.inviteAcceptance.inviteFound && this.inviteAcceptance.inviteAccepted && this.inviteAcceptance.sentUserSetupEmail) {
                this.statusShow = "InviteAccepted"
                this.message = "AcceptedCheckEmail"
            }
            else if (this.inviteAcceptance.inviteFound && this.inviteAcceptance.inviteAccepted && !this.inviteAcceptance.sentUserSetupEmail) {
                this.statusShow = "InviteAccepted"
                this.message = "AcceptedLogin"
            }
            else {
                this.statusShow = "Error"
                this.message = "???"
            }
        });
    }

    disableEmail() {

        if (this.externalEmail != "" && this.emailPattern.test(this.externalEmail)) {
            return false;
        }
        return true;
    }

    logInRedirect() {
        this.router.navigate(['/login']);
    }

}



