import { Component, OnInit, SimpleChanges} from '@angular/core';
import { ProdGenApi } from './../../apiService/prodgen.api';
import { Content} from './../../apiService/classFiles/class.content';
import { UserMultiSelect } from './../../apiService/classFiles/class.users';
import { BulkNotEnrolled } from './../../apiService/classFiles/class.enrollments';
import { AppControlService } from '../../AppControlService';
import { TranslationService } from '../../services/TranslationService';
import { IDatePickerConfig} from 'ng2-date-picker';
import { lang } from 'moment';

declare var $: any;

@Component({
    selector: 'template-enrolluserscontent',
    templateUrl: './enrolluserscontent.component.html',
    styleUrls: ['./enrolluserscontent.component.css'],
    inputs: ['users', 'content', 'userType', 'contentObj']
})

export class EnrollUsersContentComponent implements OnInit {
    usersData = [];
    contentData = [];
    settingsUsers = {};
    settingsContent = {};
    selUsers = [];
    selContent = [];
    uNames = [];

    users: [{ id: String, itemName: String }];
    content: [{ id: String, itemName: String }];
    contentObj: Array<Content>;
    cItems: Array<Content> = new Array<Content>();
    userMSel: Array<UserMultiSelect> = new Array<UserMultiSelect>();
    notEnrolledDetails = new Array<BulkNotEnrolled>();

    userType: string;
    enrollUserType: string = "";
    enrollUserDescText: string = "";
    enrOnly: boolean = true;
    notEnr: boolean = false;
        
    currentDate: Date = new Date(Date.now() + (1000*60*60*24*30*2));
    selectedDate: string;
    hasDueDate: boolean = false;

    public datePickerConfigEnr: IDatePickerConfig = {
        format: "MM-DD-YYYY"
    };

    constructor(private pinnacleService: ProdGenApi, private controlService: AppControlService, private translationService: TranslationService) {
    }

    ngOnChanges(changes: SimpleChanges) {
        try {
            this.usersData = this.users;
            this.contentData = this.content;
            this.cItems = this.contentObj;
        }
        catch (err) { }
    }

    ngOnInit() {
        this.resetControls();
    }

    resetControls() {
        this.selUsers = [];
        this.selContent = [];
        this.enrollUserType = this.userType;
        this.enrollUserDescText = this.translationService.getTranslationFileData("APP.ModalEnrollDesc");
        var ddText = this.translationService.getTranslationFileData("APP.ModalEnrollSelect");

        try {
            this.selectedDate = this.currentDate.getFullYear() + "-" + this.pad(this.currentDate.getMonth()+1, 2) + "-" + this.pad(this.currentDate.getDate(), 2);
        }
        catch (err) { };

        if (this.enrollUserType == "Members") {
            ddText = ddText + this.translationService.getTranslationFileData("APP.ModalEnrollTypeMembers");
        }
        else if (this.enrollUserType == "Users") {
            ddText = ddText + this.translationService.getTranslationFileData("APP.ModalEnrollTypeUsers");
        }

        this.settingsUsers = {
            singleSelection: false,
            text: ddText,
            enableSearchFilter: true,
            classes: "multi-select-dropdown"
        };

        this.settingsContent = {
            singleSelection: false,
            text: this.translationService.getTranslationFileData("APP.ModalEnrollSelectContent"),
            enableSearchFilter: true,
            classes: "multi-select-dropdown"
        };

        $('#assignDueDateEnr').attr('checked', false);
    }

    pad(num: number, size: number): string {
        let s = num + "";
        while (s.length < size) s = "0" + s;
        return s;
    }

    isDatePickerDisabledEnr(){
        if ($('#assignDueDateEnr').is(":checked")) {
            $('#datepicker').prop('disabled', false);
        }
        else {
            $('#datepicker').prop('disabled', true);
        }  
    }

    commitEnrollUsers(users, content, useDueDate, dueDate) {
        if (users == null || !users.length) return;
        if (content == null || !content.length) return;
        if (useDueDate && dueDate == null) return;

        this.cItems = [];
        content.forEach(c => {
            let index = this.contentObj.findIndex(i => i.contentId == c.id);
            if (index != -1) {
                this.cItems.push(this.contentObj[index]);
            }
        });

        this.userMSel = [];
        users.forEach(u => {
            this.userMSel.push({ id: u.UserId, itemName: u.DisplayName });
        });

        this.hasDueDate = useDueDate;
        this.selectedDate = dueDate;

        $('#enrollmentModal').modal('show');

        this.enrollUsers();
    }

    enrollUsers() {
        //this.cItems = new Array<Content>();
        //this.userMSel = this.selUsers;

        //for (var j = 0; j < this.selContent.length; j++) {
        //    for (var k = 0; k < this.contentObj.length; k++) {
        //        if (this.contentObj[k].contentId == this.selContent[j].id) {
        //            this.cItems.push(this.contentObj[k]);
        //            break;
        //        }
        //    }
        //}

        //let dueDateStr = "";
        //let hasDueDate = ($('#assignDueDateEnr').is(":checked"));
        let dueDateStr = this.hasDueDate ? (this.selectedDate + ' ' + this.currentDate.getHours() + ':' + this.currentDate.getMinutes() + ':' + this.currentDate.getSeconds()) : '';
        let toggle = false;
        let ctNotEnrolled = 0;
        this.notEnr = true;
        this.pinnacleService.getCurrentUser().subscribe(user => {
            if (this.cItems != null && this.cItems.length > 0 && this.userMSel != null && this.userMSel.length > 0) {
                this.showProgress();
                this.pinnacleService.SetBulkEnrollments(this.cItems, this.userMSel, user.userId, dueDateStr, this.hasDueDate).subscribe(res => {
                    if (res.length > 0) {
                        this.enrOnly = false;
                        this.notEnrolledDetails = res;
                        this.uNames = [];
                        let name = "";
                        ctNotEnrolled = this.notEnrolledDetails.length;
                        for (let i = 0; i < ctNotEnrolled; i++) {
                            if (i == 0) {
                                name = this.notEnrolledDetails[i].userName.toString();
                                this.uNames[i] = name;
                            }
                            else {
                                if (name != this.notEnrolledDetails[i].userName.toString()) {
                                    name = this.notEnrolledDetails[i].userName.toString();
                                    let addName = true;
                                    for (let k = 0; k < this.uNames.length; k++) {
                                        if (this.uNames[k] == name) {
                                            addName = false;
                                            break;
                                        }
                                    }
                                    if (addName == true) {
                                        this.uNames[i] = name;
                                    }
                                }
                            }

                            if (i == (ctNotEnrolled - 1)) {
                                $("#progressMessage").hide();
                            }
                        }                        
                    }
                    else {
                        this.enrOnly = false;
                        $("#progressMessage").hide();
                        toggle = true;
                    }
                });
            }
            else {
                $("#progressMessage").hide();
                toggle = true;
            }

            if (toggle) {
                this.resetControls();
                $('#enrollmentModal').modal('hide');
            }
        });
    }

    showProgress() {
        $("#progressMessage").show();
    }

    enrollUsersCancel() {
        this.resetControls();
    }

    onCancel() {
        this.notEnrolledDetails = [];
        this.resetControls(); 
        this.enrOnly = true;
        $('#enrollmentModal').modal('hide');     
    }
}
