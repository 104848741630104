import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ContentProduct, ContentProductVersion, DescriptionFilter_T } from '../../apiService/classFiles/class.content';
import { ProdGenApi } from '../../apiService/prodgen.api';
import { AppControlService, MsgBxStringVals } from '../../AppControlService';

declare var nicEditor: any;
declare var nicEditorInstance: any;

declare var $: any;



@Component({
  selector: 'template-newmodifycheatsheet',
  templateUrl: './newmodifycheatsheet.component.html',
  styleUrls: ['./newmodifycheatsheet.component.css']
})
export class NewmodifycheatsheetComponent implements OnInit,OnChanges {
  //assetLibrariesData: Array<AssetLibrary> = new Array<AssetLibrary>();
  //assetLibrariesProducts: Array<AssetLibraryProduct> = new Array<AssetLibraryProduct>();
  //assetLibrariesVersions: Array<AssetLibraryProductVersion> = new Array<AssetLibraryProductVersion>();
  @Input("Id") EditID: string;
  @Input("Name") Editname: string;
  @Input("Desc") Editdesc: string;
  @Input("Prods") Editprods: Array<ContentProduct>;
  @Input("isNew") isNew: boolean;
  @Output() closeModalEvent = new EventEmitter<boolean>();


  ContentProducts: Array<ContentProduct> = new Array<ContentProduct>();
  selectedProducts: Array<ContentProduct> = new Array<ContentProduct>();
  selectedVersion: Array<ContentProduct> = new Array<ContentProduct>();
  //ContentProductVersions: Array<ContentProductVersion> = new Array<ContentProductVersion>();

  productstrings: Array<string> = new Array<string>();

  bProdandVersLoaded: boolean = false;
  counter: number = 0;
  prodcounter: number = 0;
  onchangecounter: number = 0;

  CheatTitle: string = "";
  CheatDescription: string = "";

  bError: boolean = false;

  MynicEditor = new nicEditor();

  saveFirstClick: boolean = true; // added to prevent duplicate saves from fast clicking before modal closes

  constructor(private pinnacleService: ProdGenApi, private sanitize:DomSanitizer, private controlService: AppControlService) {

  }

  ngOnInit() {
    if (this.EditID != "") {
      this.pinnacleService.GetCheatSheetWithoutImageHeader(this.EditID, DescriptionFilter_T.formatted).subscribe(c => {
        this.Editdesc = c.description.toString();
        this.OpeningEvents();
      });
    }
    else {
      this.OpeningEvents();
    }
   
  }

  ngOnChanges() {
    
  }

  OpeningEvents() {
    this.MynicEditor.removeInstance('editor');
    //load the products
    this.LoadProducts();
    //initialize the editor

    this.MynicEditor.panelInstance('editor');
    //this.MynicEditor.addInstance('editor');

    //fill the editor
    var el = document.getElementsByClassName('nicEdit-main');
    var editorInstance = this.MynicEditor.instanceById('editor');
    if (this.Editdesc != "" && this.Editdesc != null) {
      el[0].innerHTML = this.Editdesc;



    }
    else {
      
     

      var content = editorInstance.getContent();

      if (this.Editdesc == "") {

        this.MynicEditor.instanceById('editor').setContent('<font face="Arial" id="placeholder" color="#b3b3b3" size="2">Type description here...</font>');
      }
    }

    this.MynicEditor.addEvent('focus', function () {
      if (/*Chrome*/editorInstance.getContent() == '<font face="Arial" id="placeholder" color="#b3b3b3" size="2">Type description here...</font>' || /*IE & Edge*/editorInstance.getContent() == '<font id="placeholder" color="#b3b3b3" face="Arial" size="2">Type description here...</font>' || /*FireFox*/editorInstance.getContent() == '<font id="placeholder" size="2" face="Arial" color="#b3b3b3">Type description here...</font>') {

        editorInstance.setContent('<font face="Arial" id="placeholder" color="#000000" size="2">Type description here...</font>');
        var el = document.getElementsByClassName("nicEdit-main");
        selectElementContents(el[0]);

        }

      });

      $(".nicEdit-main").keydown(function(e) {
          if (e.keyCode === 9) { // tab was pressed

              editorInstance.nicCommand('indent');
              //// get caret position/selection
              //var start = this.selectionStart;
              //var end = this.selectionEnd;

              //var $this = $(this);
              //var value = $this.val();

              //// set textarea value to: text before caret + tab + text after caret
              //$this.val(value.substring(0, start)
              //    + "\t"
              //    + value.substring(end));

              //// put caret at right position again (add one for the tab)
              //this.selectionStart = this.selectionEnd = start + 1;

              // prevent the focus lose
              e.preventDefault();
          }
      });

    function selectElementContents(el) {
      setTimeout(function () {
        if ((navigator.userAgent.indexOf("MSIE") != -1) || (!!document['documentMode'] == true)) {
          var range = window.getSelection().getRangeAt(0);
          range.selectNodeContents(el);
        }
        else {
          var range = document.createRange();
          range.selectNodeContents(el);
        }

        var sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
      }, 1);
    }

    this.MynicEditor.addEvent('blur', function () {
      var newText = editorInstance.getContent();
      if (editorInstance.getContent() == '<br>' || /*Chrome*/editorInstance.getContent() == '<font face="Arial" id="placeholder" color="#000000" size="2">Type description here...</font>' || /*IE & Edge*/editorInstance.getContent() == '<font id="placeholder" color="#000000" face="Arial" size="2">Type description here...</font>' || /*FireFox*/editorInstance.getContent() == '<font id="placeholder" size="2" face="Arial" color="#000000">Type description here...</font>') {
        editorInstance.setContent('<font face="Arial" id="placeholder" color="#b3b3b3" size="2">Type description here...</font>');
      }
    });

    //check the existing products
    this.CheckExistingProds();
  }



  LoadProducts() {
    if (this.bProdandVersLoaded == false) {
      this.pinnacleService.GetContentProducts().subscribe(r => {
        this.ContentProducts = r;
        for (var a = 0; a < this.ContentProducts.length; a++) {
          
          for (var b = 0; b < this.Editprods.length; b++) {
            if (this.ContentProducts[a].ID == this.Editprods[b].ID) {
              this.ContentProducts[a].ischecked = true;

              for (var y = 0; y < this.ContentProducts[a].Versions.length; y++) {
                for (var z = 0; z < this.Editprods[b].Versions.length; z++) {
                  if (this.ContentProducts[a].Versions[y].ID == this.Editprods[b].Versions[z].ID) {
                    this.ContentProducts[a].Versions[y].ischecked = true;
                  }
                }
              }
            }
          }
        }
        this.bProdandVersLoaded = true;
        
        
      });
      

    }
  }

  CheckExistingProds() {
    //Once the products have been loaded we need to check the appropriate boxes
    for (var a = 0; a < this.Editprods.length; a++) {
      let x = <any>document.getElementsByClassName('prodcheck ' + this.Editprods[a].ID);
      for (let item of x) {
        item.checked = true;
      }
      this.productstrings.push(this.Editprods[a].Name.toString());
      for (var b = 0; b < this.Editprods[a].Versions.length; b++) {
        let y = <any>document.getElementsByClassName('verscheck ' + this.Editprods[a].ID + ' ' + this.Editprods[a].Versions[b].ID);
        for (let item of y) {
          item.checked = true;
        }
        this.productstrings.push(this.Editprods[a].Name + ' ' + this.Editprods[a].Versions[b].Name);
      }
    }
  }

  SelectAllVersions(productid: string, productname: string) {

    let x = <any>document.getElementsByClassName('verscheck ' + productid);
    this.productstrings.push(productname);
    //get the names of the versions for this product
    let temparray = new Array<string>();
    for (var a = 0; a < this.ContentProducts.length; a++) {
      if (this.ContentProducts[a].ID == productid) {
        for (var b = 0; b < this.ContentProducts[a].Versions.length; b++) {
          temparray.push(this.ContentProducts[a].Versions[b].Name.toString());
        }
      }
    }

    let index = 0;
    for (let item of x) {
      if ($('.' + productid).prop('checked')) {
        
        item.checked = true;
        this.productstrings.push(productname + ' ' + temparray[index]);
        
      }
      else {
        item.checked = false;

        this.productstrings.splice(this.productstrings.indexOf(productname + ' ' + temparray[index]), 1)
        if (this.productstrings.indexOf(productname) != -1) {
          this.productstrings.splice(this.productstrings.indexOf(productname), 1)
        }
        
      }
      index = index + 1;
    }
  }

  SelectParentProduct(productid: string, productname: string, versionname: string, versionid: string) {
    let x = <any>document.getElementsByClassName('prodcheck ' + productid);
    

    if ($('.' + versionid).prop('checked')) {
      if (this.productstrings.indexOf(productname) == -1) {
        this.productstrings.push(productname);
      }
      this.productstrings.push(productname + ' ' + versionname);
    }
    else {
      this.productstrings.splice(this.productstrings.indexOf(productname + ' ' + versionname), 1);
    }
    
    for (let item of x) {
     
        item.checked = true;
     
    }
  }

  Save() {
    // If first time clicking save, save as usual
    // Else do nothing
    if (this.saveFirstClick) {
      let selectedProds = new Array<any>();
      let selectedVers = new Array<any>();

      //gets the elements that have been checked and stores them in an array.
      let elselectedProds = <any>document.getElementsByClassName('prodcheck');
      for (var i = 0; i < elselectedProds.length; i++) {
        if (elselectedProds[i].checked == true) {
          selectedProds.push(elselectedProds[i]);
        }
      }

      let elselectedVers = <any>document.getElementsByClassName('verscheck');
      for (var i = 0; i < elselectedVers.length; i++) {
        if (elselectedVers[i].checked == true) {
          selectedVers.push(elselectedVers[i]);
        }
      }



      //This loops through the selected products and versions in order to build a new ContentProduct object to add to the array of selected ContentProducts.
      for (var a = 0; a < selectedProds.length; a++) {
        for (var b = 0; b < this.ContentProducts.length; b++) {
          if (selectedProds[a].classList.item(1) == this.ContentProducts[b].ID) {
            let addprod = new ContentProduct();
            //addprod.ExtensionData = this.ContentProducts[b].ExtensionData;
            addprod.ID = this.ContentProducts[b].ID;
            addprod.ImageURL = this.ContentProducts[b].ImageURL;
            addprod.Name = this.ContentProducts[b].Name;
            addprod.TenantID = this.ContentProducts[b].TenantID;
            addprod.Versions = new Array<ContentProductVersion>();
            for (var c = 0; c < selectedVers.length; c++) {
              for (var d = 0; d < this.ContentProducts[b].Versions.length; d++) {

                if (selectedVers[c].classList.item(2) == this.ContentProducts[b].Versions[d].ID) {
                  
                  let addvers = new ContentProductVersion();
                  //addvers.extensionData = this.ContentProducts[b].Versions[d].extensionData;
                  addvers.ID = this.ContentProducts[b].Versions[d].ID;
                  addvers.Name = this.ContentProducts[b].Versions[d].Name;
                  addvers.ProductId = this.ContentProducts[b].Versions[d].ProductId;
                  addvers.TenantId = this.ContentProducts[b].Versions[d].TenantId;
                  addprod.Versions.push(addvers);
                }
              }
            }
            
            this.selectedProducts.push(addprod);
          }
        }
      }
      
      //now we should have the list of products selected. call the service with the relevant info
      if (this.Editname != "" && this.Editname != null) {
        this.bError = false;

        var el = document.getElementsByClassName('nicEdit-main');
        let newdesc = el[0].innerHTML;

        if (this.EditID == "" || this.EditID == null) {
          this.pinnacleService.AddMyCheatSheet("new", this.Editname, newdesc, this.selectedProducts).subscribe(r => {
            this.onCloseModal("Save");
            $('#newcheat').modal('hide');
          },
          err => {
              console.log("Error saving Cheat Sheet");
              var v_Msg = new MsgBxStringVals();
              v_Msg.body = "There was an error saving your cheat sheet. Please try again.";
              v_Msg.title = ``;
              this.controlService.openMessageBoxPopUp(v_Msg);
              
          });
        }
        else {
          this.pinnacleService.AddMyCheatSheet(this.EditID, this.Editname, newdesc, this.selectedProducts).subscribe(r => {
            this.onCloseModal("Save");
            $('#newcheat').modal('hide');
          },
          err => {
              console.log("Error saving Cheat Sheet");

              var v_Msg = new MsgBxStringVals();
              v_Msg.body = "There was an error saving your cheat sheet. Please try again.";
              v_Msg.title = ``;
              this.controlService.openMessageBoxPopUp(v_Msg);
          });
        }
      
      }
      else {
        this.bError = true;
      }

      this.saveFirstClick = false;
    }    
  }

  AllowClose() {
    event.stopPropagation();​
  }

  onCloseModal(event: any) {
    //Clear Vars to prevent accidentally doubling up the products.
    for (var i = 0; i < this.ContentProducts.length; i++) {
      this.ContentProducts[i].ischecked = null;
      for (var j = 0; j < this.ContentProducts[i].Versions.length; j++) {
        this.ContentProducts[i].Versions[j].ischecked = null;
      }
    }

    var el = document.getElementsByClassName('nicEdit-main');
    el[0].innerHTML = "";

    this.EditID = "";
    this.Editname = "";
    this.Editdesc = "";
    this.isNew = true;
    this.Editprods = new Array<ContentProduct>();
    this.bProdandVersLoaded = false;
    this.selectedProducts = new Array<ContentProduct>();
      $('body').removeClass('modal-open');
      $('body').removeClass('registrationFormScrollLock');
    this.closeModalEvent.emit(event);
  }






}
