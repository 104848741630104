import { ContentType_T, DescriptionFilter_T } from "./class.content"
import { Role } from "./class.users"

export enum PermissionLevel_T {
    owner =0,
    editor =1,
    user =2
}

export enum IndexType_T {
    defaultIndex =0,
    frequentlyUsedIndex =1
}

export class Workgroup {
    public workgroupId: String;
    public name: String;
    public imageURL: String;
    public description: String;
    public createdById: String;
    public createdDatetime: Date;
    public archivedById: String;
    public archivedDatetime: Date;
    public archivedNotes: String;
    public isArchived: boolean;
    public tenantid: string;
    public tenantName: string;
    public assetCount: number;
    public memberCount: number;
    public externalMemberCount: number;
    public ownerDisplay: string;
    public isPublic: boolean;
    public ownerTenantName: string;
    public isExternal: boolean;
    public membersTabEditorsAllowed: boolean;
    public membersTabMembersAllowed: boolean;
    public membersTabExternMembersAllowed: boolean;
    public discussionEditorsAllowed: boolean;
    public discussionMembersAllowed: boolean;
    public discussionExternMembersAllowed: boolean;
    public editorsDashboardAllowed: boolean;
    public membersDashboardAllowed: boolean;
    public externalMembersDashboardAllowed: boolean;
    public notifyContentAdded: boolean;
    public notifyContentRemoved: boolean;
    public notifyContentModified: boolean;
    public notifyUserAdded: boolean;
    public notifyUserRemoved: boolean;
    public notifyUserExpiring: boolean;
    public notifyCommentPosted: boolean;
    public notifyArchivedRestored: boolean;
}

export class WorkgroupPost {
    public name: string;
    public unformattedDescription: string;
    public formattedDescription: string;
    public imageURL: string;
    public isPublic: boolean;
    public membersTabEditorsAllowed: boolean;
    public membersTabMembersAllowed: boolean;
    public membersTabExternMembersAllowed: boolean;
    public discussionEditorsAllowed: boolean;
    public discussionMembersAllowed: boolean;
    public discussionExternMembersAllowed: boolean;
    public editorsDashboardAllowed: boolean;
    public membersDashboardAllowed: boolean;
    public externalMembersDashboardAllowed: boolean;
    public notifyContentAdded: boolean;
    public notifyContentRemoved: boolean;
    public notifyContentModified: boolean;
    public notifyUserAdded: boolean;
    public notifyUserRemoved: boolean;
    public notifyUserExpiring: boolean;
    public notifyCommentPosted: boolean;
    public notifyArchivedRestored: boolean;
}
export class WorkgroupPut {
	public name: string;
	public description: string;
    public imageURL: string;
    public isPublic: boolean;
    public membersTabEditorsAllowed: boolean;
    public membersTabMembersAllowed: boolean;
    public membersTabExternMembersAllowed: boolean;
    public discussionEditorsAllowed: boolean;
    public discussionMembersAllowed: boolean;
    public discussionExternMembersAllowed: boolean;
    public editorsDashboardAllowed: boolean;
    public membersDashboardAllowed: boolean;
    public externalMembersDashboardAllowed: boolean;
    public notifyContentAdded: boolean;
    public notifyContentRemoved: boolean;
    public notifyContentModified: boolean;
    public notifyUserAdded: boolean;
    public notifyUserRemoved: boolean;
    public notifyUserExpiring: boolean;
    public notifyCommentPosted: boolean;
    public notifyArchivedRestored: boolean;
}
export class WorkgroupMember {
    public workgroupId: String;
    public userId: String;
    public name: String;
    public email: String;
    public permissionLevel: String;
    public imageURL: String;
    public isExplicitMember: boolean;
    public inheritedRoles: Role[];
    public isSelfInvited: boolean;
}
export class WorkgroupRole {
    public workgroupId: String;
    public roleId: String;
    public name: String;
    public permissionLevel: String;
} 

export class WorkgroupFilter {
    public limit: number;
    public offset: number;
    public search: String;

    public archiveIncluded: boolean ;
    public descriptionFilter: DescriptionFilter_T;
    public permissionFilter: Array<PermissionLevel_T>; 
}

export class WorkgroupContentPost {
    public contentId: String;
    public contentType: ContentType_T;
    public isValid: boolean ;
}

export class WorkgroupContentIndex {
    public workgroupID: String;
    public wgcontentID: String;
    public contentID: String;
    public dateTimeAdded: Date;
    public index: number;
}

export class WorkgroupMemberPost {
    public id: String;
    public permissionLevel: PermissionLevel_T;
}

export class WorkgroupMemberPut {
    public permissionLevel: PermissionLevel_T;    
}

export class WorkgroupRolePost {
    public id: String;
    public permissionLevel: PermissionLevel_T;
}

export class WorkgroupRolePut {
    public permissionLevel: PermissionLevel_T;
}

export class WorkgroupExternalGroup {
    public id: string;
    public name: string;
    public members: WorkgroupExternalMembers[];
    public multimember: boolean;
}

export class WorkgroupExternalMembers {
    public id: string;
    public name: string;
    public email: string;
    public imageURL: string;
    public isManager: boolean;
    public status: boolean;
    public organizationName: string;
    public phone: string;
    public organizationRole: string;

    public maxInvites: number;
    public inviteFromDisplayName: string;
    public inviteFromEmail: string;
    public inviteDate: string;
    public membershipExpires: boolean;
    public expirationDate: string;
}

export class WorkgroupExternalMemberPost {
    public email: String;
    public isManager: boolean;
}

export class WorkgroupExternalGroupPost {
    public name: String;
    public multimember: boolean;
}

export class WorkgroupExternalGroupAndMemberPost {
    public email: string;
    public isManager: boolean;
    public displayName: string;
    public imageURL: string;
    public groupName: string;
    public invitesAllowed: number;
    public membershipExpires: boolean;
    public expirationDate: string;
}

export class WorkgroupExternalSummary {
    public tenantid: string;
    public externalMembersMax: number;
    public externalMembersUsed: number;

    public externalMemberInvitesAvailable: number;

    public summaryRecords: Array<WorkgroupExternalSummaryRecord>;
}

export class WorkgroupExternalSummaryRecord {
    public workgroupID: string;
    public name: string;
    public externalMemberCount: number;
    public owners: string;
}

export class WorkgroupExternalMemberPut {
    public isManager: boolean;
    public invitesAllowed: number;
    public membershipExpires: boolean;
    public expirationDate: string;
}


export class WorkgroupListByPermission {
    public editorPermissionWorkgroups: Array<String>;
    public ownerPermissionWorkgroups: Array<String>;
}

export class WorkgroupExternalInviteAcceptance {
    public inviteFound: boolean;
    public newExternalUser: boolean;
    public inviteAccepted: boolean;
    public sentUserSetupEmail: boolean;
    
}

export enum WorkgroupSharContentStatusEnum {
    AlreadyExist,
    Success,
    Fail
}

export class WorkgroupShareContent {
    public Status: WorkgroupSharContentStatusEnum;
}