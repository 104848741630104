import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AssetLibrary } from '../apiService/classFiles/class.assetlibrary';
import { CompanyNewsItem } from '../apiService/classFiles/class.organizations';
import { User } from '../apiService/classFiles/class.users';
import { PermissionLevel_T, Workgroup, WorkgroupMember } from '../apiService/classFiles/class.workgroups';
import { defpreviewtheme } from '../app.component';
import { WidgetContainerComponent } from '../templates/widget-container/widget-container.component';
import { Category } from './../apiService/classFiles/class.categories';
import { PartnerNewsItem } from './../apiService/classFiles/class.partners';
import { ProdGenApi } from './../apiService/prodgen.api';
import { BrowserAuthenticationService } from './../BrowserAuthenticationService';
import { SearchComponent } from './../search/search.component';
import { TranslationService } from './../services/TranslationService';

declare var $: any;

//declare function doesFontExist(fontName: string): boolean;


//We no able user workcenter2, we use Workcenter3
@Component({
    selector: 'app-workcenter2',
    templateUrl: './workcenter2.component.html',
    styleUrls: ['./workcenter2.component.css'],
    //providers: [LearningcenterheaderComponent, ProdGenApi],
    providers: [ProdGenApi],
    encapsulation: ViewEncapsulation.None,
})

export class Workcenter2Component implements OnInit {
    @ViewChild(WidgetContainerComponent, { static: false }) WGContainer: WidgetContainerComponent;
    workGroups: Array<Workgroup> = new Array<Workgroup>();
    curWorkgroupId: string = "";

    selectedWorkgroup: Workgroup = new Workgroup();
    workgroupMembers: Array<WorkgroupMember> = new Array<WorkgroupMember>();

    assetLibrariesData: Array<AssetLibrary> = new Array<AssetLibrary>();
    assetLibraryLoaded: boolean = false;

    assetLibrariesSubscription: Array<AssetLibrary> = new Array<AssetLibrary>();
    assetLibrarySubscriptionLoaded: boolean = false;

    //categoriesTenant: Array<Category> = new Array<Category>();
    currentUser: User = new User();

    companyInfo: Array<CompanyNewsItem> = new Array<CompanyNewsItem>();
    recommendedAddons: Array<CompanyNewsItem> = new Array<CompanyNewsItem>();
    companyInfoImage: string = "";
    companyInfoLoaded: boolean = false;

    partnerInfo: Array<PartnerNewsItem> = new Array<PartnerNewsItem>();
    recommendedPartnerAddons: Array<PartnerNewsItem> = new Array<PartnerNewsItem>();
    partnerInfoImage: string = "";
    partnerInfoLoaded: boolean = false;

    workgroupsLoaded: boolean = false;
    isAuthenticated: boolean = false;

    currentUserPermissionsLevel: String = "user";


    canViewDownloadAddOns: boolean = true;

    imageId: string = "";
    showImageLoader: boolean = false;
    newModifyWorkgroupTitle: string = "";
    newWorkgroupName: string = "";
    newWorkgroupURL: string = "";
    newWorkgroupDesc: string = "";
    isModify: boolean = false;
    thumbnailURL: string = "../../assets/images/default_project.jpg";

    newImageFile: File;

    previewtheme = defpreviewtheme;

    constructor(private pinnacleService: ProdGenApi, private route: ActivatedRoute, private router: Router, private _sanitizer: DomSanitizer, private authenticationService: BrowserAuthenticationService, private translationService: TranslationService) {

    }

    ngOnInit() {
        window.scroll(0, 0);
        if (this.authenticationService.AuthenticatePage() == true) {

            this.isAuthenticated = true;

            this.pinnacleService.GetCurrentTenant().subscribe(
                res => {
                    ProdGenApi.setCurrentTenantId(res.tenantId as string);

                });

            this.pinnacleService.getCurrentUser().subscribe(u => {
                this.currentUser = u;

                try {
                    this.workGroups = JSON.parse(localStorage.getItem(this.currentUser.userId + "_workgroups"));
                }
                catch (err) {
                    console.log(err)
                }



                this.pinnacleService.getCurrentUserWorkgroups([PermissionLevel_T.owner, PermissionLevel_T.editor, PermissionLevel_T.user], -1).subscribe(res => {
                    for (var i = 0; i < res.length; i++) {
                        if (res[i].imageURL == null || res[i].imageURL == "") {
                            //get default workgroup image from storage
                            let defaultwgimage = "";

                            try {
                                defaultwgimage = localStorage.getItem(ProdGenApi.getCurrentTenantId() + "_defWorkgroupImage");
                            }
                            catch (err) {
                            }

                            if (defaultwgimage != null && defaultwgimage != "") {
                                res[i].imageURL = defaultwgimage;
                            }
                            else {
                                res[i].imageURL = "../assets/images/default_project.jpg";
                            }
                        }
                    }

                    this.workgroupsLoaded = true;
                    this.workGroups = res;

                    let selectId = "";
                    this.route.queryParams.subscribe(queryParams => {
                        if (queryParams['id'] != null) {
                            selectId = queryParams['id'];
                        }
                    });


                    if (/*this.selectedWorkgroup.workgroupId == null || this.selectedWorkgroup.workgroupId.length == 0*/true) {
                        if (this.workGroups.length > 0) {
                            this.selectedWorkgroup = this.workGroups.find(f => f.workgroupId == selectId);

                            if (this.selectedWorkgroup == null) {
                                this.selectedWorkgroup = this.workGroups[0];
                            }
                        }

                        if (this.selectedWorkgroup.workgroupId != null && this.selectedWorkgroup.workgroupId.length > 0) {
                            this.loadMembers();
                        }
                    }

                    for (let w of this.workGroups) {
                    }

                    /// write to Local Storage for this user
                    try {
                        localStorage.setItem(this.currentUser.userId + "_workgroups", JSON.stringify(this.workGroups))
                    }
                    catch (err) {
                        console.log(err);
                    }

                }
                );
            });

        }

    }


    onResize(event) {
    }




    hideLoadingGif(someId: string) {
        document.getElementById(someId).style.display = "none";
    }

    categoryHREF(category: Category): string {
        let url: string = "";
        url += "search?category=" + SearchComponent.encodeSearchParameters(category.name as string);
        return url;

    }


    getSafeHtml(htmlText: string): SafeHtml {
        if (htmlText == null) {
            htmlText = "";
        }
        return this._sanitizer.bypassSecurityTrustHtml(htmlText);
    }

    onWorkgroupChanged(event) {
        this.router.navigateByUrl("workcenter?id=" + event.selectedItem);

        this.selectedWorkgroup = this.workGroups.find(w => w.workgroupId == event.selectedItem);
        this.loadMembers();
    }


    loadMembers() {
        this.currentUserPermissionsLevel = "user";

        let workgroup = this.selectedWorkgroup;
        if (workgroup != null && workgroup.workgroupId != null) {
            this.workgroupMembers = new Array<WorkgroupMember>();
            this.curWorkgroupId = workgroup.workgroupId as string;
            this.pinnacleService.GetWorkgroupMembers(workgroup.workgroupId, -1, 0).subscribe(res => {
                let tempMembers = res;
                this.pinnacleService.GetWorkgroupExternalGroups(this.curWorkgroupId).subscribe(groups => {

                    for (let g of groups) {
                        for (let em of g.members) {
                            let m = new WorkgroupMember();
                            m.email = em.email;
                            m.userId = em.id;
                            m.imageURL = em.imageURL;
                            m.permissionLevel = "external";
                            m.workgroupId = g.id;
                            m.name = em.name;

                            tempMembers.push(m);
                        }
                    }

                    for (var i = 0; i < tempMembers.length; i++) {
                        if (tempMembers[i].userId == this.currentUser.userId) {
                            this.currentUserPermissionsLevel = tempMembers[i].permissionLevel;
                        }

                        if (tempMembers[i].imageURL == "") {
                            tempMembers[i].imageURL = "../../assets/images/default_user.jpg";
                        }
                    }
                    this.workgroupMembers = tempMembers;
                });
            });
        }
    }

    onImageChanged(event: any) {
        if (this.imageId == "") {
            this.newImageFile = event.fileData;
            this.thumbnailURL = event.imageURL;
        }
        else {
            this.selectedWorkgroup.imageURL = event.imageURL;
            if (this.selectedWorkgroup.imageURL == "") {
                this.selectedWorkgroup.imageURL = "../../assets/images/default_project.jpg";
            }
            this.thumbnailURL = this.selectedWorkgroup.imageURL.toString();
        }
    }

    getThumbnailImage() {
        return this._sanitizer.bypassSecurityTrustUrl(this.thumbnailURL);
    }


    onNewWorkgroupClick() {
        this.imageId = "clear";

        this.newModifyWorkgroupTitle = this.translationService.getTranslationFileData("WORKCENTER.TS_CreateWorkgroup");
        this.newWorkgroupName = "";
        this.newWorkgroupDesc = "";
        this.imageId = "";
        this.isModify = false;

        this.thumbnailURL = localStorage.getItem(ProdGenApi.getCurrentTenantId() + "_defWorkgroupImage");

        $('#newWorkgroupModal').modal('show');
    }

    onModifyWorkgroupClick() {

        this.imageId = "clear";

        this.newModifyWorkgroupTitle = this.translationService.getTranslationFileData("WORKCENTER.TS_ModifyWorkgroup");
        this.newWorkgroupName = this.selectedWorkgroup.name.toString();
        this.newWorkgroupDesc = this.selectedWorkgroup.description.toString();
        this.imageId = this.selectedWorkgroup.workgroupId.toString();
        this.isModify = true;

        this.thumbnailURL = this.selectedWorkgroup.imageURL.toString();

        $('#newWorkgroupModal').modal('show');
    }

    onWorkgroupImageClick() {
        $('#changeWorkgroupImage').modal('show');
    }

    onArchiveWorkgroupClick() {
        $('#archiveWorkgroupModal').modal('show');

    }

    onArchiveWorkgroup() {
        this.pinnacleService.DeleteWorkgroup(this.selectedWorkgroup.workgroupId, "").subscribe(i => {
            this.router.navigateByUrl("workcenter");
            this.ngOnInit();
            this.selectedWorkgroup.name = "";
        });
    }

    onMembersUpdated() {
        this.loadMembers();
    }

    onCreateWorkgroup() {
        if (this.isModify == true) {
            let v_ImageURL  = this.selectedWorkgroup.imageURL;
            if (v_ImageURL.startsWith("../")) {
                v_ImageURL = "";
            }
            this.pinnacleService.UpdateWorkgroup(this.selectedWorkgroup.workgroupId.toString(), this.newWorkgroupName, this.newWorkgroupDesc, v_ImageURL.toString(), false, false,false, false, true, true, true, true,true, true, true, true, true, true, true, true, true, true).subscribe(i => {
                this.ngOnInit();
            });
        }
        else {
            let workgroup = new Workgroup();
            workgroup.name = this.newWorkgroupName;
            workgroup.description = this.newWorkgroupDesc;
            workgroup.imageURL = "";
            workgroup.isPublic = false;
            workgroup.editorsDashboardAllowed = false;
            workgroup.membersDashboardAllowed = false;
            workgroup.externalMembersDashboardAllowed = false;
            workgroup.notifyContentAdded = true;
            workgroup.notifyContentRemoved = true;
            workgroup.notifyContentModified = true;
            workgroup.notifyUserAdded = true;
            workgroup.notifyUserRemoved = true;

            this.pinnacleService.SetWorkgroup(workgroup).subscribe(i => {
                if (this.newImageFile != null) {
                    this.pinnacleService.updateWorkgroupImage(i.workgroupId.toString(), this.newImageFile).subscribe(res => {
                        i.imageURL = res;
                        this.pinnacleService.UpdateWorkgroup(i.workgroupId.toString(), i.name.toString(), i.description.toString(), i.imageURL.toString(), false, false,false, false, true, true, true, true, true,true, true, true, true, true, true, true, true, true).subscribe(r => {
                            this.router.navigateByUrl("workcenter?id=" + i.workgroupId);
                            this.ngOnInit();
                        },
                            err => {
                                console.log(err);
                                this.router.navigateByUrl("workcenter?id=" + i.workgroupId);
                                this.ngOnInit();
                            });
                    });
                }
                else {
                    this.router.navigateByUrl("workcenter?id=" + i.workgroupId);
                    this.ngOnInit();
                }
            });
        }
    }

    dashboardClick() {
        
        setTimeout(() => {
            //reload the container
            if (this.WGContainer != null) {
                this.WGContainer.clearWidgets();
                this.WGContainer.ngOnInit();
            }
        }, 0);
    }
}

