import { Component, OnInit } from '@angular/core';
import { ProdGenApi } from '../apiService/prodgen.api';
import { ActivatedRoute, Router } from '@angular/router';
import { PWResetRequest } from '../apiService/classFiles/class.authorization';
import { TranslationService } from './../services/TranslationService';

declare var $: any;

@Component({
  selector: 'app-passwordreset',
  templateUrl: './passwordreset.component.html',
  styleUrls: ['./passwordreset.component.css']
})
export class PasswordresetComponent implements OnInit {
  requestid: string = "";
  authcode: string = "";
  ResetRequest: PWResetRequest = new PWResetRequest();
  newPWconfirm: string = "";
  newPW: string = "";

  ssoSetting: boolean = false;
  passwordPolicyEnforcedSetting: boolean = false;
  complexPasswordSetting: boolean = false;
  passwordExpiresSetting: boolean = false;
  passwordExpiresCount: number = 0;
  passwordDifferentSetting: boolean = false;
    passwordDiferentCount: number = 0;

    textResetPassword: string = "";
    textCreatePassword: string = "";
    textEnterverification1: string = "";
    textClickHere: string = "";
    textEnterverification2: string = "";
    textNext: string = "";
    textExpiredRequest: string = "";
    textGoToLogin: string = "";
    textPinnacleSeriesMessage: string = "";
    textPWSecMsg: string = "";
    textOK: string = "";
    textPWChangeSuccess: string = "";
    textPWChangeSuccessMsg: string = "";
    textCreateNewPW: string = "";
    textApply: string = "";
    textCancel: string = "";

    textChangePasswordTitle: string = "";
    textPasswordGuidelines: string = "";
    textMinCharCount: string = "";
    textOneUpper: string = "";
    textOneLower: string = "";
    textOneSymbol: string = "";
    textPreDifferentCount: string = "";
    textPostDifferentCount: string = "";
    textOldPasswordText: string = "";
    textNewPasswordText: string = "";
    textConfirmPasswordText: string = "";
    textSubmit: string = "";
    textTS_FillPassword: string = "";
    textTS_ComparePassword: string = "";
    textTS_UpdateFail: string = "";
    textTS_EnforcementFail: string = "";
    textPasswordSuccess: string = "";
    textPasswordSuccessMsg: string = "";


    constructor(private pinnacleservice: ProdGenApi, private route: ActivatedRoute, private router: Router, private tranService: TranslationService) {
    this.route.queryParams.subscribe(queryParams => {
      this.requestid = queryParams['id'];
    });

    this.pinnacleservice.GetPasswordResetRequest(this.requestid).subscribe(res => {
      this.ResetRequest = res;
    });

   
  }

  ngOnInit() {
      this.pinnacleservice.getTranslatedFileFromLanguageCode("en").subscribe(dataFile => {

          this.tranService.loadTranslationFileDataFromVariable(dataFile);
          this.loadTranslatedText();
      },
          fileError => {
              this.tranService.loadTranslationFileLocal().subscribe(dataFile => {

                  this.tranService.loadTranslationFileDataFromVariable(dataFile);
                  this.loadTranslatedText();

              },
                  err => {
                      console.log(err);
                  });
          });
  }

    loadTranslatedText() {
        this.textResetPassword = this.tranService.getTranslationFileData("RESETPASSWORD.ResetPassword");
        this.textCreatePassword = this.tranService.getTranslationFileData("RESETPASSWORD.CreatePassword");
        this.textEnterverification1 = this.tranService.getTranslationFileData("RESETPASSWORD.Enterverification1");
        this.textClickHere = this.tranService.getTranslationFileData("RESETPASSWORD.ClickHere");
        this.textEnterverification2 = this.tranService.getTranslationFileData("RESETPASSWORD.Enterverification2");
        this.textNext = this.tranService.getTranslationFileData("RESETPASSWORD.Next");
        this.textExpiredRequest = this.tranService.getTranslationFileData("RESETPASSWORD.ExpiredRequest");
        this.textGoToLogin = this.tranService.getTranslationFileData("RESETPASSWORD.GoToLogin");
        this.textPinnacleSeriesMessage = this.tranService.getTranslationFileData("RESETPASSWORD.PinnacleSeriesMessage");
        this.textPWSecMsg = this.tranService.getTranslationFileData("RESETPASSWORD.PWSecMsg");
        this.textOK = this.tranService.getTranslationFileData("RESETPASSWORD.OK");
        this.textPWChangeSuccess = this.tranService.getTranslationFileData("RESETPASSWORD.PWChangeSuccess");
        this.textPWChangeSuccessMsg = this.tranService.getTranslationFileData("RESETPASSWORD.PWChangeSuccessMsg");
        this.textCreateNewPW = this.tranService.getTranslationFileData("RESETPASSWORD.CreateNewPW");
        this.textApply = this.tranService.getTranslationFileData("RESETPASSWORD.Apply");
        this.textCancel = this.tranService.getTranslationFileData("RESETPASSWORD.Cancel");

        this.textChangePasswordTitle = this.tranService.getTranslationFileData("CHANGEPASSWORD.ChangePasswordTitle");
        this.textPasswordGuidelines = this.tranService.getTranslationFileData("CHANGEPASSWORD.PasswordGuidelines");
        this.textMinCharCount = this.tranService.getTranslationFileData("CHANGEPASSWORD.MinCharCount");
        this.textOneUpper = this.tranService.getTranslationFileData("CHANGEPASSWORD.OneUpper");
        this.textOneLower = this.tranService.getTranslationFileData("CHANGEPASSWORD.OneLower");
        this.textOneSymbol = this.tranService.getTranslationFileData("CHANGEPASSWORD.OneSymbol");
        this.textPreDifferentCount = this.tranService.getTranslationFileData("CHANGEPASSWORD.PreDifferentCount");
        this.textPostDifferentCount = this.tranService.getTranslationFileData("CHANGEPASSWORD.PostDifferentCount");
        this.textOldPasswordText = this.tranService.getTranslationFileData("CHANGEPASSWORD.OldPasswordText");
        this.textNewPasswordText = this.tranService.getTranslationFileData("CHANGEPASSWORD.NewPasswordText");
        this.textConfirmPasswordText = this.tranService.getTranslationFileData("CHANGEPASSWORD.ConfirmPasswordText");
        this.textSubmit = this.tranService.getTranslationFileData("CHANGEPASSWORD.Submit");
        this.textCancel = this.tranService.getTranslationFileData("CHANGEPASSWORD.Cancel");
        this.textTS_FillPassword = this.tranService.getTranslationFileData("CHANGEPASSWORD.TS_FillPassword");
        this.textTS_ComparePassword = this.tranService.getTranslationFileData("CHANGEPASSWORD.TS_ComparePassword");
        this.textTS_UpdateFail = this.tranService.getTranslationFileData("CHANGEPASSWORD.TS_UpdateFail");
        this.textTS_EnforcementFail = this.tranService.getTranslationFileData("CHANGEPASSWORD.TS_EnforcementFail");
        this.textPasswordSuccess = this.tranService.getTranslationFileData("CHANGEPASSWORD.PasswordSuccess");
        this.textPasswordSuccessMsg = this.tranService.getTranslationFileData("CHANGEPASSWORD.PasswordSuccessMsg");
    }

  ShowReset() {
    //check if authentication code matches code in db
    if (this.authcode != null && this.authcode.length > 0) {
      this.pinnacleservice.GetPasswordResetRequest(this.requestid).subscribe(res => {
        this.ResetRequest = res;
        if (this.ResetRequest.reqCode == this.authcode) {
          $('#NewPassEnter').modal('show');

          this.pinnacleservice.GetCurrentTenantSettingsWithKey("PWReset_" + this.ResetRequest.tenantID).subscribe(res => {
            res.forEach((setting) => {
              if (setting.name == "PWD_ENFORCE_POLICY") {
                this.passwordPolicyEnforcedSetting = Boolean(setting.settingValue);
              }
              else if (setting.name == "PWD_USE_COMPLEX_PASSWORDS") {
                this.complexPasswordSetting = Boolean(setting.settingValue);
              }
              else if (setting.name == "PWD_PASSWORD_EXPIRES") {
                this.passwordExpiresSetting = Boolean(setting.settingValue);
              }
              else if (setting.name == "PWD_EXPIRATION_DURATION") {
                this.passwordExpiresCount = setting.settingValue as number;
              }
              else if (setting.name == "PWD_MUST_BE_UNIQUE") {
                this.passwordDifferentSetting = Boolean(setting.settingValue);
              }
              else if (setting.name == "PWD_UNIQUE_HISTORY_COUNT") {
                this.passwordDiferentCount = setting.settingValue as number;
              }
              else if (setting.name == "PWD_DISABLE_WINDOWS_AUTH") {
                this.ssoSetting = Boolean(setting.settingValue);
              }
            });
          });
        }
        else {
          $('#AuthFail').modal('show');
        }
      });
    }
    
    
  }

  SendEmail() {
    //send email with new authentication code
    this.pinnacleservice.ResendPWResetEmail(this.requestid).subscribe();
    $('#EmailSent').modal('show');
  }

  SetNewPassword() {
    if (this.newPW == this.newPWconfirm) {
      //check if the new PW meets PW requirements for the tenant. if yes,


      if (this.passwordPolicyEnforcedSetting == true) {
        this.pinnacleservice.isNewPasswordValidWithGivenKey(this.newPW, "PWReset_" + this.ResetRequest.tenantID).subscribe(res => {
          if (res == true) {
            //reset the password
            this.pinnacleservice.PasswordReset(this.ResetRequest.requestID, this.ResetRequest.userID, this.newPW).subscribe(res => {
              //password has been reset

              $('#NewPassEnter').modal('hide');
              $('#PassChangeSuccess').modal('show');
            });
          }
          else {
            $('#PassSecFail').modal('show');
          }
        });
      }
      else {
        //reset the password
        this.pinnacleservice.PasswordReset(this.ResetRequest.requestID, this.ResetRequest.userID, this.newPW).subscribe(res => {
          //password has been reset

          $('#NewPassEnter').modal('hide');
          $('#PassChangeSuccess').modal('show');
        });
      }
     

      
    }
    else {
      $('#PassMatchFail').modal('show');
    }
  }

  toLogin() {
    //navigate to login page

      this.router.navigateByUrl("login");

  }
}
