import { Component, OnInit, Input, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { ProdGenApi } from '../../../apiService/prodgen.api';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { BrowserAuthenticationService } from '../../../BrowserAuthenticationService';
import { User, Playlist, PlaylistContent, WidgetProperties, WidgetPropertyOptions } from '../../../apiService/classFiles/class.users';
import { Content, ContentProduct, ContentType_T } from '../../../apiService/classFiles/class.content';
import { Enrollment, EnrollmentStatus_T } from '../../../apiService/classFiles/class.enrollments';
import { WidgetComponent } from '../../widget/widget.component';
import { Workgroup, PermissionLevel_T } from '../../../apiService/classFiles/class.workgroups';
declare var $: any;

@Component({
  selector: 'app-my-assets-widget',
  templateUrl: './my-assets-widget.component.html',
  styleUrls: ['./my-assets-widget.component.css']
})
export class MyAssetsWidgetComponent extends WidgetComponent implements OnInit, AfterViewInit {
    static key = "MyAssetsWidgetComponent";

    widgetLoaded: boolean = false;

    currentUser: User = new User();
    playlist: Playlist = new Playlist();
    playlistcontent: Array<PlaylistContent> = new Array<PlaylistContent>();
    playlistcontentC: Array<Content> = new Array<Content>();
    playlistcontentitem: Content = new Content();
    currentEnrollments: Array<Enrollment> = new Array<Enrollment>();
    activeEnrollments: Array<Enrollment> = new Array<Enrollment>();
    enrollmentsLoaded: boolean = false;
    playlistLoaded: boolean = false;

    Editid: string = "";
    Editname: string = "";
    Editdesc: string = "";
    isNew: boolean = true;
    Editprods: Array<ContentProduct> = new Array<ContentProduct>();

    modalVisible = false;

    widgetProps: Array<WidgetProperties> = new Array<WidgetProperties>();

    maxheight: number = 0;

    isListDisplay: boolean = false;
    rowCardCount: number = 5;

    //For Share-dropdown Component
    constructedContent: Content = new Content();
    currentHover: string = "";
    shareClickId: string = "";
    shareAdjustmentX: string = '0';
    shareAdjustmentY: string = '0';

    availibleWorkgroups: Array<Workgroup> = new Array<Workgroup>();


    constructor(private pinnacleService: ProdGenApi, private changedetectorref: ChangeDetectorRef, private router: Router, private _sanitizer: DomSanitizer, private authenticationService: BrowserAuthenticationService) {
        // call the base Widget class
        super(pinnacleService, changedetectorref);
        this.widgetHeight = 4;
        this.widgetWidth = 2; 

        this.widgetTitle = "My Assets";
    }

    ngOnInit() {
        super.ngOnInit();
        this.widgetName = "My Assets"
        this.widgetDesc = "Displays a list of content that has been shared to 'My Assets'";
        try {
            //this.categoriesSubscription = JSON.parse(localStorage.getItem(this.currentUser.userId + "_categories_subscription"));
            this.displayType = JSON.parse(localStorage.getItem(this.widgetID + "_displayType"));
        }
        catch (err) {
        }

        // if it was not in storage, reert to default
        if (this.displayType == null) {
            this.displayType = "panel";
        }
        this.createProperties();
        this.Load();
        this.isListDisplay = this.decideListorCards();
    }

    ngAfterViewInit() {
        this.widgetContainer.incrementRenderedWidget();
    }

    Load() {
        this.getmaxheight();
        super.Load();

        try {
            this.playlist = JSON.parse(sessionStorage.getItem(this.currentUser.userId + "_playlist"));
            this.playlistcontent = JSON.parse(sessionStorage.getItem(this.currentUser.userId + "_playlistcontent"));
            if (this.playlistcontent) {
                this.copyPlaylistContentTypetoContentType();
            }
            
            this.playlistLoaded = true;
        }
        catch (err) {
            console.log(err)
        }

        this.pinnacleService.getCurrentUser().subscribe(res => {
            this.currentUser = res;

            this.pinnacleService.getCurrentUserWorkgroups([PermissionLevel_T.owner, PermissionLevel_T.editor]).subscribe(res => {
                this.availibleWorkgroups = res;
            });

            this.pinnacleService.GetPlaylist(this.currentUser.userId).subscribe(pl => {
                this.playlist = pl;
                sessionStorage.setItem(this.currentUser.userId + "_playlist", JSON.stringify(this.playlist));
                this.pinnacleService.GetPlaylistContent(this.playlist.plid, "00000000-0000-0000-0000-000000000000").subscribe(plc => {
                    this.playlistcontent = plc.sort((a: PlaylistContent, b: PlaylistContent) => {
                        if (a.contentname.toLowerCase() < b.contentname.toLowerCase())
                            return -1;
                        else if (a.contentname.toLowerCase() > b.contentname.toLowerCase())
                            return 1;
                        else
                            return 0;
                    });

                    sessionStorage.setItem(this.currentUser.userId + "_playlistcontent", JSON.stringify(this.playlistcontent)); 
                    
                    if (this.playlistcontent) {
                        this.copyPlaylistContentTypetoContentType();
                    }

                    this.playlistLoaded = true;
                });
            });
            this.pinnacleService.getCurrentUserEnrollments().subscribe(res => {
                this.currentEnrollments = res;
                this.activeEnrollments = res.filter(x => x.enrollmentStatus == EnrollmentStatus_T.inProgress);
            });
        });
    }

    onResize() {
        super.onResize();
        this.getEnrollCardCount();
        this.isListDisplay = this.decideListorCards();

    }
    onWidgetResized() {
        super.onWidgetResized();
        this.getEnrollCardCount();

        this.isListDisplay = this.decideListorCards();
    }

    decideListorCards(): boolean {
        if (this.widgetWidth >= 4) {
            if (this.widgetRenderSize == "sm" || this.widgetRenderSize == "xs") {
                return true;
            }
            else {
                return false;
            }

        }
        else {
            return true;
        }
    }

    getEnrollCardCount() {
        if (document.getElementById("widget_" + this.widgetID + "_" + this.widgetContainer.seq)) {

            let pxwidth = document.getElementById("widget_" + this.widgetID + "_" + this.widgetContainer.seq).offsetWidth;

            this.rowCardCount = Math.floor(pxwidth / 150);//Integer is the full width of the card, including margins, borders, and padding
        }
        else {
            this.rowCardCount = 0;
        }
    }

    showMoreCaretDown(someId: string) {
        $('#' + someId).fadeIn(300);
        document.getElementById(someId + 'ShowMoreButton').style.display = "none";
        document.getElementById(someId + 'ShowLessButton').style.display = "block";
        $('#' + someId + "playlistcontentCBlockContainer").removeClass("small-card-container-singlerow");

        this.widgetContainer.onWidgetExpand(this.widgetID);
    }

    showLessCaretUp(someId: string) {
        $('#' + someId).slideToggle(300);
        document.getElementById(someId + 'ShowLessButton').style.display = "none";
        document.getElementById(someId + 'ShowMoreButton').style.display = "block";
        $('#' + someId + "playlistcontentCBlockContainer").addClass("small-card-container-singlerow");
        this.widgetContainer.onWidgetCollapse(this.widgetID);
    }

    getContentUrl(r: Content): string {
        let url: string = "#";
        if (r.contentType == 5) {
            url += "/workflowviewer?id=" + r.contentId + "&contentType=workflow";
        }
        else if (r.contentType == 6) {
            url += "/workflowviewer?id=" + r.contentId + "&contentType=process";
        }
        else if (r.contentType == 7) {
            url += "/workflowviewer?id=" + r.contentId + "&contentType=task";
        }
        else if (r.contentType == 8) {
            url += "/workflowviewer?id=" + r.contentId + "&contentType=step";
        }
        else if (r.contentType == 0) {
            url += "/cheatsheetviewer?id=" + r.contentId;
        }
        else if (r.contentType == 4) {
            url += "/videoviewer?id=" + r.contentId;
        }
        else if (r.contentType == 1) {
            url += "/learningcenter/series?learningPathId=" + r.contentId;
        }
        else if (r.contentType == 2) {
            if (this.currentEnrollments != null) {
                if (this.currentEnrollments.findIndex(course => course.courseId == r.contentId) == -1) {
                    url += "/learningcenter/series?courseId=" + r.contentId;
                }
                else {
                    url += "/learningcenter/courseviewer?enrollmentId=" + this.currentEnrollments[this.currentEnrollments.findIndex(course => course.courseId == r.contentId)].enrollmentId;
                }
            }


        }

        return url;
    }


    getContentImage(r: Content): string {
        let image: string = "";

        image += ProdGenApi.getIconImageFromType(r.contentType);


        if (r.bisCustom == false) {
            //      if (r.contentType == ContentType_T.cheatsheet && r.docURL != null && r.docURL != "") {
            //          if (r.docURL.includes(".pdf")) {
            //              image += "PDF Icon.png"
            //          }
            //          else if (r.docURL.includes(".doc")) {
            //              image += "Word Icon.png"
            //          }
            //          else if (r.docURL.includes(".xls")) {
            //              image += "Excel Icon.png"
            //          }
            //          else if (r.docURL.includes(".ppt")) {
            //              image += "PowerPoint Icon.png"
            //          }
            //          else if (r.docURL.includes(".htm")) {
            //              image += "HTML Icon.png"
            //          }
            //      }
            //      else {
            //          image += ProdGenApi.getIconImageFromType(r.contentType);
            //      }
        }
        else {
            image = "fas fa-id-badge";
        }


        return image;
    }

    onClose(event: any) {

        this.Editid = "";
        this.Editname = "";
        this.Editdesc = "";
        this.isNew = true;
        this.Editprods = new Array<ContentProduct>();
        this.modalVisible = false;

        if (event == "Save") {
            this.pinnacleService.GetPlaylist(this.currentUser.userId).subscribe(pl => {
                this.playlist = pl;
                this.pinnacleService.GetPlaylistContent(this.playlist.plid, "00000000-0000-0000-0000-000000000000").subscribe(plc => {
                    this.playlistcontent = plc;
                    if (this.playlistcontent) {
                        this.copyPlaylistContentTypetoContentType();
                    }
                    this.playlistLoaded = true;
                });
            });
        }

        let otherwidgets = document.getElementsByClassName("widget ongrid");
        for (var i = 0; i < otherwidgets.length; i++) {
            if ((<HTMLElement>otherwidgets[i]).getAttribute("data-widgetid") != this.widgetID) {
                (<HTMLElement>otherwidgets[i]).style.zIndex = "10000";
            }
        }
    }

    NewCheat() {
        this.modalVisible = true;

        let otherwidgets = document.getElementsByClassName("widget ongrid");
        for (var i = 0; i < otherwidgets.length; i++) {
            if ((<HTMLElement>otherwidgets[i]).getAttribute("data-widgetid") != this.widgetID) {
                (<HTMLElement>otherwidgets[i]).style.zIndex = "10";
            }
        }

        setTimeout(function () {
            $('#newcheat').modal({ backdrop: 'static', keyboard: false });
        }, 1);
    }

    getmaxheight() {
        if (document.getElementById(this.widgetContainer.seq + "row0col0")) {
            let baseheight = document.getElementById(this.widgetContainer.seq + "row0col0").clientHeight;
            this.maxheight = (this.widgetHeight * baseheight) - 46;
        }        
    }

    onPropsChanged(newprops: Array<WidgetProperties>, writeSettings: boolean = false, doresequence = true) {
        super.onPropsChanged(newprops, writeSettings, doresequence);
        

        if (writeSettings) {
            this.pinnacleService.setWidgetProperties(this.widgetContainer.containerInfo.containerInstanceId, this.widgetID, newprops, "MyAssetsWidgetComponent").subscribe(res => {/*return stuff. might not need to do anything here*/ });
        }

        super.onPropsChangedLocalStorage();
        

        //this.widgetContainer.UpdateWidgetDimensions(this.widgetID, this.widgetWidth, this.widgetHeight);
        this.widgetLoaded = true;
        super.widgetArePropertiesLoaded();
    }


    createProperties() {

        super.createProperties();

        super.createPropertiesDisplayType("panel");

        super.createPropertiesWidgetTitle("My Assets");

        super.createPropertiesMobileOptions();

    }
    FauxDBLoad() {
        let h: WidgetProperties = new WidgetProperties();
        h.propID = "1";
        h.propName = "Height";
        h.propType = "number";
        h.propValue = this.widgetHeight.toString();

        this.widgetProps.push(h);

        let w: WidgetProperties = new WidgetProperties();
        w.propID = "2";
        w.propName = "Width";
        w.propType = "number";
        w.propValue = this.widgetWidth.toString();

        this.widgetProps.push(w);
    }

    copyPlaylistContentTypetoContentType() {
        this.playlistcontentC.splice(0, this.playlistcontentC.length);
        for (let item of this.playlistcontent) {
            let pushitem = new Content();
            pushitem.bisCustom = item.biscustom;
            pushitem.bisDownloadable = item.bisdownloadable;
            pushitem.contentId = item.contentId;
            pushitem.contentType = item.contentType;
            pushitem.description = item.contentdesc;
            pushitem.docURL = item.docURL;
            pushitem.imageURL = "";
            pushitem.name = item.contentname;
            pushitem.providedBy = "";

            this.playlistcontentC.push(pushitem);
        }
    }

    //For Share DropDown
    buildItem(itemID: string, itemName: string, itemType: ContentType_T): Content {
        let constructedContent = new Content();
        constructedContent.contentId = itemID;
        constructedContent.name = itemName;
        constructedContent.contentType = itemType;

        return constructedContent;
    }

    //For Share DropDown
    hoverElem(content: Content) {
        this.constructedContent = this.buildItem(content.contentId.toString(), content.name.toString(), content.contentType);
        this.currentHover = content.contentId.toString();

        if (this.currentHover != this.shareClickId) {
            this.shareClickId = "";
        }
    }

    //For Share DropDown
    shareClick(content: Content) {
        this.constructedContent = this.buildItem(content.contentId.toString(), content.name.toString(), content.contentType);
        if (this.shareClickId == content.contentId.toString()) {
            this.shareClickId = "";
        }
        else {
            this.shareClickId = content.contentId.toString();
        }

        let tempdiv = document.getElementById('contentScrollArea' + this.widgetID);

        let box = { left: 0, top: 0 };
        box = tempdiv.getBoundingClientRect();
       

        let reservedSpotShare = document.getElementById('shareIconPlacment' + this.widgetID + content.contentId);

        let box2 = { left: 0, top: 0 };
        box2 = reservedSpotShare.getBoundingClientRect();

        let x = (box.left - box2.left);
        let y = (box.top - box2.top);
        //console.log('x : ' + x + '   y : ' + y);


        if (x > -250) {
            this.shareAdjustmentX = (20) + 'px';
        }
        else {
            this.shareAdjustmentX = ((-1 * x) -220) + 'px';
        }
        
        this.shareAdjustmentY = ((-1 * y) + 70) + 'px';

    }

    //For Share DropDown
    onHideShareDropDown(event) {
        this.shareClickId = "";
    }

    //For Share DropDown
    exitHoverElem() {
        if (this.shareClickId == "") {
            this.currentHover = "";
        }
    }
}
