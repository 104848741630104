import { Component, OnInit, ViewChild, Input, OnChanges, AfterViewInit } from '@angular/core';
import { ProdGenApi } from './../../apiService/prodgen.api';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { BrowserAuthenticationService } from '../../BrowserAuthenticationService';
import { PartnerNewsItem } from '../../apiService/classFiles/class.partners';
import { CompanyNewsItem } from '../../apiService/classFiles/class.organizations';
import { User } from '../../apiService/classFiles/class.users';
import { AssetLibrary, AssetLibraryProduct } from '../../apiService/classFiles/class.assetlibrary';
import { ProductListingComponent } from '../../product-listing/product-listing.component';
import { Location } from '@angular/common';
import { AppComponent } from '../../app.component';
import { BrandingUpdateServiceService } from '../../services/branding-update-service.service';
import { WidgetLibraryService } from '../../services/widget-library.service';

declare var $: any;

@Component({
  selector: 'template-corporatepanel',
  templateUrl: './corporatepanel.component.html',
  styleUrls: ['./corporatepanel.component.css']
})
export class CorporatepanelComponent implements OnInit, AfterViewInit {
    @Input('currentUser') currentUser: User = new User();
    @Input('showNews') showNews: boolean = true;
  @ViewChild('product-listing', { static: false }) productlisting: ProductListingComponent;
  isAuthenticated: boolean = false;
  canViewDownloadAddOns: boolean = true;

  companyInfo: Array<CompanyNewsItem> = new Array<CompanyNewsItem>();
  recommendedAddons: Array<CompanyNewsItem> = new Array<CompanyNewsItem>();
  companyInfoImage: string = "";
  companyInfoLoaded: boolean = false;

  partnerInfo: Array<PartnerNewsItem> = new Array<PartnerNewsItem>();
  recommendedPartnerAddons: Array<PartnerNewsItem> = new Array<PartnerNewsItem>();
  partnerInfoImage: string = "";
  partnerInfoLoaded: boolean = false;
    privateAssetsExist: boolean = false;
  uniqueImageQuery: number = Date.now();

  //currentUser: User = new User();
  assetLibrariesData: Array<AssetLibrary> = new Array<AssetLibrary>();
  assetLibraryLoaded: boolean = false;

  assetLibrariesSubscription: Array<AssetLibrary> = new Array<AssetLibrary>();
    assetLibrarySubscriptionLoaded: boolean = false;

    preSelectedAssetID: string = "";

    constructor(private pinnacleService: ProdGenApi,private route:ActivatedRoute, private router: Router, private _sanitizer: DomSanitizer, private authenticationService: BrowserAuthenticationService, private location: Location, private brandingservice:BrandingUpdateServiceService, private widgetlibsvc:WidgetLibraryService) {
   
  }

    ngOnInit() {
        this.preSelectedAssetID = this.widgetlibsvc.selectedAssetLibID;
        
        this.route.queryParams.subscribe(queryParams => {
            this.preSelectedAssetID = queryParams['astid'];
        });

        this.widgetlibsvc.selectedAssetLibID = "";
        if (this.authenticationService.AuthenticatePage() == true) {
            this.isAuthenticated = true;
            this.companyInfoLoaded = false;
            this.pinnacleService.GetCurrentTenantSettings().subscribe(res => {
                let infoIndex = res.findIndex(w => w.name == "COMPANY_NEWS");
                if (infoIndex != -1) {
                    this.companyInfoImage = res[infoIndex].settingValue as string;
                    try {
                        localStorage.setItem(ProdGenApi.getCurrentTenantId() + "_company_news_image", JSON.stringify(this.companyInfoImage));
                    }
                    catch (err) {
                        console.log(err);
                    }
                }
                this.companyInfoLoaded = true;

                let viewDownloadIndex = res.findIndex(w => w.name == "DOWNLOAD_VIEW");

                if (viewDownloadIndex != -1) {
                    //this.canViewDownloadAddOns = res[viewDownloadIndex].settingValue as boolean;


                    if (res[viewDownloadIndex].settingValue == "True") {
                        this.canViewDownloadAddOns = true;
                    }
                    else {
                        this.canViewDownloadAddOns = false;
                    }



                    try {
                        localStorage.setItem(ProdGenApi.getCurrentTenantId() + "_download_view", JSON.stringify(this.canViewDownloadAddOns));
                    }
                    catch (err) {
                        console.log(err);
                    }
                }
            });

            this.pinnacleService.GetCurrentTenantNews().subscribe(res => {
                this.companyInfo = res;
                try {
                    localStorage.setItem(ProdGenApi.getCurrentTenantId() + "_company_news", JSON.stringify(this.companyInfo));
                }
                catch (err) {
                    console.log(err);
                }
            });

            this.pinnacleService.GetPartnerSettings().subscribe(res => {
                if (AppComponent.isPinnacleLite == true) {
                    let infoIndex = res.findIndex(w => w.name == "LITE_NEWS");
                    if (infoIndex != -1) {
                        this.partnerInfoImage = res[infoIndex].settingValue as string;
                    }
                    else {
                        this.partnerInfoImage = "";
                    }


                    try {
                        localStorage.setItem(ProdGenApi.getCurrentTenantId() + "_lite_news_image", JSON.stringify(this.partnerInfoImage));
                    }
                    catch (err) {
                        console.log(err);
                    }
                    this.companyInfoLoaded = true;
                }
                else {
                    let infoIndex = res.findIndex(w => w.name == "PARTNER_NEWS");
                    if (infoIndex != -1) {
                        this.partnerInfoImage = res[infoIndex].settingValue as string;
                    }
                    else {
                        this.partnerInfoImage = "";
                    }


                    try {
                        localStorage.setItem(ProdGenApi.getCurrentTenantId() + "_partner_news_image", JSON.stringify(this.partnerInfoImage));
                    }
                    catch (err) {
                        console.log(err);
                    }
                    this.companyInfoLoaded = true;
                }

            });

            this.pinnacleService.GetPartnerNewsItems().subscribe(res => {
                this.partnerInfo = res;
                try {
                    localStorage.setItem(ProdGenApi.getCurrentTenantId() + "_partner_news", JSON.stringify(this.partnerInfo));
                }
                catch (err) {
                    console.log(err);
                }
            });

            this.brandingservice.newsitemsemitter.subscribe(res => { this.companyInfo = res });
            this.brandingservice.newsimgemitter.subscribe(res => { this.companyInfoImage = res });
        }

        //this.pinnacleService.getCurrentUser().subscribe(u => {

        if (this.currentUser.imageURL == "") {
            this.currentUser.imageURL = "../assets/images/default_user.jpg";
        }
        //this.currentUser = u;

        try {
            localStorage.setItem(this.currentUser.userId + "_user_image", JSON.stringify(this.currentUser.imageURL));
        }
        catch (err) {
            console.log(err);
        }


        // try to load up any previous information. this will keep the screen from flickering..?
        try {
            //this.categoriesSubscription = JSON.parse(localStorage.getItem(this.currentUser.userId + "_categories_subscription"));
            this.assetLibrariesData = JSON.parse(localStorage.getItem(this.currentUser.userId + "_asset_library"));

        }
        catch (err) {
            console.log(err)
        }

        try {
            //this.categoriesSubscription = JSON.parse(localStorage.getItem(this.currentUser.userId + "_categories_subscription"));
            this.assetLibrariesSubscription = JSON.parse(localStorage.getItem(this.currentUser.userId + "_asset_library_subscription"));
        }
        catch (err) {
            console.log(err);
        }


        try {
            let i = JSON.parse(localStorage.getItem(ProdGenApi.getCurrentTenantId() + "_company_news"));
            if (i != null) {
                this.companyInfo = i;
            }
        }
        catch (err) {
            console.log(err)
        }

        try {
            let i = JSON.parse(localStorage.getItem(ProdGenApi.getCurrentTenantId() + "_company_news_image"));
            if (i != null) {
                this.companyInfoImage = i;
            }
        }
        catch (err) {
            console.log(err)
        }


        try {
            let i = JSON.parse(localStorage.getItem(ProdGenApi.getCurrentTenantId() + "_partner_news"));
            if (i != null) {
                this.partnerInfo = i;
            }
        }
        catch (err) {
            console.log(err)
        }

        try {
            let i = JSON.parse(localStorage.getItem(ProdGenApi.getCurrentTenantId() + "_partner_news_image"));
            if (i != null) {
                this.partnerInfoImage = i;
            }
        }
        catch (err) {
            console.log(err)
        }

        this.loadAssetLibrary();

    //});
    }

    ngAfterViewInit() {


    }

    loadAssetLibrary() {
        this.assetLibrariesData = new Array<AssetLibrary>();;

        //Asset Library
        this.pinnacleService.getAssetLibraries(true, true, false).subscribe(s => {


            let assetLibrariesDatatoRemove = new Array<AssetLibrary>();
            let assetLibrariesDataAddedto = new Array<AssetLibrary>();
            let assetLibraryProdsRemoved = new Array<AssetLibraryProduct>();

            this.assetLibrariesData = s;

            //go through asset libraries and see if any have the same name.
            //If they do we want to combine those asset libraries and only
            //  display distinct products under them.

            for (var a = 0; a < this.assetLibrariesData.length; a++) {
                for (var b = 0; b < this.assetLibrariesData.length; b++) {
                    if (this.assetLibrariesData[a].name == this.assetLibrariesData[b].name && this.assetLibrariesData[a].assetLibraryId != this.assetLibrariesData[b].assetLibraryId && (this.assetLibrariesData[a].assetLibraryId != "00000000-0000-0000-0000-000000000000" && this.assetLibrariesData[b].assetLibraryId != "00000000-0000-0000-0000-000000000000")) {//makes sure they have the same name but are not the same lib
                        if (this.assetLibrariesData[a].isSubcription == false && this.assetLibrariesData[b].isSubcription == true) {// a is custom b is not, move a into b
                            assetLibrariesDatatoRemove.push(this.assetLibrariesData[a]);
                            assetLibrariesDataAddedto.push(this.assetLibrariesData[b]);
                            for (var c = 0; c < this.assetLibrariesData[a].productCollection.length; c++) {
                                this.assetLibrariesData[b].productCollection.push(this.assetLibrariesData[a].productCollection[c]);
                            }
                            this.assetLibrariesData.splice(a, 1);
                        }
                        else if (this.assetLibrariesData[a].isSubcription == true && this.assetLibrariesData[b].isSubcription == false) {// b is custom a is not, move b into a
                            assetLibrariesDatatoRemove.push(this.assetLibrariesData[b]);
                            assetLibrariesDataAddedto.push(this.assetLibrariesData[a]);
                            for (var c = 0; c < this.assetLibrariesData[b].productCollection.length; c++) {
                                this.assetLibrariesData[a].productCollection.push(this.assetLibrariesData[b].productCollection[c]);
                            }
                            this.assetLibrariesData.splice(b, 1);
                        }
                        else if (this.assetLibrariesData[a].isSubcription == this.assetLibrariesData[b].isSubcription) {// both have same subcription status, direction doesnt matter
                            assetLibrariesDatatoRemove.push(this.assetLibrariesData[a]);
                            assetLibrariesDataAddedto.push(this.assetLibrariesData[b]);
                            for (var c = 0; c < this.assetLibrariesData[a].productCollection.length; c++) {
                                this.assetLibrariesData[b].productCollection.push(this.assetLibrariesData[a].productCollection[c]);
                            }
                            this.assetLibrariesData.splice(a, 1);
                        }
                    }
                }
            }

            sessionStorage.setItem("AssetLibsRemoved", JSON.stringify(assetLibrariesDatatoRemove));
            sessionStorage.setItem("AssetLibsAddedto", JSON.stringify(assetLibrariesDataAddedto));
            //go throught the assetLibrariesDataAddedto and clean up duplicate products.
            //Add the removed products to local storage so it can be accessed once a product is clicked.

            for (var a = 0; a < assetLibrariesDataAddedto.length; a++) {
                for (var b = 0; b < this.assetLibrariesData.length; b++) {
                    if (assetLibrariesDataAddedto[a].assetLibraryId == this.assetLibrariesData[b].assetLibraryId) {
                        for (var c = 0; c < this.assetLibrariesData[b].productCollection.length; c++) {
                            for (var d = 0; d < this.assetLibrariesData[b].productCollection.length; d++) {
                                if (this.assetLibrariesData[b].productCollection[c].name == this.assetLibrariesData[b].productCollection[d].name && this.assetLibrariesData[b].productCollection[c].productId != this.assetLibrariesData[b].productCollection[d].productId) {
                                    assetLibraryProdsRemoved.push(this.assetLibrariesData[b].productCollection[d]);
                                    this.assetLibrariesData[b].productCollection.splice(d, 1);
                                    d = d - 1;
                                }
                            }
                        }
                    }
                }
            }

            sessionStorage.setItem("duplicateProds", JSON.stringify(assetLibraryProdsRemoved));

            //remove from assetLibrariesData items in assetLibrariesDatatoRemove.

            //for (var a = 0; a < assetLibrariesDatatoRemove.length; a++) {
            //  for (var b = 0; b < this.assetLibrariesData.length; b++) {
            //    if (assetLibrariesDatatoRemove[a].assetLibraryId == this.assetLibrariesData[b].assetLibraryId) {
            //      this.assetLibrariesData.splice(b, 1);
            //    }
            //  }
            //}
            for (var i = 0; i < this.assetLibrariesData.length; i++) {
                this.assetLibrariesData[i].productCollection = this.assetLibrariesData[i].productCollection.sort((a: any, b: any) => {
                    var x = a.name;
                    var y = b.name;
                    if (x < y) { return -1; }
                    if (x > y) { return 1; }
                    return 0;
                });
            }
            this.assetLibraryLoaded = true;
            /// write to Local Storage for this user
            try {
                localStorage.setItem(this.currentUser.userId + "_asset_library", JSON.stringify(this.assetLibrariesData))
            }
            catch (err) {
                console.log(err);
            }


            this.assetLibrariesData.forEach(x => {
                if (x.isSubcription) {
                    this.assetLibrarySubscriptionLoaded = true;
                }
                else {
                    this.privateAssetsExist = true;
                }
            });

        }, e => {

        });
    }

    get isPinnacleLite(){
        return AppComponent.isPinnacleLite;
    }

    onReloadAssetLibrary() {
        this.pinnacleService.invlidateCacheStringContaining("assetLibraries");

        this.assetLibraryLoaded = false;
        this.loadAssetLibrary();
    }

  getExpandedIconClass(elmId: string): string {

    //return "fa fa-minus";
      if (this.preSelectedAssetID != "") {
          if (elmId.includes(this.preSelectedAssetID)) {
              return "fa fa-minus";
          }
      }

    return "fa fa-plus";
  }

  onFacetHeaderClicked(a: AssetLibrary) {
    this.getExpandedIconClass("#collapse_" + a.assetLibraryId);

    var isExpanded = $("#image_" + a.assetLibraryId).hasClass("fa-minus");
    $("#image_" + a.assetLibraryId).toggleClass("fa-minus");
    $("#image_" + a.assetLibraryId).toggleClass("fa-plus");
  }

  assetLibraryProductHREF(product: AssetLibraryProduct, asset: AssetLibrary): string {
    let url: string = "";
      //url += "search?category=" + SearchComponent.encodeSearchParameters(product.name as string);
      url += "product-listing?proid=" + product.productId + "&astid=" + product.assetLibraryId + "&libname=" + this.encodeVarForUrl(asset.name) + "&name=" + this.encodeVarForUrl(product.name) + "&isprod=true" + "&issubscription=" + asset.isSubcription;
    return url;

  }

  assetLibraryAssetHREF(asset: AssetLibrary): string {
    let url: string = "";
    //url += "search?category=" + SearchComponent.encodeSearchParameters(asset.name as string);
    //url += "product-listing?proid=" + asset.productCollection[0].productId + "&astid=" + asset.assetLibraryId + "&name=" + "" + "&isprod=true";

      url += "product-listing?proid=" + asset.productCollection[0].productId + "&astid=" + asset.assetLibraryId + "&libname=" + this.encodeVarForUrl(asset.name) + "&name=" + "" + "&isprod=true" + "&issubscription=" + asset.isSubcription;
    return url;

  }

  selectAsset(asset: AssetLibrary) {
      this.router.navigateByUrl("product-listing?proid=" + asset.productCollection[0].productId + "&astid=" + asset.assetLibraryId + "&libname=" + this.encodeVarForUrl(asset.name) + "&name=" + "" + "&isprod=true" + "&issubscription=" + asset.isSubcription);

      if (AppComponent.thisFromOutside.Flyoutopen == true) {
          AppComponent.thisFromOutside.closeFlyout();
          AppComponent.thisFromOutside.Flyoutopen = false;
      }
    //this.location.go("/product-listing?proid=" + asset.productCollection[0].productId + "&astid=" + asset.assetLibraryId + "&libname=" + asset.name + "&name=" + "" + "&isprod=true");
    //this.loadWorkgroupData(w);
    //this.productlisting.LoadData();

  }
  selectProduct(product: AssetLibraryProduct, asset: AssetLibrary) {
      this.router.navigateByUrl("product-listing?proid=" + product.productId + "&astid=" + product.assetLibraryId + "&libname=" + this.encodeVarForUrl(asset.name) + "&name=" + this.encodeVarForUrl(product.name) + "&isprod=true" + "&issubscription=" + asset.isSubcription);

      if (AppComponent.thisFromOutside.Flyoutopen == true) {
          AppComponent.thisFromOutside.closeFlyout();
          AppComponent.thisFromOutside.Flyoutopen = false;
      }
   // this.location.go("/product-listing?proid=" + product.productId + "&astid=" + product.assetLibraryId + "&libname=" + asset.name + "&name=" + product.name + "&isprod=true");
    //this.loadWorkgroupData(w);
    //this.productlisting.LoadData();

  }

    encodeVarForUrl(text: string) {
        return text.replace("&", "%26").replace("?", "%3F");
    }

    getUniqueImageSource(source: string): string {
        let newSource = "";
        if (source != null) {
            if (source.length > 0) {
                if (source.indexOf("?") == -1) {
                    newSource = source + "?" + this.uniqueImageQuery;
                }
                else {
                    newSource = source + "&" + this.uniqueImageQuery;
                }
            }
        }
        return newSource;
    }

    getLinkTarget(linkUrl: string):string {
        let target = "";

        if (linkUrl.includes(window.location.origin)) {
            target = "_self";
        }
        else {
            target = "_blank";
        }

        return target;
    }

}
