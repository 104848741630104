import { Injectable, SecurityContext } from '@angular/core';
import { Router } from '@angular/router';
import { Component, OnInit, Input, OnChanges, SimpleChanges, SimpleChange, Output, EventEmitter } from '@angular/core';
import { Content, ContentType_T } from './../../apiService/classFiles/class.content';
import { WorkgroupExternalMembers } from './../../apiService/classFiles/class.workgroups';
import { ProdGenApi } from './../../apiService/prodgen.api';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'external-user-card',
    templateUrl: './external-user-card.component.html',
    styleUrls: ['./external-user-card.component.css'],
    providers: [ProdGenApi]
})

export class ExternalUserCardComponent implements OnInit {

    @Input('member') member: WorkgroupExternalMembers;
    @Input('canDelete') canDelete: boolean;

    //@Input('classInput') classInput: string;


    constructor(private pinnacleService: ProdGenApi, private router: Router, private sanitizer: DomSanitizer) {

    }

    ngOnInit() {
    }

    removeMember() {

    }
}