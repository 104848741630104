import { Component, OnInit, ViewChild } from '@angular/core';
import { Enrollment, EnrollmentTrackingItem, EnrollmentFilter_T } from '../apiService/classFiles/class.enrollments';
import { Quiz, ContentType_T, DescriptionFilter_T, CourseWithContent, Course, LearningPath, ContentUsedProduct, ContentUsedProductVersion, Content, ContentAuthoring} from '../apiService/classFiles/class.content';
import { User} from '../apiService/classFiles/class.users';
import { ActivatedRoute, Router } from '@angular/router';
import { ProdGenApi } from './../apiService/prodgen.api';
import { CourseViewerSyllabusComponent } from './../templates/courseviewersyllabus/courseviewersyllabus.component';
import { VideoContentComponent } from './../templates/videocontent/videocontent.component';
import { BrowserAuthenticationService } from './../BrowserAuthenticationService';
import { QuizContentComponent } from '../templates/quizcontent/quizcontent.component';
import { AutoplayVidService } from './../AutoplayVidService';
import { PermissionLevel_T, Workgroup } from '../apiService/classFiles/class.workgroups';
import { TranslationService } from './../services/TranslationService';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ScormViewer } from '../templates/scormviewer/scormviewer.component';
import { AppComponent } from '../app.component';

declare var $: any;

@Component({
    selector: 'app-learningcentercourseviewer',
    templateUrl: './learningcentercourseviewer.component.html',
    styleUrls: ['./learningcentercourseviewer.component.css']
})
export class LearningCenterCourseViewerComponent implements OnInit {
    @ViewChild('courseSyllabus', { static: false }) courseSyllabus: CourseViewerSyllabusComponent;
    @ViewChild('videoContent', { static: false }) videoContent: VideoContentComponent;
    @ViewChild('quizContent', { static: false }) quizContent: QuizContentComponent;
    @ViewChild('scormContent', { static: false }) scormContent: ScormViewer;

    learningPath: LearningPath = new LearningPath();
    courses: Array<Course> = new Array<Course>();
    coursesAndContent: Array<CourseWithContent> = new Array<CourseWithContent>();

    enrollmentId: string = "";
    activeItem: EnrollmentTrackingItem = new EnrollmentTrackingItem();
    enrollment: Enrollment = new Enrollment();
    assignedBy: User = new User();
    currentUser: User = new User();
    activeViewStart: Date = new Date(Date.now());
    samelpenrollments: Array<Enrollment> = new Array<Enrollment>();
    samelpenrollmentsAfterComplete: Array<Enrollment> = new Array<Enrollment>();
    isNewNavigation: boolean = true;

    isAuthenticated: boolean = false;

    isActiveSubscriptionItem: boolean = false;
    allownavigation: boolean = true;
    allowAutoPlay: boolean = false;
    endofvid: boolean = false;
    usedProducts: Array<string> = new Array<string>();
    productVersions: Array<ContentUsedProductVersion> = new Array<ContentUsedProductVersion>();
    products: Array<ContentUsedProduct> = new Array<ContentUsedProduct>();
  noErrs: boolean = true;
    friendlyMsg: SafeHtml;

    allowComplete: boolean = true;
    //nextPossibleIndex: number = 0;
    failedQuizzes: Array<string> = new Array<string>();
    requiredQuizzesNotAttempted: Array<string> = new Array<string>();

    showButtons: boolean = false;

    maxAttemptsMessage: string = "";
    navNextItemMessage: string = "";
    completeBeforeNavMessage: string = "";
    navPrevItemMessage: string = "";

    contentAuthoring: ContentAuthoring = new ContentAuthoring();

    currentVideoPlayed: boolean = false;

    activeItemIsScormCourse: boolean = false;

	constructor(private pinnacleService: ProdGenApi, private router: Router, private route: ActivatedRoute, private authenticationService: BrowserAuthenticationService, private autoplayvidservice: AutoplayVidService, private translationService: TranslationService, private _sanitizer: DomSanitizer)
    {
    }

    ngOnInit() {

        if (this.authenticationService.AuthenticatePage() == true) {
            this.isAuthenticated = true;

            this.maxAttemptsMessage = this.translationService.getTranslationFileData("LEARNINGCENTERCOURSEVIEWER.MaxAttemptsMessage");
            this.navNextItemMessage = this.translationService.getTranslationFileData("LEARNINGCENTERCOURSEVIEWER.NavNextItemMessage");
            this.completeBeforeNavMessage = this.translationService.getTranslationFileData("LEARNINGCENTERCOURSEVIEWER.CompleteBeforeNavMessage");
            this.navPrevItemMessage = this.translationService.getTranslationFileData("LEARNINGCENTERCOURSEVIEWER.NavPrevItemMessage");

            this.route.queryParams.subscribe(queryParams => {
                if (queryParams['enrollmentId'] != null) {
                    this.enrollmentId = queryParams['enrollmentId'];
                }
            });
            if (this.enrollmentId != "") {
                this.pinnacleService.GetEnrollment(this.enrollmentId).subscribe(res => {

                    this.enrollment = res;
                    if (this.enrollment.enrollmentId == ProdGenApi.EMPTY_GUID) {
                        this.noErrs = false;
                        this.friendlyMsg = this._sanitizer.bypassSecurityTrustHtml(this.translationService.getTranslationFileData("APP.AssetViewing0DRs"));
                        location.href = "#/learningcenter/series?courseId=" + this.enrollment.courseId;
                    }
                    else {
                        this.getAuthoringContent(this.enrollment.learningPathId.toString());

                        this.courseSyllabus.owner(this);
                        this.courseSyllabus.loadTrackingItems();

                        // this.enrollment.isCustom = true; set is custom to true for testing

                        if (res.isSelfEnrolled == false) {
                            this.pinnacleService.getUser(res.assignerUserId).subscribe(u => this.assignedBy = u);
                        }

                        if (this.isNewNavigation == true) {

                            this.pinnacleService.getLearningPathUserEnrollments([EnrollmentFilter_T.assigned, EnrollmentFilter_T.inProgress], this.enrollment.learningPathId as string).subscribe(res2 => {
                                this.samelpenrollments = res2;
                                this.loadLearningPath(this.enrollment.learningPathId as string);

                            });
                            this.isNewNavigation = false;
                        }                    

                    }

                });               

            }
        }

        this.pinnacleService.getCurrentUser(false).subscribe(res => {
            this.currentUser = res
        });

        this.onResize(null);
    }


    onActiveItemChanged(event) {
        if (this.activeItem != event.activeItem) {
            this.recordCurrentActivity();
        }

        // If the current item is SCORM, let the SCORM viewer
        // component have a chance to synchronize with the driver
        // in blob storage and persist to the database before navigating
        // to the next item in the syllabus.
        if (this.activeItem.contentType == ContentType_T.scormcourse) {
            this.scormContent.forceComplete();
            setTimeout(() => { this.finishActiveItemChange(event); }, 500);
        }
        else {
            // If the current item is not SCORM, proceed as normal
            // with no delay.
            this.finishActiveItemChange(event);
        }
    }

    finishActiveItemChange(event) {
        this.activeItemIsScormCourse = false;

        this.activeItem = event.activeItem;
        this.activeViewStart = new Date(Date.now());

        // Check if current course is SCORM content
        if (this.activeItem.contentType == 12) {
            setTimeout(() => { this.activeItemIsScormCourse = true; }, 0);
            //this.scormContent.ngOnDestroy();
        }

        $('#courseSyllabus').insertAfter('#CourseButton_' + this.activeItem.enrollmentId);
        //check if active item is available to subscription

        if (AppComponent.thisFromOutside.Flyoutopen == true) {
            AppComponent.thisFromOutside.closeFlyout();
            AppComponent.thisFromOutside.Flyoutopen = false;
        }
    }

    onQuizSubmitted(event) {

        if (this.activeItem == null ||
            this.activeItem.contentId == null ||
            this.activeItem.contentId == "" ||
            this.activeItem.contentId == '00000000-0000-0000-0000-000000000000' ||
            this.enrollment == null ||
            this.enrollment.enrollmentId == null ||
            this.enrollment.enrollmentId == "" ||
            this.enrollment.enrollmentId == "00000000-0000-0000-000000000000") {
            return;
        }

      let q: Quiz = event.quiz;
      if (q.quizId == this.activeItem.contentId)
      {
          this.activeItem.isComplete = true;
          this.activeItem.quizStatus = q.status;
      }
      this.checkQuizzesStatus();


    
  }

    checkQuizzesStatus() {
        if (this.allowComplete != false && this.activeItem != null && this.activeItem.enrollmentId != null) {

            this.pinnacleService.lpGetFailedQuizzes(this.activeItem.enrollmentId as string).subscribe(res => {
                this.failedQuizzes = new Array<string>();
                this.allowComplete = res.length == 0;
                if (!this.allowComplete) {
                    for (let q of res) {
                        this.failedQuizzes.push(q);
                    }
                    if (this.activeItem.requireQuizzesPassed) {
                        this.courseSyllabus.stopProgress();
                    }
                }
            });
            this.pinnacleService.lpAreAnyRequiredQuizzesNotAttempted(this.activeItem.enrollmentId as string).subscribe(res => {
                this.requiredQuizzesNotAttempted = new Array<string>();
                this.requiredQuizzesNotAttempted = res;
            });
        }
    }

  recordCurrentActivity()
  {
      /* User is manually changing completion status BACK to incomplete. 
         We need to ensure 'onblur' does not revert it back to complete: manualChange */ 
      var manualChange = this.courseSyllabus.isMarked

        if (manualChange === true) {
            this.courseSyllabus.iterateMarkedValue = 1;
            this.courseSyllabus.isMarked = false;
        }

        if (manualChange === false && this.activeItem.isComplete === false && (this.activeItem.contentId == this.courseSyllabus.markedContent) && this.courseSyllabus.iterateMarkedValue === 1) {
            this.courseSyllabus.iterateMarkedValue = 0;
            return;
        }

        if (this.activeItem == null ||
            this.activeItem.contentId == null ||
            this.activeItem.contentId == "" ||
            this.activeItem.contentId == '00000000-0000-0000-0000-000000000000' ||
            this.enrollment == null ||
            this.enrollment.enrollmentId == null ||
            this.enrollment.enrollmentId == "" ||
            this.enrollment.enrollmentId == "00000000-0000-0000-000000000000") {
            return;
        }

        // see if we need to mark the previous active item as complete
        // if it was a quiz we only do it if the quiz was taken
        // if a video, only if it was played

      if (this.activeItem.contentType == ContentType_T.video) {
          // The video content page takes care of this. all we need to do is see if it was played
          if (this.videoContent.hasPlayedVideo == true)
          {
              this.activeItem.isComplete = true;

              let v_Date = new Date(Date.now())
              
              this.pinnacleService.SetVideoEnrollmentTracking(this.enrollmentId as string,
                  this.activeItem.contentId as string,
                  0,
                  v_Date,
                  v_Date,
                  true,
                  1).subscribe(res => {

                  });
                  
          }

            if (this.videoContent.isValidURL == false) {
                // this was an external video, so log the tracking information here since it was not tied to actual video controls
                this.pinnacleService.SetVideoEnrollmentTracking(this.enrollmentId as string,
                    this.activeItem.contentId as string,
                    1,
                    this.activeViewStart,
                    new Date(Date.now()),
                    true,
                    1).subscribe(res => {

                    });
            }
        }
        else if (this.activeItem.contentType == ContentType_T.quiz) {
            // see if quiz was taken
        }
        else {
            this.activeItem.isComplete = true;

            this.pinnacleService.SetEnrollmentTracking(this.enrollment.enrollmentId as string,
                this.activeItem.contentId as string,
                this.activeItem.contentType,
                this.activeItem.isComplete,
                this.activeViewStart,
                new Date(Date.now())).subscribe(res => {

                });
        }
    }

    areAllItemsComplete() {

        if (this.courseSyllabus == null)
            return false;

        return this.courseSyllabus.areAllItemsComplete();
    }

    getPercentComplete(): number {

        if (this.courseSyllabus == null)
            return 0;

        return this.courseSyllabus.getPercentComplete();
    }

    isFirstItem() {

        if (this.courseSyllabus == null) {
            return true;
        }
        return this.courseSyllabus.isFirstItemActive();
    }

    onNextItem() {
        if (!this.activeItemIsScormCourse) {
            this.courseSyllabus.onNextItem();
            this.currentVideoPlayed = false;
        }
        //this.nextPossibleIndex++;

    }

    onPrevItem() {

        this.courseSyllabus.onPrevItem();
        
  }

    sendValue() {



        this.courseSyllabus.changeValue($('#showknowlegesmart').prop('checked'));

    }

    completeCourse() {

        this.pinnacleService.completeEnrollment(this.enrollment.enrollmentId as string).subscribe(res => {
            if (this.samelpenrollments.length > 1) {
                this.pinnacleService.getLearningPathUserEnrollments([EnrollmentFilter_T.assigned, EnrollmentFilter_T.inProgress], this.enrollment.learningPathId as string).subscribe(res2 => {
                    this.samelpenrollmentsAfterComplete = res2;

                    location.href = "#/learningcenter/courseviewer?enrollmentId=" + this.samelpenrollmentsAfterComplete[0].enrollmentId;
                    location.reload();
                });

            }
            else {
                this.router.navigate(['/learningcenter']);
            }


        });
    }


    dict: any;

    tabChange(some_id) {

        this.dict = ["tab1", "tab2", "tab3", "tab4"];
        // remove any active classes so the underlined tab is not selected. also hide the previous sections if not selected
        for (var i = 0; i < this.dict.length; i++) {
            if (this.dict[i] != some_id) {
                document.getElementById(this.dict[i]).classList.remove("active");
                document.getElementById(this.dict[i] + "section").style.display = "none";
            }
        }

        // section shows the selected tab and their respective sections.
        var selected_tab = document.getElementById(some_id);
        var selected_tab_section = document.getElementById(some_id + "section");
        selected_tab.classList.add("active");
        selected_tab_section.style.display = "block";
    }

    navigateToCourse(newenrollmentId: string): void {

        if (this.videoContent != null && this.videoContent.vidPlaying == true) {
            this.allownavigation = confirm("Leave Video?");
        }
        if (this.allownavigation == true) {

            this.allownavigation = false;
            this.autoplayvidservice.SetAutoplayNext(false);
            this.autoplayvidservice.SetRunAutoplay(false);

            //$(".CourseButton").attr('style', 'background-color:#3D464E !important');
            //$(".CourseButton:hover").attr('style', 'background-color: #85ACB7 !important')
            //$("#CourseButton" + btnnum).attr('style', 'background-color:#6A8385 !important');
            this.recordCurrentActivity();




            if (newenrollmentId != "") {

                location.href = "#/learningcenter/courseviewer?enrollmentId=" + this.enrollmentId;




                this.pinnacleService.GetEnrollment(newenrollmentId).subscribe(res => {

                    this.enrollment = res;
                    if (this.enrollment.enrollmentId == ProdGenApi.EMPTY_GUID) {
                        this.noErrs = false;
                        this.friendlyMsg = this._sanitizer.bypassSecurityTrustHtml(this.translationService.getTranslationFileData("APP.AssetViewing0DRs"));
                        location.href = "#/learningcenter/series?courseId=" + this.enrollment.courseId;
                    }
                    else {
                        this.courseSyllabus.enrollment = this.enrollment;

                        this.courseSyllabus.loadTrackingItems();
                        // this.enrollment.isCustom = true; set is custom to true for testing
                        $('#CourseButton_' + this.enrollmentId).attr('style', 'display:normal;font-size:18px; font-weight:700;margin-top:5px;margin-bottom:5px;');
                        $('#courseSyllabus').attr('style', 'display:none');
                        $('#courseSyllabus').insertAfter('#CourseButton_' + newenrollmentId);
                        this.enrollmentId = newenrollmentId;
                        if (res.isSelfEnrolled == false) {
                            this.pinnacleService.getUser(res.assignerUserId).subscribe(u => this.assignedBy = u);
                        }


                        this.allownavigation = true;
                    }


                });

            }
        }
        if (AppComponent.thisFromOutside.Flyoutopen == true) {
            AppComponent.thisFromOutside.closeFlyout();
            AppComponent.thisFromOutside.Flyoutopen = false;
        }
    }

    loadLearningPath(lpId: String) {

        let tempArray = new Array<CourseWithContent>();
        this.coursesAndContent = new Array<CourseWithContent>();
        this.usedProducts = new Array<string>();
        // load up the learning path

        this.pinnacleService.GetLearningPath(lpId, DescriptionFilter_T.formatted).subscribe(lp => {
            this.pinnacleService.GetContentUsedProducts(lp.learningPathId, ContentType_T.learningpath.toString()).subscribe(products => {
                for (var i = 0; i < products.length; i++) {
                    this.products = products;
                    this.productVersions = this.products[i].Versions;

          if (this.productVersions.length > 0) {
            for (var j = 0; j < this.productVersions.length; j++) {
              this.usedProducts.push(this.products[i].Name + " " + this.productVersions[j].Name);
            }
          }
          else {
            this.usedProducts.push(this.products[i].Name.toString());
          }
        }
      });
        this.learningPath = lp;
      // now, get the courses
      $('#CourseButton_' + this.enrollmentId).attr('style', 'display:none');
      $('#courseSyllabus').insertAfter('#CourseButton_' + this.enrollmentId);
      this.pinnacleService.GetLearningPathCourses(lp.learningPathId, -1, 0, DescriptionFilter_T.none).subscribe(c => {
          this.courses = c;
        let courseIndex = 0;

                for (let course of this.courses) {
                    //// content items and description will be loaded on demand

                    // create a courses with content 
                    let stuff = new CourseWithContent();
                    stuff.courseId = course.courseId;
                    stuff.description = course.description;
                    stuff.learningPathId = course.learningPathId;
                    stuff.name = course.name;
                    stuff.providedBy = course.providedBy;
                    stuff.sequence = course.sequence;
                    stuff.version = course.version;
                    //stuff.content = content;

          tempArray.push(stuff);
          if (tempArray.length == this.courses.length) {
            // we got them all...sort by the seq
            tempArray.sort((r1, r2) => { return r1.sequence - r2.sequence });
          }
        }
        this.coursesAndContent = tempArray;
        });
        this.showButtons = true;
    }   , err => {
            //display friendly message to user so they are aware of what happened & have no access if they shouldn't
            this.noErrs = false;
            this.learningPath.name = this.translationService.getTranslationFileData("LEARNINGCENTERCOURSEVIEWER.TS_Error");

            if (err.status == 400) {
                //bad request caused by 0 data rows and/or no content ids for user - user has no access
                this.friendlyMsg = this._sanitizer.bypassSecurityTrustHtml(this.translationService.getTranslationFileData("APP.AssetViewing0DRs"));
            }
            else {
                //for now, let's give generic error as there are multiple errors possibly thrown by services (for which I added most common to translate file in case a specific one is wanted)
                this.friendlyMsg = this._sanitizer.bypassSecurityTrustHtml(this.translationService.getTranslationFileData("APP.AssetViewingError"));
            }

        }
    );
    }

    dropFailedCourse() {
        if (this.activeItem != null) {
            this.pinnacleService.DeleteCourseEnrollment(this.activeItem.enrollmentId).subscribe(res => {
                if (this.samelpenrollments.length > 1) {
                    this.pinnacleService.getLearningPathUserEnrollments([EnrollmentFilter_T.assigned, EnrollmentFilter_T.inProgress], this.enrollment.learningPathId as string).subscribe(res2 => {
                        this.samelpenrollmentsAfterComplete = res2;

                        location.href = "#/learningcenter/courseviewer?enrollmentId=" + this.samelpenrollmentsAfterComplete[0].enrollmentId;
                        location.reload();
                    });

                }
                else {
                    this.router.navigate(['/learningcenter']);
                }
            });
        }
    }

    enableNext(): boolean {
        //console.log("enable next? ", this.activeItem);
        if (
            this.showButtons
            && this.activeItem != null
            && this.activeItem.contentId != null
            && this.activeItem.contentType != ContentType_T.course
            &&  (this.activeItem.contentType != ContentType_T.quiz
                ||  ((this.activeItem.isComplete || !this.activeItem.ForceViewSequential)
                    && ((this.failedQuizzes.indexOf(this.activeItem.contentId.toString())==-1) || !this.activeItem.ForceViewSequential || !this.activeItem.requireQuizzesPassed)
                    && !((this.activeItem.quizStatus != "PASS" && this.activeItem.quizStatus != "") && this.activeItem.ForceViewSequential && this.activeItem.requireQuizzesPassed)
                    )
            )
            && (this.activeItem.contentType != ContentType_T.video
                || this.activeItem.isComplete || !this.activeItem.ForceViewSequential || this.currentVideoPlayed)
            && (this.activeItem.contentType != ContentType_T.scormcourse
                || !this.activeItem.scormRequireComplete || (this.scormContent != undefined && this.scormContent.checkCompleted())
                || !this.activeItem.scormRequireScore || (this.scormContent != undefined && this.scormContent.checkScore(this.activeItem.scormScoreToComplete))
                )
        ) {
            //if (this.activeItem.scormRequireComplete || this.activeItem.scormRequireScore) {
                //console.log("content type != scorm? ", this.activeItem.contentType != ContentType_T.scormcourse);
               // console.log("!requireComplete? ", !this.activeItem.scormRequireComplete);
                //if (this.scormContent != undefined)
                    //console.log("completed?", this.scormContent.checkCompleted());
                //console.log("!requireScore?", !this.activeItem.scormRequireScore);
                //if (this.scormContent != undefined)
                    //console.log("score?", this.scormContent.checkScore(this.activeItem.scormScoreToComplete));
            //}

            if (this.activeItem.contentType == ContentType_T.scormcourse
                && this.activeItem.isComplete) {
                this.activeItem.ForceViewSequential = false;
            }

            return true;
        }
        return false;
    }

    showDrop(): boolean {
        if (
            this.showButtons // enrollment tracking items are loaded
            && this.activeItem != null
            && this.activeItem.contentId != null
            && this.activeItem.contentType != ContentType_T.course // AND the active item is not the Finish Course item
            && this.activeItem.ForceViewSequential
            && (this.failedQuizzes.indexOf(this.activeItem.contentId.toString())!=-1)
        ) {
            return true;
        }
        return false;
    }

    resetFailedQuizzes() {
        this.failedQuizzes = new Array<string>();
        this.checkQuizzesStatus();
    }

    getAuthoringContent(c: string) {
        if (c != null) {
            this.pinnacleService.getContentAuthoringData(c, ContentType_T.learningpath.toString()).subscribe(d => {
                if (d != null && d.lastModBy != "") {
                    this.contentAuthoring.contentId = d.contentId;
                    this.contentAuthoring.lastModBy = d.lastModBy;
                    this.contentAuthoring.lastModDate = d.lastModDate;
                    this.contentAuthoring.lastModDateStr = d.lastModDateStr;
                }
            });
        }
        else {
            this.contentAuthoring = new ContentAuthoring();
        }
    }

    onResize(event) {

        setTimeout(() => {
            let itemtomove = document.getElementById("CourseSyllabus");
            if (AppComponent.thisFromOutside.innerWidth <= 992) {
                //itemtomove.style.padding = "15px";
                document.getElementById("genFlyout").appendChild(itemtomove);
            }
            else {
                
                document.getElementById("CourseSyllabusHome").appendChild(itemtomove);

            }
        }, 0);
    }

    startTracking: number = 0;
    endTracking: number = 0;
    fallbackTimer;
    onScormFinish(result: any) {
        if (result.begin) {
            this.startTracking = Date.now();
        }
        if (result.terminate) {
            this.endTracking = Date.now();
            this.activeItem.isComplete = true;
            this.pinnacleService.SetEnrollmentTracking(this.enrollment.enrollmentId as string,
                this.activeItem.contentId as string,
                ContentType_T.scormcourse,
                this.activeItem.isComplete,
                new Date(this.startTracking),
                new Date(this.endTracking)
            ).subscribe(res => {
                this.courseSyllabus.onNextItem();
                });
            this.startTracking = 0;
            this.endTracking = 0;
            return;
        }
        if (result.reset) {
            this.startTracking = Date.now();
            this.endTracking = 0;
            this.activeItem.isComplete = false;
        }
        if (result.destroyed) {
            this.endTracking = Date.now();
            if (result.completed) {
                // Disgusting hotfix because the syllabus was
                // advanced by a user click.
                let prevIndex = this.courseSyllabus.contentItems.findIndex(x => x.contentType == ContentType_T.scormcourse);
                this.courseSyllabus.contentItems[prevIndex].isComplete = true;
            }
            // Hotfix for race condition when scormviewer
            // is destroyed but was still waiting for a terminate
            // command from the package.
            this.fallbackTimer = setTimeout(() => this.onScormFinish({ recurse: true, writeTracking: true, completed: result.completed }), 1500);
        }
        if (result.continue) {
            this.startTracking = 0;
            this.endTracking = 0;
            this.courseSyllabus.onNextItem();
        }
        if (result.failForceClose) {
            this.startTracking = 0;
            this.endTracking = 0;
            this.activeItem.isComplete = false;
            this.courseSyllabus.onNextItem();
        }
        if (result.writeTracking
            && this.startTracking > 0
            && this.endTracking > 0) {
            if (this.endTracking < this.startTracking) {
                this.endTracking = this.startTracking;
            }

            clearTimeout(this.fallbackTimer);
            this.pinnacleService.SetEnrollmentTracking(this.enrollment.enrollmentId as string,
                this.activeItem.contentId as string,
                ContentType_T.scormcourse,
                result.completed,
                new Date(this.startTracking),
                new Date(this.endTracking)
            ).subscribe(res => {
                this.courseSyllabus.onNextItem();
            });
        }
    }
}
