import { Location } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
//import { ContentServiceService } from '../content-service.service';
import { AssetLibrary, AssetLibraryContent, AssetLibraryContentSummary, AssetLibraryProduct, AssetLibraryProductVersion } from '../apiService/classFiles/class.assetlibrary';
import { Enrollment, EnrollmentFilter_T } from '../apiService/classFiles/class.enrollments';
import { PermissionLevel_T, Workgroup } from '../apiService/classFiles/class.workgroups';
import { ProdGenApi } from '../apiService/prodgen.api';
import { AppComponent } from '../app.component';
import { BrowserAuthenticationService } from '../BrowserAuthenticationService';
import { SearchComponent } from '../search/search.component';
import { Content, ContentType_T } from './../apiService/classFiles/class.content';
import { Organization } from './../apiService/classFiles/class.organizations';

declare var $: any;

@Component({
    selector: 'app-product-listing',
    templateUrl: './product-listing.component.html',
    styleUrls: ['./product-listing.component.css']
})
export class ProductListingComponent implements OnInit, AfterViewInit {
    currentProduct: AssetLibraryProduct = new AssetLibraryProduct();
    currentAsset: AssetLibrary = new AssetLibrary();
    currentProductVersions: Array<AssetLibraryProductVersion> = new Array<AssetLibraryProductVersion>();
    selectedProductVersion: AssetLibraryProductVersion;
    versionPara: string;
    isProduct: boolean;
    isSubscription: boolean;
    selectedProductContent: AssetLibraryContentSummary = new AssetLibraryContentSummary();
    org: Organization = new Organization();
    currentHover: string = "";

    // variables used for filtering - we maintain a spearate list from the master that we can easily manipulate
    filterText: string = "";
    filteredLearningPaths: Object = {};
    filteredWorkflows: Object = {};
    filteredCheatSheets: Object = { };
    filteredVideos: Object = {};
    filteredCheatCount: number = 0;
    filteredVideoCount: number = 0;
    filteredWFCount: number = 0;
    filteredLPCount: number = 0;

    assetParameterListed: boolean = false
    AssetLibname: string = "";
    searchTerms: string = "";
    workflowImage: string = "";
    cheatImage: string = "";
    videoImage: string = "";
    learningPathImage: string = "";
    foundSavedVersionSelection: boolean = false;
    foundSavedPageState: boolean = false;

    dupeProdVers: Array<AssetLibraryProductVersion> = new Array<AssetLibraryProductVersion>();

    availibleWorkgroups: Array<Workgroup> = new Array<Workgroup>();

    isWFexpanded: boolean = true;
    isLPexpanded: boolean = true;
    isWFdetailexpanded: boolean = true;
    isLPdetailexpanded: boolean = true;
    isFinishedLoading: boolean = false;
    static g_thisComponent: any;
    constructedContent: Content = new Content();

    objectKeys = Object.keys;

    completedEnrollmentsForUser: Array<Enrollment> = new Array<Enrollment>();

    constructor(private pinnacleService: ProdGenApi,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: BrowserAuthenticationService,
        private location: Location,
        private changedetectorref: ChangeDetectorRef) {

        // set a global this ptr so we hav access from static callbacks
        ProductListingComponent.g_thisComponent = this;

        // Get default images to use
        let wfimg = ProdGenApi.getLargeDefaultImageFromType(ContentType_T.workflow);
        let cheatimg = ProdGenApi.getLargeDefaultImageFromType(ContentType_T.cheatsheet);
        let vidimg = ProdGenApi.getLargeDefaultImageFromType(ContentType_T.video);
        let lpimg = ProdGenApi.getLargeDefaultImageFromType(ContentType_T.learningpath);


        if (wfimg.indexOf("default") == -1) {
            this.workflowImage = wfimg;
        }
        else {
            this.workflowImage = "../../assets/images/" + wfimg;
        }

        if (cheatimg.indexOf("default") == -1) {
            this.cheatImage = cheatimg;
        }
        else {
            this.cheatImage = "../../assets/images/" + cheatimg;
        }

        if (vidimg.indexOf("default") == -1) {
            this.videoImage = vidimg;
        }
        else {
            this.videoImage = "../../assets/images/" + vidimg;
        }
        if (lpimg.indexOf("default") == -1) {
            this.learningPathImage = lpimg;
        }
        else {
            this.learningPathImage = "../../assets/images/" + lpimg;
        }


    }

    ngOnInit() {
        // set back to default values
        this.foundSavedVersionSelection = false;
        this.foundSavedPageState = false;


        if (this.authenticationService.AuthenticatePage() == true) {
            //need to grab the workgroups quick for the share buttons on the page
            this.pinnacleService.getCurrentUserWorkgroups([PermissionLevel_T.owner, PermissionLevel_T.editor]).subscribe(res => {
                this.availibleWorkgroups = res;
            });
            this.pinnacleService.GetCurrentOrganization().subscribe(resp => {
                this.org = resp;
            });


            // read the qury parameters necessary to know which data to load
            // note that whenever the query params change (same page navigation), the code in the subscribe will be 
            // re-called and therefore we will load the data.
            this.route.queryParams.subscribe(queryParams => {


                this.currentProduct.productId = queryParams['proid'];
                if (typeof this.currentProduct.productId == 'undefined') {
                    this.assetParameterListed = false;
                }
                else {
                    this.assetParameterListed = true;
                }

                this.currentProduct.assetLibraryId = queryParams['astid'];
                this.AssetLibname = queryParams['libname'];
                try {
                    this.isSubscription = queryParams['issubscription'] == "true";
                }
                catch (e) {
                    this.isSubscription = false;
                }

                this.currentProduct.name = queryParams['name'];
                if (this.currentProduct.name == null || this.currentProduct.name.length == 0) {
                    this.currentProduct.name = this.AssetLibname;
                }


                this.isProduct = queryParams['isprod'];
                if (queryParams['version']) {
                    this.versionPara = decodeURIComponent(queryParams['version']);
                }

                let basestring = this.currentProduct.productId;

                if (sessionStorage.getItem(basestring + 'learningpaths') != null ||
                    sessionStorage.getItem(basestring + 'workflows') != null ||
                    sessionStorage.getItem(basestring + 'documents') != null ||
                    sessionStorage.getItem(basestring + 'videos') != null ||
                    sessionStorage.getItem(this.currentProduct.productId + 'filters') != null) {

                    // set the flag that we have a saved page state
                    this.foundSavedPageState = true;
                }


                // attempt to get saved page settings
                if (sessionStorage.getItem(this.currentProduct.productId) != null) {
                    this.foundSavedVersionSelection = true;
                }


                // load the data for the current asset library/product/version
                this.pinnacleService.getCurrentUserEnrollments([EnrollmentFilter_T.completed]).subscribe(completedenrollres => {
                    this.completedEnrollmentsForUser = completedenrollres;
                    this.LoadData();
                });
                
            });

        }
    }

    ngAfterViewInit() {
        // set back to default values
        this.foundSavedVersionSelection = false;
        this.foundSavedPageState = false;

    }

    ChangeVersion(clickedVersion: AssetLibraryProductVersion): void {

        this.selectedProductContent = new AssetLibraryContentSummary();
        this.selectedProductContent.cheatSheets = new Array<AssetLibraryContent>();
        this.selectedProductContent.learningPaths = new Array<AssetLibraryContent>();
        this.selectedProductContent.videos = new Array<AssetLibraryContent>();
        this.selectedProductContent.workfows = new Array<AssetLibraryContent>();

        this.filteredLearningPaths = {};
        this.filteredWorkflows = {};
        this.filteredCheatSheets = {}
        this.filteredVideos = {};

        this.selectedProductVersion = clickedVersion;
        sessionStorage.setItem(this.currentProduct.productId, this.selectedProductVersion.versionName);
        this.GetProductContent(this.selectedProductVersion);

        var sanitizedVersionName = encodeURIComponent(this.selectedProductVersion.versionName);
        this.location.replaceState(this.updateURLParameter(window.location.href, 'version', sanitizedVersionName).split('#')[1]);
    }

    updateURLParameter(url, param, paramVal) {
        var newAdditionalURL = "";
        var tempArray = url.split("?");
        var baseURL = tempArray[0];
        var additionalURL = tempArray[1];
        var temp = "";
        if (additionalURL) {
            tempArray = additionalURL.split("&");
            for (var i = 0; i < tempArray.length; i++) {
                if (tempArray[i].split('=')[0] != param) {
                    newAdditionalURL += temp + tempArray[i];
                    temp = "&";
                }
            }
        }

        var rows_txt = temp + "" + param + "=" + paramVal;
        return baseURL + "?" + newAdditionalURL + rows_txt;
    }

    InitialVersionSet(defaultVersion: Array<AssetLibraryProductVersion>): AssetLibraryProductVersion {
        return defaultVersion[0];
    }

    get isPinnacleLite() {
        return AppComponent.isPinnacleLite;
    }

    GetProductContent(productVersion: AssetLibraryProductVersion) {
        this.isFinishedLoading = false;

        if (productVersion == null) {
            productVersion = new AssetLibraryProductVersion();
            productVersion.versionId = "00000000-0000-0000-0000-000000000000";
            productVersion.productId = this.currentProduct.productId;
            productVersion.assetLibraryId = this.currentProduct.assetLibraryId;
            productVersion.productName = this.currentProduct.name;
        }

        this.selectedProductContent.cheatSheets = new Array<AssetLibraryContent>();
        this.selectedProductContent.learningPaths = new Array<AssetLibraryContent>();
        this.selectedProductContent.videos = new Array<AssetLibraryContent>();
        this.selectedProductContent.workfows = new Array<AssetLibraryContent>();

        // get the assets for the main product
        this.pinnacleService.getAssetLibraryAssetSummary(productVersion.assetLibraryId, productVersion.productId, productVersion.versionId).subscribe(r => {
            // add the results into our component arrays
            this.selectedProductContent.cheatSheets = this.addUniqueContentToArray(this.selectedProductContent.cheatSheets, r.cheatSheets);
            this.selectedProductContent.learningPaths = this.addUniqueContentToArray(this.selectedProductContent.learningPaths, r.learningPaths);
            this.selectedProductContent.videos = this.addUniqueContentToArray(this.selectedProductContent.videos, r.videos);
            this.selectedProductContent.workfows = this.addUniqueContentToArray(this.selectedProductContent.workfows, r.workfows);

            //go through any dupe prods with the same name as the current prod, gets its content, and append it to the existing content.
            if (this.dupeProdVers.length > 0) {
                let retrieveCount = 0;
                let matchCount = 0;
                // pre-count the matches we should have
                for (var i = 0; i < this.dupeProdVers.length; i++) {
                    if (this.dupeProdVers[i].versionName == productVersion.versionName) {
                        matchCount++;
                    }
                }


                for (var i = 0; i < this.dupeProdVers.length; i++) {
                    if (this.dupeProdVers[i].versionName == productVersion.versionName) {
                        this.pinnacleService.getAssetLibraryAssetSummary(this.dupeProdVers[i].assetLibraryId, this.dupeProdVers[i].productId, this.dupeProdVers[i].versionId).subscribe(s => {
                            retrieveCount++;

                            // add the results into our component arrays
                            this.selectedProductContent.cheatSheets = this.addUniqueContentToArray(this.selectedProductContent.cheatSheets, s.cheatSheets);
                            this.selectedProductContent.learningPaths = this.addUniqueContentToArray(this.selectedProductContent.learningPaths, s.learningPaths);
                            this.selectedProductContent.videos = this.addUniqueContentToArray(this.selectedProductContent.videos, s.videos);
                            this.selectedProductContent.workfows = this.addUniqueContentToArray(this.selectedProductContent.workfows, s.workfows);


                            if (retrieveCount == matchCount) {
                                this.getAllClonedAssets();
                                this.SetColumnData();
                                this.filterText = "";
                                this.isFinishedLoading = true;
                            }
                        });
                    }
                }
                if (matchCount == 0) {
                    this.getAllClonedAssets();
                    this.SetColumnData();
                    this.filterText = "";
                    this.isFinishedLoading = true;
                }

            }
            else {
                this.getAllClonedAssets();
                this.SetColumnData();
                this.filterText = "";
                this.isFinishedLoading = true;
            }
        });
    }


    getAllClonedAssets() {
        this.getClonedAssets("LP");
        this.getClonedAssets("WF");
        this.getClonedAssets("CS");
        this.getClonedAssets("V");
    }

    addUniqueContentToArray(baseArray: Array<AssetLibraryContent>, toAdd: Array<AssetLibraryContent>): Array<AssetLibraryContent> {
        for (var j = 0; j < toAdd.length; j++) {
            if (baseArray.findIndex(x => x.contentId == toAdd[j].contentId) == -1) {
                baseArray.push(toAdd[j]);
            }
        }
        return baseArray.sort(this.sortContent);
    }


    sortContent(a: any, b: any): number {
        if (isNaN(parseInt(b.name)) == false && isNaN(parseInt(a.name)) == false) {
            if (parseInt(b.name) > parseInt(a.name)) {
                return -1;
            }
            else {
                return 1;
            }
        }

        if (b.providedBy > a.providedBy) {
            return -1;
        }
        else if (b.name > a.name) {
            return -1;
        }
        return 1;
    }

    ngOnDestroy() {
    }

    
    SetColumnData() {
        Object.keys(this.filteredLearningPaths).forEach(key => {
            this.setColumnIndicator((this.filteredLearningPaths[key] as OwnerParent).content);
        });

        Object.keys(this.filteredWorkflows).forEach(key => {
            this.setColumnIndicator((this.filteredWorkflows[key] as OwnerParent).content);
        });
        Object.keys(this.filteredCheatSheets).forEach(key => {
            this.setColumnIndicator((this.filteredCheatSheets[key] as OwnerParent).content);
        });
        Object.keys(this.filteredVideos).forEach(key => {
            this.setColumnIndicator((this.filteredVideos[key] as OwnerParent).content);
        });
    }

    setColumnIndicator(content: Array<AssetLibraryContent>) {
        // get the total count of items including children
        let count = 0;
        for (let c of content) {
            count++;
            if (c.children != null) {
                count += c.children.length;
            }
        }

        let half = count / 2;
        let column = 0;
        count = 0;
        // now that we know the total, try to find as close as we can to half way point
        for (let c of content) {
            count++;
            c.column = column;

            if (c.children != null) {
                count += c.children.length;
            }
            if (count > half) {
                column = 1;
            }

        }
    }


    getClonedAssets(type: string) {
        if (type == "LP") {
            this.filteredLPCount = 0;
            this.filteredLearningPaths = {};


            //let LPcontent: Array<OwnerParent> = new Array<OwnerParent>();
            for (let c of this.selectedProductContent.learningPaths) {
                let newLP: AssetLibraryContent = new AssetLibraryContent();

                newLP.contentId = c.contentId;
                newLP.name = c.name;
                newLP.imageUrl = c.imageUrl;
                newLP.children = new Array<AssetLibraryContent>();
                let completedcount = 0;
                for (let lpChild of c.children) {
                    let lpc = new AssetLibraryContent(); 
                    lpc.children = lpChild.children;
                    lpc.column = lpChild.column;
                    lpc.contentId = lpChild.contentId;
                    lpc.imageUrl = lpChild.imageUrl;
                    lpc.name = lpChild.name;
                    lpc.providedBy = lpChild.providedBy;

                    let itemindex = -1;
                    if (this.completedEnrollmentsForUser.findIndex(x => x.learningPathId == lpChild.contentId) != -1) {
                        itemindex = this.completedEnrollmentsForUser.findIndex(x => x.learningPathId == lpChild.contentId);
                    }
                    else if (this.completedEnrollmentsForUser.findIndex(x => x.courseId == lpChild.contentId) != -1) {
                        itemindex = this.completedEnrollmentsForUser.findIndex(x => x.courseId == lpChild.contentId);
                    }
                    
                    if (itemindex != -1) {
                        lpc.isCompleted = true;
                        completedcount++;
                        const options = { year: 'numeric', month: 'long', day: 'numeric' };
                        let date = new Date(this.completedEnrollmentsForUser[itemindex].statusDate);
                        lpc.completedDate = date.toLocaleDateString(undefined, options);
                    } 

                    newLP.children.push(lpc);
                }

                if (completedcount == c.children.length) {//if all courses are completed also mark the learning path completed
                    newLP.isCompleted = true;   
                    const options = { year: 'numeric', month: 'long', day: 'numeric' };
                    let date = new Date(Math.max.apply(null, newLP.children.map((e) => {
                        return new Date(e.completedDate);
                    })));
                    newLP.completedDate = date.toLocaleDateString(undefined, options);
                }

                //LPcontent.push(newLP);

                if (this.filteredLearningPaths[c.providedBy] == null) {
                    let v_Owner: OwnerParent = new OwnerParent();
                    v_Owner.content = new Array<AssetLibraryContent>();
                    v_Owner.name = c.providedBy;

                    this.filteredLearningPaths[c.providedBy] = v_Owner;
                }


                if ((this.filteredLearningPaths[c.providedBy] as OwnerParent).content.find(o => o.contentId === newLP.contentId) == null) {
                    (this.filteredLearningPaths[c.providedBy] as OwnerParent).content.push(newLP);
                    this.filteredLPCount++;
                }


            }

            // determine correct column - do this since it is a nested resource

           
            //return LPcontent;
        }
        else if (type == "WF") {
            this.filteredWFCount = 0;
            this.filteredWorkflows = {};

            //let WFcontent: Array<AssetLibraryContent> = new Array<AssetLibraryContent>();
            for (let c of this.selectedProductContent.workfows) {
                let newWF: AssetLibraryContent = new AssetLibraryContent();

                newWF.contentId = c.contentId;
                newWF.name = c.name;
                newWF.imageUrl = c.imageUrl;
                newWF.children = new Array<AssetLibraryContent>();
                for (let wfChild of c.children) {
                    newWF.children.push(wfChild);
                }

                if (this.filteredWorkflows[c.providedBy] == null) {
                    let v_Owner: OwnerParent = new OwnerParent();
                    v_Owner.content = new Array<AssetLibraryContent>();
                    v_Owner.name = c.providedBy;

                    this.filteredWorkflows[c.providedBy] = v_Owner;
                }

                (this.filteredWorkflows[c.providedBy] as OwnerParent).content.push(newWF);
                this.filteredWFCount++;

                

               // WFcontent.push(newWF);
            }

            //return WFcontent;
        }
        else if (type == "CS") {
            this.filteredCheatCount = 0;
            //let CScontent: Array<AssetLibraryContent> = new Array<AssetLibraryContent>();

            this.filteredCheatSheets = {};

            for (let c of this.selectedProductContent.cheatSheets) {
                //CScontent.push(c);

                if (this.filteredCheatSheets[c.providedBy] == null) {
                    let v_Owner: OwnerParent = new OwnerParent();
                    v_Owner.content = new Array<AssetLibraryContent>();
                    v_Owner.name = c.providedBy;

                    this.filteredCheatSheets[c.providedBy] = v_Owner;
                }

                (this.filteredCheatSheets[c.providedBy] as OwnerParent).content.push(c);
                this.filteredCheatCount++;

            }


            //return CScontent;
        }
        else if (type == "V") {
            this.filteredVideoCount = 0;
            this.filteredVideos = {};
            //let Vcontent: Array<AssetLibraryContent> = new Array<AssetLibraryContent>();

            for (let c of this.selectedProductContent.videos) {
                //Vcontent.push(c);

                if (this.filteredVideos[c.providedBy] == null) {
                    let v_Owner: OwnerParent = new OwnerParent();
                    v_Owner.content = new Array<AssetLibraryContent>();
                    v_Owner.name = c.providedBy;

                    this.filteredVideos[c.providedBy] = v_Owner;
                }

                //if (this.filteredVideos[i].name.toLowerCase().indexOf(splitFilterText[d].toLowerCase()) == -1) {)

                (this.filteredVideos[c.providedBy] as OwnerParent).content.push(c);
                this.filteredVideoCount++;
            }


            //return Vcontent;
        }

    }

    filterTextChanged() {
        sessionStorage.setItem(this.currentProduct.productId + 'filters', this.filterText);

        this.getAllClonedAssets();

        var splitFilterText = this.filterText.split(" ");

        for (var d = 0; d < splitFilterText.length; d++) {

            if (splitFilterText[d].trim().length > 0) {

                this.filteredLPCount = 0;

                Object.keys(this.filteredLearningPaths).forEach(key => {

                    for (var i = 0; i < this.filteredLearningPaths[key].content.length; i++) {
                        for (var n = 0; n < this.filteredLearningPaths[key].content[i].children.length; n++) {
                            if (this.filteredLearningPaths[key].content[i].children[n].name.toLowerCase().indexOf(splitFilterText[d].toLowerCase()) == -1) {
                                this.filteredLearningPaths[key].content[i].children.splice(n, 1);
                                n--;
                            }
                        }
                        if (this.filteredLearningPaths[key].content[i].children.length == 0) {
                            if (this.filteredLearningPaths[key].content[i].name.toLowerCase().indexOf(splitFilterText[d].toLowerCase()) == -1) {
                                this.filteredLearningPaths[key].content.splice(i, 1);
                                i--;
                            }
                        }
                    }

                    this.filteredLPCount += this.filteredLearningPaths[key].content.length;


                });


                
            }

            Object.keys(this.filteredLearningPaths).forEach(key => {
                this.setColumnIndicator(this.filteredLearningPaths[key].content);
            });

            if (splitFilterText[d].trim().length > 0) {

                this.filteredWFCount = 0;

                Object.keys(this.filteredWorkflows).forEach(key => {

                    for (var i = 0; i < this.filteredWorkflows[key].content.length; i++) {
                        for (var n = 0; n < this.filteredWorkflows[key].content[i].children.length; n++) {
                            if (this.filteredWorkflows[key].content[i].children[n].name.toLowerCase().indexOf(splitFilterText[d].toLowerCase()) == -1) {
                                this.filteredWorkflows[key].content[i].children.splice(n, 1);
                                n--;
                            }
                        }
                        if (this.filteredWorkflows[key].content[i].children.length == 0) {
                            if (this.filteredWorkflows[key].content[i].name.toLowerCase().indexOf(splitFilterText[d].toLowerCase()) == -1) {
                                this.filteredWorkflows[key].content.splice(i, 1);
                                i--;
                            }
                        }
                    }

                    this.filteredWFCount += this.filteredWorkflows[key].content.length;

                });

            }

            Object.keys(this.filteredWorkflows).forEach(key => {
                this.setColumnIndicator(this.filteredWorkflows[key].content);
            });



            if (splitFilterText[d].trim().length > 0) {
                this.filteredCheatCount = 0;

                Object.keys(this.filteredCheatSheets).forEach(key => {

                    

                    for (var i = 0; i < this.filteredCheatSheets[key].content.length; i++) {
                        if (this.filteredCheatSheets[key].content[i].name.toLowerCase().indexOf(splitFilterText[d].toLowerCase()) == -1) {
                            this.filteredCheatSheets[key].content.splice(i, 1);
                            i--;
                        }
                    }

                    this.filteredCheatCount += this.filteredCheatSheets[key].content.length;

                });
            }



            if (splitFilterText[d].trim().length > 0) {

                this.filteredVideoCount = 0;

                Object.keys(this.filteredVideos).forEach(key => {
                    for (var i = 0; i < this.filteredVideos[key].content.length; i++) {
                        if (this.filteredVideos[key].content[i].name.toLowerCase().indexOf(splitFilterText[d].toLowerCase()) == -1) {
                            this.filteredVideos[key].content.splice(i, 1);
                            i--;
                        }
                    }

                    this.filteredVideoCount += this.filteredVideos[key].content.length;
                });
            }

        }
    }

    onSearch(): void {
        let url: string = "";
        if (this.searchTerms.trim().length > 0) {
            url += "search?searchTerms=" + SearchComponent.encodeSearchParameters(this.searchTerms.trim()) + "&category=" + this.currentProduct.name;
            this.router.navigateByUrl(url);
        }
    }

    onKeySearchPress(event: any) {
        if (event.keyCode == 13) {
            this.onSearch();
        }
    }
    onKeySearchInput(event: any) {
        if (event.data == null && event.composed != null && event.composed == false) {
            this.onSearch();
        }
    }

    LoadData() {
        // clear all existing info from our arrays
        this.ClearVars();


        window.scroll(0, 0);

        // isProduct is currently always set to true. We are leaving it as a flag for future use.
        if (this.isProduct) {
            let isSubscription = this.isSubscription;
            this.isFinishedLoading = false;

            //look at session storage. If we have removed an asset lib with the same name as the one we are on
            //  we need to look through any removed products for that library and load versions and content for them as well.

            let assetLibrariesMerged = new Array<AssetLibrary>();
            let assetLibraryProdsMerged = new Array<AssetLibraryProduct>();
            let mergeInfo = new Array<MergedAssetLibraryInfo>();

            assetLibrariesMerged = JSON.parse(sessionStorage.getItem("AssetLibsRemoved"));
            assetLibraryProdsMerged = JSON.parse(sessionStorage.getItem("duplicateProds"));

            // between the asset libraries merged and products merged, lets widdle this list to only the stuff we care about
            // then we will retrieve the version info and load the content

            // all the prods to merge come from asset libraries merged

            // really what we care about are the products named the same that are in an asset library named the same as the current
            if (assetLibraryProdsMerged != undefined) {
                for (let p of assetLibraryProdsMerged) {
                    if (p.name == this.currentProduct.name) {
                        // we might care about this. the product is named the same, now we should check the name of asset library
                        let libIndex = assetLibrariesMerged.findIndex(i => i.assetLibraryId == p.assetLibraryId);
                        if (libIndex != -1) {
                            if (assetLibrariesMerged[libIndex].name == this.AssetLibname) {
                                // ok, this is a match so lets keep track of this
                                let merge = new MergedAssetLibraryInfo();
                                merge.assetLibId = p.assetLibraryId;
                                merge.assetLibName = this.AssetLibname;
                                merge.productId = p.productId;
                                merge.productName = p.name;

                                mergeInfo.push(merge);
                            }
                        }
                    }
                }
            }

            // now we have merged info specifically for the current product


            // get version information for the asset library product we clicked on. If the product is duplicated due to merging of asset libraries, we will handle below
            this.pinnacleService.getAssetLibraryProductVersion(this.currentProduct.assetLibraryId, this.currentProduct.productId).subscribe(r => {

                this.currentProductVersions = r;

                if (mergeInfo.length == 0) {
                    // this product was not duplicated in multiple asset libraries
                    if (this.currentProductVersions.length == 0) {
                        this.selectedProductVersion = null;
                    }
                    else {
                        // there were not any duplicated asset libraries
                        this.AttemptRestoreSavedVersionSelection();
                    }
                    this.currentProductVersions = this.currentProductVersions.sort(this.sortVersion);
                    // get the content now that version stuff in in order
                    this.GetProductContent(this.selectedProductVersion);
                }
                else {
                    this.currentProductVersions = this.currentProductVersions.sort(this.sortVersion);
                    let prodMatchVersionsRetrieved = 0;

                    // this product was duplicated in multiple asset libraries
                    for (var a = 0; a < mergeInfo.length; a++) {

                        // store these vars outside the array so we can use them when we return async.
                        let mergedAssetLibId = mergeInfo[a].assetLibId;
                        let mergedProductName = mergeInfo[a].productName;
                        let mergedProductId = mergeInfo[a].productId;

                        //now we can get the versions for this specific product and append them to the currentProductVersions array
                        this.pinnacleService.getAssetLibraryProductVersion(mergeInfo[a].assetLibId, mergeInfo[a].productId).subscribe(s => {
                            prodMatchVersionsRetrieved++;


                            // we are a merged asset library with matching product
                            // however, neither product has any versions...therefore our dupProdVers will be empty (problem)
                            if (s.length == 0 && r.length == 0) {
                                let fakeDupe = new AssetLibraryProductVersion();
                                fakeDupe.assetLibraryId = mergedAssetLibId;
                                fakeDupe.name = null;
                                fakeDupe.versionId = "00000000-0000-0000-0000-000000000000";
                                fakeDupe.productName = mergedProductName;
                                fakeDupe.productId = mergedProductId;
                                this.dupeProdVers.push(fakeDupe);
                                
                            }
                            for (var i = 0; i < s.length; i++) {// make sure not to add the version if one with the same name already exist. put the skipped version into a temp var
                                let binpush = 0;
                                for (var j = 0; j < this.currentProductVersions.length; j++) {
                                    if (this.currentProductVersions[j].versionName == s[i].versionName) {
                                        binpush = binpush + 1;
                                    }
                                }

                                if (binpush == 0) {
                                    this.currentProductVersions.push(s[i]);
                                }
                                else {//add skipped version to array
                                    if (this.dupeProdVers.findIndex(x => x.productId == s[i].productId && x.versionId == s[i].versionId) == -1) {
                                        this.dupeProdVers.push(s[i]);
                                    }
                                }

                                // check if we are on the last version of the current product iteration
                                if (i == (s.length - 1)) {
                                    // sort the versions
                                    this.currentProductVersions = this.currentProductVersions.sort(this.sortVersion);
                                }

                            }

                            if (prodMatchVersionsRetrieved == mergeInfo.length) {
                                // if this is the last one to retrieve, get the content
                                if (this.currentProductVersions.length == 0) {
                                    this.selectedProductVersion = null;
                                }
                                else {
                                    // there were not any duplicated asset libraries
                                    this.AttemptRestoreSavedVersionSelection();
                                }
                                // get the content now that version stuff in in order
                                this.GetProductContent(this.selectedProductVersion);

                            }
                        }); // end nested get version subscribe
                    }
                }
            });// end outer get version subscribe
        }
    }
               

    sortVersion(a: any, b: any):number {

        if (ProductListingComponent.g_thisComponent == null)
            return 0;

        // Try to sort by seq number first. Public assets
        // will all have seq number == 0, so will still
        // sort by name after checking.
        if (ProductListingComponent.g_thisComponent.shouldSortBySequence(ProductListingComponent.g_thisComponent.isSubscription, a, b) == true) {
            if (a.seq < b.seq) { return -1; }
            if (a.seq > b.seq) { return 1; }
        }

        let x = a.versionName;
        let y = b.versionName;
        if (x < y) { return 1; }
        if (x > y) { return -1; }

        return 0;
    }

    shouldSortBySequence(isSubscriptionLibrary:boolean, a: any, b: any): boolean {
        // for subscription asset libraries, weher the versions are numbers, we do not want to use the sequence, 
        // but rather the name. This is due to combining libraries with the same products but different version orders being declared
        // by different partners
        if ( isSubscriptionLibrary as boolean == true) {
            let str_a = a.versionName;
            let str_b = b.versionName;
            if (parseInt(str_a) != NaN && parseInt(str_b) != NaN) {
                return false;
            }
        }
        return true;
    }

    ClearVars() {
        this.selectedProductVersion = null;

        this.selectedProductContent = new AssetLibraryContentSummary();
        this.selectedProductContent.cheatSheets = new Array<AssetLibraryContent>();
        this.selectedProductContent.learningPaths = new Array<AssetLibraryContent>();
        this.selectedProductContent.videos = new Array<AssetLibraryContent>();
        this.selectedProductContent.workfows = new Array<AssetLibraryContent>();

        this.filteredLearningPaths = new Array<AssetLibraryContent>();
        this.filteredWorkflows = new Array<AssetLibraryContent>();
        this.filteredCheatSheets = new Array<AssetLibraryContent>();
        this.filteredVideos = new Array<AssetLibraryContent>();

        this.dupeProdVers = new Array<AssetLibraryProductVersion>();
        this.currentProductVersions = new Array<AssetLibraryProductVersion>();
    }

    /* The version selection is stored in sessionStorage on click. When the page
     * reloads, check if a selection has been saved and apply it if so. Used to
     * partially satisfy user story 316. */
    AttemptRestoreSavedVersionSelection() {
        if (this.foundSavedVersionSelection) {
            for (let i = 0; i < this.currentProductVersions.length; i++) {
                if (this.currentProductVersions[i].versionName == sessionStorage.getItem(this.currentProduct.productId)) {
                    this.selectedProductVersion = this.currentProductVersions[i];
                }
            }
            if (this.selectedProductVersion == null) {
                this.selectedProductVersion = this.InitialVersionSet(this.currentProductVersions);
            }
        }
        else {
            // No saved version selection, choose the default version
            this.selectedProductVersion = this.InitialVersionSet(this.currentProductVersions);
        }

        if (this.versionPara != null && this.currentProductVersions.length > 0) {
            for (let i = 0; i < this.currentProductVersions.length; i++) {

                if (this.currentProductVersions[i].versionName == this.versionPara) {
                    this.ChangeVersion(this.currentProductVersions[i]);
                    break;
                }
            }

        }        
    }

    /* Event handler to save page state on detail expand/collapse. Used to
     * partially satisfy user story 316. */
    ChangePageState(section: string, action: string) {
        if (action == 'expanded' || action == 'collapsed') {
            sessionStorage.setItem(this.currentProduct.productId + section, action);
        }
    }

    /* Method called after DOM loads all angular components, changes accordion
     * statuses to match any saved states. Because the detail sections use the
     * bootstrap data-toggle function, hooking into the elements is not
     * preferable. Instead, fetch the icons presented to the user and call
     * their click handlers. Additionally, handle re-adding the filter query
     * if one exists. Used to partially satisfy user story 316. */
    ExpandDetails(): void {
        if (this.foundSavedPageState) {
            this.foundSavedPageState = false;
            this.changedetectorref.detectChanges();
            let basestring = this.currentProduct.productId;
            if (sessionStorage.getItem(basestring + 'learningpaths') == 'expanded' && document.getElementById('expandiconPlusLP') != null) {
                document.getElementById('expandiconPlusLP').click();
            }
            if (sessionStorage.getItem(basestring + 'workflows') == 'expanded' && document.getElementById('expandiconPlusWF') != null) {
                document.getElementById('expandiconPlusWF').click();
            }
            if (sessionStorage.getItem(basestring + 'documents') == 'expanded' && document.getElementById('expandiconPlusCS') != null) {
                document.getElementById('expandiconPlusCS').click();
            }
            if (sessionStorage.getItem(basestring + 'videos') == 'expanded' && document.getElementById('expandiconPlusV') != null) {
                document.getElementById('expandiconPlusV').click();
            }
            if (sessionStorage.getItem(basestring + 'filters') != null) {
                this.filterText = sessionStorage.getItem(basestring + 'filters');
                this.filterTextChanged();
            }
        }
    }

    public toggle(obj: any) {
        if (obj.target.tagName == "H5") {
            obj.target.classList.toggle("fa-plus");
            obj.target.classList.toggle("fa-minus");
        }
        else {
            obj.target.parentNode.classList.toggle("fa-plus");
            obj.target.parentNode.classList.toggle("fa-minus");
        }
    }

    onReloadAssetLibrary() {
        this.pinnacleService.invlidateCacheStringContaining("assetLibraries/");
        this.LoadData();
    }

    buildItem(itemID: string, itemName: string, itemType: string): Content {
        let constructedContent = new Content();
        constructedContent.contentId = itemID;
        constructedContent.name = itemName;
        switch (itemType) {
            case "cs":
                constructedContent.contentType = ContentType_T.cheatsheet;
                break;
            case "wf":
                constructedContent.contentType = ContentType_T.workflow;
                break;
            case "vid":
                constructedContent.contentType = ContentType_T.video;
                break;
            case "lp":
                constructedContent.contentType = ContentType_T.learningpath;
                break;
            case "cr":
                constructedContent.contentType = ContentType_T.course;
                break;
            case "pr":
                constructedContent.contentType = ContentType_T.process;
                break;
            default:
                constructedContent.contentType = ContentType_T.cheatsheet;
                break;
        }
        return constructedContent;
    }

    hoverElem(contentId: string, itemName: string, itemType: string) {


        this.constructedContent = this.buildItem(contentId, itemName, itemType);
        this.currentHover = contentId;

        
    }

}

export class OwnerParent{
    public name: string;

    public content: Array<AssetLibraryContent>;

}

export class MergedAssetLibraryInfo {
    assetLibName: string;
    assetLibId: string;
    productId: string;
    productName: string;

}
