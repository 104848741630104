import { Injectable } from '@angular/core';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Content, ContentType_T } from './../../apiService/classFiles/class.content';
import { WorkgroupExternalMembers, WorkgroupExternalGroup } from './../../apiService/classFiles/class.workgroups';
import { ProdGenApi } from './../../apiService/prodgen.api';
import { Workgroup } from './../../apiService/classFiles/class.workgroups';

declare var $: any;

@Component({
    selector: 'workgroupselfinvite',
    templateUrl: './workgroup-selfinvite.component.html', 
    styleUrls: ['./workgroup-selfinvite.component.css'],
    providers: [ProdGenApi]
})
export class WorkgroupSelfInviteComponent {
    @Input('workgroupId') workgroupId: string;
    @Input('redirect') redirect: boolean;

    groupList: Array<Workgroup> = new Array<Workgroup>();
    

    constructor(private service: ProdGenApi) {

    }

    ngOnChanges(changes: SimpleChanges) {
        try {
            if (changes['workgroupId'] != null) {
                this.workgroupId = changes['workgroupId'].currentValue;

                this.loadData();
            }
        } catch (err) {
            console.log(err);
        };
    }

    loadData() {


        

    }
}