import { Injectable, SecurityContext, ViewChild, ElementRef, ViewChildren, QueryList  } from '@angular/core';
import { Router } from '@angular/router';
import { Component, OnInit, Input, OnChanges, SimpleChanges, SimpleChange, Output, EventEmitter } from '@angular/core';
import { IndexType_T, Workgroup, WorkgroupMember, WorkgroupRole, PermissionLevel_T, WorkgroupContentIndex } from '../../apiService/classFiles/class.workgroups';
import { Content, ContentType_T, DescriptionFilter_T } from '../../apiService/classFiles/class.content';
import { ProdGenApi } from './../../apiService/prodgen.api';
import { DomSanitizer  } from '@angular/platform-browser';
import { TranslationService } from './../../services/TranslationService';
import { EnrollUsersContentComponent } from '../enrolluserscontent/enrolluserscontent.component';

declare var $: any;

@Component({
  selector: 'template-workgroup-asset-view',
    templateUrl: './workgroup-asset-view.component.html',
    styleUrls: ['./workgroup-asset-view.component.css'],
  providers: [ProdGenApi]
})
export class WorkgroupAssetViewComponent implements OnInit {

    @Input('workgroup') workgroup: Workgroup;
    @Input('workgroupPermissionsLevel') currentUserPermissionsLevel: string;
    isAssetEditMode: boolean = false;
    isDefault: boolean = true;
    filterText: string = "";
    userSelectedValue: string = "";
    dateTimeAddedMap: { [contentID: string]: Date } = {};

    workgroupMembers: Array<WorkgroupMember>;

    filteredAssets: Array<Content> = new Array<Content>();
    filteredLearningPaths: Array<Content> = new Array<Content>();
    filteredWorkflows: Array<Content> = new Array<Content>();
    filteredCheatSheets: Array<Content> = new Array<Content>();
    filteredVideos: Array<Content> = new Array<Content>();

    currentAssets: Array<Content> = new Array<Content>();
    assestsLoaded: boolean = false;
    indicesPreLoaded: boolean = false;
    indicesLoaded: boolean = false;
    maxIndex: string;
    highlightedID: string = "";
    assetHeight: number;
    contentFiltered: boolean = false;
    contentIndices: Array<WorkgroupContentIndex> = new Array<WorkgroupContentIndex>();
    wgcontentIDMap: { [contentID: string]: String } = {};

    indexType: IndexType_T = IndexType_T.defaultIndex;
    noEditMode: boolean = false;

    @ViewChildren('assetContainer') assetsRef: QueryList<ElementRef>;

    userType: string = "Members";
    contentObj: Array<Content> = new Array<Content>();
    users = [];
    content = [];

    constructor(private pinnacleService: ProdGenApi, private router: Router, private sanitizer: DomSanitizer, private translationService: TranslationService) {
  }

    ngOnInit() {
        scroll(0, 0);
        this.userSelectedSortOption();
        this.loadWorkgroupData(this.workgroup);

    }

    ngOnChanges(changes: SimpleChanges) {
        try {
            try {
                if (changes.currentUserPermissionsLevel.previousValue != changes.currentUserPermissionsLevel.currentValue ) {

                    if (this.currentUserPermissionsLevel == "owner" || this.currentUserPermissionsLevel == "editor") { 
                        this.setEnrollmentData(); 
                    }
                }
                
            } catch (err) { };
            try {
                this.isAssetEditMode = false;
                this.filterText = "";

                this.workgroup = changes['workgroup'].currentValue;
                this.assestsLoaded = false;
                this.loadWorkgroupData(this.workgroup);
            } catch (err) { };

        } catch (err) { };

    }

    setIndexType() {
        if (this.userSelectedValue == "Frequently Used") {
            this.indexType = IndexType_T.frequentlyUsedIndex;
            this.noEditMode = true;
        }
        else {
            this.indexType = IndexType_T.defaultIndex;
            if (this.userSelectedValue == "Default") {
                this.noEditMode = false;
            }
            else {
                this.noEditMode = true;
            }
            
        }
    }

    userSelectedSortOption() {
        var value = sessionStorage.getItem("workGroupSortBy");
        if (value == "" || value == null || value == "Asset Name") {
            value = "Default";
        }
        if (value == "Asset Type") {
            this.isDefault = false;
        }
        else {
            this.isDefault = true;
        }
        this.userSelectedValue = value;
        this.setIndexType();
    }

    removeAssetFromCurrent(a: Content) {
        this.pinnacleService.DeleteWorkgoupContentItem(this.workgroup.workgroupId, a.contentId).subscribe(i => {
            this.currentAssets.splice(this.currentAssets.findIndex(x => x.contentId == a.contentId), 1);
            this.filteredAssets.splice(this.filteredAssets.findIndex(x => x.contentId == a.contentId), 1);
            this.filteredLearningPaths = this.getClonedAssetsByType("LP");
            this.filteredWorkflows = this.getClonedAssetsByType("WF");
            this.filteredCheatSheets = this.getClonedAssetsByType("CS");
            this.filteredVideos = this.getClonedAssetsByType("VID");

            // Update range for tooltip
            this.maxIndex = (this.filteredAssets.length - 1).toString();

            if (this.highlightedID == a.contentId as string) {
                this.highlightedID = "";
            }

            //let userIds: Array<string> = new Array<string>();
            //for (let member of this.currentMembers) {
            //    userIds.push(member.userId as string);
            //}

            //let title = this.currentWorkgroup.name + " has changed";
            //let text = ProdGenApi.getSessionUser().name + " has removed '" + a.name + "' from the '" + this.currentWorkgroup.name + "' work group."
            //let linkUrl = "/workcenter?id=" + this.currentWorkgroup.workgroupId;
            //this.pinnacleService.SetNotification(title, text, ProdGenApi.getSessionUser().imageURL as string, linkUrl, userIds).subscribe();

        });
    }

    loadWorkgroupData(w: Workgroup, loadAssets: boolean = true) {

        if (w == null || w.workgroupId == null) {
            return;
        }

        // load up the assets
        if (loadAssets == true) {

            this.contentIndices = new Array<WorkgroupContentIndex>();

            this.filteredAssets = new Array<Content>();
            this.filteredLearningPaths = new Array<Content>();
            this.filteredWorkflows = new Array<Content>();
            this.filteredCheatSheets = new Array<Content>();
            this.filteredVideos = new Array<Content>();

            this.currentAssets = new Array<Content>();

            //this.assestsLoaded = false;
            this.pinnacleService.getWorkgroupContentIndices(w.workgroupId, this.indexType).subscribe(res => {
                this.contentIndices = res;

                // Get mapping for reconstruction on save
                for (let i = 0; i < this.contentIndices.length; i++) {
                    this.wgcontentIDMap[this.contentIndices[i].contentID as string] = this.contentIndices[i].wgcontentID;
                    this.dateTimeAddedMap[this.contentIndices[i].contentID as string] = this.contentIndices[i].dateTimeAdded;
                }
                this.maxIndex = (this.contentIndices.length - 1).toString()

                this.indicesPreLoaded = true;
                this.pinnacleService.GetWorkgroupContentItems(w.workgroupId, -1, 0, DescriptionFilter_T.unformatted).subscribe(res => {
                    this.currentAssets = res;
                    this.filteredAssets = this.getClonedAssets();

                    this.filteredLearningPaths = this.getClonedAssetsByType("LP");
                    this.filteredWorkflows = this.getClonedAssetsByType("WF");
                    this.filteredCheatSheets = this.getClonedAssetsByType("CS");
                    this.filteredVideos = this.getClonedAssetsByType("VID");

                    this.assestsLoaded = true;

                    if (this.amIEditor(w)) {
                        this.pinnacleService.GetWorkgroupMembers(w.workgroupId).subscribe(
                            res => {
                                this.workgroupMembers = res;
                                this.setEnrollmentData();
                            }
                        );
                    }
                });

            });


        }
    }

    removeInvisibleContent() {
        // Content Service filters content by visibility. To avoid
        // filtering index results as well, post process here.
        let indiciesToRemove = [];

        for (let c of this.contentIndices) {
            if (this.currentAssets.findIndex(r => { return r.contentId == c.contentID; }) == -1) {
                let contentIndex = this.contentIndices.findIndex(i => { return i.contentID == c.contentID; });
                indiciesToRemove.push(contentIndex);
            }
        }
        for (let i of indiciesToRemove) {
            this.contentIndices.splice(i, 1);
        }

        this.indicesLoaded = true;
    }

    getClonedAssets(): Array<Content> {
        let content: Array<Content> = new Array<Content>();

        this.removeInvisibleContent();

        // Push the items in currentAssets in the
        // order prescribed by contentIndices
        for (let i of this.contentIndices) {
            let c = this.currentAssets.find(function (element) {
                return element.contentId == i.contentID;
            });

            if (c != null) {
                content.push(c);
            }
            //content.push(
            //    this.currentAssets.find(function (element) {
            //        return element.contentId == i.contentID;
            //    })
            //);
        }

        return content;
    }

    getClonedAssetsByType(type: string): Array<Content> {
        let content: Array<Content> = new Array<Content>();
        if (type == "CS") {
            for (let c of this.currentAssets) {
                if (c.contentType == ContentType_T.cheatsheet) {
                    content.push(c);
                }
            }
            return content;
        }

        else if (type == "VID") {
            for (let c of this.currentAssets) {
                if (c.contentType == ContentType_T.video) {
                    content.push(c);
                }
            }
            return content;
        }

        else if (type == "LP") {
            for (let c of this.currentAssets) {
                if (c.contentType == ContentType_T.learningpath || c.contentType == ContentType_T.course) {
                    content.push(c);
                }
            }
            return content;
        }

        else if (type == "WF") {
            for (let c of this.currentAssets) {
                if (c.contentType == ContentType_T.workflow || c.contentType == ContentType_T.process) {
                    content.push(c);
                }
            }
            return content;
        }
    }

    filterTextChanged() {
        this.contentFiltered = true;
        this.filteredAssets = this.getClonedAssets();

        this.filteredLearningPaths = this.getClonedAssetsByType("LP");
        this.filteredWorkflows = this.getClonedAssetsByType("WF");
        this.filteredCheatSheets = this.getClonedAssetsByType("CS");
        this.filteredVideos = this.getClonedAssetsByType("VID");

        if (this.filterText.trim().length > 0) {

            for (var i = 0; i < this.filteredAssets.length; i++) {
                if ((this.filteredAssets[i].name.toLowerCase().indexOf(this.filterText.toLowerCase()) == -1) &&
                    this.filteredAssets[i].description.toLowerCase().indexOf(this.filterText.toLowerCase()) == -1) {
                    this.filteredAssets.splice(i, 1);
                    i--;
                }
            }

            var splitFilterText = this.filterText.split(" ");

            for (var d = 0; d < splitFilterText.length; d++) {
                if (splitFilterText[d].trim().length > 0) {
                    for (var i = 0; i < this.filteredCheatSheets.length; i++) {
                        if (this.filteredCheatSheets[i].name.toLowerCase().indexOf(splitFilterText[d].toLowerCase()) == -1) {
                            this.filteredCheatSheets.splice(i, 1);
                            i--;
                        }
                    }
                }

                if (splitFilterText[d].trim().length > 0) {
                    for (var i = 0; i < this.filteredVideos.length; i++) {
                        if (this.filteredVideos[i].name.toLowerCase().indexOf(splitFilterText[d].toLowerCase()) == -1) {
                            this.filteredVideos.splice(i, 1);
                            i--;
                        }
                    }
                }

                if (splitFilterText[d].trim().length > 0) {
                    for (var i = 0; i < this.filteredWorkflows.length; i++) {
                        if (this.filteredWorkflows[i].name.toLowerCase().indexOf(splitFilterText[d].toLowerCase()) == -1) {
                            this.filteredWorkflows.splice(i, 1);
                            i--;
                        }
                    }
                }

                if (splitFilterText[d].trim().length > 0) {
                    for (var i = 0; i < this.filteredLearningPaths.length; i++) {
                        if (this.filteredLearningPaths[i].name.toLowerCase().indexOf(splitFilterText[d].toLowerCase()) == -1) {
                            this.filteredLearningPaths.splice(i, 1);
                            i--;
                        }
                    }
                }
            }
        }
        else {
            this.contentFiltered = false;
        }
    }


    toggleAssetEditMode() {
        if (this.isAssetEditMode) {
            if (!this.contentFiltered) {
                this.tryModifyIndices();
            }
            if (this.highlightedID != "") {
                try {
                    document.getElementById(this.highlightedID).style.backgroundColor = "transparent";
                    this.highlightedID = "";
                }
                catch (err) {

                }

            }
        }
        this.isAssetEditMode = !this.isAssetEditMode;
        if (this.userSelectedValue == "Asset Name") {
            this.initAssetMidpoints();
        }
    }

    getAssetEditModeText(): string {
        if (this.isAssetEditMode == true) {
            return this.translationService.getTranslationFileData("WORKCENTER.Done");
        }
        return this.translationService.getTranslationFileData("WORKCENTER.Edit");
    }

    getContentIndex(r: Content): string {
        return (this.filteredAssets.findIndex(function (element) {
            return element.contentId == r.contentId;
        }) + 1).toString();
    }

    getContentUrl(r: Content): string {
        let url: string = "#";
        if (r.contentType == ContentType_T.workflow) {
            url += "/workflowviewer?id=" + r.contentId + "&contentType=workflow";
        }
        else if (r.contentType == ContentType_T.process) {
            url += "/workflowviewer?id=" + r.contentId + "&contentType=process";
        }
        else if (r.contentType == ContentType_T.task) {
            url += "/workflowviewer?id=" + r.contentId + "&contentType=task";
        }
        else if (r.contentType == ContentType_T.step) {
            url += "/workflowviewer?id=" + r.contentId + "&contentType=step";
        }
        else if (r.contentType == ContentType_T.cheatsheet) {
            url += "/cheatsheetviewer?id=" + r.contentId;
        }
        else if (r.contentType == ContentType_T.video) {
            url += "/videoviewer?id=" + r.contentId;
        }
        else if (r.contentType == ContentType_T.learningpath) {
            url += "/learningcenter/series?learningPathId=" + r.contentId;
        }
        else if (r.contentType == ContentType_T.course) {
            url += "/learningcenter/series?courseId=" + r.contentId;
        }
        return url;

    }

    getContentImage(r: Content): string {
        let image: string = "";

        image = "assets/images/";

        if (r.contentType == ContentType_T.cheatsheet && r.docURL != null && r.docURL != "") {
            if (r.docURL.includes(".pdf")) {
                image += "PDF Graphic.png"
            }
            else if (r.docURL.includes(".doc")) {
                image += "Word Graphic.png"
            }
            else if (r.docURL.includes(".xls")) {
                image += "Excel Graphic.png"
            }
            else if (r.docURL.includes(".ppt")) {
                image += "PowerPoint Graphic.png"
            }
            else if (r.docURL.includes(".htm")) {
                image += "HTML Graphic.png"
            }
        }
        else {
            image += ProdGenApi.getLargeDefaultImageFromType(r.contentType);
            if (image.indexOf("default") == -1) {
                image = image.replace("assets/images/", "");
            }
        }

        return image;
    }

    getContentLPImage(): string {
        let image: string = "";

        image = "assets/images/";
        let img = ProdGenApi.getLargeDefaultImageFromType(ContentType_T.learningpath);

        if (img.indexOf("default") == -1) {
            image = img;
        }
        else {
            image += img;
        }
        return image;
    }

    getContentWFImage(): string {
        let image: string = "";

        image = "assets/images/";
        let img = ProdGenApi.getLargeDefaultImageFromType(ContentType_T.workflow);

        if (img.indexOf("default") == -1) {
            image = img;
        }
        else {
            image += img;
        }
        return image;
    }

    getContentCSImage(): string {
        let image: string = "";

        image = "assets/images/";
        let img = ProdGenApi.getLargeDefaultImageFromType(ContentType_T.cheatsheet);

        if (img.indexOf("default") == -1) {
            image = img;
        }
        else {
            image += img;
        }
        return image;
    }

    getContentVidImage(): string {
        let image: string = "";

        image = "assets/images/";
        let img = ProdGenApi.getLargeDefaultImageFromType(ContentType_T.video);

        if (img.indexOf("default") == -1) {
            image = img;
        }
        else {
            image += img;
        }
        return image;
    }

    onChange(event): void {
        const val = event.target.value;
        if (val == "Default" || val == "Frequently Used") {
            this.isDefault = true;
        }
        else if (val == "Asset Type") {
            this.isDefault = false;
        }

        sessionStorage.setItem("workGroupSortBy", val);
        this.setIndexType();

        this.loadWorkgroupData(this.workgroup);
    }


    amIEditor(w: Workgroup): boolean {

        if (this.currentUserPermissionsLevel == "owner" || this.currentUserPermissionsLevel == "editor") {
            return true;
        }

        return false;
    }

    // Part of asset reordering. Moves the selected
    // item up one position.
    moveUp(item: Content) {
        this.initAssetMidpoints();
        let newAssets = this.filteredAssets;
        let itemIndex = newAssets.findIndex(function (element) {
            return element.contentId == item.contentId;
        });
        if (itemIndex > 0) {
            let itemAbove = newAssets[itemIndex - 1];
            newAssets[itemIndex - 1] = item;
            newAssets[itemIndex] = itemAbove;
            this.filteredAssets = newAssets;

            window.scrollTo(0, pageYOffset - this.assetHeight);
            this.highlightElement(item.contentId as string);
        }
    }

    // Part of asset reordering. Moves the selected
    // item down one position.
    moveDown(item: Content) {
        this.initAssetMidpoints();
        let newAssets = this.filteredAssets;
        let itemIndex = newAssets.findIndex(function (element) {
            return element.contentId == item.contentId;
        });
        if (itemIndex < newAssets.length - 1) {
            let itemBelow = newAssets[itemIndex + 1];
            newAssets[itemIndex + 1] = item;
            newAssets[itemIndex] = itemBelow;
            this.filteredAssets = newAssets;

            window.scrollTo(0, pageYOffset + this.assetHeight);
            this.highlightElement(item.contentId as string);
        }
    }

    // Part of asset reordering. Moves the selected
    // item to the top of the list.
    makeFirst(item: Content, event) {
        this.initAssetMidpoints();
        let oldIndex = this.filteredAssets.findIndex(i => { return item.contentId == i.contentId; });
        let newXoffset = oldIndex * this.assetHeight;

        let newAssets = new Array<Content>();
        newAssets.push(item);
        for (let a of this.filteredAssets) {
            if (a.contentId != item.contentId) {
                newAssets.push(a);
            }
        }
        this.filteredAssets = newAssets;

        window.scrollTo(0, pageYOffset - newXoffset);
        this.highlightElement(item.contentId as string);
    }

    // Part of asset reordering. Moves the selected
    // item to the bottom of the list.
    makeLast(item: Content, event) {
        this.initAssetMidpoints();
        let oldIndex = this.filteredAssets.findIndex(i => { return item.contentId == i.contentId; });
        let newXoffset = ((this.filteredAssets.length - 1) - oldIndex) * this.assetHeight;

        let newAssets = new Array<Content>();
        for (let a of this.filteredAssets) {
            if (a.contentId != item.contentId) {
                newAssets.push(a);
            }
        }
        newAssets.push(item);
        this.filteredAssets = newAssets;

        window.scrollTo(0, pageYOffset + newXoffset);
        this.highlightElement(item.contentId as string);
    }

    // Part of asset reordering. Moves the selected
    // item to the destination in the list. (Hint:
    // the destination is index + 1)
    moveTo(item: Content, p_destination: any) {
        this.initAssetMidpoints();
        let destination = Number(p_destination);

        // Sync large and small versions of the page
        (document.getElementById(item.contentId.toString() + "inputLG") as HTMLInputElement).value = p_destination as string;
        (document.getElementById(item.contentId.toString() + "inputSM") as HTMLInputElement).value = p_destination as string;

        if (destination > 0 && destination < 100000) {

            // Per Chris, if the destination is larger than
            // the size of the list, append the item anyway
            if (destination > this.filteredAssets.length) {
                destination = this.filteredAssets.length;
            }

            let newAssets = this.filteredAssets;
            let curIndex = newAssets.findIndex(function (element) {
                return element.contentId == item.contentId;
            });

            // If item is not already at location...
            if ((curIndex + 1) != destination) {

                // Store
                let temp = newAssets[curIndex];

                // Remove
                newAssets.splice(curIndex, 1);

                // Replace
                newAssets.splice(destination - 1, 0, temp);

                // Highlight
                this.highlightElement(item.contentId as string);

                // Scroll
                let newYOffset = ((destination - (curIndex + 1)) * this.assetHeight);
                window.scrollTo(0, pageYOffset + newYOffset);
            }
        }
        else {
            this.highlightElement(item.contentId.toString());
            document.getElementById(item.contentId.toString() + "inputLG").classList.add("inptInvalid");
            document.getElementById(item.contentId.toString() + "iconLG").style.color = "darkred";
            document.getElementById(item.contentId.toString() + "inputSM").classList.add("inptInvalid");
            document.getElementById(item.contentId.toString() + "iconSM").style.display = "inline-block";
        }
    }

    // Part of asset reordering. Handles highlighting
    // the row containing the selected item.
    highlightElement(itemID: string) {
        if (this.highlightedID != "") {
            document.getElementById(this.highlightedID).style.backgroundColor = "transparent";
            document.getElementById(this.highlightedID + "btnFirst").classList.remove("btnHighlighted");
            document.getElementById(this.highlightedID + "btnUp").classList.remove("btnHighlighted");
            document.getElementById(this.highlightedID + "btnDown").classList.remove("btnHighlighted");
            document.getElementById(this.highlightedID + "btnLast").classList.remove("btnHighlighted");

            document.getElementById(this.highlightedID + "inputLG").classList.remove("inptInvalid");
            document.getElementById(this.highlightedID + "iconLG").style.color = "transparent";
            document.getElementById(this.highlightedID + "inputSM").classList.remove("inptInvalid");
            document.getElementById(this.highlightedID + "iconSM").style.display = "none";
        }
        document.getElementById(itemID).style.backgroundColor = "#d1dceb";
        document.getElementById(itemID + "btnFirst").classList.add("btnHighlighted");
        document.getElementById(itemID + "btnUp").classList.add("btnHighlighted");
        document.getElementById(itemID + "btnDown").classList.add("btnHighlighted");
        document.getElementById(itemID + "btnLast").classList.add("btnHighlighted");
        this.highlightedID = itemID;
    }

    // Part of asset reordering. Handles input
    // from the large version of the page.
    onInputChangedLG(event) {
        var curItem = this.filteredAssets.find(function (element) {
            return (element.contentId.toString() + "inputLG") == event.target.id;
        });
        this.moveTo(curItem, event.target.value);
    }

    // Part of asset reordering. Handles input
    // from the small version of the page.
    onInputChangedSM(event) {
        var curItem = this.filteredAssets.find(function (element) {
            return (element.contentId.toString() + "inputSM") == event.target.id;
        });
        this.moveTo(curItem, event.target.value);
    }

    // Part of asset reordering. Called whenever
    // the modify mode is toggled by the user.
    // Handles calling the API service to update
    // the sequence numbers for the items.
    tryModifyIndices() {
        var newIndices = new Array<WorkgroupContentIndex>();
        for (let i = 0; i < this.filteredAssets.length; i++) {
            let curItem = new WorkgroupContentIndex();
            curItem.workgroupID = this.workgroup.workgroupId;
            curItem.wgcontentID = this.wgcontentIDMap[this.filteredAssets[i].contentId as string];
            curItem.contentID = this.filteredAssets[i].contentId;
            curItem.dateTimeAdded = this.dateTimeAddedMap[this.filteredAssets[i].contentId as string];
            curItem.index = i;
            newIndices.push(curItem);
        }

        // (Hint: you have to subscribe to the API even if it doesn't return anything)
        this.pinnacleService.modifyWorkgroupContentIndices(this.workgroup.workgroupId, newIndices).subscribe(res => {
        });
    }

    // Part of asset reordering. Called whenever
    // the modify mode is toggled by the user and
    // whenever the page is resized. Calculates
    // the DOM locations to jump to for each
    // index in the list.
    initAssetMidpoints() {
        if (this.filteredAssets.length > 0) {
            this.assetHeight = (this.assetsRef.first as ElementRef).nativeElement.offsetHeight;
            this.assetHeight += 16;
        }
        else {
            this.assetHeight = 0;
        }
    }

    // Part of asset reordering.
    onResize(event) {
        if (this.isAssetEditMode) {
            this.initAssetMidpoints();
        }
    }

    enrollMembersClick() {
        //$('#enrollmentModal').modal('show');
        this.assignableLearningContent.emit(this.content);
        this.showUserGrid.emit(true);

    }

    @ViewChild(EnrollUsersContentComponent, { static: false }) enrollUsersComponent;

    commitEnrollMembers(members, content, useDueDate, dueDate) {
        this.enrollUsersComponent.commitEnrollUsers(members, content, useDueDate, dueDate);
    }

    setEnrollmentData() {
        this.users = [];
        this.content = [];
        this.contentObj = new Array<Content>();
        if (this.workgroupMembers != null) {
            for (var j = 0; j < this.workgroupMembers.length; j++) {
                if (this.workgroup.workgroupId == this.workgroupMembers[j].workgroupId) {
                    this.users.push({ "id": this.workgroupMembers[j].userId, "itemName": this.workgroupMembers[j].name });
                }
            }

            this.pinnacleService.GetWorkgroupExternalGroups(this.workgroup.workgroupId as string).subscribe(res => {
                res.forEach(g => {
                    g.members.forEach(m => {
                        this.users.push({ "id": m.id, "itemName": m.name })
                    });
                });
            });

        }

        for (var i = 0; i < this.currentAssets.length; i++) {
            if (this.currentAssets[i].contentType == ContentType_T.learningpath || this.currentAssets[i].contentType == ContentType_T.course) {
                this.content.push({ "id": this.currentAssets[i].contentId, "itemName": this.currentAssets[i].name });
                this.contentObj.push(this.currentAssets[i]);
            }
        }
    }

    @Output("userGridVisibilityChanged") showUserGrid = new EventEmitter<boolean>();
    @Output("assignableLearningContentChanged") assignableLearningContent = new EventEmitter<any>();
}
