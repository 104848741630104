import { Tenant, Organization } from "./class.organizations"

export interface SecurityKey {
	orgid: number,
	user: string,
	userid: string,
	tenantid: string,
	partnerid: string,
	selling_partnerid: string,
	tenant_name: string,
	edition: string,
	is_trial: boolean,
	is_mobile: boolean,
	is_browser: boolean,
	is_description_html: boolean,
	time_offset: number,
	is_support_disabled: boolean,
	pinnacle_version: string,
	isExternal: boolean
}

export class UserOrgInfoObject {
	organizationId: string;
		//public String organizationName { get; set; }
	partnerId: string;
	partnerName: string;
	partnerSupportEmail: string;
	partnerSupportPhone: string;

	loginImageURL: string;
	splashImageURL: string;
	badgeImageURL: string;

	productName: string;
	supportURL: string;
	chatSupportAvailable: boolean;
	emailSupportAvailable: boolean;
	phoneSupportAvailable: boolean;
	runSearchAvailable: boolean;
	remoteAssistanceAvailable: boolean;
	supportMaxWaitCount: number;
	supportMaxAgentsCount: number;



	tenantList: Tenant[];
}

export class Language {
    public languageName: String;
    public languageCode: String;
}

export class AuthorizationBody {
	email: string;
	password: string;
	tenantId: string;
	language: string;
    returnLongLivedToken: boolean;
    timeOffset: number;
    timezoneName: string;
}

export class APIV2AccessKey {
    public bearerToken: string;
    public expiration: Date;
    public orgID: number;
    public tenantid: string;
    public userId: string;
}
export class UserToken {
	userAccessKey: string;
    apiV2AccessKey: APIV2AccessKey;
    longLivedToken: string;
    isExternalUser: boolean;

    email: string;
    tenantid: string;
}

export class Policy {
	policyID: string;
	title: string;
	text: string;
}

export class PasswordPolicies {
	policyEnforced: boolean;
	requiresComplexPassword: boolean;
	passwordExpires: boolean;
	passwordChangeOnFirstLogIn: boolean;
	passwordMustBeDifferentFromPrev: boolean;
	disableWinAuth: boolean;
	useAutoDiscover: boolean;
	ssoURL :string
	autoLogInWithWindows: boolean;
	disableForgotPassword: boolean;
	enableHybridSSO: boolean;
	numDaysPasExpires: number;
	numDiffPasCount: number;
}



export class PolicyAcceptBody {
	policyID: string;
	tenantID: string;
	email: string;
}


export class MarkPolicyAccepted extends PolicyAcceptBody {

    timestamp: string;
}

export class PWResetRequest {
  requestID: string;
  userID: string;
  reqType: string;
  reqByID: string;
  reqDateTime: string;
  resetDateTime: string;
  reqCode: string;
  isPending: boolean;
  tenantID: string;
}

export class FullAuthToken {
    token: UserToken;
    policyList: Array<Policy>;
    languageFile: string;
    apiToken: string;
    languageSetting: string;
    orgData: Organization;
    isTest: boolean;
}

export class SSO_Override {

    partnerID: string;
    email: string;
    link: string;
}

export class IsNaviateUserRequestObj {
	email: string;
	token: string;
}

export class GetGlobalSettingRequestObj {
	settingName: string;
	token: string;
}