import {
    Input,
    Output,
    Component,
    ViewChildren,
    QueryList,
    EventEmitter,
    ChangeDetectorRef,
} from '@angular/core';
import { OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { DiagramViewComponent, DiagramMenu } from './../../templates/diagram-view/diagram-view.component';
import { ProdGenApi } from './../../apiService/prodgen.api';
import {
    Content,
    ContentType_T,
    DescriptionFilter_T
} from "./../../apiService/classFiles/class.content"
import { DiagramTreeService } from './../../services/diagram-tree.service';
import { AppComponent } from '../../app.component';
declare var $: any;

@Component({
    selector: 'app-workflowtree',
    templateUrl: './workflowtree.component.html',
    styleUrls: ['./workflowtree.component.css'],
    providers: [DiagramViewComponent],
})
export class WorkflowtreeComponent implements OnInit {

    @Input('diagramType') diagramType: string;
    @Input('diagramId') diagramId: string;
    @Input('initialSelectionId') initialSelectionId: string;
    @Input('isCourseViewer') isCourseViewer?: boolean;
    @Input('objectClickId') objectClickId: string;
    @Input('generateNewWorkflow') generateNewWorkflow?: boolean;
    @Output('selectionChanged') selectionChanged = new EventEmitter<any>();
    @Output('diagramChange') diagramChanged = new EventEmitter<any>();
    @Output('newWorkflowChange') newWorkflowChange = new EventEmitter<any>();
    @Output('nextCourseItem') nextCourseItem = new EventEmitter<any>();
    @Output('prevCourseItem') prevCourseItem = new EventEmitter<any>();
    @ViewChildren('defaultProcessDiv') defaultProcessDiv: QueryList<any>;

    selection: Content = new Content();
    parentworkflowItems: Array<HierarchyList>;
    WFId: String;
    stepsExpanded: boolean = false;
    selected: any;
    isDefault: boolean = true;

    public constructor(
        private pinnacleService: ProdGenApi,
        private cdr: ChangeDetectorRef,
        private route: ActivatedRoute,
        private router: Router,
        private diagramTreeInteractionService: DiagramTreeService) { }

    ngOnInit() {
        this.objectClickId = this.diagramId;
        this.selection.contentId = this.diagramId;
    }

    ngAfterViewInit() {

        this.defaultProcessDiv.changes.subscribe((result: QueryList<any>) => {
            result.first.nativeElement.click();
        });
    }

    ngOnChanges(changes: SimpleChanges) {

        try { this.diagramId = changes['diagramId'].currentValue } catch (err) { };
        try { this.diagramType = changes['diagramType'].currentValue } catch (err) { };
        try {
            this.objectClickId = changes['objectClickId'].currentValue;

            // When the diagram is clicked, find the associated
            // item in the tree and expose it. Partial fix for
            // bug 714.
            for (let i = 0; i < this.parentworkflowItems.length; i++) {

                if (this.parentworkflowItems[i].contentId == this.objectClickId) {

                    this.exposeTreeItem(this.parentworkflowItems, i);
                    this.exposeChildren(this.parentworkflowItems, i);
                }
            }
        } catch (err) { };
        try { this.generateNewWorkflow = changes['generateNewWorkflow'].currentValue } catch (err) { };

        if (this.isCourseViewer) {

            this.diagramTreeInteractionService.objectClickId$.subscribe(ID => {                
                this.objectClickId = ID;
            });
            

            
        }

        if (this.generateNewWorkflow) {

            this.loadTree();
            if (this.isCourseViewer == false) {

                this.newWorkflowChange.emit({
                    selectedItem: false
                });
            }
        }

        this.onTreeClick;
    }

    loadTree(): void {

        if (this.diagramId != "") {

            this.getWorkflowHierarchy(this.diagramId);
        }
        else {
            this.diagramType = "task";
            this.getWorkflowHierarchy(this.initialSelectionId);
        }

        this.isDefault = true;
    }

    getWorkflowHierarchy(id: string): void {

        if (this.diagramType == "workflow") {

            this.WFId = id;

            // get the hierarchy
            this.pinnacleService.GetWorkflowHierarchy(id).subscribe(res => {
                this.createNestedList(res);
            });
        }
        else if (this.diagramType == "process") {

            this.pinnacleService.GetProcess(id).subscribe(op => {
                this.WFId = op.workflowId;

                // get the hierarchy
                this.pinnacleService.GetWorkflowHierarchy(op.workflowId).subscribe(res => {
                    this.createNestedList(res);
                });
            });
        }
        else if (this.diagramType == "task") {

            this.route.queryParams.subscribe(queryParams => {
                if (queryParams['contentType'] != null) {

                    if (queryParams['contentType'] == "task") {

                        let taskId = queryParams['id'];
                        this.initialSelectionId = taskId;

                        // get the process
                        if (taskId != null && taskId.length > 0) {

                            this.pinnacleService.GetTask(taskId, DescriptionFilter_T.none).subscribe(res => {
                                id = res.processId as string;
                                this.pinnacleService.GetProcess(id).subscribe(op => {
                                    this.WFId = op.workflowId;

                                    // get the hierarchy
                                    this.pinnacleService.GetWorkflowHierarchy(op.workflowId).subscribe(res => {
                                        this.createNestedList(res);
                                    });
                                });
                            });
                        }
                    }

                    if (queryParams['contentType'] == "step") {

                        let stepId = queryParams['id'];
                        this.initialSelectionId = stepId;

                        // get the process
                        if (stepId != null && stepId.length > 0) {

                            this.pinnacleService.GetStep(stepId, DescriptionFilter_T.none).subscribe(res1 => {
                                //console.log("Step G");
                                this.pinnacleService.GetTask(res1.taskId, DescriptionFilter_T.none).subscribe(res => {
                                    id = res.processId as string;
                                    this.pinnacleService.GetProcess(id).subscribe(op => {
                                        this.WFId = op.workflowId;

                                        // get the hierarchy
                                        this.pinnacleService.GetWorkflowHierarchy(op.workflowId).subscribe(res => {
                                            this.createNestedList(res);
                                        });
                                    });
                                });
                            });
                        }
                    }
                }
            });
        }
    }

  
    createNestedList(hierarchyItems: HierarchyContent) {

        if (this.isCourseViewer == false || this.diagramType == "workflow") {

            let parentWorkflow = new HierarchyList();
            this.parentworkflowItems = new Array<HierarchyList>();
            parentWorkflow.contentId = this.WFId.toString();
            parentWorkflow.name = hierarchyItems.Name;
            parentWorkflow.indent = 0;
            parentWorkflow.type = "Workflow";

            if (hierarchyItems.Children.length > 0)
                parentWorkflow.hasChildren = true;

            this.parentworkflowItems.push(parentWorkflow);
            let procChilds = hierarchyItems.Children;

            for (var j = 0; j < procChilds.length; j++) {

                let processContent = new HierarchyList();
                processContent.contentId = procChilds[j].ContentId;
                processContent.name = (j + 1) + ".0 - " + procChilds[j].Name;
                processContent.type = "Process";
                processContent.indent = 1;

                if (procChilds[j].Children.length > 0)
                    processContent.hasChildren = true;

                this.parentworkflowItems.push(processContent);
                let taskChilds = procChilds[j].Children;

                for (var k = 0; k < taskChilds.length; k++) {

                    let taskContent = new HierarchyList();
                    taskContent.contentId = taskChilds[k].ContentId;
                    taskContent.name = (j + 1) + "." + (k + 1) + " - " + taskChilds[k].Name;
                    taskContent.type = "Task";
                    taskContent.indent = 2;

                    if (taskChilds[k].Children.length > 0)
                        taskContent.hasChildren = true;

                    this.parentworkflowItems.push(taskContent);
                    let stepChilds = taskChilds[k].Children;

                    for (var i = 0; i < stepChilds.length; i++) {

                        let stepContent = new HierarchyList();
                        stepContent.contentId = stepChilds[i].ContentId;
                        stepContent.name = (j + 1) + "." + (k + 1) + "." + (i + 1) + " - " + stepChilds[i].Name;
                        stepContent.type = "Step";
                        stepContent.indent = 3;
                        this.parentworkflowItems.push(stepContent);
                    }
                }
            }
        }
        else {

            let procChilds = hierarchyItems.Children;
            this.parentworkflowItems = new Array<HierarchyList>();

            for (var j = 0; j < procChilds.length; j++) {

                if (this.diagramId == procChilds[j].ContentId) {

                    let processContent = new HierarchyList();
                    processContent.contentId = procChilds[j].ContentId;
                    processContent.name = (j + 1) + ".0 - " + procChilds[j].Name;
                    processContent.type = "Process";
                    processContent.indent = 0;

                    if (procChilds[j].Children.length > 0)
                        processContent.hasChildren = true;

                    this.parentworkflowItems.push(processContent);
                    let taskChilds = procChilds[j].Children;

                    for (var k = 0; k < taskChilds.length; k++) {

                        let taskContent = new HierarchyList();
                        taskContent.contentId = taskChilds[k].ContentId;
                        taskContent.name = (j + 1) + "." + (k + 1) + " - " + taskChilds[k].Name;
                        taskContent.type = "Task";
                        taskContent.indent = 1;

                        if (taskChilds[k].Children.length > 0)
                            taskContent.hasChildren = true;

                        this.parentworkflowItems.push(taskContent);
                        let stepChilds = taskChilds[k].Children;

                        for (var i = 0; i < stepChilds.length; i++) {

                            let stepContent = new HierarchyList();
                            stepContent.contentId = stepChilds[i].ContentId;
                            stepContent.name = (j + 1) + "." + (k + 1) + "." + (i + 1) + " - " + stepChilds[i].Name;
                            stepContent.type = "Step";
                            stepContent.indent = 2;
                            this.parentworkflowItems.push(stepContent);
                        }
                    }

                    break;
                }
            }
        }
    }

  getResultImage(r: HierarchyList): string {
    let image: string = "";
    let contentType: ContentType_T;

    if (r.type == "Workflow")
      contentType = ContentType_T.workflow;
    else if (r.type == "Process")
      contentType = ContentType_T.process;
    else if (r.type == "Task")
      contentType = ContentType_T.task;
    else if (r.type == "Step")
      contentType = ContentType_T.step;

    image += ProdGenApi.getIconImageFromType(contentType);

    return image;
  }

    // Modified for performance and as a partial fix
    // for bug 714. Elements broken out for modularity
    // and detailed below.
    displayToggleResults(r: Array<HierarchyList>, index: number): void {

        var maxIndent: number;
        if (this.isCourseViewer == false
            || (this.isCourseViewer && this.diagramType == "workflow")) {

            maxIndent = 3;
        }
        else {

            maxIndent = 2;
        }
        var indentLevel: number = r[index].indent;
        if (indentLevel < maxIndent) {
            if ($('#expandiconPlus' + index).is(':visible')) {

                this.exposeChildren(r, index);
            }
            else {

                this.collapseAllDescendants(r, index);
            }
        }
    }

    // Private method used in-class to show the
    // direct descendants of the item in Hierarchy
    // array 'r' at index 'index'. Modifies the
    // toggle icon of the item at 'index'.
    private exposeChildren(r: Array<HierarchyList>, index: number): void {

        var indentLevel: number = r[index].indent;
        var curIndex: number = index + 1;

        if (curIndex < r.length
            && r[curIndex].indent == indentLevel + 1
            && $('#tree' + curIndex).is(':hidden')) {

            $('#expandiconPlus' + index).css('display', 'none');
            $('#expandiconMinus' + index).css('display', 'block');

            while (curIndex < r.length
                && r[curIndex].indent > indentLevel) {

                if (r[curIndex].indent == indentLevel + 1
                    && $('#tree' + curIndex).is(':hidden')) {

                    $('#expandiconPlus' + curIndex).css('display', 'block');
                    $('#tree' + curIndex).css('display', 'block');
                }

                curIndex++;
            }
        }
    }

    // Private method used in-class to hide all
    // descendants of the item in Hierarchy
    // array 'r' at index 'index'. Does not modify
    // the visibility of the item at index. Does
    // not collapse the direct descendants of the
    // root element.
    private collapseAllDescendants(r: Array<HierarchyList>, index: number) {

        var indentLevel: number = r[index].indent;
        var curIndex: number = index + 1;
        $('#expandiconPlus' + index).css('display', 'block');
        $('#expandiconMinus' + index).css('display', 'none');

        while (curIndex < r.length
            && r[curIndex].indent > indentLevel) {

            if (r[curIndex].indent > 1)
                $('#expandiconPlus' + curIndex).css('display', 'none');

            $('#expandiconMinus' + curIndex).css('display', 'none');
            $('#tree' + curIndex).css('display', 'none');
            curIndex++;
        }
    }

    // Private method used in-class to show the
    // in Hierarchy array 'r' at index 'targetIndex'.
    // Expands all ancestors.
    private exposeTreeItem(r: Array<HierarchyList>, targetIndex: number): void {

        var ancestry: Array<number> = this.getItemAncestry(r, targetIndex);
        var curIndex: number;

        while (ancestry.length > 0) {

            curIndex = ancestry.pop();
            this.exposeChildren(r, curIndex);
        }
    }

    // Private method used in-class to obtain
    // an array containing the parent indicies
    // of the item in Hierarchy array 'r' at
    // index 'index'. Does not include the
    // root element of 'r'. Returned in reverse
    // order; use .pop() or .reverse() to
    // get ancestors in descending order.
    private getItemAncestry(r: Array<HierarchyList>, index: number): Array<number> {

        var ancestry = new Array<number>();
        var indentLevel: number = r[index].indent;
        var curIndex: number = index;

        while (curIndex > 0) {

            if (r[curIndex].indent == indentLevel - 1) {

                ancestry.push(curIndex);
                indentLevel--;
            }
            curIndex--;
        }
        return ancestry;
    }

    onTreeClick(item: HierarchyList, event) {

        let diagramTypeDesc: string = item.type.toLowerCase();
        let ItemType: ContentType_T;

        if (diagramTypeDesc == "workflow") {

            ItemType = ContentType_T.workflow;
        }
        else if (diagramTypeDesc == "process") {

            ItemType = ContentType_T.process;
        }
        else if (diagramTypeDesc == "task") {

            ItemType = ContentType_T.task;
        }
        else if (diagramTypeDesc == "step") {

            ItemType = ContentType_T.step;
        }

        this.selection.contentId = item.contentId;
        this.selection.contentType = ItemType;
        this.selection.description = diagramTypeDesc;

        if (this.isCourseViewer == false) {

            this.diagramChanged.emit({
                selectedItem: this.selection
            });

            this.diagramId = item.contentId;
            this.diagramType = diagramTypeDesc;
            this.objectClickId = item.contentId;

            this.selectionChanged.emit({

                selectedItem: this.selection
            });
        }
        else {

            this.diagramTreeInteractionService.SetSelectContent(this.selection);
            this.objectClickId = item.contentId;
        }

        if (AppComponent.thisFromOutside.Flyoutopen == true) {
            AppComponent.thisFromOutside.closeFlyout();
            AppComponent.thisFromOutside.Flyoutopen = false;
        }
    }

    onAccordionClick(event) { }

    getDefaultExpandedClass(r: Array<HierarchyList>): string {

        if (this.isCourseViewer == false) {

            for (var i = 0; i < r.length; i++) {

                if (r[i].indent == 1) {

                    if (this.diagramId == r[i].contentId) {

                        $('#icon' + i).click();

                        for (var k = i + 1; k < r.length; k++) {

                            if (r[k].indent == 1) {

                                i = k - 1;
                                break;
                            }
                            else if (r[k].indent == 2) {

                                $('#tree' + k).collapse("show");
                            }
                        }
                    }
                }
            }
        }
        else {

            for (var i = 0; i < r.length; i++) {

                if (r[i].indent == 0 && this.diagramType == "process") {

                    if (this.diagramId == r[0].contentId) {

                        $('#icon' + i).click();

                        for (var k = i + 1; k < r.length; k++) {

                            if (r[k].indent == 1) {

                                $('#tree' + k).collapse("show");
                            }
                        }
                    }
                }
            }
        }

        return "";
    }

    getClassList(): string {
        return "";
    }

    getDefaultExpandedProcess(id: string): string {

        if (this.isCourseViewer == false) {

            let Index: number = 0;

            for (var i = Index; i < this.parentworkflowItems.length; i++) {

                if (id == this.parentworkflowItems[i].contentId) {

                    Index = i;
                    break;
                }
            }

            $('#icon' + Index).click();

            for (var k = Index + 1; k < this.parentworkflowItems.length; k++) {

                if (this.parentworkflowItems[k].indent == 1) {

                    break;
                }
                else if (this.parentworkflowItems[k].indent == 2) {

                    $('#tree' + k).collapse("show");
                }
            }
        }
        else {

            for (var i = 0; i < this.parentworkflowItems.length; i++) {

                if (this.diagramType == "process") {

                    $('#icon' + 0).click();

                    for (var k = i + 1; k < this.parentworkflowItems.length; k++) {

                        if (this.parentworkflowItems[k].indent == 1) {

                            $('#tree' + k).collapse("show");
                        }
                    }
                }
            }
        }

        return "";
    }

    getStyling(pr: HierarchyList): string {

        var styling: string = "";

        if (pr.hasChildren == false && pr.indent == 2) {

            styling = "pr.indent * 26";
        }
        else if (pr.hasChildren == true || pr.indent != 3) {

            styling = "pr.indent * 24";
        }
        else {

            styling = "pr.indent * 29";
        }

        return styling;
    }

    public NextItem() {


        var v_Index = this.parentworkflowItems.map(function (e) { return e.contentId }).indexOf(this.selection.contentId as string);

        if (v_Index < this.parentworkflowItems.length - 1) {

            if (this.parentworkflowItems[v_Index].hasChildren) {
                 //$('#tree' + v_Index).collapse("show");
                //$('#tree' + v_Index).collapse("toggle");
            }

            var v_Content = this.parentworkflowItems[v_Index + 1];
            
            this.onTreeClick(v_Content, null);
            
        }
        else {
            this.nextCourseItem.emit();
        }
    }

    public PrevItem() {
        var v_Index = this.parentworkflowItems.map(function (e) { return e.contentId }).indexOf(this.selection.contentId as string);


        if (v_Index > 0) {
            var v_Content = this.parentworkflowItems[v_Index -1];


            this.onTreeClick(v_Content, null);

        }
        else {
            this.prevCourseItem.emit();
        }
    }


}

export class HierarchyContent {
    ContentId: string = "";
    Name: string = "";
    Children: Array<HierarchyContent> = new Array<HierarchyContent>();
}

export class HierarchyList {
    contentId: string = "";
    name: string = "";
    type: string = "";
    indent: number;
    hasChildren: boolean = false;
}
