import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Organization } from '../../apiService/classFiles/class.organizations';
import { Playlist, PlaylistContent, User } from '../../apiService/classFiles/class.users';
import { UserDataGridComponent } from '../userdatagrid/userdatagrid.component';
import { Content, ContentType_T, DescriptionFilter_T } from './../../apiService/classFiles/class.content';
import { Workgroup, WorkgroupSharContentStatusEnum } from './../../apiService/classFiles/class.workgroups';
import { ProdGenApi } from './../../apiService/prodgen.api';
import { TranslationService } from './../../services/TranslationService';

declare var $: any;


@Component({
    selector: 'template-share-dropdown',
    templateUrl: './share-dropdown.component.html',
    styleUrls: ['./share-dropdown.component.css'],
    providers: [ProdGenApi]
})

//NOTE THAT FOR WIDGETS USING THIS:
    //Like My-assets-widget and frequently-used-widget
    //You need to add an exception in the Widget Container > createWidgetComponent()
    //for the dropdown to work outside the widget area

export class ShareDropdownComponent implements OnInit {
    @Input('contentItem') item: Content;
    @Input('editorWorkgroups') editorWorkgroups: Array<Workgroup>;
    @Input('excludeShareToMyAssets') excludeShareToMyAssets: boolean = false;


    @Output('shareDropDownHide') hideComponent: EventEmitter<boolean> = new EventEmitter<boolean>();
    @ViewChild('userGrid', { static: false }) userGridChild: UserDataGridComponent;


    displayShareNotification: boolean = true;

    playlist: Playlist = new Playlist();
    plcontent: PlaylistContent = new PlaylistContent()
    plcontentID: string = "";
    user: User = new User();

    statusText: string = "Asset has been shared to the selected workgroup.";


    org: Organization = new Organization();
    assetDefaultPrefix = "Pinnacle Series Asset ";
    assetNowPrefix = "ProductivityNOW ";
    prodNow: string[] = ['342AA211-04BB-4F21-A4C0-5A6FB3AA5511', '5B4D8094-590C-4317-A611-8011B244CA65', 'A972914D-3CFB-4F3F-9585-85A120F12BFF', '9A3D36B1-0206-4A95-9073-F43EDFEB54A8']; 

    constructor(private pinnacleService: ProdGenApi, private el: ElementRef, private translationService: TranslationService, private sanitize: DomSanitizer) {
        this.statusText = translationService.getTranslationFileData("SHARE.TS_AssetWaiting");

        

    }


    ngOnInit() {
        if (window.location.href.indexOf("/search") != -1) {
            this.displayShareNotification = false;
        }
        else {
            this.displayShareNotification = true;
        }
        this.setOrgData();

    }

    ngAfterViewInit() {
    }



    setOrgData() {
        try {
            this.pinnacleService.GetCurrentOrganization().subscribe(resp => {
                this.org = resp;
            });
        }
        catch (e) { return false; }
    }

    hideDropdown() {
        this.hideComponent.emit(true);
    }

    copyLinkToClipboardFormatted() {
        let url = this.getShareLinkURL();
        let text = `<a href="` + url + `"><u>` + this.item.name + `</u></a>`;

        this.copyToClipboard(text);

        this.hideDropdown();
    }

    copyLinkToClipboardUnformatted() {
        let url = this.getShareLinkURL();
        this.copyToClipboard(url);

        this.hideDropdown();
    }


    getShareLinkURL() {
        let url = document.location.protocol + "//" + document.location.host + "/#";
        url += this.getRoutingLinkURL();

        return url;
    }

    getRoutingLinkURL() {
        let url = "";

        if (this.item.contentType == ContentType_T.cheatsheet) {
            url += "/cheatsheetviewer?id=" + this.item.contentId;
        }
        else if (this.item.contentType == ContentType_T.video) {            
            url += "/videoviewer?id=" + this.item.contentId;
        }
        else if (this.item.contentType == ContentType_T.learningpath) {
            url += "/learningcenter/series?learningPathId=" + this.item.contentId;
        }
        else if (this.item.contentType == ContentType_T.course) {
            url += "/learningcenter/series?courseId=" + this.item.contentId;
        }
        else if (this.item.contentType == ContentType_T.workflow || this.item.contentType == ContentType_T.process || this.item.contentType == ContentType_T.task || this.item.contentType == ContentType_T.step) {
            let contentType = "";
            if (this.item.contentType == ContentType_T.workflow) {
                contentType = "workflow";
            }
            else if (this.item.contentType == ContentType_T.process) {
                contentType = "process";
            }
            else if (this.item.contentType == ContentType_T.task) {
                contentType = "task";
            }
            else if (this.item.contentType == ContentType_T.step) {
                contentType = "step";
            }
            url += "/workflowviewer?id=" + this.item.contentId + "&contentType=" + contentType;
        }
        return url;
    }


    copyToClipboard(textToCopy: string): boolean {
        // create hidden text element, if it doesn't already exist
        var aux = document.createElement("div");

        aux.setAttribute("contentEditable", "true");
        aux.innerHTML = textToCopy;
        aux.setAttribute("style", "background-color:white;");

        document.getElementById("shareDropdownContainer").appendChild(aux);
        window.getSelection().selectAllChildren(aux);
        aux.focus();
        document.execCommand("copy");
        document.getElementById("shareDropdownContainer").removeChild(aux);



        return true;
    }


    createEmailBodyAndOpen() {
        let subjTitlePrefix = this.assetDefaultPrefix;
        if (this.org != null) {
            for (var j = 0; j < this.prodNow.length; j++) {
                if (this.prodNow[j].toString() == this.org.sellingPartnerId) {
                    subjTitlePrefix = this.assetNowPrefix;
                    break;
                }
            }
        }

        let body = "";
        if (this.item != undefined && this.item.name != undefined) {
            body = "mailto:?subject=";

            let itemName = this.item.name.replace(/&/g, "%26").replace(/#/g, "%23").replace(/'/g, "%27").replace(/"/g, "%22");
            let subject = subjTitlePrefix + itemName;
            body += subject + "&body=";

            let link = document.location.protocol + "//" + document.location.host + "/#" + this.getRoutingLinkURL();
            body += itemName + " ";
            body += link;

            body = encodeURI(body);
        }
        window.location.href = body;
        this.hideDropdown();
    }


    //Share Notification Variables
    tenantUsers = [];
    tenantGroups = [];
    selectedUsers = [];
    selectedGroups = [];
    dropdownSettingsUsers = {};
    dropdownSettingsGroups = {};
    usersLoaded = false;
    groupsLoaded = false;
    titleText = "";
    titleDefault = "";
    bodyText = "";
    bodyDefault = "";
    userImage = "";
    showusergrid: boolean = false;
    actionID: string = "00000000-0000-0000-0000-000000000000";

    shareNotification() {
        $('#shareViaNotification' + this.item.contentId).appendTo("body").modal('show');
        this.selectedUsers = [];
        this.selectedGroups = [];
        this.dropdownSettingsUsers = {};
        this.dropdownSettingsGroups = {};



        $('#shareViaNotification' + this.item.contentId).on('shown.bs.modal', () => {
            setTimeout(() => { this.showusergrid = true; }, 1);
        })

        this.bodyDefault = this.translationService.getTranslationFileData("SHARE.TS_BodyDefault") + ": " + this.item.name;
        this.bodyText = this.bodyDefault;

        (document.getElementById("titleInput") as HTMLInputElement).value = "";
        (document.getElementById("bodyInput") as HTMLInputElement).value = "";

        this.dropdownSettingsUsers = {
            singleSelection: false,
            enableCheckAll: true,
            enableFilterSelectAll: true,
            text: this.translationService.getTranslationFileData("SHARE.TS_SelectUsers"),
            enableSearchFilter: true,
            classes: "multi-select-dropdown",
            selectAllText: this.translationService.getTranslationFileData("SHARE.TS_SelectAll"),
            unSelectAllText: this.translationService.getTranslationFileData("SHARE.TS_UnSelectAll"),
            searchPlaceholderText: this.translationService.getTranslationFileData("SHARE.TS_Search")
        };
        this.dropdownSettingsGroups = {
            singleSelection: false,
            enableCheckAll: true,
            enableFilterSelectAll: true,
            text: this.translationService.getTranslationFileData("SHARE.TS_SelectGroups"),
            enableSearchFilter: true,
            classes: "multi-select-dropdown",
            selectAllText: this.translationService.getTranslationFileData("SHARE.TS_SelectAll"),
            unSelectAllText: this.translationService.getTranslationFileData("SHARE.TS_UnSelectAll"),
            searchPlaceholderText: this.translationService.getTranslationFileData("SHARE.TS_Search")
        };

        this.pinnacleService.getCurrentUser().subscribe(u => {
            this.user = u;
            if (u.imageURL == null || u.imageURL == "") {
                this.userImage = "../../../assets/images/default_user.jpg";
            }
            else {
                this.userImage = u.imageURL;
            }

            this.titleDefault = this.user.name + " " + this.translationService.getTranslationFileData("SHARE.TS_TitleDefault");
            this.titleText = this.titleDefault;
        });

        this.pinnacleService.getUsers().subscribe(res => {
            this.usersLoaded = false;
            this.tenantUsers = [];
            for (let i = 0; i < res.length; i++) {
                this.tenantUsers.push({ "id": res[i].userId, "itemName": res[i].name });
            }
            this.usersLoaded = true;
        });

        this.pinnacleService.getJobRoles().subscribe(res => {
            this.groupsLoaded = false;
            this.tenantGroups = [];
            for (let i = 0; i < res.length; i++) {
                this.tenantGroups.push({ "id": res[i].roleId, "itemName": res[i].roleName });
            }
            this.groupsLoaded = true;
        });
        this.actionID = this.item.contentId as string;
    }

    sendNotifications() {
        this.selectedUsers = this.userGridChild.getSelectedUserIds();
        this.userGridChild.reset();
        let url = this.getRoutingLinkURL();

        if (this.selectedUsers.length > 0) {

            this.sendNotifications2(this.selectedUsers, this.titleText, this.bodyText, this.user.imageURL, url, false);
        }
        else {
            if (this.selectedGroups.length > 0) {

                this.sendNotifications2(this.selectedGroups, this.titleText, this.bodyText, this.user.imageURL, url, true);
            }
        }

        this.hideDropdown();

    }

    private sendNotifications2(p_selection,
        p_title: string,
        p_text: string,
        p_senderImg: string,
        p_url: string,
        p_isGroup: boolean) {

        let v_IDs = new Array<string>();


        for (let i of p_selection) {
            if (i.id) {
                v_IDs.push(i.id);
            }
            else {
                v_IDs.push(i);
            }

        }
        this.pinnacleService.SetNotification(
            p_title,
            p_text,
            p_senderImg,
            p_url,
            v_IDs,
            p_isGroup).subscribe(res => {
                if (!p_isGroup && this.selectedGroups.length > 0) {
                    this.sendNotifications2(this.selectedGroups, this.titleText, this.bodyText, this.user.imageURL, p_url, true);
                }
            });
    }

    updatePreview(event) {
        if (event.target.id == "titleInput") {
            if (event.target.value != "") {
                if (event.target.value.length > 49) {
                    (event.target as HTMLInputElement).style.backgroundColor = "rgba(230,0,0,0.1)";
                }
                else {
                    this.titleText = event.target.value;
                    (event.target as HTMLInputElement).style.backgroundColor = "transparent";
                }
            }
            else {
                this.titleText = this.titleDefault;
            }
        }
        if (event.target.id == "bodyInput") {
            if (event.target.value != "") {
                if (event.target.value.length > 149) {
                    (event.target as HTMLInputElement).style.backgroundColor = "rgba(230,0,0,0.1)";
                }
                else {
                    this.bodyText = event.target.value;
                    (event.target as HTMLInputElement).style.backgroundColor = "transparent";
                }
            }
            else {
                this.bodyText = this.bodyDefault;
            }
        }
    }


   
    addToWorkgroup(w: Workgroup) {
        /*
        $('#addToWorkgroupModal').appendTo("body").modal('show');
        
        this.statusText = this.statusText = this.translationService.getTranslationFileData("SHARE.TS_AssetChecking");


        this.pinnacleService.GetWorkgroupContentItems(w.workgroupId, -1, 0, DescriptionFilter_T.unformatted).subscribe(res => {
            let assetFound = false;

            for (let c of res) {
                if (c.contentId == this.item.contentId) {
                    assetFound = true;
                    break;
                }
            }

            if (assetFound) {
                this.statusText = this.translationService.getTranslationFileData("SHARE.TS_AlreadySharedAssetWorkgroup") + " '" + w.name + "' " + this.translationService.getTranslationFileData("SHARE.TS_PostAssetSharedWorkgroup");

            }
            else {
                this.statusText = this.translationService.getTranslationFileData("SHARE.TS_AssetWaiting");
                this.pinnacleService.SetWorkgoupContentItem(w.workgroupId, this.item.contentId, this.item.contentType).subscribe(res => {
                    this.statusText = "'" + this.item.name + "' " + this.translationService.getTranslationFileData("SHARE.TS_MidAssetSharedWorkgroup") + " '" + w.name + "' " + this.translationService.getTranslationFileData("SHARE.TS_PostAssetSharedWorkgroup");
                });
            }

        });
        */
        console.log("B");
        $('#addToWorkgroupModal').appendTo("body").modal('show');
        this.statusText = this.statusText = this.translationService.getTranslationFileData("SHARE.TS_AssetChecking");

        this.pinnacleService.SetWorkgoupContentItem(w.workgroupId, this.item.contentId, this.item.contentType).subscribe(res => {
            console.log(res);
            if (res.Status == WorkgroupSharContentStatusEnum.AlreadyExist) {
                this.statusText = this.translationService.getTranslationFileData("SHARE.TS_AlreadySharedAssetWorkgroup") + " '" + w.name + "' " + this.translationService.getTranslationFileData("SHARE.TS_PostAssetSharedWorkgroup");
            }
            else if (res.Status == WorkgroupSharContentStatusEnum.Success) {
                this.statusText = "'" + this.item.name + "' " + this.translationService.getTranslationFileData("SHARE.TS_MidAssetSharedWorkgroup") + " '" + w.name + "' " + this.translationService.getTranslationFileData("SHARE.TS_PostAssetSharedWorkgroup");

            }
            else {
                this.statusText = this.translationService.getTranslationFileData("SHARE.TS_FailedAssetSharedWorkgroup");

            }
        }, err => {

        });
    }


    AddtoPlaylist() {

        $('#addToPlaylistModal').appendTo("body").modal('show');
        this.plcontent.addeddatetime = new Date();
        this.plcontent.bisdownloadable = true;
        this.plcontent.contentId = this.item.contentId.toString();
        this.plcontent.contentType = this.item.contentType;
        this.plcontent.plid = this.playlist.plid;
        this.plcontent.tenantid = this.playlist.tenantid;

        this.pinnacleService.getCurrentUser().subscribe(r => {
            this.user = r;
            this.pinnacleService.GetPlaylist(this.user.userId).subscribe(pl => {
                this.playlist = pl;



                if (this.plcontent.contentType == ContentType_T.course) {
                    this.pinnacleService.GetEnrollmentIDFromCourse(this.plcontent.contentId).subscribe(r => {
                        this.pinnacleService.AddtoPlaylist(this.playlist.plid, this.item.contentId.toString(), this.plcontent, r).subscribe(res => {
                            this.plcontentID = res;
                            //this.PLstatusText = "'" + this.item.name + "' " + this.translationService.getTranslationFileData("SHARE.TS_MidAssetSharedPlaylist") + " '" + this.playlist.name + "' ";;


                            this.pinnacleService.GetPlaylist(this.user.userId).subscribe(pl => {
                                this.playlist = pl;
                                this.hideDropdown();

                            });

                        });
                    });
                }
                else {
                    this.pinnacleService.AddtoPlaylist(this.playlist.plid, this.item.contentId.toString(), this.plcontent).subscribe(res => {
                        this.plcontentID = res;


                        this.pinnacleService.GetPlaylist(this.user.userId).subscribe(pl => {
                            this.playlist = pl;
                            this.hideDropdown();

                        });

                    });
                }
            });
        });


    }
}