import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Content, ContentType_T } from '../apiService/classFiles/class.content';
import { User } from '../apiService/classFiles/class.users';
import { ProdGenApi } from '../apiService/prodgen.api';
import { Apiv2Service } from '../apiService/apiv2.service';
import { AppComponent } from '../app.component';
import { Group, Group_Micro, AssociatedLearningContent, AssociatedLearning, AssociatedLearningWithProgress, ContentType, GroupProperty } from '../apiService/classFiles/v2-groups';
import { IndexType_T } from '../apiService/classFiles/class.workgroups';
import { Enrollment, EnrollmentFilter_T, EnrollmentStatus_T } from '../apiService/classFiles/class.enrollments';
import { V2User } from '../apiService/classFiles/v2-users';
import { BrowserAuthenticationService } from '../BrowserAuthenticationService';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-job-role-details',
  templateUrl: './job-role-details.component.html',
  styleUrls: ['./job-role-details.component.css']
})
export class JobRoleDetailsComponent implements OnInit {
    associatedLearning: Array<AssociatedLearningWithProgress> = new Array<AssociatedLearningWithProgress>();
    JobRoleGroups: Array<Group_Micro> = new Array<Group_Micro>();
    selectedGroup: string = "";
    parameterGroup: string = "";
    currentUser: V2User = new V2User();

    userSelectedValue: string = "";
    isDefault: boolean = true;
    indexType: IndexType_T = IndexType_T.defaultIndex;
    noEditMode: boolean = false;
    isAssetEditMode: boolean = false;

    filteredAssets: Array<AssociatedLearningWithProgress> = new Array<AssociatedLearningWithProgress>();
    filteredLearningPaths: Array<AssociatedLearningWithProgress> = new Array<AssociatedLearningWithProgress>();
    filteredWorkflows: Array<AssociatedLearningWithProgress> = new Array<AssociatedLearningWithProgress>();
    filteredCheatSheets: Array<AssociatedLearningWithProgress> = new Array<AssociatedLearningWithProgress>();
    filteredVideos: Array<AssociatedLearningWithProgress> = new Array<AssociatedLearningWithProgress>();

    contentFiltered: boolean = false;
    assestsLoaded: boolean = false;

    currentAssets: Array<AssociatedLearningWithProgress> = new Array<AssociatedLearningWithProgress>();
    filterText: string = "";

    fullGroup: Group = new Group();
    groupLoaded: boolean = false;

    showProperties: boolean = false;
    propertiesWitValues: Array<GroupProperty> = new Array<GroupProperty>();
    completedAndActiveEnrollmentsForUser: Array<Enrollment> = new Array<Enrollment>();

    constructor(private pinnacleService: ProdGenApi, private v2Service: Apiv2Service, private authenticationService: BrowserAuthenticationService, private route: ActivatedRoute, private location: Location, private changedetectorref: ChangeDetectorRef) { }

    ngOnInit() {
        window.scroll(0, 0);


        if (this.authenticationService.AuthenticatePage() == true) {
            this.pinnacleService.getCurrentUser().subscribe(v1User => {
                // Get Associated Learning Content
                this.v2Service.getV2User(v1User.userId, "Mini").subscribe(user => {

                    this.currentUser = user.user;
                    // load the data for the current asset library/product/version
                    this.pinnacleService.getCurrentUserEnrollments([EnrollmentFilter_T.completed, EnrollmentFilter_T.inProgress]).subscribe(completedenrollres => {
                        this.completedAndActiveEnrollmentsForUser = completedenrollres;
                    });
                    for (var i = 0; i < user.user.groups.length; i++) {
                        if (user.user.groups[i].groupType == 1) {
                            this.JobRoleGroups.push(user.user.groups[i]);
                        }
                    }


                    this.route.queryParams.subscribe(queryParams => {
                        this.parameterGroup = queryParams['roleid']
                        if (this.parameterGroup) {                         

                            this.v2Service.getV2Group(this.parameterGroup, "Mini").subscribe(
                                res => {
                                    this.onJobRoleClick(this.parameterGroup);
                                },
                                err => {
                                    this.initalSetupNoParameterGroup();
                                });
                        }
                        else {
                            this.initalSetupNoParameterGroup();
                        }
                        
                    });
                });
            });
        }
    }

    initalSetupNoParameterGroup() {
        if (this.JobRoleGroups.length > 0) {
            this.selectedGroup = this.JobRoleGroups[0].groupId + "/" + this.JobRoleGroups[0].groupName;
        }

        if (this.selectedGroup != "") {
            this.v2Service.getAssociatedLearning(this.currentUser.userId).subscribe(res => {
                this.associatedLearning = res.userAssociatedLearning;

                this.loadContent(this.selectedGroup.split("/")[0]);

            });
        }
        else {//no groups. dont show things
            this.groupLoaded = true;
            this.assestsLoaded = true;
        }
    }

    loadContent(groupId: string) {
        this.currentAssets.splice(0);

        this.v2Service.getV2Group(groupId, "Full").subscribe(res => {
            this.fullGroup = res.group;
            this.getPropertiesWithValues();
            this.groupLoaded = true;

            //sort the previous and Next Job Roles
            if (this.fullGroup.previousJobRoles.length > 1) {
                this.fullGroup.previousJobRoles.sort(function (a, b) {
                    if (a.groupName.toLowerCase() < b.groupName.toLowerCase()) { return -1; }
                    if (a.groupName.toLowerCase() > b.groupName.toLowerCase()) { return 1; }
                    return 0;
                });
            }

            if (this.fullGroup.nextJobRoles.length > 1) {
                this.fullGroup.nextJobRoles.sort(function (a, b) {
                    if (a.groupName.toLowerCase() < b.groupName.toLowerCase()) { return -1; }
                    if (a.groupName.toLowerCase() > b.groupName.toLowerCase()) { return 1; }
                    return 0;
                });
            }

            this.v2Service.getV2GroupAssociatedLearningUserProgress(groupId, this.currentUser.userId.toString()).subscribe(al => {
                console.log(al);
                let tempList = new Array<AssociatedLearningWithProgress>();
                tempList.push.apply(tempList, al);

                for (let i = 0; i < tempList.length; i++) {
                    if (tempList[i].groupId == groupId) {
                        this.currentAssets.push(tempList[i]);
                    }
                }

                this.filteredAssets = this.getClonedAssets();
                this.filteredLearningPaths = this.getClonedAssetsByType("LP");

                this.filteredWorkflows = this.getClonedAssetsByType("WF");
                this.filteredCheatSheets = this.getClonedAssetsByType("CS");
                this.filteredVideos = this.getClonedAssetsByType("VID");
                this.assestsLoaded = true;

                this.changedetectorref.detectChanges();

            });

            
        });

        
    }

    onJobRoleChange() { 
        this.groupLoaded = false;
        this.assestsLoaded = false;
        this.loadContent(this.selectedGroup.split("/")[0]);
        this.updateURL(this.selectedGroup.split("/")[0]);
    }

    onJobRoleClick(groupId: string) {
        this.updateURL(groupId);
        this.groupLoaded = false;
        this.assestsLoaded = false;

        this.loadContent(groupId);

        if (this.JobRoleGroups.length > 0) {

            let indexCheck = this.JobRoleGroups.findIndex(x => x.groupId == groupId);
            if (indexCheck != -1) {
                this.selectedGroup = this.JobRoleGroups[indexCheck].groupId + "/" + this.JobRoleGroups[indexCheck].groupName;
            }
            else {
                this.selectedGroup = "Nope";
            }
        }
    }

    updateURL(groupId :string) {
        
        this.location.replaceState(this.updateURLParameter(window.location.href, 'roleid', groupId).split('#')[1]);

    }

    updateURLParameter(url, param, paramVal) {
        var newAdditionalURL = "";
        var tempArray = url.split("?");
        var baseURL = tempArray[0];
        var additionalURL = tempArray[1];
        var temp = "";
        if (additionalURL) {
            tempArray = additionalURL.split("&");
            for (var i = 0; i < tempArray.length; i++) {
                if (tempArray[i].split('=')[0] != param) {
                    newAdditionalURL += temp + tempArray[i];
                    temp = "&";
                }
            }
        }

        var rows_txt = temp + "" + param + "=" + paramVal;
        return baseURL + "?" + newAdditionalURL + rows_txt;
    }

    onChange(event): void {
        const val = event.target.value;
        if (val == "Default" || val == "Frequently Used") {
            this.isDefault = true;
        }
        else if (val == "Asset Type") {
            this.isDefault = false;
        }

    }


    userSelectedSortOption() {
        var value = sessionStorage.getItem("workGroupSortBy");
        if (value == "" || value == null || value == "Asset Name") {
            value = "Default";
        }
        if (value == "Asset Type") {
            this.isDefault = false;
        }
        else {
            this.isDefault = true;
        }
        this.userSelectedValue = value;
    }

    getContentUrl(r: AssociatedLearning): string {
        let url: string = "#";
        if (r.contentType == ContentType.Workflow) {
            url += "/workflowviewer?id=" + r.contentId + "&contentType=workflow";
        }
        else if (r.contentType == ContentType.Process) {
            url += "/workflowviewer?id=" + r.contentId + "&contentType=process";
        }
        //else if (r.contentType == LegacyTreeItemType.) {                        //NO TYPE FOR TASK
        //    url += "/workflowviewer?id=" + r.contentId + "&contentType=task";
        //}
        //else if (r.contentType == ContentType_T.step) {                         //NO TYPE FOR STEP
        //    url += "/workflowviewer?id=" + r.contentId + "&contentType=step";
        //}
        else if (r.contentType == ContentType.Document) {
            url += "/cheatsheetviewer?id=" + r.contentId;
        }
        else if (r.contentType == ContentType.Video) {
            url += "/videoviewer?id=" + r.contentId;
        }
        else if (r.contentType == ContentType.LearningPath) {
            url += "/learningcenter/series?learningPathId=" + r.contentId;
        }
        else if (r.contentType == ContentType.Course) {
            //url += "/learningcenter/series?courseId=" + r.contentId;

            if (this.completedAndActiveEnrollmentsForUser != null) {
                if (this.completedAndActiveEnrollmentsForUser.findIndex(course => course.courseId == r.contentId) == -1) {
                    url += "/learningcenter/series?courseId=" + r.contentId;
                }
                else {
                    url += "/learningcenter/courseviewer?enrollmentId=" + this.completedAndActiveEnrollmentsForUser[this.completedAndActiveEnrollmentsForUser.findIndex(course => course.courseId == r.contentId)].enrollmentId;
                }
            }
        }
        return url;

    }

    getContentImage(r: AssociatedLearning): string {
        let image: string = "";

        image = "assets/images/";

        switch (r.contentType) {
            case ContentType.Course://course
                image += ProdGenApi.getLargeDefaultImageFromType(ContentType_T.course);
                break;
            case ContentType.Document://document
                image += ProdGenApi.getLargeDefaultImageFromType(ContentType_T.cheatsheet);
                break;
            case ContentType.LearningPath://learning path
                image += ProdGenApi.getLargeDefaultImageFromType(ContentType_T.learningpath);
                break;
            case ContentType.Process://process
                image += ProdGenApi.getLargeDefaultImageFromType(ContentType_T.process);
                break;
            case ContentType.Video://video
                image += ProdGenApi.getLargeDefaultImageFromType(ContentType_T.video);
                break;
            case ContentType.Workflow://workflow
                image += ProdGenApi.getLargeDefaultImageFromType(ContentType_T.workflow);
                break;
            default:
                image += ProdGenApi.getLargeDefaultImageFromType(ContentType_T.cheatsheet);;
                break;

        }
        if (image.indexOf("default") == -1) {
            image = image.replace("assets/images/", "");
        }
        return image;
    }

    getContentLPImage(): string {
        let image: string = "";

        image = "assets/images/";
        let img = ProdGenApi.getLargeDefaultImageFromType(ContentType_T.learningpath);

        if (img.indexOf("default") == -1) {
            image = img;
        }
        else {
            image += img;
        }
        return image;
    }

    getContentWFImage(): string {
        let image: string = "";

        image = "assets/images/";
        let img = ProdGenApi.getLargeDefaultImageFromType(ContentType_T.workflow);

        if (img.indexOf("default") == -1) {
            image = img;
        }
        else {
            image += img;
        }
        return image;
    }

    getContentCSImage(): string {
        let image: string = "";

        image = "assets/images/";
        let img = ProdGenApi.getLargeDefaultImageFromType(ContentType_T.cheatsheet);

        if (img.indexOf("default") == -1) {
            image = img;
        }
        else {
            image += img;
        }
        return image;
    }

    getContentVidImage(): string {
        let image: string = "";

        image = "assets/images/";
        let img = ProdGenApi.getLargeDefaultImageFromType(ContentType_T.video);

        if (img.indexOf("default") == -1) {
            image = img;
        }
        else {
            image += img;
        }
        return image;
    }

    getClonedAssets(): Array<AssociatedLearningWithProgress> {
        let content: Array<AssociatedLearningWithProgress> = new Array<AssociatedLearningWithProgress>();

        for (var i = 0; i < this.currentAssets.length; i++) {
            content.push(this.currentAssets[i]);
        }

        return content;
    }

    getClonedAssetsByType(type: string): Array<AssociatedLearningWithProgress> {
        let content: Array<AssociatedLearningWithProgress> = new Array<AssociatedLearningWithProgress>();
        if (type == "CS") {
            for (let c of this.currentAssets) {
                if (c.contentType == ContentType.Document) {
                    content.push(c);
                }
            }
            return content;
        }

        else if (type == "VID") {
            for (let c of this.currentAssets) {
                if (c.contentType == ContentType.Video) {
                    content.push(c);
                }
            }
            return content;
        }

        else if (type == "LP") {
            for (let c of this.currentAssets) {
                if (c.contentType == ContentType.LearningPath || c.contentType == ContentType.Course) {
                    content.push(c);
                }
            }
            return content;
        }

        else if (type == "WF") {
            for (let c of this.currentAssets) {
                if (c.contentType == ContentType.Workflow || c.contentType == ContentType.Process) {
                    content.push(c);
                }
            }
            return content;
        }
    }

    toggleShowProperties() {
        if (this.showProperties) {
            this.showProperties = false
        }
        else { this.showProperties = true }
    }

    getPropertiesWithValues() {
        this.showProperties = false;
        this.propertiesWitValues = new Array<GroupProperty>();
        for (let i = 0; i < this.fullGroup.properties.length; i++) {

            let prop = this.fullGroup.properties[i];

            if (prop.propertyType == 0 && (prop.value.stringValue != "" && prop.value.stringValue != null)) {
                this.propertiesWitValues.push(prop);
            }
            else if (prop.propertyType == 1 && (prop.value.intValue != null)) {
                this.propertiesWitValues.push(prop);
            }
            else if (prop.propertyType == 2) {
                this.propertiesWitValues.push(prop);
            }
            else if (prop.propertyType == 3 && (prop.value.dateTimeValue != null)) {
                this.propertiesWitValues.push(prop);
            }
        }
    }


    getPropValue(prop: GroupProperty) {
        
        if (prop.propertyType == 0 && (prop.value.stringValue != "" && prop.value.stringValue != null)) {
            return prop.value.stringValue;
        }
        else if (prop.propertyType == 1 && (prop.value.intValue != null)) {
            return prop.value.intValue.toString();
        }
        else if (prop.propertyType == 2) {
            if (prop.value.boolValue == null || prop.value.boolValue) {
                return "False"
            }
            return true;
        }
        else if (prop.propertyType == 3 && (prop.value.dateTimeValue != null)) {
            return prop.value.dateTimeValue.toString();
        }

        return prop.name + ' has no value';
    }





    filterTextChanged() {
        this.contentFiltered = true;
        this.filteredAssets = this.getClonedAssets();

        this.filteredLearningPaths = this.getClonedAssetsByType("LP");
        this.filteredWorkflows = this.getClonedAssetsByType("WF");
        this.filteredCheatSheets = this.getClonedAssetsByType("CS");
        this.filteredVideos = this.getClonedAssetsByType("VID");

        if (this.filterText.trim().length > 0) {

            for (var i = 0; i < this.filteredAssets.length; i++) {
                if ((this.filteredAssets[i].contentName.toLowerCase().indexOf(this.filterText.toLowerCase()) == -1) &&
                    this.filteredAssets[i].contentDescription.toLowerCase().indexOf(this.filterText.toLowerCase()) == -1) {
                    this.filteredAssets.splice(i, 1);
                    i--;
                }
            }

            var splitFilterText = this.filterText.split(" ");

            for (var d = 0; d < splitFilterText.length; d++) {
                if (splitFilterText[d].trim().length > 0) {
                    for (var i = 0; i < this.filteredCheatSheets.length; i++) {
                        if (this.filteredCheatSheets[i].contentName.toLowerCase().indexOf(splitFilterText[d].toLowerCase()) == -1) {
                            this.filteredCheatSheets.splice(i, 1);
                            i--;
                        }
                    }
                }

                if (splitFilterText[d].trim().length > 0) {
                    for (var i = 0; i < this.filteredVideos.length; i++) {
                        if (this.filteredVideos[i].contentName.toLowerCase().indexOf(splitFilterText[d].toLowerCase()) == -1) {
                            this.filteredVideos.splice(i, 1);
                            i--;
                        }
                    }
                }

                if (splitFilterText[d].trim().length > 0) {
                    for (var i = 0; i < this.filteredWorkflows.length; i++) {
                        if (this.filteredWorkflows[i].contentName.toLowerCase().indexOf(splitFilterText[d].toLowerCase()) == -1) {
                            this.filteredWorkflows.splice(i, 1);
                            i--;
                        }
                    }
                }

                if (splitFilterText[d].trim().length > 0) {
                    for (var i = 0; i < this.filteredLearningPaths.length; i++) {
                        if (this.filteredLearningPaths[i].contentName.toLowerCase().indexOf(splitFilterText[d].toLowerCase()) == -1) {
                            this.filteredLearningPaths.splice(i, 1);
                            i--;
                        }
                    }
                }
            }
        }
        else {
            this.contentFiltered = false;
        }
    }


    getLocalDate(value: Date): string {
        return new Date(value).toLocaleDateString();
    }
}
