import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ProdGenApi } from './../../apiService/prodgen.api';
import { Content, ContentType_T } from './../../apiService/classFiles/class.content';
import { User } from './../../apiService/classFiles/class.users';

@Component({
  selector: 'template-recommend',
  templateUrl: './recommend.component.html',
  styleUrls: ['./recommend.component.css']
})
export class RecommendComponent implements OnInit {
    @Input('contentId') contentId: string;
    users: Array<User> = new Array<User>();
    currentUser: User = new User();

    constructor(private pinnacleService: ProdGenApi) {
        //this.loadData();

        this.pinnacleService.getCurrentUser().subscribe(res => {
            this.currentUser = res;
            if (this.currentUser.imageURL!= null && this.currentUser.imageURL.length == 0) {
                this.currentUser.imageURL = "../../assets/images/default_user.jpg";
            }

        });
    }

    ngOnChanges(changes: SimpleChanges) {


        try { this.contentId = changes['contentId'].currentValue } catch (err) { };
        this.loadData();

    }

  ngOnInit() {
  }

  loadData() {
      this.users = new Array<User>();
      if (this.contentId != null) {
          this.pinnacleService.GetContentUserRecommendations(this.contentId).subscribe(res => {
              this.users = res;
              for (let u of this.users) {
                  if (u.imageURL.length == 0) {
                      u.imageURL = "../../assets/images/default_user.jpg";
                  }
              }
          });
      }

  }

  recommendAsset()
  {
      if (this.contentId != null && this.contentId != "" && this.contentId != "00000000-0000-0000-0000-000000000000")
      {
          this.pinnacleService.SetContentUserRecommendation(this.contentId).subscribe(res => { this.users.push(this.currentUser); });
      }
  }

  currentUserRecommended(): boolean
  {
      if (this.users.findIndex(u => u.userId == this.currentUser.userId) != -1)
      {
          return true;
      }

      return false;
  }

}
