import { Directive, ElementRef, HostListener, Input } from '@angular/core';


@Directive({
	selector: '[href1]',
    host: {
        '(click)':'preventDefault($event)'
	}
})

export class LinkIntercept {
	@Input() href;
	preventDefault(event) {
        /*
		if (this.href.length > 0 ) {
			event.preventDefault();
		}
        */
	}
}