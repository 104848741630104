import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Component, OnInit, Input } from '@angular/core';
import { Content, ContentType_T } from './../../apiService/classFiles/class.content';
import { Workgroup } from './../../apiService/classFiles/class.workgroups';
import { ProdGenApi } from './../../apiService/prodgen.api';

@Component({
  selector: 'template-workgroup-card-small',
  templateUrl: './workgroup-card-small.component.html',
  styleUrls: ['./workgroup-card-small.component.css'],
  providers: [ProdGenApi]
})
export class WorkgroupCardSmallComponent implements OnInit {

  @Input('workgroup') item: Workgroup;
  @Input('themeRotation') themeRotation: number;
  @Input('isOwner') isOwner: boolean;


  constructor(private pinnacleService: ProdGenApi, private router: Router) { 
  }

  ngOnInit() {
  }

  getClassList(): string {
	  let classes: string = "row col-xs-2 col-sm-2 col-md-2 col-lg-2  wg-card clearfix ";
      let themeNum: number = (this.themeRotation % 3);

      //if (this.themeRotation < 3)
      //{
      //    themeNum = ((this.themeRotation + 1) % 3);
      //}
      //else
      //{
      //}
      //themeNum += 1;

      if (themeNum == 0) {
          themeNum = 1;
      }
      else if (themeNum == 1) {
          themeNum = 2;
      }
      else if (themeNum == 2) {
          themeNum = 3;
      }

      classes += "smallcardcolortheme" + themeNum;
      return classes;
  }

  onClickItem() {
      //let url: string = "workcenter?id=" + this.item.workgroupId; 
      let url: string = "workcenter?id=" + this.item.workgroupId;


      this.router.navigateByUrl(url).then(i => {
          if (i == false) {
              console.log("unable to navigate");
          }
      });

  }


}
