import { Component, OnInit, OnChanges, SimpleChanges, SimpleChange, Input } from '@angular/core';

import { Content, ContentType_T, Comment } from './../../apiService/classFiles/class.content';
import { ProdGenApi } from './../../apiService/prodgen.api';
import { User, UserPermissions } from '../../apiService/classFiles/class.users';
import { Workgroup } from '../../apiService/classFiles/class.workgroups';

@Component({
    selector: 'template-workgroup-discussion-view',
    templateUrl: './workgroup-discussion-view.component.html',
    styleUrls: ['./workgroup-discussion-view.component.css'], 
    providers: [ProdGenApi],
})
export class WorkgroupDiscussionViewComponent implements OnInit {
    @Input('workgroupPermissionsLevel') currentUserPermissionsLevel: string;
    @Input('workgroup') workgroup: Workgroup;
    @Input('isAdmin') currentUserisAdmin: boolean = false;
    @Input('isWGAdmin') currentUserisWGAdmin: boolean = false;
    @Input('canCreateExternalUsers') canCreateExternalUsers: boolean = false;
    @Input('currentUserId') userIdCurrent: string = "";

    comments: Array<Comment> = new Array<Comment>();
    newComment: string = "";

    commentsLoaded = true;
    contentNameLoaded = false;
    currentContentName: string = "";
    newSuggestion: string = "";

    constructor(private service: ProdGenApi) { }

    ngOnInit() {
        //this.getComments();
        
    }

    getComments() {
        //this.commentsLoaded = false;
        if (this.workgroup.workgroupId == null || this.workgroup.workgroupId.length == 0) {
            this.commentsLoaded = true;
            return;
        }
        this.service.GetContentComments(this.workgroup.workgroupId).subscribe(c => {
            for (var i = 0; i < c.length; i++) {
                if (c[i].imageURL == "") {
                    c[i].imageURL = "../../assets/images/default_user.jpg";
                }
            }
            this.comments = c;
            this.commentsLoaded = true;
        });
    }

    ngOnChanges(changes: SimpleChanges) {

        try { this.workgroup = changes['workgroup'].currentValue } catch (err) { };
        try { this.currentUserPermissionsLevel = changes['workgroupPermissionsLevel'].currentValue } catch (err) { };
        this.getComments();

    }

    onSubmitComment() {
        this.newComment = this.newComment.replace('\\', '').trim();
        if (this.newComment.length > 0) {
            this.service.SetContentComment(this.workgroup.workgroupId, "workgroup", this.newComment).subscribe(c => {
				if (c.commentId != "00000000-0000-0000-0000-000000000000") {
					if (c.imageURL == "") {
						c.imageURL = "../../assets/images/default_user.jpg";
					}

					this.comments.push(c);
					this.newComment = "";

					console.log("Comment committed");
					console.log(`userIdCurrent : ${this.userIdCurrent}`);
					console.log(`currentUserisAdmin : ${this.currentUserisAdmin}`);
					console.log(`currentUserisWGAdmin : ${this.currentUserisWGAdmin}`);
					console.log(`currentUserPermissionsLevel : ${this.currentUserPermissionsLevel}`);
					console.log(`requiredPermissionsLevel : ${this.requiredPermissionsLevel}`);
					console.log(`contentType : ${c.contentType}`);
					console.log("Current User");
					console.log(c);
                }
            });
        }
    }

    // May change in the future. The workgroup permissions
    // level required to delete comments.
    requiredPermissionsLevel: string = "owner";

    /* Delete links are hidden if the current user does
     * not have sufficient permissions to the comment,
     * but re-check to avoid injection. */
    onDeleteComment(p_commentId: String) {
        // If a comment exists and is selected
        if (p_commentId != null && p_commentId != "" && this.comments.length > 0) {
            // Find the subscribed comment object
            for (let i = 0; i < this.comments.length; i++) {
                if (this.comments[i].commentId == p_commentId) {
                    // Check that the comment was left by the current user
                    // or that the current user is an administrator
                    if (this.comments[i].userId == this.userIdCurrent ||
                        this.currentUserisAdmin || this.currentUserisWGAdmin ||
                        (this.currentUserPermissionsLevel == this.requiredPermissionsLevel)) {
                        this.service.deleteContentComment(this.workgroup.workgroupId, this.comments[i].commentId).subscribe(c => {
                            if (c) {
                                this.comments.splice(i, 1);
                            }
                        });
                    }
                }
            }
        }
    }

    // If the current content type is not "workgroup", a link to submit
    // feedback and suggestions is displayed to the user. This function
    // handles calling ProdGenAPI to deliver the input. Satisfies
    // user story 255.
    onSubmitSuggestion() {
        this.newSuggestion = this.newSuggestion.replace("'", "''").replace('\\', '').trim();
        if (this.newSuggestion != "" && this.contentNameLoaded) {
            this.service.addContentSuggestion(this.workgroup.workgroupId, "workgroup", this.newSuggestion).subscribe(r => {
                // We don't currently display a confirmation
            });
            document.getElementById("contentNotLoadedLabel").style.display = "none";
            document.getElementById("suggestionEmptyLabel").style.display = "none";
            this.newSuggestion = "";

            // Hacky way to close the modal without needing a JS controller or a JQuery import
            document.getElementById("userSuggestionCancelButton").click();
        }
        else {
            if (this.contentNameLoaded == false) {
                document.getElementById("suggestionEmptyLabel").style.display = "none";
                document.getElementById("contentNotLoadedLabel").style.display = "inline-block";
            }
            else {
                document.getElementById("suggestionEmptyLabel").style.display = "inline-block";
                document.getElementById("contentNotLoadedLabel").style.display = "none";
            }
        }
    }
}
