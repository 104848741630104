import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter, HostListener , Renderer2} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ProdGenApi } from './../../apiService/prodgen.api';
import { DescriptionFilter_T, Quiz, QuizQuestion, QuizAnswer, QuizQuestionType_T } from './../../apiService/classFiles/class.content';
import { QuizTrackingItem, QuizSessionItem, QuizSessionTracking } from './../../apiService/classFiles/class.enrollments';
import { Router, NavigationStart} from '@angular/router';
import { TranslationService } from './../../services/TranslationService';

declare var $: any;

@Component({
    selector: 'app-quizcontent',
    templateUrl: './quizcontent.component.html',
    styleUrls: ['./quizcontent.component.css']
})
export class QuizContentComponent implements OnInit {
    @Input('enrollmentId') enrollmentId: string;
    @Input('quizId') quizId: string;
    @Output('quizSubmitted') quizSubmitted?= new EventEmitter<any>();
    quiz: Quiz = new Quiz();
    quizTrackingItem: QuizTrackingItem = new QuizTrackingItem();
    quizQuestions: Array<QuizQuestion> = new Array<QuizQuestion>();
    quizAnswers: Array<QuizAnswer> = new Array<QuizAnswer>();
    currentQuestion: QuizQuestion = null;
    currentAnswers: Array<QuizAnswer> = null;
    questionIndex: number = 0;
    correctAnswerCount: number = 0;
    startTime: Date;
    isAnswerSubmitted: boolean = false;
    isNoneSelected: boolean = false;
    isQuizSubmitted: boolean = false;

    resumeMe: boolean = false;
    quizSessionItem: QuizSessionItem = new QuizSessionItem();
    quizSessionTracking: QuizSessionTracking = new QuizSessionTracking();
    quizSessionTrackings: Array<QuizSessionTracking> = new Array<QuizSessionTracking>();
    testerWarned: boolean = false;
    quizWorking: boolean = true;
    resultsShown: boolean = false;
    limitDiff: number = 0;
    qap: number = 0;
    answCorrect: number = 0;
    quizStatus: number = -1;
    inProcQ: string = "";
    submitAnyway: boolean = false;
    allowRetakes: boolean = false;
    maxAttempts: number = 1;
    enrollAttempts: number = 0;
    retakeQuiz: number = -1;
    allowTake: boolean = false;
    quizAnswersLoaded: boolean = false;  
    translatedTitle: string = "";
    //toFlag = false;

    constructor(private service: ProdGenApi, private _sanitizer: DomSanitizer, private renderer: Renderer2, private router: Router, private translationService: TranslationService) {
        this.router.events
            .subscribe((event) => {
                if (event instanceof NavigationStart) {
                    if (this.inProcQ == "quizQ") {
                        let navNote = this.translationService.getTranslationFileData("QUIZCONTENT.NavigationStartNote");
                        let res = alert(navNote);
                        this.submitAnyway = true;
                        this.submitAnswer();
                        this.submitAnyway = false;
                        this.inProcQ = "";

                        location.href = "#/home/";
                    }     
                }
            });
    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
        try {
            //this.quizId = changes['quizId'].currentValue;
            if (changes['quizId'] != null) {
                this.quizId = changes['quizId'].currentValue;
                this.loadData();
            }
        } catch (err) { };
        try {
            this.enrollmentId = changes['enrollmentId'].currentValue;
        } catch (err) { };
        //this.loadData();
    }

    loadData() {
        if (this.quizId != null && this.quizId != "") {
            this.allowTake = false;
            this.quizAnswersLoaded = false;
            this.service.getEnrollmentQuiz(this.enrollmentId, this.quizId, DescriptionFilter_T.formatted).subscribe(c => {
                this.quiz = c;
                window.scroll(0, 0);

                this.service.GetQuizQuestions(this.quizId, this.quiz.randomOrder).subscribe(q => {
                    this.quizQuestions = q;

                    let subAnswers = this.service.GetQuizAnswers(this.quizId).subscribe({
                        next: (answers: Array<QuizAnswer>) => {
                            this.quizAnswers = answers;
                        },
                        error: () => {
                        },
                        complete: () => {
                            subAnswers.unsubscribe();
                            this.quizAnswersLoaded = true; 
                        }
                    });


                    if (this.enrollmentId != null || this.enrollmentId != "") {
                        this.service.GetQuizEnrollmentTracking(this.enrollmentId as string, this.quizId).subscribe(q => {
                            this.quizTrackingItem = q;

                            if (this.quizTrackingItem.quizSessionItem != null) {
                                //console.log(1);
                                this.answCorrect = this.quizTrackingItem.quizSessionItem[0].quizAnswersCorrect;
                                this.qap = this.quizTrackingItem.quizSessionItem[0].quizAnswersPossible;
                                this.resumeMe = true; 
                                this.retakeQuiz = 0;
                            }
                            else {
                                this.resumeMe = false;
                                //console.log(2)
                                this.allowRetakes = this.quizTrackingItem.AllowQuizRetakes;
                                this.maxAttempts = this.quizTrackingItem.MaxQuizAttempts;
                                this.enrollAttempts = this.quizTrackingItem.EnrollAttemptCount;

                                if (this.enrollAttempts == 0) {
                                    this.retakeQuiz = 1;
                                }
                                else {
                                    if (!this.allowRetakes) {
                                        this.retakeQuiz = -1;
                                    }
                                    else {
                                        if ((this.enrollAttempts < this.maxAttempts) && this.quizTrackingItem.quizStatus == 1 && this.quizTrackingItem.quizAnswersPass > 0) {
                                            this.retakeQuiz = 1;
                                        }
                                        else {
                                            this.retakeQuiz = -1;
                                        }
                                    }
                                }
                            }
                            this.allowTake = true;
                        });
                    }
                });
                if (this.quiz != null && this.quiz.name != null) {
                    this.service.translateText(this.quiz.name as string, "en", false).subscribe(res => {
                        this.translatedTitle = res;
                    });
                }
                
            }, err => {
                if (err.status == 409) {
                    //console.log("data unavailable to subscription");
                    this.quiz.quizId = this.quizId;
                    this.quiz.description = "This content is unavailable with your subscription selection.";
                }
                });

        }
    }

    takeQuiz() {
        this.setQuizSessionData(true);

        if (this.quizQuestions.length > 0) {

            this.inProcQ = "quizQ";
            this.currentQuestion = this.quizQuestions[0];
            this.questionIndex = 0;
            this.isAnswerSubmitted = false;
            this.correctAnswerCount = 0;
            this.startTime = new Date(Date.now());
            this.currentAnswers = this.getQuestionAnswers(this.currentQuestion.questionId);

            $('#takeQuizModal').modal('show');

            if (this.currentQuestion.questionType == QuizQuestionType_T.fillInBlank) {
                $('.quizfillinbox').val('');
                $('.quizfillinbox').attr('disabled', false);
                var elem = document.getElementsByClassName("quizfillinbox");

                for (var i = 0; i < elem.length; i++) {
                    elem[i].removeAttribute("disabled");
                }
            }
        }
    }

    resumeQuiz() {
        //If quiz was NOT completed, resume from where user left off 
        //(we no longer want to allow user to cancel quiz)
        this.setQuizSessionData(false);

        if (this.quizQuestions.length > 0) {
            this.inProcQ = "quizQ";
            this.currentQuestion = this.quizQuestions[0];

            this.questionIndex = 0;
            this.isAnswerSubmitted = false;
            this.correctAnswerCount = 0;
            this.startTime = new Date(Date.now());

            this.currentAnswers = this.getQuestionAnswers(this.currentQuestion.questionId);

            $('#takeQuizModal').modal('show');

            if (this.currentQuestion.questionType == QuizQuestionType_T.fillInBlank) {
                $('.quizfillinbox').val('');
                $('.quizfillinbox').attr('disabled', false);
                var elem = document.getElementsByClassName("quizfillinbox");

                for (var i = 0; i < elem.length; i++) {
                    elem[i].removeAttribute("disabled");
                }
            }
        }
    }

    isAnswerCorrect(): boolean {
        if (this.currentQuestion.questionType == QuizQuestionType_T.multipleChoiceSingleSelect ||
            this.currentQuestion.questionType == QuizQuestionType_T.multipleChoiceMultiSelect ||
            this.currentQuestion.questionType == QuizQuestionType_T.trueFalse) {

            for (let ans of this.currentAnswers) {
                var radio = $('#' + ans.answerId);
                radio.attr('disabled', true);
            }

            for (let a of this.currentAnswers) {
                var radio = $('#' + a.answerId);
                let isChecked = radio.is(':checked');

                if (a.isAnswer != isChecked) {
                    return false;
                }
            }
            return true;
        }
        else {
            for (let ans of this.currentAnswers) {
                var input = $('#' + ans.answerId).val();
                $('#' + ans.answerId).attr('disabled', true);
                let txt: string = input;

                for (let a of this.currentAnswers) {
                    if (txt != null && txt != "") {
                        txt = txt.trim().toLowerCase();
                        if (txt == a.answerText.toLowerCase()) {
                            return true;
                        }
                    }
                }
            }
            return false;
        }
        //return false;
    }

    submitAnswer() {
        let answerSubmittedMulti = false;
        let answerSubmittedFib = false;

        if (this.currentQuestion.questionType == QuizQuestionType_T.multipleChoiceSingleSelect ||
            this.currentQuestion.questionType == QuizQuestionType_T.multipleChoiceMultiSelect ||
            this.currentQuestion.questionType == QuizQuestionType_T.trueFalse) {
            let pipedAnswers = '';
            let k: number = 0;
            for (let a of this.currentAnswers) {
                var radio = $('#' + a.answerId);
                let isChecked = radio.is(':checked');

                k++

                if (isChecked) {
                    this.isAnswerSubmitted = true;
                    this.isNoneSelected = false;

                    let text = 'Image';
                    if (a.answerText == '' && a.hasImage == true) {
                        pipedAnswers = pipedAnswers + '|' + text + k.toString();
                    }
                    else {
                        if (a.answerText != '') {
                            pipedAnswers = pipedAnswers + '|' + a.answerText;
                        }
                    }

                    if (this.currentQuestion.questionType != QuizQuestionType_T.multipleChoiceMultiSelect) {
                        break;
                    }
                    else {
                        answerSubmittedMulti = true;
                    }
                }
                else {
                    if (this.submitAnyway == false) {
                        this.isAnswerSubmitted = false;
                        this.isNoneSelected = true;
                    }
                    else {
                        this.isAnswerSubmitted = true;
                        this.isNoneSelected = false;
                        pipedAnswers = pipedAnswers + '|' + this.inProcQ;
                    }
                }
            }
            pipedAnswers = pipedAnswers.replace('|', '');

            try {
                this.quizTrackingItem.quizSessionItem[0].quizSessionTracking[this.questionIndex].givenAnswer = pipedAnswers;
            }
            catch(error){
                console.log(error);
                this.isAnswerSubmitted = false;
                window.alert("Something has gone wrong, please reload the page.");
                return false;
            }
        }
        else {
            var pipedAnswers = '';
            for (let a of this.currentAnswers) {
                var input = $('#' + a.answerId).val();

                if (input == null || input == "") {
                    if (this.submitAnyway == false) {
                        this.isAnswerSubmitted = false;
                        this.isNoneSelected = true;
                    }
                    else {
                        this.isAnswerSubmitted = true;
                        this.isNoneSelected = false;
                        pipedAnswers = pipedAnswers + '|' + this.inProcQ;
                    }
                }
                else {
                    this.isAnswerSubmitted = true;
                    this.isNoneSelected = false;

                    pipedAnswers = pipedAnswers + '|' + input;

                    answerSubmittedFib = true;//we only need 1 correctly submitted answer for Fib type
                }
            }
            pipedAnswers = pipedAnswers.replace('|', '');

            try {
                this.quizTrackingItem.quizSessionItem[0].quizSessionTracking[this.questionIndex].givenAnswer = pipedAnswers;
            }
            catch (error) {
                //console.log(error);
                this.isAnswerSubmitted = false;
                window.alert("Something has gone wrong, please reload the page.");
                return false;
            }
        }

        if (this.currentQuestion.questionType == QuizQuestionType_T.multipleChoiceMultiSelect) {
            this.isAnswerSubmitted = answerSubmittedMulti;
            answerSubmittedMulti = false;
        }

        if (this.currentQuestion.questionType == QuizQuestionType_T.fillInBlank) {
            this.isAnswerSubmitted = answerSubmittedFib;
            answerSubmittedFib = false;
        }

        if (this.submitAnyway == true) {
            $('#takeQuizModal').modal('hide');
        }

        if (this.isAnswerSubmitted) {
            if (this.isAnswerCorrect() == true) {
                this.answCorrect++;
                this.correctAnswerCount++;
                this.quizTrackingItem.quizSessionItem[0].quizSessionTracking[this.questionIndex].isCorrect = true;
            }

            this.quizTrackingItem.quizSessionItem[0].quizSessionTracking[this.questionIndex].isAnswered = true;

            this.quizTrackingItem.quizSessionItem[0].quizSessionTracking[this.questionIndex].correctAnswer = this.getCorrectAnswersPipedStr(this.currentAnswers);

            if (this.quizTrackingItem.quizSessionItem[0].quizSessionTracking[this.questionIndex].isCorrect != true) {
                this.quizTrackingItem.quizSessionItem[0].quizSessionTracking[this.questionIndex].isCorrect = false;
            }

            this.submitQuizAnswer();
        }
    }


    submitQuizAnswer() {
        this.quizTrackingItem.quizSessionItem[0].quizSessionTracking[this.questionIndex].answerDatetime = new Date(Date.now());

        if (this.resumeMe == false) {
            this.quizTrackingItem.quizAnswersCorrect = this.correctAnswerCount;
            this.quizTrackingItem.quizAnswersPossible = this.quizQuestions.length;
        }
        else {
            this.quizTrackingItem.quizAnswersCorrect = this.answCorrect;//this.addToExistingTotal ? inProgCorrect + 1 : inProgCorrect;
            this.quizTrackingItem.quizAnswersPossible = this.qap;
        }

        this.quizTrackingItem.quizSessionItem[0].quizAnswersCorrect = this.quizTrackingItem.quizAnswersCorrect;
        this.quizTrackingItem.quizSessionItem[0].quizAnswersPossible = this.quizTrackingItem.quizAnswersPossible;

        console.warn("SETTING - ", this.quizTrackingItem.quizSessionItem[0]);
        this.service.SetQuizSessionAnswer(
            this.questionIndex,
            this.quizTrackingItem.quizSessionItem[0]
        ).subscribe(qsi => {
            //remove tracking item from session object or return updated...  
            let trackingUpdatedId = qsi;
            this.updateTrackingId(qsi, this.questionIndex);
        });

        if (this.questionIndex < (this.quizQuestions.length - 1)) {
            this.nextQuestion();
        }
        else {
            //submit quiz will show
            this.isAnswerSubmitted == true;
            this.submitQuiz();
            //
        }

    }

    submitQuiz() {
        this.inProcQ = "";
        if (this.resumeMe == false) {
            this.quizTrackingItem.quizAnswersPossible = this.quizQuestions.length;
            this.quizTrackingItem.quizAnswersCorrect = this.correctAnswerCount;

            this.quizTrackingItem.quizSessionItem[0].quizAnswersCorrect = this.quizTrackingItem.quizAnswersCorrect;
            this.quizTrackingItem.quizSessionItem[0].quizAnswersPossible = this.quizTrackingItem.quizAnswersPossible;
        }
        else {
            this.quizTrackingItem.quizAnswersPossible = this.qap;

            this.quizTrackingItem.quizSessionItem[0].quizAnswersCorrect = this.quizTrackingItem.quizAnswersCorrect;
            this.quizTrackingItem.quizSessionItem[0].quizAnswersPossible = this.quizTrackingItem.quizAnswersPossible;
        }

        this.quizTrackingItem.quizAnswersPass = this.quiz.usePassingNumber ? this.quiz.passingNumber : 0;
        this.quizTrackingItem.quizSessionItem[0].quizAnswersPass = this.quizTrackingItem.quizAnswersPass;

        this.quiz.status = this.getQuizStatus() as string;
        this.quizTrackingItem.quizSessionItem[0].quizStatus = this.getQuizStatus();
        this.quizStatus = (this.quizTrackingItem.quizSessionItem[0].quizStatus == 'FAIL') ? 1 : ((this.quizTrackingItem.quizSessionItem[0].quizStatus == 'PASS') ? 0 : -1);

        this.quizTrackingItem.quizSessionItem[0].isComplete = this.getCompleteStatus();
        this.quizTrackingItem.quizSessionItem[0].endDatetime = new Date(Date.now());

        this.quizWorking = false;
        this.resultsShown = true;

        let lpcScore = this.resumeMe === false ? this.correctAnswerCount : this.quizTrackingItem.quizAnswersCorrect;

        this.service.SetQuizEnrollmentTracking(
            this.enrollmentId as string,
            this.quiz.quizId as string,
            lpcScore,
            this.startTime,
            new Date(Date.now()),
            this.quizTrackingItem.quizSessionItem[0]
        ).subscribe(res => {
            this.isQuizSubmitted = true;
            // reload the data

            this.loadData();

            this.quizSubmitted.emit({
                quiz: this.quiz
            });
            
            });
    }

    continueCourse() {
        $('#takeQuizModal').modal('hide');
        this.quizWorking = true;
    }

    setQuizSessionData(getData: boolean): void {
        //If no current instance of quiz set one up
        //otherwise set data to current instance
        let limitVal = this.quiz.useLimitedQs ? this.quiz.limitedQs : 0;
        this.limitDiff = this.quizQuestions.length - limitVal;
        let possCt = this.quizQuestions.length > 0 ? this.quizQuestions.length : 0;

        if (getData == false) {
            this.answCorrect = this.quizTrackingItem.quizSessionItem[0].quizAnswersCorrect;

            let questions: Array<QuizQuestion> = new Array<QuizQuestion>();
            for (let qId of this.quizTrackingItem.quizSessionItem[0].quizSessionTracking) {
                for (let que of this.quizQuestions) {
                    if (qId.questionId == que.questionId) {
                        que.sequence = qId.seq;
                        questions.push(que);
                        break;
                    }
                }
            }

            let j = 0;
            let ct = this.quizQuestions.length;
            for (let q of questions) {
                this.quizQuestions.push(q);
                if (j == 0) {
                    this.quizQuestions.splice(j, ct);
                }
                j++
            }
        }
        else {
            
            this.quizSessionItem = new QuizSessionItem();
            this.quizSessionTrackings = new Array<QuizSessionTracking>();
            this.quizTrackingItem.quizSessionItem = new Array<QuizSessionItem>();

            this.service.getCurrentUser().subscribe(user => {
                var tenant = ProdGenApi.getCurrentTenantId();

                //loading session data;
                this.quizSessionItem.startDatetime = new Date(Date.now());
                this.quizSessionItem.isComplete = false;
                this.quizSessionItem.quizId = this.quizId;
                this.quizSessionItem.enrollId = this.enrollmentId;
                this.quizSessionItem.userId = user.userId;
                this.quizSessionItem.tenantId = tenant;
                this.quizSessionItem.quizStatus = '';

                this.quizTrackingItem.quizSessionItem.push(this.quizSessionItem);
                this.quizTrackingItem.quizSessionItem[0].quizSessionTracking = new Array<QuizSessionTracking>();

                if (this.limitDiff > 0 && this.quizQuestions.length > limitVal && this.limitDiff != this.quizQuestions.length) {
                    for (let j = 0; j < this.limitDiff; j++) {
                        this.quizQuestions.pop();
                    }
                }

                for (let qt of this.quizQuestions) {

                    this.quizSessionTracking = new QuizSessionTracking();

                    this.quizSessionTracking.questionId = qt.questionId;
                    this.quizSessionTracking.questionType = this.getQTypeString(qt.questionType);
                    this.quizSessionTracking.questionText = qt.questionText;
                    this.quizSessionTracking.seq = qt.sequence;

                    this.quizSessionTracking.isAnswered = false;
                    this.quizSessionTracking.isCorrect = false;
                    this.quizSessionTracking.givenAnswer = '';

                    this.quizSessionTracking.tenantId = this.quizSessionItem.tenantId;

                    var i = 0;
                    for (let at of this.quizAnswers) {
                        if (this.quizSessionTracking.questionId == at.questionId) {
                            i = i + 1;
                            //}

                        switch (i) {
                            case 1: {
                                this.quizSessionTracking.correctAnswer = at.answerText;
                                break;
                            }
                            case 2: {
                                this.quizSessionTracking.correctAnswer = this.getCorrectAnswersPipedStr(this.getQuestionAnswers(at.questionId));
                                break;
                            }
                        }
                    }
                }

                    this.quizTrackingItem.quizSessionItem[0].quizSessionTracking.push(this.quizSessionTracking);

                }
                this.submitSessionData();
            });
        }

       
    }

    submitSessionData(): void {
        
        this.quizTrackingItem.quizSessionItem[0].quizAnswersPossible = this.quizQuestions.length;
        this.quizTrackingItem.quizSessionItem[0].quizAnswersPass = this.quiz.usePassingNumber ? this.quiz.passingNumber : 0;
        this.quizTrackingItem.quizSessionItem[0].quizAnswersCorrect = 0;


        //if sessionid does not exist insert, otherwise wait until answer
        //or quiz submission to update session data


        let sesId: String = this.quizTrackingItem.quizSessionItem[0].quizSessionId;
       
        if (sesId == null || sesId == '') {//start should already be set
            this.service.SetQuizSessionData(
                this.quizTrackingItem.quizSessionItem[0]
            ).subscribe(ses => {
                //get return session id for tracking
                if (ses != null && ses != '') {
                    this.updateSessionId(ses);
                     
           
                }


            });
        }
    }

    nextQuestion() {
        if (this.questionIndex < this.quizQuestions.length) {
            if (this.currentQuestion.questionType == QuizQuestionType_T.fillInBlank) {
                $('#' + this.currentAnswers[0].answerId).val('');
                $('#' + this.currentAnswers[0].answerId).attr('disabled', false);
            }

            this.questionIndex++;
            this.isAnswerSubmitted = false;
            this.currentQuestion = this.quizQuestions[this.questionIndex];
            this.currentAnswers = this.getQuestionAnswers(this.currentQuestion.questionId);
        }
    }

    getQuestionAnswers(questionId: String): Array<QuizAnswer> {
        let answers = new Array<QuizAnswer>();

        answers = this.quizAnswers.filter(f => f.questionId == questionId)
        answers = answers.sort((r1, r2) => { return r1.sequence - r2.sequence });

        return answers;
    }


    getQTypeString(qType: QuizQuestionType_T): String {
        let retVal = "";

        switch (qType) {
            case QuizQuestionType_T.multipleChoiceSingleSelect: {
                retVal = "MC_Single";
                break;
            }
            case QuizQuestionType_T.multipleChoiceMultiSelect: {
                retVal = "MC_Multi";
                break;
            }
            case QuizQuestionType_T.trueFalse: {
                retVal = "TrueFalse";
                break;
            }
            case QuizQuestionType_T.fillInBlank: {
                retVal = "FillInBlank";
                break;
            }
        }

        return retVal;
    }

    getCorrectAnswersPipedStr(allAnswers: Array<QuizAnswer>): String {
        var pipedAnswers = "";
        let i = 0;
        let text = 'Image';
        for (let theseAns of allAnswers) {
            i++;
            if (theseAns.isAnswer == true) {
                if (theseAns.hasImage == true) {
                    pipedAnswers = pipedAnswers + '|' + text + i.toString();
                }
                else {
                    if (theseAns.answerText != "" && theseAns.answerText != null) {
                        pipedAnswers = pipedAnswers + '|' + theseAns.answerText;
                    }
                }
            }
        }
        pipedAnswers = pipedAnswers.replace('|', '');
        return pipedAnswers;
    }

    getPercentCorrect(): number {
        if (this.quiz.useLimitedQs == true && this.quiz.limitedQs > 0) {
            return Math.round((this.quizTrackingItem.quizAnswersCorrect / this.quiz.limitedQs * 100) + .499);
        }
        else {
            return Math.round((this.quizTrackingItem.quizAnswersCorrect / this.quizTrackingItem.quizAnswersPossible * 100) + .499);
        }
    }

    getActiveDesc(): SafeHtml {
        return this._sanitizer.bypassSecurityTrustHtml(this.quiz.description as string);
    }

    getQuizStatus(): String {
        var strStatus = '';
        var noPassFail = this.quizTrackingItem.quizAnswersPass;

        if (this.getCompleteStatus() == true) {
            if (noPassFail < 1) {
                strStatus = "";
            }
            else {
                if (this.quizTrackingItem.quizAnswersCorrect >= this.quizTrackingItem.quizAnswersPass) {
                    strStatus = "PASS";
                }
                else {
                    strStatus = "FAIL";
                }
            }
        }

        return strStatus;
    }

    getCompleteStatus(): boolean {
        var isComplete = false;

        if (this.quizTrackingItem.quizSessionItem[0].quizSessionTracking != null) {
            var idx = this.quizTrackingItem.quizSessionItem[0].quizSessionTracking.length;

            if (this.quizTrackingItem.quizSessionItem[0].quizSessionTracking[idx - 1].isAnswered == true) {
                isComplete = true;
            }
        }

        return isComplete;
    }

    updateSessionId(id: string): void {
        try {
            if (id.length == 36 || id.length == 38) {
                this.quizTrackingItem.quizSessionItem[0].quizSessionId = id;

                for (let i = 0; i < this.quizTrackingItem.quizSessionItem[0].quizSessionTracking.length; i++) {
                    this.quizTrackingItem.quizSessionItem[0].quizSessionTracking[i].quizSessionId = id;
                }
            }
        }
        catch (e) { } //log an error
    }

    updateTrackingId(id: string, rec: number): void {
        try {
            //update tracking q/a with tracking id
            if ((id.length == 36 || id.length == 38) && rec > -1) {
                this.quizTrackingItem.quizSessionItem[0].quizSessionTracking[rec].quizTrackId = id;
            }
        }
        catch (e) { } //log an error
    }
    /*
    @HostListener('window:beforeunload', ['$event'])
    unloadNotification($event: any) {
        if (this.inProcQ == "quizQ") {
            this.submitAnyway = true;
            this.submitAnswer();
            this.submitAnyway = false;
            this.inProcQ = "";
        }
    }
    */ 
    @HostListener("document:keydown", ["$event"])
    onKeyDown(ev: KeyboardEvent) {
        if (this.inProcQ == "quizQ" && (ev.key === "Escape" || ev.key === "Esc" || ev.keyCode == 27)) {
            var escNote = this.translationService.getTranslationFileData("QUIZCONTENT.EscNote");
            var refreshNote = this.translationService.getTranslationFileData("QUIZCONTENT.RefreshNote");
            var res = confirm(escNote + refreshNote);
            if (res == true) {
                this.inProcQ == "quizQ";
                this.submitAnyway = true;
                this.submitAnswer();
                this.submitAnyway = false;
                this.inProcQ = "";

                location.href = "#/home/";
            }
            else {
                ev.preventDefault();
            }
        }
    }
}
