import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { ProdGenApi } from './../../apiService/prodgen.api';
import { Content, ContentType_T } from './../../apiService/classFiles/class.content';
import { TenantSubscription, Subscription } from './../../apiService/classFiles/class.partners';
import { User } from './../../apiService/classFiles/class.users';
import { DomSanitizer } from '@angular/platform-browser';
import { AppControlService, MsgBxStringVals } from './../../AppControlService';
import { Organization } from '../../apiService/classFiles/class.organizations';

declare var $: any;

@Component({
    selector: 'template-partner-new-customer',
    templateUrl: './partnernewcustomer.component.html',
    styleUrls:  ['./partnernewcustomer.component.css']
})

export class PartnerNewCustomerComponent implements OnInit {
    @Output('accountCreated') accountCreated?= new EventEmitter<any>();
    static staticThis: PartnerNewCustomerComponent;

    partnerSubscriptions: Array<Subscription> = new Array<Subscription>();
    additonalSubscriptionsArray: Array<TenantSubscriptionPartner> = null;
    subscriptionToAdd = new TenantSubscriptionPartner();
    selPartnerSubscription: Subscription = new Subscription();
    newExpirationDate: string = "";
    createdOrg: Organization = new Organization();
    isSaving: boolean = false;


  model: any = {
    newAccountName: "",
    crmRefID: "",
    adminName: "",
      adminEmail: "",
      poNumber:"",
      maxUsers: 5,
    subscriptions: new Array<TenantSubscription>()
  };
  
    constructor(private pinnacleService: ProdGenApi, private sanitizer: DomSanitizer, private changeRef: ChangeDetectorRef, private controlService: AppControlService) {
        PartnerNewCustomerComponent.staticThis = this;
    }

    ngOnChanges(changes: SimpleChanges) {
		this.loadData();
    }


    ngOnInit() {
        $('#newCustomer').on('show.bs.modal', function (event) {
            if (event.target.id == 'newCustomer') {
                PartnerNewCustomerComponent.staticThis.onShowDialog();
            }
        });

        this.loadData();
    }

    onShowDialog() {
        this.model.newAccountName ="";
        this.model.crmRefID ="";
        this.model.adminName ="";
        this.model.adminEmail = "";
        this.model.poNumber = "";
        this.model.maxUsers = 5;
        this.additonalSubscriptionsArray = new Array<TenantSubscriptionPartner>();

        $('#name').val("");
        $('#admin').val("");
        $('#adminName').val("");
        $('#adminEmail').val("");
        $('#poNumber').val("");
        $('#maxUsers').val("5");

        this.changeRef.detectChanges();

    }

    loadData()
    {
        // lookup the tenant information
        //this.pinnacleService.partner
        this.partnerSubscriptions = new Array<Subscription>();
        this.additonalSubscriptionsArray = new Array<TenantSubscriptionPartner>();
    // get the subscriptions thid partner is allowed to sell
        this.pinnacleService.getPartnerSubscriptions().subscribe(subs => {
            for (let s of subs) {
                this.partnerSubscriptions.push(s);
            }

        });


    }



    onSubmit() {

        if (this.model.newAccountName == '') {
            alert('Please fill in the required field \'Account Name\'.');
            return;
        }
        else if (this.model.adminName == '') {
            alert('Please fill in the required field \'Admin Name\'.');
            return;
        }
        else if (this.model.adminEmail == '') {
            alert('Please fill in the required field \'Admin Email\'.');
            return;
        }
        else if (this.additonalSubscriptionsArray.length == 0) {
            alert('Please add the subscriptions for this customer.');
            return;
        }

        this.isSaving = true;
            // create the account
            this.pinnacleService.addCustomerAccount(this.model.newAccountName, this.model.adminName, this.model.adminEmail, true, this.model.maxUsers).subscribe(res => {

                if (res.organizationId == null || res.organizationId == "0") {
                    alert("There was problem creating a new tenant for this organization");
                    this.isSaving = false;

                    return;
                }

                this.createdOrg = res;


            // account was created, now get the tenantid that was created from the orgid
            this.pinnacleService.getPartnerAccountTenants(res.organizationId as string).subscribe(tenants => {
                // now add the pinnacle lite subscriptions to this tenant
                // should only be 1 tenant since we just created the account
                if (tenants.length != 1) {
                    alert("There was problem creating a new tenant for this organization");
                    this.isSaving = false;
                    return;
                }

                let orgId: number = +tenants[0].organizationId;
                let tenantId: string = tenants[0].tenantId as string;


                if (this.model.crmRefID.length > 0) {
                    this.pinnacleService.updateCustomerAltId(+res.organizationId, this.model.crmRefID).subscribe(res => {
                        this.addSubscriptionsToTenant(orgId, tenantId);
                    });
                }
                else {

                    this.addSubscriptionsToTenant(orgId, tenantId);
                }


            })
        }, error => {

            alert("A problem was encountered creating this account. The likely cause is that the email address used for the Admin is already in use.");
            this.isSaving = false;
        });
    }

    addSubscriptionsToTenant(orgId:number, tenantId:string) {
        let x = 0;
        let paidSubscription = new Array<TenantSubscription>();

        for (let s of this.additonalSubscriptionsArray) {

            // if it is a paid eagle point subscription, we need to add it to an order
            if (s.isPinnacleLite == false && s.partnerId.toLowerCase() =="8380a9f3-d671-4664-aecb-c883ee418d09") {

                
                paidSubscription.push(s);
            }

            this.pinnacleService.addCustomerTenantSubscription(orgId,
                tenantId as string, s.subscriptionID, s.quantity,
                ProdGenApi.currentUser.email, ProdGenApi.currentUser.name, s.expirationDate).subscribe(sub => {

                    x++;
                    // see if we are done
                    this.checkIfAllSubscriptionsSubmitted(x, this.additonalSubscriptionsArray.length, orgId, tenantId, this.model.poNumber, paidSubscription);
                });

        }

    }

    checkIfAllSubscriptionsSubmitted(curSub: number, maxSubs: number, orgId:number, tenantId:string, poNumber:string, paidSubscriptions: Array<TenantSubscription>): boolean {
        if (curSub == maxSubs) {

            let msg: string = "The new account has been successfully created.<br/><br/>An email has been sent to the designated order processsing person in your organization to review and pass along to the customer."

            if (paidSubscriptions.length > 0) {
                msg += "<br/>";
                msg += "<br/>";
                msg += "* Eagle Point Software will receive a notification for this account because one or more subscriptions require payment."
            }


            var v_Msg = new MsgBxStringVals();
            v_Msg.body = msg;
            v_Msg.title = ``;
            this.controlService.openMessageBoxPopUp(v_Msg);
            

            $("#newCustomer").modal("hide");

            this.accountCreated.emit({
                organizaton: this.createdOrg
            });

            if (paidSubscriptions.length > 0) {
                
                // these go on an order to be sent to Eagle Point
                this.pinnacleService.createUpgradeOrderRequest(orgId, tenantId, false, poNumber, paidSubscriptions).subscribe(res => {
                }
                );

            }
            this.isSaving = false;

            return true;
        }
        return false;
    }
    
    addSubscription() {
        this.subscriptionToAdd = new TenantSubscriptionPartner();
        this.subscriptionToAdd.isTrial = false;
        this.subscriptionToAdd.quantity = 1;
        this.subscriptionToAdd.isPinnacleLite = false;
        this.subscriptionToAdd.expirationDate = new Date();
        this.subscriptionToAdd.expirationDate.setFullYear(this.subscriptionToAdd.expirationDate.getFullYear() + 1);
        this.subscriptionToAdd.unlimitedExpirationDate = this.subscriptionToAdd.expirationDate;
        this.subscriptionToAdd.partnerId = "";

        this.newExpirationDate = this.subscriptionToAdd.expirationDate.toISOString().substring(0, 10);

    }

    removeSubscription(index: number) {

        this.additonalSubscriptionsArray.splice(index, 1); // removes temporarily
        // will save the subscription (s) info from the click event , so we can use that when submitting the request to delete permanently.
    }

    submitNewSubscription(closeBox: boolean) {
        //Do something
        let sub = this.subscriptionToAdd;
        let strDate = this.newExpirationDate;//$("#expirationDate").val();

        var parts = strDate.split('-');
        // Please pay attention to the month (parts[1]); JavaScript counts months from 0:
        // January - 0, February - 1, etc.

        sub.subscriptionID = this.selPartnerSubscription.subscriptionID;
        sub.name = this.selPartnerSubscription.name as string;
        sub.tenantID = '00000000-0000-0000-0000-000000000000'; // filled in after account created

        sub.unlimitedExpirationDate = new Date(Number.parseInt(parts[0]), Number.parseInt(parts[1]) - 1, Number.parseInt(parts[2]));
        sub.expirationDate = sub.unlimitedExpirationDate;
        sub.isPinnacleLite = this.selPartnerSubscription.isPinnacleLite;
        sub.partnerId = this.selPartnerSubscription.partnerId;

        if (sub.name != null && sub.name.length > 0) {

            let exists = false;
            // check to see if it already exists
            for (let existing of this.additonalSubscriptionsArray) {
                if (existing.subscriptionID == sub.subscriptionID) {
                    exists = true;
                    existing.expirationDate = sub.expirationDate;
                    existing.quantity = sub.quantity;
                    existing.unlimitedExpirationDate = sub.unlimitedExpirationDate;
                }
            }

            if (exists == false) {
                this.additonalSubscriptionsArray.push(sub);
            }

            if (closeBox == true) {
                this.closeModal("addSubscriptionForNewModal");
            }
            else {
                this.subscriptionToAdd = new TenantSubscriptionPartner();

                this.subscriptionToAdd.isTrial = false;
                this.subscriptionToAdd.quantity = sub.quantity;
                this.subscriptionToAdd.isPinnacleLite = sub.isPinnacleLite;
                this.subscriptionToAdd.expirationDate = sub.expirationDate;
                this.subscriptionToAdd.unlimitedExpirationDate = sub.unlimitedExpirationDate;
                this.subscriptionToAdd.partnerId = "";
            }
        }
        else {
            var v_Msg = new MsgBxStringVals();
            v_Msg.body = "Please select a subscription.";
            v_Msg.title = ``;
            this.controlService.openMessageBoxPopUp(v_Msg);
            
        }

    }


    closeModal(id: string) {
        $('#' + id).modal('hide');

    }

}


export class TenantSubscriptionPartner extends TenantSubscription {
    partnerId: string = "";
}
