import { Component, OnInit, Input } from '@angular/core';
import { ProdGenApi } from '../../apiService/prodgen.api';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { BrowserAuthenticationService } from '../../BrowserAuthenticationService';
import { User } from '../../apiService/classFiles/class.users';
import { EnrollmentFilter_T, Enrollment } from '../../apiService/classFiles/class.enrollments';
import { Content, ContentType_T } from '../../apiService/classFiles/class.content';
import { Workgroup } from '../../apiService/classFiles/class.workgroups';
import { BrandingUpdateServiceService } from '../../services/branding-update-service.service';

@Component({
  selector: 'template-profilepanel',
  templateUrl: './profilepanel.component.html',
  styleUrls: ['./profilepanel.component.css']
})
export class ProfilepanelComponent implements OnInit {
  @Input('currentUser') currentUser: User = new User();
  isAuthenticated: boolean = false;
  companyInfoLoaded: boolean = false;
  workGroups: Array<Workgroup> = new Array<Workgroup>();
  completedCourseCount: number = 0;
  currentEnrollments: Array<Enrollment> = new Array<Enrollment>();
  frequentlyUsed: Array<Content> = new Array<Content>();
  trendingContent: Array<Content> = new Array<Content>();
  enrollmentsLoaded: boolean = false;
  canSignClientAccount: boolean = false;

    constructor(private pinnacleService: ProdGenApi, private router: Router, private _sanitizer: DomSanitizer, private authenticationService: BrowserAuthenticationService, private brandingService:BrandingUpdateServiceService) {
    if (authenticationService.AuthenticatePage() == true) {
      this.isAuthenticated = true;
      this.companyInfoLoaded = false;
      //this.currentUser.imageURL = "../assets/images/default_user.jpg" + "?" + Date.now();
      //this.pinnacleService.getCurrentUser().subscribe(u => {
        this.brandingService.profilepictureemitter.subscribe(res => { this.currentUser.imageURL = res});
        if (this.currentUser.imageURL == "") {
            this.currentUser.imageURL = "../assets/images/default_user.jpg" + "?" + Date.now();;
        }

        //this.currentUser = u;
        //this.currentUser.imageURL = u.imageURL;

        try {
          localStorage.setItem(this.currentUser.userId + "_user_image", JSON.stringify(this.currentUser.imageURL));
        }
        catch (err) {
          console.log(err);
        }


        // try to load up any previous information. this will keep the screen from flickering..?

        try {
          this.workGroups = JSON.parse(localStorage.getItem(this.currentUser.userId + "_workgroups"));
        }
        catch (err) {
          console.log(err)
        }


        try {
          this.currentEnrollments = JSON.parse(localStorage.getItem(this.currentUser.userId + "_enrollments"));
        }
        catch (err) {
          console.log(err)
        }


        try {
          this.frequentlyUsed = JSON.parse(localStorage.getItem(this.currentUser.userId + "_frequent"));
        }
        catch (err) {
          console.log(err)
        }

        try {
          this.trendingContent = JSON.parse(localStorage.getItem(this.currentUser.userId + "_trending"));
        }
        catch (err) {
          console.log(err)
        }


        this.pinnacleService.getCurrentUserEnrollments().subscribe(res => {
          for (var i = 0; i < res.length; i++) {
            if (res[i].imageURL == null || res[i].imageURL == "") {

              res[i].imageURL = "../assets/images/default_course.jpg" + "?" + Date.now();;
            }
          }
          this.currentEnrollments = res;

          //copy and sort the array. flag the lowest squences with the number of other items with the same learning path. remove non-lowest sequence.
          //copy and sort the array.
          //let condensedEnrollments: Array<Enrollment> = new Array<Enrollment>();
          //this.CondenseEnrollments()

          this.enrollmentsLoaded = true;
          /// write to Local Storage for this user
          try {
            localStorage.setItem(this.currentUser.userId + "_enrollments", JSON.stringify(this.currentEnrollments))
          }
          catch (err) {
            console.log(err);
          }

        }
        );

        this.pinnacleService.getCurrentUserEnrollments([EnrollmentFilter_T.completed]).subscribe(e => this.completedCourseCount = e.length);

       

        this.pinnacleService.getCurrentUserPermissions().subscribe(res => {
          if (res.signClientAccount == true) {
            this.canSignClientAccount = true;
          }
        });

        this.pinnacleService.getCurrentUserWorkgroups().subscribe(wgs => {
            this.workGroups = wgs;
        });
      //});
    }
  }
  

  ngOnInit() {
  }

}
