export enum NotificationFilter_T {
	all = 0,
	new = 1,
	read = 2
}

export enum NotificationStatus_T {
	new = 0,
	read = 1
}


export class Notification {
    public notificationId: String;
    public title: String;
    public description: String;
    public imageUrl: String;
    public linkUrl: String;
    public publishDate: Date;
    public tenantId: String;
    public userId: String;
    public createdByUserId: String;
    public notificationStatus: NotificationStatus_T;
}

export class NotificationPost {
	title: string;
	text: string;
    imageUrl: string;
    linkUrl: string;
    users: Array<string> = new Array<string>();
    useGroups: boolean = false;
}

