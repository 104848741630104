import { Component, OnInit, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { WidgetComponent } from '../../widget/widget.component';
import { ProdGenApi } from '../../../apiService/prodgen.api';
import { User } from '../../../apiService/classFiles/class.users';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-test-widget',
  templateUrl: './test-widget.component.html',
    styleUrls: ['./test-widget.component.css'],
    providers: [{ provide: WidgetComponent, useExisting: TestWidgetComponent }]

})
export class TestWidgetComponent extends WidgetComponent implements OnInit, AfterViewInit {
    static key = "TestWidgetComponent";

    user: User = new User();
    widgetID = "03";

    constructor(private pinnacleService: ProdGenApi, private changedetectorref: ChangeDetectorRef) {
        // call the base Widget class
        super(pinnacleService, changedetectorref);
}

    ngOnInit() {

        this.widgetName = "Test Widget"
        this.widgetDesc = "First example of creating a widget.";


        this.Load();
    }

    ngAfterViewInit() {
        this.widgetContainer.incrementRenderedWidget();
    }

    Load() {
        super.Load();


        document.getElementById("WidgetBody").appendChild(this.OverlayHTML);

        this.widgetName = "Current User Widget"
        this.widgetID = "03";
        this.widgetDesc = "This widget displays information about the currently logged in user";
        //this.widgetHeight = 3;
        //this.widgetWidth = 5;

        this.pinnacleService.getCurrentUser().subscribe(res => {
            this.user = res;
            
        });
    }

}
