import { Component, OnInit, Input, OnChanges, SimpleChanges, ApplicationRef } from '@angular/core';
import { Router } from '@angular/router';
import { ProdGenApi } from './../../apiService/prodgen.api';
import { Content, ContentType_T } from './../../apiService/classFiles/class.content';
import { User } from './../../apiService/classFiles/class.users';
import { Notification, NotificationFilter_T, NotificationStatus_T } from './../../apiService/classFiles/class.notifications';
import { AppComponent } from './../../app.component';
import { TranslationService } from '../../services/TranslationService';
import { Apiv2Service } from './../../apiService/apiv2.service';
import { KSResult, KSAssignment, AssessmentActionType, KSUser, KSMappedAssessment, KS_UI_Options } from './../../apiService/classFiles/v2-organizations';
import { parse } from 'url';
import { CreateEnrollmentFromAssessmentRequest } from './../../apiService/classFiles/class.enrollments';
import { request } from 'https';


declare var $: any;


@Component({
    selector: 'template-ks-assessment-navbar',
    templateUrl: './ks-assessment-navbar.component.html',
    styleUrls: ['./ks-assessment-navbar.component.css']
})
export class KSAssessmentNavbarComponent implements OnInit {

    assessments: Array<string> = new Array<string>();
    newCount: number = 0;

    loadingCount: number = 0;

    completedAssignments: Array<KSResult> = new Array<KSResult>();
    inProgressAssignments: Array<KSAssignment> = new Array<KSAssignment>();
    notStartedAssignments: Array<KSAssignment> = new Array<KSAssignment>();

    todaysDate: Date = new Date();

    modalCompletedAssignment: KSResult = new KSResult();

    actionFlag: AssessmentActionType;
    actionItems: Array<string> = new Array<string>();

    hasDueDate: boolean = false;
    dueDate: Date;

    templateMappings: Array<KSMappedAssessment>;

    showSpinner: boolean = false;

    getAssessmentsTimer: NodeJS.Timer

    userExistsInKS: boolean = false;

    userEmail: string = "";
    KSUser: KSUser = new KSUser();

    show_ksHomepage: boolean = false;

    constructor(private pinnacleService: ProdGenApi,
        private v2Service: Apiv2Service,
        private appRef: ApplicationRef,
        private router: Router,
        private translationService: TranslationService) {
        this.getAssessmentsTimer = setInterval(() => this.loadItems(), 10 * 60 * 1000);//10 min live
        //this.getAssessmentsTimer = setInterval(() => this.loadItems(), 10 * 1000);//10 second testing
    }

    ngOnChanges(changes: SimpleChanges) {

    }


    ngOnInit() {
        //this.userEmail = ProdGenApi.getSessionUser().email;
        this.userEmail = localStorage.getItem("userEmail");
        this.loadItems();
    }

    loadItems() {
        //Get the KS User

        if (ProdGenApi.isImpersonating()) {
            const bearerToken = ProdGenApi.getAPIV2AccessKey();
            let userId = bearerToken.userId;


            this.v2Service.getV2User(userId, "Micro").subscribe(userRes => {
                this.userEmail = userRes.user.email;
                this.v2Service.getAssessmentUserFromEmail(this.userEmail).subscribe(res => {

                    if (res) {
                        this.userExistsInKS = true;
                        this.KSUser = res;
                    }
                    else {
                        this.userExistsInKS = false;
                        this.KSUser = null;
                    }
                });
            });

        }
        else {
            this.v2Service.getAssessmentUserFromEmail(this.userEmail).subscribe(res => {
                if (res) {
                    this.userExistsInKS = true;
                    this.KSUser = res;
                }
                else {
                    this.userExistsInKS = false;
                    this.KSUser = null;
                }
            });
        }

        //Check if the UserHomepage UI option Setting
        this.v2Service.getKSIntegrationUIOptions().subscribe(res => {
            if (res) {
                let temp: KS_UI_Options = res;

                this.show_ksHomepage = temp.enableUserPageAccess;
            }
        });

        //Update this part
        this.v2Service.getKSResultsForUser().subscribe(res => {
            res.ksResults.forEach(item => {
                item.lastKnownTime = new Date(Date.parse(item.lastKnownTime.toString()));
                item.testDate = new Date(Date.parse(item.testDate.toString()));
            });
            this.completedAssignments.splice(0);
            this.completedAssignments = res.ksResults;
        });
        this.v2Service.getAssessmentsNotStartedForUser().subscribe(res => {
            res.ksAssignments.forEach(item => {
                item.sentDate = new Date(Date.parse(item.sentDate.toString()));
                item.expiryDate = item.expiryDate ? new Date(Date.parse(item.expiryDate.toString())) : null;
            });
            res.ksAssignments.filter(items => items.expiryDate > this.todaysDate)
            res.ksAssignments.sort((a, b) => {
                if (a.expiryDate < b.expiryDate) {
                    return -1;
                }
                if (a.expiryDate > b.expiryDate) {
                    return 1;
                }
                return 0;
            })
            this.notStartedAssignments.splice(0);
            this.notStartedAssignments = res.ksAssignments;
            //this.newCount = this.newCount + this.notStartedAssignments.length;

            this.v2Service.getAssessmentsInProgressForUser().subscribe(res => {
                res.ksAssignments.forEach(item => {
                    item.sentDate = new Date(Date.parse(item.sentDate.toString()));
                    item.expiryDate = item.expiryDate ? new Date(Date.parse(item.expiryDate.toString())) : null;
                });
                res.ksAssignments.filter(items => items.expiryDate > this.todaysDate);
                res.ksAssignments.sort((a, b) => {
                    if (a.expiryDate < b.expiryDate) {
                        return -1;
                    }
                    if (a.expiryDate > b.expiryDate) {
                        return 1;
                    }
                    return 0;
                })
                this.inProgressAssignments.splice(0);
                this.inProgressAssignments = res.ksAssignments;
                this.newCount = 0;
                this.newCount = this.newCount + this.inProgressAssignments.length + this.notStartedAssignments.length;
            });
        });
    }

    showCompletedAssessmentModal(result: KSResult) {
        //check if they are enrolled already in the courses.
        this.v2Service.getContentFromAssessment(result.assessmentID, result.testId).subscribe(res => {
            this.actionFlag = res.assessmentAction;
            this.actionItems = res.actionIds;
            this.templateMappings = res.templateMappings
            this.modalCompletedAssignment = result;

            $("#completedAssessmentModal").modal("show");
        });

    }

    navTrainingClick() {
        $("#completedAssessmentModal").modal("hide");
        this.router.navigateByUrl("learningcenter/courseviewer?enrollmentId=" + this.actionItems[0]);

    }

    enrollClick() {
        let requestObj = new CreateEnrollmentFromAssessmentRequest();
        requestObj.assessmentId = this.modalCompletedAssignment.assessmentID;
        requestObj.assessmentName = this.modalCompletedAssignment.testName;
        requestObj.assignmentId = this.modalCompletedAssignment.testId;
        requestObj.bHasDueDate = this.hasDueDate;
        requestObj.dueDate = this.dueDate;
        requestObj.templateMappings = this.templateMappings;
        this.showSpinner = true;
        this.pinnacleService.createEnrollmentFromAssessment(requestObj).subscribe(res => {
            $("#completedAssessmentModal").modal("hide");
            this.showSpinner = false;
        });
    }

    KS_FormModalClick() {
        this.showSpinner = true;
        if (this.KSUser == null) {
            this.showSpinner = false;
            console.error("Unable to open link");
            return;

        }
        else {
            let resultlinkURL: string = "https://online.knowledgesmart.net/Test/testReport.aspx?resultID=" + this.modalCompletedAssignment.resultID +
                "&guid=" + this.KSUser.userID;

            this.showSpinner = false;
            $("#completedAssessmentModal").modal("hide");
            window.open(resultlinkURL, '_blank').focus();
        }
    }


    KS_ExamModalClick() {
        this.showSpinner = true;

        if (this.KSUser == null) {
            this.showSpinner = false;
            console.error("Unable to open link");
            return;

        }
        else {

            this.v2Service.requestLoginToken(this.KSUser.userID).subscribe(login => {
                if (login == "") {
                    $("#ksRedirectWait").modal("hide");
                    console.error("Unable to open link");
                    return;
                }

                let resultLinkURL: string = "https://online.knowledgesmart.net/trafficcop.aspx?userid=" + this.KSUser.userID + "&logintoken=" + login + "&destination=examcertificate&resultID=" + this.modalCompletedAssignment.resultID;


                $("#completedAssessmentModal").modal("hide");
                this.showSpinner = false;
                window.open(resultLinkURL, '_blank').focus();

            });
        }
    }


    KS_FromDropDownClick(selectedAssessment: KSAssignment) {
        $("#ksRedirectWait").modal("show");

        if (this.KSUser == null) {
            $("#ksRedirectWait").modal("hide");
            console.error("Unable to open link");
            return;
        }
        else {
            this.v2Service.requestLoginToken(this.KSUser.userID).subscribe(login => {
                if (login == "") {
                    $("#ksRedirectWait").modal("hide");
                    console.error("Unable to open link");
                    return;
                }

                let resultlinkURL: string = "https://online.knowledgesmart.net/trafficcop.aspx?" + "userid=" + this.KSUser.userID +
                    "&logintoken=" + login + "&destination=taketest&inviteID=" + selectedAssessment.assessmentID;

                $("#ksRedirectWait").modal("hide");
                window.open(resultlinkURL, '_blank').focus();

            });
        }
    }


    launchUserKSHomePage() {

        $("#ksRedirectWait").modal("show");
        if (this.KSUser == null) {
            //modal?? sorry you can't?
            $("#ksRedirectWait").modal("hide");
            console.error("Unable to open link");
            return;
        }
        else {
            this.v2Service.requestLoginToken(this.KSUser.userID).subscribe(login => {
                if (login == "") {
                    //modal - Sorry again
                    $("#ksRedirectWait").modal("hide");
                    console.error("Unable to open link");
                    return;
                }

                let resultlinkURL: string = "https://online.knowledgesmart.net/trafficcop.aspx?" + "userid=" + this.KSUser.userID +
                    "&logintoken=" + login + "&destination=userhome";

                $("#ksRedirectWait").modal("hide");
                window.open(resultlinkURL, '_blank').focus();

            });
        }
    }


}
