import { Component, OnInit, Input, OnChanges, SimpleChanges, ApplicationRef } from '@angular/core';
import { Router } from '@angular/router';
import { ProdGenApi } from './../../apiService/prodgen.api';
import { Content, ContentType_T } from './../../apiService/classFiles/class.content';
import { User } from './../../apiService/classFiles/class.users';
import { Notification, NotificationFilter_T, NotificationStatus_T } from './../../apiService/classFiles/class.notifications';
import { AppComponent } from './../../app.component';

declare var $: any;

@Component({
    selector: 'template-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {
    notifications: Array<Notification> = new Array<Notification>();
    newCount: number = 0;
    getMessagesTimer: NodeJS.Timer;
    static thisFromOutside: NotificationsComponent = null;

    constructor(private pinnacleService: ProdGenApi, private appRef: ApplicationRef, private router: Router) {
        this.loadData();
        this.getMessagesTimer = setInterval(() => this.onGetMessagesTimer(), 10 * 60 * 1000); //5 min live interval
        //this.getMessagesTimer = setInterval(() => this.onGetMessagesTimer(), 10 * 1000); //10 second testing interval
        NotificationsComponent.thisFromOutside = this;

}

    ngOnChanges(changes: SimpleChanges) {

        //try { this.contentId = changes['contentId'].currentValue } catch (err) { };
        this.loadData();

    }


    ngOnInit() {
        $('#notifications-button').on('hide.bs.dropdown', function () {
            // do something…
            NotificationsComponent.thisFromOutside.markNotificationsAsRead();
        });
    }

    getNewNotifcationCount(): number
    {
        return this.newCount;
    }

    loadData()
    {
        this.notifications = new Array<Notification>();
        this.pinnacleService.getCurrentUserNotifications(NotificationFilter_T.all, 100).subscribe(res => {
            this.notifications = res;
            let count = 0;
            for (let n of this.notifications)
            {
                if (n.imageUrl == null || n.imageUrl.length == 0)
                {
                    n.imageUrl = "../assets/images/default_user.jpg";
                }
                if (n.notificationStatus == NotificationStatus_T.new)
                {
                    count++;
                }
            }
            this.newCount = count;
        });

    }

    notificationClicked(notification: Notification) {
        //if the linkurl contains "record-management",then jump to the External Learning Records Management application in the Management Portal
        if (notification.linkUrl != null && notification.linkUrl.length > 0) {
            if (notification.linkUrl.search("record-management") > -1) {
                let url = window.location.protocol + "//" + window.location.host;
                url = url.replace("4200", "4201");
                url += "/administration/#/admin/external-learning/record-management";
                var adminWindow = window.open(url, "_blank");
            }
            else {
                this.router.navigateByUrl(notification.linkUrl as string);
            }
        }
    }

    markNotificationsAsRead()
    {
        let unread = new Array<string>();
        for (let n of this.notifications)
        {
            if (n.notificationStatus == NotificationStatus_T.new)
            {
                n.notificationStatus = NotificationStatus_T.read;
                unread.push(n.notificationId as string);
            }
            else
            {
            }
        }
        if (unread.length > 0)
        {
            this.pinnacleService.markNotificationsAsRead(unread).subscribe();
        }
        this.newCount = 0
        this.appRef.tick();
    }

  onGetMessagesTimer() {
    if (AppComponent.thisFromOutside.isLoggedIn == true) {
      this.loadData();
    }
    else {
    }
        
    }

}

export enum NotificationMessageType
{
    userAddedToWorkgroup,
    userRemovedFromWorkgroup,
    contentAddedToWorkgroup,
    workgroupPermissionChanged,
    userEnrolledInCourse,
    userDroppedCourse,
    userCompletedCourse,
    courseAlmostDue,
    coursePastDue,
}
