import { Component, HostListener, ChangeDetectorRef } from '@angular/core';
import { Injectable } from '@angular/core';
import { OnInit, OnChanges, Input, SimpleChanges } from '@angular/core';
import { ProdGenApi } from './../apiService/prodgen.api';
import { Apiv2Service } from './../apiService/apiv2.service';
import { ContentType_T } from "./../apiService/classFiles/class.content"
import { ChatSettings, ChatMessage, ChatStatus_T, ChatSession, ChatUser, ChatProductVersion, ChatProduct, SupportEmail } from "./../apiService/classFiles/class.chat";
import { PartnerSupport } from "./../apiService/classFiles/class.partners";
import { User } from './../apiService/classFiles/class.users';
import { AppControlService, MsgBxStringVals } from './../AppControlService';
import { read } from 'fs';

declare var $: any;

@Component({
    selector: 'expert-chat',
    templateUrl: 'chat.component.html',
    styleUrls: ['chat.component.css'],
    moduleId: module.id
})



export class ChatComponent implements OnInit {
    @Input('chatSettings') chatSettings: ChatSettings = new ChatSettings();
	@Input('currentUser') currentUser: User = new User();
  @Input('chatID') inputChatID: string = "";
  @Input('chatSettingsLoaded') isChatSettingLoaded: boolean = false;

    name: String = "Test";
    errorMessage: String;
	chatWindowOpen: boolean = false;
	chatWindowMinimized: boolean = false;
    chatActive: boolean = false;
    chatSubject: string = "";
    chatProduct: string = "";
    chatProductVersion: string = "";
    chatReqStage: number = 0;
    chatSessionId: string = "";
    chatMessages: Array<ChatMessage> = new Array<ChatMessage>();
    reqStageTimer: NodeJS.Timer;
    getMessagesTimer: NodeJS.Timer;
	messageText: string = "";

	messageTo: ChatUser;
	messageFrom: ChatUser;
	messageTitle: string = "";
	messagesLoaded: boolean = false;

  chatName: string = "";
  supportEmailInfo: SupportEmail = new SupportEmail();
  isSupportAvailible: boolean = false;
  phoneSupportEnabled: boolean = false;
  supportMessage: string = "";
  supportPhone: string = "";
  partnerSupportInfo: PartnerSupport = new PartnerSupport();
    supportButtonDisabled: boolean = false;

    

    constructor(private pinnacleService: ProdGenApi, private controlService: AppControlService, private v2Service: Apiv2Service) {
    }

	ngOnInit(): void {
		if (this.inputChatID != null && this.inputChatID != "") {
			this.chatSessionId = this.inputChatID;


			this.chatActive = true;
			this.chatWindowOpen = true;

			this.messageTo = JSON.parse(sessionStorage.getItem("ChatTo"));
			this.messageFrom = JSON.parse(sessionStorage.getItem("ChatFrom"));
			this.messageTitle = sessionStorage.getItem("ChatTitle");
			this.messagesLoaded = true;	

			this.getMessagesTimer = setInterval(() => this.onGetMessagesTimer(), 3000); 
		}
    }

    ngOnChanges(changes: SimpleChanges) {

        try { this.chatSettings = changes['chatSettings'].currentValue } catch (err) { };
		    try { this.currentUser = changes['currentUser'].currentValue } catch (err) { };
		    try {
			    this.inputChatID = changes['chatID'].currentValue;
			    if (this.inputChatID != "") {
				    this.chatSessionId = this.inputChatID;
				    this.chatActive = true;
				    this.chatWindowOpen = true;
				    this.getMessagesTimer = setInterval(() => this.onGetMessagesTimer(), 3000); 
          }
      } catch (err) { };
      try { this.isChatSettingLoaded = changes['chatSettingsLoaded'].currentValue } catch (err) { };

      if (this.isChatSettingLoaded == false) {
        $('#btnSupportMain').prop("disabled", true);
      }
      else {
        $('#btnSupportMain').prop("disabled", false);
      }
    }

    onOpenChatWindow(): void {
        if (this.chatSettings.useZendeskSupport == true) {
           
            this.v2Service.getZendeskRedirectURL().subscribe(res => {
                
                //window.location.href = res.redirectURL;
                window.open(res.redirectURL, "_blank");
            });
            return;
        }
    
    this.pinnacleService.isWithinPartnerSupportHours(this.chatSettings.supportPartnerId).subscribe(r => {
      if (r == "True") {
        this.pinnacleService.isChatAvailible().subscribe(s => {
          if (s == true) {
            this.isSupportAvailible = true;
            this.supportMessage = "";
          }
          else {
            //create API call to get partner support info, including the off hours message
            this.pinnacleService.GetPartnerSupportInfo(this.chatSettings.supportPartnerId).subscribe(r => {
              this.partnerSupportInfo = r;
              if (this.partnerSupportInfo.AgentsUnavailableMsg != null && this.partnerSupportInfo.AgentsUnavailableMsg != "") {
                this.supportMessage = this.partnerSupportInfo.AgentsUnavailableMsg;
              }
              else {
                this.supportMessage = "";
              }
            });
            
            }

            this.pinnacleService.GetPartnerSupportInfo(this.chatSettings.supportPartnerId).subscribe(r => {

                if (r.PhoneSupportEnabled == true) {
                    this.supportPhone = r.SupportPhone;
                    this.phoneSupportEnabled = true;
                }

                else {
                    this.phoneSupportEnabled = false;
                }

            });


        });
      }
      else {
        this.isSupportAvailible = false;
        this.supportMessage = r;

      }
    });
    
      
    
		this.chatWindowOpen = !this.chatWindowOpen;
		this.chatWindowMinimized = !this.chatWindowOpen;

		if (this.messagesLoaded == false) {
			this.chatActive = false;
			this.chatSubject = "";
		}
  }

    getSelProdVersions(): ChatProductVersion[]
    {
        let vers: ChatProductVersion[] = [];

        for (let p of this.chatSettings.products)
        {
            if (p.name == this.chatProduct && p.versions != null)
            {
                vers = p.versions;
                break;
            }
        }

        return vers;
    }

    onRequestChat(): void {
      
      if (this.chatSubject.length == 0) {
        $('#chatSubject').focus();
          $('#chatSubject').focus();
          var v_Msg = new MsgBxStringVals();
          v_Msg.body = `You must fill in the Subject field.`;
          v_Msg.title = ``;
          this.controlService.openMessageBoxPopUp(v_Msg);
          event.preventDefault();
        return;
      }
        

        if (this.chatProduct.length == 0 && this.chatSettings.products.length > 0) {
            $('#product-list').focus();
            $('#chatSubject').focus();

            var v_Msg = new MsgBxStringVals();
            v_Msg.body = `You must fill in the Product field.`;
            v_Msg.title = ``;
            this.controlService.openMessageBoxPopUp(v_Msg);

            event.preventDefault();

            return;
      }
      $('#requestChatButton').prop('disabled', true);
        this.chatReqStage = 0;
        this.chatMessages = [];

        // make the request
      this.pinnacleService.requestExpertChat(this.chatSubject, this.chatProduct).subscribe(session => {
            this.chatSessionId = session.sessionId;

            if (session.sessionId == "00000000-0000-0000-0000-000000000000") {
                // error
                 alert(session.errorMessage);              
            }
            else {
                // set up timers
                // convert minutes to milliseconds

                let stageInc: number = (this.chatSettings.maxConnectWaitTime * 60 * 1000) / this.chatSettings.maxAgentsToPing;
                this.reqStageTimer = setInterval(() => this.onReqStageTimer(), stageInc);
                this.getMessagesTimer = setInterval(() => this.onGetMessagesTimer(), 3000); 
                // set up timers
                $('#waiting-msg').css('display', 'inline-block');

            }
        }, error => {
          $('#requestChatButton').prop('disabled', false);
          });

    }

    onGetMessagesTimer() {
        try {
			this.pinnacleService.getNewChatMessages().subscribe(messages => {
                if (messages.length > 0) {
                    this.chatActive = true;
                    clearInterval(this.reqStageTimer);
                    $('#waiting-msg').css('display', 'none');

                }
				let skipFirst: boolean = true;
				
                if (this.chatMessages.length > 0 || this.messagesLoaded == true)
                {
                    skipFirst = false;
                }

				

                for (var i = 0; i < messages.length; i++) {

                    if (messages[i].sessionId != this.chatSessionId)
					{
					
                        continue;
                    }

					

                    if (skipFirst == true)
					{
						

                        skipFirst = false;
                    }
                    else
					{

						this.chatMessages.push(messages[i]);

						if (this.messagesLoaded == false) {
							
							
							sessionStorage.setItem("ChatActive", "true");
							sessionStorage.setItem("ChatID", this.chatSessionId);

							this.messageTo = messages[i].from;
							this.messageFrom = messages[i].to;
							this.messageTitle = messages[i].title;

							sessionStorage.setItem("ChatFrom", JSON.stringify(this.messageFrom));
							sessionStorage.setItem("ChatTo", JSON.stringify(this.messageTo));
							sessionStorage.setItem("ChatTitle", this.messageTitle);
							this.messagesLoaded = true;
						}
					}

					
                    
					if (messages[i].body.includes("has ended the chat")) {
						this.onChatEnded();
					}
					else {
						
						//match pinnacle link
						let v_MatchArray = messages[i].body.match("pinnacle://PS_([A-Za-z])+:[0-9A-Fa-f]{8}[-]([0-9A-Fa-f]{4}[-]){3}[0-9A-Fa-f]{12}");

						if (v_MatchArray != null) {
                          
							for (var x = 0; x < v_MatchArray.length; x++) {
								let v_LinkInfo = v_MatchArray[x].split(":");
								if (v_LinkInfo.length == 3) {
									let v_Type = v_LinkInfo[1].replace("//", "");
									let v_ID = v_LinkInfo[2];
									let v_Link = "";

									if (v_Type == "PS_Cheat") {
										v_Link = "/cheatsheetviewer?id=" + v_ID;
									}
									else if (v_Type == "PS_Workflow") {
										v_Link = "/workflowviewer?id=" + v_ID + "&contentType=workflow";
									}
									else if (v_Type == "PS_Process") {
										v_Link = "/workflowviewer?id=" + v_ID + "&contentType=process";
									}
									else if (v_Type == "PS_Task" || v_Type =="PS_Milestone") {
										v_Link = "/workflowviewer?id=" + v_ID + "&contentType=task";
									}
									else if (v_Type == "PS_Step") {
										v_Link = "/workflowviewer?id=" + v_ID + "&contentType=step";
									}
									else if (v_Type == "PS_VidTutorial" || v_Type == "PS_VidArchive" || v_Type =="PS_VidUser") {
										v_Link = "/videoviewer?id=" + v_ID;
									}
									else if (v_Type == "PS_LearningPath") {
										v_Link = "/learningcenter/series?learningPathId=" + v_ID;
									}
									else if (v_Type == "PS_Course") {
										v_Link = "/learningcenter/series?courseId=" + v_ID;
									}
									else if (v_Type == "PS_Workgroup") {
										v_Link = "/workcenter?id=" + v_ID;
									}	

									messages[i].body = messages[i].body.replace(v_MatchArray[x], "<a href='#" + v_Link + "'>" + v_MatchArray[x] + "</a>");
								}
							}
						}

                        //match url
                        //^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$
						//v_MatchArray = messages[i].body.match("(http|https)\:\/\/[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,3}(\/\S*)?");

						v_MatchArray = messages[i].body.match("^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$");

						if (v_MatchArray != null) {
                 
							//for (var x = 0; x < v_MatchArray.length; x++) {
								
									
								messages[i].body = messages[i].body.replace(v_MatchArray[0], "<a href='" + v_MatchArray[0] + "' target='_blank'>" + v_MatchArray[0] + "</a>");
									
								
							//}
						}

					}
                }
				if (messages.length > 0) {
					
                    // wait for rendering to happen with binding
                    let timer: NodeJS.Timer = setInterval(() => {
                        var elem = document.getElementById('chat-body');
                        if (elem != null) {
                            clearInterval(timer);
                            elem.scrollTop = elem.scrollHeight;
                        }

                    }, 500)
                }
            });
        }
        catch (e) {
        }
    }

    onReqStageTimer() {
        this.chatReqStage++;
        if (this.chatReqStage >= this.chatSettings.maxAgentsToPing) {
            this.pinnacleService.updateChatSessionStatus(this.chatSessionId, ChatStatus_T.agentsBusy).subscribe();
            clearInterval(this.reqStageTimer);
            $('#waiting-msg').css('display', 'none');
            alert("All support agents are currently busy. Please try again later.");
            $('#requestChatButton').prop('disabled', false);
        }
        else {
            if (this.chatActive == true) {
                clearInterval(this.reqStageTimer);
            }
        }

    }

	onEndChat(): void {
        this.pinnacleService.updateChatSessionStatus(this.chatSessionId, ChatStatus_T.completed).subscribe();
        if (this.getMessagesTimer != null && this.getMessagesTimer != undefined) {
            clearInterval(this.getMessagesTimer);
        }
        this.chatActive = false;
		this.chatWindowOpen = false;
		this.chatWindowMinimized = false;

		clearInterval(this.reqStageTimer);

        this.chatMessages = [];
		this.chatSubject = "";
		sessionStorage.setItem("ChatActive", "false");
		sessionStorage.setItem("ChatID", "");
		this.messagesLoaded = false;
    }

    onChatSend(): void {
		let message: ChatMessage = new ChatMessage();
		/*
			message.to = this.chatMessages[0].from;
			message.from = this.chatMessages[0].to;
			message.title = this.chatMessages[0].title;
		*/

		

		message.to = this.messageTo;
		message.from = this.messageFrom;
		message.title = this.messageTitle;

        message.timestamp = new Date();
        message.sessionId = this.chatSessionId;
        message.conversationId = this.chatSessionId;
        message.body = this.messageText;

        try {
            this.messageText = "";
            this.chatMessages.push(message);
          this.pinnacleService.sendChatMessage(message).subscribe(result => { /*console.log("SUCCESS")*/ },
            error => {
              //console.log("ERROR");

              let erroruser: ChatUser = new ChatUser();
              erroruser.displayName = "Pinnacle System";
              

              let errormessage: ChatMessage = new ChatMessage();
              errormessage.to = this.messageTo;
              errormessage.from = this.messageTo;
              errormessage.title = "Lost Connection with Support";
              errormessage.timestamp = new Date();
              errormessage.sessionId = this.chatSessionId;
              errormessage.conversationId = this.chatSessionId;
              errormessage.body = '<b style="color:red">Connection has been lost to the support session. Please try again later.</b>';

              this.chatMessages.push(errormessage);

              this.onChatEnded();
            });

            let timer: NodeJS.Timer = setInterval(() => {
                var elem = document.getElementById('chat-body');
                if (elem != null) {
                    clearInterval(timer);
                    elem.scrollTop = elem.scrollHeight;
                }

            }, 500)

        }
        catch (e) {
            this.onChatEnded();
        }

    }

    onChatEnded(): void {
		clearInterval(this.getMessagesTimer);
		clearInterval(this.reqStageTimer);
        var btnChat = <HTMLInputElement>document.getElementById('btn-chat');
        if (btnChat != null) {
            btnChat.disabled = true;
        }

        var btnChat = <HTMLInputElement>document.getElementById('btn-input');
        if (btnChat != null) {
            btnChat.disabled = true;
        }

		sessionStorage.setItem("ChatActive", "false");

		this.messagesLoaded = false;

	}

	MinimizeChatWindow() {
		this.chatWindowOpen = false;
		this.chatWindowMinimized = true;

		
		if (this.messagesLoaded == false) {
			this.chatActive = false;
		}
	}

	getClassList(): string {
		if (this.chatWindowMinimized == true && this.chatActive == true) {
            return "btn btn-minimized themed-button";
		}
		else {
            return "btn btn-primary themed-button";
		}
	}

	saveChat()
	{
		if (this.chatName == "") {

		}
		else {
			this.pinnacleService.saveChatMessage(this.chatName, this.chatMessages).subscribe();
			$('#saveChatDialog').modal('hide');
			this.chatName = "";
		}
	}

	OpenSaveChat() {
		$('#saveChatDialog').modal('show');
  }

  onEmailExpert() {
    // send the api call
    this.supportEmailInfo.userID = this.currentUser.userId;
    this.supportEmailInfo.useremail = this.currentUser.email.trim();
    this.supportEmailInfo.emailSubject =  this.chatProduct + " " + this.chatProductVersion;
    this.supportEmailInfo.emailbody = this.chatSubject;
    //if (this.chatProduct.length == 0) {
    //  $('#ProductBox').focus();
    //  return;
    //}
    //if (this.chatProductVersion.length == 0) {
    //  $('#VersionBox').focus();
    //  return;
    //}
    //if (this.chatSubject.length == 0) {
    //  $('#chatSubject').focus();
    //  return;
    //}

    if (this.chatSubject.length == 0) {
        $('#chatSubject').focus();

        var v_Msg = new MsgBxStringVals();
        v_Msg.body = `You must fill in the Subject field.`;
        v_Msg.title = ``;
        this.controlService.openMessageBoxPopUp(v_Msg);

        event.preventDefault();
      return;
    }


    if (this.chatProduct.length == 0 && this.chatSettings.products.length > 0) {
        $('#product-list').focus();

        var v_Msg = new MsgBxStringVals();
        v_Msg.body = `You must fill in the Product field.`;
        v_Msg.title = ``;
        this.controlService.openMessageBoxPopUp(v_Msg);
        
          event.preventDefault();
          return;
      }

    this.pinnacleService.sendSupportEmail(this.supportEmailInfo).subscribe(r => {
      this.chatActive = false;
      this.chatWindowOpen = false;
      this.chatWindowMinimized = false;

      clearInterval(this.reqStageTimer);

      this.chatMessages = [];
      this.chatSubject = "";
      sessionStorage.setItem("ChatActive", "false");
      sessionStorage.setItem("ChatID", "");
        this.messagesLoaded = false;


        var v_Msg = new MsgBxStringVals();
        v_Msg.body = `An email has been sent to Support with your inquiry. You have also been carbon-copied on this email.`;
        v_Msg.title = ``;
        this.controlService.openMessageBoxPopUp(v_Msg);

    });
  }
}

    
