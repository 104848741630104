import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { ProdGenApi } from './../../apiService/prodgen.api';
import { Content, ContentType_T } from './../../apiService/classFiles/class.content';
import { User } from './../../apiService/classFiles/class.users';
import { Tenant } from './../../apiService/classFiles/class.organizations';
import { TenantSubscription, Subscription } from './../../apiService/classFiles/class.partners';
import { AppControlService, MsgBxStringVals } from './../../AppControlService';
import { DomSanitizer } from '@angular/platform-browser';
import { TenantSubscriptionPartner } from './../partnernewcustomer/partnernewcustomer.component'

declare var $: any;

@Component({
    selector: 'template-partner-edit-customer',
    templateUrl: './partnereditcustomer.component.html',
    styleUrls: ['./partnereditcustomer.component.css']
})

export class PartnerEditCustomerComponent implements OnInit {
    @Input('orgId') orgId: string =""; // defines what is image for
    @Input('tenantId') tenantId: string =""; // userid, workgroupid, etc
    @Output('accountChanged') accountChanged?= new EventEmitter<any>();

    currentTenant: Tenant = new Tenant();

    partnerSubscriptions: Array<Subscription> = new Array<Subscription>();
    subscriptionToAdd = new TenantSubscriptionPartner();
    selPartnerSubscription: Subscription = new Subscription();
    newExpirationDate: string = "";    


    model: any = {
      pinnacleID: "",
      accountName: "",
      crmRefID: "",
      platform: "",
      maxUsers: 5,
      subscriptions: new Array <TenantSubscription>()
  };

    additonalSubscriptionsArray: Array<TenantSubscriptionPartner> = null;
    isAddingSubscription: boolean = false;
    deletedSubscriptionIndex: number;
    totalWidth = document.body.clientWidth + 700;  

    constructor(private pinnacleService: ProdGenApi, private controlService: AppControlService, private sanitizer: DomSanitizer) {
    }

    ngOnChanges(changes: SimpleChanges) {
        try { this.orgId = changes['orgId'].currentValue;  } catch (err) { };
        try { this.tenantId = changes['tenantId'].currentValue; } catch (err) { };

		this.loadData();
    }


    ngOnInit() {

    this.loadData();

    this.isAddingSubscription = false;

    
  }
    loadData()
    {
        this.model.pinnacleID = "";
        this.model.accountName = "";
        this.model.crmRefID = "";
        this.model.maxUsers = 5;
        this.model.platform = "";
        this.model.subscriptions = new Array<TenantSubscription>();
        this.additonalSubscriptionsArray = new Array<TenantSubscriptionPartner>();

        if (this.orgId == null || this.tenantId == null) {
            return;
        }

        this.pinnacleService.getPartnerAccountTenants(this.orgId).subscribe(res => {
            // loop through the tenants
            for (let t of res) {
                if (t.tenantId == this.tenantId) {
                    this.currentTenant = t;

                    // found the one we are looking for
                    this.model.pinnacleID = this.orgId;
                    if (t.isPinnacleLite == true) {
                        this.model.platform = "Pinnacle Lite";
                    }
                    else {
                        this.model.platform = "Pinnacle Series";
                    }

                    this.model.accountName = t.name;
                    this.model.crmRefID = t.altOrganizationId;

                    this.model.crmRefID = t.altOrganizationId;
                    this.model.maxUsers = t.maxPinnacleLiteUsers;

                    this.pinnacleService.getPartnerAccountSubscriptions(t.organizationId.toString(), t.tenantId as string).subscribe(subs => {
                        this.model.subscriptions = subs;
                    });

                    break;
                }
            }

        });

        this.partnerSubscriptions = new Array<Subscription>();
        // get the subscriptions thid partner is allowed to sell
        this.pinnacleService.getPartnerSubscriptions().subscribe(subs => {
            for (let s of subs) {
                if (s.isPinnacleLite == true) {
                    s.name;
                }

                this.partnerSubscriptions.push(s);
            }
            if (this.partnerSubscriptions.length > 0) {
                this.selPartnerSubscription = this.partnerSubscriptions[0];
            }
            
        })
  }

  onResize(event) {
  }

  

  onSubmitOrderRequest() {
    // Submit the order request for processing
      let bUpgradeToPS = $("#upgrade").is(":checked");
      let poNumber = $("#poNumber").val();

      let upgradeSubscriptions = new Array<TenantSubscription>();

      for (let s of this.additonalSubscriptionsArray)
      {
          if (s.isPinnacleLite == false && s.partnerId.toLowerCase() == "8380a9f3-d671-4664-aecb-c883ee418d09") {
              upgradeSubscriptions.push(s);
          }
      }

      if (bUpgradeToPS == true || upgradeSubscriptions.length > 0) {
          this.pinnacleService.createUpgradeOrderRequest(+this.orgId, this.tenantId, bUpgradeToPS, poNumber, upgradeSubscriptions).subscribe(res => {

              var v_Msg = new MsgBxStringVals();
              v_Msg.body = "The account information has been updated.<br/><br/>* Eagle Point Software will receive a notification for this account because one or more options require payment.";
              v_Msg.title = ``;
              this.controlService.openMessageBoxPopUp(v_Msg);

              event.preventDefault();

              this.processNewPLSubscriptions();
              this.closeModal("orderRequestModal");

          }
          );
      }
      else {
          this.processNewPLSubscriptions();
          this.closeModal("orderRequestModal");
      }
    
  }

    processNewPLSubscriptions() {
        let subCount = this.additonalSubscriptionsArray.length;


        let i = 0;
        for (let s of this.additonalSubscriptionsArray) {
            this.pinnacleService.addCustomerTenantSubscription(+this.orgId,
                this.tenantId as string, s.subscriptionID, s.quantity,
                ProdGenApi.currentUser.email, ProdGenApi.currentUser.name, s.expirationDate).subscribe(sub => {
                    i++;
                    if (i == subCount) {

                        this.pinnacleService.getPartnerAccountSubscriptions(this.orgId, this.tenantId as string).subscribe(subs => {
                            this.model.subscriptions = subs;
                        });
                    }


                });
        }
    }
    
    saveTenantChanges() {
        // update the reference if need be
        if (this.model.crmRefID != this.currentTenant.altOrganizationId) {
            // save the crm id
            this.pinnacleService.updateCustomerAltId(this.currentTenant.organizationId, this.model.crmRefID).subscribe(res => {

                if (this.currentTenant.name != this.model.accountName || this.currentTenant.maxPinnacleLiteUsers != this.model.maxUser) {
                    // save the name change
                    this.pinnacleService.updatePartnerAccountTenant(this.currentTenant.organizationId.toString(), this.currentTenant.tenantId as string, this.model.accountName, this.model.maxUsers).subscribe(ten => {
                        this.displaySavedMessage();
                    });
                }
                else {
                    this.displaySavedMessage();
                }
            });
        }
        else if (this.currentTenant.name != this.model.accountName || this.currentTenant.maxPinnacleLiteUsers != this.model.maxUser) {
            // save the name change
            // save the name change
            this.pinnacleService.updatePartnerAccountTenant(this.currentTenant.organizationId.toString(), this.currentTenant.tenantId as string, this.model.accountName, this.model.maxUsers).subscribe(ten => {
                this.displaySavedMessage();
            });
        }

    }

    onOpenOrderRequest() {
        this.additonalSubscriptionsArray = new Array<TenantSubscriptionPartner>();
        $("#upgrade").attr("checked", false);
        $("#poNumber").val("");

        $('#PinnacleLiteAccountModal').animate(
            {
                "left": "-=" + this.totalWidth + "px"

            }, "slow");
    }


    addSubscription() {
        this.subscriptionToAdd = new TenantSubscriptionPartner();
        this.subscriptionToAdd.isTrial = false;
        this.subscriptionToAdd.quantity = 1;
        this.subscriptionToAdd.isPinnacleLite = false;
        this.subscriptionToAdd.expirationDate = new Date();
        this.subscriptionToAdd.expirationDate.setFullYear(this.subscriptionToAdd.expirationDate.getFullYear() + 1);
        this.subscriptionToAdd.unlimitedExpirationDate = this.subscriptionToAdd.expirationDate;
        this.subscriptionToAdd.partnerId = "";

        this.newExpirationDate = this.subscriptionToAdd.expirationDate.toISOString().substring(0, 10);

        //$('#orderRequestModal').animate(
        //    {
        //        "left": "-=" + this.totalWidth + "px"

        //    }, 300);
    }

    submitNewSubscription(closeBox:boolean) {
        //Do something
        let sub = this.subscriptionToAdd;
        let strDate = $("#expirationDate").val();

        var parts = strDate.split('-');
        // Please pay attention to the month (parts[1]); JavaScript counts months from 0:
        // January - 0, February - 1, etc.

        sub.subscriptionID = this.selPartnerSubscription.subscriptionID;
        sub.name = this.selPartnerSubscription.name as string;
        sub.tenantID = this.tenantId;

        sub.unlimitedExpirationDate = new Date(parts[0], parts[1] - 1, parts[2]); 
        sub.expirationDate = sub.unlimitedExpirationDate;
        sub.isPinnacleLite = this.selPartnerSubscription.isPinnacleLite;
        sub.partnerId = this.selPartnerSubscription.partnerId;


        if (sub.name != null && sub.name.length > 0) {

            let exists = false;
            // check to see if it already exists
            for (let existing of this.additonalSubscriptionsArray) {
                if (existing.subscriptionID == sub.subscriptionID) {
                    exists = true;
                    existing.expirationDate = sub.expirationDate;
                    existing.quantity = sub.quantity;
                    existing.unlimitedExpirationDate = sub.unlimitedExpirationDate;
                }
            }



            if (exists == false) {
                this.additonalSubscriptionsArray.push(sub);
            }

            if (closeBox == true) {
                this.closeModal("addSubscriptionModal");
            }
            else {
                this.subscriptionToAdd = new TenantSubscriptionPartner();

                this.subscriptionToAdd.isTrial = false;
                this.subscriptionToAdd.quantity = sub.quantity;
                this.subscriptionToAdd.isPinnacleLite = sub.isPinnacleLite;
                this.subscriptionToAdd.expirationDate = sub.expirationDate;
                this.subscriptionToAdd.unlimitedExpirationDate = sub.unlimitedExpirationDate;
                this.subscriptionToAdd.partnerId = "";
            }

        }
        else {
            var v_Msg = new MsgBxStringVals();
            v_Msg.body = "Please select a subscription.";
            v_Msg.title = ``;
            this.controlService.openMessageBoxPopUp(v_Msg);
        }
}


    removeSubscription(index: number) {

        this.additonalSubscriptionsArray.splice(index, 1); // removes temporarily
        // will save the subscription (s) info from the click event , so we can use that when submitting the request to delete permanently.
    }


    displaySavedMessage() {

        this.accountChanged.emit({
            organizaton: this.currentTenant
        });

        var v_Msg = new MsgBxStringVals();
        v_Msg.body = "Account information has been saved.";
        v_Msg.title = ``;
        this.controlService.openMessageBoxPopUp(v_Msg);
        
        event.preventDefault();
    }

    closeModal(id: string) {
        $('#' + id).modal('hide');

        if (id == "orderRequestModal") {
            $('#PinnacleLiteAccountModal').animate(
                {
                    "left": "+=" + this.totalWidth + "px"

                }, "slow");
        }

        //else if (id == "addSubscriptionModal") {
        //    $('#orderRequestModal').animate(
        //        {
        //            "left": "+=" + this.totalWidth + "px"

        //        }, "slow");
        //}
    }

}
