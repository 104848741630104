import { Component, OnInit, AfterViewInit, Input, OnChanges, SimpleChanges, ElementRef, Renderer2, ViewChild, ChangeDetectorRef, HostListener, EventEmitter, Output } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { ProdGenApi } from './../../apiService/prodgen.api';
import { ContentType_T, DescriptionFilter_T, Video, Content } from './../../apiService/classFiles/class.content';
import { AppControlService } from './../../AppControlService';
import { TranslationService } from './../../services/TranslationService';
import { AutoplayVidService } from './../../AutoplayVidService';
import { CourseViewerSyllabusComponent } from './../courseviewersyllabus/courseviewersyllabus.component';
import { setTimeout } from 'timers';
import { SessionTimeoutService } from '../../services/session-timeout.service';

declare function makeAblePlayerReady(): void;
declare var $: any;
declare var AblePlayer: any;

@Component({
    providers: [CourseViewerSyllabusComponent],
    selector: 'app-videocontent',
    templateUrl: './videocontent.component.html',
    styleUrls: ['./videocontent.component.css']
})
export class VideoContentComponent implements OnInit, AfterViewInit, OnChanges {
    @Input('contentId') contentId: string;
    @Input('enrollmentId') enrollmentId?: string;
    @Input('isModal') isModal?: boolean;
    @Input('openCount') openCount?: number;
    renderer: Renderer2 = null;
    video: Video = new Video();
    @Input() hasPlayedVideo: boolean = false;
    @Output() hasPlayedVideoChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    startTime: Date;
    hasLoaded: boolean = false;
    vidstarttime: number;

    vidDivName: string = "video1";
    transcriptDivName: string = "video-transcript-2";
    isValidURL: boolean = true;
    vidPlaying: boolean = false;
    vidAtEnd: boolean = false;
    autoplaynext: boolean = false;
    loadWithAutoplay: boolean = false;
    externalVideoDiv: SafeHtml;
    volume: string = "";
    translatedTitle: string = "";

    isComplete: boolean = false;
    constructor(private service: ProdGenApi, private _sanitizer: DomSanitizer, elementRef: ElementRef, renderer: Renderer2, private appService: AppControlService, private changeRef: ChangeDetectorRef, private translationService: TranslationService, private autoplaysidservice: AutoplayVidService, private courseviewersyllabus: CourseViewerSyllabusComponent,
        private timeoutService: SessionTimeoutService) {

        this.video.description = translationService.getTranslationFileData("VIDEOCONTENT.TS_Loading");
        this.renderer = renderer;
        this.loadData();

        this.video.hasTranscript = true;
    }

    ngOnInit() {

        if (this.isModal != null && this.isModal == true) {
            this.vidDivName = "video3";
            this.transcriptDivName = "video-transcript-3";
        }
        /*
		<div id="video-house" ngClass= "{{getMainClassList()}}" >
			<video id="video1" data- able - player preload= "auto" style= "max-width:100%;" src= "{{video.videoURL}}"(play) = "onPlay($event)"(pause) = "onPause($event)" >
				<!--<video id= "video1" data- able - player preload= "auto" style= "max-width:100%;"(play) = "onPlay($event)"(pause) = "onPause($event)" >
					<!--<source id= "vid-source" type= "video/mp4" />-->
						<!--<track kind= "captions" src= "path_to_captions.vtt" />
							<track kind="descriptions" src= "path_to_descriptions.vtt" />-->
								</video>
								< /div>
								< div * ngIf="video.hasTranscript==true" style= "margin-top:15px;" id= "video-transcript-2" ngClass= "{{getTranscriptClassList()}}" > </div>
        */

        
    }

    ngAfterViewInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
        try {
            if (changes['contentId'] != null) {
                this.contentId = changes['contentId'].currentValue;
                this.hasPlayedVideo = false;
                this.video.description = this.translationService.getTranslationFileData("VIDEOCONTENT.TS_Loading");
                this.video.hasTranscript = false;
                try {
                    document.getElementById("video-house").innerHTML = ``;
                    document.getElementById("videoModal-house").innerHTML = ``;
                    $(`#videoModal_Parent #${this.transcriptDivName}`).remove();
                } catch (err) { }
                this.loadData();
            }
            else if (changes['openCount'] != null) {
                try {
                    document.getElementById("video-house").innerHTML = ``;
                    document.getElementById("videoModal-house").innerHTML = ``;
                    $(`#videoModal_Parent #${this.transcriptDivName}`).remove();
                }
                catch (err) { }
                this.loadData();
            }
        } catch (err) {
            console.log(err);
        };
        this.autoplaynext = this.autoplaysidservice.GetAutoplayNext();
        this.loadWithAutoplay = this.autoplaysidservice.GetRunAutoplay();
    }

    addEventHandlers() {
        if (this.isValidURL == true) {
            //console.log("adding handlers");
            var vidElm = document.getElementById(this.vidDivName);

            this.renderer.listen(vidElm, 'play', (event) => {
                //console.log("Play");
                this.onPlay(event);
                // Do something with 'event'
            });
            /*
            this.renderer.listen(vidElm, 'playing', (event) => {
                console.log("Playing");
            });

            this.renderer.listen(vidElm, 'waiting', (event) => {
                console.log("Waiting");
            });
            */
            this.renderer.listen(vidElm, 'pause', (event) => {
                //console.log("Pause");
                this.onPause(event);
                // Do something with 'event'
            });

            this.renderer.listen(vidElm, 'ended', (event) => {
                this.onEnded(event);
                // Do something with 'event'
            });

            //this.renderer.listen(vidElm, 'loadedmetadata', (event) => {
            //  this.onLoaded(event);
            //  // Do something with 'event'
            //});
        }
    }

    loadData() {
      this.hasLoaded = false;
      if (this.contentId != null && this.contentId != "") {
			  this.video.hasTranscript = true;
			  if (this.enrollmentId == null || this.enrollmentId =="") {
                  this.service.GetVideo(this.contentId, DescriptionFilter_T.formatted).subscribe(v => {


            this.video = v;
            this.hasLoaded = true;
            this.setupPlayer();
            //if (this.loadWithAutoplay == true) {
            //  $('#' + this.vidDivName).get(0).play(); 
            //}
                this.getTranslatedTitle();	

            });
        }
        else {
                  this.service.getEnrollmentVideo(this.enrollmentId, this.contentId, DescriptionFilter_T.formatted).subscribe(v => {
          this.video = v;
			    this.hasLoaded = true;
          this.setupPlayer();
            //if (this.loadWithAutoplay == true) {
                    
            //   // $('#' + this.vidDivName).get(0).play();
                    
                      
                    
            // // $('#' + this.vidDivName).get(0).pause();
                    
              //}
              this.getTranslatedTitle();	
                      //}
                      this.service.GetVideoEnrollmentTracking(this.enrollmentId, this.video.videoId as string).subscribe(res => {
                          this.isComplete = res.isComplete;
                          //console.log(res);
                      });

		      }, err => {
			      if (err.status == 409) {
				      console.log("data unavailable to subscription");
				      this.video.videoId = this.contentId;
				      this.isValidURL = false;
				      this.hasLoaded = true;
				      this.video.description = "This content is unavailable with your subscription selection.";
			      }
		      });
          }
        }        
    }

    getTranslatedTitle() {
        //if (this.video != null && this.video.name != null) {
        //    this.service.translateText(this.video.name as string, "en", false).subscribe(res => {
        //        this.translatedTitle = res;
        //    });
        //}
        this.translatedTitle = this.video.name.toString();
    }

    setupPlayer() {

        this.isValidURL = true;
        if (this.video.videoURL.toLowerCase().includes("http") == false) {
            this.isValidURL = false;
        }
        else if (this.video.videoURL.toLowerCase().includes(".mp4") == false) {
            this.isValidURL = false;
        }

        if (this.isValidURL == false && this.isWebVideo() == false) {
            this.video.videoURL = "pinnaclecommand://HYPERLINK?" + this.video.videoURL;

            // Workaround for out-of-platform videos
            this.hasPlayedVideo = true;
            this.hasPlayedVideoChange.emit(this.hasPlayedVideo);
        }

        this.externalVideoDiv = this.getExternalModalHTMLMessage();

        this.volume = sessionStorage.getItem("volume");

        if (this.volume == "" || this.volume == null) {
            this.volume = "7";
        }

        if (this.isValidURL == false) {
            this.video.hasTranscript = false;
            this.video.hasBookmark = false;
            this.hasPlayedVideo = true;
        }

        this.changeRef.detectChanges();


        if ($('#transcriptTab') != null) {
            let transcriptDiv = `<div id="${this.transcriptDivName}"  ></div>`;

            $(`#transcriptTab #${this.transcriptDivName}`).remove();
            $('#transcriptTab').append(transcriptDiv);

        }
        if (this.enrollmentId != null && this.enrollmentId.length > 0) {
            this.service.GetVideoEnrollmentTracking(this.enrollmentId, this.video.videoId.toString()).subscribe(res => {
                this.vidstarttime = res.lastVideoPosition;
                if (this.vidstarttime == 0) {
                    this.vidstarttime = 0.1;
                }
                if (this.isModal != null && this.isModal == true) {
                    this.buildVideoModalHTML();
                }
                else {
                    this.buildVideoHTML();
                }

            });
        }
        else {
            if (this.isModal != null && this.isModal == true) {
                this.buildVideoModalHTML();
            }
            else {
                this.buildVideoHTML();
            }
        }

        if (this.video.hasTranscript == true) {
            if ($('.able-transcript-area') != null) {
                //$('.able-transcript-area').css("height", "350px");
                if (document.getElementById("video-house")) {
                    let height = document.getElementById("video-house").offsetHeight;
                    $('.able-transcript-area').css("height", height.toString() + "px");
                }
                else {
                    let height = document.getElementById("videoModal-house").offsetHeight;
                    $('.able-transcript-area').css("height", height.toString() + "px");
                }

            }
            if ($('.able-transcript') != null) {
                //$('.able-transcript').css("height", "300px");
                if (document.getElementById("video-house")) {
                    let height = document.getElementById("video-house").offsetHeight - 75;
                    $('.able-transcript').css("height", height.toString() + "px");
                }
                else {
                    let height = document.getElementById("videoModal-house").offsetHeight - 75;
                    $('.able-transcript').css("height", height.toString() + "px");
                }
            }

            // move into tab
            //var el = $('#video-transcript-2').detach();
            //$('#transcriptTab').append(el);
        }


    }

    buildVideoHTML() {
        if (this.loadWithAutoplay == true) {
            if (this.video.hasBookmark && this.video.hasTranscript) {
                try {

                    document.getElementById("video-house").innerHTML = `
  <video  id="${this.vidDivName}" data-able-player data-lyrics-mode data-start-time="` + this.vidstarttime + `" autoplay data-transcript-div="${this.transcriptDivName}" data-transcript-title="Transcript" data-chapters-title="Bookmarks" 
            preload="auto" style="max-width:100%;" src="` + this.video.videoURL + `" data-volume="` + this.volume + `" oncontextmenu="return false;">
          <source id= "vid-source" type= "video/mp4" />
              <track kind= "captions" src= "`+ this.video.transcriptURL + `" />
              <track kind= "chapters" src= "`+ this.video.bookmarkURL + `" />
            </video>`;
                } catch (err) { }

            }

            else if (this.video.hasTranscript == true) {
                try {

                    document.getElementById("video-house").innerHTML = `
  <video  id="${this.vidDivName}" data-able-player data-lyrics-mode data-start-time="` + this.vidstarttime + `" autoplay data-transcript-div="${this.transcriptDivName}" data-transcript-title="Transcript"
            preload="auto" style="max-width:100%;" src="` + this.video.videoURL + `" data-volume="` + this.volume + `" oncontextmenu="return false;">
          <source id= "vid-source" type= "video/mp4" />
              <track kind= "captions" src= "`+ this.video.transcriptURL + `" />
            </video>`;
                } catch (err) { }

            }
            else if (this.video.hasBookmark == true) {
                document.getElementById("video-house").innerHTML = `<video id="${this.vidDivName}" data-able-player data-start-time="` + this.vidstarttime + `"  data-chapters-title="Bookmarks" autoplay
                preload="auto" style="max- width:100 %;" src="` + this.video.videoURL + `" data-volume="` + this.volume + `" oncontextmenu="return false;">
                <source id= "vid-source" type= "video/mp4" />
                    <track kind= "chapters" src= "`+ this.video.bookmarkURL + `" />
                  </video>`;
            }
            else {

                try {
                    document.getElementById("video-house").innerHTML = `
<video  id="${this.vidDivName}" data-able-player data-start-time="` + this.vidstarttime + `" autoplay preload="auto" style="max-width:100%;" src="` + this.video.videoURL + `" data-volume="` + this.volume + `" oncontextmenu="return false;">
          < !--<source id= "vid-source" type= "video/mp4" />-->
              <!--<track kind= "captions" src= "path_to_captions.vtt" />
                  <track kind="descriptions" src= "path_to_descriptions.vtt" />-->
                      </video>`;
                }
                catch (err) { }
            }
        }
        else {
            if (this.video.hasBookmark && this.video.hasTranscript) {
                try {

                    document.getElementById("video-house").innerHTML = `
  <video  id="${this.vidDivName}" data-able-player data-lyrics-mode data-start-time="` + this.vidstarttime + `" data-transcript-div="${this.transcriptDivName}" data-transcript-title="Transcript" data-chapters-title="Bookmarks" 
            preload="auto" style="max-width:100%;" src="` + this.video.videoURL + `" data-volume="` + this.volume + `" oncontextmenu="return false;">
          <source id= "vid-source" type= "video/mp4" />
              <track kind= "captions" src= "`+ this.video.transcriptURL + `" />
              <track kind= "chapters" src= "`+ this.video.bookmarkURL + `" />
            </video>`;
                } catch (err) { }

            }

            else if (this.video.hasTranscript == true) {
                try {
                    var vid = document.getElementById("video-house").innerHTML = `
  <video  id="${this.vidDivName}" data-able-player data-lyrics-mode data-start-time="` + this.vidstarttime + `" data-transcript-div="${this.transcriptDivName}" data-transcript-title="Transcript"
            preload="auto" style="max-width:100%;" src="` + this.video.videoURL + `" data-volume="` + this.volume + `" oncontextmenu="return false;">
          <source id= "vid-source" type= "video/mp4" />
              <track kind= "captions" src= "`+ this.video.transcriptURL + `" />
          </video>`;
                } catch (err) { console.log(err); }

            }
            else if (this.video.hasBookmark == true) {
                document.getElementById("video-house").innerHTML = `<video id="${this.vidDivName}" data-able-player preload="auto" data-start-time="` + this.vidstarttime + `" data-chapters-title="Bookmarks"
                  style="max- width:100 %;" src="` + this.video.videoURL + `" data-volume="` + this.volume + `" oncontextmenu="return false;">
                <source id= "vid-source" type= "video/mp4" />
                    <track kind= "chapters" src= "`+ this.video.bookmarkURL + `" />
                  </video>`;
            }

            else {

                try {
                    document.getElementById("video-house").innerHTML = `
<video  id="${this.vidDivName}" data-able-player data-start-time="` + this.vidstarttime + `" preload="auto" style="max-width:100%;" src="` + this.video.videoURL + `" data-volume="` + this.volume + `" oncontextmenu="return false;">
          < !--<source id= "vid-source" type= "video/mp4" />-->
              <!--<track kind= "captions" src= "path_to_captions.vtt" />
                  <track kind="descriptions" src= "path_to_descriptions.vtt" />-->
                      </video>`;
                }
                catch (err) { }
            }
        }

        this.addEventHandlers();

        window.scroll(0, 0);
        makeAblePlayerReady();
    }

    buildVideoModalHTML() {

        if (this.video.hasBookmark && this.video.hasTranscript) {
            try {

                document.getElementById("videoModal-house").innerHTML = `
<video  id="${this.vidDivName}" data-able-player data-lyrics-mode data-start-time="` + this.vidstarttime + `" data-transcript-div="${this.transcriptDivName}" data-transcript-title="Transcript" data-chapters-title="Bookmarks" 
          preload="auto" style="max-width:100%;" src="` + this.video.videoURL + `" data-volume="` + this.volume + `" oncontextmenu="return false;">
        <source id= "vid-source" type= "video/mp4" />
            <track kind= "captions" src= "`+ this.video.transcriptURL + `" />
            <track kind= "chapters" src= "`+ this.video.bookmarkURL + `" />
          </video>`;
            } catch (err) { }

        }

        else if (this.video.hasTranscript == true) {
            try {
                var vid = document.getElementById("videoModal-house").innerHTML = `
<video  id="${this.vidDivName}" data-able-player data-lyrics-mode data-start-time="` + this.vidstarttime + `" data-transcript-div="${this.transcriptDivName}" data-transcript-title="Transcript"
          preload="auto" style="max-width:100%;" src="` + this.video.videoURL + `" data-volume="` + this.volume + `" oncontextmenu="return false;">
        <source id= "vid-source" type= "video/mp4" />
            <track kind= "captions" src= "`+ this.video.transcriptURL + `" />
        </video>`;
            } catch (err) { console.log(err); }

        }

        else if (this.video.hasBookmark == true) {
            document.getElementById("videoModal-house").innerHTML = `<video id="${this.vidDivName}" data-able-player preload="auto" data-start-time="` + this.vidstarttime + `" data-chapters-title="Bookmarks"
                style="max- width:100 %;" src="` + this.video.videoURL + `" data-volume="` + this.volume + `" oncontextmenu="return false;">
              <source id= "vid-source" type= "video/mp4" />
                  <track kind= "chapters" src= "`+ this.video.bookmarkURL + `" />
                </video>`;
        }

        else {

            try {
                document.getElementById("videoModal-house").innerHTML = `
<video  id="${this.vidDivName}" data-able-player data-start-time="` + this.vidstarttime + `" preload="auto" style="max-width:100%;" src="` + this.video.videoURL + `" data-volume="` + this.volume + `" oncontextmenu="return false;">
        < !--<source id= "vid-source" type= "video/mp4" />-->
            <!--<track kind= "captions" src= "path_to_captions.vtt" />
                <track kind="descriptions" src= "path_to_descriptions.vtt" />-->
                    </video>`;
            }
            catch (err) { }
        }


        this.addEventHandlers();
        window.scroll(0, 0);

        //makeAblePlayerReady() is not recommended here.
        $(document.getElementById(this.vidDivName)).each(function (index, element) {
            if ($(element).data('able-player') !== undefined) {
                new AblePlayer($(this), $(element));
            }
        });
    }

    isWebVideo(): boolean {
        if (this.video.videoURL.toLowerCase().startsWith("http")) {
            if ((this.video.videoURL.toLowerCase().endsWith("wmv") == false) &&
                (this.video.videoURL.toLowerCase().endsWith("avi") == false)) {
                return true;
            }
        }
        return false;
    }

    getActiveDesc(): SafeHtml {
        return this._sanitizer.bypassSecurityTrustHtml(this.video.description as string);
    }

    getExternalModalHTMLMessage(): SafeHtml {

        if (this.isWebVideo()) {

            return this._sanitizer.bypassSecurityTrustHtml(`<!DOCTYPE>
<div style="margin-top:20px;margin-left:10px;margin-right:0px; max-width:100%"> 
  <a href="` + this.video.videoURL + `" target="_blank"><img src="../../assets/images/empy_video.png" style="width:100%"></a>
</div>
`);
        }


        return this._sanitizer.bypassSecurityTrustHtml(`<!DOCTYPE>
<div style="margin-top:20px;margin-left:10px;margin-right:0px; max-width:100%"> 
  <a href="` + this.video.videoURL + `"><img src="../../assets/images/empy_video.png" style="width:100%"></a>
</div>
`);
    }

    onPlay(event) {
        this.vidPlaying = true;
        this.hasPlayedVideo = true;
        this.hasPlayedVideoChange.emit(this.hasPlayedVideo);
        this.startTime = new Date(Date.now());

        // Do not log the user out for inactivity
        // while a video is playing.
        this.timeoutService.videoPlaying = true;
        this.timeoutService.stop();
    }



    onPause(event) {

        let EndTime = new Date(Date.now());
        this.vidPlaying = false;

        // log the event
        if (this.enrollmentId != null && this.enrollmentId != "") {
            let videoLength: number = Number.isNaN(AblePlayer.lastCreated.media.duration) ? 1 : AblePlayer.lastCreated.media.duration;
            let curPos: number = AblePlayer.lastCreated.media.currentTime;

            if (curPos != videoLength) {
                this.vidAtEnd = false;
                this.autoplaysidservice.SetRunAutoplay(false);

              this.service.SetVideoEnrollmentTracking(this.enrollmentId as string,
                  this.video.videoId as string,
                  curPos,
                  this.startTime,
                  new Date(Date.now()),
                  true,
                  videoLength).subscribe(res => {
                      
                  });


            }

        }

        // Since the video is paused, we can begin
        // monitoring for inactivity again.
        this.timeoutService.videoPlaying = false;
        this.timeoutService.start();
    }

    onEnded(event) {

        this.vidAtEnd = false;
        let EndTime = new Date(Date.now());

        this.vidPlaying = false;
        // log the event
        if (this.enrollmentId != null && this.enrollmentId != "") {
            let videoLength: number = Number.isNaN(AblePlayer.lastCreated.media.duration) ? 1 : AblePlayer.lastCreated.media.duration;
            let curPos: number = AblePlayer.lastCreated.media.currentTime;

            this.vidAtEnd = true;

            this.service.SetVideoEnrollmentTracking(this.enrollmentId as string,
                this.video.videoId as string,
                0,
                this.startTime,
                new Date(Date.now()),
                true,
                videoLength).subscribe(res => {

                });

            if (this.vidAtEnd == true && this.autoplaynext == true) {
                this.autoplaysidservice.SetRunAutoplay(true);
                $('#nextbutton').click();
            }
            else {
                this.autoplaysidservice.SetRunAutoplay(false);
            }
        }

        // Since the video has ended, we can begin
        // monitoring for inactivity again.
        this.timeoutService.videoPlaying = false;
        this.timeoutService.start();
    }

    getMainClassList(): string {
        if (this.video != null && this.video.hasTranscript) {
            //let classes: string = "col-ld-8 col-md-8 col-sm-8 col-xs-12 ";
            let classes: string = "col-ld-12 col-md-12 col-sm-12 col-xs-12 ";

            return classes;
        }
        else {
            let classes: string = "col-ld-12 col-md-12 col-sm-12 col-xs-12 ";

            return classes;
        }
    }

    getTranscriptClassList(): string {
        if (this.video != null && this.video.hasTranscript) {
            let classes: string = "hidden-xs visible-sm visible-md visible-lg col-ld-4 col-md-4 col-sm-4 ";

            return classes;
        }
    }

    onResize(event) {
        if ($('.able-transcript-area') != null) {
            //$('.able-transcript-area').css("height", "350px");
            let height = document.getElementById("video-house").offsetHeight;
            $('.able-transcript-area').css("height", height.toString() + "px");
        }
        if ($('.able-transcript') != null) {
            //$('.able-transcript').css("height", "300px");
            let height = document.getElementById("video-house").offsetHeight - 75;
            $('.able-transcript').css("height", height.toString() + "px");
        }
    }

    @HostListener('mouseup', ['$event'])
    public handleMouseUpEvent(event: MouseEvent): void {
        if (this.isModal != null && this.isModal == true) {
            var aud = <HTMLVideoElement>document.getElementById("video3");
            if (aud != null && aud != undefined && aud.volume != null && aud.volume != undefined)
                sessionStorage.setItem("volume", (aud.volume * 10).toString());
        }
        else {
            var aud = <HTMLVideoElement>document.getElementById("video1");
            if (aud != null && aud != undefined && aud.volume != null && aud.volume != undefined)
                sessionStorage.setItem("volume", (aud.volume * 10).toString());
        }

    }
}

