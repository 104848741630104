import { Component, OnInit } from '@angular/core';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ProdGenApi } from './../apiService/prodgen.api';
import { ContentType_T, DescriptionFilter_T, ChatLog, Content } from './../apiService/classFiles/class.content';
import { User } from './../apiService/classFiles/class.users';
import { BrowserAuthenticationService } from './../BrowserAuthenticationService';

@Component({
    selector: 'app-chatviewer',
    templateUrl: './chatviewer.component.html',
    styleUrls: ['./chatviewer.component.css'],
    providers: [ProdGenApi],
})
export class ChatviewerComponent implements OnInit {
    contentId: string = "";
    chatLog: ChatLog = new ChatLog();
    contentItem: Content = new Content();
    currentUser: User = new User();
	isAuthenticated: boolean = false;

	constructor(private service: ProdGenApi, private route: ActivatedRoute, private router: Router, private _sanitizer: DomSanitizer, private authenticationService: BrowserAuthenticationService) {
		if (authenticationService.AuthenticatePage() == true) {
            this.isAuthenticated = true;
            service.getCurrentUser().subscribe(u => this.currentUser = u);
            this.contentItem.contentType = ContentType_T.chatLog;
            this.route.queryParams.subscribe(queryParams => {
                this.contentId = queryParams['id'];
                this.service.getChatLog(this.contentId).subscribe(res => {
                    this.chatLog = res;
                    this.contentItem.name = res.name;

                    for (var i = 0; i < this.chatLog.messages.length; i++)
                    {
                        let m = this.chatLog.messages[i];
                        let v_MatchArray = m.message.match("pinnacle://PS_([A-Za-z])+:[0-9A-Fa-f]{8}[-]([0-9A-Fa-f]{4}[-]){3}[0-9A-Fa-f]{12}");

                        if (v_MatchArray != null) {

                            for (var x = 0; x < v_MatchArray.length; x++) {
                                let v_LinkInfo = v_MatchArray[x].split(":");
                                if (v_LinkInfo.length == 3) {
                                    let v_Type = v_LinkInfo[1].replace("//", "");
                                    let v_ID = v_LinkInfo[2];
                                    let v_Link = "";

                                    if (v_Type == "PS_Cheat") {
                                        v_Link = "/cheatsheetviewer?id=" + v_ID;
                                    }
                                    else if (v_Type == "PS_Workflow") {
                                        v_Link = "/workflowviewer?id=" + v_ID + "&contentType=workflow";
                                    }
                                    else if (v_Type == "PS_Process") {
                                        v_Link = "/workflowviewer?id=" + v_ID + "&contentType=process";
                                    }
                                    else if (v_Type == "PS_Task" || v_Type == "PS_Milestone") {
                                        v_Link = "/workflowviewer?id=" + v_ID + "&contentType=task";
                                    }
                                    else if (v_Type == "PS_Step") {
                                        v_Link = "/workflowviewer?id=" + v_ID + "&contentType=step";
                                    }
                                    else if (v_Type == "PS_VidTutorial" || v_Type == "PS_VidArchive" || v_Type == "PS_VidUser") {
                                        v_Link = "/videoviewer?id=" + v_ID;
                                    }
                                    else if (v_Type == "PS_LearningPath") {
                                        v_Link = "/learningcenter/series?learningPathId=" + v_ID;
                                    }
                                    else if (v_Type == "PS_Course") {
                                        v_Link = "/learningcenter/series?courseId=" + v_ID;
                                    }
                                    else if (v_Type == "PS_Workgroup") {
                                        v_Link = "/workcenter?id=" + v_ID;
                                    }

                                    m.message = m.message.replace(v_MatchArray[x], "<a href='#" + v_Link + "'>" + v_MatchArray[x] + "</a>");
                                }
                            }
                        }

                    }
                });
            });
		}
    } 

    ngOnInit() {
        window.scroll(0, 0);

  }

    getActiveDesc(): SafeHtml {
        return this._sanitizer.bypassSecurityTrustHtml("" as string);
    }


}
