import { Component, ElementRef, EventEmitter, Inject, Input, LOCALE_ID, OnInit, Output, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { PermissionLevel_T, Workgroup, WorkgroupExternalGroup, WorkgroupExternalMembers, WorkgroupMember, WorkgroupRole } from '../../apiService/classFiles/class.workgroups';
import { AppControlService, MsgBxStringVals } from '../../AppControlService';
import { APICacheService } from '../../services/api-cache.service';
import { ProdGenApi } from './../../apiService/prodgen.api';
import { TranslationService } from './../../services/TranslationService';

declare var $: any;


@Component({
    selector: 'template-workgroup-member-view',
    templateUrl: './workgroup-member-view.component.html',
    styleUrls: ['./workgroup-member-view.component.css'],
    providers: [ProdGenApi]
})
export class WorkgroupMemberViewComponent implements OnInit {

    @Input('workgroup') workgroup: Workgroup;
    //@Input('workgroupMembers') workgroupMembers: Array<WorkgroupMember>;
    @Input('workgroupPermissionsLevel') currentUserPermissionsLevel: string;
    @Input('isWGAdmin') currentUserisWGAdmin: boolean = false;
    @Input('canCreateExternalUsers') canCreateExternalUsers: boolean = false;
    @Input('currentUserId') userIdCurrent: string = "";

    @Output('membersSelfDelete') memberSelfDelete = new EventEmitter<any>();

    workgroupMembers: Array<WorkgroupMember> = new Array<WorkgroupMember>();

    memberGroups: Array<MemberGroup> = new Array<MemberGroup>();

    filteredMemberGroups: Array<MemberGroup> = new Array<MemberGroup>();


    sortStatusName: number = 1;
    sortStatusEmail: number = 0;
    sortStatusRole: number = 0;
    curWorkgroupId: string = "";
    filterText: string = "";
    membersLoaded: boolean = false;
    limitShowUsers: Array<number> = [2000, 2000, 2000];
    exUsersLoaded: boolean = false;


    dropdownUsersData = [];
    dropdownRoleData = [];
    dropdownSettingsUsers = {};
    dropdownSettingsRoles = {};
    selUsers = [];
    selRoles = [];

    currentRoles: Array<WorkgroupRole> = new Array<WorkgroupRole>();
    filteredRoles: Array<WorkgroupRole> = new Array<WorkgroupRole>();

    addMemberPermissionLevel: string;
    addMemberTypeText: string;
    addMemberTypeDescText: string;

    collapseAll: boolean = false;
    collapseOwners: boolean = false;
    collapseEditors: boolean = false;



    externalemail: string = "";
    externalgroupid: string = "";
    externalIsManager: boolean = false;
    externalInvitesAllowed: number = 0;
    externaldisplayname: string = "";
    externalimageurl: string = "";
    externalgroupName: string = "";
    externalMembershipExpires: boolean = false;
    externalExpirationDate: string = "";

    selectedGroupID: string = "";
    selectedGroupName: string = "";

    externalGroupList: Array<WorkgroupExternalGroup> = new Array<WorkgroupExternalGroup>();

    filteredExternalGroupList: Array<WorkgroupExternalGroup> = new Array<WorkgroupExternalGroup>();
    
    canAddExternalUser: boolean = false;

    externalGroupsCanBeEdited: Object = new Object;

    invitesRemaining: number = 0;

    isMemberEditMode: boolean = false;
    externalMemberCount: number = 0;
    filteredExternalMemberCount = 0;

    canManageGroupSet: Object = {};
    currentUserId: string = "";

    /*
    deleteMemberRef: new WorkgroupMember();
    delete
*/
    deleteObservable: Observable<any>;
    removeConfirmTitle: string = "";
    removeConfirmBody: string = "";

    viewType: string = "Individual";

    modalMember: WorkgroupExternalMembers = new WorkgroupExternalMembers();
    modalGroup: WorkgroupExternalGroup = new WorkgroupExternalGroup();

    todaysDate: Date = new Date();

    @ViewChildren('assetContainer') assetsRef: QueryList<ElementRef>;

    //public datePickerConfig: IDatePickerConfig = {
    //    format: "MM/DD/YYYY"
    //};

        constructor(@Inject(LOCALE_ID) public locale: string, private pinnacleService: ProdGenApi, private router: Router, private sanitizer: DomSanitizer, private translationService: TranslationService, private controlService: AppControlService,
            private apiCacheService: APICacheService) {


    }

    ngOnInit() {
        scroll(0, 0);      
        //var newdate = new Date();
        //newdate.setDate(newdate.getDate() + 30);
        //console.log(newdate);
        //console.log(newdate.getDate());
        //console.log(newdate.getMonth());
        //console.log(newdate.getFullYear());

        

        this.dropdownSettingsUsers = {
            singleSelection: false,
            text: this.translationService.getTranslationFileData("WORKGROUP_MEMBER_VIEW.TS_SelectUsers"),
            enableSearchFilter: true,
            classes: "multi-select-dropdown"
        };

        this.dropdownSettingsRoles = {
            singleSelection: false,
            text: this.translationService.getTranslationFileData("WORKGROUP_MEMBER_VIEW.TS_SelectRoles"),
            enableSearchFilter: true,
            classes: "multi-select-dropdown"
        };



    }

    ngOnChanges(changes: SimpleChanges) {

        try {
            let w = changes['workgroup'].currentValue;
            if (w != null && w.workgroupId.toString() != this.curWorkgroupId) {

                this.membersLoaded = false;
                this.memberGroups = new Array<MemberGroup>();

                this.externalGroupList = new Array<WorkgroupExternalGroup>();
                this.exUsersLoaded = false;

                    try {                        
                        this.workgroup = w;
                        this.limitShowUsers =[2000, 2000, 2000];
                        this.membersLoaded = true;
                        
                        this.curWorkgroupId = this.workgroup.workgroupId.toString();                           
                        this.currentUserId = this.userIdCurrent;

                        this.loadMembers();
                        
                        this.LoadExternalMembers();
                        this.isMemberEditMode = false;

                        if (w.isExternal == true) {
                            this.currentUserPermissionsLevel = 'external';
                        }     
                        
                    } catch (err) { };

                    try {
                        this.currentUserPermissionsLevel = changes['workgroupPermissionsLevel'].currentValue;

                    } catch (err) { };

                    /*
                    try {
                        this.workgroupMembers = changes['workgroupMembers'].currentValue;
                        this.organizeMembers();
                    } catch (err) { };
                    */
            }
            

        } catch (err) { };

    }

    reloadUsersByLimit() {
        this.membersLoaded = false;

        this.memberGroups = new Array<MemberGroup>();
        this.loadMembers();

    }

    formatDate(date: Date, format: string) {
        const map = {
            mm: date.getMonth() + 1,
            dd: date.getDate(),
            yy: date.getFullYear().toString().slice(-2),
            yyyy: date.getFullYear()
        }

        return format.replace(/mm|dd|yy|yyy/gi, matched => map[matched])
    }

    /*
    organizeMembers() {
        this.memberGroups = new Array<MemberGroup>();
        

        let owners: MemberGroup = new MemberGroup(); 
        owners.name = "Owners";
        owners.members = this.workgroupMembers.filter(r => r.permissionLevel == "owner");

        

        owners.groupid = "owner";

        let editors: MemberGroup = new MemberGroup();
        editors.name = "Editors";
        editors.members = this.workgroupMembers.filter(r => r.permissionLevel == "editor");
        editors.groupid = "editor";

        let users: MemberGroup = new MemberGroup();
        users.name = "Members";
        users.members = this.workgroupMembers.filter(r => r.permissionLevel == "user");
        users.groupid = "user";

        let external: MemberGroup = new MemberGroup();
        external.name = "External Members";
        external.members = this.workgroupMembers.filter(r => r.permissionLevel == "external");
        external.groupid = "external";


        this.memberGroups.push(owners);
        this.memberGroups.push(editors);
        this.memberGroups.push(users);
        this.memberGroups.push(external);

        if (this.workgroup != null && this.workgroup.workgroupId != null && this.workgroupMembers.length >0) {

            this.pinnacleService.GetWorkgroupExternalGroups(this.workgroup.workgroupId as string).subscribe(res => {
                this.externalGroupList = res;



                this.GetRightsForExternalUsers();
            });
        }
        
    }
    */

    userPermissionLevelChanged(m: WorkgroupMember, g: MemberGroup) {

        //change value in service
        this.pinnacleService.updateWorkgoupMembers(this.workgroup.workgroupId, m.userId, PermissionLevel_T[m.permissionLevel as string]).subscribe(res => {

            if (res == true) {

                //change group 
                let v_Index = g.members.indexOf(m);
                g.members.splice(v_Index, 1);


                this.memberGroups.forEach(group => {
                    if (group.groupid == m.permissionLevel) {
                        if (m.isExplicitMember == false) {//if we are modifying a member that was part of a group we should change him/her to an explicit member and remove their associated roles
                            m.isExplicitMember = true;
                            m.inheritedRoles.splice(0);
                        }
                        group.members.push(m);
                        group.members.sort((a, b) => (a.name > b.name) ? 1 : -1);
                    }
                });
            }

        });
    }

    rolePermissionLevelChanged(r: WorkgroupRole, g: MemberGroup) {

        //change value in service
        this.pinnacleService.updateWorkgroupRole(this.workgroup.workgroupId, r.roleId, PermissionLevel_T[r.permissionLevel as string]).subscribe(res => {
            if (res == true) {

                this.loadMembers();

            }

        });
    }

    readMemberRoles(m: WorkgroupMember) {
        let v_RoleString = "";
        if (m != null && m.inheritedRoles != null && m.inheritedRoles.length > 0) {



            m.inheritedRoles.forEach(role => {
                if (v_RoleString.length > 0) {
                    v_RoleString += ", ";
                }

                v_RoleString += role.roleName;
            });

        }


        return v_RoleString;
    }

    @Output('selectedWorkgroupMemberIdsChange') selectedWorkgroupMemberIds = new EventEmitter<Array<string>>();

    loadMembers() {
        if (this.workgroup != null && this.workgroup.workgroupId != null && this.workgroup.workgroupId.length > 0) {
            this.pinnacleService.GetWorkgroupMembers(this.workgroup.workgroupId).subscribe(res => {
                


                this.workgroupMembers = res;
                this.onIncludedMembersChange();


                //set default image
                res.forEach(m => {
                    if (m.imageURL == "") {
                        m.imageURL = "../../assets/images/default_user.jpg";
                    }
                });




                //load roles
                this.pinnacleService.getWorkgroupRoles(this.workgroup.workgroupId, -1, 0).subscribe(res => {
                    this.currentRoles = res;
                    this.filteredRoles = Array.from(this.currentRoles);

                });


                this.loadMemberGroups(this.workgroupMembers);

            });
        }
    }

    loadMemberGroups(p_MemberArray: Array<WorkgroupMember>) {
        this.memberGroups = new Array<MemberGroup>();
        let owners: MemberGroup = new MemberGroup();
        owners.name = this.translationService.getTranslationFileData("WORKGROUP_MEMBER_VIEW.OwnerGroup");
        owners.members = p_MemberArray.filter(r => r.permissionLevel == "owner");
        owners.groupid = "owner";


        let editors: MemberGroup = new MemberGroup();
        editors.name = this.translationService.getTranslationFileData("WORKGROUP_MEMBER_VIEW.EditorGroup");
        editors.members = p_MemberArray.filter(r => r.permissionLevel == "editor");
        editors.groupid = "editor";

        let users: MemberGroup = new MemberGroup();
        users.name = this.translationService.getTranslationFileData("WORKGROUP_MEMBER_VIEW.MemberGroup");
        users.members = p_MemberArray.filter(r => r.permissionLevel == "user");
        users.groupid = "user";

        this.memberGroups.push(owners);
        this.memberGroups.push(editors);
        this.memberGroups.push(users);

        this.membersLoaded = true;        
    }


    GetRightsForExternalUsers() {
        this.canAddExternalUser = false;

        this.pinnacleService.getCurrentUserExternalInvitesRemainingByWorkGroup(this.workgroup.workgroupId as string).subscribe(res => {
            this.invitesRemaining = res;


            if (this.amIOwner() == true && this.canCreateExternalUsers == true) {
                this.canAddExternalUser = true;
            }
            else {
                this.externalGroupsCanBeEdited = {};
                this.pinnacleService.getCurrentUserExternalGroupsWithAddPermission(this.workgroup.workgroupId as string).subscribe(res2 => {


                    for (let v_ExternalGroup of res2) {
                        this.externalGroupsCanBeEdited[v_ExternalGroup.id] = true;
                    }

                    this.canAddExternalUser = res2.length > 0;


                });
            }


        });


    }

    amIOwner(): boolean {

        if (this.currentUserPermissionsLevel == "owner") {
            return true;
        }

        return false;


    }

    getDefaultExpandClass(group: MemberGroup): string {
        if (group.members.length > 0) {
            if (group.members[0].permissionLevel == "owner") {
                return " in";
            }
        }
        return "";
    }


    getMemberPermissionRole(member: WorkgroupMember): string {
        if (member.permissionLevel == "owner") {
            return this.translationService.getTranslationFileData("WORKGROUP_MEMBER_VIEW.OwnerOption");
        }
        if (member.permissionLevel == "editor") {
            return this.translationService.getTranslationFileData("WORKGROUP_MEMBER_VIEW.EditorOption");
        }
        if (member.permissionLevel == "user") {
            return this.translationService.getTranslationFileData("WORKGROUP_MEMBER_VIEW.MemberOption");
        }
        if (member.permissionLevel == "external") {
            return this.translationService.getTranslationFileData("WORKGROUP_MEMBER_VIEW.ExternalOption");
        }
        return this.translationService.getTranslationFileData("WORKGROUP_MEMBER_VIEW.MemberOption");

    }

    filterTextChanged() {
        let v_LowerFilter = this.filterText.toLowerCase().trim();

        if (v_LowerFilter.length > 0) {
            let v_TempMemberArray = Array.from(this.workgroupMembers);

            for (var i = 0; i < v_TempMemberArray.length; i++) {
                if ((v_TempMemberArray[i].name.toLowerCase().indexOf(v_LowerFilter) == -1) &&
                    v_TempMemberArray[i].email.toLowerCase().indexOf(v_LowerFilter) == -1) {
                    v_TempMemberArray.splice(i, 1);
                    i--;
                }
            }

            this.loadMemberGroups(v_TempMemberArray);


            this.loadFilteredExternalGroup();

            this.filteredExternalMemberCount = 0;

            for (var i = 0; i < this.filteredExternalGroupList.length; i++) {



                for (var x = 0; x < this.filteredExternalGroupList[i].members.length; x++) {
                    if ((this.filteredExternalGroupList[i].members[x].name.toLowerCase().indexOf(v_LowerFilter) == -1) &&
                        this.filteredExternalGroupList[i].members[x].email.toLowerCase().indexOf(v_LowerFilter) == -1) {
                        this.filteredExternalGroupList[i].members.splice(x, 1);
                        x--;
                    }
                }

                this.filteredExternalMemberCount += this.filteredExternalGroupList[i].members.length;

                if (this.filteredExternalGroupList[i].members.length == 0 && this.filteredExternalGroupList[i].multimember == false) {
                    this.filteredExternalGroupList.splice(i, 1);
                    i--;
                }

            }


            this.filteredRoles = Array.from(this.currentRoles);
            for (var i = 0; i < this.filteredRoles.length; i++) {
                if ((this.filteredRoles[i].name.toLowerCase().indexOf(v_LowerFilter) == -1)) {

                    this.filteredRoles.splice(i, 1);
                    i--;
                }
            }


        }
        else {

            this.loadMemberGroups(this.workgroupMembers);
            this.loadFilteredExternalGroup();


            this.filteredExternalMemberCount = 0;
            this.filteredExternalMemberCount += this.externalMemberCount;

            this.filteredRoles = Array.from(this.currentRoles);
        }




    }

    //necessary, as Array.from only creates new references for topmost array vals, not children arrays, such as members
    loadFilteredExternalGroup() {
        this.filteredExternalGroupList = new Array<WorkgroupExternalGroup>();

        this.externalGroupList.forEach(g => {
            let v_Workgroup = new WorkgroupExternalGroup();
            v_Workgroup.id = g.id;
            v_Workgroup.multimember = g.multimember;
            v_Workgroup.name = g.name;
            v_Workgroup.members = Array.from(g.members);

            this.filteredExternalGroupList.push(v_Workgroup);
        });
    }

    onHeaderClicked(header: string) {
        this.getExpandedIconClass("#collapse_" + header);

        var isExpanded = $("#image_" + header).hasClass("fa-minus");
        $("#image_" + header).toggleClass("fa-minus");
        $("#image_" + header).toggleClass("fa-plus");
    }

    getExpandedIconClass(elmId: string): string {
        //var isExpanded = $(elmId).hasClass("in");
        //if (isExpanded == true) {
        //    return "fa fa-minus";
        //}
        return "fa fa-minus";

    }


    onFacetHeaderClicked(permissionLevel: string) {

        var isExpanded = $("#image_" + permissionLevel).hasClass("fa-minus");
        $("#image_" + permissionLevel).toggleClass("fa-minus");
        $("#image_" + permissionLevel).toggleClass("fa-plus");

        if (permissionLevel == 'user') {

            if (isExpanded) {
                this.collapseAll = true;
            }
            else {
                this.collapseAll = false;
            }
        }

        else if (permissionLevel == 'owner') {

            if (isExpanded) {
                this.collapseOwners = true;
            }
            else {
                this.collapseOwners = false;
            }
        }

        else if (permissionLevel == 'editor') {

            if (isExpanded) {
                this.collapseEditors = true;
            }
            else {
                this.collapseEditors = false;
            }
        }
    }

    @Output("userGridVisibilityChanged") showUserGrid = new EventEmitter<boolean>();

    addGroupsClick() {
        this.dropdownUsersData = [];
        this.selRoles = [];


        this.pinnacleService.getJobRoles().subscribe(r => {
            this.dropdownRoleData = [];
            for (var i = 0; i < r.length; i++) {
                if (this.currentRoles.findIndex(m => m.roleId == r[i].roleId) == -1) {
                    this.dropdownRoleData.push({ "id": r[i].roleId, "itemName": r[i].roleName })
                }
            }

        });
    }

    addMembersClick() {
        this.showUserGrid.emit(true);
    }

    commitNewMembers(members, role: string) {
        let userIds = members.map(x => x.UserId);
        let level = role === "member" ? PermissionLevel_T.user : PermissionLevel_T[role];
        //console.log(userIds, role);
        this.pinnacleService.setWorkgoupMembers(this.workgroup.workgroupId, userIds, level).subscribe(m => {
            //console.log("Done");
            this.apiCacheService.invalidateContaining("users/info");
            this.loadMembers();
        })
    }

    commitNewGroups() {
        this.addMemberPermissionLevel = (<HTMLInputElement>document.getElementById("workgrouprole")).value;

        if (this.selRoles.length > 0) {
            let roleIds: Array<String> = new Array<String>();
            for (var i = 0; i < this.selRoles.length; i++) {
                roleIds.push(this.selRoles[i].id);
            }

            let v_PermissionLevel = PermissionLevel_T.user;
            if (this.addMemberPermissionLevel == "owner") {
                v_PermissionLevel = PermissionLevel_T.owner;
            }
            else if (this.addMemberPermissionLevel == "editor") {
                v_PermissionLevel = PermissionLevel_T.editor;
            }


            this.pinnacleService.setWorkgoupRoles(this.workgroup.workgroupId, roleIds, v_PermissionLevel).subscribe(m => {
                this.apiCacheService.invalidateContaining("users/info");
                this.loadMembers();
            });
        }
    }


    groupChanged(event) {

    }

    filterGroups() {
        if (this.externalGroupList != null) {
            return this.externalGroupList.filter(x => x.multimember == true && ((this.amIOwner() == true && this.canCreateExternalUsers == true) || (this.externalGroupsCanBeEdited[x.id] == true)));
        }
    }


    finishAddingExternalMembers() {
        /*
        this.pinnacleService.IsExistingUser(this.externalemail).subscribe(val => {

            if (val == true) {
                this.pinnacleService.SetExternalWorkgoupMember(this.workgroup.workgroupId as string, this.externalemail, this.selectedGroupID, this.externalIsManager).subscribe(res => {

                    this.membersUpdated.emit();
                });
            }
            else {
                
                this.finishAddingNewUser();
            }

        });
        */
        if (this.externalMembershipExpires == true && this.externalExpirationDate == "") {
            $("#addExternalErrorModal").modal("show");
        }
        else {
            var sendExpirationDate = "";
            if (this.externalMembershipExpires == false) {
                sendExpirationDate = "";
            }
            else {
                sendExpirationDate = this.externalExpirationDate;
            }
            $("#addExternalMembersModal").modal("hide");
            this.pinnacleService.SetExternalWorkgoupAndMember(this.workgroup.workgroupId, this.externalemail, this.externaldisplayname, this.externalIsManager, this.selectedGroupName, this.externalInvitesAllowed, this.externalMembershipExpires, sendExpirationDate).subscribe(res => {

                this.apiCacheService.invalidateContaining("users/info");
                var v_Msg = new MsgBxStringVals();
                if (res.length > 0) {
                    v_Msg.body = `${this.translationService.getTranslationFileData("WORKGROUP_MEMBER_VIEW.ExternAddMsg")}`;
                    v_Msg.title = `Invite External Member`;
                    this.controlService.openMessageBoxPopUp(v_Msg);
                    this.LoadExternalMembers();
                }
                
                
                this.externalExpirationDate = "";
            });
        }
    }

    finishAddingNewUser() {
        this.pinnacleService.addNewExternalUser(this.externalemail, this.externaldisplayname, this.externalimageurl).subscribe(res => {


            if (this.selectedGroupID == "") {
                this.pinnacleService.SetExternalWorkgoup(this.workgroup.workgroupId as string, this.externalemail, false).subscribe(res2 => {
                    this.pinnacleService.SetExternalWorkgoupMember(this.workgroup.workgroupId as string, this.externalemail, res2.id, this.externalIsManager).subscribe(res3 => {

                        //this.membersUpdated.emit();
                        this.LoadExternalMembers();
                    });
                });
            }
            else {
                this.pinnacleService.SetExternalWorkgoupMember(this.workgroup.workgroupId as string, this.externalemail, this.selectedGroupID, this.externalIsManager).subscribe(res => {

                    this.LoadExternalMembers();
                });
            }
        });
    }

    changeIsManagerSetting() {
        this.externalIsManager = !this.externalIsManager;
    }

    addExternalMembersClick(g: WorkgroupExternalGroup) {
        var date = new Date();
        date.setDate(date.getDate() + 30);
        
            // date.getFullYear() + "-" + date.getMonth() + "-" + date.getDay()
        this.externalExpirationDate = date.toUTCString();
        if (this.invitesRemaining > 0) {

            this.externalemail = "";
            //this.externalgroupid = g.id;
            this.selectedGroupID = "";
            this.externalIsManager = false;
            this.externaldisplayname = "";
            this.externalimageurl = "";
            this.selectedGroupName = "";
            this.externalInvitesAllowed = 0;


            if (this.currentUserPermissionsLevel == 'external') {
                let v_Key: string = "";
                Object.keys(this.externalGroupsCanBeEdited).forEach(o => {
                    v_Key = o;

                });


                let v_Group = this.externalGroupList.find(o => o.id == v_Key);

                if (v_Group != null) {
                    this.selectedGroupName = v_Group.name;
                }

            }

            $('#addExternalMembersModal').modal('show', { backdrop: 'static', keyboard: false });

        }
        else {

            var v_Msg = new MsgBxStringVals();
            v_Msg.body = this.translationService.getTranslationFileData("WORKGROUP_MEMBER_VIEW.NoLicenseMsg");
            v_Msg.title = ``;
            this.controlService.openMessageBoxPopUp(v_Msg);

        }




    }

    orgChange() {
        if (this.selectedGroupName == "") {
            this.externalIsManager = false;
        }
    }

    public onChange(event): void {  // event will give you full breif of action
    }


    removeMember(m: WorkgroupMember) {

        this.removeConfirmTitle = m.name as string;

        //Owner Work Group requires at least 1 member. 
        //--The removeConfirmBody determines if the remove -> continue button displays
        if (m.permissionLevel == 'owner' && this.memberGroups[0].members.length == 1) {
            this.removeConfirmBody = this.translationService.getTranslationFileData("WORKGROUP_MEMBER_VIEW.RemoveLastOwnerMsgBody");

        }
        else {
            if (this.currentUserId == m.userId) {
                this.removeConfirmBody = this.translationService.getTranslationFileData("WORKGROUP_MEMBER_VIEW.RemoveSelfMsgBody");
            }
            else {

                this.removeConfirmBody = this.translationService.getTranslationFileData("WORKGROUP_MEMBER_VIEW.RemoveMemMsgBody");
            }
        }

        this.deleteObservable = new Observable(() => {
            if (m.permissionLevel == "external") {
                this.pinnacleService.DeleteExternalWorkgroupMember(this.workgroup.workgroupId as string, m.workgroupId as string, m.userId as string).subscribe(res => {
                    if (this.currentUserId == m.userId) {
                        this.memberSelfDelete.emit();
                    }
                    else {
                        this.loadMembers();
                    }
                });
            }
            else {
                this.pinnacleService.DeleteWorkgoupMember(m.workgroupId, m.userId).subscribe(res => {
                    this.apiCacheService.invalidateContaining("users/info");
                    this.loadMembers();
                }, err => { return; });
                if (this.currentUserId == m.userId) {
                    this.memberSelfDelete.emit();
                }
            }
        });

        $('#removeConfirmModal').modal('show');
    }


    removeRole(role: WorkgroupRole) {
        /*
        if (confirm("Removing " + role.name + "and all of the associated members. Continue?")) {
            this.pinnacleService.DeleteWorkgoupRole(this.workgroup.workgroupId, role.roleId).subscribe(m => { this.loadMembers(); });
        }
        */
        this.removeConfirmTitle = role.name as string;

        if (this.confirmGroupRemoval(role)) {
            this.removeConfirmBody = this.translationService.getTranslationFileData("WORKGROUP_MEMBER_VIEW.RemoveRoleBody");
        }
        else {
            this.removeConfirmBody = this.translationService.getTranslationFileData("WORKGROUP_MEMBER_VIEW.RemoveLastOwnerMsgBody")
        }

        this.deleteObservable = new Observable(() => {
            this.pinnacleService.DeleteWorkgoupRole(this.workgroup.workgroupId, role.roleId).subscribe(m => {
                this.apiCacheService.invalidateContaining("users/info");
                this.loadMembers();
            });
        });

        $('#removeConfirmModal').modal('show');
    }

    confirmGroupRemoval(role: WorkgroupRole): boolean {

        //Owner Work Group requires at least 1 member. 
        //This removes the role dropdown if there is only one group (no members) in the Owner Group

        if (role.permissionLevel == 'owner') {

            //Check if there is more than 2 Owner groups
            var numOwnerGroups = 0;
            for (let group of this.filteredRoles) {
                if (group.permissionLevel == "owner") {
                    numOwnerGroups++;
                    if (numOwnerGroups >= 2) {
                        return true;
                    }
                }
            }

            //Check if one member.isExplicitMember==true
            for (let member of this.memberGroups[0].members) {
                if (member.isExplicitMember) {
                    return true;
                }
            }
            return false;
        }
        return true;
    }

    removeExternalGroup(group: WorkgroupExternalGroup) {
        /*
        if (confirm("Removing " + group.name + "and all of the associated members. Continue?")) {
            this.pinnacleService.DeleteExternalWorkgroup(this.workgroup.workgroupId as string, group.id).subscribe(res => {
                if (res == true) {
                    let v_Index = this.externalGroupList.indexOf(group);
                    this.externalGroupList.splice(v_Index, 1);

                    this.externalMemberCount -= group.members.length;
                }
            });
        }
        */


        if (group.multimember == true) {
            this.removeConfirmTitle = group.name;
            this.removeConfirmBody = this.translationService.getTranslationFileData("WORKGROUP_MEMBER_VIEW.RemoveExternalGroupBody");
        }
        else {
            this.removeConfirmTitle = group.name;
            this.removeConfirmBody = this.translationService.getTranslationFileData("WORKGROUP_MEMBER_VIEW.RemovingExternalGroupMemberBody");
        }

        this.deleteObservable = new Observable(() => {

            this.pinnacleService.DeleteExternalWorkgroup(this.workgroup.workgroupId as string, group.id).subscribe(res => {
                this.apiCacheService.invalidateContaining("users/info");
                if (res == true) {
                    /*
                    let v_Index = this.externalGroupList.indexOf(group);
                    this.externalGroupList.splice(v_Index, 1);

                    this.externalMemberCount -= group.members.length;
                    */
                    this.LoadExternalMembers();
                }
            });

        });

        $('#removeConfirmModal').modal('show');
    }


    removeExternalGroupMember(group: WorkgroupExternalGroup, member: WorkgroupExternalMembers) {



        if (this.currentUserId == member.id) {
            this.removeConfirmBody = this.translationService.getTranslationFileData("WORKGROUP_MEMBER_VIEW.RemoveSelfMsgBody");
        }
        else {
            this.removeConfirmBody = `${group.name}: ${this.translationService.getTranslationFileData("WORKGROUP_MEMBER_VIEW.RemovingExternalGroupMemberBody")}`;
        }
        this.removeConfirmTitle = this.translationService.getTranslationFileData("WORKGROUP_MEMBER_VIEW.RemoveExternalMemTitle");


        this.deleteObservable = new Observable(() => {

            this.pinnacleService.DeleteExternalWorkgroupMember(this.workgroup.workgroupId as string, group.id, member.id).subscribe(res => {
                if (res == true) {

                    //let v_GroupIndx = this.externalGroupList.indexOf(group);
                    //let v_MemberIndex = this.externalGroupList[v_GroupIndx].members.indexOf(member);

                    //this.externalGroupList[v_GroupIndx].members.splice(v_MemberIndex, 1);
                    //this.externalMemberCount -= 1;

                    this.LoadExternalMembers();
                    if (this.currentUserId == member.id) {
                        this.memberSelfDelete.emit();
                    }
                }
            });

        });

        $('#removeConfirmModal').modal('show');

    }

    LoadExternalMembers() {
        if (this.workgroup.workgroupId != null) {
            this.pinnacleService.GetWorkgroupExternalGroups(this.workgroup.workgroupId as string).subscribe(res => {
                //this.externalGroupList = res.sort((a, b) => a.multimember === b.multimember ? 0 : a ? -1 : 1);
                
                this.externalGroupList = res;
                this.loadFilteredExternalGroup();

                this.externalMemberCount = 0;
                this.filteredExternalMemberCount = 0;

                this.canManageGroupSet = {};

                res.forEach(g => {


                    this.externalMemberCount += g.members.length;
                    this.filteredExternalMemberCount += g.members.length;

                    this.canManageGroupSet[g.id] = false;

                    //load default images
                    g.members.forEach(m => {
                        if (m.imageURL == "") {
                            m.imageURL = "../../assets/images/default_user.jpg";
                        }




                        if (m.id == this.currentUserId) {


                            if ((g.multimember == true && m.isManager == true)) {
                                this.canManageGroupSet[g.id] = true;
                            }
                        }

                        m.expirationDate = m.expirationDate.split('T')[0];

                    });


                });

                this.exUsersLoaded = true;

                this.GetRightsForExternalUsers();

                this.onIncludedMembersChange();
            });
        }
    }

    updateMember(m: WorkgroupExternalMembers, group: WorkgroupExternalGroup) {

        if ((m.maxInvites as number)) {

        }
        else {
            m.maxInvites = 0;
        }

        if (m.isManager == false) {
            m.maxInvites = 0;
        }


        this.pinnacleService.UpdateExternalWorkgoupMember(this.workgroup.workgroupId, m.id, group.id, m.isManager, m.maxInvites, m.membershipExpires, m.expirationDate).subscribe(res => {
            for (var i = 0; i < this.filteredExternalGroupList.length; i++) {
                let foundindex = this.filteredExternalGroupList[i].members.findIndex(x => x.id == m.id);
                if (foundindex != -1) {
                    this.filteredExternalGroupList[i].members[foundindex].membershipExpires = m.membershipExpires;
                    this.filteredExternalGroupList[i].members[foundindex].expirationDate = m.expirationDate;
                }
            }
        }, err => {

            var v_Msg = new MsgBxStringVals();
            v_Msg.body = this.translationService.getTranslationFileData("WORKGROUP_MEMBER_VIEW.NoUpdateMsg");
            v_Msg.title = ``;
            this.controlService.openMessageBoxPopUp(v_Msg);

        });

    }

    resendInvite(m: WorkgroupExternalMembers, group: WorkgroupExternalGroup) {
        if (m.status == false) {
            this.pinnacleService.ResendExternalWorkgoupMemberInvite(this.workgroup.workgroupId, m.id, group.id).subscribe(res => {

                var v_Msg = new MsgBxStringVals();
                v_Msg.body = this.translationService.getTranslationFileData("WORKGROUP_MEMBER_VIEW.ResendInviteMsg");
                v_Msg.title = ``;
                this.controlService.openMessageBoxPopUp(v_Msg);
            }, err => {

            });
        }
    }

    onEditToggle() {
        this.isMemberEditMode = !this.isMemberEditMode;
        if (this.isMemberEditMode == true) {
            this.viewType = 'Groups';
        }
        else {
            this.viewType = 'Individual';
        }
    }
    setModalVars(extUser: WorkgroupExternalMembers, group: WorkgroupExternalGroup) {
        //this.modalMember = extUser;
        this.modalMember.email = extUser.email;

        var date = new Date();
        date.setDate(date.getDate() + 30);
        if (extUser.expirationDate == "0001-01-01") {
            this.modalMember.expirationDate = date.toUTCString();
        }
        else {
            this.modalMember.expirationDate = extUser.expirationDate;
        }
        this.modalMember.id = extUser.id;
        this.modalMember.imageURL = extUser.imageURL;
        this.modalMember.inviteDate = extUser.inviteDate;
        this.modalMember.inviteFromDisplayName = extUser.inviteFromDisplayName;
        this.modalMember.inviteFromEmail = extUser.inviteFromEmail;
        this.modalMember.isManager = extUser.isManager;
        this.modalMember.maxInvites = extUser.maxInvites;
        this.modalMember.membershipExpires = extUser.membershipExpires;
        this.modalMember.name = extUser.name;
        this.modalMember.organizationName = extUser.organizationName;
        this.modalMember.organizationRole = extUser.organizationRole;
        this.modalMember.phone = extUser.phone;
        this.modalMember.status = extUser.status;

        this.modalGroup = group;
    }

    // Build a list of user and external user
    // identifiers for the userdatagridcomponent
    // to use as a whitelist.
    onIncludedMembersChange(): void {
        let memberIds = [];
        this.workgroupMembers.forEach(x => {
            memberIds.push(x.userId);
        });
        this.externalGroupList.forEach(x => {
            x.members.forEach(y => {
                if (y.status) {
                    memberIds.push(y.id);
                }
            });
        });
        this.selectedWorkgroupMemberIds.emit(memberIds);
    }

}


export class MemberGroup {
    name: string;
    groupid: string;
    members: Array<WorkgroupMember> = new Array<WorkgroupMember>();
}
