import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class AutoplayVidService {
  autoplayNext: boolean = false;
  runautoplay: boolean = false;


  constructor(private router: Router) {

  }


  SetAutoplayNext(value:boolean): void {
    this.autoplayNext = value;
  }

  GetAutoplayNext(): boolean {
    
    return this.autoplayNext;
  }

  SetRunAutoplay(value: boolean): void {
    this.runautoplay = value;
  }

  GetRunAutoplay(): boolean {

    return this.runautoplay;
  }
}
