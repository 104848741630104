import { Component, OnInit } from '@angular/core';
import { BrowserAuthenticationService } from './../BrowserAuthenticationService';

@Component({
  selector: 'app-learningpageoutlet',
  templateUrl: './learningpageoutlet.component.html',
  styleUrls: ['./learningpageoutlet.component.css']
})
export class LearningpageoutletComponent implements OnInit {

	isAuthenticated: boolean = false;

	constructor(private authenticationService: BrowserAuthenticationService) {

	  if (authenticationService.AuthenticatePage() == true) {
		  this.isAuthenticated = true;
	  }
   }

  ngOnInit() {
  }

}
