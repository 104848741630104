import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { WidgetProperties } from '../../apiService/classFiles/class.users';
import { ProdGenApi } from '../../apiService/prodgen.api';
import { WidgetContainerComponent } from '../widget-container/widget-container.component';
declare var $: any;

@Component({
    selector: 'app-widgetconfig',
    templateUrl: './widgetconfig.component.html',
    styleUrls: ['./widgetconfig.component.css']
})
export class WidgetconfigComponent implements OnInit, AfterViewInit {
    @Input('widgetID') widgetID: string;
    @Input('widgetProps') widgetProps: Array<WidgetProperties> = new Array<WidgetProperties>();
    @Input('container') widgetContainer: WidgetContainerComponent;
    @Output() propsChanged = new EventEmitter<Array<WidgetProperties>>();
    @Output() widgetRemoved = new EventEmitter<string>();

    antiScale = "scale(1.0)";
    
    themeColors: Array<string[]> = [
        ["--blank--", "(Custom Color)"],
        ["--navbar-foreground-color", "Navigation Bar- Foreground"],
        ["--navbar-background-color", "Navigation Bar- Background"],
        ["--page-text-color", "General Page- Text"],
        ["--page-background-color", "General Page- Background"],
        ["--page-separator-color", "General Page- Separator"],
        ["--large-card-foreground-color1", "Large Card 1- Text"],
        ["--large-card-background-color1", "Large Card 1- Background"],
        ["--large-card-foreground-color2", "Large Card 2- Text"],
        ["--large-card-background-color2", "Large Card 2- Background"],
        ["--large-card-foreground-color3", "Large Card 3- Text"],
        ["--large-card-background-color3", "Large Card 3- Background"],
        ["--large-card-border-color", "Large Card- Border"],
        ["--small-card-foreground-color1", "Small Card 1- Text"],
        ["--small-card-background-color1", "Small Card 1- Background"],
        ["--small-card-foreground-color2", "Small Card 2- Text"],
        ["--small-card-background-color2", "Small Card 2- Background"],
        ["--small-card-foreground-color3", "Small Card 3- Text"],
        ["--small-card-background-color3", "Small Card 3- Background"],
        ["--small-card-border-color", "Small Card- Border"],
        ["--panel-title-foreground-color", "Panel Title- Text"],
        ["--panel-title-background-color", "Panel Title- Background"],
        ["--panel-body-foreground-color", "Panel Body- Text"],
        ["--panel-body-background-color", "Panel Body- Background"],
        ["--panel-border-color", "Panel Border"],
        ["--hsl-foreground-color", "Home Screen Left- Text"],
        ["--hsl-background-color", "Home Screen Left- Background"],
        ["--hsl-border-color", "Home Screen Left- Border/Separator"],
        ["--hsr-foreground-color", "Home Screen Right- Text"],
        ["--hsr-background-color", "Home Screen Right- Background"],
        ["--hsr-border-color", "Home Screen Right- Border/Separator"],
        ["--button-foreground", "Button- Text"],
        ["--button-background", "Button- Background"],

    ];

    themeBorderWidth: Array<string[]> = [
        ["--blank--", "(Custom Width)"],
        ["--large-card-border-width", "Large Card"],
        ["--small-card-border-width", "Small Card"],
        ["--panel-border-width", "Panel"]
    ];

    themeBorderRadius: Array<string[]> = [
        ["--blank--", "(Custom Radius)"],
        ["--large-card-border-radius", "Large Card"],
        ["--small-card-border-radius", "Small Card"],
        ["--panel-border-radius", "Panel"]
    ];

    textAlignment: Array<string[]> = [
        ["left", "Left", "fas fa-align-left"],
        ["center", "Center", "fas fa-align-center"],
        ["right", "Right", "fas fa-align-right"],
        ["justify", "Justify", "fas fa-align-justify"]
    ];

    itemAlignment: Array<string[]> = [
        ["left", "Left", "fas fa-align-left"],
        ["center", "Center", "fas fa-align-center"],
        ["right", "Right", "fas fa-align-right"]
    ];


    //This if for the icon section, not implemented yet.
    icons: Array<string[]> = [
        ["none", "(None)"],
        ["fas fa-anchor", "Anchor"],
        ["fas fa-angry", "Angry Face"],
        ["fas fa-bomb", "Bomb"],
        ["fas fa-cat", "Cat"],
        ["fas fa-car", "Car"],
        ["fas fa-dog", "Dog"]
    ]

    constructor(private pinnacleService: ProdGenApi) {

    }

    ngOnInit() {
        this.antiScale = "scale(" + 1.0 / this.widgetContainer.scale + ")";
    }

    ngAfterViewInit() {
        let modal = document.getElementById("configModal_" + this.widgetID);
        //let modalbody = modal.getElementsByClassName("modal-body");

        //for (var i = 0; i < this.widgetProps.length; i++) {
        //    switch (this.widgetProps[i].propType) {
        //        case "string":
        //            let stringlabel = document.createElement("label");
        //            stringlabel.innerText = this.widgetProps[i].propName;
        //            let stringinput = document.createElement("input");
        //            stringinput.type = "text";
        //            stringinput.value = this.widgetProps[i].propValue;
        //            stringinput.id = this.widgetProps[i].propID + "_" + this.widgetID;
        //            stringinput.classList.add("inputcontrol");
        //            stringinput.setAttribute("draggable", "false");

        //            let stringcontainer = document.createElement("div");
        //            stringcontainer.id = this.widgetProps[i].propName.replace(" ", "") + "_div"

        //            stringcontainer.appendChild(stringlabel);
        //            stringcontainer.appendChild(stringinput);

        //            modalbody[0].appendChild(stringcontainer);
        //            break;
        //        case "color":
        //            let colorlabel = document.createElement("label");
        //            colorlabel.innerText = this.widgetProps[i].propName;
        //            let colorinput = document.createElement("input");
        //            colorinput.type = "color";
        //            colorinput.value = this.widgetProps[i].propValue;
        //            colorinput.id = this.widgetProps[i].propID + "_" + this.widgetID;
        //            colorinput.classList.add("inputcontrol");
        //            colorinput.setAttribute("draggable", "false");

        //            let colorcontainer = document.createElement("div");
        //            colorcontainer.id = this.widgetProps[i].propName.replace(" ", "") + "_div"

        //            colorcontainer.appendChild(colorlabel);
        //            colorcontainer.appendChild(colorinput);

        //            modalbody[0].appendChild(colorcontainer);
        //            break;
        //        case "number":
        //            let numberlabel = document.createElement("label");
        //            numberlabel.innerText = this.widgetProps[i].propName;
        //            let numberinput = document.createElement("input");
        //            numberinput.type = "number";
        //            numberinput.value = this.widgetProps[i].propValue;
        //            numberinput.id = this.widgetProps[i].propID + "_" + this.widgetID;
        //            numberinput.classList.add("inputcontrol");
        //            numberinput.setAttribute("draggable", "false");

        //            let numbercontainer = document.createElement("div");
        //            numbercontainer.id = this.widgetProps[i].propName.replace(" ", "") + "_div"

        //            numbercontainer.appendChild(numberlabel);
        //            numbercontainer.appendChild(numberinput);
        //            modalbody[0].appendChild(numbercontainer);
        //            break;

        //        default:
        //            break;
        //    }

        //}


    }

    getPropertiesTop(widgetId: string): number {
        let margin = 80;
        for (let w of this.widgetContainer.containerWidgets) {
            if (w.id == widgetId) {
                margin = w.lg_y * 50 * this.widgetContainer.scale - 200;
                if (margin < 80) {
                    margin = 80;
                }
            }
        }
        return margin;
    }

    getPropertiesLeft(widgetId: string): number {
        let margin = 0;

        margin = 1.0 - this.widgetContainer.scale;
        margin *= -100.0;

        return margin;
    }

    saveChanges() {
        for (var i = 0; i < this.widgetProps.length; i++) {
            if (this.widgetProps[i].propType == "select") {
                this.widgetProps[i].propValue = (<HTMLSelectElement>document.getElementById(this.widgetProps[i].propID + "_" + this.widgetID)).value;
            }

            //Ignore these -- They are updated prior
            else if (this.widgetProps[i].propType == "themeColor" || this.widgetProps[i].propType == "color" ||
                this.widgetProps[i].propType == "themeBorderWidth" || this.widgetProps[i].propType == "themeBorderRadius"
                || this.widgetProps[i].propType == "dividerLabel" || this.widgetProps[i].propType == "iconSelect"
                || this.widgetProps[i].propType == "textAlignment" || this.widgetProps[i].propType == 'verticalAlign'
                || this.widgetProps[i].propType == "itemAlign") {
                
            }

            else if (this.widgetProps[i].propType == "image") {
                //Ignore this too!!
                //this will actually be taken care of by the upload portion.
                //This line will need to be removed later.
                //this.widgetProps[i].propValue = this.widgetProps[i].propValue;
            }
            else if (this.widgetProps[i].propType == "shouldTranslate") {
                this.widgetProps[i].propValue = (<HTMLInputElement>document.getElementById(this.widgetProps[i].propID + "_" + this.widgetID + "_shouldTranslate")).checked.toString();
            }
            else {
                this.widgetProps[i].propValue = (<HTMLInputElement>document.getElementById(this.widgetProps[i].propID + "_" + this.widgetID)).value;
            }
        }

        this.propsChanged.emit(this.widgetProps);
        this.indexAdjustmentsClose();

        $('#configModal_' + this.widgetID).modal("hide");
    }
    close() {
        this.indexAdjustmentsClose();
    }

    indexAdjustmentsOpen() {
        let otherwidgets = document.getElementsByClassName("widget ongrid " + this.widgetContainer.seq);
        for (var i = 0; i < otherwidgets.length; i++) {
            if ((<HTMLElement>otherwidgets[i]).getAttribute("data-widgetid") != this.widgetID) {
                (<HTMLElement>otherwidgets[i]).style.zIndex = "10";
            }
            otherwidgets[i].setAttribute("draggable", "false");
        }
    }
    indexAdjustmentsClose() {
        let otherwidgets = document.getElementsByClassName("widget ongrid " + this.widgetContainer.seq);
        for (var i = 0; i < otherwidgets.length; i++) {
            if ((<HTMLElement>otherwidgets[i]).getAttribute("data-widgetid") != this.widgetID) {
                (<HTMLElement>otherwidgets[i]).style.zIndex = "10000";
            }
            otherwidgets[i].setAttribute("draggable", "true");
        }
    }


    removeWidget() {
        let ongridwidgets = document.getElementsByClassName("widget ongrid " + this.widgetContainer.seq);
        let seq = "";
        for (var i = 0; i < ongridwidgets.length; i++) {
            if (ongridwidgets[i].id.includes(this.widgetID) == true) {
                seq = (ongridwidgets[i].id.split("_"))[2];
            }
        }

        let elementToRemove = document.getElementById("widget_" + this.widgetID + "_" + seq);
        let graveyard = document.getElementById("graveyarddiv" + seq);
        graveyard.appendChild(elementToRemove);
        this.widgetRemoved.emit(this.widgetID);


        //graveyard.removeChild(elementToRemove);
        //this.pinnacleService.removeWidgetFromContainer(this.widgetID).subscribe(res => {
        //    console.log("deleted");
        //}, err => { console.log(err); });
    }

    useDefaultValue(prop: WidgetProperties) {

        for (var i = 0; i < this.widgetProps.length; i++) {
            if (this.widgetProps[i].propType == "string" && this.widgetProps[i].propName == prop.propName) {
                (<HTMLInputElement>document.getElementById(this.widgetProps[i].propID + "_" + this.widgetID)).value = prop.propDefaultValue;
                break;
            }
        }
    }


    uploadClick(prop: WidgetProperties) {

        this.widgetContainer.updateImageUploadProp(prop, this.widgetID);

    }

    onImageChanged(event: any) {

        //Get the right widgetProp
        for (var i = 0; i < this.widgetProps.length; i++) {
            if (this.widgetProps[i].propID == this.widgetContainer.imageUploadProp.propID && this.widgetID == this.widgetContainer.imageUploadWidgetID) {
                
                this.widgetProps[i].propValue = event.imageURL;

                this.propsChanged.emit(this.widgetProps);
                break;
            }
        }

    }

    themeDropDown(wP: WidgetProperties, idEnding: string) {
        for (var i = 0; i < this.widgetProps.length; i++) {

            if (this.widgetProps[i] == wP) {
                let newValue = (<HTMLInputElement>document.getElementById(wP.propID + "_" + this.widgetID + idEnding)).value;
                if (newValue != "--blank--") {
                    this.widgetProps[i].propValue = newValue;
                    if (wP.propType == 'themeBorderWidth' || wP.propType == 'themeBorderRadius') {
                        (<HTMLInputElement>document.getElementById(wP.propID + "_" + this.widgetID + '_number')).value = "";
                    }
                    break;
                }
            }
        }
    }

    themeNumberUpdate(wP: WidgetProperties) {
        wP.propValue = (<HTMLInputElement>document.getElementById(wP.propID + "_" + this.widgetID + "_number")).value;
    }

    //NOT IMPLEMENTED YET -- This will be able to select an icon vs image
    //genericListSelectionChange(wP: WidgetProperties) {
    //    wP.propValue = (<HTMLInputElement>document.getElementById(wP.propID + "_" + this.widgetID)).value;
    //}

    buttonClickUpdatePropValue(value: string, wP: WidgetProperties) {
        wP.propValue = value;
    }

    colorPickerUpdate(event: any, wP: WidgetProperties) {//itemToUpdate: string) {  https://www.npmjs.com/package/ngx-color-picker
        if (wP.propType == 'themeColor') {
            wP.propValue = event;
        }
    }

    colorPickerTheme(wP: WidgetProperties): string {
        if (wP.propValue.startsWith('--')) {
            return '#ffffff';
        }
        return wP.propValue;
    }

    displayMobile(wP: WidgetProperties) {

        let isChecked = (<HTMLInputElement>document.getElementById(wP.propID + "_" + this.widgetID + "_display")).checked;
        if (!isChecked) {
            wP.propValue = '-1';
        }
        else {
            wP.propValue = '1';
        }        
    }

    displayMobileSequence(wP: WidgetProperties) {
        let numberValue = (<HTMLInputElement>document.getElementById(wP.propID + "_" + this.widgetID)).value;
        
        if (+numberValue < 1) {
            (<HTMLInputElement>document.getElementById(wP.propID + "_" + this.widgetID)).value = '1';

        }
    }

    shouldTranslate(wP: WidgetProperties) {

        let isChecked = (<HTMLInputElement>document.getElementById(wP.propID + "_" + this.widgetID + "_shouldTranslate")).checked;
        wP.propValue = isChecked.toString();

    }
}
