export const environment = {
    production: true,
    beta: false,
    dev: false,
    local: false,
    apiUrl: {
        v1: 'https://prodgenapi.azurewebsites.net/api',
        v2: 'https://prodgenapi.azurewebsites.net/api/v2'
    },
    apiKey: '9D391065-2ABE-4681-9EA3-A78557A42A13'
};
