// Angular imports
import { Component, EventEmitter, ViewChild, Output, NgZone, ElementRef, ChangeDetectorRef, Input } from '@angular/core';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { setTimeout } from 'timers';

// Pinnacle imports
import { ProdGenApi } from '../../apiService/prodgen.api';
import { TranslationService } from '../../services/TranslationService';
import { LiveEventWrapper, LiveEventBrowserCalendarStatus, LiveEventProperty, LiveEventPropertyType } from '../../../app/apiService/classFiles/class.liveevents';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { ContentPrerequisite } from '../../../app/apiService/classFiles/class.content';
import { LiveEventPropertiesComponent } from '../liveeventproperties/liveeventproperties.component';
import { Observable } from 'rxjs';
import { User } from '../../../app/apiService/classFiles/class.users';
import { PinnacleDateTimePipe } from '../../../app/pipes/pinnacleDateTime';
// JQuery workaround
declare var $: any;

@Component({
    selector: 'template-liveeventregistrationform',
    templateUrl: 'liveeventregistrationform.component.html',
    styleUrls: ['liveeventregistrationform.component.css'],
    providers: [ProdGenApi]
})

export class LiveEventRegistrationForm {

    currentDate: Date = new Date(Date.now());
    currentUser: User;
    showLoading: boolean = true;
    showInfo: boolean = false;
    showDescription: boolean = false;
    showRegistrationInfoProperties: boolean = false;
    showEventSessionProperties: boolean = false;
    showInstructorEntryProperties: boolean = false;
    showSurveyFieldProperties: boolean = false;
    showRegistrationRemovalPrompt: boolean = false;
    showRegistrationConfirmation: boolean = false;
    showRegistrationError: boolean = false;
    showSurveyConfirmation: boolean = false;
    showSurveyError: boolean = false;
    showCalendarMessage: boolean = false;
    showRegistrationButton: boolean = false;
    showSubmitButton: boolean = false;
    showRemoveRegistrationButton: boolean = false;
    showRegistrationRemovalConfirmation: boolean = false;
    showCloseButton: boolean = false;
    showYesNoButtons: boolean = false;
    showReturnButton: boolean = false;

    incompletePrerequisites: Array<ContentPrerequisite> = [];
    currentDateTime: Date = new Date(Date.now());
    datepipe: DatePipe = new DatePipe("en-US");
    hasProperties: boolean[] = [false, false, false, false]; // 0 -> RegistrationInfo, 1 -> EventSession, 2 -> InstructorEntry, 3 -> SurveyField

    selectedEventRegistrationWindowString = "";
    selectedEventDateString: string = "";
    selectedEventDurationString: string = "";
    descriptionTitleColor: string = "#555";
    descriptionBackgroundColor: string = "#fff";
    selectedEventFormattedDescription: SafeHtml = "";
    selectedEventStatusString: string = "Loading...";
    selectedEventStatusBadgeString: string = "";
    registrationErrorMsg: string = "";
    randID: string = "";
    registrationURLOverride: string = "";

    @Input() selectedEvent: LiveEventWrapper = new LiveEventWrapper(
        "00000000-0000-0000-0000-000000000000",         // Event ID
        "",                                         // Event name
        "",                                         // Simple description
        "",                                         // Formatted description
        "",                                         // Keywords
        new Date(1970, 0, 1, 0, 0, 0, 0),           // Previous recording date
        "00000000-0000-0000-0000-000000000000",         // Previous recording video ID
        "00000000-0000-0000-0000-000000000000",         // Folder ID
        false,                                      // Is deleted
        "00000000-0000-0000-0000-000000000000",         // Provided by
        0,                                          // Duration (mins)
        new Array<ContentPrerequisite>(),           // Prerequisites
        new Array<LiveEventProperty>(),             // Properties
        "00000000-0000-0000-0000-000000000000",         // Session ID
        "00000000-0000-0000-0000-000000000000",         // Presenter ID
        "",                                         // Presenter display name
        "00000000-0000-0000-0000-000000000000",         // Scheduled by
        new Date(1970, 0, 1, 0, 0, 0, 0),           // Session datetime (unused)
        "",                                         // Session URL
        "",                                         // Session location
        LiveEventBrowserCalendarStatus.Internal,    // Session status
        new Date(1970, 0, 1, 0, 0, 0, 0),           // Session start datetime
        new Date(1970, 0, 1, 0, 0, 0, 0),           // Session end datetime
        false,                                      // All day session (unused)
        false,                                      // Use registration dates
        new Date(1970, 0, 1, 0, 0, 0, 0),           // Registration start date
        new Date(1970, 0, 1, 0, 0, 0, 0),           // Registration end date
        "00000000-0000-0000-0000-000000000000",
        false                                       // Session attended
	);

	

    @Output() selectedEventChange: EventEmitter<LiveEventWrapper> = new EventEmitter<LiveEventWrapper>();

    @ViewChild('registrationInfoProperties', { read: LiveEventPropertiesComponent, static: false }) registrationPropertiesComponent: LiveEventPropertiesComponent;
    @ViewChild('eventSessionProperties', { read: LiveEventPropertiesComponent, static: false }) infoPropertiesComponent: LiveEventPropertiesComponent;
    @ViewChild('surveyFieldProperties', { read: LiveEventPropertiesComponent, static: false }) surveyPropertiesComponent: LiveEventPropertiesComponent;
    @ViewChild('instructorEntryProperties', { read: LiveEventPropertiesComponent, static: false }) instructorEntryPropertiesComponent: LiveEventPropertiesComponent;

    //selectedCalendarString: string = "calendar";
    //userRegisteredForSelectedEvent: boolean = false;
    //propertyTypes: Array<LiveEventPropertyType>;

    constructor(private translationService: TranslationService,
                private pinnacleService: ProdGenApi,
                private sanitizer: DomSanitizer,
                private changeRef: ChangeDetectorRef,
		private router: Router) {

        this.setRandomModalID();
        this.router.events.subscribe(e => {
            $('.modal-backdrop').remove();
            $('body').removeClass("modal-open");
        });
        //this.propertyTypes = new Array<LiveEventPropertyType>();
        //this.propertyTypes.push(LiveEventPropertyType.RegistrationField);

		
	}

	sanitize(url: string) {
		return this.sanitizer.bypassSecurityTrustUrl(url);
	}

    showForm(): void {
        // Only act if session has been provided
		if (this.selectedEvent.sessionID != "00000000-0000-0000-0000-000000000000") {
			

            let newProps = [];
            for (let i = 0; i < this.selectedEvent.properties.length; i++) {
                if (this.selectedEvent.properties[i].propertyType != LiveEventPropertyType.RegistrationField
                    && this.selectedEvent.properties[i].propertyType != LiveEventPropertyType.SurveyField
                    && this.selectedEvent.properties[i].isVisibleInBrowser) {
                    newProps.push(this.selectedEvent.properties[i]);
                }
                // Hidden patch for redirecting users to external registration site
                if (this.selectedEvent.properties[i].propertyName == "registration_URL_override") {
                    this.registrationURLOverride = this.selectedEvent.properties[i].stringValue;
                }
            }
            this.selectedEvent.properties = newProps;

            this.registrationErrorMsg = "";
            this.incompletePrerequisites = [];

            this.pinnacleService.getCurrentUser().subscribe(res => {
                this.currentUser = res;
            });

            // Reset visibility flags
            this.showLoading = true;
            this.showInfo = false;
            this.showDescription = false;
            this.showRegistrationInfoProperties = false;
            this.showEventSessionProperties = false;
            this.showInstructorEntryProperties = false;
            this.showSurveyFieldProperties = false;
            this.showRegistrationRemovalPrompt = false;
            this.showRegistrationConfirmation = false;
            this.showRegistrationError = false;
            this.showSurveyConfirmation = false;
            this.showSurveyError = false;
            this.showCalendarMessage = false;
            this.showRegistrationButton = false;
            this.showSubmitButton = false;
            this.showRemoveRegistrationButton = false;
            this.showRegistrationRemovalConfirmation = false;
            this.showCloseButton = true;
            this.showYesNoButtons = false;
            this.showReturnButton = false;

            // Dates break all the time, make sure ours is still of correct type
            this.selectedEvent.sessionDateTime = new Date(this.selectedEvent.sessionDateTime);
            this.selectedEvent.registrationStartDate = new Date(this.selectedEvent.registrationStartDate);
            this.selectedEvent.registrationEndDate = new Date(this.selectedEvent.registrationEndDate);
            this.selectedEvent.startDate = new Date(this.selectedEvent.startDate);
            this.selectedEvent.endDate = new Date(this.selectedEvent.endDate);

            // Set session information (done in code-behind to prevent excess calls from view)
            this.setSelectedEventDateString();
            this.setSelectedEventStatusString();
            this.setSelectedEventDurationString();
            this.setSelectedEventFormattedDescription();
            this.setSelectedEventRegistrationWindowString();

            

            $("#" + this.randID).modal('show');

            this.hasProperties = [false, false, false, false];
            for (let i = 0; i < this.selectedEvent.properties.length; i++) {
                // propertyType enum value corresponds to index of hasProperties, will be used to determine visibility
                this.hasProperties[this.selectedEvent.properties[i].propertyType] = true;
            }


            if (this.selectedEvent.sessionStatus == LiveEventBrowserCalendarStatus.Registered && this.selectedEvent.endDate < this.currentDateTime && this.selectedEvent.attended === true) {
                // Force survey if not complete
                this.pinnacleService.leGetUserProperties(this.selectedEvent.eventID, this.selectedEvent.sessionID, LiveEventPropertyType[3].toString()).subscribe(res => {
                    let surveyPrompts: Array<LiveEventProperty> = res;
                    if (surveyPrompts.length > 0) {
                        if (!this.hasProperties[3]) {
                            for (let i = 0; i < surveyPrompts.length; i++) {
                                this.selectedEvent.properties.push(surveyPrompts[i]);
                            }
                        }
                        this.hasProperties[LiveEventPropertyType.SurveyField] = true;
                        this.showSurveyFieldProperties = true;
                        this.showSubmitButton = true;
                        this.showCloseButton = true;
                        this.showLoading = false;
                    }
                    else {
                        // Force feedback if available
                        this.pinnacleService.leGetUserProperties(this.selectedEvent.eventID, this.selectedEvent.sessionID, LiveEventPropertyType[2].toString()).subscribe(res => {
                            let feedback: Array<LiveEventProperty> = res;
                            if (feedback.length > 0) {
                                if (!this.hasProperties[2]) {
                                    for (let i = 0; i < feedback.length; i++) {
                                        this.selectedEvent.properties.push(feedback[i]);
                                    }
                                }
                                this.hasProperties[LiveEventPropertyType.InstructorEntry] = true;
                                this.showInstructorEntryProperties = true;
                                this.showReturnButton = true;
                                this.showCloseButton = true;
                                this.showLoading = false;
                            }
                            else {
                                this.showEventInfo();
                            }
                        });
                    }
                });
            }
            else {
                this.showEventInfo();
            }
        }
    }

    showEventInfo(): void {
        this.showInfo = true;
        this.showDescription = true;
        this.showCloseButton = true;
        this.showInstructorEntryProperties = false;

        if (this.hasProperties[1]) {
            this.showEventSessionProperties = true;
        }

        if (this.selectedEvent.sessionStatus != LiveEventBrowserCalendarStatus.Registered
            && this.selectedEvent.sessionStatus != LiveEventBrowserCalendarStatus.Waitlisted
            && (this.selectedEvent.registrationStartDate <= this.currentDate || !this.selectedEvent.useRegistrationDates)
            && (this.selectedEvent.registrationEndDate >= this.currentDate || !this.selectedEvent.useRegistrationDates)
            && this.selectedEvent.endDate > this.currentDate) {
            this.showRegistrationButton = true;
        }
        else if ((this.selectedEvent.sessionStatus == LiveEventBrowserCalendarStatus.Registered
                    || this.selectedEvent.sessionStatus == LiveEventBrowserCalendarStatus.Waitlisted)
            && this.selectedEvent.sessionDateTime > this.currentDateTime) {
            this.showRemoveRegistrationButton = true;
        }
        this.showLoading = false;
    }

    setSelectedEventDateString(): void {
        this.selectedEventDateString = PinnacleDateTimePipe.transform(this.selectedEvent.startDate, [{ range: this.selectedEvent.endDate }]);
    }

    setSelectedEventRegistrationWindowString(): void {
        this.selectedEventRegistrationWindowString = PinnacleDateTimePipe.transform(this.selectedEvent.registrationStartDate, [{ range: this.selectedEvent.registrationEndDate }]);
    }

    setSelectedEventStatusString(): void {
        if (this.selectedEvent.sessionStatus == LiveEventBrowserCalendarStatus.Required) {
            this.selectedEventStatusString = "Assigned"
        }
        else {
            this.selectedEventStatusString = LiveEventBrowserCalendarStatus[this.selectedEvent.sessionStatus];
        }
        this.selectedEventStatusBadgeString = "liveEvent" + LiveEventBrowserCalendarStatus[this.selectedEvent.sessionStatus] + "BadgeColor";
    }

    setSelectedEventDurationString(): void {
        // selectedEvent.duration = 135 (mins) => selectedEventDurationString = "2 hours, 15 minutes"
        this.selectedEventDurationString = "";
        let remainder = this.selectedEvent.duration;

        // Reverse-nesting to reduce code length
        if (this.selectedEvent.duration >= 60) {
            if (this.selectedEvent.duration >= 1440) {
                if (this.selectedEvent.duration > 10080) {
                    // duration is at least a week, calculate how many and reduce the running duration value "remainder"
                    this.selectedEventDurationString += ~~(this.selectedEvent.duration / 10080) + (~~(this.selectedEvent.duration / 10080) == 1 ? " week, " : " weeks, ");
                    remainder = remainder % 10080;
                }
                // If duration has some day component, do the same as above
                if (remainder / 1440 > 0) {
                    this.selectedEventDurationString += ~~(remainder / 1440) + (~~(remainder / 1440) == 1 ? " day, " : " days, ");
                    remainder = remainder % 1440;
                }
            }
            // If duration has some hour component, do the same as above
            if (remainder / 60 > 0) {
                this.selectedEventDurationString += ~~(remainder / 60) + (~~(remainder / 60) == 1 ? " hour, " : " hours, ");
                remainder = remainder % 60;
            }
        }
        // If duration has some minute component, do the same as above
        if (remainder > 0) {
            this.selectedEventDurationString += remainder + (remainder == 1 ? " minute, " : " minutes, ");
        }

        // Remove trailing ", "
        this.selectedEventDurationString = this.selectedEventDurationString.slice(0, -2);
    }

    setSelectedEventFormattedDescription(): void {
        this.selectedEventFormattedDescription = "";

        if (this.selectedEvent.formattedDescription != "") {
            this.selectedEventFormattedDescription = this.sanitizer.bypassSecurityTrustHtml(this.selectedEvent.formattedDescription);
        }
        // Nothing should get past the first check. The CS returns some blank HTML by default.
        else if (this.selectedEvent.simpleDescription != "") {
            this.selectedEventFormattedDescription = this.sanitizer.bypassSecurityTrustHtml(this.selectedEvent.simpleDescription);
        }
        else {
            this.selectedEventFormattedDescription = this.sanitizer.bypassSecurityTrustHtml("");
        }
    }

    setRandomModalID(): void {
        let id = "";
        let chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
        for (let i = 0; i < 20; i++) {
            id += chars[Math.floor(Math.random() * chars.length)];
        }
        this.randID = id;
    }

    register(): void {
        //if (this.registrationURLOverride != "") {
        //    //console.log(this.registrationURLOverride);
        //    window.open(this.registrationURLOverride, "_blank");
        //    this.registrationURLOverride = "";
        //}

        this.pinnacleService.leGetUserProperties(this.selectedEvent.eventID, this.selectedEvent.sessionID, LiveEventPropertyType[0].toString()).subscribe(res => {
            let registrationPrompts: Array<LiveEventProperty> = res;
            if (registrationPrompts.length > 0) {
                for (let i = 0; i < registrationPrompts.length; i++) {
                    this.selectedEvent.properties.push(registrationPrompts[i]);
                }
                this.hasProperties[0] = true;
                this.showInfo = false;
                this.showDescription = false;
                this.showRegistrationInfoProperties = true;
                this.showEventSessionProperties = false;
                this.showInstructorEntryProperties = false;
                this.showRegistrationButton = false;
                this.showSubmitButton = true;
            }
            else {
                this.pinnacleService.leRegisterForEvent(this.selectedEvent.sessionID, []).subscribe(res => {
                    let response: LiveEventWrapper = res;
                    this.showInfo = false;
                    this.showEventSessionProperties = false;
                    this.handleRegistrationResponse(response);
                });
            }
        });
    }

    submit(): void {
        if (this.showRegistrationInfoProperties) {
            let v_responses = new Array<LiveEventProperty>();
            for (let i = 0; i < this.selectedEvent.properties.length; i++) {
                if (this.selectedEvent.properties[i].propertyType == LiveEventPropertyType.RegistrationField) {
                    v_responses.push(this.selectedEvent.properties[i]);
                }
            }
            this.pinnacleService.leRegisterForEvent(this.selectedEvent.sessionID, v_responses).subscribe(res => {
                let response: LiveEventWrapper = res;
                this.handleRegistrationResponse(response);
            });
        }
        else if (this.showSurveyFieldProperties) {
            let v_responses = new Array<LiveEventProperty>();
            for (let i = 0; i < this.selectedEvent.properties.length; i++) {
                if (this.selectedEvent.properties[i].propertyType == LiveEventPropertyType.SurveyField) {
                    v_responses.push(this.selectedEvent.properties[i]);
                }
            }
            this.pinnacleService.leSumbitSurvey(this.selectedEvent.sessionID, v_responses).subscribe(res => {
                let response: boolean = res;
                this.showSurveyFieldProperties = false;
                this.showSubmitButton = false;
                if (response) {
                    this.showSurveyConfirmation = true;
                    this.showReturnButton = true;
                }
                else {
                    this.showSurveyError = true;
                }
            });
        }
    }

    return(): void {
        this.showSurveyConfirmation = false;
        this.showReturnButton = false;
        this.showEventInfo();
    }

    removeRegistration(): void {
        this.showRegistrationRemovalPrompt = true;
        this.showInfo = false;
        this.showDescription = false;
        this.showEventSessionProperties = false;
        this.showInstructorEntryProperties = false;
        this.showRemoveRegistrationButton = false;
        this.showCloseButton = false;
        this.showYesNoButtons = true;
    }

    confirmRegistrationRemoval(value: boolean): void {
        if (value) {
            let v_curUser = new User();
            this.pinnacleService.getCurrentUser().subscribe(res => v_curUser = res);
            this.pinnacleService.leRemoveRegistration(this.selectedEvent.sessionID, v_curUser.userId).subscribe(res => {
                this.pinnacleService.leGetLiveEventSessionWrapper(this.selectedEvent.sessionID, [LiveEventPropertyType.EventSessionProperty]).subscribe(update => {
                    this.selectedEvent.sessionStatus = update.sessionStatus;
                    this.selectedEventChange.emit(this.selectedEvent);
                    this.showRegistrationRemovalPrompt = false;
                    this.showYesNoButtons = false;
                    this.showRegistrationRemovalConfirmation = true;
                    this.showCloseButton = true;
                });
            });
        }
        else {
            this.showRegistrationRemovalPrompt = false;
            this.showYesNoButtons = false;

            this.showEventInfo();
        }
    }

    handleRegistrationResponse(response: LiveEventWrapper): void {
        switch (response.presenterDisplayName) {
            case "ALREADY_REGISTERED": 
                this.registrationErrorMsg = this.translationService.getTranslationFileData("LIVE_EVENT_REGISTRATION_FORM.AlreadyRegistered");
                break;
            case "SESSION_RESTRICTED":
                this.registrationErrorMsg = this.translationService.getTranslationFileData("LIVE_EVENT_REGISTRATION_FORM.NoAccess");
                break;
            case "SESSION_FULL_NO_WAITLIST":
                this.registrationErrorMsg = this.translationService.getTranslationFileData("LIVE_EVENT_REGISTRATION_FORM.SessionFullNoWL");
                break;
            case "SESSION_FULL_BUT_WAITLIST":
                this.registrationErrorMsg = this.translationService.getTranslationFileData("LIVE_EVENT_REGISTRATION_FORM.SessionFullWL");
                break;
            case "ALREADY_ON_WAITLIST":
                this.registrationErrorMsg = this.translationService.getTranslationFileData("LIVE_EVENT_REGISTRATION_FORM.OnWL");
                break;
            case "PREREQUISITES_NOT_MET":
            case "SESSION_FULL_NO_WAITLIST&PREREQUISITES_NOT_MET":
            case "SESSION_FULL_BUT_WAITLIST&PREREQUISITES_NOT_MET":
                this.registrationErrorMsg = this.translationService.getTranslationFileData("LIVE_EVENT_REGISTRATION_FORM.NoPrereqs");
                this.pinnacleService.leGetIncompletePrerequisites(this.selectedEvent.eventID).subscribe(res => {
                    this.incompletePrerequisites = res;
                    //console.log(this.incompletePrerequisites);
                });
                break;
            
            default:
                this.registrationErrorMsg = "";
                if (this.registrationURLOverride != "") {
                    //console.log(this.registrationURLOverride);
                    window.open(this.registrationURLOverride, "_blank");
                    this.registrationURLOverride = "";
                }
                break;
        }

        this.showSubmitButton = false;
        this.showRegistrationButton = false;
        this.showRegistrationInfoProperties = false;

        if (this.registrationErrorMsg == "") {
            this.showRegistrationConfirmation = true;
            this.selectedEvent.sessionStatus = response.sessionStatus;
            this.selectedEventChange.emit(this.selectedEvent);
            this.showDescription = false;
        }
        else {
            this.showRegistrationError = true;
            if (response.presenterDisplayName == "SESSION_FULL_BUT_WAITLIST") {
                this.selectedEvent.sessionStatus = LiveEventBrowserCalendarStatus.Waitlisted;
                this.selectedEventChange.emit(this.selectedEvent);
            }
        }
    }

    pushToCalendar(): void {
        this.pinnacleService.leShareEventICS(this.selectedEvent.sessionID.toString(), this.currentUser.userId).subscribe(res => {
            if (res as boolean) {
                this.showCalendarMessage = true;
            }
        });
    }































 



    


 

    showCalendarMsg(): void {
        //$("#pushToCalendarMsg").addClass("pushToCalendarMsg");
        //$("#pushToCalendarMsg").removeClass("pushToCalendarMsgHidden");
    }

    removeCalendarMsg(): void {
        //$("#pushToCalendarMsg").addClass("pushToCalendarMsgHidden");
        //$("#pushToCalendarMsg").removeClass("pushToCalendarMsg");
    }

    //showForm(liveEvent: LiveEventWrapper): void {
        //this.registrationErrorMsg = "";
        //this.selectedEvent = liveEvent;


        //for (let i = 0; i < this.selectedEvent.properties.length; i++) {

        //    // Only show "More Info" section if there are visible eventsession properties
        //    if (this.selectedEvent.properties[i].propertyType == LiveEventPropertyType.EventSessionProperty
        //        && this.selectedEvent.properties[i].isVisibleInBrowser) {
        //        this.showInfoProperties = true;
        //        continue;
        //    }
        //}

        //this.cancelButtonText = "Close";
        
        //this.showRegistrationProperties = false;
        //this.promptRegistrationRemoval = false;
        //this.userRegisteredForSelectedEvent = this.selectedEvent.sessionStatus == LiveEventBrowserCalendarStatus.Registered;

        //this.setSelectedEventStatusString();
        //this.setSelectedEventDateString();
        //this.setSelectedEventDurationString();
        //this.setSelectedEventFormattedDescription();
        //this.descriptionBackgroundColor = this.selectedEvent.formattedDescription == "" ? "transparent" : "white";
        //this.descriptionTitleColor = this.selectedEvent.formattedDescription == "" ? "" : "#555";
        //$("#registrationForm").modal('show');
    //}

    

    

    

    

    getLiveEventStatusString(liveEvent: LiveEventWrapper): string {
        return LiveEventBrowserCalendarStatus[liveEvent.sessionStatus];
    }

    getLiveEventStatusColorString(liveEvent: LiveEventWrapper): string {
        return "liveEvent" + LiveEventBrowserCalendarStatus[liveEvent.sessionStatus] + "Color";
    }
}





//if (this.selectedEvent.duration >= 10080) {
//    this.selectedEventDurationString += ~~(this.selectedEvent.duration / 10080) + (~~(this.selectedEvent.duration / 10080) == 1 ? " week, " : " weeks, ");
//    let remainder = this.selectedEvent.duration % 10080;
//    if (remainder > 0) {
//        this.selectedEventDurationString += ~~(remainder / 1440) + (~~(remainder / 1440) == 1 ? " day, " : " days, ");
//        remainder = remainder % 1440;
//        if (remainder > 0) {
//            this.selectedEventDurationString += ~~(remainder / 60) + (~~(remainder / 60) == 1 ? " hour, " : " hours, ");
//            remainder = remainder % 60;
//            if (remainder > 0) {
//                this.selectedEventDurationString += remainder + (remainder == 1 ? " minute " : " minutes, ");

//            }
//        }
//    }
//}
//else if (this.selectedEvent.duration >= 1440) {
//    this.selectedEventDurationString += ~~(this.selectedEvent.duration / 1440) + (~~(this.selectedEvent.duration / 1440) == 1 ? " day, " : " days, ");
//    let remainder = this.selectedEvent.duration % 1440;
//    if (remainder > 0) {
//        this.selectedEventDurationString += ~~(remainder / 60) + (~~(remainder / 60) == 1 ? " hour, " : " hours, ");
//        remainder = remainder % 60;
//        if (remainder > 0) {
//            this.selectedEventDurationString += remainder + (remainder == 1 ? " minute " : " minutes, ");
//        }
//    }
//}
//else if (this.selectedEvent.duration >= 60) {
//    this.selectedEventDurationString += ~~(this.selectedEvent.duration / 60) + (~~(this.selectedEvent.duration / 60) == 1 ? " hour, " : " hours, ");
//    let remainder = this.selectedEvent.duration % 60;
//    if (remainder > 0) {
//        this.selectedEventDurationString += remainder + (remainder == 1 ? " minute " : " minutes, ");
//    }
//}
//else if (this.selectedEvent.duration >= 60) {
//    this.selectedEventDurationString += this.selectedEvent.duration + (this.selectedEvent.duration == 1 ? " minute " : " minutes, ");
//}