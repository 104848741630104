import { Injectable, SecurityContext } from '@angular/core';
import { Router } from '@angular/router';
import { Component, OnInit, Input, OnChanges,SimpleChanges, SimpleChange } from '@angular/core';
import { Content, ContentType_T } from './../../apiService/classFiles/class.content';
import { Workgroup, WorkgroupMember, PermissionLevel_T } from './../../apiService/classFiles/class.workgroups';
import { ProdGenApi } from './../../apiService/prodgen.api';
import { DomSanitizer  } from '@angular/platform-browser';


@Component({
  selector: 'template-workgroup-card',
  templateUrl: './workgroup-card.component.html',
  styleUrls: ['./workgroup-card.component.css'],
  providers: [ProdGenApi]
})
export class WorkgroupCardComponent implements OnInit {

  @Input('workgroup') item: Workgroup;
  @Input('themeRotation') themeRotation: number;
  @Input('isOwner') isOwner: boolean;

  ownerString: string = "Owner(s):";
  windowWidth: number = 0;

  constructor(private pinnacleService: ProdGenApi, private router: Router, private sanitizer:DomSanitizer) {
      if (this.item != null) {
          
          /*
          this.pinnacleService.GetWorkgroupMembers(this.item.workgroupId).subscribe(o => {
              this.owners = o.filter(f => f.permissionLevel == PermissionLevel_T.owner.toString());
          });
          */
          
      }
  }

    ngOnInit() {
 
  }

  ngOnChanges(changes: SimpleChanges) {


      if (this.item != null) {
          this.item.imageURL = this.sanitizer.sanitize(SecurityContext.URL,this.item.imageURL);
          try { this.item = changes['workgroup'].currentValue } catch (err) { };

          let tenantid = ProdGenApi.getCurrentTenantId();
          this.ownerString = this.loadOwners();

     //     this.pinnacleService.GetWorkgroupMembers(this.item.workgroupId).subscribe(o => {

     //         this.members = o;
			  //this.owners = o.filter(f => f.permissionLevel == "owner");

     //         if (this.item.tenantid != tenantid) {
     //             this.ownerString = this.item.tenantName;
     //         }

     //         else {
     //             this.ownerString = this.loadOwners();
     //         }
              
     //     });
      }
  }

  loadOwners() : string {

      let tempString = "Owner(s): ";
      tempString += this.item.ownerDisplay;

	   return tempString;
  }

  getClassList(): string {
	  let classes: string = "col-xs-12 col-sm-4 col-md-4 col-lg-4 home-card card-display ";
      let themeNum: number = (this.themeRotation % 3);

      //if (this.themeRotation < 3)
      //{
      //    themeNum = ((this.themeRotation + 1) % 3);
      //}
      //else
      //{
      //}
      //themeNum += 1;

      if (themeNum == 0) {
          themeNum = 1;
      }
      else if (themeNum == 1) {
          themeNum = 2;
      }
      else if (themeNum == 2) {
          themeNum = 3;
      }

      classes += "largecardcolortheme" + themeNum;
      return classes;
  }

  onClickItem() {
      let url: string = "workcenter?id=" + this.item.workgroupId;  


	  this.router.navigateByUrl(url).then(i => {
		  if (i == false) {
			  console.log("unable to navigate");
		  }
	  });
  }

  getItemName() {
	  return this.item.name;
  }

    /*
  onResize(event) {

	  let width = event.target.innerWidth;

	  let tempString = this.loadOwners();
	  if (width > 992) {
		  let diff = tempString.length - 260;
		  if (diff > 0) {
			  diff += 1;
			  //&#8230;
			  tempString = tempString.slice(0, tempString.length - diff);
			  tempString += "&#8230;";
		  }
	  }
	  else {
		  
	  }

	  this.ownerString = tempString;

  }
    */
}
