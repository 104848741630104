import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProdGenApi } from './../apiService/prodgen.api';
import { Setting} from './../apiService/classFiles/class.organizations';
import { TranslationService } from './../services/TranslationService';


@Component({
    selector: 'app-navigation-menu',
    templateUrl: './navigation-menu.component.html',
    styleUrls: ['./navigation-menu.component.css'],
    providers: [ProdGenApi],
})
export class NavigationMenuComponent implements OnInit {
    @Output() navigateMenuEvent = new EventEmitter<NavigationMenuItem>();

    navItems = new Array<NavigationMenuItem>();
    logoLarge: string = "";
    logoSmall: string = "";
    logoUrl: string = "";
    logoUrlTarget: string = "_blank";
    displayLogo: boolean = false;

    constructor(private service: ProdGenApi,
        private route: ActivatedRoute,
        private translationService: TranslationService) {

    }

    ngOnInit() {
        window.scroll(0, 0);

        this.loadLogo();
        this.loadMenu();
    }

    loadLogo() {
        this.service.GetCurrentTenantSettings().subscribe(s => {

            let logoSetting = s.find(v => v.name == "COMPANY_LOGO");
            let showLogoSetting = s.find(v => v.name == "HIDE_LOGO");

            if (showLogoSetting) {
                if (showLogoSetting.settingValue.toString().toLowerCase() == "true") {
                    this.displayLogo = true;
                }
                else {
                    this.displayLogo = false;
                }
            }
            else {
                this.displayLogo = true;
            }


            if (logoSetting != null && logoSetting.settingValue.toString().trim() != "") {
                this.logoLarge = logoSetting.settingValue as string;
            }
            else {
                this.logoLarge = "./assets/images/nav-menu/pinnacle_logo.svg";
            }

            let logoNavigateURLSetting: Setting = s.find(l => l.name == "COMPANY_LOGO_NAVIGATE_URL");
            if (logoNavigateURLSetting != null && logoNavigateURLSetting.settingValue.toString().trim() != "") {
                this.logoUrl = logoNavigateURLSetting.settingValue.toString();
                if (this.logoUrl.includes(window.location.hostname) == true) {
                    this.logoUrlTarget = "_self";
                }
                else {
                    this.logoUrlTarget = "_blank";
                }
            }
            else {
                this.logoUrl = "";
            }



        })

    }

    loadMenu() {
        let home = new NavigationMenuItem();
        home.title = "Home";
        home.image = "assets/images/nav-menu/home.svg";
        home.navType = NavigationMenuType.route;
        home.navValue = "/home";

        let libraries = new NavigationMenuItem();
        libraries.title = "Libraries";
        libraries.image = "assets/images/nav-menu/libraries.svg";
        libraries.navType = NavigationMenuType.route;
        libraries.navValue = "/product-listing";

        let liveEvents = new NavigationMenuItem();
        liveEvents.title = "Live Events";
        liveEvents.image = "assets/images/nav-menu/live_events.svg";
        liveEvents.navType = NavigationMenuType.route;
        liveEvents.navValue = "/learningcenter";

        let workGroups = new NavigationMenuItem();
        workGroups.title = "Work Groups";
        workGroups.image = "assets/images/nav-menu/live_events.svg";
        workGroups.navType = NavigationMenuType.route;
        workGroups.navValue = "/workcenter";

        let bookmarks = new NavigationMenuItem();
        bookmarks.title = "Bookmarks";
        bookmarks.image = "assets/images/nav-menu/live_events.svg";
        bookmarks.navType = NavigationMenuType.route;
        bookmarks.navValue = "/myassets";

        let myDocuments = new NavigationMenuItem();
        myDocuments.title = "My Documents";
        myDocuments.image = "assets/images/nav-menu/live_events.svg";
        myDocuments.navType = NavigationMenuType.route;
        myDocuments.navValue = "/myassets";

        this.navItems.push(home);
        this.navItems.push(libraries);
        this.navItems.push(liveEvents);
        this.navItems.push(workGroups);
        this.navItems.push(bookmarks);
        this.navItems.push(myDocuments);
    }

    onNavEvent(item:NavigationMenuItem) {
        console.log(item);
    }

}

export enum NavigationMenuType {
    none,
    route,
    url,
    external_url
}

export class NavigationMenuItem {
    public title: string = ""; 
    public navType: NavigationMenuType;
    public navValue: string = "";
    public image: string = "";
    public children: NavigationMenuItem[];
}
