import { ChangeDetectorRef, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { IDatePickerConfig } from 'ng2-date-picker';
import { AssetLibrary } from '../apiService/classFiles/class.assetlibrary';
import { CompanyNewsItem } from '../apiService/classFiles/class.organizations';
import { User } from '../apiService/classFiles/class.users';
import { PermissionLevel_T, Workgroup, WorkgroupExternalSummaryRecord } from '../apiService/classFiles/class.workgroups';
import { AppComponent, defpreviewtheme } from '../app.component';
import { UserDataGridComponent } from '../templates/userdatagrid/userdatagrid.component';
import { UserDataGridInfo } from '../templates/userdatagrid/UserDataGridInfo';
import { WidgetContainerComponent } from '../templates/widget-container/widget-container.component';
import { WorkgroupAssetViewComponent } from '../templates/workgroup-asset-view/workgroup-asset-view.component';
import { WorkgroupMemberViewComponent } from '../templates/workgroup-member-view/workgroup-member-view.component';
import { PartnerNewsItem } from './../apiService/classFiles/class.partners';
import { ProdGenApi } from './../apiService/prodgen.api';
import { BrowserAuthenticationService } from './../BrowserAuthenticationService';
import { TranslationService } from './../services/TranslationService';

declare var $: any;

//declare function doesFontExist(fontName: string): boolean;

@Component({
    selector: 'app-workcenter3',
    templateUrl: './workcenter3.component.html',
    styleUrls: ['./workcenter3.component.css'],
    //providers: [LearningcenterheaderComponent, ProdGenApi],
    providers: [ProdGenApi],
    encapsulation: ViewEncapsulation.None,
})

export class Workcenter3Component implements OnInit {
    @ViewChild(WidgetContainerComponent, { static: false }) WGContainer: WidgetContainerComponent;
    myWorkGroups: Array<Workgroup> = new Array<Workgroup>();
    externalWorkGroups: Array<Workgroup> = new Array<Workgroup>();
    curWorkgroupId: string = "";

    selectedWorkgroup: Workgroup = new Workgroup();
    showWorkgroupMobileOnLoad: boolean = false;

    //workgroupMembers: Array<WorkgroupMember> = new Array<WorkgroupMember>();

    assetLibrariesData: Array<AssetLibrary> = new Array<AssetLibrary>();
    assetLibraryLoaded: boolean = false;

    assetLibrariesSubscription: Array<AssetLibrary> = new Array<AssetLibrary>();
    assetLibrarySubscriptionLoaded: boolean = false;

    //categoriesTenant: Array<Category> = new Array<Category>();
    currentUser: User = new User();

    companyInfo: Array<CompanyNewsItem> = new Array<CompanyNewsItem>();
    recommendedAddons: Array<CompanyNewsItem> = new Array<CompanyNewsItem>();
    companyInfoImage: string = "";
    companyInfoLoaded: boolean = false;

    partnerInfo: Array<PartnerNewsItem> = new Array<PartnerNewsItem>();
    recommendedPartnerAddons: Array<PartnerNewsItem> = new Array<PartnerNewsItem>();
    partnerInfoImage: string = "";
    partnerInfoLoaded: boolean = false;

    workgroupsLoaded: boolean = false;
    isAuthenticated: boolean = false;

    currentUserPermissionsLevel: String = "user";
    ownerWorkGroups: Array<String> = new Array<String>();
    editorWorkGroups: Array<String> = new Array<String>();


    canViewDownloadAddOns: boolean = true;

    imageId: string = "";
    showImageLoader: boolean = false;
    newModifyWorkgroupTitle: string = "";
    newWorkgroupName: string = "";
    newWorkgroupURL: string = "";
    newWorkgroupDesc: string = "";
    newWorkgroupIsPublic: boolean = false;

    newwgEditorDiscussion: boolean = true;
    newwgMemberDiscussion: boolean = true;
    newwgExternalMemDiscussion: boolean = true;

    newwgEditorMembers: boolean = true;
    newwgMemberMembers: boolean = true;
    newwgExternalMemMembers: boolean = true;

    newWorkgroupEditorDashboard: boolean = false;
    newWorkgroupMemberDashboard: boolean = false;
    newWorkgroupExternalMemDashboard: boolean = false;

    notifyContentAdded: boolean = false;
    notifyContentRemoved: boolean = false;
    notifyContentModified: boolean = false;
    notifyUserAdded: boolean = false;
    notifyUserRemoved: boolean = false;
    notifyUserExpiring: boolean = false;
    notifyCommentPosted: boolean = false;
    notifyArchivedRestored: boolean = false;

    isModify: boolean = false;
    thumbnailURL: string = "../../assets/images/default_project.jpg";
    
    newImageFile: File;

    previewtheme = defpreviewtheme;

    selectedWorkgroupIDForCopy: string = "";

    summaryInvitesTotal: number = 0;
    summaryInvitesAssigned: number = 0;
    summaryInvitesAvailable: number = 0;
    summaryRecords: Array<WorkgroupExternalSummaryRecord> = new Array<WorkgroupExternalSummaryRecord>();

    isAdmin: boolean = false;
    hasWGAdminPerm: boolean = false;
    hasWGCreatePerm: boolean = false;
    canCreateExternalUsers: boolean = false;

    showAdminWorkgroup: boolean = false;
    allWorkgroups: Array<Workgroup> = new Array<Workgroup>();
    lookupSelectedWG: Workgroup;
    adminSelectedWG: Workgroup;
    findWGFilterText: string = ""; 
    filteredFindWorkgroups: Array<Workgroup> = new Array<Workgroup>();
    wgFindSelectedValue: string = "All";

    publicWorkgroupList: Array<Workgroup> = new Array<Workgroup>();
    publicWorkgroupListFilterCopy: Array<Workgroup> = new Array<Workgroup>();
    selectedWorkgroupPanel: Workgroup = new Workgroup();
    filterText: string = "";

    isPinnacleLite: boolean = false;

    dashboardVisited: boolean = false;
    workgroupCopyEditDeleteFlag: boolean = false;

    assignLearningNoLearningErr: boolean = false;
    assignLearningNoUsersErr: boolean = false;

    
    constructor(private pinnacleService: ProdGenApi, private route: ActivatedRoute, private router: Router, private _sanitizer: DomSanitizer, private authenticationService: BrowserAuthenticationService, private translationService: TranslationService, private changedetector: ChangeDetectorRef) {
        this.isPinnacleLite = ProdGenApi.isPinnacleLite;  
    }

	ngOnInit() {
		
        window.scroll(0, 0);
        if (window.location.href.includes("id=")) {
            this.showWorkgroupMobileOnLoad = true;
        }

        if (this.authenticationService.AuthenticatePage() == true) {

            this.isAuthenticated = true;

            if (ProdGenApi.GetUserIsExternal() == true) {
                
                this.route.queryParams.subscribe(queryParams => {
                    if (queryParams['id'] != null) {
                        var selectId = queryParams['id'];
                        this.router.navigate(['/external-home'], { queryParams: { id: selectId } })
                    }
                    else {
                        this.router.navigate(['/external-home']);
                    }
                });

                return;
            }

            this.pinnacleService.GetCurrentTenant().subscribe(
                res => {
                    ProdGenApi.setCurrentTenantId(res.tenantId as string);

                });

            this.pinnacleService.getCurrentUser().subscribe(u => {
                this.currentUser = u;
                
                this.pinnacleService.getCurrentUserPermissions().subscribe(res => {
                    this.isAdmin = res.administrator;
                    this.hasWGAdminPerm = res.workgroupAdmin;
                    this.hasWGCreatePerm = res.workgroupCreate;
                    this.canCreateExternalUsers = res.canCreateExternalUsers;
                    

                    this.pinnacleService.getCurrentUserWorkgroups([PermissionLevel_T.owner, PermissionLevel_T.editor, PermissionLevel_T.user], -1).subscribe(res => {

                        this.myWorkGroups = new Array<Workgroup>();
                        this.externalWorkGroups = new Array<Workgroup>();

                        let selectId = "";
                        this.route.queryParams.subscribe(queryParams => {
                            if (queryParams['id'] != null) {
                                selectId = queryParams['id'];
                            }
                        });

                        for (var i = 0; i < res.length; i++) {
                            if (res[i].imageURL == null || res[i].imageURL == "") {
                                //get default workgroup image from storage
                                let defaultwgimage = "";

                                try {
                                    defaultwgimage = localStorage.getItem(ProdGenApi.getCurrentTenantId() + "_defWorkgroupImage");
                                }
                                catch (err) {
                                }

                                if (defaultwgimage != null && defaultwgimage != "") {
                                    res[i].imageURL = defaultwgimage;
                                }
                                else {
                                    res[i].imageURL = "../assets/images/default_project.jpg";
                                }
                            }

                            if (res[i].isExternal == false) {
                                this.myWorkGroups.push(res[i]);
                            }
                            else {
                                this.externalWorkGroups.push(res[i]);
                            }

                            if (res[i].workgroupId == selectId) {
                                this.selectedWorkgroup = res[i];
                            }

                        }

                        this.workgroupsLoaded = true;


                        if (this.selectedWorkgroup == null || this.selectedWorkgroup.workgroupId == null || this.selectedWorkgroup.workgroupId.length == 0) {
                            if (this.myWorkGroups.length > 0) {
                                this.selectedWorkgroup = this.myWorkGroups[0];
                            }
                            else if (this.externalWorkGroups.length > 0) {
                                this.selectedWorkgroup = this.externalWorkGroups[0];
                            }
                        }

                        this.pinnacleService.getCurrentUserWorkgroupIdsWithOwnerEditorRights().subscribe(res2 => {
                            
                            this.ownerWorkGroups = res2.ownerPermissionWorkgroups;
                            this.editorWorkGroups = res2.editorPermissionWorkgroups;

                            this.currentUserPermissionsLevel = "user";
                            if (this.ownerWorkGroups.findIndex(i => i == this.selectedWorkgroup.workgroupId) != -1 || this.hasWGAdminPerm) {
                                this.currentUserPermissionsLevel = "owner";
                            }
                            else if (this.editorWorkGroups.findIndex(i => i == this.selectedWorkgroup.workgroupId) != -1) {
                                this.currentUserPermissionsLevel = "editor";
                            }

                        });
                        
                    });
                });
                
            });

            this.pinnacleService.getPublicWorkgroups().subscribe(res => {

                res.forEach(w => {
                    if (w.imageURL.trim() == "") {

                        let defaultwgimage = "";

                        try {
                            defaultwgimage = localStorage.getItem(ProdGenApi.getCurrentTenantId() + "_defWorkgroupImage");
                        }
                        catch (err) {
                        }

                        if (defaultwgimage != null && defaultwgimage != "") {
                            w.imageURL = defaultwgimage;
                        }
                        else {
                            w.imageURL = "../assets/images/default_project.jpg";
                        }
                    }
                })

                this.publicWorkgroupList = res;
                this.publicWorkgroupListFilterCopy = res;

                //document.getElementById("widget_" + this.widgetID + "_" + this.widgetContainer.seq).style.overflowY = "auto";
            });
        }

    }




    onWorkgroupChanged(event) {
        this.router.navigateByUrl("workcenter?id=" + event.selectedItem);

        /*
        this.selectedWorkgroup = this.workGroups.find(w => w.workgroupId == event.selectedItem);
        this.loadMembers();
        */

        this.showAdminWorkgroup = false;

        let tempSelection = this.myWorkGroups.find(w => w.workgroupId == event.selectedItem);
        if (tempSelection == null) {
            tempSelection = this.externalWorkGroups.find(w => w.workgroupId == event.selectedItem);
        }

        if (tempSelection != null) {

            this.selectedWorkgroup = tempSelection;

            this.currentUserPermissionsLevel = "user";

            if (this.ownerWorkGroups.findIndex(i => i == this.selectedWorkgroup.workgroupId) != -1 || this.hasWGAdminPerm) {
                this.currentUserPermissionsLevel = "owner";
            }
            else if (this.editorWorkGroups.findIndex(i => i == this.selectedWorkgroup.workgroupId) != -1) {
                this.currentUserPermissionsLevel = "editor";
            }

        }

    }

    selectedWorkGroupClicked() {
        if (!this.workgroupCopyEditDeleteFlag) {        
            $('.toggleViewWorkGroupArea').collapse('show');
            $('.toggleSelectWorkGroupArea').collapse('hide');
        }
        else {
            this.workgroupCopyEditDeleteFlag = false;
        }

        if (window.innerWidth < 992) {
            this.dashboardTabResetClick();
            $('#assets_tab').addClass('active');
            $('.nav-tabs a[href="#assets_tab"]').tab('show')
            $('#discussion_tab').removeClass('active');
            $('#members_tab').removeClass('active');
            $('#dashboard_tab').removeClass('active');
            window.scroll(0, 0);
        }

    }

    onImageChanged(event: any) {

        if (this.imageId == "") {
            this.newImageFile = event.fileData;
            this.thumbnailURL = event.imageURL;
        }
        else {
            this.selectedWorkgroup.imageURL = event.imageURL;
            if (this.selectedWorkgroup.imageURL == "") {
                this.selectedWorkgroup.imageURL = "../../assets/images/default_project.jpg";
            }
            this.thumbnailURL = this.selectedWorkgroup.imageURL.toString();
        }
    }

    getThumbnailImage() {
        return this._sanitizer.bypassSecurityTrustUrl(this.thumbnailURL);
    }

    findWorkgroup() {
        
        this.pinnacleService.GetWorkgroups([0, 1, 2], 0, 0, "", true).subscribe(res => {
            this.allWorkgroups = res;
            this.lookupSelectedWG = this.allWorkgroups[0];
            this.showAdminWorkgroup = true;
            this.findWgFilterTextChanged();
            $('#selectAnyWorkgroup').modal('show');
     
        });
        
    }

    findWgFilterTextChanged() {
        let v_LowerFilter = this.findWGFilterText.toLowerCase().trim();
        
        this.filteredFindWorkgroups = this.getCloneAllWorkgroups();

        if (v_LowerFilter.length > 0) {
            
            for (var i = 0; i < this.filteredFindWorkgroups.length; i++) {
                if ((this.filteredFindWorkgroups[i].name.toLowerCase().indexOf(v_LowerFilter.toLowerCase()) == -1) &&
                    this.filteredFindWorkgroups[i].description.toLowerCase().indexOf(v_LowerFilter.toLowerCase()) == -1) {

                    this.filteredFindWorkgroups.splice(i, 1);
                    i--;
                }
            }            
        }
    }

    getCloneAllWorkgroups(): Array<Workgroup> {
        let wg: Array<Workgroup> = new Array<Workgroup>();

        if (this.wgFindSelectedValue == "All") {
            for (let c of this.allWorkgroups) {
                wg.push(c);
            }
        }
        else if (this.wgFindSelectedValue == "Active") {
            for (let c of this.allWorkgroups) {
                if (!c.isArchived) {
                    wg.push(c);
                }
            }

        }
        else if (this.wgFindSelectedValue == "Archived") {
            for (let c of this.allWorkgroups) {

                if (c.isArchived) {
                    wg.push(c);
                }
            }
        }
        return wg;
    }


    adminSelectWGClick(wg: Workgroup) {
        this.lookupSelectedWG = wg;
        
    }
    adminSelectionWorkgroup() {
        this.adminSelectedWG = this.lookupSelectedWG;
        this.selectedWorkgroup = this.adminSelectedWG;
    }

    setSelectedWGtoAdminWG() {
        this.selectedWorkgroup = this.adminSelectedWG;
    }


    onNewWorkgroupClick() {
        this.imageId = "clear";

        this.newModifyWorkgroupTitle = this.translationService.getTranslationFileData("WORKCENTER.TS_CreateWorkgroup");
        this.newWorkgroupName = "";
        this.newWorkgroupDesc = "";
        this.imageId = "";
        this.newImageFile = null;
        this.newWorkgroupIsPublic = false;
        this.isModify = false;

        this.newwgEditorDiscussion = true;
        this.newwgMemberDiscussion = true;
        this.newwgExternalMemDiscussion = true;

        this.newwgEditorMembers = true;
        this.newwgMemberMembers = true;
        this.newwgExternalMemMembers = true;

        this.newWorkgroupEditorDashboard = false;
        this.newWorkgroupMemberDashboard = false;
        this.newWorkgroupExternalMemDashboard = false;

        this.notifyContentAdded = false;
        this.notifyContentRemoved = false;
        this.notifyContentModified = false;
        this.notifyUserAdded = false;
        this.notifyUserRemoved = false;
        this.notifyCommentPosted = false;
        this.notifyArchivedRestored = false;
        this.notifyUserExpiring = false;

        this.thumbnailURL = localStorage.getItem(ProdGenApi.getCurrentTenantId() + "_defWorkgroupImage");

        $('#newWorkgroupModal').modal('show');
    }

    onModifyWorkgroupClick() {
        this.workgroupCopyEditDeleteFlag = true;
        this.imageId = "clear";

        this.newModifyWorkgroupTitle = this.translationService.getTranslationFileData("WORKCENTER.TS_ModifyWorkgroup");
        this.newWorkgroupName = this.selectedWorkgroup.name.toString();
        this.newWorkgroupDesc = this.selectedWorkgroup.description.toString();
        this.newWorkgroupIsPublic = this.selectedWorkgroup.isPublic;
        this.imageId = this.selectedWorkgroup.workgroupId.toString();
        this.isModify = true;


        this.newwgEditorDiscussion = this.selectedWorkgroup.discussionEditorsAllowed;
        this.newwgMemberDiscussion = this.selectedWorkgroup.discussionMembersAllowed;
        this.newwgExternalMemDiscussion = this.selectedWorkgroup.discussionExternMembersAllowed;

        this.newwgEditorMembers = this.selectedWorkgroup.membersTabEditorsAllowed;
        this.newwgMemberMembers = this.selectedWorkgroup.membersTabMembersAllowed;
        this.newwgExternalMemMembers = this.selectedWorkgroup.membersTabExternMembersAllowed;

        this.newWorkgroupEditorDashboard = this.selectedWorkgroup.editorsDashboardAllowed;
        this.newWorkgroupMemberDashboard = this.selectedWorkgroup.membersDashboardAllowed;
        this.newWorkgroupExternalMemDashboard = this.selectedWorkgroup.externalMembersDashboardAllowed;

        this.notifyContentAdded = this.selectedWorkgroup.notifyContentAdded;
        this.notifyContentRemoved = this.selectedWorkgroup.notifyContentRemoved;
        this.notifyContentModified = this.selectedWorkgroup.notifyContentModified;
        this.notifyUserAdded = this.selectedWorkgroup.notifyUserAdded;
        this.notifyCommentPosted = this.selectedWorkgroup.notifyCommentPosted;
        this.notifyArchivedRestored = this.selectedWorkgroup.notifyArchivedRestored;
        this.notifyUserRemoved = this.selectedWorkgroup.notifyUserRemoved;
        this.notifyUserExpiring = this.selectedWorkgroup.notifyUserExpiring;

        this.thumbnailURL = this.selectedWorkgroup.imageURL.toString();

        $('#newWorkgroupModal').modal('show');
    }


    onCopyWorkgroupClick() {
        this.workgroupCopyEditDeleteFlag = true;
        $('#copyWorkgroupModal').modal('show');
    }

    onCopyWorkgroup() {

        if (this.selectedWorkgroup.isExternal == false) {

            this.pinnacleService.copyWorkgroup(this.selectedWorkgroup.workgroupId as string, this.selectedWorkgroup.name + '_Copy').subscribe(res => {
                if (res.name != "") {
                    if (res.imageURL == "") {
                        res.imageURL = localStorage.getItem(ProdGenApi.getCurrentTenantId() + "_defWorkgroupImage");
                    }

                    this.myWorkGroups.push(res);
                    this.ownerWorkGroups.push(res.workgroupId);
                    this.selectedWorkgroup = res;
                    this.currentUserPermissionsLevel = "owner";
                    this.showAdminWorkgroup = false;
                }
            });
            
        }

    }


    onWorkgroupImageClick() {
        $('#changeWorkgroupImage').modal('show');
    }

    onArchiveWorkgroupClick() {
        this.workgroupCopyEditDeleteFlag = true;
        $('#archiveWorkgroupModal').modal('show');
        
    }


    onArchiveWorkgroup() {
        this.pinnacleService.DeleteWorkgroup(this.selectedWorkgroup.workgroupId, "").subscribe(i => {

            let adminSelectedToArchive = false;
            if (this.selectedWorkgroup == this.adminSelectedWG) {
                adminSelectedToArchive =true;
            }

            this.router.navigateByUrl("workcenter");
            this.ngOnInit();

            this.changedetector.detectChanges();

            if (adminSelectedToArchive) {
                this.adminSelectedWG.isArchived = true;
                this.adminSelectedWG.archivedDatetime = new Date();
                this.selectedWorkgroup = this.adminSelectedWG;
            }

        });
    }

    restoreWorkgroupClick() {
        $('#restoreWorkgroupModal').modal('show');
    }

    restoreWorkgroup() {
        if (this.selectedWorkgroup.isArchived == true) {
            this.pinnacleService.RestoreWorkgroup(this.selectedWorkgroup.workgroupId).subscribe(i => {
                this.router.navigateByUrl("workcenter");
                this.ngOnInit();

                this.adminSelectedWG.isArchived = false;
                this.selectedWorkgroup = this.adminSelectedWG;
            });
        }
    }

    onMembersUpdated() {
        //this.loadMembers();
    }

    onCreateWorkgroup() {
        if (this.isModify == true) {
            let v_ImageURL = this.selectedWorkgroup.imageURL;
            if (v_ImageURL.startsWith("../")) {
                v_ImageURL = "";
            }
            this.pinnacleService.UpdateWorkgroup(this.selectedWorkgroup.workgroupId.toString(), this.newWorkgroupName, this.newWorkgroupDesc, v_ImageURL.toString(), this.newWorkgroupIsPublic, this.newWorkgroupEditorDashboard, this.newWorkgroupMemberDashboard, this.newWorkgroupExternalMemDashboard, this.notifyContentAdded, this.notifyContentRemoved, this.notifyContentModified, this.notifyUserAdded, this.notifyUserRemoved, this.notifyUserExpiring, this.notifyCommentPosted, this.notifyArchivedRestored, this.newwgEditorMembers, this.newwgMemberMembers, this.newwgExternalMemMembers, this.newwgEditorDiscussion, this.newwgMemberDiscussion, this.newwgExternalMemDiscussion).subscribe(i => {
                this.ngOnInit();

                this.selectedWorkgroup.editorsDashboardAllowed = this.newWorkgroupEditorDashboard;
                this.selectedWorkgroup.membersDashboardAllowed = this.newWorkgroupMemberDashboard;
                this.selectedWorkgroup.externalMembersDashboardAllowed = this.newWorkgroupExternalMemDashboard;

                this.selectedWorkgroup.discussionEditorsAllowed = this.newwgEditorDiscussion;
                this.selectedWorkgroup.discussionMembersAllowed = this.newwgMemberDiscussion;
                this.selectedWorkgroup.discussionExternMembersAllowed = this.newwgExternalMemDiscussion;

                this.selectedWorkgroup.membersTabEditorsAllowed = this.newwgEditorMembers;
                this.selectedWorkgroup.membersTabMembersAllowed = this.newwgMemberMembers;
                this.selectedWorkgroup.membersTabExternMembersAllowed = this.newwgExternalMemMembers;

                if (this.selectedWorkgroup == this.adminSelectedWG) {
                    this.adminSelectedWG.name = this.newWorkgroupName;
                    this.adminSelectedWG.description = this.newWorkgroupDesc;
                    this.selectedWorkgroup = this.adminSelectedWG;
                    
                }
            });
        }
        else {            

            let workgroup = new Workgroup();
            workgroup.name = this.newWorkgroupName;
            workgroup.description = this.newWorkgroupDesc;
            workgroup.imageURL = "";
            workgroup.isPublic = this.newWorkgroupIsPublic;

            workgroup.editorsDashboardAllowed = this.newWorkgroupEditorDashboard;
            workgroup.membersDashboardAllowed = this.newWorkgroupMemberDashboard;
            workgroup.externalMembersDashboardAllowed = this.newWorkgroupExternalMemDashboard;
            workgroup.discussionEditorsAllowed = this.newwgEditorDiscussion;
            workgroup.discussionMembersAllowed = this.newwgMemberDiscussion;
            workgroup.discussionExternMembersAllowed = this.newwgExternalMemDiscussion;
            workgroup.membersTabEditorsAllowed = this.newwgEditorMembers;
            workgroup.membersTabMembersAllowed = this.newwgMemberMembers;
            workgroup.membersTabExternMembersAllowed = this.newwgExternalMemMembers;


            workgroup.notifyContentAdded = this.notifyContentAdded;
            workgroup.notifyContentRemoved = this.notifyContentRemoved;
            workgroup.notifyContentModified = this.notifyContentModified;
            workgroup.notifyUserAdded = this.notifyUserAdded;
            workgroup.notifyCommentPosted = this.notifyCommentPosted;
            workgroup.notifyArchivedRestored = this.notifyArchivedRestored;
            workgroup.notifyUserRemoved = this.notifyUserRemoved;
            workgroup.notifyUserExpiring = this.notifyUserExpiring;

            this.pinnacleService.SetWorkgroup(workgroup).subscribe(i => {

                if (this.newImageFile != null) {
                    this.pinnacleService.updateWorkgroupImage(i.workgroupId.toString(), this.newImageFile).subscribe(res => {
                        i.imageURL = res;
                        this.pinnacleService.UpdateWorkgroup(i.workgroupId.toString(), i.name.toString(), i.description.toString(), i.imageURL.toString(), false, this.newWorkgroupEditorDashboard, this.newWorkgroupMemberDashboard, this.newWorkgroupExternalMemDashboard, this.notifyContentAdded, this.notifyContentRemoved, this.notifyContentModified, this.notifyUserAdded, this.notifyUserRemoved, this.notifyUserExpiring, this.notifyCommentPosted, this.notifyArchivedRestored, this.newwgEditorMembers, this.newwgMemberMembers, this.newwgExternalMemMembers, this.newwgEditorDiscussion, this.newwgMemberDiscussion, this.newwgExternalMemDiscussion).subscribe(r => {
                            this.router.navigateByUrl("workcenter?id=" + i.workgroupId);
                            this.ngOnInit();
                        },
                            err => {
                                console.log(err);
                                this.router.navigateByUrl("workcenter?id=" + i.workgroupId);
                                this.ngOnInit();
                            });
                    });
                }
                else {
                    this.router.navigateByUrl("workcenter?id=" + i.workgroupId);
                    this.ngOnInit();
                }
            });
        }
    }

    dashboardTabResetClick() { //When a WG Tab that isn't Dashboard is Clicked
        this.dashboardVisited = false;
    }

    dashboardClick() { //When WG Dashboard Tab is clicked.
        if (!this.dashboardVisited) {
            this.dashboardVisited = true;
            setTimeout(() => { this.WGContainer.outsideDivSetup(true); }, 10);
            
        }

    }

    reviewExternalUsersClick() {
        this.summaryInvitesAssigned = 0;
        this.summaryInvitesAvailable = 0;
        this.summaryInvitesTotal = 0;
        this.summaryRecords = new Array<WorkgroupExternalSummaryRecord>();

        this.pinnacleService.GetExternalWorkgroupSummary().subscribe(res => {
            this.summaryInvitesAssigned = res.externalMembersUsed;
            this.summaryInvitesAvailable = res.externalMemberInvitesAvailable;
            this.summaryInvitesTotal = res.externalMembersMax;
            this.summaryRecords = res.summaryRecords;


            $('#reviewExternalUsersModal').modal('show');
        });
    }

    memberSelfDelete() {

        if (this.selectedWorkgroup.isExternal) {

            var v_Index = this.externalWorkGroups.indexOf(this.selectedWorkgroup);

            if (this.externalWorkGroups.length - 1 > 0) {
                let v_NewIndex = 0;
                if (v_Index == this.externalWorkGroups.length) {
                    v_NewIndex = v_Index - 1;
                }
                else {
                    v_NewIndex = v_Index + 1;
                }

                this.selectedWorkgroup = this.externalWorkGroups[v_NewIndex];
                

            }
            else {
                if (this.myWorkGroups.length > 0) {
                    this.selectedWorkgroup = this.myWorkGroups[0];
                }
                else {
                    //signout

                    sessionStorage.clear();
                    AppComponent.thisFromOutside.isLoggedIn = false;
                    AppComponent.thisFromOutside.companyLogo = "";
                    AppComponent.isPinnacleLite = false;
                    AppComponent.thisFromOutside.isPinnacleLiteLocal = false;
                    this.pinnacleService.EndSession();
                   

                    localStorage.setItem("rememberMe", "false");
                    localStorage.setItem("longLivedToken", "");

                    sessionStorage.setItem("ChatActive", "false");
                    localStorage.setItem("lastUsedTenant", "");
                    localStorage.setItem("isAuthenicated", "");



                    sessionStorage.setItem("workGroupSortBy", "Asset Name");
                    sessionStorage.setItem("volume", "7");
                    this.router.navigate(['/login']);
                    return;
                }
            }

            this.externalWorkGroups.splice(v_Index, 1);
        }
        else {


            if (this.myWorkGroups.length - 1 > 0) {


                var v_Index = this.myWorkGroups.indexOf(this.selectedWorkgroup);

                let v_NewIndex = 0;

                if ((v_Index + 1) == this.myWorkGroups.length) {

                    v_NewIndex = v_Index - 1;
                }
                else {

                    v_NewIndex = v_Index + 1;
                }


                this.selectedWorkgroup = this.myWorkGroups[v_NewIndex];

            }
            else {
                if (this.externalWorkGroups.length > 0) {
                    this.selectedWorkgroup = this.externalWorkGroups[0];
                }
                else {
                    //home
                    this.router.navigate(['/home']);
                    return;
                }
            }


            if (this.myWorkGroups[v_Index].isPublic == true) {
                this.publicWorkgroupList.push(this.myWorkGroups[v_Index]);
                this.publicWorkgroupListFilterCopy.push(this.myWorkGroups[v_Index]);
            }

            this.myWorkGroups.splice(v_Index, 1);

        }

        //console.log("Reload Perm");

        if (this.ownerWorkGroups.findIndex(i => i == this.selectedWorkgroup.workgroupId) != -1 || this.hasWGAdminPerm) {
            this.currentUserPermissionsLevel = "owner";
        }
        else if (this.editorWorkGroups.findIndex(i => i == this.selectedWorkgroup.workgroupId) != -1) {
            this.currentUserPermissionsLevel = "editor";
        }

    }

    getClassList(index: number): string {
        let classes: string = "";


        let themeNum: number = (index % 3);


        if (themeNum == 0) {
            themeNum = 1;
        }
        else if (themeNum == 1) {
            themeNum = 2;
        }
        else if (themeNum == 2) {
            themeNum = 3;
        }

        classes += "largecardcolortheme" + themeNum;
        return classes;
    }

    onClickItem(w: Workgroup) {
        this.selectedWorkgroupPanel = w;

        $('#selfInviteModal').modal('show');
    }

    onSubmit() {
        try {
            let userid = ProdGenApi.currentUser.userId;
            this.pinnacleService.SetWorkgoupMember(this.selectedWorkgroupPanel.workgroupId, userid, PermissionLevel_T.user).subscribe(
                res => {

                    let index = this.publicWorkgroupList.indexOf(this.selectedWorkgroupPanel);
                    this.publicWorkgroupList.splice(index, 1);
                    this.publicWorkgroupListFilterCopy = new Array<Workgroup>();
                    for (var i = 0; i < this.publicWorkgroupList.length; i++) {
                        this.publicWorkgroupListFilterCopy.push(this.publicWorkgroupList[i]);
                    }

                    //add workgroup to the array that shows on the left
                    this.myWorkGroups.push(this.selectedWorkgroupPanel);

                });
        }
        catch (e){ }
    }

    filterWorkgroups(ev: any) {
        //console.log(this.filterText);
        //console.log(this.publicWorkgroupList);
        //console.log(this.publicWorkgroupListFilterCopy);

        this.publicWorkgroupListFilterCopy = new Array<Workgroup>();
        for (var i = 0; i < this.publicWorkgroupList.length; i++) {
            this.publicWorkgroupListFilterCopy.push(this.publicWorkgroupList[i]);
        }

        for (var j = 0; j < this.publicWorkgroupListFilterCopy.length; j++) {
            if (this.publicWorkgroupListFilterCopy[j].name.toLowerCase().indexOf(this.filterText.toLowerCase()) == -1) {
                this.publicWorkgroupListFilterCopy.splice(j, 1);
                j--;
            }
        }
    } 



    /*
     *
     * User Grid stuff...
     * 
     */

    @ViewChild("userGrid", { static: false }) userGrid: UserDataGridComponent;
    userGridMode: 'addMembers' | 'assignLearning' = 'addMembers';

    // Flag to prevent rendering DX component until its
    // layout space is stable.
    showUserGrid: boolean = false;

    selectedUserRole: 'member' | 'editor' | 'owner' = 'member';

    // Callback from invoking the "Add Members" action
    // in the WorkgroupMemebersViewComponent.
    public startAddMembers(): void {
        this.userGridMode = 'addMembers';
        this.filterMode = 'exclude';
        this.includeExternalUsers = false;
        this.selectedUserRole = 'member';
        this.startShowUserGrid();
    }

    // Callback from invoking the "Assign Learning" action
    // in the WorkgroupAssetViewComponent.
    public startAssignLearning(): void {
        this.userGridMode = 'assignLearning';
        this.filterMode = 'include';
        this.includeExternalUsers = true;
        this.useDueDate = false;
        this.selectedDueDate = this.formatDate(new Date(Date.now()));
        this.selectedLearningContent = [];
        this.startShowUserGrid();
    }

    // Open the user picker modal, wait for the layout to be stable
    // before allowing the DX component to render.
    startShowUserGrid(): void {
        $("#userGridModal").modal("show");
        setTimeout(() => { this.showUserGrid = true; }, 100);
    }

    // Destroy the DX component.
    hideUserGrid(e) {
        this.showUserGrid = false;
    }

    // Delegate user selection results back to child components.
    @ViewChild(WorkgroupMemberViewComponent, { static: false }) memberView;
    @ViewChild(WorkgroupAssetViewComponent, { static: false }) assetView;
    commitUserGrid(e: any): void {
        let users: UserDataGridInfo[] = this.userGrid.getSelectedUsers();

        if (this.userGridMode == 'addMembers') {
            this.memberView.commitNewMembers(users, this.selectedUserRole);
            $("#userGridModal").modal("hide");
            this.hideUserGrid(e);


        }
        else if (this.userGridMode == 'assignLearning') {
            this.assignLearningNoLearningErr = false;
            this.assignLearningNoUsersErr = false;
            
            if (this.selectedLearningContent.length > 0 && users.length > 0) {
                this.assetView.commitEnrollMembers(users, this.selectedLearningContent, this.useDueDate, this.selectedDueDate);
                $("#userGridModal").modal("hide");
                this.hideUserGrid(e);
            }
            else {
                
                if (this.selectedLearningContent.length == 0) {
                    this.assignLearningNoLearningErr = true;
                }
                if (users.length == 0) {
                    this.assignLearningNoUsersErr = true;
                }

                $("#moreInformationNeedModal").modal("show");
            }

        }

        
    }

    // Used in the legacy Angular datepicker component.
    // 7/31 DH - We no longer user the legacy Angular Datepicker component but we need to modify and continue to use this method for the input field
    formatDate(date: Date): string {
        let month = date.getMonth() + 1;
        let day = date.getDate();

        return [
            date.getFullYear(),
            (month > 9 ? "" : "0") + month,
            (day > 9 ? "" : "0") + day].join("-");
    }

    // Settings for picking content to assign.
    assignableLearningContent = [];
    selectedLearningContent = [];
    useDueDate: boolean = false;
    selectedDueDate = this.formatDate(new Date(Date.now()));
    dueDateSettings: IDatePickerConfig = {
        format: 'MM-DD-YYYY'
    };
    learningContentSettings = {
        text: this.translationService.getTranslationFileData('WORKCENTER.SelectLearning'),
        enableCheckAll: true,
        enableSearchFilter: true,
        enableFilterSelectAll: true,
        maxHeight: 500,
        badgeShowLimit: 2,
        autoPosition: false,
        position: 'bottom',
        noDataLabel: this.translationService.getTranslationFileData('WORKCENTER.NoLearningData')
    };

    // Callback from initializing a list of learning content
    // in WorkcenterAssetViewComponent.
    setAssignableLearningContent(content) {
        this.assignableLearningContent = content;
    }

    // Limit the users shown in the grid.
    filterUserIds: Array<string>;
    filterMode: 'exclude' | 'include' = 'exclude';
    setUserFilter(userIds: string[], mode: 'exclude' | 'include' = 'exclude'): void {
        this.filterUserIds = userIds;
        this.filterMode = mode;
    }

    includeExternalUsers: boolean = false;
}