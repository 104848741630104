import { Component, OnInit, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { WidgetComponent } from '../../widget/widget.component';
import { ProdGenApi } from '../../../apiService/prodgen.api';
import { WidgetProperties, WidgetPropertyOptions, RoleLayoutAssignment, SettingType_T, WidgetContainerInfo } from '../../../apiService/classFiles/class.users';

import { BrowserAuthenticationService } from '../../../BrowserAuthenticationService';
import { User } from '../../../apiService/classFiles/class.users';
import { EnrollmentFilter_T, Enrollment } from '../../../apiService/classFiles/class.enrollments';
import { Content, ContentType_T } from '../../../apiService/classFiles/class.content';
import { Workgroup } from '../../../apiService/classFiles/class.workgroups';
import { BrandingUpdateServiceService } from '../../../services/branding-update-service.service';



@Component({
  selector: 'app-profile-panel-widget',
  templateUrl: './profile-panel-widget.component.html',
  styleUrls: ['./profile-panel-widget.component.css']
})
export class ProfilePanelWidgetComponent extends WidgetComponent implements OnInit, AfterViewInit {
    static key = "ProfilePanelWidgetComponent";

    widgetLoaded: boolean = false;

    currentUser: User = new User();
    isAuthenticated: boolean = false;
    companyInfoLoaded: boolean = false;
    workGroups: Array<Workgroup> = new Array<Workgroup>();
    completedCourseCount: number = 0;
    currentEnrollments: Array<Enrollment> = new Array<Enrollment>();
    enrollmentsLoaded: boolean = false;
    canSignClientAccount: boolean = false;
    displayImage: string;
    overlayDisplayClass = "widget-overlay-style-page";
    overlayDisplayLinkClass = "";

    roleLayoutAssignments: Array<RoleLayoutAssignment> = new Array<RoleLayoutAssignment>();
    selectedRole: string = "";

    useMobileHeight = true;

    constructor(private pinnacleService: ProdGenApi, private changedetectorref: ChangeDetectorRef, private authenticationService: BrowserAuthenticationService, private brandingService: BrandingUpdateServiceService) {
        // call the base Widget class
        super(pinnacleService, changedetectorref);
    
        //Widget Defaults
        this.widgetHeight = 6;
        this.widgetWidth = 2;
        this.widgetTitle = "";
        this.mobileHeight = 8;
        this.displayImage = "no";
        this.widgetName = "Profile Widget"
        this.widgetDesc = "Display information about the current user.";
        //End Widget Defaults

        if (authenticationService.AuthenticatePage() == true) {
            this.pinnacleService.getCurrentUser().subscribe(res => { 
                this.currentUser = res;
                this.isAuthenticated = true;
                this.companyInfoLoaded = false;
                //this.currentUser.imageURL = "../assets/images/default_user.jpg" + "?" + Date.now();
                //this.pinnacleService.getCurrentUser().subscribe(u => {
                this.brandingService.profilepictureemitter.subscribe(res => { this.currentUser.imageURL = res });
                if (this.currentUser.imageURL == "") {
                    this.currentUser.imageURL = "../assets/images/default_user.jpg" + "?" + Date.now();;
                }

                //this.currentUser = u;
                //this.currentUser.imageURL = u.imageURL;

                try {
                    localStorage.setItem(this.currentUser.userId + "_user_image", JSON.stringify(this.currentUser.imageURL));
                }
                catch (err) {
                    console.log(err);
                }


                // try to load up any previous information. this will keep the screen from flickering..?

                try {
                    this.workGroups = JSON.parse(localStorage.getItem(this.currentUser.userId + "_workgroups"));
                }
                catch (err) {
                    console.log(err)
                }


                try {
                    this.currentEnrollments = JSON.parse(localStorage.getItem(this.currentUser.userId + "_enrollments"));
                }
                catch (err) {
                    console.log(err)
                }



                this.pinnacleService.getCurrentUserEnrollments().subscribe(res => {
                    for (var i = 0; i < res.length; i++) {
                        if (res[i].imageURL == null || res[i].imageURL == "") {

                            res[i].imageURL = "../assets/images/default_course.jpg" + "?" + Date.now();;
                        }
                    }
                    this.currentEnrollments = res;

                    //copy and sort the array. flag the lowest squences with the number of other items with the same learning path. remove non-lowest sequence.
                    //copy and sort the array.
                    //let condensedEnrollments: Array<Enrollment> = new Array<Enrollment>();
                    //this.CondenseEnrollments()

                    this.enrollmentsLoaded = true;
                    /// write to Local Storage for this user
                    try {
                        localStorage.setItem(this.currentUser.userId + "_enrollments", JSON.stringify(this.currentEnrollments))
                    }
                    catch (err) {
                        console.log(err);
                    }

                }
                );

                this.pinnacleService.getCurrentUserEnrollments([EnrollmentFilter_T.completed]).subscribe(e => this.completedCourseCount = e.length);

                this.pinnacleService.getCurrentUserPermissions().subscribe(res => {
                    


                    if (res.signClientAccount == true) {
                        this.canSignClientAccount = true;
                    }
                });

                this.pinnacleService.getCurrentUserWorkgroups().subscribe(wgs => {
                    this.workGroups = wgs;
                });
                //});
            });
        }      
    }

    ngOnInit() {
        super.ngOnInit();

        try {
            //this.categoriesSubscription = JSON.parse(localStorage.getItem(this.currentUser.userId + "_categories_subscription"));
            this.displayType = JSON.parse(localStorage.getItem(this.widgetID + "_displayType"));
        }
        catch (err) {
        }

        // if it was not in storage, reert to default
        if (this.displayType == null) {
            this.displayType = "panel";
        }

        try {
            //this.categoriesSubscription = JSON.parse(localStorage.getItem(this.currentUser.userId + "_categories_subscription"));
            this.displayImage = JSON.parse(localStorage.getItem(this.widgetID + "_displayImage"));
        }
        catch (err) {
        }

        this.pinnacleService.getUserLayoutAssignments().subscribe(res => {
            this.roleLayoutAssignments = res;
            this.roleLayoutAssignments.sort((a, b) => (a.rolename > b.rolename) ? 1 : -1);
            if (this.roleLayoutAssignments && this.roleLayoutAssignments.length > 0) {
                this.pinnacleService.getCurrentUserSetting("ROLE_LAYOUT_PREFERENCE").subscribe(res2 => {
                    if (res2) {
                        if (this.roleLayoutAssignments.findIndex(x => x.roleid == res2.settingValue) != -1) {
                            this.selectedRole = this.roleLayoutAssignments[this.roleLayoutAssignments.findIndex(x => x.roleid == res2.settingValue)].roleid;
                        }
                        else {
                            this.selectedRole = this.roleLayoutAssignments[0].roleid;
                        }
                    }
                    else {
                        this.selectedRole = this.roleLayoutAssignments[0].roleid;
                    }

                    if (this.roleLayoutAssignments.length > 1) {
                        let defaultRoleIDIndex = this.roleLayoutAssignments.findIndex(x => x.roleid == "00000000-0000-0000-0000-000000000000"); //get the index of the default layout role
                        if (this.selectedRole == "00000000-0000-0000-0000-000000000000") {//is the selected role equal to an empty guid, which indicates a default layout selection
                            if (defaultRoleIDIndex == 0) {
                                this.selectedRole = this.roleLayoutAssignments[1].roleid;
                            }
                            else {
                                this.selectedRole = this.roleLayoutAssignments[0].roleid;
                            }
                            //this.onSelectedRoleChanged();//set the users setting to not be the default and refresh the page
                            this.pinnacleService.saveCurrentUserSetting("ROLE_LAYOUT_PREFERENCE", this.selectedRole, SettingType_T.string).subscribe();
                        }
                        else {
                            //no work needed
                        }
                        this.roleLayoutAssignments.splice(defaultRoleIDIndex, 1);
                    }
                },
                    err => {
                        console.log(err);
                        this.selectedRole = this.roleLayoutAssignments[0].roleid;
                    });

            }
            this.adjustSize();
        },
            err => { console.log(err);});
        // if it was not in storage, reert to default

        this.createProperties();
        this.Load();
        this.calMobileHeight();
    }

    ngAfterViewInit() {
        this.widgetContainer.incrementRenderedWidget();
    }

    adjustSize() {
        //this.widgetContainer.onWidgetExpand(this.widgetID);
        //let overflowamt = this.widgetContainer.determineOverflow(this.widgetID);

        let item = document.getElementById("profile_" + this.widgetID);

        if (item == null) {
            return;
        }

        let overflowamt = item.scrollHeight - item.clientHeight;

    }



    calMobileHeight() {
        var tally: number = 0;
        this.mobileHeight = 0;


        if (this.widgetTitle != null && this.widgetTitle != "") {
            this.mobileHeight++;
        }

        //Get Tally
        if (this.workGroups != null && this.workGroups.length != 0) {
            tally++;
        }

        if (this.currentEnrollments != null && this.currentEnrollments.length != 0) {
            tally++;
        }
        
        if (this.completedCourseCount != 0) {
            tally++;
        }

        if (this.canSignClientAccount == true) {
            tally++;
        }
        
        
        
        if (this.displayImage == 'yes') {
            if (tally == 0 || tally == 1 ||tally == 2) {
                this.mobileHeight = this.mobileHeight + 7;
                    
            }
            else if (tally == 3 || tally == 4) {
                this.mobileHeight = this.mobileHeight + 8;                   
            } 
        }
        else {

            if (tally == 0 || tally == 1 || tally == 2) {
                this.mobileHeight = this.mobileHeight + 3;
            }
            else if (tally == 3 || tally == 4) {
                this.mobileHeight = this.mobileHeight + 4;
            }
            
        }


    }  


    //START WIDGET REQUIRED CALLS
    onPropsChanged(newprops: Array<WidgetProperties>, writeSettings: boolean = false, doresequence = true) {
        super.onPropsChanged(newprops, writeSettings, doresequence);

        //The rest of the onPropsChanged method is widget specific, following the template of the two IF statements above.
        
        if (newprops.findIndex(x => x.propID == "DISPLAY_IMAGE") != -1) {
            this.displayImage = newprops[newprops.findIndex(x => x.propID == "DISPLAY_IMAGE")].propValue;
        }


        if (writeSettings) {
            this.pinnacleService.setWidgetProperties(this.widgetContainer.containerInfo.containerInstanceId, this.widgetID, newprops, ProfilePanelWidgetComponent.key).subscribe(res => {/*return stuff. might not need to do anything here*/ });
        }

        try {
            localStorage.setItem(this.widgetID + "_displayImage", JSON.stringify(this.displayImage));
        }
        catch (err) {
            console.log(err);
        }

        super.onPropsChangedLocalStorage();

        this.widgetLoaded = true;
        this.calMobileHeight();
        //super.widgetMobileHeightCalucaled(); //Let's Widget Container know that the right Mobile Height has been calculated
        super.widgetArePropertiesLoaded();
    }



    Load() {
        super.Load();

        //The rest of the load function will be widget specific

    }

    createProperties() {

        super.createProperties();

        super.createPropertiesDisplayType();


        let displayImage = new WidgetProperties();
        let imageOption1: WidgetPropertyOptions = new WidgetPropertyOptions();
        imageOption1.opName = "Yes";
        imageOption1.opValue = "yes";
        displayImage.propOptions.push(imageOption1);

        let imageOption2: WidgetPropertyOptions = new WidgetPropertyOptions();
        imageOption2.opName = "No";
        imageOption2.opValue = "no";
        displayImage.propOptions.push(imageOption2);

        displayImage.propName = "Show User Image"
        displayImage.propValue = this.displayImage;
        displayImage.propType = "select";
        displayImage.propID = "DISPLAY_IMAGE";
        this.widgetProps.push(displayImage);

        super.createPropertiesWidgetTitle();

        super.createPropertiesMobileOptions();



    }

    //END WIDGET REQUIRED CALLS
    onWidgetMoved(x: number, y: number, selectedlayout: string = this.widgetContainer.selectedLayout) {
        super.onWidgetMoved(x, y, selectedlayout);
    }

    onSelectedRoleChanged() {
        this.pinnacleService.saveCurrentUserSetting("ROLE_LAYOUT_PREFERENCE", this.selectedRole, SettingType_T.string).subscribe(res => { 

            let newcontainer = new WidgetContainerInfo();
            if (document.URL.includes("home")) {
                this.pinnacleService.getWidgetContainerForUser("HOME_SCREEN_CONTAINER").subscribe(res => {
                    newcontainer = res;
                    this.widgetContainer.clearWidgets();
                    this.widgetContainer.instanceID = newcontainer.containerInstanceId;
                    this.widgetContainer.bindingId = newcontainer.bindingId;
                    this.widgetContainer.loadContainerFramework();
                    this.widgetContainer.adjustLayout();
                    this.widgetContainer.onResize(null);
                });
            }
            else if (document.URL.includes("learningcenter")) {
                this.pinnacleService.getWidgetContainerForUser("LEARNING_CENTER_CONTAINER").subscribe(res => {
                    newcontainer = res;
                    this.widgetContainer.clearWidgets();
                    this.widgetContainer.instanceID = newcontainer.containerInstanceId;
                    this.widgetContainer.bindingId = newcontainer.bindingId;
                    this.widgetContainer.loadContainerFramework();
                    this.widgetContainer.adjustLayout();
                    this.widgetContainer.onResize(null);
                });
            }


        });
    }
}
