import { Component, OnInit, Input, ChangeDetectorRef, ApplicationRef} from '@angular/core';
import { ProdGenApi } from './../../apiService/prodgen.api';
import { SearchResult, SearchResults } from '../../apiService/classFiles/class.search';
import { Content, ContentType_T } from './../../apiService/classFiles/class.content';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Workgroup, WorkgroupMember, PermissionLevel_T } from './../../apiService/classFiles/class.workgroups';


declare var $: any;

@Component({
  selector: 'template-searchresult',
  templateUrl: './searchresult.component.html',
  styleUrls: ['./searchresult.component.css']
})

export class SearchresultComponent implements OnInit {
    @Input('searchitem') searchitem: SearchResult;
    @Input('index') searchindex: number;
    @Input('searchterms') searchTerms: string;
    @Input('editorworkgroups') editorWorkgroups: Array<Workgroup>;
    @Input('searchresults') searchResults: SearchResults;

    resultName: SafeHtml = "";
    resultDesc: SafeHtml = "";
    resultImage: string = "";
    resultUrl: string = "";
    resultTarget: string = "";
    resultType: string = "";
    contentItem: Content = new Content();

    resultProvidedBy: string = "";

    constructor(private service: ProdGenApi, private _sanitizer: DomSanitizer, private cd: ChangeDetectorRef, private appref:ApplicationRef) {

    }

    ngOnInit() {
        this.resultName = this.getSafeHtml(this.searchitem.name)
        this.resultDesc = this.getSafeHtml(this.searchitem.desc)
        this.resultImage = this.getResultImage(this.searchitem);
        this.contentItem = this.getResultAsContentItem(this.searchitem);
        this.resultUrl = this.getResultUrl(this.searchitem);
        this.resultTarget = this.getResultTarget(this.searchitem);
        this.resultType = this.getResultType(this.searchitem);
        this.resultProvidedBy = this.getResultProvidedBy(this.searchitem);
        
  }

  ngAfterViewInit() {
      this.cd.detectChanges();
  }

  getResultImage(r: SearchResult): string {
      let image: string = "";

      image = "assets/images/";

      if (r.biscustom == false) {
          if (r.contentType == ContentType_T.cheatsheet && r.url != null && r.url != "") {
              if (r.url.includes(".pdf")) {
                  image += "PDF Graphic.png"
              }
              else if (r.url.includes(".doc")) {
                  image += "Word Graphic.png"
              }
              else if (r.url.includes(".xls")) {
                  image += "Excel Graphic.png"
              }
              else if (r.url.includes(".ppt")) {
                  image += "PowerPoint Graphic.png"
              }
              else if (r.url.includes(".htm")) {
                  image += "HTML Graphic.png"
              }
          }
          else { 
              image += ProdGenApi.getLargeDefaultImageFromType(r.contentType);
              if ((image.indexOf("default") == -1) && (image.indexOf("extended_search.jpg") == -1)) {
                  image = image.replace("assets/images/", "");
              }
          }
      }
      else {
          try {
              image = localStorage.getItem(ProdGenApi.getCurrentTenantId + "_MyDocsImage");
          } catch (e) {

          }
          if (image == "" || image == null) {
              image = "assets/images/My Cheat Sheets Graphic.png";
          }
      }
      return image;
  }

    displayToggleResults(r: Array<SearchResult>, index: number): string {
        var stringArr = new Array<string>();

        if (r[index].indent == 0) {
            for (var i = index + 1; i < r.length; i++) {
                if (r[i].indent == 0)
                    break;
                stringArr.push("#searchresult" + i);
            }
            return stringArr.join();
        }
    }

    getResultUrl(r: SearchResult): string {
        let url: string = "./#/";
        if (r.contentType == ContentType_T.workflow) {
            url += "workflowviewer?id=" + r.contentid + "&contentType=workflow";
        }
        else if (r.contentType == ContentType_T.process) {
            url += "workflowviewer?id=" + r.contentid + "&contentType=process";
        }
        else if (r.contentType == ContentType_T.task) {
            url += "workflowviewer?id=" + r.contentid + "&contentType=task";
        }
        else if (r.contentType == ContentType_T.step) {
            url += "workflowviewer?id=" + r.contentid + "&contentType=step";
        }
        else if (r.contentType == ContentType_T.cheatsheet) {
            url += "cheatsheetviewer?id=" + r.contentid;
        }
        else if (r.contentType == ContentType_T.video) {
            url += "videoviewer?id=" + r.contentid;
        }
        else if (r.contentType == ContentType_T.course) {
            url += "learningcenter/series?courseId=" + r.contentid;
        }
        else if (r.contentType == ContentType_T.learningpath) {
            url += "learningcenter/series?learningPathId=" + r.contentid;
        }
        else if (r.contentType == ContentType_T.chatLog) {
            url += "chatviewer?id=" + r.contentid;
        }
        else if (r.contentType == ContentType_T.extendedSearch) {
            url = r.path + this.searchTerms;
        }

        return url;
    }

    getResultTarget(r: SearchResult): string {
        let target: string = "_self";
        if (r.contentType == ContentType_T.extendedSearch) {
            target = "_blank";
        }

        return target;
    }

    getResultAsContentItem(r: SearchResult): Content {
        let c: Content = new Content();
        c.contentId = r.contentid;
        c.contentType = r.contentType;
        c.name = r.name;
        c.name = String(c.name).replace(/<[^>]+>/gm, '')
        return c;
    }

    getSafeHtml(htmlText: string): SafeHtml {
        if (htmlText == null) {
            htmlText = "";
        }
        return this._sanitizer.bypassSecurityTrustHtml(htmlText);
    }

    getResultType(r: SearchResult): string {
        let image: string = "";

        switch (r.contentType) {
            case ContentType_T.workflow:
                image = "Workflow";
                break;
            case ContentType_T.process:
                image = "Process";
                break;
            case ContentType_T.task:
                image = "Task";
                break;
            case ContentType_T.step:
                image = "Step";
                break;
            case ContentType_T.cheatsheet:
                image = "Document";
                break;
            case ContentType_T.chatLog:
                image = "Saved Chat";
                break;
            case ContentType_T.video:
                image = "Video";
                break;
            case ContentType_T.course:
                image = "Course";
                break;
            case ContentType_T.learningpath:
                image = "Learning Path";
                break;
            case ContentType_T.extendedSearch:
                image = "Extended Search";
                break;
        }
        return image;
    }

    getResultProvidedBy(r: SearchResult): string {
        if (r != null && r.publisher != null && r.publisher.length > 0) { 
            let providedBy: string = "";

            providedBy = "Provided By: " + r.publisher;

            return providedBy;
        }
        return null;
    }

    calls: number = 0;

    GetScrollHeight(element: string): number {
        return 0;
        //let htmlelem = document.getElementsByClassName(element);
        //if (htmlelem.length != 0) {
        //    if (htmlelem[0].scrollHeight < 40) {
        //        return 0;
        //    }
        //    else {
        //        return htmlelem[0].scrollHeight;
        //    }
        //}
        
    }
    
}
