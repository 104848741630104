export class Partner {
    public partnerId: String;
    public name: string;
    public logoURL: string;
    public partnerSupportEmail: String;
    public partnerSupportPhone: String;
    public supportURL: String;
    public chatSupportAvailable: boolean;
    public emailSupportAvailable: boolean; 
    public phoneSupportAvailable: boolean;
    public runSearchAvailable: boolean;
    public remoteAssistanceAvailable: boolean;
    public supportMaxWaitCount: number;
    public supportMaxAgentsCount: number;
    public partnerProductName: String;
}

export class PartnerNewsItem {
	public newsId: string;
	public partnerId: string;
	public name: string;
	public description: string;
	public linkUrl: string;
	public seq: number;

}

export class PartnerSupport {
  public PartnerID: string;
  public SupportPhone: string;
  public SupportURL: string;
  public SupportEmail: string;
  public TimeZone: string;
  public GMTOffset: number;
  public GMTOffsetDS: number;
  public StartHour: any;
  public EndHour: any;
  public bMonday: boolean;
  public bTuesday: boolean;
  public bWednesday: boolean;
  public bThursday: boolean;
  public bFriday: boolean;
  public bSaturday: boolean;
  public bSunday: boolean;
  public OffHoursMsg: string;
  public bShutdownOverride: boolean;
  public OverrideMsg: string;
  public HasAdvancedInfo: boolean;
  public MaxClientWait: number;
  public MaxAgentsNotify: number;
  public RunSearch: boolean;
  public PingAllAgents: boolean;
  public MaxAgentChants: number;
  public EvenlyDistributeChats: boolean;
  public PhoneSupportEnabled: boolean;
  public ChatSupportEnabled: boolean;
  public EmailSupportEnabled: boolean;
  public RemoteAssistanceEnabled: boolean;
  public AgentsUnavailableMsg: string;

}

export class AccountInformation {
    public accountTenantID: string;
    public accountTenantName: string;
    public accountOrgID: number;
}
export class TenantSubscription {
    public subscriptionID: string;
    public tenantID: string;
    public name: string;
    public createDate: Date;
    public expirationDate: Date;
    public unlimitedExpirationDate: Date;
    public isTrial: boolean;
    public isPinnacleLite: boolean;
    public quantity: number;
}

export class Subscription {
    public subscriptionID: string;
    public name: String;
    public isPinnacleLite: boolean;
    public partnerId: string;
}
export class AccountPost {
    public companyName: string;
    public userDisplayName: string;
    public userEmail: string;
    public isPinnacleLite: boolean;
    public maxPinnacleLiteUsers: number;
}
export class AccountPut {
    public companyName: string;
    public userEmail: string;
}
export class SubscriptionPost {
    public partnerSubscriptionID: string;
    public liscenseCount: number;
    public licenseCountCertifiedByEmail: string;
    public licenseCountCertifiedByName: string;
    public subscriptionExpiration: Date;
    public subscriptionUnlimitedUseExpiration: Date;
    public isTrial: boolean;
}
export class SubscriptionPut {
    public liscenseCount: number;
    public licenseCountCertifiedByEmail: string;
    public licenseCountCertifiedByName: string;
    public subscriptionExpiration: Date;
    public subscriptionUnlimitedUseExpiration: Date;
    public isTrial: boolean;
}

export class TenantPost {
    public tenantName: string;
    public adminEmail: string;
    public isPinnacleLite: boolean;
}

export class TenantPut {
    tenantName: string;
    maxPinnacleLiteUsers: number;
}

export class UpgradeOrderRequestPost {
    public orgId: number;
    public tenantId: string;
    public upgradePlatform: boolean;
    public poNumber: string;
    public additionalSubscriptions: Array<SubscriptionPost>;
}
