import { Pipe, PipeTransform } from '@angular/core';
import { WebVideoTextTracksCue } from './../apiService/classFiles/class.content';

@Pipe({
  name: 'filter'
})

export class FilterPipe implements PipeTransform {
  transform(items: WebVideoTextTracksCue[], searchText: string, isCaseSensitive: boolean): any[] {
    if (!items) return [];
    if (!searchText) return items;

    return items.filter(it => {
      if (isCaseSensitive) {
        return it.text.includes(searchText);
      } else {
        return it.text.toUpperCase().includes(searchText.toUpperCase());
      }
    });
  }
}
