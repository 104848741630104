import { AfterViewInit, Directive, ElementRef, HostListener, Input, OnChanges, OnDestroy, Renderer2, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AppControlService, MsgBxStringVals } from './AppControlService';
import { Subscription } from 'rxjs';

declare function doesFontExist(fontName: string): boolean;

@Directive({
    selector: '[ModalHTML]'

}) 

export class ModalHtmlDirective implements AfterViewInit, OnChanges {
	private htmlLoaded: boolean;
    private htmlUrl: String;

	listenClickFunc: Function;
    appControlSubscription: Subscription;

	@Input() ModalHTML: any;

    constructor(private el: ElementRef, private renderer: Renderer2, private domSanitizer: DomSanitizer, private router: Router, private controlService: AppControlService) {
        this.htmlLoaded = false;



	}

	ngAfterViewInit() {
		this.el.nativeElement.innerHTML = this.domSanitizer.sanitize(SecurityContext.HTML, this.ModalHTML);
        this.htmlUrl = window.location.href;

        this.createLinkListeners();
	}

	ngOnChanges(changes) {

        if (changes.ModalHTML.currentValue != null && changes.ModalHTML.previousValue != null){
			//this contains image elements within current width of their parent container
			for (let imgElem of this.el.nativeElement.getElementsByTagName('img')) {
				imgElem.style.maxWidth = "calc(100% - 20px)";
				imgElem.style.height = "auto";
			}
		  try {
			  if (changes.ModalHTML.currentValue.changingThisBreaksApplicationSecurity == changes.ModalHTML.previousValue.changingThisBreaksApplicationSecurity) {
				  return;
			  }
			  else {
			  }
		  }
		  catch (err) {
			  console.log(err);
			  return;
		  }
		  this.htmlLoaded = false;

          /*
	      if (this.htmlUrl != window.location.href) {
			  this.htmlLoaded = false;
			  this.el.nativeElement.innerHTML = this.domSanitizer.sanitize(SecurityContext.HTML, this.ContentHTML);
		  }
          */
          if (this.htmlLoaded == false) {
              //if(1==1){
			  /*
			  try {

			  }
			  catch (Exception) { }
              */


              if (changes.ModalHTML.currentValue.changingThisBreaksApplicationSecurity.startsWith('<!DOCTYPE')) {
                  //this.htmlUrl = window.location.href;
                  //this.el.nativeElement.innerHTML = this.domSanitizer.sanitize(SecurityContext.HTML,this.ContentHTML);
				  this.el.nativeElement.innerHTML = this.domSanitizer.sanitize(SecurityContext.HTML, this.ModalHTML);


                  this.htmlLoaded = true;

                  this.createLinkListeners();

              }
          }

		}

    }

	createLinkListeners() {

		const navigationElements = Array.prototype.slice.call(this.el.nativeElement.querySelectorAll('a[href]'));
		navigationElements.forEach(elem => {
			this.listenClickFunc = this.renderer.listen(elem, 'click', (event) => {
                if (elem.getAttribute('href').toLowerCase().startsWith('pinnaclecommand:')) {
                    // check to see if they are on Windows. If not, give them the not supported message and stop processing
                    if (window.navigator.userAgent.indexOf("Windows ") == -1) {
                        //not supported

                        var v_Msg = new MsgBxStringVals();
                        v_Msg.body = "The selected tool link is not supported on this device.";
                        v_Msg.title = ``;
                        this.controlService.openMessageBoxPopUp(v_Msg);
                        
                        event.preventDefault();
                        return; 
                    }

                    // check to see if they have installed protocol already. Done by checking if font exists that is installed with it
                    if (doesFontExist("Xenotron")== false)
                    { 
                      // if they have not, give them a message showing the download link
                        var v_Msg = new MsgBxStringVals();
                        v_Msg.body = 'toolModal';
                        v_Msg.title = ``;
                        this.controlService.openMessageBoxPopUp(v_Msg);

                        
                        event.preventDefault();
                        return;
                    }

                    // if they have installed the plugin, let it go through


				}

                let v_Href = elem.getAttribute('href');

				if (v_Href.toLowerCase().startsWith('pinnacle:')) {

					let href_url = v_Href.replace('pinnacle://', '');
					let url_array = href_url.split(':');

					let url = "#";

					if (url_array[0] == "PS_Cheat") {

						this.controlService.openCheatSheetPopUp(url_array[1]);
						//window.location.href = url += "/cheatsheetviewer?id=" + url_array[1];
					}
					else if (url_array[0] == "PS_VidTutorial" || url_array[0] == "PS_VidArchive" || url_array[0] == "PS_VidUser") {
						//window.location.href = url += "/videoviewer?id=" + url_array[1];
						this.controlService.openVideoPopUp(url_array[1]);
					}
					else if (url_array[0] == "PS_LearningPath") {
						this.controlService.closePopUps();
						window.location.href = url += "/learningcenter/series?learningPathId=" + url_array[1];
					}
					else if (url_array[0] == "PS_Course") {
						this.controlService.closePopUps();
						window.location.href = url += "/learningcenter/series?courseId=" + url_array[1];
					}
                    else if (url_array[0] == "PS_Workflow" || url_array[0] == "PS_Process" || url_array[0] == "PS_Task" || url_array[0] == "PS_Step" || url_array[0] == "PS_Milestone") {
						let contentType = "";
						if (url_array[0] == "PS_Workflow") {
							contentType = "workflow";
						}
						else if (url_array[0] == "PS_Process") {
							contentType = "process";
						}
                        else if (url_array[0] == "PS_Task" || url_array[0] == "PS_Milestone") {
							contentType = "task";
						}
						else if (url_array[0] == "PS_Step") {
							contentType = "step";
						}
						//this.router.navigate([url += "/workflowviewer?id=" + url_array[1] + "&contentType=" + contentType]);
                        let full_url = url += "/workflowviewer?id=" + url_array[1] + "&contentType=" + contentType;
						this.controlService.closePopUps();
						window.location.href = full_url;
					}

					event.preventDefault();
				}
				else if (v_Href.toLowerCase().startsWith('https://portal.pinnacleseries.com')) {
					event.preventDefault();
					window.location.href = elem.getAttribute('href');
                }
			});
		});
    }


	ngOnDestroy() {
		this.listenClickFunc;
    }
}
