import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Content, ContentType_T, CheatSheet, DescriptionFilter_T, ContentProduct, ContentAuthoring } from './../../apiService/classFiles/class.content';
import { Workgroup, WorkgroupMember, PermissionLevel_T } from './../../apiService/classFiles/class.workgroups';
import { ProdGenApi } from './../../apiService/prodgen.api';
import { SearchComponent } from './../../search/search.component';


declare var $: any;

@Component({
  selector: 'template-asset-titlebar',
  templateUrl: './asset-titlebar.component.html',
  styleUrls: ['./asset-titlebar.component.css']
})
export class AssetTitlebarComponent implements OnInit {
    
  @Input('contentItem') item: Content;
  @Input('usedProducts') usedProducts: Array<string> = new Array<string>();
  @Input('contentAuthoring') itemAuthoring: ContentAuthoring;
  @Output('updatedCheat') uCheat = new EventEmitter<CheatSheet>();
  searchTerms: string = "";
  editorWorkgroups: Array<Workgroup> = new Array<Workgroup>();

  cheatsheet = new CheatSheet();

  Editid: string = "";
  Editname: string = "";
  Editdesc: string = "";
  wWidth: number;
  Editprods: Array<ContentProduct> = new Array<ContentProduct>();
  isNew: boolean = true;
    modalVisible = false;

    isExternal: boolean = false;

    constructor(private pinnacleService: ProdGenApi, private router: Router) { 
        this.pinnacleService.getCurrentUserWorkgroups([PermissionLevel_T.owner, PermissionLevel_T.editor]).subscribe(res => {
            this.editorWorkgroups = res;
        });
  }

    ngOnInit() {
        if (this.item.bisDownloadable) {  }
        else { }

        this.isExternal = ProdGenApi.GetUserIsExternal();
        this.checkWindowWidth();


  }

  onSearch(): void {
    let url: string = "#/";
    
      if (this.searchTerms.trim().length > 0) {
          url += "search?searchTerms=" + SearchComponent.encodeSearchParameters(this.searchTerms.trim());
          //this.router.navigateByUrl(url);
          window.location.href = url;
      }
  }

  onKeySearchPress(event: any) {
      if (event.keyCode == 13) {
          this.onSearch();
      }
  }
  onKeySearchInput(event: any) {
      if (event.data == null && event.composed != null && event.composed == false) {
          this.onSearch();
      }
  }

  getContentImage(r: Content): string {
      let image: string = "";
      
      image = "assets/images/";
      if (r.bisCustom == false || r.bisCustom == null) {
          if (r.contentType == ContentType_T.cheatsheet && r.docURL != null && r.docURL != "") {
              if (r.docURL.includes(".pdf")) {
                  image += "PDF Graphic.png"
              }
              else if (r.docURL.includes(".doc")) {
                  image += "Word Graphic.png"
              }
              else if (r.docURL.includes(".xls")) {
                  image += "Excel Graphic.png"
              }
              else if (r.docURL.includes(".ppt")) {
                  image += "PowerPoint Graphic.png"
              }
              else if (r.docURL.includes(".htm")) {
                  image += "HTML Graphic.png"
              }
          }
          else {
              image += ProdGenApi.getLargeDefaultImageFromType(r.contentType);
              if (image.indexOf("default") == -1) {
                  image = image.replace("assets/images/", "");
              }
          }
      }
      else {
          try {
              image = localStorage.getItem(ProdGenApi.getCurrentTenantId + "_MyDocsImage");
          } catch (e) {

          }
          if (image == "" || image == null) {
              image = "assets/images/My Cheat Sheets Graphic.png";
          }
      }
      

      return image;
  }

  EditCheat(r: Content) {
    this.Editid = r.contentId.toString();
    this.Editname = r.name.toString();
    this.Editdesc = r.description.toString();
    //this.Editdesc = this.FormatHTMLDesc(r.description.toString());
    this.isNew = false;
    this.pinnacleService.GetContentUsedProducts(r.contentId, ContentType_T.cheatsheet.toString()).subscribe(r => {
      this.Editprods = r;
      this.modalVisible = true;

      setTimeout(function () {
        $('#newcheat').modal('show');
      }, 1);

    });

    }

    onResize(event) {
        this.checkWindowWidth();
    }
    checkWindowWidth() {
        this.wWidth = window.innerWidth;
        if (this.wWidth < 768) {
            $('#asset-titlebar-searchbar').removeClass('in');
            $('#asset-titlebar-searchbar').addClass('collapse');
        }
        else {
            $('#asset-titlebar-searchbar').addClass('in');
        }
    }

  onClose(event: any) {
    
    this.Editid = "";
    this.Editname = "";
    this.Editdesc = "";
    this.isNew = true;
    this.Editprods = new Array<ContentProduct>();
    this.modalVisible = false;
    
    if (event == "Save") {
      this.pinnacleService.GetCheatSheet(this.item.contentId, DescriptionFilter_T.formatted).subscribe(c => {

        this.uCheat.emit(c);
        
      });
    }
  }
}
