import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { ProdGenApi } from './../../apiService/prodgen.api';
import { Content, ContentType_T } from './../../apiService/classFiles/class.content';
import { Subscription } from './../../apiService/classFiles/class.partners';
import { User, PinnacleLiteUser } from './../../apiService/classFiles/class.users';
import { DomSanitizer } from '@angular/platform-browser';
import { AppControlService, MsgBxStringVals } from './../../AppControlService';
import { Organization } from '../../apiService/classFiles/class.organizations';

declare var $: any;

@Component({
    selector: 'template-pinnaclelite_newuser',
    templateUrl: './pinnaclelite-newuser.component.html',
    styleUrls:  ['./pinnaclelite-newuser.component.css']
})

export class PinnacleLiteNewUserComponent implements OnInit {
    @Output('userCreated') userCreated?= new EventEmitter<any>();
    @Output('userModified') userModified?= new EventEmitter<any>();
    @Input('modifyUser') modifyUser = new PinnacleLiteUser();
    static staticThis: PinnacleLiteNewUserComponent;
    isModify: boolean = false;
    emailInstructions: boolean = false;


    
  model: any = {
      userName: "",
      userEmail: "",
      isAdmin: false
  };
  
    constructor(private pinnacleService: ProdGenApi, private sanitizer: DomSanitizer, private controlService: AppControlService) {
        PinnacleLiteNewUserComponent.staticThis = this;
    }

    ngOnChanges(changes: SimpleChanges) {
		this.loadData();
    }

    

    ngOnInit() {
        this.loadData();

        $('#newPLUser').on('show.bs.modal', function (event) {
            PinnacleLiteNewUserComponent.staticThis.onShowDialog();
        })
    }

    onShowDialog() {
        $('#userName').val(this.model.userName);
        $('#userEmail').val(this.model.userEmail);
        $('#isAdmin').val(this.model.isAdmin);
    }

    loadData()
    {
        // lookup the tenant information
        //this.pinnacleService.partner
        if (this.modifyUser != null && this.modifyUser.userId != null && this.modifyUser.userId.length > 0) {
            // we are a modify instead of a new
            this.isModify = true;
            this.emailInstructions = false;
            this.model.userName = this.modifyUser.userName;
            this.model.userEmail = this.modifyUser.userEmail
            this.model.isAdmin = this.modifyUser.isAdmin;
        }
        else {
            this.model.userName = "";
            this.model.userEmail = "";
            this.model.isAdmin = false;
            this.emailInstructions = false;
        }
    }


    onSubmit() {

        let email = this.model.userEmail as string;
        if (this.model.userName == '') {
            alert('Please fill in the required field \'User Name\'.');
            return;
        }
        else if (this.model.userEmail == '') {
            alert('Please fill in the required field \'Email\'.');
            return;
        }
        else if (email.includes('@') == false) {
            alert('Please specify a valid \'Email\'.');
            return;
        }

        if (this.isModify == false) {
            // new user
            this.pinnacleService.addPinnacleLiteUser(this.model.userName, this.model.userEmail, this.model.isAdmin).subscribe(res => {
                if (res.userId == ProdGenApi.EMPTY_GUID) {

                    var v_Msg = new MsgBxStringVals();
                    v_Msg.body = "There was an error creating the user. This is usually caused by the email address already being in use.";
                    v_Msg.title = ``;
                    this.controlService.openMessageBoxPopUp(v_Msg);
                    
                    console.log("Error creating the user - returned empty user");
                }
                else {
                    if (this.emailInstructions == true) {
                        // send the email
                        this.pinnacleService.sendEmailToPinnacleLiteUser(res.userId).subscribe();
                    }

                    $("#newUser").modal("hide");

                    this.userCreated.emit({ newUser: res });
                }
            },
                err => {

                    var v_Msg = new MsgBxStringVals();
                    v_Msg.body = "There was an error creating the user. This is usually caused by the email address already being in use.";
                    v_Msg.title = ``;
                    this.controlService.openMessageBoxPopUp(v_Msg);
                    
                });
        }
        else {
            // modify
            this.pinnacleService.modifyPinnacleLiteUser(this.modifyUser.userId, this.model.userName, this.model.userEmail, this.model.isAdmin).subscribe(res => {
                $("#modifyUser").modal("hide");

                this.userModified.emit({ newUser: res });
            }, err => {

                var v_Msg = new MsgBxStringVals();
                v_Msg.body = "There was an error creating the user. This is usually caused by the email address already being in use.";
                v_Msg.title = ``;
                this.controlService.openMessageBoxPopUp(v_Msg);
                
            });
        }

    }
}
