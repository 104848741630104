import { Component, OnInit, Input, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Content, ContentType_T, DescriptionFilter_T } from './../../apiService/classFiles/class.content';
import { ProdGenApi } from './../../apiService/prodgen.api';

@Component({
  selector: 'template-relatedcourses',
  templateUrl: './relatedcourses.component.html',
  styleUrls: ['./relatedcourses.component.css'],
    providers: [ProdGenApi],
})
export class RelatedCoursesComponent implements OnInit {

    @Input('relatedContent') relatedContent: Array<Content>;

    constructor(private pinnacleService: ProdGenApi, private route: ActivatedRoute, private router: Router)
    {
    }

    ngOnInit() {
        this.getRelatedContent();
    }

    ngOnChanges(changes: SimpleChanges) {


        try { this.relatedContent = changes['relatedContent'].currentValue } catch (err) { };

    }

    getRelatedContent()
    {
    }


    getContentUrl(r: Content): string {
        let url: string = "#";
        if (r.contentType == ContentType_T.workflow) {
            url += "/workflowviewer?id=" + r.contentId + "&contentType=workflow";
        }
        else if (r.contentType == ContentType_T.process) {
            url += "/workflowviewer?id=" + r.contentId + "&contentType=process";
        }
        else if (r.contentType == ContentType_T.task) {
            url += "/workflowviewer?id=" + r.contentId + "&contentType=task";
        }
        else if (r.contentType == ContentType_T.step) {
            url += "/workflowviewer?id=" + r.contentId + "&contentType=step";
        }
        else if (r.contentType == ContentType_T.cheatsheet) {
            url += "/cheatsheetviewer?id=" + r.contentId;
        }
        else if (r.contentType == ContentType_T.video) {
            url += "/videoviewer?id=" + r.contentId;
        }
        else if (r.contentType == ContentType_T.learningpath) {
            url += "/learningcenter/series?learningPathId=" + r.contentId;
        }
        else if (r.contentType == ContentType_T.course) {
            url += "/learningcenter/series?courseId=" + r.contentId;
        }
        return url;
    }


    getContentImage(r: Content): string {
        let image: string = "";

        image = "assets/images/";

        let img = ProdGenApi.getLargeDefaultImageFromType(r.contentType);

        if (img.indexOf("default") == -1) {
            image = img;
        }
        else {
            image += img;

        }


        return image;
    }

}
