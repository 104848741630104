import { ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild, ViewRef } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import cssVars from 'css-vars-ponyfill';
import { Apiv2Service } from './apiService/apiv2.service';
import { ChatSettings } from './apiService/classFiles/class.chat';
import { Setting, Tenant, TenantWithLogo, Theme } from './apiService/classFiles/class.organizations';
import { Partner } from './apiService/classFiles/class.partners';
import { User } from './apiService/classFiles/class.users';
import { KnowledgeSmartConfiguration } from './apiService/classFiles/v2-organizations';
import { SharedService } from './apiService/loginservice.service';
import { ProdGenApi } from './apiService/prodgen.api';
import { AppControlService } from './AppControlService';
import { AutoplayVidService } from './AutoplayVidService';
import { BrowserAuthenticationService } from './BrowserAuthenticationService';
import { ChatComponent } from './chat/chat.component';
import { HomeComponent } from './home/home.component';
import { LearningpageoutletComponent } from './learningpageoutlet/learningpageoutlet.component';
import { SearchComponent } from './search/search.component';
import { BrandingUpdateServiceService } from './services/branding-update-service.service';
import { DiagramTreeService } from './services/diagram-tree.service';
import { LoadedStorageService } from './services/LoadedStorageService';
import { SessionTimeoutService } from './services/session-timeout.service';
import { TranslationService } from './services/TranslationService';
import { WidgetLibraryService } from './services/widget-library.service';
import { ChangePasswordComponent } from './templates/changepassword/changepassword.component';
import { KSAssessmentNavbarComponent } from './templates/ks-assessment-navbar/ks-assessment-navbar.component';
import { WidgetContainerComponent } from './templates/widget-container/widget-container.component';
import { Location } from '@angular/common';

declare var $: any;
declare var AblePlayer: any;


declare function doesFontExist(fontName: string): boolean;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    providers: [ProdGenApi, SharedService, WidgetLibraryService, AppControlService, BrowserAuthenticationService, TranslationService, AutoplayVidService, DiagramTreeService, BrandingUpdateServiceService, LoadedStorageService],
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{

    @HostListener('click')
    onClick() {
        if (this.minutesUntilTimeout) {
            this.timeoutService.start();
        }
    }

    @HostListener('keydown')
    onKeyDown() {
        if (this.minutesUntilTimeout) {
            this.timeoutService.start();
        }
    }

    wheelEventTimeout: number | null = null;
    @HostListener('document:wheel', ['$event.target'])
    wheelScroll(): void {
        if (!this.minutesUntilTimeout) return;

        this.timeoutService.start();

        this.wheelEventTimeout = window.requestAnimationFrame(() => {
            if (this.minutesUntilTimeout) {
                this.timeoutService.start();
            }
        });

    }

    scrollEventTimeout: number | null = null;
    @HostListener('window:scroll')
    onScroll() {
        if (!this.minutesUntilTimeout) return;

        // Because scrolling will generate a large
        // number of events, we want to debounce the
        // call to restart the session timeout.
        // This approach resets it at most once per
        // animation frame which is about as slow as I
        // know how to get without another timer...
        if (this.scrollEventTimeout) {
            window.cancelAnimationFrame(this.scrollEventTimeout);
        }

        this.scrollEventTimeout = window.requestAnimationFrame(() => {
            if (this.minutesUntilTimeout) {
                this.timeoutService.start();
            }
        });
    }

    @HostListener('playing')
    onPlaying() {
        this.timeoutService.videoPlaying = true;
        this.timeoutService.stop();
    }

    @HostListener('pause')
    onPaused() {
        this.timeoutService.videoPlaying = false;
        if (this.minutesUntilTimeout) {
            this.timeoutService.start();
        }
    }

    @HostListener('ended')
    onEnded() {
        this.timeoutService.videoPlaying = false;
        if (this.minutesUntilTimeout) {
            this.timeoutService.start();
        }
    }


    @ViewChild('navbarToggler', { static: false }) navbarToggler: ElementRef;
    @ViewChild('chatControl', { static: false }) chatControl: ChatComponent;
    @ViewChild('FlyoutContainer', { static: false }) flyoutContainer: WidgetContainerComponent;
    @ViewChild('ksNavbar', { static: false }) ksNavbar: KSAssessmentNavbarComponent;
    @ViewChild(ChangePasswordComponent, { static: false })
    private passwordComponent: ChangePasswordComponent;
    public static isPinnacleLite: boolean = false;

    isPinnacleLiteLocal: boolean = false;
    pinnacleLiteHasWorkgroups: boolean = false;
    AdminPortalHome: boolean = false;       //flag to indicate if home page or records management page should be opened in management portal

    isExternal: boolean = false;
    isPeakExperience: boolean = false;

    bUseWidgets: boolean = false;
    isWidgetsDetermined: boolean = false;
    // when isLoggedIn = true. It shows certain icons in the upper right of the header.
    isAdministrator: boolean = false;
    isBrandingManager: boolean = false;
    isLoggedIn: boolean = false;
    isChatSettingsLoaded: boolean = false;
    companyLogo: string = "";
    partnerLogo: string = "";
    companyLogoNavigateURL: string = "";
    URLTarget: string = "_blank";
    sellingPartner: Partner = new Partner();
    chatSettings: ChatSettings = new ChatSettings();
    currentUser: User = new User();

    uniqueImageQuery: number = Date.now();

    modalCheatSheetId: string = "";
    modalCheatSheetEnrollmentId: string = "";

    modalVideoId: string = "";
    modalVideoEnrollmentId: string = "";

    modalVidLoaded = false;
    modalVideoOpenedCount: number = 0;

    messageBoxMessage: string = "";
    messageBoxTitle: string = "";

    chatSessionId: string = "";

    currentRoute: string = "";
    isWindows: boolean = false;
    managementFrame: HTMLIFrameElement;
    canSellPinnacleLite: boolean = false;
    isAdmin: boolean = false;
    isContentAdministrator: boolean = false;
    isContentPublisher: boolean = false;
    isContentAuthor: boolean = false;
    isEPSupportDisabled: boolean = true;

    selectedOption: string = "";

    static thisFromOutside: AppComponent = null;
    previewtheme = defpreviewtheme;

    isExternalPage: boolean = false;

    static panelBodyBackgroundColor: string = ""; // Used on external Live Event Registration page
    static panelBodyForegroundColor: string = ""; // Used in StarRatingComponent
    static pageBackgroundColor: string = ""; // Used in StarRatingComponent

    Flyoutopen: boolean = false;
    innerWidth: number = 0;
    isFlyoutVisible: boolean = true;

    isLogoVisible: boolean = true;

    flyoutTitle: string = "More";

    KSConfigInfo: KnowledgeSmartConfiguration = new KnowledgeSmartConfiguration();
    minutesUntilTimeout: number = 0;

    isTenantDropdownPrimary: boolean = true;
    currentTenant: Tenant = new Tenant();
    tenants: Array<Tenant> = new Array<Tenant>();
    tenantsWithLogo: Array<TenantWithLogo> = new Array<TenantWithLogo>();
    destinationTenant: TenantWithLogo = new TenantWithLogo();
    isSwitching: boolean = false;
    isAssignor: boolean = false;
    isAssignmentAdmin: boolean = false;

    constructor(private pinnacleService: ProdGenApi, private APIv2: Apiv2Service,
        private router: Router,
        private _sharedService: SharedService,
        private controlService: AppControlService,
        private authenticationService: BrowserAuthenticationService,
        private brandingsvc: BrandingUpdateServiceService,
        private changedetector: ChangeDetectorRef,
        private translate: TranslationService,
        private timeoutService: SessionTimeoutService,
        private location: Location
    ) {

        AppComponent.thisFromOutside = this;
        if (window.location.href.includes("liveeventsurvey") || window.location.href.includes("liveeventregistration")) {
            this.isExternalPage = true;
        }

        try {
            this.bUseWidgets = localStorage.getItem("usewidgets" + ProdGenApi.getCurrentTenantId()).toString().toLowerCase() == 'true';
        } catch (e) {
            this.bUseWidgets = false;
        }
        //else {

        this.brandingsvc.getLogoChangeEmitter().subscribe(res => {
            this.companyLogo = res;
        });
        this.brandingsvc.courseimgemitter.subscribe(res => { localStorage.setItem(ProdGenApi.getCurrentTenantId + "_CourseImage", res); });
        this.brandingsvc.docimgemitter.subscribe(res => { localStorage.setItem(ProdGenApi.getCurrentTenantId + "_DocsImage", res); });
        this.brandingsvc.lpimageemitter.subscribe(res => { localStorage.setItem(ProdGenApi.getCurrentTenantId + "_LPImage", res); });
        this.brandingsvc.mydocimgemitter.subscribe(res => { localStorage.setItem(ProdGenApi.getCurrentTenantId + "_MyDocsImage", res); });
        this.brandingsvc.processimgemitter.subscribe(res => { localStorage.setItem(ProdGenApi.getCurrentTenantId + "_ProcessImage", res); });
        this.brandingsvc.quizimgemitter.subscribe(res => { localStorage.setItem(ProdGenApi.getCurrentTenantId + "_QuizImage", res); });
        this.brandingsvc.vidimgemitter.subscribe(res => { localStorage.setItem(ProdGenApi.getCurrentTenantId + "_VidsImage", res); });
        this.brandingsvc.wfimageemitter.subscribe(res => { localStorage.setItem(ProdGenApi.getCurrentTenantId + "_WFImage", res); });
        this.brandingsvc.defworkgroupemitter.subscribe(res => { localStorage.setItem(ProdGenApi.getCurrentTenantId + "_defWorkgroupImage", res); });

        $(document).on('show.bs.modal', '#cheatsheetDialog', function () {
            $('.modal .modal-body').css('overflow-y', 'auto');
            $('.modal .modal-body').css('max-height', ($(window).height() - 160) * 0.85);
        });
        //video modal show listener
        $(function () {
            $(document).on('show.bs.modal', '#videoDialog', function () {
                if (!AppComponent.thisFromOutside.modalVidLoaded) {
                    AppComponent.thisFromOutside.modalVidLoaded = true;
                    $('#videoDialog').on('hidden.bs.modal', function () {
                        if (AblePlayer != null && AblePlayer.lastCreated != null) {
                            AblePlayer.lastCreated.pauseMedia();
                        }
                    });
                }
            });
        });

        //change password show listener
        $(function () {
            $(document).on('show.bs.modal', '#changeUserPassword', function () {
                AppComponent.thisFromOutside.passwordComponent.loadData();
            });
        });

        //change password hidden listener
        $(function () {
            $(document).on('hidden.bs.modal', '#changeUserPassword', function () {
                if (AppComponent.thisFromOutside.currentRoute.lastIndexOf("login") == -1) {
                    AppComponent.thisFromOutside.passwordComponent.resetKeys();
                }
                else {
                    if (AppComponent.thisFromOutside.passwordComponent.passwordChangedSuccessfully() == true) {
                        AppComponent.thisFromOutside.passwordComponent.resetKeys();
                        let v_Email = ProdGenApi.getSessionUser().email;

                        AppComponent.thisFromOutside._sharedService.emitChange(v_Email);
                        let v_URL = localStorage.getItem("LoginURL");
                        if (v_URL == null || v_URL == "") {
                            AppComponent.thisFromOutside.router.navigate(['/home']);
                        }
                        else {

                            window.location.href = v_URL;
                            window.location.reload();
                            localStorage.setItem("LoginURL", "");
                        }
                    }
                }
            });
        });



        controlService.cheatSheetPopupID$.subscribe(cheatsheetID => {

            $('#videoDialog').modal('hide');
            $('#cheatsheetDialog').on('hidden.bs.modal', this.cheatModalHiddenCompleted(cheatsheetID));
        });



        controlService.videoPopupID$.subscribe(videoID => {

            $('#cheatsheetDialog').modal('hide');
            this.modalVideoId = videoID;
            this.modalVideoOpenedCount++;
            $('#videoDialog').modal('show');
        });

        controlService.closePopUps$.subscribe(val => {
            $('#videoDialog').modal('hide');
            $('#cheatsheetDialog').modal('hide');
        });

        controlService.messageBoxPopup$.subscribe(msg => {
            this.messageBoxMessage = msg.body;
            this.messageBoxTitle = msg.title;
            $('#messageBoxDialog').modal('show');
        });
        //}

        // we will create a listener for whenever we launch an admin utility from this tab
        // format of the token that is sent to Management Portal is the bearerToken, then language and lastly a new indicator
        // for the default page. The default page is only sent when the Management Portal is being invoked from the notifications
        // modules for a external learning record. The AdminPortalFlag should only be set in launchAdministrationPortal in this module
        // for when portal is invoked from the Gear cog in menu of pinnacle browser.
        window.addEventListener("message", (event) => {
            if (this.canAcceptMessage(event.origin)) {
                if (event.data == "administration_logged_out") {
                    console.log("Admin Browser has timed out or user logged out, don't send apiV2BearerToken on referesh");
                }
                else if (event.data == "administration_opened") {
                    // post the v2 access key to the newly opened window to give it access to the tenant
                    let token = "apiV2BearerToken:" + JSON.stringify(ProdGenApi.getAPIV2AccessKey());
                    token += "|languageCode:" + this.pinnacleService.getCurrentLanguage();
                    if (this.AdminPortalHome != true) {
                        token += "|defaultPage:" + "RecordsManagement";
                    }
                    this.AdminPortalHome = false;

                    // https://stackoverflow.com/questions/53064444/typescript-complains-when-i-try-to-postmessage-back-to-source
                    let w: Window = event.source as Window;

                    w.postMessage(token, "*");
                }

                else if (event.data == "peak_opened") {
                    // post the v2 access key to the newly opened window to give it access to the tenant
                    let token = "param|:" + ProdGenApi.getUserAccessKey();
                    token += "param|:" + JSON.stringify(ProdGenApi.getAPIV2AccessKey());
                    token += "param|:" + this.pinnacleService.getCurrentLanguage();

                    // https://stackoverflow.com/questions/53064444/typescript-complains-when-i-try-to-postmessage-back-to-source
                    let w: Window = event.source as Window;

                    w.postMessage(token, "*");
                }
            }
            // our window has received a notification, confirm it is from the admin utility
        }, false);

    }

    get canLaunchAdminPortal(): boolean {
        const adminPortalAccessUserPermissions = new Array<boolean>(
            this.isAdmin,
            this.isAssignor,
            this.isAssignmentAdmin,
            this.isContentAdministrator,
            this.isContentPublisher,
            this.isContentAuthor,
            this.isBrandingManager
        );
        return this.isLoggedIn == true
            && adminPortalAccessUserPermissions.some(permission => permission === true)
            && this.isPinnacleLiteLocal == false;
    }

    // use this method to verify the message received is from expected domain
    private canAcceptMessage(host: string): boolean {
        if (host == "http://localhost:4200" ||
            host == "http://localhost:4201" ||
            host == "http://localhost:4202" ||
            host == "https://prodgenbrowser-p1-2022.azurewebsites.net" ||
            host == "https://prodgenbrowser-pw.azurewebsites.net" ||
            host == "https://prodgenbrowser-indev.azurewebsites.net" ||
            host == "https://portal.pinnacleseries.com" ||
            host == "https://portalbeta.pinnacleseries.com" ||
            host == "https://portaldev.pinnacleseries.com" ||
            host == "https://productivitynow.imaginit.com" ||
            host == "https://productivitynow.ascented.com" ||
            host == "https://productivitynow.rand3d.com") {

            return true;
        }
        return false;
    }


    cheatModalHiddenCompleted(cheatID: string) {
        this.modalCheatSheetId = cheatID;
        $('#cheatsheetDialog').off('hidden.bs.modal');
        $('#cheatsheetDialog').modal('show');

    }

    videoModalHiddenCompleted(videoID: string) {
        this.modalVideoId = videoID;
        $('#videoDialog').off('hidden.bs.modal');
        $('#videoDialog').modal('show');

    }

    ngOnInit() {
        //if "signout" is found in the params, that means we're redirecting from peak via sign out
        //to the legacy sign on page
        const search = this.location.path().split('?')[1] || '';
        const queryParams = search.split("&").reduce((params, param) => {
          const [key, value] = param.split("=");
          params[key] = value;
          return params;
        }, {});
        
        if (queryParams['signout']) {
            this.logOut();
        }

        //if (!this.isExternalPage) {
        let appliedtheme = new Theme();
        try {
            appliedtheme = JSON.parse(localStorage.getItem("AppliedTheme" + localStorage.getItem("lastUsedTenant")));

            if (appliedtheme.themeID != "00000000-0000-0000-0000-000000000000") {

                Object.keys(this.previewtheme.properties).forEach(property => {
                    if (property.indexOf("width") != -1 || property.indexOf("radius") != -1) {
                        //this.previewtheme.properties[property] = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => "--preview-" + x.itemName.replace(/_/g, "-") == property)].itemValue + "px";
                        this.previewtheme.properties[property] = appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                            function (e) {
                                return "--preview-" + e.itemName.replace(/_/g, "-")
                            }).indexOf(property)].itemValue + "px";

                    }
                    else if (property.indexOf("font-face") != -1) {
                        //this.previewtheme.properties[property] = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => "--preview-" + x.itemName.replace(/_/g, "-") == property)].itemValue;
                        this.previewtheme.properties[property] = appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                            function (e) {
                                return "--preview-" + e.itemName.replace(/_/g, "-")
                            }).indexOf(property)].itemValue;

                    }
                    else if (property.indexOf("themeid") == -1 && property.indexOf("tenantid") == -1 && property.indexOf("is_public") == -1 && property.indexOf("name") == -1) {
                        //this.previewtheme.properties[property] = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => "--preview-" + x.itemName.replace(/_/g, "-") == property)].itemValue;
                        this.previewtheme.properties[property] = appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                            function (e) {
                                return "--preview-" + e.itemName.replace(/_/g, "-")
                            }).indexOf(property)].itemValue;

                    }

                    if (property.indexOf("themeid") == -1 && property.indexOf("tenantid") == -1 && property.indexOf("is_public") == -1 && property.indexOf("name") == -1) {
                        document.documentElement.style.setProperty(
                            property,
                            this.previewtheme.properties[property]
                        );
                    }
                });

                Object.keys(currenttheme.properties).forEach(property => {
                    if (property.indexOf("width") != -1 || property.indexOf("radius") != -1) {
                        //this.previewtheme.properties[property] = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => "--preview-" + x.itemName.replace(/_/g, "-") == property)].itemValue + "px";
                        currenttheme.properties[property] = appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                            function (e) {
                                return "--" + e.itemName.replace(/_/g, "-")
                            }).indexOf(property)].itemValue + "px";

                    }
                    else if (property.indexOf("font-face") != -1) {
                        //this.previewtheme.properties[property] = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => "--preview-" + x.itemName.replace(/_/g, "-") == property)].itemValue;
                        currenttheme.properties[property] = appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                            function (e) {
                                return "--" + e.itemName.replace(/_/g, "-")
                            }).indexOf(property)].itemValue;

                    }
                    else if (property.indexOf("themeid") == -1 && property.indexOf("tenantid") == -1 && property.indexOf("is_public") == -1 && property.indexOf("name") == -1) {
                        //this.previewtheme.properties[property] = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => "--preview-" + x.itemName.replace(/_/g, "-") == property)].itemValue;
                        currenttheme.properties[property] = appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                            function (e) {
                                return "--" + e.itemName.replace(/_/g, "-")
                            }).indexOf(property)].itemValue;

                    }

                    if (property.indexOf("themeid") == -1 && property.indexOf("tenantid") == -1 && property.indexOf("is_public") == -1 && property.indexOf("name") == -1) {
                        document.documentElement.style.setProperty(
                            property,
                            currenttheme.properties[property] 
                        );
                    }
                });
                localStorage.setItem(sessionStorage.getItem("currentTenant") + "_DocsImage", appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                    function (e) {
                        return e.itemName
                    }).indexOf("document_image")].itemValue);
                localStorage.setItem(sessionStorage.getItem("currentTenant") + "_MyDocsImage", appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                    function (e) {
                        return e.itemName
                    }).indexOf("my_document_image")].itemValue);
                localStorage.setItem(sessionStorage.getItem("currentTenant") + "_VidsImage", appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                    function (e) {
                        return e.itemName
                    }).indexOf("video_image")].itemValue);
                localStorage.setItem(sessionStorage.getItem("currentTenant") + "_CourseImage", appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                    function (e) {
                        return e.itemName
                    }).indexOf("course_image")].itemValue);
                localStorage.setItem(sessionStorage.getItem("currentTenant") + "_LPImage", appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                    function (e) {
                        return e.itemName
                    }).indexOf("learning_path_image")].itemValue);
                localStorage.setItem(sessionStorage.getItem("currentTenant") + "_WFImage", appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                    function (e) {
                        return e.itemName
                    }).indexOf("workflow_image")].itemValue);
                localStorage.setItem(sessionStorage.getItem("currentTenant") + "_ProcessImage", appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                    function (e) {
                        return e.itemName
                    }).indexOf("process_image")].itemValue);
                localStorage.setItem(sessionStorage.getItem("currentTenant") + "_QuizImage", appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                    function (e) {
                        return e.itemName
                    }).indexOf("quiz_image")].itemValue);
                localStorage.setItem(sessionStorage.getItem("currentTenant") + "_defWorkgroupImage", appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                    function (e) {
                        return e.itemName
                    }).indexOf("workgroup_image")].itemValue);
                localStorage.setItem(sessionStorage.getItem("currentTenant") + "_EnrollmentImage", appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                    function (e) {
                        return e.itemName
                    }).indexOf("enrollment_image")].itemValue);
                cssVars({
                    rootElement: document,
                    variables: {
                        "--preview-font-face": this.previewtheme.properties["--preview-font-face"],
                        "--preview-page-background-color": this.previewtheme.properties["--preview-page-background-color"],
                        "--preview-page-text-color": this.previewtheme.properties["--preview-page-text-color"],
                        "--preview-page-separator-color": this.previewtheme.properties["--preview-page-separator-color"],
                        "--preview-page-separator-width": this.previewtheme.properties["--preview-page-separator-width"],
                        "--preview-navbar-background-color": this.previewtheme.properties["--preview-navbar-background-color"],
                        "--preview-navbar-foreground-color": this.previewtheme.properties["--preview-navbar-foreground-color"],
                        "--preview-hsl-background-color": this.previewtheme.properties["--preview-hsl-background-color"],
                        "--preview-hsl-foreground-color": this.previewtheme.properties["--preview-hsl-foreground-color"],
                        "--preview-hsl-border-color": this.previewtheme.properties["--preview-hsl-border-color"],
                        "--preview-hsl-border-width": this.previewtheme.properties["--preview-hsl-border-width"],
                        "--preview-hsr-background-color": this.previewtheme.properties["--preview-hsr-background-color"],
                        "--preview-hsr-foreground-color": this.previewtheme.properties["--preview-hsr-foreground-color"],
                        "--preview-hsr-border-color": this.previewtheme.properties["--preview-hsr-border-color"],
                        "--preview-hsr-border-width": this.previewtheme.properties["--preview-hsr-border-width"],
                        "--preview-large-card-background-color1": this.previewtheme.properties["--preview-large-card-background-color1"],
                        "--preview-large-card-foreground-color1": this.previewtheme.properties["--preview-large-card-foreground-color1"],
                        "--preview-large-card-background-color2": this.previewtheme.properties["--preview-large-card-background-color2"],
                        "--preview-large-card-foreground-color2": this.previewtheme.properties["--preview-large-card-foreground-color2"],
                        "--preview-large-card-background-color3": this.previewtheme.properties["--preview-large-card-background-color3"],
                        "--preview-large-card-foreground-color3": this.previewtheme.properties["--preview-large-card-foreground-color3"],
                        "--preview-large-card-border-color": this.previewtheme.properties["--preview-large-card-border-color"],
                        "--preview-large-card-border-width": this.previewtheme.properties["--preview-large-card-border-width"],
                        "--preview-large-card-border-radius": this.previewtheme.properties["--preview-large-card-border-radius"],
                        "--preview-small-card-background-color1": this.previewtheme.properties["--preview-small-card-background-color1"],
                        "--preview-small-card-foreground-color1": this.previewtheme.properties["--preview-small-card-foreground-color1"],
                        "--preview-small-card-background-color2": this.previewtheme.properties["--preview-small-card-background-color2"],
                        "--preview-small-card-foreground-color2": this.previewtheme.properties["--preview-small-card-foreground-color2"],
                        "--preview-small-card-background-color3": this.previewtheme.properties["--preview-small-card-background-color3"],
                        "--preview-small-card-foreground-color3": this.previewtheme.properties["--preview-small-card-foreground-color3"],
                        "--preview-small-card-border-color": this.previewtheme.properties["--preview-small-card-border-color"],
                        "--preview-small-card-border-width": this.previewtheme.properties["--preview-small-card-border-width"],
                        "--preview-small-card-border-radius": this.previewtheme.properties["--preview-small-card-border-radius"],
                        "--preview-panel-title-background-color": this.previewtheme.properties["--preview-panel-title-background-color"],
                        "--preview-panel-title-foreground-color": this.previewtheme.properties["--preview-panel-title-foreground-color"],
                        "--preview-panel-body-background-color": this.previewtheme.properties["--preview-panel-body-background-color"],
                        "--preview-panel-body-foreground-color": this.previewtheme.properties["--preview-panel-body-foreground-color"],
                        "--preview-panel-border-color": this.previewtheme.properties["--preview-panel-border-color"],
                        "--preview-panel-border-width": this.previewtheme.properties["--preview-panel-border-width"],
                        "--preview-panel-border-radius": this.previewtheme.properties["--preview-panel-border-radius"],
                        "--preview-button-background": this.previewtheme.properties["--preview-button-background"],
                        "--preview-button-foreground": this.previewtheme.properties["--preview-button-foreground"],

                        "--font-face": currenttheme.properties["--font-face"],
                        "--page-background-color": currenttheme.properties["--page-background-color"],
                        "--page-text-color": currenttheme.properties["--page-text-color"],
                        "--page-separator-color": currenttheme.properties["--page-separator-color"],
                        "--page-separator-width": currenttheme.properties["--page-separator-width"],
                        "--navbar-background-color": currenttheme.properties["--navbar-background-color"],
                        "--navbar-foreground-color": currenttheme.properties["--navbar-foreground-color"],
                        "--hsl-background-color": currenttheme.properties["--hsl-background-color"],
                        "--hsl-foreground-color": currenttheme.properties["--hsl-foreground-color"],
                        "--hsl-border-color": currenttheme.properties["--hsl-border-color"],
                        "--hsl-border-width": currenttheme.properties["--hsl-border-width"],
                        "--hsr-background-color": currenttheme.properties["--hsr-background-color"],
                        "--hsr-foreground-color": currenttheme.properties["--hsr-foreground-color"],
                        "--hsr-border-color": currenttheme.properties["--hsr-border-color"],
                        "--hsr-border-width": currenttheme.properties["--hsr-border-width"],
                        "--large-card-background-color1": currenttheme.properties["--large-card-background-color1"],
                        "--large-card-foreground-color1": currenttheme.properties["--large-card-foreground-color1"],
                        "--large-card-background-color2": currenttheme.properties["--large-card-background-color2"],
                        "--large-card-foreground-color2": currenttheme.properties["--large-card-foreground-color2"],
                        "--large-card-background-color3": currenttheme.properties["--large-card-background-color3"],
                        "--large-card-foreground-color3": currenttheme.properties["--large-card-foreground-color3"],
                        "--large-card-border-color": currenttheme.properties["--large-card-border-color"],
                        "--large-card-border-width": currenttheme.properties["--large-card-border-width"],
                        "--large-card-border-radius": currenttheme.properties["--large-card-border-radius"],
                        "--small-card-background-color1": currenttheme.properties["--small-card-background-color1"],
                        "--small-card-foreground-color1": currenttheme.properties["--small-card-foreground-color1"],
                        "--small-card-background-color2": currenttheme.properties["--small-card-background-color2"],
                        "--small-card-foreground-color2": currenttheme.properties["--small-card-foreground-color2"],
                        "--small-card-background-color3": currenttheme.properties["--small-card-background-color3"],
                        "--small-card-foreground-color3": currenttheme.properties["--small-card-foreground-color3"],
                        "--small-card-border-color": currenttheme.properties["--small-card-border-color"],
                        "--small-card-border-width": currenttheme.properties["--small-card-border-width"],
                        "--small-card-border-radius": currenttheme.properties["--small-card-border-radius"],
                        "--panel-title-background-color": currenttheme.properties["--panel-title-background-color"],
                        "--panel-title-foreground-color": currenttheme.properties["--panel-title-foreground-color"],
                        "--panel-body-background-color": currenttheme.properties["--panel-body-background-color"],
                        "--panel-body-foreground-color": currenttheme.properties["--panel-body-foreground-color"],
                        "--panel-border-color": currenttheme.properties["--panel-border-color"],
                        "--panel-border-width": currenttheme.properties["--panel-border-width"],
                        "--panel-border-radius": currenttheme.properties["--panel-border-radius"],
                        "--button-background": currenttheme.properties["--button-background"],
                        "--button-foreground": currenttheme.properties["--button-foreground"]
                    }
                });
            }
        } catch (e) {

        }

        // If both of these conditions are met, that means that the user is authenticated.
        if (sessionStorage.getItem("APIKey") && sessionStorage.getItem("userAccessKey") && sessionStorage.getItem("apiV2AccessKey")) {
            this.isLoggedIn = true;
            // In case the user refreshes the page, the above _sharedService doesn't work. So let's grab the email. One can save a call if we save email in sessionStorage.
            this.loadSettings();

            sessionStorage.removeItem('sessionLoggedOut');//If the sessionLoggedOut sessionStorage is still out there from prior time out, clear it on successful sign into Pinnacle browser.

            // Restart the timer when the page is refreshed.
            this.timeoutService.loggedOut = false;
            this.timeoutService.start();

            let v_ChatSessionAvailable = sessionStorage.getItem("ChatActive");
            if (v_ChatSessionAvailable != null && v_ChatSessionAvailable == "true") {
                let v_chatID = sessionStorage.getItem("ChatID");
                if (v_chatID != null && v_chatID != "") {
                    this.chatSessionId = v_chatID;
                }
            }
        }


        // Once user logs in from /login page, this transfer to email to be shown up in the header through a service.
        this._sharedService.changeEmitted$.subscribe(
            text => {
                this.isLoggedIn = true;

                this.loadSettings();
            }
        );
        
        if (window.navigator.userAgent.indexOf("Windows ") != -1) {
            this.isWindows = true;
        }

        this.onResize(null);
        //}

        //If the user has not logged in, wait for the
        //tenant identifier from logging in before starting
        //the inactivity timer. This will allow us to use the
        //tenant-specific value if that gets requested.
        this._sharedService.v2BearerToken$.subscribe(() => {
            if (this.timeoutService.debugTimeout) console.log("*  in sharedService setting loggedout to false");  
            this.timeoutService.loggedOut = false;
            this.timeoutService.start();
        });

        this.timeoutService.minutesRemaining.subscribe(minutes => {
            this.minutesUntilTimeout = minutes;
            if (minutes === 5) {
                if (this.timeoutService.debugTimeout) console.log("*  minutes is equal to 5, show the session-timeout-modal");  
                $('#session-timeout-modal').modal('show');
            }
            if (minutes === 0) {
                $('#session-timeout-modal').modal('hide');
                this.logOut().then(() => {
                    setTimeout(() => {
                        $('#loginModal').css('visibility', 'hidden');
                        $('#session-timeout-notice-modal').modal('show');
                    }, 0);
                });
            }
        });

        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                //any navigation that happens should reset the tenant dropdown to go back to its default state. this event will only change UI elements, so we shouldnt have overhead
                this.showPrimary();
            }
        });
    }

    isExternalUser(): boolean {
        if (ProdGenApi.getCurrentTenantId() == '00000000-0000-0000-0000-000000000000') {
            return true;
        }
        return false;
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        setTimeout(() => {
            this.innerWidth = window.innerWidth;

            if (this.innerWidth > 992) {
                if (this.Flyoutopen == true) {
                    this.closeFlyout();
                    this.Flyoutopen = false;
                }
            }
        });

    }

    getRouterLink(): string {
        return this.router.url;
    }

    loadSettings() {
        SearchComponent.clearSavedResults();

        this.isExternal = ProdGenApi.GetUserIsExternal();
        this.pinnacleService.GetCurrentTenant().subscribe(res => {
            this.currentTenant = res;
        });

        this.pinnacleService.getSettingByName("USE_WIDGETS").subscribe(res => {
            let widgetSetting = res;
            if (widgetSetting) {
                this.bUseWidgets = widgetSetting.settingValue.toString().toLowerCase() == 'true';
            }
            else {
                this.bUseWidgets = false;
            }
            localStorage.setItem("usewidgets" + ProdGenApi.getCurrentTenantId(), this.bUseWidgets.toString());
            this.isWidgetsDetermined = true
        },
            err => { this.isWidgetsDetermined = true; }
        );
        this.pinnacleService.GetCurrentTenantPinnacleLiteSetting().subscribe(
            res => {
                AppComponent.isPinnacleLite = res.isPinnacleLite;
                this.isPinnacleLiteLocal = res.isPinnacleLite;

                ProdGenApi.isPinnacleLite = res.isPinnacleLite;

                try {
                    if (this.isPinnacleLiteLocal == true) {
                        this.companyLogo = JSON.parse(localStorage.getItem(ProdGenApi.getCurrentTenantId() + "_lite_logo"));

                        //load potential workgroups
                        this.pinnacleService.getCurrentUserWorkgroups().subscribe(res => {
                            if (res.length > 0) {
                                this.pinnacleLiteHasWorkgroups = true;
                            }
                        });


                    }
                    else {
                        this.isLogoVisible = JSON.parse(localStorage.getItem(ProdGenApi.getCurrentTenantId() + "_show_logo"));
                        this.companyLogo = JSON.parse(localStorage.getItem(ProdGenApi.getCurrentTenantId() + "_company_logo"));

                    }
                }
                catch (err) {
                    console.log(err)
                }

                // get the org image
                this.pinnacleService.GetCurrentTenantSettings().subscribe(s => {
                    let logoSetting: Setting = new Setting();
                    let showLogoSetting: Setting = new Setting();
                    let isPeak: Setting = new Setting();

                    // log the settings temporarily to verify
                    console.log(s);

                    logoSetting = s.find(v => v.name == "COMPANY_LOGO");
                    showLogoSetting = s.find(v => v.name == "HIDE_LOGO");
                    isPeak = s.find(v => v.name === "PEAK_USER_ENABLED");

                    if (isPeak) {
                        if (isPeak.settingValue == 1) {
                            //  this.isPeakExperience = true;
                            console.log("Peak Experience Enabled");

                        }
                        else {
                            console.log("Peak Experience NOT Enabled");

                        }
                    }


                    if (showLogoSetting) {
                        if (showLogoSetting.settingValue.toString().toLowerCase() == "true") {
                            this.isLogoVisible = true;
                        }
                        else {
                            this.isLogoVisible = false;
                        }
                    }
                    else {
                        this.isLogoVisible = true;
                    }
                    localStorage.setItem(ProdGenApi.getCurrentTenantId() + "_show_logo", JSON.stringify(this.isLogoVisible));

                    let logoNavigateURLSetting: Setting = s.find(l => l.name == "COMPANY_LOGO_NAVIGATE_URL");
                    if (logoNavigateURLSetting != null && logoNavigateURLSetting.settingValue.toString().trim() != "") {
                        this.companyLogoNavigateURL = logoNavigateURLSetting.settingValue.toString();
                        if (this.companyLogoNavigateURL.includes(window.location.hostname) == true) {
                            this.URLTarget = "_self";
                        }
                        else {
                            this.URLTarget = "_blank";
                        }
                    }
                    else {
                        this.companyLogoNavigateURL = "";
                    }

                    if (this.companyLogoNavigateURL == "") {
                        $("#company_link_url").attr('href', '').css({ 'cursor': 'default', 'pointer-events': 'none' });
                    }

                    if (logoSetting != null && logoSetting.settingValue.toString().trim() != "") {
                        this.companyLogo = logoSetting.settingValue as string;



                        try {
                            if (this.isPinnacleLiteLocal == true) {
                                localStorage.setItem(ProdGenApi.getCurrentTenantId() + "_lite_logo", JSON.stringify(this.companyLogo));

                            }
                            else {
                                localStorage.setItem(ProdGenApi.getCurrentTenantId() + "_company_logo", JSON.stringify(this.companyLogo));

                            }
                        }
                        catch (err) {
                            console.log(err);
                        }
                    }
                    else {//if the setting is not found we will default to using the partner logo
                        this.pinnacleService.GetPartnerSettings().subscribe(partnerSettings => {
                            let partnerLogoSetting: Setting = new Setting();
                            if (this.isPinnacleLiteLocal == true) {
                                partnerLogoSetting = partnerSettings.find(v => v.name == "LITE_LOGO");
                                if (partnerLogoSetting.settingValue == "" || partnerLogoSetting.settingValue == null) {
                                    partnerLogoSetting = partnerSettings.find(v => v.name == "PARTNER_LOGO");
                                }
                            }
                            else {
                                partnerLogoSetting = partnerSettings.find(v => v.name == "PARTNER_LOGO");
                            }
                            if (partnerLogoSetting != null) {
                                this.companyLogo = partnerLogoSetting.settingValue as string;
                                this.partnerLogo = partnerLogoSetting.settingValue as string;

                                try {
                                    if (this.isPinnacleLiteLocal == true) {
                                        localStorage.setItem(ProdGenApi.getCurrentTenantId() + "_lite_logo", JSON.stringify(this.companyLogo));

                                    }
                                    else {
                                        localStorage.setItem(ProdGenApi.getCurrentTenantId() + "_company_logo", JSON.stringify(this.companyLogo));
                                    }
                                }
                                catch (err) {
                                    console.log(err);
                                }
                            }
                        });
                    }

                    let widgetSetting = s.find(v => v.name == "USE_WIDGETS");
                    if (widgetSetting) {
                        this.bUseWidgets = widgetSetting.settingValue.toString().toLowerCase() == 'true';
                    }
                    else {
                        this.bUseWidgets = false;
                    }
                    localStorage.setItem("usewidgets" + ProdGenApi.getCurrentTenantId(), this.bUseWidgets.toString());


                    let viewDownloadIndex = s.findIndex(w => w.name == "DOWNLOAD_VIEW");

                    if (viewDownloadIndex != -1) {
                        //this.canViewDownloadAddOns = res[viewDownloadIndex].settingValue as boolean;

                        let canViewDownloadAddOns = false;
                        if (s[viewDownloadIndex].settingValue == "True") {
                            canViewDownloadAddOns = true;
                        }
                        else {
                            canViewDownloadAddOns = false;
                        }



                        try {
                            localStorage.setItem(ProdGenApi.getCurrentTenantId() + "_download_view", JSON.stringify(canViewDownloadAddOns));
                        }
                        catch (err) {
                            console.log(err);
                        }
                    }
                });
            });

        this.pinnacleService.GetCanPartnerSellPinnacleLite().subscribe(
            res => {
                this.canSellPinnacleLite = res;
            }
        );

        this.pinnacleService.getCurrentUser().subscribe(res => {
            this.currentUser = res;
            this.pinnacleService.getCurrentUserPermissions(true).subscribe(permissions => {
                this.isAdmin = permissions.administrator;
                this.isContentAdministrator = permissions.administerContent;
                this.isContentPublisher = permissions.publishContent;
                this.isContentAuthor = permissions.authorContent;
                this.isAdministrator = permissions.administrator;
                this.isEPSupportDisabled = permissions.disabledEPSupport;
                this.isBrandingManager = (permissions.administrator == true || permissions.canManageBranding == true);
                this.isAssignor = permissions.isAssignor;
                this.isAssignmentAdmin = permissions.enrollUsers;
            });


            this.LoadTheme(sessionStorage.getItem("currentTenant"));
            this.pinnacleService.GetUserOrgInfo(this.currentUser.email).subscribe(r => {
                this.tenants = r.tenantList;
                for (let tenant of this.tenants) {
                    this.pinnacleService.getSettingByNameAndTenant("COMPANY_LOGO", tenant.tenantId).subscribe(res => {
                        let tenantObj = this.tenants[this.tenants.findIndex(x => x.tenantId == res.tenantId)];
                        let tenantWLogo = new TenantWithLogo();
                        tenantWLogo.altOrganizationId = tenantObj.altOrganizationId;
                        tenantWLogo.expiration = tenantObj.expiration;
                        tenantWLogo.isPinnacleLite = tenantObj.isPinnacleLite;
                        tenantWLogo.maxPinnacleLiteUsers = tenantObj.maxPinnacleLiteUsers;
                        tenantWLogo.name = tenantObj.name;
                        tenantWLogo.organizationId = tenantObj.organizationId;
                        tenantWLogo.tenantId = tenantObj.tenantId;
                        tenantWLogo.logo = res.settingValue.toString() != "" ? res.settingValue.toString() : this.partnerLogo;

                        this.tenantsWithLogo.push(tenantWLogo);
                    });
                }
            });
        });

        this.pinnacleService.GetCurrentPartner().subscribe(p => {
            this.sellingPartner = p;
            if (this.sellingPartner.logoURL == null || this.sellingPartner.logoURL.length == 0)
            {
                this.sellingPartner.logoURL = "https://pinnacle.blob.core.windows.net/partner-files/174848/ps_browser_logo.png";
            }

            if (this.sellingPartner.partnerProductName.length > 0) {
                window.document.title = this.sellingPartner.partnerProductName as string;

            }

        });

        this.isChatSettingsLoaded = false;
        // get some chat settings
        this.pinnacleService.getChatSettings().subscribe(s => {
            this.isChatSettingsLoaded = true;
            this.chatSettings = s
        });
        this.APIv2.getKSIntegrationConfigInfo().subscribe(ksci => {
            this.KSConfigInfo = ksci.knowledgeSmartConfiguration;
        },
            err => {
                this.KSConfigInfo = new KnowledgeSmartConfiguration();
                this.KSConfigInfo.useKnowledgeSmartIntegration = false;
                this.KSConfigInfo.knowledgeSmartAPI_Key = "";
                this.KSConfigInfo.assessmentSelfEnroll = false;
            });

    }


    isAuthorized(): boolean {
        //this.isExternal = ProdGenApi.GetUserIsExternal();
        return ProdGenApi.getUserAccessKey().length > 0;
    }

    canShowAssetLibrary(): boolean {
        let url: string = this.router.url.toLowerCase();

        if (url.includes("/home") && this.bUseWidgets == false) {
            return true;
        }
        if (url.includes("/product-listing")) {
            return true;
        }
        if (url.includes("/pinnaclelite") && this.bUseWidgets == false) {
            return true;
        }
        return false;

    }
    canShowRightTrioPanels(): boolean {
        let url: string = this.router.url.toLowerCase();

        if (url.includes("/home") && this.bUseWidgets == false && (this.currentUser.userId != "" && this.currentUser.userId != null)) {
            return true;
        }
        return false;

    }

    getMainClassList(): string {
        let classes: string = "col-lg-8 col-lg-push-2 col-md-8 col-md-push-2 col-sm-12 col-xs-12";
        if (this.canShowAssetLibrary() == false) {
            classes = "col-lg-12 col-md-12 col-sm-12 col-xs-12";
        }
        if (this.canShowAssetLibrary() == true) {
            if (this.canShowRightTrioPanels() == false) {
                classes = "col-lg-10 col-lg-push-2 col-md-10 col-md-push-2 col-sm-12 col-xs-12";
            }
        }

        return classes;
    }
    getCorporateClassList(): string {
        let classes: string = "col-lg-2 col-lg-pull-8 col-md-2 col-md-pull-8 col-sm-6 col-xs-12";
        if (this.canShowAssetLibrary() == false) {
            classes = "hidden-lg hidden-md hidden-sm hidden-xs";
        }
        if (this.canShowAssetLibrary() == true) {
            if (this.canShowRightTrioPanels() == false) {
                classes = "col-lg-2 col-lg-pull-10 col-md-2 col-md-pull-10 col-sm-6 col-xs-12";
            }
        }

        return classes;

    }
    getRightTrioPanelClassList(): string {
        let classes: string = "col-lg-2 col-md-2 col-sm-12 col-xs-12";
        if (this.canShowRightTrioPanels() == false) {
            classes = "hidden-lg hidden-md hidden-sm hidden-xs";
        }

        return classes;
    }



    logOut(): Promise<boolean> {

        try {
            this.chatControl.onEndChat();
        }
        catch (err) { }
        this.isAdmin = false;
        this.isAdministrator = false;
        this.isContentAdministrator = false;
        this.isContentPublisher = false;
        this.isContentAuthor = false;
        this.isEPSupportDisabled = true;
        if (this.timeoutService.debugTimeout) console.log("*logOut, setting loggedOut to true");  
        this.timeoutService.loggedOut = true;
        this.timeoutService.videoPlaying = false;
        this.timeoutService.stop();

        // maintain the API key
        let tmpApiKey = ProdGenApi.getAPIKey();
        let temptAPIKeyV2 = ProdGenApi.getAPIV2AccessKey();
        sessionStorage.clear();
        ProdGenApi.setAPIKey(tmpApiKey);
        ProdGenApi.setAPIV2BearerToken(temptAPIKeyV2);
        let token = ProdGenApi.getAPIV2AccessKey().bearerToken;

        this.isLoggedIn = false;
        this.companyLogo = "";
        this.partnerLogo = "";
        AppComponent.isPinnacleLite = false;
        this.isPinnacleLiteLocal = false;
        this.isPeakExperience = false;
        this.tenants.splice(0);
        this.tenantsWithLogo.splice(0);
        // this.pinnacleService.EndSession();
        this.pinnacleService.EndSessionToken(token);     

        /*
        try {
            let v_TenantID = ProdGenApi.getCurrentTenantId();
            let v_UserID = ProdGenApi.currentUser.userId;
  
            localStorage.removeItem(`${v_TenantID}_company_news`);
            localStorage.removeItem(`${v_TenantID}_company_news_image`);
            localStorage.removeItem(`${v_TenantID}_partner_news`);
            localStorage.removeItem(`${v_TenantID}_partner_news_image`);
            localStorage.removeItem(`${v_UserID}_user_image`);
            localStorage.removeItem(`${v_UserID}_workgroups`);
            localStorage.removeItem(`${v_UserID}_enrollments`);
            localStorage.removeItem(`${v_UserID}_frequent`);
            localStorage.removeItem(`${v_UserID}_trending`);
            
            
  
        }
        catch(e){ }
        */

        localStorage.setItem("rememberMe", "false");
        localStorage.setItem("longLivedToken", "");

        sessionStorage.setItem("ChatActive", "false");
        localStorage.setItem("lastUsedTenant", "");
        localStorage.setItem("isAuthenicated", "");
        //localStorage.setItem("userEmail", "");



        sessionStorage.setItem("workGroupSortBy", "Asset Name");
        sessionStorage.setItem("volume", "7");

        // Clean up if you're logging out of the
        // impersonation account. Not strictly 
        // necessary since the login screen performs
        // a reload, but good in case that changes.
        ProdGenApi.setIsImpersonating(false);

        return this.router.navigate(['/login']);
    }

    getUniqueImageSource(source: string): string {
        let newSource = "";
        if (source != null) {
            if (source.length > 0) {
                if (source.indexOf("?") == -1) {
                    newSource = source + "?" + this.uniqueImageQuery;
                }
                else {
                    newSource = source + "&" + this.uniqueImageQuery;
                }
            }
        }
        return newSource;
    }

    getFooterImageSource(partnerId: string): string {

        if (window.location.href.toLocaleLowerCase().includes("productivitynow.imaginit.com") || partnerId.toUpperCase() == "342AA211-04BB-4F21-A4C0-5A6FB3AA5511")        //IMAGINiT
        {
            return "../../assets/images/imaginit_ps_browser_logo.png";
        }
        else if (window.location.href.toLocaleLowerCase().includes("productivitynow.ascented.com") || partnerId.toUpperCase() == "5B4D8094-590C-4317-A611-8011B244CA65")        //Ascent
        {
            return "../../assets/images/ascent_ps_browser_logo.png";
        }
        else if (window.location.href.toLocaleLowerCase().includes("productivitynow.rand3d.com") || partnerId.toUpperCase() == "A972914D-3CFB-4F3F-9585-85A120F12BFF")        //Rand 3D
        {
            return "../../assets/images/rand3d_ps_browser_logo.png";
        }
        else
        {
            return "../../assets/images/ps_browser_logo.png";
        }
    }

    navBarTogglerIsVisible() {
        return this.navbarToggler.nativeElement.offsetParent !== null;
    }

    collapseNav() {
        if (this.navBarTogglerIsVisible()) {
            this.navbarToggler.nativeElement.click();
        }
        $('#newcheat').modal('hide');
        $('body').removeClass('modal-open');
        $('.modal-backdrop').remove();
    }

    translatedOption(value: string) {

        if (value == "Warning Title") {
            return this.translate.getTranslationFileData("APPCOMPONENT.TimeoutTitle");
        }
        else if (value == "Warning Message") {
            return this.translate.getTranslationFileData("APPCOMPONENT.TimeoutMessage");
        }
        else if (value == "Warning Time") {
            return this.translate.getTranslationFileData("APPCOMPONENT.TimeoutMessageTimeRemaining");
        }
        else if (value == "Warning Minute") {
            return this.translate.getTranslationFileData("APPCOMPONENT.TimeoutUnitsSingular");
        }
        else if (value == "Warning Minutes") {
            return this.translate.getTranslationFileData("APPCOMPONENT.TimeoutUnitsPlural");
        }
        else if (value == "Warning Button") {
            return this.translate.getTranslationFileData("APPCOMPONENT.TimeoutContinue");
        }
        else if (value == "Expired Title") {
            return this.translate.getTranslationFileData("APPCOMPONENT.ExpiredTitle");
        }
        else if (value == "Expired Message") {
            return this.translate.getTranslationFileData("APPCOMPONENT.ExpiredMessage");
        }
        else if (value == "Expired Button") {
            return this.translate.getTranslationFileData("APPCOMPONENT.ExpiredConfirm");
        }
        return value;
    }

    launchAdministrationPortal() {
        // we will launch off the new window then waith for a notification that it is created (look in the app constructor for this callback)
        this.AdminPortalHome = true;                //set flag to indicate to open home page in Management Portal.
        let url = window.location.protocol + "//" + window.location.host;
        url = url.replace("4200", "4201");
        url += "/administration";
        var adminWindow = window.open(url, "_blank");
    }

    /**
     * Navigate to Peak site with optional query params
     * @param target window.open target value
     * @param queryParams list of query params to append to a url
     * @returns WindowProxy object representing the newly opened window
     */
    openPeak(
        target: '_self' | '_blank', 
        queryParams: { [key: string]: string } = undefined
    ): WindowProxy {
        // we will redirect then waith for a notification that it is created (look in the app constructor for this callback)
        let url = window.location.protocol + "//" + window.location.host;
        url = url.replace("4200", "4202");
        url += "/peak";
        const params = this.createQueryParamsStr(queryParams);
        if (params) {
            url += params;
        }
        return window.open(url, target);
    }

    /**
     * Create a formatted string of query params
     * @param queryParams list of query params to append to a url
     * @rturns query params formatted as a string
     */
    private createQueryParamsStr(queryParams: { [key: string]: string } = undefined): string {
        let url = '';
        if (queryParams != null) {
            url += '?';
            const paramKeys = Object.keys(queryParams);
            if (paramKeys) {
                for (let i = 0; i < paramKeys.length; i++) {
                    const paramKey = paramKeys[i];
                    const paramValue = queryParams[paramKey];
                    if (!paramKey || !paramValue) {
                        continue; // skip null or undefined values
                    }

                    if (i > 0) {
                        url += '&'; // only append ampersand if there are more than 1 query params
                    }
                    url += `${paramKey}=${paramValue}`;
                }
            }
        }
        return url;
    }

    launchManagementUtility() {
        this.collapseNav();
        //if(1!=1){
        /*
        if (doesFontExist("supercalifragilisticexpialidocious") == false) {
            // if they have not, give them a message showing the download link
            this.controlService.openMessageBoxPopUp(
                `The <b>Pinnacle Management Utility</b> application is required to execute this link.<BR><BR>
  <a href="https://www.eaglepoint.com/downloads/pinnacle/pinnacle.msi" target="_blank"><b>Click Here</b></a> to download and install this application.<BR><BR>
  
  <b>After the installation you must restart your browser.</b>`);
            return;
        }
        
  
        else {
        */


        //if (doesFontExist("xenomorph hq") == true) {
        if (doesFontExist("Xenotron Broadstroke") == true)
        //if(1==1)
        {


            this.pinnacleService.getCurrentUserPermissions().subscribe(permissions => {

                if (permissions.administrator == true || permissions.administerContent == true || permissions.enrollUsers == true || permissions.viewReports == true) {

                    if (this.managementFrame != null) {
                        this.managementFrame.setAttribute("src", 'pinnaclecommand://APPLICATION?"C:\\Program Files (x86)\\Pinnacle Series\\Pinnacle.exe"');
                    }
                    else {
                        this.managementFrame = document.createElement("iframe");
                        this.managementFrame.setAttribute("src", 'pinnaclecommand://APPLICATION?"C:\\Program Files (x86)\\Pinnacle Series\\Pinnacle.exe"');
                        this.managementFrame.style.display = "none";
                        this.managementFrame.id = "#managementframe";
                        document.body.appendChild(this.managementFrame);
                    }

                }
            });
        }
    }

    hideAllModals() {
        $(".modal.in").modal("hide");
    }

    onDeactivate(ev: any) {

        if (ev.componentname == "CompanybrandingComponent") {
            this.pinnacleService.GetCurrentTenantSettings().subscribe(s => {

                let widgetSetting = s.find(v => v.name == "USE_WIDGETS");
                if (widgetSetting) {
                    this.bUseWidgets = widgetSetting.settingValue.toString().toLowerCase() == 'true';
                }
                else {
                    this.bUseWidgets = false;
                }
                localStorage.setItem("usewidgets" + ProdGenApi.getCurrentTenantId(), this.bUseWidgets.toString());

            });
        }
        else {
            //do nothin  
        }

    }

    onOpenChatWindowFromMenu() {
        this.collapseNav();
        this.chatControl.onOpenChatWindow();
    }

    onRouteActivated(ev: any) {

        if (ev instanceof HomeComponent || ev instanceof LearningpageoutletComponent) {
            this.pinnacleService.getSettingByName("USE_WIDGETS").subscribe(s => {

                let widgetSetting = s;
                if (widgetSetting) {
                    this.bUseWidgets = widgetSetting.settingValue.toString().toLowerCase() == 'true';
                }
                else {
                    this.bUseWidgets = false;
                }
                localStorage.setItem("usewidgets" + ProdGenApi.getCurrentTenantId(), this.bUseWidgets.toString());

            });
        }
        else {
            //close the flyout if open
            this.closeFlyout();
        }

    }

    LoadTheme(Tenant: string) {
        let appliedtheme = new Theme();
        try {
            appliedtheme = JSON.parse(localStorage.getItem("AppliedTheme" + Tenant));

            if (appliedtheme.themeID != "00000000-0000-0000-0000-000000000000") {

                Object.keys(this.previewtheme.properties).forEach(property => {
                    if (property.indexOf("width") != -1 || property.indexOf("radius") != -1) {
                        //this.previewtheme.properties[property] = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => "--preview-" + x.itemName.replace(/_/g, "-") == property)].itemValue + "px";
                        this.previewtheme.properties[property] = appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                            function (e) {
                                return "--preview-" + e.itemName.replace(/_/g, "-")
                            }).indexOf(property)].itemValue + "px";

                    }
                    else if (property.indexOf("font-face") != -1) {
                        //this.previewtheme.properties[property] = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => "--preview-" + x.itemName.replace(/_/g, "-") == property)].itemValue;
                        this.previewtheme.properties[property] = appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                            function (e) {
                                return "--preview-" + e.itemName.replace(/_/g, "-")
                            }).indexOf(property)].itemValue;

                    }
                    else if (property.indexOf("themeid") == -1 && property.indexOf("tenantid") == -1 && property.indexOf("is_public") == -1 && property.indexOf("name") == -1) {
                        //this.previewtheme.properties[property] = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => "--preview-" + x.itemName.replace(/_/g, "-") == property)].itemValue;
                        this.previewtheme.properties[property] = appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                            function (e) {
                                return "--preview-" + e.itemName.replace(/_/g, "-")
                            }).indexOf(property)].itemValue;

                    }

                    if (property.indexOf("themeid") == -1 && property.indexOf("tenantid") == -1 && property.indexOf("is_public") == -1 && property.indexOf("name") == -1) {
                        document.documentElement.style.setProperty(
                            property,
                            this.previewtheme.properties[property]
                        );
                    }
                });

                Object.keys(currenttheme.properties).forEach(property => {
                    if (property.indexOf("width") != -1 || property.indexOf("radius") != -1) {
                        //this.previewtheme.properties[property] = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => "--preview-" + x.itemName.replace(/_/g, "-") == property)].itemValue + "px";
                        currenttheme.properties[property] = appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                            function (e) {
                                return "--" + e.itemName.replace(/_/g, "-")
                            }).indexOf(property)].itemValue + "px";

                    }
                    else if (property.indexOf("font-face") != -1) {
                        //this.previewtheme.properties[property] = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => "--preview-" + x.itemName.replace(/_/g, "-") == property)].itemValue;
                        currenttheme.properties[property] = appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                            function (e) {
                                return "--" + e.itemName.replace(/_/g, "-")
                            }).indexOf(property)].itemValue;

                    }
                    else if (property.indexOf("themeid") == -1 && property.indexOf("tenantid") == -1 && property.indexOf("is_public") == -1 && property.indexOf("name") == -1) {
                        //this.previewtheme.properties[property] = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => "--preview-" + x.itemName.replace(/_/g, "-") == property)].itemValue;
                        currenttheme.properties[property] = appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                            function (e) {
                                return "--" + e.itemName.replace(/_/g, "-")
                            }).indexOf(property)].itemValue;

                    }

                    if (property.indexOf("themeid") == -1 && property.indexOf("tenantid") == -1 && property.indexOf("is_public") == -1 && property.indexOf("name") == -1) {
                        document.documentElement.style.setProperty(
                            property,
                            currenttheme.properties[property]
                        );
                    }
                });
                localStorage.setItem(Tenant + "_DocsImage", appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                    function (e) {
                        return e.itemName
                    }).indexOf("document_image")].itemValue);
                localStorage.setItem(Tenant + "_MyDocsImage", appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                    function (e) {
                        return e.itemName
                    }).indexOf("my_document_image")].itemValue);
                localStorage.setItem(Tenant + "_VidsImage", appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                    function (e) {
                        return e.itemName
                    }).indexOf("video_image")].itemValue);
                localStorage.setItem(Tenant + "_CourseImage", appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                    function (e) {
                        return e.itemName
                    }).indexOf("course_image")].itemValue);
                localStorage.setItem(Tenant + "_LPImage", appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                    function (e) {
                        return e.itemName
                    }).indexOf("learning_path_image")].itemValue);
                localStorage.setItem(Tenant + "_WFImage", appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                    function (e) {
                        return e.itemName
                    }).indexOf("workflow_image")].itemValue);
                localStorage.setItem(Tenant + "_ProcessImage", appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                    function (e) {
                        return e.itemName
                    }).indexOf("process_image")].itemValue);
                localStorage.setItem(Tenant + "_QuizImage", appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                    function (e) {
                        return e.itemName
                    }).indexOf("quiz_image")].itemValue);
                localStorage.setItem(Tenant + "_defWorkgroupImage", appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                    function (e) {
                        return e.itemName
                    }).indexOf("workgroup_image")].itemValue);
                localStorage.setItem(Tenant + "_EnrollmentImage", appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                    function (e) {
                        return e.itemName
                    }).indexOf("enrollment_image")].itemValue);
                cssVars({
                    rootElement: document,
                    variables: {
                        "--preview-font-face": this.previewtheme.properties["--preview-font-face"],
                        "--preview-page-background-color": this.previewtheme.properties["--preview-page-background-color"],
                        "--preview-page-text-color": this.previewtheme.properties["--preview-page-text-color"],
                        "--preview-page-separator-color": this.previewtheme.properties["--preview-page-separator-color"],
                        "--preview-page-separator-width": this.previewtheme.properties["--preview-page-separator-width"],
                        "--preview-navbar-background-color": this.previewtheme.properties["--preview-navbar-background-color"],
                        "--preview-navbar-foreground-color": this.previewtheme.properties["--preview-navbar-foreground-color"],
                        "--preview-hsl-background-color": this.previewtheme.properties["--preview-hsl-background-color"],
                        "--preview-hsl-foreground-color": this.previewtheme.properties["--preview-hsl-foreground-color"],
                        "--preview-hsl-border-color": this.previewtheme.properties["--preview-hsl-border-color"],
                        "--preview-hsl-border-width": this.previewtheme.properties["--preview-hsl-border-width"],
                        "--preview-hsr-background-color": this.previewtheme.properties["--preview-hsr-background-color"],
                        "--preview-hsr-foreground-color": this.previewtheme.properties["--preview-hsr-foreground-color"],
                        "--preview-hsr-border-color": this.previewtheme.properties["--preview-hsr-border-color"],
                        "--preview-hsr-border-width": this.previewtheme.properties["--preview-hsr-border-width"],
                        "--preview-large-card-background-color1": this.previewtheme.properties["--preview-large-card-background-color1"],
                        "--preview-large-card-foreground-color1": this.previewtheme.properties["--preview-large-card-foreground-color1"],
                        "--preview-large-card-background-color2": this.previewtheme.properties["--preview-large-card-background-color2"],
                        "--preview-large-card-foreground-color2": this.previewtheme.properties["--preview-large-card-foreground-color2"],
                        "--preview-large-card-background-color3": this.previewtheme.properties["--preview-large-card-background-color3"],
                        "--preview-large-card-foreground-color3": this.previewtheme.properties["--preview-large-card-foreground-color3"],
                        "--preview-large-card-border-color": this.previewtheme.properties["--preview-large-card-border-color"],
                        "--preview-large-card-border-width": this.previewtheme.properties["--preview-large-card-border-width"],
                        "--preview-large-card-border-radius": this.previewtheme.properties["--preview-large-card-border-radius"],
                        "--preview-small-card-background-color1": this.previewtheme.properties["--preview-small-card-background-color1"],
                        "--preview-small-card-foreground-color1": this.previewtheme.properties["--preview-small-card-foreground-color1"],
                        "--preview-small-card-background-color2": this.previewtheme.properties["--preview-small-card-background-color2"],
                        "--preview-small-card-foreground-color2": this.previewtheme.properties["--preview-small-card-foreground-color2"],
                        "--preview-small-card-background-color3": this.previewtheme.properties["--preview-small-card-background-color3"],
                        "--preview-small-card-foreground-color3": this.previewtheme.properties["--preview-small-card-foreground-color3"],
                        "--preview-small-card-border-color": this.previewtheme.properties["--preview-small-card-border-color"],
                        "--preview-small-card-border-width": this.previewtheme.properties["--preview-small-card-border-width"],
                        "--preview-small-card-border-radius": this.previewtheme.properties["--preview-small-card-border-radius"],
                        "--preview-panel-title-background-color": this.previewtheme.properties["--preview-panel-title-background-color"],
                        "--preview-panel-title-foreground-color": this.previewtheme.properties["--preview-panel-title-foreground-color"],
                        "--preview-panel-body-background-color": this.previewtheme.properties["--preview-panel-body-background-color"],
                        "--preview-panel-body-foreground-color": this.previewtheme.properties["--preview-panel-body-foreground-color"],
                        "--preview-panel-border-color": this.previewtheme.properties["--preview-panel-border-color"],
                        "--preview-panel-border-width": this.previewtheme.properties["--preview-panel-border-width"],
                        "--preview-panel-border-radius": this.previewtheme.properties["--preview-panel-border-radius"],
                        "--preview-button-background": this.previewtheme.properties["--preview-button-background"],
                        "--preview-button-foreground": this.previewtheme.properties["--preview-button-foreground"],

                        "--font-face": currenttheme.properties["--font-face"],
                        "--page-background-color": currenttheme.properties["--page-background-color"],
                        "--page-text-color": currenttheme.properties["--page-text-color"],
                        "--page-separator-color": currenttheme.properties["--page-separator-color"],
                        "--page-separator-width": currenttheme.properties["--page-separator-width"],
                        "--navbar-background-color": currenttheme.properties["--navbar-background-color"],
                        "--navbar-foreground-color": currenttheme.properties["--navbar-foreground-color"],
                        "--hsl-background-color": currenttheme.properties["--hsl-background-color"],
                        "--hsl-foreground-color": currenttheme.properties["--hsl-foreground-color"],
                        "--hsl-border-color": currenttheme.properties["--hsl-border-color"],
                        "--hsl-border-width": currenttheme.properties["--hsl-border-width"],
                        "--hsr-background-color": currenttheme.properties["--hsr-background-color"],
                        "--hsr-foreground-color": currenttheme.properties["--hsr-foreground-color"],
                        "--hsr-border-color": currenttheme.properties["--hsr-border-color"],
                        "--hsr-border-width": currenttheme.properties["--hsr-border-width"],
                        "--large-card-background-color1": currenttheme.properties["--large-card-background-color1"],
                        "--large-card-foreground-color1": currenttheme.properties["--large-card-foreground-color1"],
                        "--large-card-background-color2": currenttheme.properties["--large-card-background-color2"],
                        "--large-card-foreground-color2": currenttheme.properties["--large-card-foreground-color2"],
                        "--large-card-background-color3": currenttheme.properties["--large-card-background-color3"],
                        "--large-card-foreground-color3": currenttheme.properties["--large-card-foreground-color3"],
                        "--large-card-border-color": currenttheme.properties["--large-card-border-color"],
                        "--large-card-border-width": currenttheme.properties["--large-card-border-width"],
                        "--large-card-border-radius": currenttheme.properties["--large-card-border-radius"],
                        "--small-card-background-color1": currenttheme.properties["--small-card-background-color1"],
                        "--small-card-foreground-color1": currenttheme.properties["--small-card-foreground-color1"],
                        "--small-card-background-color2": currenttheme.properties["--small-card-background-color2"],
                        "--small-card-foreground-color2": currenttheme.properties["--small-card-foreground-color2"],
                        "--small-card-background-color3": currenttheme.properties["--small-card-background-color3"],
                        "--small-card-foreground-color3": currenttheme.properties["--small-card-foreground-color3"],
                        "--small-card-border-color": currenttheme.properties["--small-card-border-color"],
                        "--small-card-border-width": currenttheme.properties["--small-card-border-width"],
                        "--small-card-border-radius": currenttheme.properties["--small-card-border-radius"],
                        "--panel-title-background-color": currenttheme.properties["--panel-title-background-color"],
                        "--panel-title-foreground-color": currenttheme.properties["--panel-title-foreground-color"],
                        "--panel-body-background-color": currenttheme.properties["--panel-body-background-color"],
                        "--panel-body-foreground-color": currenttheme.properties["--panel-body-foreground-color"],
                        "--panel-border-color": currenttheme.properties["--panel-border-color"],
                        "--panel-border-width": currenttheme.properties["--panel-border-width"],
                        "--panel-border-radius": currenttheme.properties["--panel-border-radius"],
                        "--button-background": currenttheme.properties["--button-background"],
                        "--button-foreground": currenttheme.properties["--button-foreground"]
                    }
                });
                AppComponent.panelBodyBackgroundColor = currenttheme.properties["--panel-body-background-color"];
                AppComponent.panelBodyForegroundColor = currenttheme.properties["--panel-body-foreground-color"];
                AppComponent.pageBackgroundColor = currenttheme.properties["--page-background-color"];
            }
        } catch (e) {

        }

        if (Tenant.includes('"') == true) {
            Tenant = Tenant.replace(/"/g, '');
        }
        this.pinnacleService.GetCurrentTenantThemeSettingAtLogin(Tenant, this.currentUser.email).subscribe(res => {

            appliedtheme = res;
            localStorage.setItem("AppliedTheme" + Tenant, JSON.stringify(appliedtheme));
            if (res.themeID != "00000000-0000-0000-0000-000000000000") {

                Object.keys(this.previewtheme.properties).forEach(property => {
                    if (property.indexOf("width") != -1 || property.indexOf("radius") != -1) {
                        //this.previewtheme.properties[property] = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => "--preview-" + x.itemName.replace(/_/g, "-") == property)].itemValue + "px";
                        this.previewtheme.properties[property] = appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                            function (e) {
                                return "--preview-" + e.itemName.replace(/_/g, "-")
                            }).indexOf(property)].itemValue + "px";

                    }
                    else if (property.indexOf("font-face") != -1) {
                        //this.previewtheme.properties[property] = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => "--preview-" + x.itemName.replace(/_/g, "-") == property)].itemValue;
                        this.previewtheme.properties[property] = appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                            function (e) {
                                return "--preview-" + e.itemName.replace(/_/g, "-")
                            }).indexOf(property)].itemValue;

                    }
                    else if (property.indexOf("themeid") == -1 && property.indexOf("tenantid") == -1 && property.indexOf("is_public") == -1 && property.indexOf("name") == -1) {
                        //this.previewtheme.properties[property] = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => "--preview-" + x.itemName.replace(/_/g, "-") == property)].itemValue;
                        this.previewtheme.properties[property] = appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                            function (e) {
                                return "--preview-" + e.itemName.replace(/_/g, "-")
                            }).indexOf(property)].itemValue;

                    }

                    if (property.indexOf("themeid") == -1 && property.indexOf("tenantid") == -1 && property.indexOf("is_public") == -1 && property.indexOf("name") == -1) {
                        document.documentElement.style.setProperty(
                            property,
                            this.previewtheme.properties[property]
                        );
                    }
                });

                Object.keys(currenttheme.properties).forEach(property => {
                    if (property.indexOf("width") != -1 || property.indexOf("radius") != -1) {
                        //this.previewtheme.properties[property] = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => "--preview-" + x.itemName.replace(/_/g, "-") == property)].itemValue + "px";
                        currenttheme.properties[property] = appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                            function (e) {
                                return "--" + e.itemName.replace(/_/g, "-")
                            }).indexOf(property)].itemValue + "px";

                    }
                    else if (property.indexOf("font-face") != -1) {
                        //this.previewtheme.properties[property] = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => "--preview-" + x.itemName.replace(/_/g, "-") == property)].itemValue;
                        currenttheme.properties[property] = appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                            function (e) {
                                return "--" + e.itemName.replace(/_/g, "-")
                            }).indexOf(property)].itemValue;

                    }
                    else if (property.indexOf("themeid") == -1 && property.indexOf("tenantid") == -1 && property.indexOf("is_public") == -1 && property.indexOf("name") == -1) {
                        //this.previewtheme.properties[property] = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => "--preview-" + x.itemName.replace(/_/g, "-") == property)].itemValue;
                        currenttheme.properties[property] = appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                            function (e) {
                                return "--" + e.itemName.replace(/_/g, "-")
                            }).indexOf(property)].itemValue;

                    }

                    if (property.indexOf("themeid") == -1 && property.indexOf("tenantid") == -1 && property.indexOf("is_public") == -1 && property.indexOf("name") == -1) {
                        document.documentElement.style.setProperty(
                            property,
                            currenttheme.properties[property]
                        );
                    }
                });
                localStorage.setItem(Tenant + "_DocsImage", appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                    function (e) {
                        return e.itemName
                    }).indexOf("document_image")].itemValue);
                localStorage.setItem(Tenant + "_MyDocsImage", appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                    function (e) {
                        return e.itemName
                    }).indexOf("my_document_image")].itemValue);
                localStorage.setItem(Tenant + "_VidsImage", appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                    function (e) {
                        return e.itemName
                    }).indexOf("video_image")].itemValue);
                localStorage.setItem(Tenant + "_CourseImage", appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                    function (e) {
                        return e.itemName
                    }).indexOf("course_image")].itemValue);
                localStorage.setItem(Tenant + "_LPImage", appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                    function (e) {
                        return e.itemName
                    }).indexOf("learning_path_image")].itemValue);
                localStorage.setItem(Tenant + "_WFImage", appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                    function (e) {
                        return e.itemName
                    }).indexOf("workflow_image")].itemValue);
                localStorage.setItem(Tenant + "_ProcessImage", appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                    function (e) {
                        return e.itemName
                    }).indexOf("process_image")].itemValue);
                localStorage.setItem(Tenant + "_QuizImage", appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                    function (e) {
                        return e.itemName
                    }).indexOf("quiz_image")].itemValue);
                localStorage.setItem(Tenant + "_defWorkgroupImage", appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                    function (e) {
                        return e.itemName
                    }).indexOf("workgroup_image")].itemValue);
                localStorage.setItem(Tenant + "_EnrollmentImage", appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                    function (e) {
                        return e.itemName
                    }).indexOf("enrollment_image")].itemValue);
                cssVars({
                    rootElement: document,
                    variables: {
                        "--preview-font-face": this.previewtheme.properties["--preview-font-face"],
                        "--preview-page-background-color": this.previewtheme.properties["--preview-page-background-color"],
                        "--preview-page-text-color": this.previewtheme.properties["--preview-page-text-color"],
                        "--preview-page-separator-color": this.previewtheme.properties["--preview-page-separator-color"],
                        "--preview-page-separator-width": this.previewtheme.properties["--preview-page-separator-width"],
                        "--preview-navbar-background-color": this.previewtheme.properties["--preview-navbar-background-color"],
                        "--preview-navbar-foreground-color": this.previewtheme.properties["--preview-navbar-foreground-color"],
                        "--preview-hsl-background-color": this.previewtheme.properties["--preview-hsl-background-color"],
                        "--preview-hsl-foreground-color": this.previewtheme.properties["--preview-hsl-foreground-color"],
                        "--preview-hsl-border-color": this.previewtheme.properties["--preview-hsl-border-color"],
                        "--preview-hsl-border-width": this.previewtheme.properties["--preview-hsl-border-width"],
                        "--preview-hsr-background-color": this.previewtheme.properties["--preview-hsr-background-color"],
                        "--preview-hsr-foreground-color": this.previewtheme.properties["--preview-hsr-foreground-color"],
                        "--preview-hsr-border-color": this.previewtheme.properties["--preview-hsr-border-color"],
                        "--preview-hsr-border-width": this.previewtheme.properties["--preview-hsr-border-width"],
                        "--preview-large-card-background-color1": this.previewtheme.properties["--preview-large-card-background-color1"],
                        "--preview-large-card-foreground-color1": this.previewtheme.properties["--preview-large-card-foreground-color1"],
                        "--preview-large-card-background-color2": this.previewtheme.properties["--preview-large-card-background-color2"],
                        "--preview-large-card-foreground-color2": this.previewtheme.properties["--preview-large-card-foreground-color2"],
                        "--preview-large-card-background-color3": this.previewtheme.properties["--preview-large-card-background-color3"],
                        "--preview-large-card-foreground-color3": this.previewtheme.properties["--preview-large-card-foreground-color3"],
                        "--preview-large-card-border-color": this.previewtheme.properties["--preview-large-card-border-color"],
                        "--preview-large-card-border-width": this.previewtheme.properties["--preview-large-card-border-width"],
                        "--preview-large-card-border-radius": this.previewtheme.properties["--preview-large-card-border-radius"],
                        "--preview-small-card-background-color1": this.previewtheme.properties["--preview-small-card-background-color1"],
                        "--preview-small-card-foreground-color1": this.previewtheme.properties["--preview-small-card-foreground-color1"],
                        "--preview-small-card-background-color2": this.previewtheme.properties["--preview-small-card-background-color2"],
                        "--preview-small-card-foreground-color2": this.previewtheme.properties["--preview-small-card-foreground-color2"],
                        "--preview-small-card-background-color3": this.previewtheme.properties["--preview-small-card-background-color3"],
                        "--preview-small-card-foreground-color3": this.previewtheme.properties["--preview-small-card-foreground-color3"],
                        "--preview-small-card-border-color": this.previewtheme.properties["--preview-small-card-border-color"],
                        "--preview-small-card-border-width": this.previewtheme.properties["--preview-small-card-border-width"],
                        "--preview-small-card-border-radius": this.previewtheme.properties["--preview-small-card-border-radius"],
                        "--preview-panel-title-background-color": this.previewtheme.properties["--preview-panel-title-background-color"],
                        "--preview-panel-title-foreground-color": this.previewtheme.properties["--preview-panel-title-foreground-color"],
                        "--preview-panel-body-background-color": this.previewtheme.properties["--preview-panel-body-background-color"],
                        "--preview-panel-body-foreground-color": this.previewtheme.properties["--preview-panel-body-foreground-color"],
                        "--preview-panel-border-color": this.previewtheme.properties["--preview-panel-border-color"],
                        "--preview-panel-border-width": this.previewtheme.properties["--preview-panel-border-width"],
                        "--preview-panel-border-radius": this.previewtheme.properties["--preview-panel-border-radius"],
                        "--preview-button-background": this.previewtheme.properties["--preview-button-background"],
                        "--preview-button-foreground": this.previewtheme.properties["--preview-button-foreground"],

                        "--font-face": currenttheme.properties["--font-face"],
                        "--page-background-color": currenttheme.properties["--page-background-color"],
                        "--page-text-color": currenttheme.properties["--page-text-color"],
                        "--page-separator-color": currenttheme.properties["--page-separator-color"],
                        "--page-separator-width": currenttheme.properties["--page-separator-width"],
                        "--navbar-background-color": currenttheme.properties["--navbar-background-color"],
                        "--navbar-foreground-color": currenttheme.properties["--navbar-foreground-color"],
                        "--hsl-background-color": currenttheme.properties["--hsl-background-color"],
                        "--hsl-foreground-color": currenttheme.properties["--hsl-foreground-color"],
                        "--hsl-border-color": currenttheme.properties["--hsl-border-color"],
                        "--hsl-border-width": currenttheme.properties["--hsl-border-width"],
                        "--hsr-background-color": currenttheme.properties["--hsr-background-color"],
                        "--hsr-foreground-color": currenttheme.properties["--hsr-foreground-color"],
                        "--hsr-border-color": currenttheme.properties["--hsr-border-color"],
                        "--hsr-border-width": currenttheme.properties["--hsr-border-width"],
                        "--large-card-background-color1": currenttheme.properties["--large-card-background-color1"],
                        "--large-card-foreground-color1": currenttheme.properties["--large-card-foreground-color1"],
                        "--large-card-background-color2": currenttheme.properties["--large-card-background-color2"],
                        "--large-card-foreground-color2": currenttheme.properties["--large-card-foreground-color2"],
                        "--large-card-background-color3": currenttheme.properties["--large-card-background-color3"],
                        "--large-card-foreground-color3": currenttheme.properties["--large-card-foreground-color3"],
                        "--large-card-border-color": currenttheme.properties["--large-card-border-color"],
                        "--large-card-border-width": currenttheme.properties["--large-card-border-width"],
                        "--large-card-border-radius": currenttheme.properties["--large-card-border-radius"],
                        "--small-card-background-color1": currenttheme.properties["--small-card-background-color1"],
                        "--small-card-foreground-color1": currenttheme.properties["--small-card-foreground-color1"],
                        "--small-card-background-color2": currenttheme.properties["--small-card-background-color2"],
                        "--small-card-foreground-color2": currenttheme.properties["--small-card-foreground-color2"],
                        "--small-card-background-color3": currenttheme.properties["--small-card-background-color3"],
                        "--small-card-foreground-color3": currenttheme.properties["--small-card-foreground-color3"],
                        "--small-card-border-color": currenttheme.properties["--small-card-border-color"],
                        "--small-card-border-width": currenttheme.properties["--small-card-border-width"],
                        "--small-card-border-radius": currenttheme.properties["--small-card-border-radius"],
                        "--panel-title-background-color": currenttheme.properties["--panel-title-background-color"],
                        "--panel-title-foreground-color": currenttheme.properties["--panel-title-foreground-color"],
                        "--panel-body-background-color": currenttheme.properties["--panel-body-background-color"],
                        "--panel-body-foreground-color": currenttheme.properties["--panel-body-foreground-color"],
                        "--panel-border-color": currenttheme.properties["--panel-border-color"],
                        "--panel-border-width": currenttheme.properties["--panel-border-width"],
                        "--panel-border-radius": currenttheme.properties["--panel-border-radius"],
                        "--button-background": currenttheme.properties["--button-background"],
                        "--button-foreground": currenttheme.properties["--button-foreground"]
                    }
                });
            }
        });
    }


    //switchTheme() {
    //    switch (this.selectedOption) {
    //        case "current":
    //            Object.keys(currenttheme.properties).forEach(property => {
    //                document.documentElement.style.setProperty(
    //                    property,
    //                    currenttheme.properties[property]
    //                );
    //            });
    //            cssVars({
    //                rootElement: document,
    //                variables:currenttheme.properties
    //            });
    //            break;
    //        case "chris":
    //            Object.keys(christheme.properties).forEach(property => {
    //                document.documentElement.style.setProperty(
    //                    property,
    //                    christheme.properties[property]
    //                );
    //            });
    //            cssVars({
    //                rootElement: document,
    //                variables: christheme.properties
    //            });
    //            break;
    //        case "gross":
    //            Object.keys(grosstheme.properties).forEach(property => {
    //                document.documentElement.style.setProperty(
    //                    property,
    //                    grosstheme.properties[property]
    //                );
    //            });
    //            cssVars({
    //                rootElement: document,
    //                variables: grosstheme.properties
    //            });
    //            break;
    //        default:
    //    }
    //}

    //cssapply() {
    //    cssVars();
    //}

    toggleFlyout() {
        if (this.Flyoutopen == true) {
            this.closeFlyout();

        }
        else {
            this.openFlyout();

        }
    }

    openFlyout() {
        try {
            let sidebarelem = document.getElementById("auxWidgetContainer");
            sidebarelem.style.height = "calc(100% - 60px)";
            this.Flyoutopen = true;
            document.getElementById("flyicon").classList.remove("fa-chevron-down");
            document.getElementById("flyicon").classList.add("fa-chevron-up");
        } catch (e) {
        }

    }

    closeFlyout() {
        try {
            document.getElementById("auxWidgetContainer").style.height = "0";
            this.Flyoutopen = false;
            document.getElementById("flyicon").classList.add("fa-chevron-down");
            document.getElementById("flyicon").classList.remove("fa-chevron-up");

        } catch (e) {
        }

    }

    getFlyoutButtonText() {
        if ((this.getRouterLink().includes("learningcenter") && !this.getRouterLink().includes("courseviewer")) || (this.getRouterLink().includes("home") && !this.getRouterLink().includes("external"))) {
            if (this.bUseWidgets == true && this.flyoutTitle != "") {
                return this.flyoutTitle;
            }
            else {
                return "Library";
            }
        }
        else if (this.getRouterLink().includes("courseviewer")) {
            return "Syllabus";
        }
        else if (this.getRouterLink().includes("workflowviewer")) {
            return "Tree";
        }
        else if (this.getRouterLink().includes("product-listing")) {
            return "Library";
        }
    }

    detectChanges() {

        setTimeout(() => {
            if (this.changedetector !== null &&
                this.changedetector !== undefined &&
                !(this.changedetector as ViewRef).destroyed) {
                this.changedetector.detectChanges();
            }

        }, 250);
    }

    destroyContainerFlyoutAdjustments() {
        this.isFlyoutVisible = true;
        this.detectChanges();
    }

    /**
     * Allow the user to reply to the 
     * timeout warning prompt. If the user
     * replies, reset the activity timer.
     */
    resetTimeout(): void {
        $('#session-timeout-modal').modal('hide');
        if (this.timeoutService.debugTimeout) console.log("*resetTimeout  (" + new Date() + ")");  
        this.timeoutService.start();
    }

    /**
     * Allow users to confirm that they 
     * have been logged out due to inactivity.
     * When they confirm, show them the login
     * prompt again.
     */
    confirmTimeout(): void {
        $('#session-timeout-notice-modal').modal('hide');
        if (this.timeoutService.debugTimeout) console.log("*confirmTimeout  (" + new Date() + ")");  
        $('#loginModal').css('visibility', '');
    }

    updateKSNavChild() {
        this.ksNavbar.loadItems();
    }

    tenantSubDropToggle(e: Event) {
        if (this.isTenantDropdownPrimary == true) {
            this.showSecondary();
        }
        else {
            this.showPrimary();
        }
        if (this.minutesUntilTimeout) {
            this.timeoutService.start();
        }
        e.stopPropagation();
        e.preventDefault();
    }

    showPrimary() {
        this.isTenantDropdownPrimary = true;
        $('.primaryDropdown').show();
        $('.secondaryDropdown').hide();

    }
    showSecondary() {
        this.isTenantDropdownPrimary = false;
        $('.primaryDropdown').hide();
        $('.secondaryDropdown').show();

    }

    switchTenant(toTenant: TenantWithLogo) {
        //message        
        this.isSwitching = true;
        this.pinnacleService.switchTenant(toTenant.organizationId, toTenant.tenantId.toString()).subscribe(res => {
            if (res != '') {
                this.pinnacleService.switchTenantV2(toTenant.organizationId, toTenant.tenantId.toString()).subscribe(res2 => {
                    ProdGenApi.setAPIV2BearerToken(res2);
                    // now call and get the V2 access key
                    window.location.href = "#/home";
                    window.location.reload();

                });

                ProdGenApi.setUserAccessKey(res);
                ProdGenApi.setSessionTenant(toTenant);
                ProdGenApi.setCurrentTenantId(toTenant.tenantId.toString());
                sessionStorage.setItem("currentTenant", toTenant.tenantId.toString());
                localStorage.setItem("lastUsedTenant", toTenant.tenantId.toString());

                // Need to keep track of this status across
                // the reload so that we don't use your key
                // to try and perform actions as the impersonation user.
                //ProdGenApi.setIsImpersonating(true);
            }
        },
            err => {

            });

    }

    setTenantSwitchModalVars(toTenant: TenantWithLogo) {
        this.destinationTenant = toTenant;
    }
}


export interface Themes {
    name: string;
    properties: any;
}
export const currenttheme: Themes = {
    name: "current",
    properties: {
        "--font-face": "arial",
        "--page-background-color": "#f0f2f3",
        "--page-text-color": "#505160",
        "--page-separator-color": "#d3d3d3",
        "--page-separator-width": "1px",
        "--navbar-background-color": "#6a8385",
        "--navbar-foreground-color": "#ffffff",
        "--hsl-background-color": "#3d464e",
        "--hsl-foreground-color": "#d8d9db",
        "--hsl-border-color": "#d3d3d3",
        "--hsl-border-width": "1px",
        "--hsr-background-color": "#f0f2f3",
        "--hsr-foreground-color": "#3d464e",
        "--hsr-border-color": "#000000",
        "--hsr-border-width": "0px",
        "--large-card-background-color1": "#3d464e",
        "--large-card-foreground-color1": "#ffffff",
        "--large-card-background-color2": "#85acb7",
        "--large-card-foreground-color2": "#ffffff",
        "--large-card-background-color3": "#6a8385",
        "--large-card-foreground-color3": "#ffffff",
        "--large-card-border-color": "#d3d3d3",
        "--large-card-border-width": "1px",
        "--large-card-border-radius": "0px",
        "--small-card-background-color1": "#85acb7",
        "--small-card-foreground-color1": "#ffffff",
        "--small-card-background-color2": "#3d464e",
        "--small-card-foreground-color2": "#ffffff",
        "--small-card-background-color3": "#6a8385",
        "--small-card-foreground-color3": "#ffffff",
        "--small-card-border-color": "#d3d3d3",
        "--small-card-border-width": "1px",
        "--small-card-border-radius": "0px",
        "--panel-title-background-color": "#3d464e",
        "--panel-title-foreground-color": "#d8d9db",
        "--panel-body-background-color": "#ffffff",
        "--panel-body-foreground-color": "#3d464e",
        "--panel-border-color": "#d3d3d3",
        "--panel-border-width": "1px",
        "--panel-border-radius": "0px",
        "--button-background": "#85acb7",
        "--button-foreground": "#ffffff"
    }
};
export const defpreviewtheme: Themes = {
    name: "preview",
    properties: {
        "--preview-font-face": "arial",
        "--preview-page-background-color": "#f0f2f3",
        "--preview-page-text-color": "#505160",
        "--preview-page-separator-color": "#d3d3d3",
        "--preview-page-separator-width": "1px",
        "--preview-navbar-background-color": "#6a8385",
        "--preview-navbar-foreground-color": "#ffffff",
        "--preview-hsl-background-color": "#3d464e",
        "--preview-hsl-foreground-color": "#d8d9db",
        "--preview-hsl-border-color": "#d3d3d3",
        "--preview-hsl-border-width": "1px",
        "--preview-hsr-background-color": "#f0f2f3",
        "--preview-hsr-foreground-color": "#3d464e",
        "--preview-hsr-border-color": "#000000",
        "--preview-hsr-border-width": "0px",
        "--preview-large-card-background-color1": "#3d464e",
        "--preview-large-card-foreground-color1": "#ffffff",
        "--preview-large-card-background-color2": "#85acb7",
        "--preview-large-card-foreground-color2": "#ffffff",
        "--preview-large-card-background-color3": "#6a8385",
        "--preview-large-card-foreground-color3": "#ffffff",
        "--preview-large-card-border-color": "#d3d3d3",
        "--preview-large-card-border-width": "1px",
        "--preview-large-card-border-radius": "0px",
        "--preview-small-card-background-color1": "#85acb7",
        "--preview-small-card-foreground-color1": "#ffffff",
        "--preview-small-card-background-color2": "#3d464e",
        "--preview-small-card-foreground-color2": "#ffffff",
        "--preview-small-card-background-color3": "#6a8385",
        "--preview-small-card-foreground-color3": "#ffffff",
        "--preview-small-card-border-color": "#d3d3d3",
        "--preview-small-card-border-width": "1px",
        "--preview-small-card-border-radius": "0px",
        "--preview-panel-title-background-color": "#3d464e",
        "--preview-panel-title-foreground-color": "#d8d9db",
        "--preview-panel-body-background-color": "#ffffff",
        "--preview-panel-body-foreground-color": "#3d464e",
        "--preview-panel-border-color": "#d3d3d3",
        "--preview-panel-border-width": "1px",
        "--preview-panel-border-radius": "0px",
        "--preview-button-background": "#85acb7",
        "--preview-button-foreground": "#ffffff"

    }
};
//export const christheme: Themes = {
//    name: "chris",
//    properties: {
//        "--font-face": " Segoe UI",
//        "--page-background-color": " #FFFFFF",
//        "--page-text-color": " #3D464E",
//        "--page-separator-color": " darkorange",
//        "--page-separator-width": " 2px",
//        "--navbar-background-color": " #283068",
//        "--navbar-foreground-color": " white",
//        "--hsl-background-color": " #283068",
//        "--hsl-foreground-color": "white",
//        "--hsl-border-color": " darkgray",
//        "--hsl-border-width": " 2px",
//        "--hsr-background-color": " orange",
//        "--hsr-foreground-color": " #3D464E",
//        "--hsr-border-color": " #3D464E",
//        "--hsr-border-width": " 2px",
//        "--large-card-background-color1": " #FFFFFF",
//        "--large-card-foreground-color1": " #3D464E",
//        "--large-card-background-color2": " #FFFFFF",
//        "--large-card-foreground-color2": " #3D464E",
//        "--large-card-background-color3": " #FFFFFF",
//        "--large-card-foreground-color3": " #3D464E",
//        "--large-card-border-color": " darkgray",
//        "--large-card-border-width": " 1px",
//        "--large-card-border-radius": " 15px",
//        "--small-card-background-color1": " #FFFFFF",
//        "--small-card-foreground-color1": " #3D464E",
//        "--small-card-background-color2": " #FFFFFF",
//        "--small-card-foreground-color2": " #3D464E",
//        "--small-card-background-color3": " #FFFFFF",
//        "--small-card-foreground-color3": " #3D464E",
//        "--small-card-border-color": " lightgray",
//        "--small-card-border-width": " 1px",
//        "--small-card-border-radius": " 10px",
//        "--panel-title-background-color": " lightgray",
//        "--panel-title-foreground-color": " #3D464E",
//        "--panel-body-background-color": " #F0F2F3",
//        "--panel-body-foreground-color": " #3D464E",
//        "--panel-border-color": " #3D464E",
//        "--panel-border-width": " 1px",
//        "--panel-border-radius": " 10px",
//        "--panel-button-background": " #3D464E",
//        "--panel-button-border": " #85ACB7",
//        "--panel-button-color": " white",
//        "--label-background": " #777",
//        "--label-foreground": " #777"
//    }
//};
//export const grosstheme: Themes = {
//    name: "gross",
//    properties: {
//        "--font-face": " arial",
//        "--page-background-color": " green",
//        "--page-text-color": " blue",
//        "--page-separator-color": " lightgray",
//        "--page-separator-width": " 1px",
//        "--navbar-background-color": " red",
//        "--navbar-foreground-color": " white",
//        "--hsl-background-color": " yellow",
//        "--hsl-foreground-color": " purple",
//        "--hsl-border-color": " black",
//        "--hsr-background-color": " teal",
//        "--hsr-foreground-color": " orange",
//        "--hsr-border-color": " black",
//        "--hsr-border-width": " 1px",
//        "--large-card-background-color1": " white",
//        "--large-card-foreground-color1": " black",
//        "--large-card-background-color2": " black",
//        "--large-card-foreground-color2": " white",
//        "--large-card-background-color3": " saddlebrown",
//        "--large-card-foreground-color3": " goldenrod",
//        "--large-card-border-color": " white",
//        "--large-card-border-width": " 1px",
//        "--large-card-border-radius": " 1px",
//        "--small-card-background-color1": " saddlebrown",
//        "--small-card-foreground-color1": " goldenrod",
//        "--small-card-background-color2": " white",
//        "--small-card-foreground-color2": " black",
//        "--small-card-background-color3": " black",
//        "--small-card-foreground-color3": " white",
//        "--small-card-border-color": " white",
//        "--small-card-border-width": " 1px",
//        "--small-card-border-radius": " 1px",
//        "--panel-title-background-color": " cyan !important",
//        "--panel-title-foreground-color": " deeppink !important",
//        "--panel-body-background-color": " tomato !important",
//        "--panel-body-foreground-color": " gold !important",
//        "--panel-border-color": " khaki",
//        "--panel-border-width": " 1px",
//        "--panel-border-radius": " 1px",
//        "--panel-button-background": " lavender !important",
//        "--panel-button-border": " olive !important",
//        "--panel-button-color": " maroon !important",
//        "--label-background": " mediumvioletred",
//        "--label-foreground": " darkgreen"
//    }
//};

//export const chris2theme: Themes = {
//    name: "chris2",
//    properties: {
//        "--font-face": " Segoe UI",
//        "--page-background-color": " #FFFFFF",
//        "--page-text-color": " #505160",
//        "--page-separator-color": " #598234",
//        "--page-separator-width": " 3px",
//        "--navbar-background-color": " #68829e",
//        "--navbar-foreground-color": " white",
//        "--hsl-background-color": " #505160",
//        "--hsl-foreground-color": " white",
//        "--hsl-border-color": " darkgray",
//        "--hsl-border-width": " 1px",
//        "--hsr-background-color": " lightgray",
//        "--hsr-foreground-color": " #3D464E",
//        "--hsr-border-color": " #3D464E",
//        "--hsr-border-width": " 1px",
//        "--large-card-background-color1": " #FFFFFF",
//        "--large-card-foreground-color1": " #3D464E",
//        "--large-card-background-color2": " #FFFFFF",
//        "--large-card-foreground-color2": " #3D464E",
//        "--large-card-background-color3": " #FFFFFF",
//        "--large-card-foreground-color3": " #3D464E",
//        "--large-card-border-color": " #505160",
//        "--large-card-border-width": " 1px",
//        "--large-card-border-radius": " 15px",
//        "--small-card-background-color1": " #FFFFFF",
//        "--small-card-foreground-color1": " #3D464E",
//        "--small-card-background-color2": " #FFFFFF",
//        "--small-card-foreground-color2": " #3D464E",
//        "--small-card-background-color3": " #FFFFFF",
//        "--small-card-foreground-color3": " #3D464E",
//        "--small-card-border-color": " #505160",
//        "--small-card-border-width": " 1px",
//        "--small-card-border-radius": " 10px",
//        "--panel-title-background-color": " #505160",
//        "--panel-title-foreground-color": " #D8D9DB",
//        "--panel-body-background-color": " #F0F2F3",
//        "--panel-body-foreground-color": " #3D464E",
//        "--panel-border-color": " #505160",
//        "--panel-border-width": " 1px",
//        "--panel-border-radius": " 4px",
//        "--panel-button-background": " #68829e",
//        "--panel-button-border": " #85ACB7",
//        "--panel-button-color": " white",
//        "--label-background": " #777",
//        "--label-foreground": " #777"
//    }
//};