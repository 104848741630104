import { Injectable, SecurityContext } from '@angular/core';
import { Router } from '@angular/router';
import { Component, OnInit, Input, OnChanges, SimpleChanges, SimpleChange, Output, EventEmitter } from '@angular/core';
import { Content, ContentType_T } from './../../apiService/classFiles/class.content';
import { Workgroup, WorkgroupMember, PermissionLevel_T } from './../../apiService/classFiles/class.workgroups';
import { ProdGenApi } from './../../apiService/prodgen.api';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
    selector: 'template-workcenter-card',
    templateUrl: './workcenter-card.component.html',
    styleUrls: ['./workcenter-card.component.css'],
    providers: [ProdGenApi]
})
export class WorkCenterCardComponent implements OnInit {

    @Input('workgroup') item: Workgroup;
    @Input('themeRotation') themeRotation: number;
    @Input('selectedWorkgroupId') selectedWorkgroupId: string = "";
    @Input('workgroupPermissionsLevel') currentUserPermissionsLevel: string;
    @Input('canCreateWG') currentUserCanCreateWG: boolean = false;
    @Input('isWGAdmin') currentUserisWGAdmin: boolean = false;
    @Output('selectionChanged') selectionChanged = new EventEmitter<any>();
    @Output('workgroupClicked') workgroupClicked = new EventEmitter<any>();
    @Output('settingsClicked') settingsClicked = new EventEmitter<any>();
    @Output('deleteClicked') deleteClicked = new EventEmitter<any>();
    @Output('copyClicked') copyClicked = new EventEmitter<any>();
    ownerString: string = "Owner(s): ";
    windowWidth: number = 0;

    constructor(private pinnacleService: ProdGenApi, private router: Router, private sanitizer: DomSanitizer) {
        if (this.item != null) {

        }
    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
        try {

            try {
                this.currentUserPermissionsLevel = changes['workgroupPermissionsLevel'].currentValue;
            } catch (err) { };
        } catch (err) { };

    }


    amIOwner(): boolean {


        if (this.currentUserPermissionsLevel == "owner" || this.currentUserisWGAdmin) {
            return true;
        }

        return false;
    }



    getClassList(): string {
        let classes: string = "col-xs-12 col-sm-4 col-md-4 col-lg-4";

        if (this.selectedWorkgroupId == this.item.workgroupId) {
            classes += " selected-card ";
        }
        else {
            classes += " home-card ";
        }
        let themeNum: number = (this.themeRotation % 3);


        if (themeNum == 0) {
            themeNum = 1;
        }
        else if (themeNum == 1) {
            themeNum = 2;
        }
        else if (themeNum == 2) {
            themeNum = 3;
        }

        classes += "largecardcolortheme" + themeNum;
        return classes;
    }

    onClickItem() {
        if (this.selectedWorkgroupId != this.item.workgroupId) {
            this.selectionChanged.emit({
                selectedItem: this.item.workgroupId
            });
        }

        this.workgroupClicked.emit({selectedItem: this.item.workgroupId});        

    }

    getItemName() {
        return this.item.name;
    }

    onClickSettings() {
        this.settingsClicked.emit({
            selectedItem: this.item.workgroupId
        });

    }

    onClickDelete() {
        this.deleteClicked.emit({
            selectedItem: this.item.workgroupId
        });
    }

  
    onCopyClick() {
        if (this.copyClicked != null) {
            this.copyClicked.emit({
                selectedItem: this.item.workgroupId
            });
        }
    }
    

}
