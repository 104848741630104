import { Component, OnInit, HostListener} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { trigger, state, style, transition, animate } from '@angular/animations';

import { ProdGenApi } from './../apiService/prodgen.api';
import { ContentType_T, DescriptionFilter_T, Video, Content, ContentUsedProductVersion, ContentUsedProduct, WebVideoTextTracksCue, ContentAuthoring} from './../apiService/classFiles/class.content';
import { BrowserAuthenticationService } from './../BrowserAuthenticationService';
import { TranslationService } from './../services/TranslationService';
import { AppControlService } from './../AppControlService'
import { UserPermissions } from '../apiService/classFiles/class.users';
import { SessionTimeoutService } from '../services/session-timeout.service';
import { mergeMap, tap, map } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import { Apiv2Service } from '../apiService/apiv2.service';

declare function makeAblePlayerReady(): void;
declare var $: any;

@Component({
    selector: 'app-videoviewer',
    templateUrl: './videoviewer.component.html',
    styleUrls: ['./videoviewer.component.css'],
    providers: [ProdGenApi],
    animations: [
        trigger('rotatedState', [
            state('default', style({ transform: 'rotate(0)' })),
            state('rotated', style({ transform: 'rotate(-180deg)' })),
            transition('rotated => default', animate('300ms ease-out')),
            transition('default => rotated', animate('300ms ease-in'))
        ])
    ]
})

export class VideoviewerComponent implements OnInit {
    contentId: string = '';
    video: Video = new Video();
    contentItem: Content = new Content();
    isAuthenticated: boolean = false;
    isValidURL: boolean = true;
    externalVideoDiv: SafeHtml;
    usedProducts: Array<string> = new Array<string>();
    productVersions: Array<ContentUsedProductVersion> = new Array<ContentUsedProductVersion>();
    products: Array<ContentUsedProduct> = new Array<ContentUsedProduct>();
    volume: string = '';
    webVideoTextTracksCues: Array<WebVideoTextTracksCue> = new Array<WebVideoTextTracksCue>();
    editableWebVideoTextTracksCues: Array<WebVideoTextTracksCue> = new Array<WebVideoTextTracksCue>();
    IsVisible = false;
    rotateState: string = 'default';
    findText: string = '';
    replaceText: string = '';
    numberOfOccurrences: number = 0;
    isCaseSensitive: boolean = false;
    audioPlayerUrl: String;
    cueOnFocus: number;
    noErrs: boolean = true;
    friendlyMsg: SafeHtml;
    userpermissions: UserPermissions = new UserPermissions();
    contentAuthoring: ContentAuthoring = new ContentAuthoring();


    constructor(private service: ProdGenApi, private route: ActivatedRoute, private _sanitizer: DomSanitizer, private authenticationService: BrowserAuthenticationService, private appService: AppControlService, private translationService: TranslationService,
        private timeoutService: SessionTimeoutService,
        private v2Service: Apiv2Service) {
        if (this.authenticationService.AuthenticatePage() == true) {
            this.isAuthenticated = true;
            this.video.description = this.translationService.getTranslationFileData("VIDEOVIEWER.TS_Loading");
        }
    }

    ngOnInit() {
        window.scroll(0, 0);
        this.contentItem.contentType = ContentType_T.video;

        this.service.getCurrentUserPermissions().subscribe(permissions => {
            this.userpermissions = permissions;

        });
        this.route.queryParams.subscribe(queryParams => {
            this.contentId = queryParams['id'];
            this.contentItem.contentId = this.contentId;

            this.service.GetVideoBookmarks(this.contentId).subscribe(b => {
                this.service.GetVideo(this.contentId, DescriptionFilter_T.formatted).subscribe(v => {
                    this.usedProducts = new Array<string>();
                    this.video = v;

                    this.service.GetContentUsedProducts(v.videoId, ContentType_T.video.toString()).subscribe(products => {
                        for (var i = 0; i < products.length; i++) {
                            this.products = products;
                            this.productVersions = this.products[i].Versions;

                            if (this.productVersions.length > 0) {
                                for (var j = 0; j < this.productVersions.length; j++) {
                                    this.usedProducts.push(this.products[i].Name + " " + this.productVersions[j].Name);
                                }
                            }
                            else {
                                this.usedProducts.push(this.products[i].Name.toString());
                            }
                        }
                    });

                    this.contentItem.contentId = v.videoId;
                    this.contentItem.contentType = ContentType_T.video;
                    this.contentItem.description = v.description;
                    this.contentItem.imageURL = "../assets/images/video.svg";
                    this.contentItem.name = v.name; 
                    this.contentItem.providedBy = v.providedBy;

                    this.getAuthoringContent(this.contentItem);

                    // add this to user history
                    this.service.addUserHistory(this.contentItem.contentId as string, this.contentItem.contentType).subscribe();

                    this.isValidURL = true;

                    if (v.videoURL.toLowerCase().includes("http") == false) {
                        this.isValidURL = false;
                    }
                    else if (v.videoURL.toLowerCase().includes(".mp4") == false) {
                        this.isValidURL = false;
                    }
                    if (this.isValidURL == false && this.isWebVideo() == false) {
                        this.video.videoURL = "pinnaclecommand://HYPERLINK?" + this.video.videoURL;
                    }

                    this.setupPlayer();                    

                }, (err) => {
                    //display friendly message to user so they are aware of what happened & have no access if they shouldn't
                    this.noErrs = false;
                    this.contentItem.name = this.translationService.getTranslationFileData("VIDEOVIEWER.TS_Error");

                    if (err.status == 400) {
                        //bad request caused by 0 data rows and/or no content ids for user - user has no access
                        this.friendlyMsg = this._sanitizer.bypassSecurityTrustHtml(this.translationService.getTranslationFileData("APP.AssetViewing0DRs"));
                    }
                    else {
                        //for now, let's give generic error as there are multiple errors possibly thrown by services (for which I added most common to translate file in case a specific one is wanted)
                        this.friendlyMsg = this._sanitizer.bypassSecurityTrustHtml(this.translationService.getTranslationFileData("APP.AssetViewingError"));
                    }

                });
            });
        });
    }

    setupPlayer(): void {
        var dateTimeParameter = new Date().getTime();
        this.externalVideoDiv = this.getExternalModalHTMLMessage();
        this.volume = sessionStorage.getItem("volume");

        if (this.volume == "" || this.volume == null) {
            this.volume = "7";
        }

        if ($('#video-transcript-parent') != null) {
            let transcriptDiv = `<div id="video-transcript"></div>`;
            $(`#video-transcript-parent #video-transcript`).remove();
            $('#video-transcript-parent').append(transcriptDiv);
        }

        if (this.webVideoTextTracksCues != undefined || this.webVideoTextTracksCues.length > 0) {
            this.webVideoTextTracksCues = new Array<WebVideoTextTracksCue>();
        }

        if (this.isValidURL == false) {
            this.video.hasTranscript = false;
            this.video.hasBookmark = false;
        }

        if (this.video.hasBookmark && this.video.hasTranscript) {
            document.getElementById("video-house").innerHTML =
                `<video id="video1" data-able-player data-lyrics-mode
                data-volume="` + this.volume + `"
                data-transcript-div="video-transcript"
                data-transcript-title="Transcript"
                data-chapters-title="Bookmarks" 
                preload="auto"
                style="max-width:100%;"
                src="` + this.getUniqueUrl(this.video.videoURL as string) + `" oncontextmenu="return false;">
          <source id= "vid-source" type= "video/mp4" />
          <track kind= "captions" src= "`+ this.getUniqueUrl(this.video.transcriptURL as string) + `" srclang="` + this.service.getCurrentLanguage() + `" />
          <track kind= "chapters" src= "`+ this.getUniqueUrl(this.video.bookmarkURL as string) + `" />
        </video>`;

            this.service.GetWebVideoTextTracksFile(this.video.videoId).subscribe(res => {
                this.webVideoTextTracksCues = res;
                this.audioPlayerUrl = this.video.videoURL;
                $('#audioPlayer').load();
            }, (err) => { console.log(err) });

        }
        else if (this.video.hasTranscript == true) {
            document.getElementById("video-house").innerHTML =
                `<video id="video1" data-able-player data-lyrics-mode
                data-volume="`+ this.volume + `"
                data-transcript-div="video-transcript"
                data-transcript-title="Transcript"
                preload="auto"
                style="max-width:100%;"
                src="` + this.getUniqueUrl(this.video.videoURL as string) + `" oncontextmenu="return false;">
          <source id="vid-source" type="video/mp4" />
          <track kind="captions" src="`+ this.getUniqueUrl(this.video.transcriptURL as string) + `" srclang="` + this.service.getCurrentLanguage() + `" />
        </video>`;

            this.service.GetWebVideoTextTracksFile(this.video.videoId).subscribe(res => {
                this.webVideoTextTracksCues = res;
                this.audioPlayerUrl = this.video.videoURL;
                $('#audioPlayer').load();
            }, (err) => { console.log(err) });
        }
        else if (this.video.hasBookmark == true) {
            document.getElementById("video-house").innerHTML =
                `<video id="video1" data-able-player
                data-volume="` + this.volume + `"
                preload="auto"
                data-chapters-title="Bookmarks"
                style="max-width:100%;"
                src="` + this.getUniqueUrl(this.video.videoURL as string) + `" oncontextmenu="return false;">
          <source id= "vid-source" type= "video/mp4" />
          <track kind= "chapters" src= "`+ this.getUniqueUrl(this.video.bookmarkURL as string) + `" />
        </video>`;
        }
        else {
            if (document.getElementById("video-house") != null) {
                if (this.isValidURL == false) {
                    document.getElementById("video-house").innerHTML = "";
                }
                else {
                    document.getElementById("video-house").innerHTML =
                        `<video id="video1" data-able-player
                data-volume="` + this.volume + `"
                preload="auto"
                style="max-width:100%;"
                src="` + this.getUniqueUrl(this.video.videoURL as string) + `" oncontextmenu="return false;">
          <!--<source id= "vid-source" type= "video/mp4" />-->
          <!--<track kind= "captions" src= "path_to_captions.vtt" />
          <track kind="descriptions" src= "path_to_descriptions.vtt" />-->
        </video>`;
                }
            }

        }

        window.scroll(0, 0);
        makeAblePlayerReady();

        // When a video is playing, do not log the user out
        // due to inactivity. This really should be a listener
        // in the timeout service directly, but using the renderer
        // there was very performance intensive at the time this
        // was added.
        const videoElement = document.querySelector('#video1');
        if (videoElement) {
            videoElement.addEventListener('playing', () => {
                this.timeoutService.videoPlaying = true;
                this.timeoutService.stop();
            });
            videoElement.addEventListener('pause', () => {
                this.timeoutService.videoPlaying = false;
                this.timeoutService.start();
            });
            videoElement.addEventListener('ended', () => {
                this.timeoutService.videoPlaying = false;
                this.timeoutService.start();
            });
        }
    }

    getUniqueUrl(source: string): string {
        let newSource = "";
        if (source != null) {
            if (source.length > 0) {
               
                if (source.indexOf("?") == -1) {
                    newSource = source + "?" + Date.now();
                }
                else {
                    newSource = source + "&" + Date.now();
                }
            }
        }

        return newSource;
    }

    getActiveDesc(): SafeHtml {
        return this._sanitizer.bypassSecurityTrustHtml(this.video.description as string);
    }

    isWebVideo(): boolean {
        if (this.video.videoURL.toLowerCase().startsWith("http")) {
            if ((this.video.videoURL.toLowerCase().endsWith("wmv") == false) &&
                (this.video.videoURL.toLowerCase().endsWith("avi") == false)) {
                return true;
            }
        }
        return false;
    }

    getExternalModalHTMLMessage(): SafeHtml {
        if (this.isWebVideo()) {

            return this._sanitizer.bypassSecurityTrustHtml(`
        <div id="externalvid" style="margin-top:20px;margin-left:10px;margin-right:0px; max-width:100%"> 
          <a href="` + this.video.videoURL + `" target="_blank" ><img src="../assets/images/empy_video.png" style="width:100%"></a>
        </div>`);
        }

        return this._sanitizer.bypassSecurityTrustHtml(`
      <div id="externalvid" style="margin-top:20px;margin-left:10px;margin-right:0px; max-width:100%"> 
        <a href="` + this.video.videoURL + `" ><img src="../assets/images/empy_video.png" style="width:100%"></a>
      </div>`);
    }

    @HostListener('mouseup', ['$event'])
    public handleMouseUpEvent(event: MouseEvent): void {
        var aud = <HTMLVideoElement>document.getElementById("video1");
        if (aud != null || aud != undefined) {
            sessionStorage.setItem("volume", (aud.volume * 10).toString());
        }
    }

    changeReplaceInputVisibility(): void {
        this.rotateState = (this.rotateState === 'default' ? 'rotated' : 'default');

        if (this.IsVisible) {
            this.IsVisible = false;
        }
        else {
            this.IsVisible = true;
        }
    }

    editTranscriptModalSetup(): void {
        document.getElementById('editTranscriptModal').style.zIndex = '100000';

        // Reset Modal Values
        this.editableWebVideoTextTracksCues = this.webVideoTextTracksCues;
        this.editableWebVideoTextTracksCues.map(cue => cue.focus = false);
        this.findText = '';
        this.replaceText = '';
        this.numberOfOccurrences = 0;

        // Find Able Player Time
        var videoCurrentTimeInSeconds = Number($('#video1')[0].currentTime);

        // Find Focus Cue
        var result = this.editableWebVideoTextTracksCues.find(cue =>
            videoCurrentTimeInSeconds >= this.GetSecondsFromTimeString(cue.start)
            && videoCurrentTimeInSeconds <= this.GetSecondsFromTimeString(cue.end)
        );

        if (result != null) {
            var count = parseInt(result.count);
            if (isNaN(count) == false) {
                this.cueOnFocus = count - 1;
                this.editableWebVideoTextTracksCues[this.cueOnFocus].focus = true;
                $('#editTranscriptModal').on('shown.bs.modal', function () {
                    $('#video1')[0].pause();
                    $("#audioPlayer")[0].currentTime = videoCurrentTimeInSeconds;
                    $('#inputFocusCue')[0].focus();
                });
                $('#editTranscriptModal').on('hidden.bs.modal', function () {
                    $("#audioPlayer")[0].pause();
                    $('#video1')[0].currentTime = $("#audioPlayer")[0].currentTime;
                });
            }
        }
    }

    updateTime(event): void {
        var result = this.editableWebVideoTextTracksCues.find(cue =>
            event.currentTarget.currentTime >= this.GetSecondsFromTimeString(cue.start)
            && event.currentTarget.currentTime <= this.GetSecondsFromTimeString(cue.end)
        );

        if (result != null) {
            var resultNum = parseInt(result.count) - 1;
            if (resultNum != this.cueOnFocus && isNaN(resultNum) == false) {
                if (this.cueOnFocus != null) {
                    this.editableWebVideoTextTracksCues[this.cueOnFocus].focus = false;
                }
                this.editableWebVideoTextTracksCues[resultNum].focus = true;
                this.cueOnFocus = resultNum;
                if ($('#inputFocusCue')[0] != null) {
                    $('#inputFocusCue')[0].focus();
                }
            }
        }
    }

    GetSecondsFromTimeString(time: string): number {
        var arr = time.split(':');
        if (arr.length == 3) {
            var hoursInSeconds = Number(arr[0]) * 60 * 60;
            var minutesInSeconds = Number(arr[1]) * 60;
            var seconds = Number(arr[2]);
            return hoursInSeconds + minutesInSeconds + seconds;
        }

        return 0;
    }

    deleteTranscriptModalSetup(): void {
        document.getElementById('deleteTranscriptModal').style.zIndex = '100000';
    }

    findAndReplaceModalSetup(): void {
        document.getElementById('findAndReplaceModal').style.zIndex = '100000';
    }

    onFindTextChange(text): void {
        if (text === null || text.match(/^ *$/) !== null) {
            this.numberOfOccurrences = 0;
        }
        else {
            if (this.isCaseSensitive) {
                this.numberOfOccurrences = this.editableWebVideoTextTracksCues.filter(cue => cue.text.includes(text)).length;
            }
            else {
                this.numberOfOccurrences = this.editableWebVideoTextTracksCues.filter(cue => cue.text.toUpperCase().includes(text.toUpperCase())).length;
            }
        }
    }

    onCaseSensitiveChange(isCaseSensitive): void {
        if (isCaseSensitive) {
            this.numberOfOccurrences = this.editableWebVideoTextTracksCues.filter(cue => cue.text.includes(this.findText)).length;
        }
        else {
            this.numberOfOccurrences = this.editableWebVideoTextTracksCues.filter(cue => cue.text.toUpperCase().includes(this.findText.toUpperCase())).length;
        }
    }

    onCueTextChange(id: string, text: string) {
        var count = parseInt(id);
        var newText = text.replace(")", " -");
        newText = newText.replace("(", "- ");
        this.editableWebVideoTextTracksCues[count - 1].text = newText;
    }

    findAndReplaceCueText(): void {
        for (let cue of this.editableWebVideoTextTracksCues) {
            if (this.isCaseSensitive) {
                if (cue.text.includes(this.findText)) {
                    var index = parseInt(cue.count) - 1;
                    var currentText = this.editableWebVideoTextTracksCues[index].text;
                    var newFindAndReplaceText = currentText.replace(this.findText, this.replaceText);
                    this.editableWebVideoTextTracksCues[index].text = newFindAndReplaceText;
                }
            } else {
                if (cue.text.toUpperCase().includes(this.findText.toUpperCase())) {
                    var index = parseInt(cue.count) - 1;
                    var currentText = this.editableWebVideoTextTracksCues[index].text;
                    var regEx = new RegExp(this.findText, "ig");
                    var newFindAndReplaceText = currentText.replace(regEx, this.replaceText);
                    this.editableWebVideoTextTracksCues[index].text = newFindAndReplaceText;
                }
            }
        }
    }


    updateWebVideoTextTracksFileModal(): void {
        this.service.UpdateWebVideoTextTracksFile(this.video.videoId, this.editableWebVideoTextTracksCues).subscribe(res => {
            this.webVideoTextTracksCues = new Array<WebVideoTextTracksCue>();
            this.editableWebVideoTextTracksCues = new Array<WebVideoTextTracksCue>();
            this.setupPlayer();

        }, (err) => { console.log(err) });
    }

    deleteWebVideoTextTracksFile(): void {
        this.service.DeleteWebVideoTextTracksFile(this.video.videoId).subscribe(res => {
            this.webVideoTextTracksCues = new Array<WebVideoTextTracksCue>();
            this.editableWebVideoTextTracksCues = new Array<WebVideoTextTracksCue>();
            this.video.hasTranscript = false;
            this.video.transcriptURL = null;
            this.setupPlayer();

        }, (err) => { console.log(err) });
    }

    cancelWebVideoAudioFileModal(): void {
        $('#audioPlayer')[0].pause();
    }

    getAuthoringContent(c: Content) {
        if (c.contentId != null) {
            this.service.getContentAuthoringData(c.contentId, c.contentType.toString()).subscribe(d => {
                if (d != null && d.lastModBy != "") {
                    this.contentAuthoring.contentId = c.contentId.toString();
                    this.contentAuthoring.lastModBy = d.lastModBy;
                    this.contentAuthoring.lastModDate = d.lastModDate;
                    this.contentAuthoring.lastModDateStr = d.lastModDateStr;
                }
            });
        }
        else {
            this.contentAuthoring = new ContentAuthoring();
        }
    }
}
