// Angular imports
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ChangeDetectorRef, SimpleChanges } from '@angular/core';
import { DatePipe } from '@angular/common';

// Pinnacle imports
import { ProdGenApi } from '../../apiService/prodgen.api';
import { TranslationService } from '../../services/TranslationService';
import { LiveEventProperty, LiveEventPropertyType, LiveEventPropertyValueType } from '../../../app/apiService/classFiles/class.liveevents';
import { StarRatingComponent } from '../starrating/starrating.component';
import { Router } from '@angular/router';
import { ContentType_T } from './../../apiService/classFiles/class.content';

// JQuery workaround
declare var $: any;

@Component({
    selector: 'template-liveeventproperties',
    templateUrl: 'liveeventproperties.component.html',
    styleUrls: ['liveeventproperties.component.css'],
    providers: [ProdGenApi]
})

export class LiveEventPropertiesComponent implements OnInit {

    @Input() properties: Array<LiveEventProperty> = [];
    @Output() propertiesChange: EventEmitter<Array<LiveEventProperty>> = new EventEmitter<Array<LiveEventProperty>>();

    @Input() propertyTypes: Array<LiveEventPropertyType> = [];
    @Input() allowEditing: boolean = false;

    filteredProperties: Array<LiveEventProperty>;

    @ViewChild(StarRatingComponent, { static: false }) ratingComponent;

    constructor(private changeRef: ChangeDetectorRef, private router: Router) {

    }

    detectChanges(): void {
        this.changeRef.detectChanges();
        this.filteredProperties = new Array<LiveEventProperty>();
        for (let i = 0; i < this.properties.length; i++) {
            if ((this.propertyTypes.findIndex(x => x == this.properties[i].propertyType) != -1) && this.properties[i].isVisibleInBrowser) {
                if (this.properties[i].valueType == LiveEventPropertyValueType.Number && (this.properties[i].numberValue == undefined || this.properties[i].numberValue < 0)) {
                    this.properties[i].numberValue = 0;
                }
                if (this.properties[i].valueType == LiveEventPropertyValueType.MultipleChoice) {
                    this.properties[i].guidValue = this.properties[i].mcChoices[0].choiceID;
                    this.properties[i].mcChoices[0].isSelected = true;
                }
                this.filteredProperties.push(this.properties[i]);
            }
        }
    }

    ngOnInit(): void {
        this.filteredProperties = new Array<LiveEventProperty>();
        for (let i = 0; i < this.properties.length; i++) {
            if ((this.propertyTypes.findIndex(x => x == this.properties[i].propertyType) != -1) && this.properties[i].isVisibleInBrowser) {
                if (this.properties[i].valueType == LiveEventPropertyValueType.Number && (this.properties[i].numberValue == undefined || this.properties[i].numberValue < 0)) {
                    this.properties[i].numberValue = 0;
                }
                if (this.properties[i].valueType == LiveEventPropertyValueType.MultipleChoice) {
                    this.properties[i].guidValue = this.properties[i].mcChoices[0].choiceID;
                    this.properties[i].mcChoices[0].isSelected = true;
                }
                this.filteredProperties.push(this.properties[i]);
            }
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        this.detectChanges();
    }

    submitProperties(): void {
        this.propertiesChange.emit(this.properties);
    }

    mcPropertyChanged(property: LiveEventProperty, value: string): void {
        property.guidValue = value;
        let choiceIndex = property.mcChoices.findIndex(choice => choice.choiceID == value);
        property.mcChoices[choiceIndex].isSelected = true;
    }

    getMCChoiceText(property: LiveEventProperty): string {
        if (property != undefined && property.valueType == LiveEventPropertyValueType.MultipleChoice && property.guidValue != undefined) {
            let choice = property.mcChoices.find(choice => choice.choiceID == property.guidValue);
            if (choice != undefined && choice.choiceText != undefined)
                return choice.choiceText;
        }
        return "";
    }

    trackByIndex(index: number, obj: any): any {
        return index;
    }

    validateNumberProp(property: LiveEventProperty, value: number): void {
        value >= 0 ? property.numberValue = value : property.numberValue = 0;
    }


    contentLink(prop: LiveEventProperty) {
        let url: string = "";

        if (prop.numberValue == ContentType_T.workflow) {
            url += "workflowviewer?id=" + prop.guidValue + "&contentType=workflow";
        }
        else if (prop.numberValue == ContentType_T.process) {
            url += "workflowviewer?id=" + prop.guidValue+ "&contentType=process";
        }
        else if (prop.numberValue == ContentType_T.task) {
            url += "workflowviewer?id=" + prop.guidValue + "&contentType=task";
        }
        else if (prop.numberValue == ContentType_T.step) {
            url += "workflowviewer?id=" + prop.guidValue + "&contentType=step";
        }

        else if (prop.numberValue == ContentType_T.cheatsheet) {
            url += "cheatsheetviewer?id=" + prop.guidValue;
        }
        else if (prop.numberValue == ContentType_T.video) {
            url += "videoviewer?id=" + prop.guidValue;
        }
        else if (prop.numberValue== ContentType_T.learningpath) {
            url += "learningcenter/series?learningPathId=" + prop.guidValue;
        }
        else if (prop.numberValue == ContentType_T.course) {
            url += "learningcenter/series?courseId=" + prop.guidValue;
        }
        if (url != "") {
            this.router.navigateByUrl(url).then(i => {
                if (i == false) {
                    console.log("unable to navigate");
                }
            });
        }
        else { console.log("unable to navigate");}

    }

    //cheatsheet = 0,
    //learningpath = 1,
    //course = 2,
    //quiz = 3,
    //video = 4,
    //workflow = 5,
    //process = 6,
    //task = 7,
    //step = 8,
    //chatLog = 9,
    //workgroup = 10,
    //trainingclass = 11,
    //scormcourse = 12,
    //extendedSearch = 99



    //getContentUrl(r: Content): string {
   
    //}
}