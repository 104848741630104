import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, Route, Params } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import cssVars from 'css-vars-ponyfill';

import { ProdGenApi } from '../../apiService/prodgen.api';
import { BrowserAuthenticationService } from '../../BrowserAuthenticationService';
import { TranslationService } from '../../services/TranslationService';
import { LiveEventSurvey, LiveEventProperty, LiveEventWrapper, LiveEventBrowserCalendarStatus, LiveEventPropertyType, LiveEventRegistrant, LiveEventRegistrationType } from '../../apiService/classFiles/class.liveevents';
import { AppComponent } from '../../../app/app.component';
import { ContentPrerequisite, PSType_1310 } from '../../../app/apiService/classFiles/class.content';
import { UserOrgInfoObject } from '../../../app/apiService/classFiles/class.authorization';
import { PinnacleDateTimePipe } from "../../pipes/pinnacleDateTime";
import { User } from '../../apiService/classFiles/class.users';
import { LifecycleHooks } from '@angular/compiler/src/lifecycle_reflector';

declare var $: any; // JQuery workaround

@Component({
    selector: 'external-liveeventregistration',
    templateUrl: 'liveeventregistration.component.html',
    styleUrls: ['liveeventregistration.component.css'],
    providers: [ProdGenApi]
})

export class LiveEventRegistrationComponent implements OnInit, AfterViewInit {

    currentDate: Date = new Date(Date.now());
    userEmail: string = "";
    userOrgInfo: UserOrgInfoObject;
    userEmailValid: boolean = true;
    linkInfo: string = "";
    platformName: string = "Pinnacle Series";
    loadingLogo: string = "../../../assets/images/ps_browser_logo.png";
    pageStatus: LERegistrationPageStatus = LERegistrationPageStatus.Loading;
    safeHTMLFormattedDescription: SafeHtml = "";
    eventDateString: string = "";
    eventDurationString: string = "";
    datepipe: DatePipe;
    panelBackgroundColorIsWhite: boolean = false;
    liveEvent: LiveEventWrapper;
    linkIsGeneric: boolean = true;
    registrationResult: string = "NOT_ATTEMPTED";
    registrationDetails: string = "";
    hasInfoProperties: boolean = false;
    hasRegistrationProperties: boolean = false;
    userRegistrationInfo: LiveEventRegistrant = new LiveEventRegistrant();
    currentUser: User;
    incompletePrerequisites: Array<ContentPrerequisite> = [];
    registrationURLOverride: string = "";

    constructor(private pinnacleService: ProdGenApi,
        private route: ActivatedRoute,
        private router: Router,
        private translationService: TranslationService,
        private sanitizer: DomSanitizer,
        private authenticationService: BrowserAuthenticationService) {
    }

    ngOnInit(): void {
        if (this.authenticationService.AuthenticatePage() == true) {
            try {
                if (window.location.href.toLowerCase().includes("productivitynow")) {
                    this.platformName = "ProductivityNOW";
                    this.loadingLogo = "../../../assets/images/pnow_logo.png";
                }

                // Prefer using the old p_linkInfo parameter, but support
                // the new sessionId parameter so that we can accommodate the
                // shorter /liveeventviewer?sessionId=... route.
                this.route.queryParams.subscribe(params => {
                    if (params["p_linkInfo"] == undefined
                        && params["sessionId"] == undefined) {
                        this.linkInfo = "";
                    }
                    else if (params["p_linkInfo"] != undefined) {
                        this.linkInfo = params["p_linkInfo"];
                        this.tryGetRegistrationInfo();
                    }
                    else if (params["sessionId"] != undefined) {
                        this.linkInfo = params["sessionId"];
                        this.tryGetRegistrationInfo();
                    }

                    if (params["productivitynow"] != undefined && params["productivitynow"] == "true") {
                        this.loadingLogo = "../../../assets/images/pnow_pinnacle.jpg";
                        this.platformName = "ProductivityNOW";
                    }
                });
            }
            catch (e) {
            }
        }
    }

	sanitize(url: string) {
		return this.sanitizer.bypassSecurityTrustUrl(url);
	}

    ngAfterViewInit(): void {
    }

    tryGetRegistrationInfo(): void {
        try {
            this.pinnacleService.leGetRegistrationInfo(this.linkInfo).subscribe(res => {
                this.liveEvent = res;
                this.liveEvent.registrationStartDate = PinnacleDateTimePipe.toLocalDateTime(new Date(this.liveEvent.registrationStartDate));
                this.liveEvent.registrationEndDate = PinnacleDateTimePipe.toLocalDateTime(new Date(this.liveEvent.registrationEndDate));

                for (let i = 0; i < this.liveEvent.properties.length; i++) {
                    if (this.liveEvent.properties[i].propertyName == "registration_URL_override") {
                        this.registrationURLOverride = this.liveEvent.properties[i].stringValue;
                    }
                }

                if (this.liveEvent.eventID != "00000000-0000-0000-0000-000000000000" && this.liveEvent.sessionID != "00000000-0000-0000-0000-000000000000") {
                    if (this.liveEvent.useRegistrationDates
                        && (this.liveEvent.registrationStartDate > this.currentDate
                            || this.liveEvent.registrationEndDate < this.currentDate)) {
                        this.pageStatus = LERegistrationPageStatus.OutsideRegistrationWindow;
                    }
                    else {
                        this.pinnacleService.getCurrentUser().subscribe(usr => {
                            this.currentUser = usr;

                            this.pinnacleService.leGetUserRegistrationInfo(this.liveEvent.sessionID, this.currentUser.userId, [], false).subscribe(reg => {
                                this.userRegistrationInfo = reg;
                                if (this.userRegistrationInfo.registrationStatus == LiveEventRegistrationType.Registered || this.userRegistrationInfo.registrationStatus == LiveEventRegistrationType.Waitlisted) {
                                    this.pageStatus = LERegistrationPageStatus.Revisit;
                                }
                                else {
                                    this.liveEvent.startDate = PinnacleDateTimePipe.toLocalDateTime(new Date(res.startDate));
                                    this.liveEvent.endDate = PinnacleDateTimePipe.toLocalDateTime(new Date(res.endDate));
                                    this.liveEvent.registrationStartDate = PinnacleDateTimePipe.toLocalDateTime(new Date(res.registrationStartDate));
                                    this.liveEvent.registrationEndDate = PinnacleDateTimePipe.toLocalDateTime(new Date(res.registrationEndDate));
                                    this.safeHTMLFormattedDescription = this.sanitizer.bypassSecurityTrustHtml(this.liveEvent.formattedDescription as string);
                                    this.setEventDateString();
                                    this.setSelectedEventDurationString();

                                    this.liveEvent.sessionStatus = this.userRegistrationInfo.registrationType;

                                    for (let i = 0; i < this.liveEvent.properties.length; i++) {
                                        if (this.hasInfoProperties && this.hasRegistrationProperties) {
                                            continue;
                                        }
                                        if (this.liveEvent.properties[i].propertyType == LiveEventPropertyType.EventSessionProperty) {
                                            this.hasInfoProperties = true;
                                        }
                                        if (this.liveEvent.properties[i].propertyType == LiveEventPropertyType.RegistrationField) {
                                            this.hasRegistrationProperties = true;
                                        }
                                    }

                                    this.pageStatus = LERegistrationPageStatus.Registration;
                                    this.panelBackgroundColorIsWhite = (AppComponent.panelBodyBackgroundColor == "#ffffff");
                                }
                            });
                        });
                    }
                }
                else {
                    if (this.liveEvent.name == "SESSION_RESTRICTED")
                        this.pageStatus = LERegistrationPageStatus.Restricted;
                    else
                        this.pageStatus = LERegistrationPageStatus.ErrorLoading;
                }
            });
        }
        catch (e) {
            this.pageStatus = LERegistrationPageStatus.ErrorLoading;
        }
    }

    setEventDateString(): void {
        this.eventDateString = PinnacleDateTimePipe.transform(this.liveEvent.startDate, [{ range: this.liveEvent.endDate }]);
    }

    setSelectedEventDurationString(): void {
        // selectedEvent.duration = 135 (mins) => selectedEventDurationString = "2 hours, 15 minutes"
        this.eventDurationString = "";
        let remainder = this.liveEvent.duration;

        // Reverse-nesting to reduce code length
        if (this.liveEvent.duration >= 60) {
            if (this.liveEvent.duration >= 1440) {
                if (this.liveEvent.duration > 10080) {
                    // duration is at least a week, calculate how many and reduce the running duration value "remainder"
                    this.eventDurationString += ~~(this.liveEvent.duration / 10080) + (~~(this.liveEvent.duration / 10080) == 1 ? " week, " : " weeks, ");
                    remainder = remainder % 10080;
                }
                // If duration has some day component, do the same as above
                if (remainder / 1440 > 0) {
                    this.eventDurationString += ~~(remainder / 1440) + (~~(remainder / 1440) == 1 ? " day, " : " days, ");
                    remainder = remainder % 1440;
                }
            }
            // If duration has some hour component, do the same as above
            if (remainder / 60 > 0) {
                this.eventDurationString += ~~(remainder / 60) + (~~(remainder / 60) == 1 ? " hour, " : " hours, ");
                remainder = remainder % 60;
            }
        }
        // If duration has some minute component, do the same as above
        if (remainder > 0) {
            this.eventDurationString += remainder + (remainder == 1 ? " minute, " : " minutes, ");
        }

        // Remove trailing ", "
        this.eventDurationString = this.eventDurationString.slice(0, -2);
    }

    submitRegistration(): void {
        //if (this.registrationURLOverride != "") {
        //    window.open(this.registrationURLOverride, "_blank");
        //}

        let userResponses = new Array<LiveEventProperty>();
        for (let i: number = 0; i < this.liveEvent.properties.length; i++) {
            if (this.liveEvent.properties[i].propertyType == LiveEventPropertyType.RegistrationField) {
                userResponses.push(this.liveEvent.properties[i]);
            }
        }

        if (this.registrationResult == "NOT_ATTEMPTED") {
            this.pinnacleService.leRegisterForEvent(this.liveEvent.sessionID, userResponses).subscribe(res => {
                let result: LiveEventWrapper = res;
                if (result.sessionStatus == 0) {
                    this.pageStatus = LERegistrationPageStatus.Completed;
                    if (this.registrationURLOverride != "") {
                        window.open(this.registrationURLOverride, "_blank");
                    }
                }
                else {
                    this.registrationResult = result.presenterDisplayName;
                    this.registrationDetails = result.presenterDisplayName;
                    switch (this.registrationResult) {
                        case "REGISTRATION_RESTRICTED":
                            this.registrationResult = this.translationService.getTranslationFileData("LIVE_EVENT_REGISTRATION.NoAccess");
                            break;
                        case "PREREQUISITES_NOT_MET":
                        case "SESSION_FULL_NO_WAITLIST&PREREQUISITES_NOT_MET":
                        case "SESSION_FULL_BUT_WAITLIST&PREREQUISITES_NOT_MET":
                            this.registrationResult = this.translationService.getTranslationFileData("LIVE_EVENT_REGISTRATION.PrereqFail");
                            this.pinnacleService.leGetIncompletePrerequisites(this.liveEvent.eventID).subscribe(res => {
                                this.incompletePrerequisites = res;
                            });
                            break;
                        case "SESSION_FULL_NO_WAITLIST":
                            this.registrationResult = this.translationService.getTranslationFileData("LIVE_EVENT_REGISTRATION.SessionFullNoWL");
                            break;
                        case "SESSION_FULL_BUT_WAITLIST":
                            this.registrationResult = this.translationService.getTranslationFileData("LIVE_EVENT_REGISTRATION.SessionFullWL");
                            break;
                        default:
                            this.registrationResult = this.translationService.getTranslationFileData("LIVE_EVENT_REGISTRATION.NoInfo");
                            break;
                    }
                    this.pageStatus = LERegistrationPageStatus.ErrorRegistering;
                    window.scroll(0, 0);
                }
            });
        }
    }

    redirectToLogin(): void {
        AppComponent.thisFromOutside.isExternalPage = false;
        this.router.navigateByUrl("/#/home");
        //window.location.href = ("http://" + window.location.hostname + (window.location.port != undefined ? ":" + window.location.port : "") + "/#/login");
    }
}

enum LERegistrationPageStatus {
    Loading,
    ErrorLoading,
    Registration,
    ErrorRegistering,
    Completed,
    Revisit,
    Restricted,
    OutsideRegistrationWindow
}