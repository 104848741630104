import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class AppControlService {

	private cheatSheetPopupIdSource = new Subject<string>();
    private messageBoxPopupSource = new Subject<MsgBxStringVals>();
	private videoPopupIdSource = new Subject<string>();
	private closePopUpsSource = new Subject<boolean>();

	cheatSheetPopupID$ = this.cheatSheetPopupIdSource.asObservable();
	videoPopupID$ = this.videoPopupIdSource.asObservable();
    messageBoxPopup$ = this.messageBoxPopupSource.asObservable();
	closePopUps$ = this.closePopUpsSource.asObservable();


	resetCheatSheetPopUpFlags() {
		this.cheatSheetPopupIdSource.next("");
	}

	openCheatSheetPopUp(cheatsheetId: string) {
		this.cheatSheetPopupIdSource.next(cheatsheetId);
	}

	openVideoPopUp(videoId: string) {
		this.videoPopupIdSource.next(videoId);
	}

    openMessageBoxPopUp(message: MsgBxStringVals) {
        this.messageBoxPopupSource.next(message);
    }

	closePopUps() {
		this.closePopUpsSource.next(true);
	}
}

export class MsgBxStringVals {
    public title: string;
    public body: string;
}