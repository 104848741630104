import { Pipe, PipeTransform, ElementRef } from '@angular/core';


@Pipe({ name: 'Truncate' })
export class TruncatePipe implements PipeTransform {


	constructor(private el: ElementRef) { }

	transform(stringVal:string)
	{
		
		return stringVal;
	}
}