import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { ProdGenApi } from './../../apiService/prodgen.api';
import { Content, ContentType_T } from './../../apiService/classFiles/class.content';
import { User } from './../../apiService/classFiles/class.users';
import { TranslationService } from './../../services/TranslationService';
import { AppControlService } from '../../AppControlService';
import { APIV2AccessKey } from './../../apiService/classFiles/class.authorization';

declare var $: any;

@Component({
    selector: 'template-changepassword',
    templateUrl: './changepassword.component.html',
    styleUrls: ['./changepassword.component.css']
})

export class ChangePasswordComponent implements OnInit {
  
    @Output('passwordChangedEvent') passwordChangedEvent?= new EventEmitter<any>();



	ssoSetting: boolean = false;
	passwordPolicyEnforcedSetting: boolean = false;
	complexPasswordSetting: boolean = false;
	passwordExpiresSetting: boolean = false;
	passwordExpiresCount: number = 0;
	passwordDifferentSetting: boolean = false;
	passwordDiferentCount: number = 0;

	public oldPassword: string = "";
	public newPassword: string = "";
	public confirmPassword: string = "";
	public passwordchangeValid: boolean = true;


	pswdChangeOpen: boolean = false;
	pswdChangeError: boolean = false;
	pswdErrorText: string = "";
	oldPasswordError: boolean = false;
	newPasswordError: boolean = false;
	confirmNewPasswordError: boolean = false;
	passwordgGuidlinesError: boolean = false; 

	public passwordChanged: boolean = false;

    savedAccessKey: string = "";
    savedAPIv2AccessKey: APIV2AccessKey;

	hasLoaded: boolean = false;


  constructor(private pinnacleService: ProdGenApi, private changeRef: ChangeDetectorRef, private translationService: TranslationService, private controlService: AppControlService) {
        //this.loadData();

}

    


    ngOnInit() {

    }

    loadData()
    {
		this.hasLoaded = false; 
        this.oldPassword= "";
	    this.newPassword = "";
	    this.confirmPassword = "";
		this.passwordchangeValid = true;
		this.pswdChangeOpen = false;
		this.pswdChangeError = false;
		this.pswdErrorText = "";
		this.oldPasswordError = false;
		this.newPasswordError = false;
		this.confirmNewPasswordError = false;
		this.passwordgGuidlinesError = false;

	    this.passwordChanged = false;

        this.savedAccessKey = ProdGenApi.getUserAccessKey();
        this.savedAPIv2AccessKey = ProdGenApi.getAPIV2AccessKey();

        ProdGenApi.setUserAccessKey("");
        ProdGenApi.setAPIV2BearerToken(new APIV2AccessKey());


		this.pinnacleService.GetCurrentTenantSettingsWithKey(this.savedAccessKey).subscribe(res => {


			res.forEach((setting) => {
				if (setting.name == "PWD_ENFORCE_POLICY") {
          this.passwordPolicyEnforcedSetting = Boolean(setting.settingValue);
				}
				else if (setting.name == "PWD_USE_COMPLEX_PASSWORDS") {
          this.complexPasswordSetting = Boolean(setting.settingValue);
				}
				else if (setting.name == "PWD_PASSWORD_EXPIRES") {
          this.passwordExpiresSetting = Boolean(setting.settingValue);
				}
				else if (setting.name == "PWD_EXPIRATION_DURATION") {
					this.passwordExpiresCount = setting.settingValue as number;
				}
				else if (setting.name == "PWD_MUST_BE_UNIQUE") {
          this.passwordDifferentSetting = Boolean(setting.settingValue);
				}
				else if (setting.name == "PWD_UNIQUE_HISTORY_COUNT") {
					this.passwordDiferentCount = setting.settingValue as number;
				}
				else if (setting.name == "PWD_DISABLE_WINDOWS_AUTH") {
          this.ssoSetting = Boolean(setting.settingValue);
				}                
				
			});

			this.hasLoaded = true;

			this.changeRef.detectChanges();
			
			
		});
	}

	changePasswordClicked() {
      this.pswdChangeOpen = true;

	}

	resetKeys() {
        ProdGenApi.setUserAccessKey(this.savedAccessKey);
        ProdGenApi.setAPIV2BearerToken(this.savedAPIv2AccessKey);
	}

	onPasswordCancel() {
		this.pswdChangeOpen = false;
		this.pswdChangeError = false;
		this.oldPassword = "";
		this.newPassword = "";
		this.oldPasswordError = false;
		this.newPasswordError = false;
		this.passwordgGuidlinesError = false;
		this.confirmNewPasswordError = false;
		this.confirmPassword = "";
      this.pswdErrorText = "";

      $("#changeUserPassword").modal("hide");
	}


	passwordChangedSuccessfully(): boolean {


		return this.passwordChanged;
	}


	onPasswordSubmit() {

		this.pswdChangeError = false;
		this.newPasswordError = false;
		this.oldPasswordError = false;
		this.confirmNewPasswordError = false;
		this.passwordgGuidlinesError = false;

		if (this.oldPassword == "") {
			this.oldPasswordError = true;
          this.pswdChangeError = true;
		}
		else {
          this.oldPasswordError = false;
		}

		if (this.newPassword == "") {
			this.newPasswordError = true;
          this.pswdChangeError = true;
		}
		else {
          this.newPasswordError = false;
		}

		if (this.confirmPassword == "")
        {
			this.confirmNewPasswordError = true;
          this.pswdChangeError = true;
        }

		if (this.pswdChangeError) {
          this.pswdErrorText = this.translationService.getTranslationFileData("CHANGEPASSWORD.TS_FillPassword");
          this.changeRef.detectChanges();
			return;
		}

      if (this.confirmPassword != this.newPassword) {
        
			this.confirmNewPasswordError = true;
			this.newPasswordError = true;
			this.pswdChangeError = true;
        this.pswdErrorText = this.translationService.getTranslationFileData("CHANGEPASSWORD.TS_ComparePassword");
        this.changeRef.detectChanges();
			return;
		}

		if (this.passwordPolicyEnforcedSetting == true) {
			this.pinnacleService.isNewPasswordValidWithGivenKey(this.newPassword, this.savedAccessKey).subscribe(res => {
				if (res == true) {
					this.pinnacleService.updateCurrentUserPasswordWithGivenKey(this.oldPassword, this.newPassword, this.savedAccessKey).subscribe(res2 => {
						if (res2 == true) {

                          this.passwordChanged = true;
                          this.changeRef.detectChanges();
                          //this.controlService.openMessageBoxPopUp("Password Successfully Changed.");
                          //this.changeRef.detectChanges();
                            /*
							this.passwordChangedEvent.emit({
								passwordChanged: this.passwordChanged
							});
                            */

							//this.onPasswordCancel();

                          //$("#PassChangeSuccess").modal("show");
							//$("#changeUserPassword").modal("hide");
						}
						else {
							this.pswdChangeError = true;
							this.pswdErrorText = this.translationService.getTranslationFileData("CHANGEPASSWORD.TS_UpdateFail");
							this.oldPassword = "";
                            this.newPassword = "";
                          this.confirmPassword = "";
                          this.changeRef.detectChanges();
						}
					});
				}
				else {
					this.pswdChangeError = true;
					this.pswdErrorText = this.translationService.getTranslationFileData("CHANGEPASSWORD.TS_EnforcementFail");
                  this.passwordgGuidlinesError = true;
                  this.changeRef.detectChanges();
				}
			});
		}
		else {
			this.pinnacleService.updateCurrentUserPasswordWithGivenKey(this.oldPassword, this.newPassword, this.savedAccessKey).subscribe(res2 => {

				if (res2 == true) {
                  this.passwordChanged = true;
                  this.changeRef.detectChanges();
                  //this.controlService.openMessageBoxPopUp("Password Successfully Changed.");
                  //this.changeRef.detectChanges();
                    /*
					this.passwordChangedEvent.emit({
						passwordChanged: this.passwordChanged
					});
                    */

					//this.onPasswordCancel();	
                  //$("#PassChangeSuccess").modal("show");
					//$("#changeUserPassword").modal("hide");
                  
				}
				else {
					this.pswdChangeError = true;
					this.pswdErrorText = this.translationService.getTranslationFileData("CHANGEPASSWORD.TS_UpdateFail");
					this.oldPassword = "";
                    this.newPassword = "";
                  this.confirmPassword = "";
                  this.changeRef.detectChanges();
				}
			});
      }
      this.changeRef.detectChanges();
	}

  closeModals() {
    $("#changeUserPassword").modal("hide");
    $("#PassChangeSuccess").modal("hide");
  }

    /*
     this.imageChanged.emit({
	imageURL: this.imageURL
});
    */
}
