import { Location } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from "@angular/router";
import { AppComponent } from '../app.component';
import { Content, ContentAuthoring, ContentType_T, ContentUsedProduct, ContentUsedProductVersion, DescriptionFilter_T, DiagramView, Step, Task } from "./../apiService/classFiles/class.content";
import { ProdGenApi } from './../apiService/prodgen.api';
import { BrowserAuthenticationService } from './../BrowserAuthenticationService';
import { TranslationService } from './../services/TranslationService';
import { DiagramViewComponent } from './../templates/diagram-view/diagram-view.component';
import { WorkflowtreeComponent } from './../templates/workflowtree/workflowtree.component';

declare var $: any;


@Component({
    selector: 'app-workflowviewer',
    templateUrl: './workflowviewer.component.html',
    styleUrls: ['./workflowviewer.component.css'],
    providers: [ProdGenApi]
})
export class WorkflowviewerComponent implements OnInit {

  diagramType: string = "workflow";
  diagramId: string = ""; 
  contentItem: Content = new Content(); // good for any active piece of content..task, step, etc
  diagramItem: Content = new Content(); // always points to info about the current diagram (workflow or process)...so we don't have to reload constantly'
  description: string = "";
  name: string = "";
  previousTaskName: string = "";
  procWorkflowName: string = "";
  procWorkflowDescription: string = "";
  steps: Array<Step> = new Array<Step>();
    copySteps: Array<Step> = new Array<Step>();
    step: Step = new Step();
    task: Task = new Task();
  isTask: boolean = false;
  isStep: boolean = false;
  isDeskTop: boolean = false;
  initiallyExpanded = false;
  initialSelectionId: string = "";
  previousSelectionId: string = "";
  initialTaskId: string = "";
	scrollToExpanded: boolean = false;
  isAuthenticated: boolean = false;
  view: DiagramView = new DiagramView();
  previousTaskId: string = "";
  previousProcessId: string = "";
  trackingDiagramType: string = "";
  objectClickId: string = "";
  generateNewWorkflow: boolean = false;
  usedProducts: Array<string> = new Array<string>();
  productVersions: Array<ContentUsedProductVersion> = new Array<ContentUsedProductVersion>();
  products: Array<ContentUsedProduct> = new Array<ContentUsedProduct>();
  navigateMode: number = 0; // 0 = tree+diagram, 1=tree only, 2=diagram only
  externalsourcetype: string = "default";
  sourceurl: string = "";
  hasTOC: boolean = false;
  noErrs: boolean = true;
  friendlyMsg; SafeHtml;
    contentAuthoring: ContentAuthoring = new ContentAuthoring();
    workflowId: string = "";

    openedSteps: Object;
         
    @ViewChild(DiagramViewComponent, { static: false })
    private diagramView: DiagramViewComponent;

    @ViewChild(WorkflowtreeComponent, { static: false })
    private workflowTree: WorkflowtreeComponent;
  
  constructor(private pinnacleService: ProdGenApi, private cdr: ChangeDetectorRef, private route: ActivatedRoute, private router: Router, private location: Location, private _sanitizer: DomSanitizer,
      private authenticationService: BrowserAuthenticationService, private translationService: TranslationService) {
      location.subscribe((event: PopStateEvent) => {
          //console.log(window.location);
          
      });

    if (authenticationService.AuthenticatePage() == true) {
			this.isAuthenticated = true;
			this.description = translationService.getTranslationFileData("WORKFLOWVIEWER.TS_Loading");
			this.contentItem.contentType = ContentType_T.workflow;
			this.steps = [];

          
			route.queryParams.subscribe(queryParams => {
				this.steps = [];

        if (queryParams['contentType'] != null) {
                
          if (queryParams['contentType'] == "task") {
						let taskId = queryParams['id'];
						this.initialSelectionId = taskId;
						// get the process
						if (taskId != null && taskId.length > 0) {

                            this.pinnacleService.GetTask(taskId, DescriptionFilter_T.none).subscribe(res => {
                                this.task = res;
                                
                this.pinnacleService.GetProcess(res.processId, DescriptionFilter_T.none).subscribe(proc => {
                  this.getUsedProducts(proc.workflowId);
                });

								this.diagramId = res.processId as string;
                this.diagramType = "process";
                this.trackingDiagramType = ContentType_T.task.toString();
                this.objectClickId = taskId;
                this.generateNewWorkflow = true;
								this.loadViewerContent();
                //load diagram item 
                this.pinnacleService.GetContentItem(res.processId, DescriptionFilter_T.formatted).subscribe(proc => {
                  this.diagramItem = proc;
                  this.contentItem = proc;
                  //    this.procWorkflowDescription = proc.description as string;
                  //this.procWorkflowName = proc.name as string;
                  //this.isTask = true;
                  //this.isStep = true;
								});



								this.initialTaskId = taskId;

								let content: Content = new Content();
								content.contentId = res.taskId;
                                content.contentType = ContentType_T.task;
                                this.isTask = true;
                                this.processSelChange(content, false);
                                
                                if (this.task.isDoc == true) {
                                    this.setupDocumentViewer("task");

                                }
							});
						}
					}
					else if (queryParams['contentType'] == "step") {
						let stepId = queryParams['id'];
						this.initialSelectionId = stepId;
						// get the process

						if (stepId != null && stepId.length > 0) {
                            this.pinnacleService.GetStep(stepId, DescriptionFilter_T.none).subscribe(res => {
                                //console.log("Step E");
                                //this.step = res;
                                //this.setupDocumentViewer("step");

                                this.pinnacleService.GetTask(res.taskId, DescriptionFilter_T.none).subscribe(res2 => {
                                    //this.task = res2;
                                    //this.setupDocumentViewer("task");
                  this.pinnacleService.GetProcess(res2.processId, DescriptionFilter_T.none).subscribe(proc => {
                    this.getUsedProducts(proc.workflowId);
                  });
									this.diagramId = res2.processId as string;
                  this.diagramType = "process";
                  this.generateNewWorkflow = true;
                  this.trackingDiagramType = ContentType_T.task.toString();
                  this.objectClickId = stepId;
									this.scrollToExpanded = true;

                  this.pinnacleService.GetContentItem(res2.processId, DescriptionFilter_T.formatted).subscribe(proc => {
                    this.diagramItem = proc;
                    this.contentItem = proc;
                    //    this.procWorkflowDescription = proc.description as string;
                    //this.procWorkflowName = proc.name as string;
                    //this.isTask = true;
                    //this.isStep = true;
                  });
									//this.loadViewerContent();
									this.initialTaskId = res.taskId as string;

                  let content: Content = new Content();
                  content.contentId = stepId;
									content.contentType = ContentType_T.step;
                  this.processSelChange(content, false);


								});
							});
						}

					}
					else {
						this.diagramId = queryParams['id'];
            this.diagramType = queryParams['contentType'];
            this.generateNewWorkflow = true;
            this.loadViewerContent();
          }
				}
      });

      //this.controlService.changeWorkflow(true);
        
  		}
    }

    loadViewerContent()
	{
      this.name = "";
      this.description = this.translationService.getTranslationFileData("WORKFLOWVIEWER.TS_Loading");
      this.procWorkflowName = "";
        this.procWorkflowDescription = this.translationService.getTranslationFileData("WORKFLOWVIEWER.TS_Loading");

      if (this.diagramId != null && this.diagramId.length > 0) {
        this.pinnacleService.GetContentItem(this.diagramId, DescriptionFilter_T.formatted).subscribe(r => {

          if (r.contentType == ContentType_T.workflow) {
              this.getUsedProducts(r.contentId);
              this.workflowId = r.contentId.toString();
          }
          else if (r.contentType == ContentType_T.process) {
              this.pinnacleService.GetProcess(r.contentId, DescriptionFilter_T.none).subscribe(proc => {
              this.workflowId = proc.workflowId.toString();
              this.getUsedProducts(proc.workflowId);
            });
          }
	
          this.contentItem = r;
          this.contentItem.imageURL = "../assets/images/workflows.svg";
          this.diagramItem = this.contentItem; // always store the diagram level. the other content item can change with selections

          this.getAuthoringContent(this.contentItem);

          if (r.contentType == ContentType_T.workflow || r.contentType == ContentType_T.process) {
            this.procWorkflowDescription = r.description as string;
            this.procWorkflowName = this.getContentType(r.contentType) + ": " + r.name as string;
            this.isTask = false;
            this.isStep = false;
          }
          else {
            this.description = r.description as string;
            //this.name = this.getContentType(r.contentType) + ": " + r.name as string;
              this.name =  r.name as string;
            this.isTask = true;
            this.isStep = true;
          }

          this.pinnacleService.addUserHistory(this.diagramItem.contentId as string, this.diagramItem.contentType).subscribe();

        }, err => {
              //display friendly message to user so they are aware of what happened (& have no access to asset if they shouldn't)
              this.noErrs = false;
              this.contentItem.name = this.translationService.getTranslationFileData("WORKFLOWVIEWER.TS_Error");

              if (err.status == 400) {
                //bad request caused by 0 data rows and/or no content ids for user - user has no access
                this.friendlyMsg = this._sanitizer.bypassSecurityTrustHtml(this.translationService.getTranslationFileData("APP.AssetViewing0DRs"));
              }
              else {
                //for now, let's give generic error as there are multiple errors possibly thrown by services (for which I added most common to translate file in case a specific one is wanted)
                this.friendlyMsg = this._sanitizer.bypassSecurityTrustHtml(this.translationService.getTranslationFileData("APP.AssetViewingError"));
              }
                
            });
      }
      
    }

  ngOnInit() {
    window.scroll(0, 0);
    if (document.body.offsetWidth > 1200) {
      this.isDeskTop = true;
    }
    else
          this.isDeskTop = false;


      try {
          /*
          let navMode = JSON.parse(localStorage.getItem(ProdGenApi.getCurrentTenantId() + "_workflow_nav_mode"));
          if (navMode != null) {
              this.navigateMode = navMode as number;
              //$('#navModeList').val(this.navigateMode.toString()).change();


          }
          */
          this.navigateMode = 0;
          this.pinnacleService.getSettingByName("WF_DISPLAY").subscribe(res => {
              if (res.settingValue == "Workflow Tree And Diagrm") {
                  this.navigateMode = 0;
              }
              else if (res.settingValue == "Workflow Tree Only") {
                  this.navigateMode = 1;
              }
              else if (res.settingValue == "Diagram Only") {
                  this.navigateMode = 2;
              }
              else {
                  // in case settings does not exist, revert to user specified
                  let navMode = JSON.parse(localStorage.getItem(ProdGenApi.getCurrentTenantId() + "_workflow_nav_mode"));
                  if (navMode != null) {
                      this.navigateMode = navMode as number;
                  }
              }
          });


      }
      catch (err) {
          console.log(err)
      }      

      this.onResize(null);
  }

  onResize(event) {
      if (document.body.offsetWidth > 1200) {
          this.isDeskTop = true;
      }
      else {
          this.isDeskTop = false;
      }


      setTimeout(() => {
          let itemtomove = document.getElementById("WFTreeDiv");
          if (AppComponent.thisFromOutside.innerWidth <= 992) {
              document.getElementById("genFlyout").appendChild(itemtomove);
          }
          else {
              document.getElementById("WFTreeHomeDiv").appendChild(itemtomove);

          }
      },0);
  }

    ngAfterContentChecked() {
        $("#navModeList").val(this.navigateMode);
    this.cdr.detectChanges();
  }

    onNavModeClicked() {
        var navMode = $('#navModeList').val();
        this.navigateMode = navMode;
        
        try {
            localStorage.setItem(ProdGenApi.getCurrentTenantId() + "_workflow_nav_mode", JSON.stringify(this.navigateMode))
        }
        catch (err) {
            console.log(err);
        }
        
    }

    getDiagramAndDescClass(): SafeHtml {

        if (this.navigateMode == 2) {
            return this._sanitizer.bypassSecurityTrustHtml("col-md-12 col-lg-12");
        }
        else if (this.navigateMode == 1) {
            return this._sanitizer.bypassSecurityTrustHtml("col-md-8 col-lg-9");
        }
        return this._sanitizer.bypassSecurityTrustHtml("col-md-8 col-lg-9");
    }

    getDiagramClass(): SafeHtml {

        if (this.navigateMode == 2) {
            return this._sanitizer.bypassSecurityTrustHtml("col-md-7 col-lg-6 diagramviewer");
        }
        else if (this.navigateMode == 1) {
            return this._sanitizer.bypassSecurityTrustHtml("diagramviewer hidden");
        }        
        return this._sanitizer.bypassSecurityTrustHtml("col-md-7 col-lg-6 diagramviewer");
    }

    getTreeClass(): SafeHtml {

        if (this.navigateMode == 2) {
            return this._sanitizer.bypassSecurityTrustHtml("wftree hidden");
        }
        else if (this.navigateMode == 1) {
            return this._sanitizer.bypassSecurityTrustHtml("col-md-4 col-lg-3 wftree");
        }
        return this._sanitizer.bypassSecurityTrustHtml("col-md-4 col-lg-3 wftree");
    }

    getTaskListClass(): SafeHtml {

        if (this.navigateMode == 2) {
            return this._sanitizer.bypassSecurityTrustHtml("col-md-5 col-lg-6");
        }
        else if (this.navigateMode == 1) {
            return this._sanitizer.bypassSecurityTrustHtml("col-md-12 col-lg-9");
        }
        return this._sanitizer.bypassSecurityTrustHtml("col-md-5 col-lg-6");
    }


  getUsedProducts(wfId: String) {
    this.usedProducts = new Array<string>();
    this.pinnacleService.GetContentUsedProducts(wfId, ContentType_T.workflow.toString()).subscribe(products => {
      for (var i = 0; i < products.length; i++) {
        this.products = products;
        this.productVersions = this.products[i].Versions;

        if (this.productVersions.length > 0) {
            for (var j = 0; j < this.productVersions.length; j++) {
                if (this.usedProducts.findIndex(x => x == (this.products[i].Name + " " + this.productVersions[j].Name)) == -1) {
                    this.usedProducts.push(this.products[i].Name + " " + this.productVersions[j].Name);
                }
          }
        }
        else {
            if (this.usedProducts.findIndex(x => x == this.products[i].Name.toString()) == -1) {
                this.usedProducts.push(this.products[i].Name.toString());
            }
         
        }
      }
    });
  }

  afterWorkflowChanged(event) {
    this.generateNewWorkflow = event.selectedItem;
  }

    diagramViewChanged(event) {
    this.steps = new Array<Step>();

    if (this.diagramItem.contentId == null || this.diagramItem.contentId.length == 0) {
      return;
    }

    let selItem: Content = event.selectedItem;

    if (selItem.contentType == ContentType_T.workflow || selItem.contentType == ContentType_T.process) {
      this.diagramId = selItem.contentId.toString();
      this.diagramType = selItem.contentType.toString();
      //this.initialTaskId = "";
    }
    else if (selItem.contentType == ContentType_T.task) {
      
        this.pinnacleService.GetTask(selItem.contentId, DescriptionFilter_T.none).subscribe(res => {
            this.task = res;
            if (this.task.isDoc == true) {
                this.setupDocumentViewer("task");
            }
            else {
                if (document.getElementById("viewercontainertask") != null) {
                    document.getElementById("viewercontainertask").innerHTML = '';
                }
            }
        this.pinnacleService.GetProcess(res.processId, DescriptionFilter_T.formatted).subscribe(res2 => {
          if (this.diagramId != res2.processId) {
            this.diagramId = res2.processId as string;
            this.diagramType = ContentType_T.process.toString();
            this.initialTaskId = selItem.contentId.toString();
            this.trackingDiagramType = ContentType_T.task.toString();

            this.pinnacleService.GetContentItem(res2.processId, DescriptionFilter_T.formatted).subscribe(res => {
              this.diagramItem = res;

            });
          }
          else {
            this.initialTaskId = selItem.contentId.toString();
            this.diagramType = ContentType_T.task.toString();
          }
           
        });
      });
    }
    else {
      this.previousTaskId = this.initialTaskId;
        this.pinnacleService.GetStep(selItem.contentId, DescriptionFilter_T.none).subscribe(res => {
            //console.log("Step D");
            //this.step = res;
            //this.setupDocumentViewer("step");
            this.pinnacleService.GetTask(res.taskId, DescriptionFilter_T.none).subscribe(res2 => {
                //this.name = "Task: " + res2.name as string;
                this.name = res2.name as string;
                this.task = res2;
                this.setupDocumentViewer("task");
          if (this.diagramId != res2.processId) {
            this.diagramId = res2.processId as string;
            this.diagramType = "process";
            this.trackingDiagramType = ContentType_T.task.toString();

            this.pinnacleService.GetContentItem(res2.processId, DescriptionFilter_T.formatted).subscribe(res => {
                this.diagramItem = res;
              
            });
          }
          else {
           this.diagramType = ContentType_T.step.toString();
          }
          this.initialTaskId = res.taskId as string;
        });
      });
    }
  }

  
  treeSelectionChanged(event) {
    if (this.diagramItem.contentId == null || this.diagramItem.contentId.length == 0) {
      return;
    }

    let selItem: Content = event.selectedItem;

    if (selItem.contentType == ContentType_T.workflow || selItem.contentType == ContentType_T.process) {
      this.diagramId = selItem.contentId.toString();
      this.diagramType = selItem.contentType.toString();
    }
    else {
      this.initialTaskId = selItem.contentId.toString();
      this.diagramType = ContentType_T.task.toString();
      this.objectClickId = selItem.contentId.toString();
    }
    
  }

	diagramSelectionChanged(event) {


        this.steps = new Array<Step>();

        if (this.diagramItem.contentId == null || this.diagramItem.contentId.length == 0) {
            return;
        }

        
        let selItem: Content = event.selectedItem;
        this.processSelChange(selItem, true);
    }

    processSelChange(selItem: Content, fromDiagram: boolean)
	{

        let url: string = "";


        if (selItem.contentId == null || selItem.contentId.length == 0)
		{
            this.isTask = false;
          this.isStep = false;

          var type: string = "";

          if (this.diagramType == "6")
            this.trackingDiagramType = "";

          if (this.diagramType == "7" || this.diagramType == "8")
            type = "6";
                      
          else if (this.trackingDiagramType == ContentType_T.task.toString() || this.trackingDiagramType == ContentType_T.step.toString()) {
            type = "6";
            this.trackingDiagramType = "";
          }
          else
            type = "5";

            url = "/workflowviewer?id=" + this.diagramItem.contentId + "&contentType=" + ContentType_T[type];

            //this.router.navigateByUrl(url, { replaceUrl: true });
            this.location.replaceState(url);
          
        }
        else
        {
            let changedDiagramView = false;

            if (selItem.contentType == ContentType_T.workflow && selItem.description == "workflow") {
                this.diagramType = "workflow";
                changedDiagramView = true;
                this.procWorkflowName = "";
                this.procWorkflowDescription = "";
            }
            else if (selItem.contentType == ContentType_T.process && selItem.description == "process") {
                this.diagramType = "process";
                changedDiagramView = true;
                this.procWorkflowName = "";
                this.procWorkflowDescription = "";
            }

            url = "/workflowviewer?id=" + selItem.contentId + "&contentType=" + ContentType_T[selItem.contentType];
            //if (this.initialTaskId.length != 0) {
            if (!window.location.href.includes(url)) {

                //this.router.navigateByUrl(url, { replaceUrl: true });
                this.location.replaceState(url);
            }
           // }
          //this.router.navigateByUrl(url);
          if (this.previousTaskId != this.initialTaskId) {
            this.name = "";
              this.description = "";
              if (document.getElementById("viewercontainertask") != null) {
                  document.getElementById("viewercontainertask").innerHTML = '';
              }
          }

            if (fromDiagram == true) {
                this.objectClickId = selItem.contentId.toString();
            }
          
          this.description = this.translationService.getTranslationFileData("WORKFLOWVIEWER.TS_Loading");
          
			    this.pinnacleService.GetContentItem(selItem.contentId, DescriptionFilter_T.formatted).subscribe(res => {

                if (changedDiagramView == true) {
                    this.contentItem = res;
                    this.diagramItem = res;
                }
              //this.contentItem.imageURL = "../assets/images/workflows.svg";
              if (res.contentType == ContentType_T.workflow || res.contentType == ContentType_T.process) {
                this.procWorkflowDescription = res.description as string;
                this.procWorkflowName = this.getContentType(res.contentType) + ": " + res.name as string;
                this.isTask = false;
                  this.isStep = false;
                  this.copySteps = [];
              }
              else {
                if (selItem.contentType == ContentType_T.task) {
                  this.description = res.description as string;
                  //this.name = this.getContentType(res.contentType) + ": " + res.name as string;
                    this.name =  res.name as string;
                  //this.previousTaskName = this.name;
                  $("#stepsDiv").animate({ scrollTop: 0 }, "fast");
                  this.isTask = true;
                  this.isStep = false;
                }

                else {
                  this.isTask = true;
                  this.isStep = true;
                }

                if (selItem.contentType == ContentType_T.task) {
                    this.pinnacleService.GetTask(selItem.contentId, DescriptionFilter_T.formatted).subscribe(res2 => {
                        this.task = res2;
                        this.isTask = true;
                        if (this.task.isDoc == true) {
                            this.setupDocumentViewer("task");
                        }
                        else {
                            if (document.getElementById("viewercontainertask") != null) {
                                document.getElementById("viewercontainertask").innerHTML = '';
                            }
                        }
                    if (this.diagramId != this.previousProcessId) {
                      this.pinnacleService.GetProcess(res2.processId, DescriptionFilter_T.formatted).subscribe(res3 => {
                        if (selItem.contentType == ContentType_T.task) {
                          this.previousProcessId = res2.processId as string;
                          this.procWorkflowDescription = res3.description as string;
                            //this.procWorkflowName = "Process: " + res3.name as string;
                            this.procWorkflowName = res3.name as string;
                        }

                        else
                          this.procWorkflowDescription = "";

                        //if (selItem.contentType ==)
                      });
                    }
                  });
                }

                  if (selItem.contentType == ContentType_T.step) {

                      this.pinnacleService.GetStep(selItem.contentId, DescriptionFilter_T.none).subscribe(res4 => {
                          //console.log("Step C");
                      //this.step = res4;
                      //this.setupDocumentViewer("step");
                    if (this.previousTaskId != res4.taskId) {
                        this.pinnacleService.GetTask(res4.taskId, DescriptionFilter_T.formatted).subscribe(res5 => {
                            this.task = res5;
                            if (this.task.isDoc == true) {
                                this.setupDocumentViewer("task");
                            }
                            //this.name = "Task: " + res5.name as string;
                            this.name = res5.name as string;
                        this.description = res5.description as string;
                        this.pinnacleService.GetProcess(res5.processId).subscribe(op => {

                          if (selItem.contentType == ContentType_T.step)
                            this.procWorkflowDescription = op.description as string;
                          else
                            this.procWorkflowDescription = "";

                          //this.procWorkflowName = "Process: " + op.name as string;
                            this.procWorkflowName = op.name as string;
                        });
                      });
                    }
                    
                  });
                  
                }
                //this.isTask = true;
              }
                

                // if this was a task, load steps
                  if (res.contentType == ContentType_T.task)
                  {
                    this.copySteps = [];
                      // add this to user history
                    this.pinnacleService.addUserHistory(res.contentId as string, res.contentType).subscribe();

                    this.pinnacleService.GetTaskSteps(res.contentId, - 1, 0, DescriptionFilter_T.formatted).subscribe(stepsRes => {
                        this.copySteps = stepsRes;
                        for (var i = 0; i < this.copySteps.length; i++) {
                            if (this.copySteps[i].isDoc == true) {
                                this.setupMultiStepDocumentViewer(this.copySteps[i]);
                            }
                        }
                      //this.steps = this.copySteps;
                        this.initiallyExpanded = false;
                        if (res.contentId != this.initialTaskId) {
                            this.initialTaskId = "";

                        }

                        //else {
                        //    if (stepsRes.length > 0) {
                        //        this.initialSelectionId = stepsRes[0].stepId as string;
                        //    }
                        //}
                    });
                  }

                  if (res.contentType == ContentType_T.step) {
                      this.pinnacleService.GetStep(res.contentId, DescriptionFilter_T.none).subscribe(res2 => {
                          //console.log("Step B");
                          this.step = res2;
                      if (this.previousTaskId != res2.taskId) {
                        this.name = this.previousTaskName;
                        this.copySteps = [];
                        $("#stepsDiv").animate({ scrollTop: 0 }, "fast");
                      }

                      if (this.copySteps.length == 0) {
                        this.pinnacleService.GetTaskSteps(res2.taskId, - 1, 0, DescriptionFilter_T.formatted).subscribe(stepsRes => {
                          this.initialSelectionId = selItem.contentId as string;

                            this.copySteps = stepsRes;
                            for (var i = 0; i < this.copySteps.length; i++) {

                                if (this.copySteps[i].isDoc == true) {
                                    this.setupMultiStepDocumentViewer(this.copySteps[i]);
                                }
                            }
                          this.initiallyExpanded = false;
                          this.scrollToExpanded = true;
                          if (res.contentId == this.initialTaskId) {
                            this.initialTaskId = "";

                          }
                          else {
                            if (stepsRes.length > 0) {
                              //for (var i = 0; i < stepsRes.length; i++) {
                              //  if (res.contentId == stepsRes[i].stepId) {
                              //    this.initialSelectionId = stepsRes[i].stepId as string;
                              //  }
                              //}
                              this.initialSelectionId = selItem.contentId as string;
                              //this.previousSelectionId = this.initialSelectionId;
                            }
                          }


                        });
                      }
                      
                      else {
                        //this.steps = this.copySteps;
                        this.initialSelectionId = selItem.contentId as string;
                        // this.previousSelectionId = this.initialSelectionId;
                        this.initiallyExpanded = false;
                        this.scrollToExpanded = true;
                        //this.getDefaultExpandedClass(this.initialSelectionId);
                      }
                         
                    });
                  }

            });

        }
    }

    expandAllSteps() {
        for (let c of this.steps) {

            $('#collapse' + c.stepId).collapse("show");
        }
    }

    collapseAllSteps() {
        for (let c of this.steps) {

            $('#collapse' + c.stepId).collapse("hide");
        }
    }


  getActiveDesc(): SafeHtml {
    return this._sanitizer.bypassSecurityTrustHtml(this.description);
    }

    getActiveDocDesc(desc:string): SafeHtml {
        return this._sanitizer.bypassSecurityTrustHtml(desc);
    }

  getActiveProcWorkflowDesc(): SafeHtml {
    return this._sanitizer.bypassSecurityTrustHtml(this.procWorkflowDescription);
  }

    getStepDesc(step: Step): SafeHtml {
        return this._sanitizer.bypassSecurityTrustHtml(step.description as string);
    }

    dict: any;

    tabChange(some_id) {
        this.dict = ["tab1", "tab2", "tab3"];
        // remove any active classes so the underlined tab is not selected. also hide the previous sections if not selected
        for (var i = 0; i < this.dict.length; i++) {
            if (this.dict[i] != some_id) {
                document.getElementById(this.dict[i]).classList.remove("active");
                document.getElementById(this.dict[i] + "section").style.display = "none";
            }
        }
    }


    getContentType(r: ContentType_T): string {
        let image: string = "";

        switch (r) {
            case ContentType_T.workflow:
                image = "Workflow";
                break;
            case ContentType_T.process:
                image = "Process";
                break;
            case ContentType_T.task:
                image = "Task";
                break;
            case ContentType_T.step:
                image = "Step";
                break;
            case ContentType_T.cheatsheet:
                image = "Cheat Sheet";
                break;
            case ContentType_T.chatLog:
                image = "Saved Chat";
                break;
            case ContentType_T.video:
                image = "Video";
                break;
            case ContentType_T.course:
                image = "Course";
                break;
            case ContentType_T.learningpath:
                image = "Learning Path";
                break;
        }
        return image;
    }

    getExpandedIconClass(elmId: string): string {
        var isExpanded = $(elmId).hasClass("in");
        if (isExpanded == true) {
            return "fa fa-minus";
        }
        return "fa fa-plus";

    }

    getDefaultExpandedClass(id: string): string {
        if (this.initialSelectionId.length > 0 && this.initiallyExpanded == false) {
            if (id == this.initialSelectionId) {
                this.initiallyExpanded = true;
                $('#collapse' + id).collapse("show");

              if (this.previousSelectionId != "" && this.previousSelectionId != id) {
                $('#collapse' + this.previousSelectionId).collapse("hide");

              }
              
              if (this.scrollToExpanded == true)
              {
                setTimeout(() => {
                  this.scrollToObject('#collapse' + id, '#collapse' + this.previousSelectionId);
                  this.scrollToExpanded = false;
                }, 1000);
                
              }

              this.previousSelectionId = this.initialSelectionId;
            }
        }
        return "";
    }

  scrollToObject(obj: string, prevObj: string) {
    //var offsetPos = $('#stepsDiv').scrollTop();
    //if (obj == prevObj && offsetPos != 0) {
    //  offsetPos = $('#stepsDiv').scrollTop();
    //}
    //else {
   var scrollPos = $('#stepsDiv').scrollTop();
    var offsetPos = $(obj).offset().top - 175;

    if (offsetPos < 0) {
      offsetPos = scrollPos - Math.abs(offsetPos);
    }
    else {
      offsetPos = scrollPos + offsetPos;
    }
    //}
    

    $('#stepsDiv').animate({
      scrollTop: offsetPos
    }, 1000);
    }

    setupDocumentViewer(type: string) {
        if (type == "task") {
            if (this.task.isDoc == true && this.task.docID != "00000000-0000-0000-0000-000000000000") { //check if sheet uses document
                this.pinnacleService.GetCheatSheet(this.task.docID).subscribe(res => {
                    let attacheddoc = res.externalDocURL;
                    let securekeysplit = attacheddoc.split("?");
                    let extensionsplit = securekeysplit[0].split("."); //get the extension of the external doc url
                    switch (extensionsplit[(extensionsplit.length - 1)]) { //set the externalsourcetype depending on the extension
                        case "pdf":
                            {
                                this.hasTOC = false;
                                this.externalsourcetype = "pdf";
                                this.sourceurl = attacheddoc.toString();
                                document.getElementById("viewercontainertask").innerHTML = '<iframe src="' + this.sourceurl + '" style="width: 100%; height: 60vh;"></iframe>';
                            }
                            break;
                        case "html":
                        case "htm":
                            {
                                this.hasTOC = false;
                                this.externalsourcetype = "html";
                                document.getElementById("viewercontainertask").innerHTML = '<iframe src="' + attacheddoc.toString() + '"  style="width: 100%; height:60vh;" marginwidth = "0" marginheight = "0" frameborder = "0" vspace = "0" hspace = "0" >';
                            }
                            break;
                        case "docx":
                        case "dotx":
                        case "xlsx":
                        case "xlsb":
                        case "xls":
                        case "xlsm":
                        case "ppt":
                        case "pptx":
                        case "ppsx":
                        case "pps":
                        case "potx":
                        case "ppsm":
                            {
                                if (extensionsplit[(extensionsplit.length - 1)] == "docx" || extensionsplit[(extensionsplit.length - 1)] == "dotx") {
                                    this.hasTOC = true;
                                }
                                else {
                                    this.hasTOC = false;
                                }

                                this.externalsourcetype = "microsoft";
                                this.sourceurl = "https://view.officeapps.live.com/op/embed.aspx?src=" + encodeURIComponent(attacheddoc.toString()) + "&amp;embedded=true";
                                document.getElementById("viewercontainertask").innerHTML = '<iframe src="' + this.sourceurl + '" style="width: 100%; height: 60vh;"></iframe>';
                            }
                            break;
                        default:
                            {
                                if (res.useExternalDoc == true) {
                                    this.hasTOC = false;
                                    this.externalsourcetype = "default";
                                    document.getElementById("viewercontainertask").innerHTML = '';
                                }
                                else {
                                    this.hasTOC = false;
                                    this.externalsourcetype = "default";
                                    this.description = res.description.toString();
                                    document.getElementById("viewercontainertask").innerHTML = '';
                                }
                                
                            }
                            break;
                    }
                
                });
        
            }
            else {
                this.hasTOC = false;
                this.externalsourcetype = "default"
                document.getElementById("viewercontainertask").innerHTML = '';
            }
        }
        else if (type == "step") {
            if (this.step.isDoc == true && this.step.docID != "00000000-0000-0000-0000-000000000000") { //check if sheet uses document
                this.pinnacleService.GetCheatSheet(this.step.docID).subscribe(res => { 
                    let attacheddoc = res.externalDocURL;
                    let securekeysplit = attacheddoc.split("?");
                let extensionsplit = securekeysplit[0].split("."); //get the extension of the external doc url
                switch (extensionsplit[(extensionsplit.length - 1)]) { //set the externalsourcetype depending on the extension
                    case "pdf":
                        {
                            this.hasTOC = false;
                            this.externalsourcetype = "pdf";
                            this.sourceurl = attacheddoc.toString();
                            this.step.description = "";
                            document.getElementById("viewercontainerstep" + this.step.stepId).innerHTML = '<iframe src="' + this.sourceurl + '" style="width: 100%; height: 60vh;"></iframe>';
                        }
                        break;
                    case "html":
                    case "htm":
                        {
                            this.hasTOC = false;
                            this.externalsourcetype = "html";
                            this.step.description = "";                   
                            document.getElementById("viewercontainerstep" + this.step.stepId).innerHTML = '<iframe src="' + attacheddoc.toString() + '"  style="width: 100%; height:60vh;" marginwidth = "0" marginheight = "0" frameborder = "0" vspace = "0" hspace = "0" >';
                        }
                        break;
                    case "docx":
                    case "dotx":
                    case "xlsx":
                    case "xlsb":
                    case "xls":
                    case "xlsm":
                    case "ppt":
                    case "pptx":
                    case "ppsx":
                    case "pps":
                    case "potx":
                    case "ppsm":
                        {
                            if (extensionsplit[(extensionsplit.length - 1)] == "docx" || extensionsplit[(extensionsplit.length - 1)] == "dotx") {
                                this.hasTOC = true;
                            }
                            else {
                                this.hasTOC = false;
                            }

                            this.externalsourcetype = "microsoft";
                            this.sourceurl = "https://view.officeapps.live.com/op/embed.aspx?src=" + encodeURIComponent(attacheddoc.toString()) + "&amp;embedded=true";
                            this.step.description = "";
                            document.getElementById("viewercontainerstep" + this.step.stepId).innerHTML = '<iframe src="' + this.sourceurl + '" style="width: 100%; height: 60vh;"></iframe>';
                        }
                        break;
                    default:
                        {
                            if (res.useExternalDoc == true) {
                                this.hasTOC = false;
                                this.externalsourcetype = "default";
                                this.step.description = res.description.toString();
                                document.getElementById("viewercontainerstep" + this.step.stepId).innerHTML = '';
                            }
                            else {
                                this.hasTOC = false;
                                this.externalsourcetype = "default";
                                this.step.description = res.description.toString();
                                document.getElementById("viewercontainerstep" + this.step.stepId).innerHTML = '';
                            }
                        }
                        break;
                    }
                });
            }         
            else {
                this.hasTOC = false;
                this.externalsourcetype = "default"
                document.getElementById("viewercontainerstep" + this.step.stepId).innerHTML = '';
            }            
        }    
        
    }

    setupMultiStepDocumentViewer(step: Step) {
        if (step.isDoc == true && step.docID != "00000000-0000-0000-0000-000000000000") { //check if sheet uses document
            this.pinnacleService.GetCheatSheet(step.docID).subscribe(res => {
                let attacheddoc = res.externalDocURL;
                let securekeysplit = attacheddoc.split("?");
                let extensionsplit = securekeysplit[0].split("."); //get the extension of the external doc url
                switch (extensionsplit[(extensionsplit.length - 1)]) { //set the externalsourcetype depending on the extension
                    case "pdf":
                        {
                            this.hasTOC = false;
                            this.externalsourcetype = "pdf";
                            this.sourceurl = attacheddoc.toString();
                            document.getElementById("stepdesc" + step.stepId).innerHTML = '';
                            document.getElementById("viewercontainerstep" + step.stepId).innerHTML = '<iframe src="' + this.sourceurl + '" style="width: 100%; height: 60vh;"></iframe>';
                        }
                        break;
                    case "html":
                    case "htm":
                        {
                            this.hasTOC = false;
                            this.externalsourcetype = "html";
                            document.getElementById("stepdesc" + step.stepId).innerHTML = '';
                            document.getElementById("viewercontainerstep" + step.stepId).innerHTML = '<iframe src="' + attacheddoc.toString() + '"  style="width: 100%; height:60vh;" marginwidth = "0" marginheight = "0" frameborder = "0" vspace = "0" hspace = "0" >';
                        }
                        break;
                    case "docx":
                    case "dotx":
                    case "xlsx":
                    case "xlsb":
                    case "xls":
                    case "xlsm":
                    case "ppt":
                    case "pptx":
                    case "ppsx":
                    case "pps":
                    case "potx":
                    case "ppsm":
                        {
                            if (extensionsplit[(extensionsplit.length - 1)] == "docx" || extensionsplit[(extensionsplit.length - 1)] == "dotx") {
                                this.hasTOC = true;
                            }
                            else {
                                this.hasTOC = false;
                            }

                            this.externalsourcetype = "microsoft";
                            this.sourceurl = "https://view.officeapps.live.com/op/embed.aspx?src=" + encodeURIComponent(attacheddoc.toString()) + "&amp;embedded=true";
                            document.getElementById("stepdesc" + step.stepId).innerHTML = '';
                            document.getElementById("viewercontainerstep" + step.stepId).innerHTML = '<iframe src="' + this.sourceurl + '" style="width: 100%; height: 60vh;"></iframe>';
                        }
                        break;
                    default:
                        {
                            if (res.useExternalDoc == true) {
                                this.hasTOC = false;
                                this.externalsourcetype = "default";
                                step.description = res.description.toString();
                                document.getElementById("viewercontainerstep" + step.stepId).innerHTML = '';
                            }
                            else {
                                this.hasTOC = false;
                                this.externalsourcetype = "default";
                                step.description = res.description.toString();
                                //this.description = res.description.toString();
                                document.getElementById("viewercontainerstep" + step.stepId).innerHTML = '';
                            }
                        }
                        break;
                }
            });
        }
        else {
            this.hasTOC = false;
            this.externalsourcetype = "default"
            document.getElementById("viewercontainerstep" + step.stepId).innerHTML = '';
        }
    }

    getAuthoringContent(c: Content){
        if (c.contentId != null) {
            this.pinnacleService.getContentAuthoringData(c.contentId, c.contentType.toString()).subscribe(d => {
                if (d != null && d.lastModBy != "") {
                    this.contentAuthoring.contentId = c.contentId.toString();
                    this.contentAuthoring.lastModBy = d.lastModBy;
                    this.contentAuthoring.lastModDate = d.lastModDate;
                    this.contentAuthoring.lastModDateStr = d.lastModDateStr;
                }
            });
        }
        else {
            this.contentAuthoring = new ContentAuthoring();
        }
    }

    stepCollapseToggleClick(element: string, id:string) {
        if ($("#stepIcon" + id).attr("class") == "fa fa-plus") {
            $("#stepIcon" + id).removeClass("fa fa-plus").addClass("fa fa-minus");
        }
        else if ($("#stepIcon" + id).attr("class") == "fa fa-minus") {
            $("#stepIcon" + id).removeClass("fa fa-minus").addClass("fa fa-plus");
        }

    }

    stepItemClicked(p_Step: Step) {
        if (this.openedSteps[p_Step.stepId.toString()] != true) {
            console.log("Check");
            this.openedSteps[p_Step.stepId.toString()] = true;
        }
        else {
            console.log("Found");
        }
    }
}

//export class HierarchyContent {
//  contentId: string = "";
//  Name: string = "";
//  Children: Array<HierarchyContent> = new Array<HierarchyContent>();
//}

//export class HierarchyList {
//  contentId: string = "";
//  name: string = "";
//  type: string = "";
//  indent: number;
//}


