import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Content, ContentType_T, DescriptionFilter_T } from '../apiService/classFiles/class.content';
import { Role, User } from '../apiService/classFiles/class.users';
import { PermissionLevel_T, Workgroup, WorkgroupContentIndex, WorkgroupMember, WorkgroupRole } from '../apiService/classFiles/class.workgroups';
import { AppComponent } from '../app.component';
import { ProdGenApi } from './../apiService/prodgen.api';
import { BrowserAuthenticationService } from './../BrowserAuthenticationService';
import { SearchComponent } from './../search/search.component';
import { TranslationService } from './../services/TranslationService';

declare function initDroplists();
declare var $: any;


//We no able user Workcenter, we use Workcenter3
@Component({
    selector: 'app-workcenter',
    templateUrl: './workcenter.component.html',
    styleUrls: ['./workcenter.component.css'],
    //providers: [LearningcenterheaderComponent, ProdGenApi],
    providers: [ProdGenApi],
    changeDetection: ChangeDetectionStrategy.Default
})
export class WorkcenterComponent implements OnInit, AfterViewInit {
    workGroups: Array<Workgroup> = new Array<Workgroup>();
    ownerWorkGroups: Array<Workgroup> = new Array<Workgroup>();
    editorWorkGroups: Array<Workgroup> = new Array<Workgroup>();
    currentUser: User = new User();
    currentWorkgroup: Workgroup = new Workgroup();
    currentUserPermissionsLevel: String;
    currentAssets: Array<Content> = new Array<Content>();
    filteredAssets: Array<Content> = new Array<Content>();
    filteredLearningPaths: Array<Content> = new Array<Content>();
    filteredWorkflows: Array<Content> = new Array<Content>();
    filteredCheatSheets: Array<Content> = new Array<Content>();
    filteredVideos: Array<Content> = new Array<Content>();
    currentMembers: Array<WorkgroupMember> = new Array<WorkgroupMember>();
    usersList: Array<WorkgroupMember> = new Array<WorkgroupMember>();
    ownersList: Array<WorkgroupMember> = new Array<WorkgroupMember>();
    editorList: Array<WorkgroupMember> = new Array<WorkgroupMember>();
    currentRoles: Array<WorkgroupRole> = new Array<WorkgroupRole>();
    contentIndices: Array<WorkgroupContentIndex> = new Array<WorkgroupContentIndex>();
    wgcontentIDMap: { [contentID: string]: String } = {};
    dateTimeAddedMap: { [contentID: string]: Date } = {};
    isAssetEditMode: boolean = false;
    isMemberEditMode: boolean = false;
    isWorkgroupEditMode: boolean = false;
    newWorkgroupName: string = "";
    newWorkgroupURL: string = "";
    newWorkgroupDesc: string = "";
    selUsers = [];
    selRoles = [];
    dropdownUsersData = [];
    dropdownRoleData = [];
    dropdownSettingsUsers = {};
    dropdownSettingsRoles = {};
    addMemberPermissionLevel: string;
    addMemberTypeText: string;
    addMemberTypeDescText: string;
    filterText: string = "";
    searchTerms: string = "";
    workgroupsLoaded: boolean = false;
    isDefault: boolean = true;
    showAll: boolean = false;
    showOwners: boolean = false;
    showEditors: boolean = false;
    collapseAll: boolean = false;
    collapseOwners: boolean = false;
    collapseEditors: boolean = false;
    newImageFile: File;
    imageId: string = "";
    showImageLoader: boolean = false;
    defaultUsersLimitReached: boolean = false;
    defaultOwnersLimitReached: boolean = false;
    defaultEditorsLimitReached: boolean = false;
    defaultLength: number = 20;
    newModifyWorkgroupTitle: string = "";
    //newModifyName: string = "";
    //newModifyDescription: string = "";
    isModify: boolean = false;
    userSelectedValue: string = "";
    membersLoaded: boolean = true;
    rolesLoaded: boolean = true;
    currentUserLoaded: boolean = false;
    membersLoadedFull: boolean = true;

    assestsLoaded: boolean = false;
    indicesPreLoaded: boolean = false;
    indicesLoaded: boolean = false;
    maxIndex: string;
    highlightedID: string = "";
    assetHeight: number;
    @ViewChildren('assetContainer') assetsRef: QueryList<ElementRef>;

    thumbnailURL: string = "../../assets/images/default_project.jpg";
    isAuthenticated: boolean = false;
    contentFiltered: boolean = false;

    constructor(private pinnacleService: ProdGenApi, private route: ActivatedRoute, private router: Router, private sanitizer: DomSanitizer, private authenticationService: BrowserAuthenticationService, private translationService: TranslationService) {
        if (authenticationService.AuthenticatePage() == true) {
            this.isAuthenticated = true;

            this.dropdownSettingsUsers = {
                singleSelection: false,
                text: translationService.getTranslationFileData("WORKCENTER.TS_SelectUsers"),
                enableSearchFilter: true,
                classes: "multi-select-dropdown"
            };

            this.dropdownSettingsRoles = {
                singleSelection: false,
                text: translationService.getTranslationFileData("WORKCENTER.TS_SelectRoles"),
                enableSearchFilter: true,
                classes: "multi-select-dropdown"
            };
        }
    }

    ngAfterViewInit() {

        //initDroplists();
    }



    ngOnInit() {
        window.scroll(0, 0);
        let selectId: string = ""
        try {
            this.thumbnailURL = localStorage.getItem(ProdGenApi.getCurrentTenantId() + "_defWorkgroupImage");
        } catch (e) {

        }

        this.route.queryParams.subscribe(queryParams => {
            if (queryParams['id'] != null) {
                selectId = queryParams['id'];
            }
        });


        this.newModifyWorkgroupTitle = this.translationService.getTranslationFileData("WORKCENTER.TS_CreateWorkgroup");

        this.pinnacleService.getCurrentUser().subscribe(u => {
            if (u.imageURL == "") {
                u.imageURL = "../assets/images/default_user.jpg"
            }
            this.currentUser = u;
            this.currentUserLoaded = true;
            this.memberListLoadedFull();
        });

        this.pinnacleService.getCurrentUserWorkgroups().subscribe(res => {
            for (var i = 0; i < res.length; i++) {
                //get default workgroup image from storage
                let defaultwgimage = "";

                try {
                    defaultwgimage = localStorage.getItem(ProdGenApi.getCurrentTenantId() + "_defWorkgroupImage");
                }
                catch (err) {
                }
                if (res[i].imageURL == "" || res[i].imageURL == null) {                
                    if (defaultwgimage != null && defaultwgimage != "") {
                        res[i].imageURL = defaultwgimage;
                    }
                    else {
                        res[i].imageURL = "../assets/images/default_project.jpg";
                    }
                }
                if (res[i].workgroupId == selectId)
                {
                    this.loadWorkgroupData(res[i]);
                }
            }
            this.workGroups = res;
            this.workgroupsLoaded = true;
        });
        this.pinnacleService.getCurrentUserWorkgroups([PermissionLevel_T.owner, PermissionLevel_T.editor]).subscribe(res => {
            this.editorWorkGroups = res;
        });

        this.pinnacleService.getCurrentUserWorkgroups([PermissionLevel_T.owner]).subscribe(res => {
            this.ownerWorkGroups = res;
        });
        this.userSelectedSortOption();
    }


    get isPinnacleLite() {
        return AppComponent.isPinnacleLite;
    }

    currentMembersCount(permission: string): number {
        let count: number = 0;
        for (var i = 0; i < this.currentMembers.length; i++) {
            if (this.currentMembers[i].permissionLevel == permission) {
                count++;
            }
        }
        return count;
    }

    userSelectedSortOption() {
        var value = sessionStorage.getItem("workGroupSortBy");

        if (value == "" || value == null) {
            value = "Asset Name";
        }

        if (value == "Asset Type") {
            this.isDefault = false;
        }
        else {
            this.isDefault = true;
        }
        this.userSelectedValue = value;
    }

    onUsersChange(value) {
    }

    finishAddingMembers() {
        // see if there are any selected users
        if (this.selUsers.length > 0) {

            let userIds: Array<String> = new Array<String>();
            for (var i = 0; i < this.selUsers.length; i++) {
                userIds.push(this.selUsers[i].id);
            }

            this.pinnacleService.setWorkgoupMembers(this.currentWorkgroup.workgroupId, userIds, PermissionLevel_T[this.addMemberPermissionLevel]).subscribe(m => {
                this.loadWorkgroupData(this.currentWorkgroup, true, false);
            });

        }
        if (this.selRoles.length > 0) {
            let roleIds: Array<String> = new Array<String>();
            for (var i = 0; i < this.selRoles.length; i++) {
                roleIds.push(this.selRoles[i].id);
            }


            this.pinnacleService.setWorkgoupRoles(this.currentWorkgroup.workgroupId, roleIds, PermissionLevel_T[this.addMemberPermissionLevel]).subscribe(m => {
                this.loadWorkgroupData(this.currentWorkgroup, true, false);
            });
        }
    }

    removeMember(member: WorkgroupMember) {
        this.pinnacleService.DeleteWorkgoupMember(member.workgroupId, member.userId).subscribe(m => { this.loadWorkgroupData(this.currentWorkgroup, true, false); });
    }

    removeRole(role: Role) {
        this.pinnacleService.DeleteWorkgoupRole(this.currentWorkgroup.workgroupId, role.roleId).subscribe(m => { this.loadWorkgroupData(this.currentWorkgroup, true, false); });
    }

    addMembersClick(permissionLevel: string) {
        this.dropdownUsersData = [];
        this.addMemberPermissionLevel = permissionLevel;
        this.selUsers = [];
        this.selRoles = [];
        switch (permissionLevel) {
            case 'owner':
                this.addMemberTypeText = this.translationService.getTranslationFileData("WORKCENTER.TS_OwnerTitle");
                this.addMemberTypeDescText = this.translationService.getTranslationFileData("WORKCENTER.TS_OwnerBody");
                break;
            case 'editor':
                this.addMemberTypeText = this.translationService.getTranslationFileData("WORKCENTER.TS_EditorTitle");
                this.addMemberTypeDescText = this.translationService.getTranslationFileData("WORKCENTER.TS_EditorBody");
                break;
            case 'user':
            default:
                this.addMemberTypeText = this.translationService.getTranslationFileData("WORKCENTER.TS_UserTitle");
                this.addMemberTypeDescText = this.translationService.getTranslationFileData("WORKCENTER.TS_UserBody");
                break;
        }

        this.pinnacleService.getUsers().subscribe(u => {
            for (var i = 0; i < u.length; i++) {
                // only add them to the list if they are not already included in the workgroup
                if (this.currentMembers.findIndex(m => m.userId == u[i].userId) == -1) {
                    this.dropdownUsersData.push({ "id": u[i].userId, "itemName": u[i].name })
                }
            }

        });

        this.pinnacleService.getJobRoles().subscribe(r => {
            this.dropdownRoleData = [];
            for (var i = 0; i < r.length; i++) {
                if (this.currentRoles.findIndex(m => m.roleId == r[i].roleId) == -1) {
                    this.dropdownRoleData.push({ "id": r[i].roleId, "itemName": r[i].roleName })
                }
            }

        });

    }

    getWorkgroupRoleUserCount(r: WorkgroupRole): number {
        let count: number = 0;

        //for (var i = 0; i < this.currentMembers.length; i++)
        for (let m of this.currentMembers) {
            if (m.inheritedRoles != null && m.inheritedRoles.findIndex(i => i.roleId == r.roleId) != -1) {
                count++;
            }
        }
        return count;
    }

    onArchiveWorkgroup() {
        this.pinnacleService.DeleteWorkgroup(this.currentWorkgroup.workgroupId, "").subscribe(i => {
            this.router.navigateByUrl("workcenter");
            this.ngOnInit();
            this.currentWorkgroup.name = "";

        });

    }

    onCreateWorkgroup() {
        if (this.isModify == true) {
            let v_ImageURL = this.currentWorkgroup.imageURL;
            if (v_ImageURL.startsWith("../")) {
                v_ImageURL = "";
            }
            this.pinnacleService.UpdateWorkgroup(this.currentWorkgroup.workgroupId.toString(), this.newWorkgroupName, this.newWorkgroupDesc, v_ImageURL.toString(), false, false, false, false, true, true, true, true, true,true, true, true, true, true, true, true, true, true).subscribe(i => {
                this.ngOnInit();
            });
        }
        else {
            let workgroup = new Workgroup();
            workgroup.name = this.newWorkgroupName;
            workgroup.description = this.newWorkgroupDesc;
            workgroup.imageURL = "";
            workgroup.isPublic = false;
            workgroup.editorsDashboardAllowed = false;
            workgroup.membersDashboardAllowed = false;
            workgroup.externalMembersDashboardAllowed = false;
            workgroup.notifyContentAdded = true;
            workgroup.notifyContentRemoved = true;
            workgroup.notifyContentModified = true;
            workgroup.notifyUserAdded = true;
            workgroup.notifyUserRemoved = true;
            this.pinnacleService.SetWorkgroup(workgroup).subscribe(i => {

                if (this.newImageFile != null) {
                    this.pinnacleService.updateWorkgroupImage(i.workgroupId.toString(), this.newImageFile).subscribe(res => {
                        i.imageURL = res;
                        this.pinnacleService.UpdateWorkgroup(i.workgroupId.toString(), i.name.toString(), i.description.toString(), i.imageURL.toString(), false, false, false, false, true, true, true, true, true, true, true, true, true, true, true, true, true, true).subscribe(r => {
                            this.router.navigateByUrl("workcenter?id=" + i.workgroupId);

                            this.ngOnInit();
                        },
                            err => {
                                console.log(err);
                                this.router.navigateByUrl("workcenter?id=" + i.workgroupId);

                                this.ngOnInit();
                            });

                    });
                }
                else {
                    this.router.navigateByUrl("workcenter?id=" + i.workgroupId);

                    this.ngOnInit();
                }
            });
        }
    }


    amIOwner(w: Workgroup): boolean {
        if (this.ownerWorkGroups.findIndex(i => i.workgroupId == w.workgroupId) != -1) {
            return true;
        }
        return false;
    }

    amIEditor(w: Workgroup): boolean {

        if (this.editorWorkGroups.findIndex(i => i.workgroupId == w.workgroupId) != -1) {
            return true;
        }
        return false;
    }

    isCurrentWorkgroup(w: Workgroup): boolean {
        if (w.workgroupId == this.currentWorkgroup.workgroupId) {
            return true;
        }
        return false;
    }
    removeAssetFromCurrent(a: Content) {
        this.pinnacleService.DeleteWorkgoupContentItem(this.currentWorkgroup.workgroupId, a.contentId).subscribe(i => {
            this.currentAssets.splice(this.currentAssets.findIndex(x => x.contentId == a.contentId), 1);
            this.filteredAssets.splice(this.filteredAssets.findIndex(x => x.contentId == a.contentId), 1);
            this.filteredLearningPaths = this.getClonedAssetsByType("LP");
            this.filteredWorkflows = this.getClonedAssetsByType("WF");
            this.filteredCheatSheets = this.getClonedAssetsByType("CS");
            this.filteredVideos = this.getClonedAssetsByType("VID");

            // Update range for tooltip
            this.maxIndex = (this.filteredAssets.length - 1).toString();

            if (this.highlightedID == a.contentId as string) {
                this.highlightedID = "";
            }

            //let userIds: Array<string> = new Array<string>();
            //for (let member of this.currentMembers) {
            //    userIds.push(member.userId as string);
            //}

            //let title = this.currentWorkgroup.name + " has changed";
            //let text = ProdGenApi.getSessionUser().name + " has removed '" + a.name + "' from the '" + this.currentWorkgroup.name + "' work group."
            //let linkUrl = "/workcenter?id=" + this.currentWorkgroup.workgroupId;
            //this.pinnacleService.SetNotification(title, text, ProdGenApi.getSessionUser().imageURL as string, linkUrl, userIds).subscribe();

        });
    }

    loadWorkgroupData(w: Workgroup, loadMembers: boolean = true, loadAssets: boolean = true) {
        this.currentWorkgroup = w;

        this.currentMembers = new Array<WorkgroupMember>();
        this.currentAssets = new Array<Content>();
        this.currentRoles = new Array<WorkgroupRole>();
        this.usersList = new Array<WorkgroupMember>();
        this.ownersList = new Array<WorkgroupMember>();
        this.editorList = new Array<WorkgroupMember>();
        this.contentIndices = new Array<WorkgroupContentIndex>();

        if (loadMembers == true) {
            // load up the members
            //this.membersLoaded = false;
            //this.rolesLoaded = false;
            //this.membersLoadedFull = false;
            this.pinnacleService.GetWorkgroupMembers(this.currentWorkgroup.workgroupId, -1, 0).subscribe(res => {


                this.currentMembers = res;
                //Default users to display is 20

                for (var i = 0; i < this.currentMembers.length; i++) {
                    if (this.currentMembers[i].imageURL == "") {
                        this.currentMembers[i].imageURL = "../assets/images/default_user.jpg"
                    }
                }

                for (var i = 0; i < this.currentMembers.length; i++) {
                    if (this.currentMembers[i].permissionLevel == 'user') {
                        this.usersList.push(this.currentMembers[i]);
                    }

                    else if (this.currentMembers[i].permissionLevel == 'owner') {
                        this.ownersList.push(this.currentMembers[i]);
                    }

                    else if (this.currentMembers[i].permissionLevel == 'editor') {
                        this.editorList.push(this.currentMembers[i]);
                    }
                }

                if (this.usersList.length > this.defaultLength) {
                    this.defaultUsersLimitReached = true;
                }
                else {
                    this.defaultUsersLimitReached = false;
                }

                if (this.ownersList.length > this.defaultLength) {
                    this.defaultOwnersLimitReached = true;
                }
                else {
                    this.defaultOwnersLimitReached = false;
                }

                if (this.editorList.length > this.defaultLength) {
                    this.defaultEditorsLimitReached = true;
                }
                else {
                    this.defaultEditorsLimitReached = false;
                }

                this.membersLoaded = true;
                this.memberListLoadedFull();
            });

            // load up the roles
            this.pinnacleService.getWorkgroupRoles(this.currentWorkgroup.workgroupId, -1, 0).subscribe(res => {
                this.currentRoles = res;
                this.rolesLoaded = true;
                this.memberListLoadedFull();
            });

        }

        // load up the assets
        if (loadAssets == true) {

            //this.assestsLoaded = false;
            this.pinnacleService.getWorkgroupContentIndices(this.currentWorkgroup.workgroupId).subscribe(res => {
                this.contentIndices = res;

                // Get mapping for reconstruction on save
                for (let i = 0; i < this.contentIndices.length; i++) {
                    this.wgcontentIDMap[this.contentIndices[i].contentID as string] = this.contentIndices[i].wgcontentID;
                    this.dateTimeAddedMap[this.contentIndices[i].contentID as string] = this.contentIndices[i].dateTimeAdded;
                }
                this.maxIndex = (this.contentIndices.length - 1).toString()

                this.indicesPreLoaded = true;
            });

            this.pinnacleService.GetWorkgroupContentItems(this.currentWorkgroup.workgroupId, -1, 0, DescriptionFilter_T.unformatted).subscribe(res => {
                this.currentAssets = res;
                this.filteredAssets = this.getClonedAssets();

                this.filteredLearningPaths = this.getClonedAssetsByType("LP");
                this.filteredWorkflows = this.getClonedAssetsByType("WF");
                this.filteredCheatSheets = this.getClonedAssetsByType("CS");
                this.filteredVideos = this.getClonedAssetsByType("VID");

                this.assestsLoaded = true;
            });

        }
    }

    removeInvisibleContent() {
        // Content Service filters content by visibility. To avoid
        // filtering index results as well, post process here.
        let indiciesToRemove = [];

        for (let c of this.contentIndices) {
            if (this.currentAssets.findIndex(r => { return r.contentId == c.contentID; }) == -1) {
                let contentIndex = this.contentIndices.findIndex(i => { return i.contentID == c.contentID; });
                indiciesToRemove.push(contentIndex);
            }
        }
        for (let i of indiciesToRemove) {
            this.contentIndices.splice(i, 1);
        }
         
        this.indicesLoaded = true;
    }

    memberListLoadedFull() {
        if (this.membersLoaded == true && this.rolesLoaded == true) {
            this.membersLoadedFull = true;
        }
        if (this.membersLoaded && this.rolesLoaded && this.currentUserLoaded) {
            if (this.currentMembers.length > 0) {
                for (let i = 0; i < this.currentMembers.length; i++) {
                    if (this.currentMembers[i].userId == this.currentUser.userId) {
                        this.currentUserPermissionsLevel = this.currentMembers[i].permissionLevel;
                        break;
                    }
                }
            }
            else {
                this.currentUserPermissionsLevel = "";
            }
        }
    }

    getClonedAssets(): Array<Content> {
        let content: Array<Content> = new Array<Content>();

        this.removeInvisibleContent();

        // Push the items in currentAssets in the
        // order prescribed by contentIndices
        for (let i of this.contentIndices) {
            let c = this.currentAssets.find(function (element) {
                return element.contentId == i.contentID;
            });

            if (c != null) {
                content.push(c);
            }
            //content.push(
            //    this.currentAssets.find(function (element) {
            //        return element.contentId == i.contentID;
            //    })
            //);
        }
        
        return content;
    }

    getClonedAssetsByType(type: string): Array<Content> {
        let content: Array<Content> = new Array<Content>();
        if (type == "CS") {
            for (let c of this.currentAssets) {
                if (c.contentType == ContentType_T.cheatsheet) {
                    content.push(c);
                }
            }
            return content;
        }

        else if (type == "VID") {
            for (let c of this.currentAssets) {
                if (c.contentType == ContentType_T.video) {
                    content.push(c);
                }
            }
            return content;
        }

        else if (type == "LP") {
            for (let c of this.currentAssets) {
                if (c.contentType == ContentType_T.learningpath || c.contentType == ContentType_T.course) {
                    content.push(c);
                }
            }
            return content;
        }

        else if (type == "WF") {
            for (let c of this.currentAssets) {
                if (c.contentType == ContentType_T.workflow || c.contentType == ContentType_T.process) {
                    content.push(c);
                }
            }
            return content;
        }
    }

    filterTextChanged() {
        this.contentFiltered = true;
        this.filteredAssets = this.getClonedAssets();

        this.filteredLearningPaths = this.getClonedAssetsByType("LP");
        this.filteredWorkflows = this.getClonedAssetsByType("WF");
        this.filteredCheatSheets = this.getClonedAssetsByType("CS");
        this.filteredVideos = this.getClonedAssetsByType("VID");

        if (this.filterText.trim().length > 0) {

            for (var i = 0; i < this.filteredAssets.length; i++) {
                if ((this.filteredAssets[i].name.toLowerCase().indexOf(this.filterText.toLowerCase()) == -1) &&
                    this.filteredAssets[i].description.toLowerCase().indexOf(this.filterText.toLowerCase()) == -1) {
                    this.filteredAssets.splice(i, 1);
                    i--;
                }
            }

            var splitFilterText = this.filterText.split(" ");

            for (var d = 0; d < splitFilterText.length; d++) {
                if (splitFilterText[d].trim().length > 0) {
                    for (var i = 0; i < this.filteredCheatSheets.length; i++) {
                        if (this.filteredCheatSheets[i].name.toLowerCase().indexOf(splitFilterText[d].toLowerCase()) == -1) {
                            this.filteredCheatSheets.splice(i, 1);
                            i--;
                        }
                    }
                }

                if (splitFilterText[d].trim().length > 0) {
                    for (var i = 0; i < this.filteredVideos.length; i++) {
                        if (this.filteredVideos[i].name.toLowerCase().indexOf(splitFilterText[d].toLowerCase()) == -1) {
                            this.filteredVideos.splice(i, 1);
                            i--;
                        }
                    }
                }

                if (splitFilterText[d].trim().length > 0) {
                    for (var i = 0; i < this.filteredWorkflows.length; i++) {
                        if (this.filteredWorkflows[i].name.toLowerCase().indexOf(splitFilterText[d].toLowerCase()) == -1) {
                            this.filteredWorkflows.splice(i, 1);
                            i--;
                        }
                    }
                }

                if (splitFilterText[d].trim().length > 0) {
                    for (var i = 0; i < this.filteredLearningPaths.length; i++) {
                        if (this.filteredLearningPaths[i].name.toLowerCase().indexOf(splitFilterText[d].toLowerCase()) == -1) {
                            this.filteredLearningPaths.splice(i, 1);
                            i--;
                        }
                    }
                }
            }
        }
        else {
            this.contentFiltered = false;
        }
    }

    selectWorkgroup(w: Workgroup) {
        this.isAssetEditMode = false;
        this.isMemberEditMode = false;
        this.isWorkgroupEditMode = false;

        this.router.navigateByUrl("workcenter?id=" + w.workgroupId);
        this.loadWorkgroupData(w);
    }

    toggleAssetEditMode() {
        if (this.isAssetEditMode) {
            if (!this.contentFiltered) {
                this.tryModifyIndices();
            }
            if (this.highlightedID != "") {
                try {
                    document.getElementById(this.highlightedID).style.backgroundColor = "transparent";
                    this.highlightedID = "";
                }
                catch(e) { }
            }
        }
        this.isAssetEditMode = !this.isAssetEditMode;
        if (this.userSelectedValue == "Asset Name") {
            this.initAssetMidpoints();
        }
    }

    toggleMemberEditMode() {
        this.isMemberEditMode = !this.isMemberEditMode;
    }

    toggleWorkgroupEditMode() {
        this.isWorkgroupEditMode = !this.isWorkgroupEditMode;
    }

    getAssetEditModeText(): string {
        if (this.isAssetEditMode == true) {
            return this.translationService.getTranslationFileData("WORKCENTER.Done");
        }
        return this.translationService.getTranslationFileData("WORKCENTER.Edit");
    }

    getMemberEditModeText(): string {
        if (this.isMemberEditMode == true) {
            return this.translationService.getTranslationFileData("WORKCENTER.Done");
        }
        return this.translationService.getTranslationFileData("WORKCENTER.Edit");
    }

    getWorkgroupEditModeText(): string {
        if (this.isWorkgroupEditMode == true) {
            return this.translationService.getTranslationFileData("WORKCENTER.Done");
        }
        return this.translationService.getTranslationFileData("WORKCENTER.Edit");
    }

    getContentIndex(r: Content): string {
        return (this.filteredAssets.findIndex(function (element) {
            return element.contentId == r.contentId;
        }) + 1).toString();
    }

    getContentUrl(r: Content): string {
        let url: string = "#";
        if (r.contentType == ContentType_T.workflow) {
            url += "/workflowviewer?id=" + r.contentId + "&contentType=workflow";
        }
        else if (r.contentType == ContentType_T.process) {
            url += "/workflowviewer?id=" + r.contentId + "&contentType=process";
        }
        else if (r.contentType == ContentType_T.task) {
            url += "/workflowviewer?id=" + r.contentId + "&contentType=task";
        }
        else if (r.contentType == ContentType_T.step) {
            url += "/workflowviewer?id=" + r.contentId + "&contentType=step";
        }
        else if (r.contentType == ContentType_T.cheatsheet) {
            url += "/cheatsheetviewer?id=" + r.contentId;
        }
        else if (r.contentType == ContentType_T.video) {
            url += "/videoviewer?id=" + r.contentId;
        }
        else if (r.contentType == ContentType_T.learningpath) {
            url += "/learningcenter/series?learningPathId=" + r.contentId;
        }
        else if (r.contentType == ContentType_T.course) {
            url += "/learningcenter/series?courseId=" + r.contentId;
        }
        return url;

    }

    getContentImage(r: Content): string {
        let image: string = "";

        image = "assets/images/";

        if (r.contentType == ContentType_T.cheatsheet && r.docURL != null && r.docURL != "") {
            if (r.docURL.includes(".pdf")) {
                image += "PDF Graphic.png"
            }
            else if (r.docURL.includes(".doc")) {
                image += "Word Graphic.png"
            }
            else if (r.docURL.includes(".xls")) {
                image += "Excel Graphic.png"
            }
            else if (r.docURL.includes(".ppt")) {
                image += "PowerPoint Graphic.png"
            }
            else if (r.docURL.includes(".htm")) {
                image += "HTML Graphic.png"
            }
        }
        else {
            image += ProdGenApi.getLargeDefaultImageFromType(r.contentType);
            if (image.indexOf("default") == -1) {
                image = image.replace("assets/images/", "");
            }
        }

        return image;
    }

    getContentLPImage(): string {
        let image: string = "";

        image = "assets/images/";
        let img = ProdGenApi.getLargeDefaultImageFromType(ContentType_T.learningpath);

        if (img.indexOf("default") == -1) {
            image = img;
        }
        else {
            image += img;
        }
        return image;
    }

    getContentWFImage(): string {
        let image: string = "";

        image = "assets/images/";
        let img = ProdGenApi.getLargeDefaultImageFromType(ContentType_T.workflow);

        if (img.indexOf("default") == -1) {
            image = img;
        }
        else {
            image += img;
        }
        return image;
    }

    getContentCSImage(): string {
        let image: string = "";

        image = "assets/images/";
        let img = ProdGenApi.getLargeDefaultImageFromType(ContentType_T.cheatsheet);

        if (img.indexOf("default") == -1) {
            image = img;
        }
        else {
            image += img;
        }
        return image;
    }

    getContentVidImage(): string {
        let image: string = "";

        image = "assets/images/";
        let img = ProdGenApi.getLargeDefaultImageFromType(ContentType_T.video);

        if (img.indexOf("default") == -1) {
            image = img;
        }
        else {
            image += img;
        }
        return image;
    }

    onKeySearchPress(event: any) {
        if (event.keyCode == 13) {
            this.onSearch();
        }
    }
    onKeySearchInput(event: any) {
        if (event.data == null && event.composed != null && event.composed == false) {
            this.onSearch();
        }
    }

    onSearch(): void {
        let url: string = "";
        if (this.searchTerms.trim().length > 0) {
            url += "search?searchTerms=" + SearchComponent.encodeSearchParameters(this.searchTerms.trim());
            this.router.navigateByUrl(url);
        }
    }


    onImageChanged(event: any) {
        if (this.imageId == "") {
            this.newImageFile = event.fileData;
            this.thumbnailURL = event.imageURL;
        }
        else {
            this.currentWorkgroup.imageURL = event.imageURL;
            if (this.currentWorkgroup.imageURL == "") {
                this.currentWorkgroup.imageURL = "../../assets/images/default_project.jpg";
            }
            this.thumbnailURL = this.currentWorkgroup.imageURL.toString();
        }
    }

    onNewWorkgroupClick() {
        this.imageId = "clear";

        this.newModifyWorkgroupTitle = this.translationService.getTranslationFileData("WORKCENTER.TS_CreateWorkgroup");
        this.newWorkgroupName = "";
        this.newWorkgroupDesc = "";
        this.imageId = "";
        this.isModify = false;

        this.thumbnailURL = localStorage.getItem(ProdGenApi.getCurrentTenantId() + "_defWorkgroupImage");

        $('#newWorkgroupModal').modal('show');
    }

    onModifyWorkgroupClick() {

        this.imageId = "clear";

        this.newModifyWorkgroupTitle = this.translationService.getTranslationFileData("WORKCENTER.TS_ModifyWorkgroup");
        this.newWorkgroupName = this.currentWorkgroup.name.toString();
        this.newWorkgroupDesc = this.currentWorkgroup.description.toString();
        this.imageId = this.currentWorkgroup.workgroupId.toString();
        this.isModify = true;

        this.thumbnailURL = this.currentWorkgroup.imageURL.toString();

        $('#newWorkgroupModal').modal('show');
    }

    onWorkgroupImageClick() {
        $('#changeWorkgroupImage').modal('show');
    }

    onChange(event): void {
        const val = event.target.value;
        if (val == "Asset Name") {
            this.isDefault = true;
        }
        else if (val == "Asset Type") {
            this.isDefault = false;
        }

        sessionStorage.setItem("workGroupSortBy", val);
    }

    getThumbnailImage() {
      
        return this.sanitizer.bypassSecurityTrustUrl(this.thumbnailURL);
    }

    onFacetHeaderClicked(permissionLevel: string) {
        this.getExpandedIconClass();

        var isExpanded = $("#image_" + permissionLevel).hasClass("fa-minus");
        $("#image_" + permissionLevel).toggleClass("fa-minus");
        $("#image_" + permissionLevel).toggleClass("fa-plus");

        if (permissionLevel == 'user') {

            if (isExpanded) {
                this.collapseAll = true;
            }
            else {
                this.collapseAll = false;
            }
        }

        else if (permissionLevel == 'owner') {

            if (isExpanded) {
                this.collapseOwners = true;
            }
            else {
                this.collapseOwners = false;
            }
        }

        else if (permissionLevel == 'editor') {

            if (isExpanded) {
                this.collapseEditors = true;
            }
            else {
                this.collapseEditors = false;
            }
        }
    }

    getExpandedIconClass(): string {
        return "fa fa-minus";
    }

    toggleShowAllUsers(permissionLevel: string) {

        if (permissionLevel == 'user') {
            this.showAll = !this.showAll;
        }
        else if (permissionLevel == 'owner') {
            this.showOwners = !this.showOwners;
        }
        else if (permissionLevel == 'editor') {
            this.showEditors = !this.showEditors;
        }
    }

    ExpandDefaultUsersList(permissionLevel: string) {
        this.onFacetHeaderClicked(permissionLevel);
    }


    // Part of asset reordering. Moves the selected
    // item up one position.
    moveUp(item: Content) {
        this.initAssetMidpoints();
        let newAssets = this.filteredAssets;
        let itemIndex = newAssets.findIndex(function (element) {
            return element.contentId == item.contentId;
        });
        if (itemIndex > 0) {
            let itemAbove = newAssets[itemIndex - 1];
            newAssets[itemIndex - 1] = item;
            newAssets[itemIndex] = itemAbove;
            this.filteredAssets = newAssets;

            window.scrollTo(0, pageYOffset - this.assetHeight);
            this.highlightElement(item.contentId as string);
        }
    }

    // Part of asset reordering. Moves the selected
    // item down one position.
    moveDown(item: Content) {
        this.initAssetMidpoints();
        let newAssets = this.filteredAssets;
        let itemIndex = newAssets.findIndex(function (element) {
            return element.contentId == item.contentId;
        });
        if (itemIndex < newAssets.length - 1) {
            let itemBelow = newAssets[itemIndex + 1];
            newAssets[itemIndex + 1] = item;
            newAssets[itemIndex] = itemBelow;
            this.filteredAssets = newAssets;

            window.scrollTo(0, pageYOffset + this.assetHeight);
            this.highlightElement(item.contentId as string);
        }
    }

    // Part of asset reordering. Moves the selected
    // item to the top of the list.
    makeFirst(item: Content, event) {
        this.initAssetMidpoints();
        let oldIndex = this.filteredAssets.findIndex(i => { return item.contentId == i.contentId; });
        let newXoffset = oldIndex * this.assetHeight;

        let newAssets = new Array<Content>();
        newAssets.push(item);
        for (let a of this.filteredAssets) {
            if (a.contentId != item.contentId) {
                newAssets.push(a);
            }
        }
        this.filteredAssets = newAssets;

        window.scrollTo(0, pageYOffset - newXoffset);
        this.highlightElement(item.contentId as string);
    }

    // Part of asset reordering. Moves the selected
    // item to the bottom of the list.
    makeLast(item: Content, event) {
        this.initAssetMidpoints();
        let oldIndex = this.filteredAssets.findIndex(i => { return item.contentId == i.contentId; });
        let newXoffset = ((this.filteredAssets.length - 1) - oldIndex) * this.assetHeight;

        let newAssets = new Array<Content>();
        for (let a of this.filteredAssets) {
            if (a.contentId != item.contentId) {
                newAssets.push(a);
            }
        }
        newAssets.push(item);
        this.filteredAssets = newAssets;

        window.scrollTo(0, pageYOffset + newXoffset);
        this.highlightElement(item.contentId as string);
    }

    // Part of asset reordering. Moves the selected
    // item to the destination in the list. (Hint:
    // the destination is index + 1)
    moveTo(item: Content, p_destination: any) {
        this.initAssetMidpoints();
        let destination = Number(p_destination);

        // Sync large and small versions of the page
        (document.getElementById(item.contentId.toString() + "inputLG") as HTMLInputElement).value = p_destination as string;
        (document.getElementById(item.contentId.toString() + "inputSM") as HTMLInputElement).value = p_destination as string;

        if (destination > 0 && destination < 100000) {

            // Per Chris, if the destination is larger than
            // the size of the list, append the item anyway
            if (destination > this.filteredAssets.length) {
                destination = this.filteredAssets.length;
            }

            let newAssets = this.filteredAssets;
            let curIndex = newAssets.findIndex(function (element) {
                return element.contentId == item.contentId;
            });

            // If item is not already at location...
            if ((curIndex + 1) != destination) {

                // Store
                let temp = newAssets[curIndex];

                // Remove
                newAssets.splice(curIndex, 1);

                // Replace
                newAssets.splice(destination - 1, 0, temp);

                // Highlight
                this.highlightElement(item.contentId as string);

                // Scroll
                let newYOffset = ((destination - (curIndex + 1)) * this.assetHeight);
                window.scrollTo(0, pageYOffset + newYOffset);
            }
        }
        else {
            this.highlightElement(item.contentId.toString());
            document.getElementById(item.contentId.toString() + "inputLG").classList.add("inptInvalid");
            document.getElementById(item.contentId.toString() + "iconLG").style.color = "darkred";
            document.getElementById(item.contentId.toString() + "inputSM").classList.add("inptInvalid");
            document.getElementById(item.contentId.toString() + "iconSM").style.display = "inline-block";
        }
    }

    // Part of asset reordering. Handles highlighting
    // the row containing the selected item.
    highlightElement(itemID: string) {
        if (this.highlightedID != "") {
            document.getElementById(this.highlightedID).style.backgroundColor = "transparent";
            document.getElementById(this.highlightedID + "btnFirst").classList.remove("btnHighlighted");
            document.getElementById(this.highlightedID + "btnUp").classList.remove("btnHighlighted");
            document.getElementById(this.highlightedID + "btnDown").classList.remove("btnHighlighted");
            document.getElementById(this.highlightedID + "btnLast").classList.remove("btnHighlighted");

            document.getElementById(this.highlightedID + "inputLG").classList.remove("inptInvalid");
            document.getElementById(this.highlightedID + "iconLG").style.color = "transparent";
            document.getElementById(this.highlightedID + "inputSM").classList.remove("inptInvalid");
            document.getElementById(this.highlightedID + "iconSM").style.display = "none";
        }
        document.getElementById(itemID).style.backgroundColor = "#d1dceb";
        document.getElementById(itemID + "btnFirst").classList.add("btnHighlighted");
        document.getElementById(itemID + "btnUp").classList.add("btnHighlighted");
        document.getElementById(itemID + "btnDown").classList.add("btnHighlighted");
        document.getElementById(itemID + "btnLast").classList.add("btnHighlighted");
        this.highlightedID = itemID;
    }

    // Part of asset reordering. Handles input
    // from the large version of the page.
    onInputChangedLG(event) {
        var curItem = this.filteredAssets.find(function (element) {
            return (element.contentId.toString() + "inputLG") == event.target.id;
        });
        this.moveTo(curItem, event.target.value);
    }

    // Part of asset reordering. Handles input
    // from the small version of the page.
    onInputChangedSM(event) {
        var curItem = this.filteredAssets.find(function (element) {
            return (element.contentId.toString() + "inputSM") == event.target.id;
        });
        this.moveTo(curItem, event.target.value);
    }

    // Part of asset reordering. Called whenever
    // the modify mode is toggled by the user.
    // Handles calling the API service to update
    // the sequence numbers for the items.
    tryModifyIndices() {
        var newIndices = new Array<WorkgroupContentIndex>();
        for (let i = 0; i < this.filteredAssets.length; i++) {
            let curItem = new WorkgroupContentIndex();
            curItem.workgroupID = this.currentWorkgroup.workgroupId;
            curItem.wgcontentID = this.wgcontentIDMap[this.filteredAssets[i].contentId as string];
            curItem.contentID = this.filteredAssets[i].contentId;
            curItem.dateTimeAdded = this.dateTimeAddedMap[this.filteredAssets[i].contentId as string];
            curItem.index = i;
            newIndices.push(curItem);
        }

        // (Hint: you have to subscribe to the API even if it doesn't return anything)
        this.pinnacleService.modifyWorkgroupContentIndices(this.currentWorkgroup.workgroupId, newIndices).subscribe(res => {
        });
    }

    // Part of asset reordering. Called whenever
    // the modify mode is toggled by the user and
    // whenever the page is resized. Calculates
    // the DOM locations to jump to for each
    // index in the list.
    initAssetMidpoints() {
        if (this.filteredAssets.length > 0) {
            this.assetHeight = (this.assetsRef.first as ElementRef).nativeElement.offsetHeight;
            this.assetHeight += 16;
        }
        else {
            this.assetHeight = 0;
        }
    }

    // Part of asset reordering.
    onResize(event) {
        if (this.isAssetEditMode) {
            this.initAssetMidpoints();
        }
    }
}
