import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslationService } from '../services/TranslationService';

@Pipe({ name: 'Translate' })
export class TranslationPipe implements PipeTransform {


	dataLoaded: boolean = false;

	language: string = "";

	constructor(private sanitizer: DomSanitizer, private tranService: TranslationService) { }

	transform(key) {
		return this.tranService.getTranslationFileData(key);

	}
}