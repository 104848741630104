import { Component, OnInit } from '@angular/core';
import { Content, ContentProduct } from '../apiService/classFiles/class.content';
import { Enrollment } from '../apiService/classFiles/class.enrollments';
import { Playlist, PlaylistContent, User } from '../apiService/classFiles/class.users';
import { ProdGenApi } from '../apiService/prodgen.api';
import { AppControlService } from '../AppControlService';
import { BrowserAuthenticationService } from '../BrowserAuthenticationService';
import { TranslationService } from '../services/TranslationService';

declare function doesFontExist(fontName: string): boolean;
declare var $: any;

declare var nicEditor: any;


@Component({
  selector: 'app-offlineviewer',
  templateUrl: './offlineviewer.component.html',
    styleUrls: ['./offlineviewer.component.css'],

})
export class OfflineViewerComponent implements OnInit {
    //THIS IS ALL COPIED FROM PLAYLIST COMPONENT
  currentuser: User = new User();
  playlist: Playlist = new Playlist();
  content: Array<PlaylistContent> = new Array<PlaylistContent>();
  clonedcontent: Array<PlaylistContent> = new Array<PlaylistContent>();
  enrollmentarray: Array<Enrollment> = new Array<Enrollment>();
    enrollmentobject: Object;
  contentcheatsheet: Array<PlaylistContent> = new Array<PlaylistContent>();
  contentlearningpath: Array<PlaylistContent> = new Array<PlaylistContent>();
  contentcourse: Array<PlaylistContent> = new Array<PlaylistContent>();
  contentquiz: Array<PlaylistContent> = new Array<PlaylistContent>();
  contentvideo: Array<PlaylistContent> = new Array<PlaylistContent>();
  contentworkflow: Array<PlaylistContent> = new Array<PlaylistContent>();
  contentprocess: Array<PlaylistContent> = new Array<PlaylistContent>();
  contenttask: Array<PlaylistContent> = new Array<PlaylistContent>();
  contentstep: Array<PlaylistContent> = new Array<PlaylistContent>();
  contentchatLog: Array<PlaylistContent> = new Array<PlaylistContent>();
  contentworkgroup: Array<PlaylistContent> = new Array<PlaylistContent>();
  contenttrainingclass: Array<PlaylistContent> = new Array<PlaylistContent>();

  externalvids: Array<PlaylistContent> = new Array<PlaylistContent>();


  ContentItem: Content = new Content();
  displayEdit: boolean = false;
  allselected: boolean = false;
  newtitletext: string = "";
  assestsLoaded: boolean = false;
  enrollmentsLoaded: boolean = false;
  filterText: string = "";
  isDefault: boolean = true;
  downloadmode: boolean = false;
  iswindowsdevice: boolean = false;
  dlExpirationDays: number = 0;

  deletedcheat: string = "";

  Editid: string = "";
  Editname: string = "";
  Editdesc: string = "";
  Editprods: Array<ContentProduct> = new Array<ContentProduct>();
  isNew: boolean = true;
  modalVisible = false;

  pendingDeletedMyCheat: PlaylistContent = new PlaylistContent();

    constructor(private pinnacleService: ProdGenApi, private authenticationService: BrowserAuthenticationService, private tranService: TranslationService, private controlService: AppControlService) {
    

    }

    ngOnInit() {

    
    }

 
}
