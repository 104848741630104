import { HttpClient } from '@angular/common/http';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnInit, Renderer, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ProdGenApi } from './../../apiService/prodgen.api';
import { AppControlService } from './../../AppControlService';
import { AutoplayVidService } from './../../AutoplayVidService';
import { TranslationService } from './../../services/TranslationService';
import { CourseViewerSyllabusComponent } from './../courseviewersyllabus/courseviewersyllabus.component';

declare function makeAblePlayerReady(): void;
declare var $: any;
declare var AblePlayer: any;

@Component({
    providers: [CourseViewerSyllabusComponent],
    selector: 'app-bentleycontent',
    templateUrl: './bentleycontent.component.html',
    styleUrls: ['./bentleycontent.component.css']
})
export class BentleyContentComponent implements OnInit, AfterViewInit, OnChanges {
    @Input('contentId') contentId: string;
    @Input('enrollmentId') enrollmentId?: string;
    @Input('content_url') contentUrl?: string;
    renderer: Renderer = null;
    viewerhtmlcontent: SafeHtml = "";
    isComplete: boolean = false;
    constructor(private service: ProdGenApi, private _sanitizer: DomSanitizer, private http: HttpClient, elementRef: ElementRef, renderer: Renderer, private appService: AppControlService, private changeRef: ChangeDetectorRef, private translationService: TranslationService, private autoplaysidservice: AutoplayVidService, private courseviewersyllabus: CourseViewerSyllabusComponent) {

        this.renderer = renderer;
        this.loadData();

    }

    ngOnInit() {
        this.bentleyAuth();
        
    }

    ngAfterViewInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
        try {
            if (changes['contentId'] != null) {
                this.contentId = changes['contentId'].currentValue;
            }
            if (changes['contentUrl'] != null) {
                this.contentUrl = changes['contentUrl'].currentValue;
                this.setupIFrame();
                console.log(this.contentUrl);
               // this.viewerhtmlcontent = this._sanitizer.bypassSecurityTrustHtml('<iframe src="' + this.contentUrl + '" style="width: 100%; height: 60vh;"></iframe>');
                //                     <!--src="https://learn.bentley.com/app/VideoPlayer/BentleyLecture?CourseId=114236"--> 

            }
        } catch (err) {
            console.log(err);
        };
    }

    bentleyAuth() {
        let clientId = 'bentley-learn-api';
        let clientSecret = 'zFOwAAKaahiyRWYMACt1gqh78hE23F4aWe8d+0Ki6wEiZMRwdRBj1nP1QHfWVRytjQv2Q8abi/sefcSO/ym6tw==';

     let body=   `{
  "accessTokenType": "jwt",
  "grantType": "hybrid",
  "allowedScopes": [
    "openid",
    "profile",
    "global_roles",
    "email",
    http://qa-learn.bentley.com/app/,
    "ims-openid-connect",
    "notification-service-20"
  ],
  "clientType": "webapp",
  "redirectUris": [
    http://localhost:5000/signin-oidc,
    https://localhost:44301/signin-oidc,
    https://portal.pinnacleseries.com/signin-oidc,
    https://portalbeta.pinnacleseries.com/signin-oidc,
    https://portal.pinnacleseries.com/api/signin_oidc,
    https://portalbeta.pinnacleseries.com/api/signin_oidc
  ],
  "postLogoutRedirectUris": [
    http://localhost:5000/signout-callback-oidc,
    https://localhost:44301/signout-callback-oidc,
    https://portal.pinnacleseries.com/api/signout_oidc,
    https://portalbeta.pinnacleseries.com/api/signout_oidc
  ],
  "requireConsent": false,
  "clientId": "bentley-learn-api",
  "productId": 2383,
  "clientName": "Bentley LEARN API",
  "logoUri": null,
  "clientUri": null,
  "frontChannelLogoutUri": null,
  "backChannelLogoutUri": null
}
`

        //this.service.postBentleyAuth(clientId, clientSecret, body);
    }

    setupIFrame() {
        let container = document.getElementById("bentleycontentviewer");
        if (container != null) {
            let prevChild = container.firstChild;
            if (prevChild) {
                container.removeChild(prevChild);
            }

            let newChild = document.createElement("iframe");
            //newChild.style.width = "600px";
            //newChild.style.height = "500px";
            newChild.src = this.contentUrl;
            newChild.style.width = "800px";
            newChild.style.height = "600px";
            
            //newChild.referrerPolicy = "unsafe-url";
            
            container.appendChild(newChild);
        }
    }

    addEventHandlers() {
    }

    loadData() {
    }


}

