import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
    name: 'pinnacleDateTime'
})
export class PinnacleDateTimePipe extends DatePipe implements PipeTransform {

    /* 
     *  Usage example:
     *  
     *  // Setup
     *  localStorate.setItem("selectedLanguage": "en"); // Set in login.component.ts on user authentication
     *  obj = { dateTime: new Date(Date.now()), endDateTime: new Date(Date.now() + (3 * 60000)) }; // Now until 3 hours from now
     *  
     *  // Use in template
     *  <span> {{obj.dateTime | pinnacleDateTime:[{ content:'time' }] }}</span>                     => '3:14PM'
     *  <span> {{obj.dateTime | pinnacleDateTime:[{ content:'time', convert: 'true' }] }}</span>    => '4:14PM' (if user is in EST)
     *  <span> {{obj.dateTime | pinnacleDateTime:[{ content:'date' }] }}</span>                     => 'Tue, Feb 11, 2020'
     *  <span> {{obj.dateTime | pinnacleDateTime}} </span>                                          => 'Tue, Feb 11, 2020, 3:14 PM'
     *  <span> {{obj.dateTime | pinnacleDateTime:[{ range:obj.endDateTime }] }}</span>              => 'Tue, Feb 11, 2020, 3:14 PM - 6:14 PM'
     *  <span> {{obj.dateTime | pinnacleDateTime:[{ shortRange:obj.endDateTime }] }}</span>         => 'Feb 11, 2020 - Feb 11, 2020' (more useful when dates are different)
     *  
     *  // Use in code-behind
     *  PinnacleDateTimePipe.toLocalDateTime(obj.endDateTime);                                      => 'Tue, Feb 11, 2020, 6:14 PM' (Date)
     *  PinnacleDateTimePipe.transform(obj.dateTime, [{ range:obj.endDateTime }]);                  => 'Tue, Feb 11, 2020, 3:14 PM - 6:14 PM' (string)
     *  
     */

    static dateTimeOptions = { year: "numeric", month: "short", weekday: "short", day: "numeric", hour: "numeric", minute: "2-digit" };
    static dateOptions = { year: "numeric", month: "short", weekday: "short", day: "numeric" };
    static shortDateOptions = { year: "numeric", month: "short", day: "numeric" };
    static timeOptions = { hour: "numeric", minute: "2-digit" };

    transform(value: any, args?: any): any {
        return PinnacleDateTimePipe.transform(value, args);
    }

    static transform(value: any, args?: any): any {
        let options = {};
        let language = localStorage.getItem("selectedLanguage");

        if (language == undefined || language == "") {
            language = "en";
        }

        let date = new Date(value as Date);

        options = PinnacleDateTimePipe.dateTimeOptions;
        if (args != undefined && args[0] != undefined) {
            switch (args[0].content) {
                case "date":
                    options = PinnacleDateTimePipe.dateOptions;
                    break;
                case "time":
                    options = PinnacleDateTimePipe.timeOptions;
                    break;
                default:
                    break;
            }

            if (args[0].convert == "true") {

                // JS Date class is awful.
                // To correct for timezone, call Date.getTimezoneOffset() which returns
                // the value of (UTC - local time) in minutes. The value returned for CST
                // is 360 (UTC is CST+6). To use this correctly, convert the time back to
                // milliseconds, then subtract (add the inverse of) the offset.

                date.setTime(date.getTime() - (date.getTimezoneOffset() * 60000));
            }

            if (args[0].range != undefined) {
                let endDate = (args[0].range as Date);
                if (date.getFullYear() == endDate.getFullYear() && date.getMonth() == endDate.getMonth() && date.getDate() == endDate.getDate()) {
                    return date.toLocaleString(language, this.dateTimeOptions) + " - " + (args[0].range as Date).toLocaleString(language, this.timeOptions);
                }
                return date.toLocaleString(language, this.dateTimeOptions) + " - " + endDate.toLocaleString(language, this.dateTimeOptions);
            }

            if (args[0].shortRange != undefined) {
                let endDate = (args[0].shortRange as Date);
                return date.toLocaleString(language, this.shortDateOptions) + " - " + endDate.toLocaleString(language, this.shortDateOptions);
            }
        }
        return date.toLocaleString(language, options);
    }

    static toLocalDateTime(value: Date): Date {
        return new Date(value.getTime() - (value.getTimezoneOffset() * 60000));
    }
}