import { Component, OnInit, Input, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Content, ContentType_T, DescriptionFilter_T } from './../../apiService/classFiles/class.content';
import { ProdGenApi } from './../../apiService/prodgen.api';

declare var $: any;

@Component({
  selector: 'template-relatedworkflows',
  templateUrl: './relatedworkflows.component.html',
  styleUrls: ['./relatedworkflows.component.css'],
    providers: [ProdGenApi],
})
export class RelatedworkflowsComponent implements OnInit {

    @Input('contentId') contentId: string;
    @Input('contentType') contentType: string;
    relatedContent: Array<Content> = new Array<Content>();
    maxRelatedToShow: number = 6;



    constructor(private pinnacleService: ProdGenApi, private route: ActivatedRoute, private router: Router)
    {
    }

	ngOnInit() {
        //this.getRelatedContent();
    }

    ngOnChanges(changes: SimpleChanges) {


        try { this.contentId = changes['contentId'].currentValue } catch (err) { };
        try { this.contentType = changes['contentType'].currentValue } catch (err) { };
        this.getRelatedContent();

    }

    getRelatedContent()
    {
        if (this.contentId == null || this.contentId.length == 0)
        {
            return;
        }
		this.pinnacleService.GetContentRelatedItems(this.contentId, this.contentType, -1, 0, DescriptionFilter_T.unformatted).subscribe(r => {
            this.relatedContent = r;
        });
    }

    onShowMore()
    {
        this.maxRelatedToShow = 999;
    }

    onShowLess() {
        this.maxRelatedToShow = 6;
    }

    getContentUrl(r: Content): string {
        let url: string = "#";
        if (r.contentType == ContentType_T.workflow) {
            url += "/workflowviewer?id=" + r.contentId + "&contentType=workflow";
        }
        else if (r.contentType == ContentType_T.process) {
            url += "/workflowviewer?id=" + r.contentId + "&contentType=process";
        }
        else if (r.contentType == ContentType_T.task) {
            url += "/workflowviewer?id=" + r.contentId + "&contentType=task";
        }
        else if (r.contentType == ContentType_T.step) {
            url += "/workflowviewer?id=" + r.contentId + "&contentType=step";
        }
        else if (r.contentType == ContentType_T.cheatsheet) {
            url += "/cheatsheetviewer?id=" + r.contentId;
        }
        else if (r.contentType == ContentType_T.video) {
            url += "/videoviewer?id=" + r.contentId;
        }
        else if (r.contentType == ContentType_T.learningpath) {
            url += "/learningcenter/series?learningPathId=" + r.contentId;
        }
        else if (r.contentType == ContentType_T.course) {
            url += "/learningcenter/series?courseId=" + r.contentId;
        }
      return url;
      
    }


    getContentImage(r: Content): string {
        let image: string = "";

      image = "assets/images/";

        if (r.bisCustom == false) {
            if (r.contentType == ContentType_T.cheatsheet && r.docURL != null && r.docURL != "") {
                if (r.docURL.includes(".pdf")) {
                    image += "PDF Graphic.png"
                }
                else if (r.docURL.includes(".doc")) {
                    image += "Word Graphic.png"
                }
                else if (r.docURL.includes(".xls")) {
                    image += "Excel Graphic.png"
                }
                else if (r.docURL.includes(".ppt")) {
                    image += "PowerPoint Graphic.png"
                }
                else if (r.docURL.includes(".htm")) {
                    image += "HTML Graphic.png"
                }
            }
            else {
                image += ProdGenApi.getLargeDefaultImageFromType(r.contentType);
                if (image.indexOf("default") == -1) {
                    image = image.replace("assets/images/", "");
                }
            }
        }
        else {
            try {
                image = localStorage.getItem(ProdGenApi.getCurrentTenantId + "_MyDocsImage");
            } catch (e) {

            }
            if (image == "" || image == null) {
                image = "assets/images/My Cheat Sheets Graphic.png";
            }
        }

        return image;
  }

  removeExternalVid(): void {
    //$('#externalvid').remove();
    //var vidtoremove = document.getElementById("#externalvid");
    //vidtoremove.remove();
  }

}
