import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Apiv2Service } from '../../../apiService/apiv2.service';
import { Content, ContentType_T } from '../../../apiService/classFiles/class.content';
import { Enrollment, EnrollmentFilter_T, EnrollmentStatus_T } from '../../../apiService/classFiles/class.enrollments';
import { User, WidgetProperties } from '../../../apiService/classFiles/class.users';
import { PermissionLevel_T, Workgroup } from '../../../apiService/classFiles/class.workgroups';
import { AssociatedLearning, AssociatedLearningWithProgress } from '../../../apiService/classFiles/v2-groups';
import { ProdGenApi } from '../../../apiService/prodgen.api';
import { WidgetComponent } from '../../widget/widget.component';

declare var $: any;

@Component({
    selector: 'app-associated-learning-widget',
    templateUrl: './associated-learning-widget.component.html',
    styleUrls: ['./associated-learning-widget.component.css']
})
export class AssociatedLearningWidgetComponent extends WidgetComponent implements OnInit, AfterViewInit {
    static key = "AssociatedLearningWidgetComponent";

    widgetLoaded: boolean = false;

    associatedLearning: Array<AssociatedLearningWithProgress> = new Array<AssociatedLearningWithProgress>();
    associatedLearningContent: Array<Content> = new Array<Content>();
    currentUser: User = new User();
    rowCardCount: number = 5;
    isListDisplay: boolean = false;

    //For Share-dropdown Component
    constructedContent: Content = new Content();
    currentHover: string = "";
    shareClickId: string = "";
    shareAdjustmentX: string = '0';
    shareAdjustmentY: string = '0';
    availibleWorkgroups: Array<Workgroup> = new Array<Workgroup>();
    completedAndActiveEnrollmentsForUser: Array<Enrollment> = new Array<Enrollment>();
    activeEnrollments: Array<Enrollment> = new Array<Enrollment>();



    constructor(private pinnacleService: ProdGenApi, private v2Service: Apiv2Service, private changedetectorref: ChangeDetectorRef, private router:Router) {
        // call the base Widget class
        super(pinnacleService, changedetectorref);

        //Widget Defaults
        this.widgetHeight = 6;
        this.widgetWidth = 8;
        this.widgetTitle = "Role Based Learning";

        this.widgetName = "Role Based Learning"
        this.widgetDesc = "Displays role based learning content.";

        //End Widget Defaults

    }

    ngOnInit() {
        super.ngOnInit();

        try {
            //this.categoriesSubscription = JSON.parse(localStorage.getItem(this.currentUser.userId + "_categories_subscription"));
            this.displayType = JSON.parse(localStorage.getItem(this.widgetID + "_displayType"));
        }
        catch (err) {
        }

        // if it was not in storage, reert to default
        if (this.displayType == null) {
            this.displayType = "overlay";
        }

        this.isListDisplay = this.decideListorCards();

        this.createProperties();

    }

    ngAfterViewInit() {
        this.widgetContainer.incrementRenderedWidget();
        this.Load();
        this.changedetectorref.detectChanges();

    }

    Load() {
        super.Load();

        this.pinnacleService.getCurrentUser().subscribe(u => {
            this.currentUser = u;

            this.pinnacleService.getCurrentUserWorkgroups([PermissionLevel_T.owner, PermissionLevel_T.editor]).subscribe(res => {
                this.availibleWorkgroups = res;
            });
            this.pinnacleService.getCurrentUserEnrollments([EnrollmentFilter_T.completed, EnrollmentFilter_T.inProgress]).subscribe(completedenrollres => {
                this.completedAndActiveEnrollmentsForUser = completedenrollres;
                this.activeEnrollments = completedenrollres.filter(x => x.enrollmentStatus == EnrollmentStatus_T.inProgress);
            });

            try {
                this.associatedLearning = JSON.parse(localStorage.getItem(this.currentUser.userId + "_associated_learning"));
                if (this.associatedLearning != null && this.associatedLearning.length > 0) {
                    //this.widgetLoaded = true;
                }
            }
            catch (err) {
                console.log(err)
            }

            // Get Role Based Learning Content
            this.v2Service.getAssociatedLearning(this.currentUser.userId).subscribe(res => {   
                this.associatedLearning = res.userAssociatedLearning
                for (let item of this.associatedLearning) {
                    if (this.associatedLearningContent.findIndex(x => x.contentId == item.contentId) == -1) {
                        let content = new Content();
                        switch (item.contentType) {
                            case 0://course
                                content.contentType = ContentType_T.course;
                                break;
                            case 1://document
                                content.contentType = ContentType_T.cheatsheet;
                                break;
                            case 3://learning path
                                content.contentType = ContentType_T.learningpath;
                                break;
                            case 4://process
                                content.contentType = ContentType_T.process;
                                break;
                            case 5://video
                                content.contentType = ContentType_T.video;
                                break;
                            case 6://workflow
                                content.contentType = ContentType_T.workflow;
                                break;
                            default:
                                content.contentType = ContentType_T.cheatsheet;
                                break;

                        }
                        content.contentId = item.contentId;
                        content.description = item.contentDescription;
                        content.name = item.contentName;
                        content.bisCustom = false;                        
                        this.associatedLearningContent.push(content);
                    }
                }
                /// write to Local Storage for this user
                try {
                    localStorage.setItem(this.currentUser.userId + "_associated_learning", JSON.stringify(this.associatedLearning));
                }
                catch (err) {
                    console.log(err);
                }

            }
            );
        });

    }

    onResize() {
        super.onResize();
        this.getEnrollCardCount();
        this.isListDisplay = this.decideListorCards();

    }
    onWidgetResized() {
        super.onWidgetResized();
        this.getEnrollCardCount();

        this.isListDisplay = this.decideListorCards();
    }

    decideListorCards(): boolean {
        if (this.widgetWidth >= 4) {
            if (this.widgetRenderSize == "sm" || this.widgetRenderSize == "xs") {
                //$('#freqwrapper').height("100%");
                //$('#freqwrapperin').height("100%");
                return true;
            }
            else {
                //$('#freqwrapper').height("auto");
                //$('#freqwrapperin').height("auto");
                return false;
            }

        }
        else {
            //$('#freqwrapper').height("100%");
            //$('#freqwrapperin').height("100%");
            return true;
        }
    }


    checkIfMarkComplete(item: Content) {


        let indexCheck = this.associatedLearning.findIndex(x => x.contentId == item.contentId);
            if (indexCheck != -1) {
                return this.associatedLearning[indexCheck].markCompleted;
        }


        return false;
    }

    checkIfPercentComplete(item: Content) {
        if (item.contentType == 2 || item.contentType == 1) { //course
            let indexCheck = this.associatedLearning.findIndex(x => x.contentId == item.contentId);
            if (indexCheck != -1) {
                return this.associatedLearning[indexCheck].currentProgress;
            }
        }

        return 0
    }


    checkIfRequired(item: Content): boolean {

        let indexCheck = this.associatedLearning.findIndex(x => x.contentId == item.contentId);
        if (indexCheck != -1) {
            return this.associatedLearning[indexCheck].required;
        }

        return false;
    }



    getEnrollCardCount() {
        if (document.getElementById("widget_" + this.widgetID + "_" + this.widgetContainer.seq)) {

            let pxwidth = document.getElementById("widget_" + this.widgetID + "_" + this.widgetContainer.seq).offsetWidth;

            this.rowCardCount = Math.floor(pxwidth / 150);//Integer is the full width of the card, including margins, borders, and padding
        }
        else {
            this.rowCardCount = 0;
        }
    }

    showMoreCaretDown(someId: string) {
        $('#' + someId).fadeIn(300);
        document.getElementById(someId + 'ShowMoreButton').style.display = "none";
        document.getElementById(someId + 'ShowLessButton').style.display = "block";
        $('#' + someId + "associatedLearningBlockContainer").removeClass("small-card-container-singlerow");

        this.widgetContainer.onWidgetExpand(this.widgetID);
    }

    showLessCaretUp(someId: string) {
        $('#' + someId).slideToggle(300);
        document.getElementById(someId + 'ShowLessButton').style.display = "none";
        document.getElementById(someId + 'ShowMoreButton').style.display = "block";
        $('#' + someId + "associatedLearningBlockContainer").addClass("small-card-container-singlerow");
        this.widgetContainer.onWidgetCollapse(this.widgetID);
    }

    getContentUrl(r: AssociatedLearning): string {
        let url: string = "#";
        if (r.contentType == 5) {
            url += "/workflowviewer?id=" + r.contentId + "&contentType=workflow";
        }
        else if (r.contentType == 6) {
            url += "/workflowviewer?id=" + r.contentId + "&contentType=process";
        }
        else if (r.contentType == 7) {
            url += "/workflowviewer?id=" + r.contentId + "&contentType=task";
        }
        else if (r.contentType == 8) {
            url += "/workflowviewer?id=" + r.contentId + "&contentType=step";
        }
        else if (r.contentType == 0) {
            url += "/cheatsheetviewer?id=" + r.contentId;
        }
        else if (r.contentType == 4) {
            url += "/videoviewer?id=" + r.contentId;
        }
        else if (r.contentType == 1) {
            url += "/learningcenter/series?learningPathId=" + r.contentId;
        }
        else if (r.contentType == 2) {
            if (this.completedAndActiveEnrollmentsForUser != null) {
                if (this.completedAndActiveEnrollmentsForUser.findIndex(course => course.courseId == r.contentId) == -1) {
                    url += "/learningcenter/series?courseId=" + r.contentId;
                }
                else {
                    url += "/learningcenter/courseviewer?enrollmentId=" + this.completedAndActiveEnrollmentsForUser[this.completedAndActiveEnrollmentsForUser.findIndex(course => course.courseId == r.contentId)].enrollmentId;
                }
            }


        }
        return url;
    }

    getContentImage(r: AssociatedLearning): string {
            let image: string = "";

            switch (r.contentType) {
                case 0://course
                    image += ProdGenApi.getIconImageFromType(ContentType_T.course);
                    break;
                case 1://document
                    image += ProdGenApi.getIconImageFromType(ContentType_T.cheatsheet);
                    break;
                case 3://learning path
                    image += ProdGenApi.getIconImageFromType(ContentType_T.learningpath);
                    break;
                case 4://process
                    image += ProdGenApi.getIconImageFromType(ContentType_T.process);
                    break;
                case 5://video
                    image += ProdGenApi.getIconImageFromType(ContentType_T.video);
                    break;
                case 6://workflow
                    image += ProdGenApi.getIconImageFromType(ContentType_T.workflow);
                    break;
                default:
                    image = "fas fa-id-badge";
                    break;
            }


            return image;
    }

    //START WIDGET REQUIRED CALLS
    onPropsChanged(newprops: Array<WidgetProperties>, writeSettings: boolean = false, doresequence = true) {
        super.onPropsChanged(newprops, writeSettings, doresequence);


        if (writeSettings) {
            this.pinnacleService.setWidgetProperties(this.widgetContainer.containerInfo.containerInstanceId, this.widgetID, newprops, "AssociatedLearningWidgetComponent").subscribe(res => {/*return stuff. might not need to do anything here*/ });
        }

        super.onPropsChangedLocalStorage
        //this.widgetContainer.UpdateWidgetDimensions(this.widgetID, this.widgetWidth, this.widgetHeight);
        this.widgetLoaded = true;
        super.widgetArePropertiesLoaded();
    }




    createProperties() {

        super.createProperties();


        super.createPropertiesDisplayType("overlay");

        super.createPropertiesWidgetTitle("Role Based Learning");
        super.createPropertiesMobileOptions();


        //let range = new WidgetProperties();
        //range.propName = "Range (in days)"
        //range.propValue = this.timeSpan.toString();
        //range.propType = "number";
        //range.propID = "CHART_RANGE";
        //this.widgetProps.push(range);
    }

    //END WIDGET REQUIRED CALLS


    //For Share DropDown
    buildItem(itemID: string, itemName: string, itemType: ContentType_T): Content {
        let constructedContent = new Content();
        constructedContent.contentId = itemID;
        constructedContent.name = itemName;
        constructedContent.contentType = itemType;

        return constructedContent;
    }

    //For Share DropDown
    hoverElem(content: Content) {
        this.constructedContent = this.buildItem(content.contentId.toString(), content.name.toString(), content.contentType);
        this.currentHover = content.contentId.toString();

        if (this.currentHover != this.shareClickId) {
            this.shareClickId = "";
        }
    }

    //For Share DropDown
    shareClick(content: Content) {
        this.constructedContent = this.buildItem(content.contentId.toString(), content.name.toString(), content.contentType);
        if (this.shareClickId == content.contentId.toString()) {
            this.shareClickId = "";
        }
        else {
            this.shareClickId = content.contentId.toString();
        }

        let tempdiv = document.getElementById('contentScrollArea' + this.widgetID);

        let box = { left: 0, top: 0 };
        box = tempdiv.getBoundingClientRect();


        let reservedSpotShare = document.getElementById('shareIconPlacment' + this.widgetID + content.contentId);

        let box2 = { left: 0, top: 0 };
        box2 = reservedSpotShare.getBoundingClientRect();

        let x = (box.left - box2.left);
        let y = (box.top - box2.top);
        //console.log('x : ' + x + '   y : ' + y);


        if (x > -250) {
            this.shareAdjustmentX = (20) + 'px';
        }
        else {
            this.shareAdjustmentX = ((-1 * x) - 220) + 'px';
        }

        this.shareAdjustmentY = ((-1 * y) + 70) + 'px';

    }

    //For Share DropDown
    onHideShareDropDown(event) {
        this.shareClickId = "";
    }

    //For Share DropDown
    exitHoverElem() {
        if (this.shareClickId == "") {
            this.currentHover = "";
        }
    }

    navToAssociatedLearning() {
        let url = "jobroledetails";
        this.router.navigateByUrl(url).then(i => {
            if (i == false) {
                //console.log("unable to navigate");
            }
        });
    }

    getLocalDate(value: Content): string {
        let datestring = "";
        if (this.associatedLearning && value) {
            let contentindex = this.associatedLearning.findIndex(x => x.contentId == value.contentId);
            if (contentindex != -1) {
                datestring = new Date(this.associatedLearning[contentindex].completedDate).toLocaleDateString();
            }

        }
        return datestring;
    }
}
