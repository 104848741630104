import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ProdGenApi } from './../../apiService/prodgen.api';
import { Content, ContentType_T } from './../../apiService/classFiles/class.content';
import { User } from './../../apiService/classFiles/class.users';


@Component({
    selector: 'template-expertsbutton',
    templateUrl: './expertsbutton.component.html',
    styleUrls: ['./expertsbutton.component.css']
})
export class ExpertsbuttonComponent implements OnInit {
    @Input('contentId') contentId: string;
    users: Array<User> = new Array<User>();

    constructor(private pinnacleService: ProdGenApi) {
        //this.loadData();
    }

    ngOnChanges(changes: SimpleChanges) {


        try { this.contentId = changes['contentId'].currentValue } catch (err) { };
        this.loadData();

    }


    ngOnInit() {
    }

    loadData()
    {
        this.users = new Array<User>();
        if (this.contentId != null) {
            this.pinnacleService.GetContentExperts(this.contentId).subscribe(res => {
                this.users = res;
                for (let u of this.users)
                {
                    if (u.imageURL.length == 0)
                    {
                        u.imageURL = "../../assets/images/default_user.jpg";
                    }
                }
            });
        }

    }

}
