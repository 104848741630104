import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeUrl, SafeHtml } from '@angular/platform-browser';
import { AppControlService, MsgBxStringVals } from '../../AppControlService';
import { TranslationService } from '../../services/TranslationService';
import { Setting } from './../../apiService/classFiles/class.organizations';
import { ProdGenApi } from './../../apiService/prodgen.api';

declare var $: any;

@Component({
    selector: 'template-uploadimage',
    templateUrl: './uploadimage.component.html',
    styleUrls: ['./uploadimage.component.css']
})

export class UploadImageComponent implements OnInit {
    @Input('imageType') imageType: string =""; // defines what is image for
    @Input('imageId') id: string = ""; // userid, workgroupid, etc
    @Input('orginizationalLevel') level: string = "";
    @Input('imagesrc') imagesource: string = "";
    @Input('widgetMarginTop') widgetMarginTop: number;
    @Output('imageChanged') imageChanged?= new EventEmitter<any>();
    imageURL: string = "";
    isUploading: boolean = false;
    imgChangeError: boolean = false;
    imgErrorText: string = "";
	imgCounter: number = 0;
    fileData: File;
    emptyfile: File;
    translatedMsg: string = "";
    defaultFileSize: number = 500;
    byteValue: string = "KB";
    iderror: boolean = false;
    typeerror: boolean = false;
    patherror: boolean = false;
    translatedtitle: string = "";
    v_URL: SafeUrl = "";
    uniqueImageQuery: number = Date.now();
    translatedDefaultButton: string = "";
    wgimgurl: SafeHtml;
    //wgimgurl: string;
    wgDefault: string;

    partnerSettings: Array<Setting> = new Array<Setting>();

    constructor(private pinnacleService: ProdGenApi, private sanitizer: DomSanitizer, private controlService: AppControlService, private translationService: TranslationService) {

    }

    ngOnChanges(changes: SimpleChanges) {


        try { this.id = changes['imageId'].currentValue;  } catch (err) { this.iderror = true };
        try { this.imageType = changes['imageType'].currentValue; } catch (err) { this.typeerror = true };
        try { this.imagesource = changes['imagesrc'].currentValue; } catch (err) { this.patherror = true };

        //if (this.iderror == true && this.typeerror == true) {
        //    //both true, no changes
        //}
        //else {
            this.loadData();
            this.getDisplayImage();
        //}

       
    }


    ngOnInit() {
        this.wgimgurl = localStorage.getItem(ProdGenApi.getCurrentTenantId() + "_defWorkgroupImage");
        this.wgDefault = localStorage.getItem(ProdGenApi.getCurrentTenantId() + "_defWorkgroupImage");
        if (!(this.wgDefault != null && this.wgDefault.length > 0)) {
            this.wgDefault = "assets/images/default_project.jpg";
        }

        this.pinnacleService.GetPartnerSettings().subscribe(res => {
            this.partnerSettings = res;
        });
    }

    loadData()
    {
    //1st Button Label-Default & Clear Button Labeling
        if (this.imageType.toLowerCase() == "news") {
            this.translatedDefaultButton = this.translationService.getTranslationFileData("UPLOADIMAGE.ClearImage");
        }
        else {
            this.translatedDefaultButton = this.translationService.getTranslationFileData("UPLOADIMAGE.UseDefault");
        }

    //2nd Button Label & based on imageType
        if (this.imageType.toLowerCase() == "user") {
            this.translatedtitle = this.translationService.getTranslationFileData("UPLOADIMAGE.ChangeImageTitle");

            // get the current users' image
            this.pinnacleService.getCurrentUser().subscribe(res => {
                this.imageURL = res.imageURL;
            });
        }
        else if (this.imageType.toLowerCase() == "workgroup") {
            this.translatedtitle = this.translationService.getTranslationFileData("UPLOADIMAGE.ChangeWorkgroupImageTitle");
        
            if (this.id != null && this.id != "") {
             
                if (this.id.length > 0 && this.id != "clear") {
                    this.pinnacleService.GetWorkgroup(this.id).subscribe(res => {

                        if (res.imageURL == "") {
                            this.imageURL = this.wgDefault;
                        }
                        else {
                            this.imageURL = res.imageURL.toString();
                        }

                        
                        this.wgimgurl = this.sanitizer.bypassSecurityTrustUrl(this.imageURL);
                    });
                }
                else {
             
                    this.imageURL = this.wgDefault;
                    this.wgimgurl = this.sanitizer.bypassSecurityTrustUrl(this.imageURL);
                }
            }
            else {
                
                this.imageURL = this.imagesource;
                this.wgimgurl = this.sanitizer.bypassSecurityTrustUrl(this.imageURL);
            }
        }
        else if (this.imageType.toLowerCase() == "logo") {
            this.translatedtitle = this.translationService.getTranslationFileData("UPLOADIMAGE.ChangeLogoImageTitle");
            try {
                if (this.level == "company") {
                    this.imageURL = this.getUniqueImageSource(JSON.parse(localStorage.getItem(ProdGenApi.getCurrentTenantId() + "_company_logo")));
                }
                else if (this.level == "partner") {
                    this.imageURL = this.getUniqueImageSource(JSON.parse(localStorage.getItem(ProdGenApi.getCurrentTenantId() + "_partner_logo")));
                }
                else if (this.level == "lite") {
                    this.imageURL = this.getUniqueImageSource(JSON.parse(localStorage.getItem(ProdGenApi.getCurrentTenantId() + "_lite_logo")));
                }
                if (this.imageURL == "") {
                   // document.getElementById("displayimage").style.display = "none";
                }
            }
            catch (err) {
                console.log(err)
            }
        }
        else if (this.imageType.toLowerCase() == "news") {
            this.translatedtitle = this.translationService.getTranslationFileData("UPLOADIMAGE.ChangeNewsImageTitle");
            
            try {
                let i = "";
                if (this.level == "company") {
                    i = JSON.parse(localStorage.getItem(ProdGenApi.getCurrentTenantId() + "_company_news_image"));
                }
                else if (this.level == "partner") {
                    i = JSON.parse(localStorage.getItem(ProdGenApi.getCurrentTenantId() + "_partner_news_image"));
                }
                else if (this.level == "lite") {
                    i = JSON.parse(localStorage.getItem(ProdGenApi.getCurrentTenantId() + "_lite_news_image"));
                }
                if (i != null) {
                    this.imageURL = this.getUniqueImageSource(i);

                    if (this.imageURL == "") {
                       // document.getElementById("displayimage").style.display = "none";
                    }
                }
            }
            catch (err) {
                console.log(err)
            }
        }
        else if (this.imageType.toLowerCase() == "docs") {
            this.translatedtitle = this.translationService.getTranslationFileData("UPLOADIMAGE.ChangeDocImageTitle");

            try {
                let i = this.imagesource;
                if (i != null) {
                    this.imageURL = i;
                }
            }
            catch (err) {
                console.log(err)
            }
        }
        else if (this.imageType.toLowerCase() == "mydocs") {
            this.translatedtitle = this.translationService.getTranslationFileData("UPLOADIMAGE.ChangeMyDocImageTitle");

            try {
                let i = this.imagesource;
                if (i != null) {
                    this.imageURL = i;
                }
            }
            catch (err) {
                console.log(err)
            }
        }
        else if (this.imageType.toLowerCase() == "vids") {
            this.translatedtitle = this.translationService.getTranslationFileData("UPLOADIMAGE.ChangeVidImageTitle");

            try {
                let i = this.imagesource;
                if (i != null) {
                    this.imageURL = i;
                }
            }
            catch (err) {
                console.log(err)
            }
        }
        else if (this.imageType.toLowerCase() == "lps") {
            this.translatedtitle = this.translationService.getTranslationFileData("UPLOADIMAGE.ChangeLPImageTitle");

            try {
                let i = this.imagesource;
                if (i != null) {
                    this.imageURL = i;
                }
            }
            catch (err) {
                console.log(err)
            }
        }
        else if (this.imageType.toLowerCase() == "course") {
            this.translatedtitle = this.translationService.getTranslationFileData("UPLOADIMAGE.ChangeCourseImageTitle");

            try {
                let i = this.imagesource;
                if (i != null) {
                    this.imageURL = i;
                }
            }
            catch (err) {
                console.log(err)
            }
        }
        else if (this.imageType.toLowerCase() == "quiz") {
            this.translatedtitle = this.translationService.getTranslationFileData("UPLOADIMAGE.ChangeQuizImageTitle");

            try {
                let i = this.imagesource;
                if (i != null) {
                    this.imageURL = i;
                }
            }
            catch (err) {
                console.log(err)
            }
        }
        else if (this.imageType.toLowerCase() == "wfs") {
            this.translatedtitle = this.translationService.getTranslationFileData("UPLOADIMAGE.ChangeWFImageTitle");

            try {
                let i = this.imagesource;
                if (i != null) {
                    this.imageURL = i;
                }
            }
            catch (err) {
                console.log(err)
            }
        }
        else if (this.imageType.toLowerCase() == "process") {
            this.translatedtitle = this.translationService.getTranslationFileData("UPLOADIMAGE.ChangeProcessImageTitle");

            try {
                let i = this.imagesource;
                if (i != null) {
                    this.imageURL = i;
                }
            }
            catch (err) {
                console.log(err)
            }
        }
        else if (this.imageType.toLowerCase() == "defworkgroup") {
            this.translatedtitle = this.translationService.getTranslationFileData("UPLOADIMAGE.ChangeWorkgroupImageTitle");

            try {
                let i = this.imagesource;
                if (i != null) {
                    this.imageURL = i;
                }
            }
            catch (err) {
                console.log(err)
            }
        }
        else if (this.imageType.toLowerCase() == "enrollment") {
            this.translatedtitle = this.translationService.getTranslationFileData("UPLOADIMAGE.ChangeEnrollmentImageTitle");

            try {
                let i = this.imagesource;
                if (i != null) {
                    this.imageURL = i;
                }
            }
            catch (err) {
                console.log(err)
            }
        }
        else if (this.imageType.toLowerCase() == "widgetprop") {
            this.translatedtitle = this.translationService.getTranslationFileData("UPLOADIMAGE.ChangeWidgetPropImageTitle");

            try {
                let i = this.imagesource;
                if (i != null) {
                    this.imageURL = i;
                }
            }
            catch (err) {
                console.log(err)
            }
        }
    }

    useDefaultImage()
    {
        this.imageURL = "";
        this.wgimgurl = "";
        console.log(this.imageType);
        switch (this.imageType.toLowerCase()) {
            case "docs":
                this.imageURL = "assets/images/default_cheatsheet.jpg";
                break;
            case "mydocs":
                this.imageURL = "assets/images/My Cheat Sheets Graphic.png";
                break;
            case "vids":
                this.imageURL = "assets/images/default_video.jpg";
                break;
            case "lps":
                this.imageURL = "assets/images/default_learningpath.jpg";
                break;
            case "course":
                this.imageURL = "assets/images/default_course.jpg";
                break;
            case "quiz":
                this.imageURL = "";
                break;
            case "wfs":
                this.imageURL = "assets/images/default_workflow.jpg";
                break;
            case "process":
                this.imageURL = "assets/images/default_process.jpg";
                break;
            case "defworkgroup":
                this.imageURL = "assets/images/default_project.jpg";
                break;
            case "enrollment":
                this.imageURL = "assets/images/default_course.jpg";
                break;
            case "workgroup":
                this.imageURL = this.wgDefault;
                break;
            case "widgetprop":
                this.imageURL = "assets/images/default_project.jpg";
            case "logo":
                //code to get rid of the company logo and fall back to the partner default
                if (this.partnerSettings.find(v => v.name == "PARTNER_LOGO")) {
                    console.log(this.partnerSettings.find(v => v.name == "PARTNER_LOGO"));
                    this.imageURL = this.partnerSettings.find(v => v.name == "PARTNER_LOGO").settingValue.toString();
                }
            default:
                break;
        }


      //  document.getElementById("displayimage").style.display = "none";
        this.uploadImage(null, "");


        $('#fileInput')[0].value = '';

    } 

    getUniqueImageSource(source: string): string {
        let newSource = "";
        if (source != null) {
            if (source.length > 0) {
                if (source.indexOf("?") == -1) {
                    newSource = source + "?" + this.uniqueImageQuery + Math.floor((Math.random() * 10000) + 1);
                }
                else {
                    newSource = source + "&" + this.uniqueImageQuery + Math.floor((Math.random() * 10000) + 1);
                }
            }
        }
        return newSource;
    }

	getDisplayImage()
    {
        if (this.imageURL != null && this.imageURL != "") {
            this.v_URL = this.sanitizer.bypassSecurityTrustUrl(this.getUniqueImageSource(this.imageURL));
           
        }
        else {
            if (this.imageType.toLowerCase() == "user") {
                this.v_URL = "assets/images/default_user.jpg"
            }
            else if (this.imageType.toLowerCase() == "workgroup") {
                this.v_URL = "assets/images/default_project.jpg"
            }
            else if (this.imageType.toLowerCase() == "docs") {
                this.v_URL = "assets/images/default_cheatsheet.jpg"
            }
            else if (this.imageType.toLowerCase() == "mydocs") {
                this.v_URL = "assets/images/My Cheat Sheets Graphic.png"
            }
            else if (this.imageType.toLowerCase() == "vids") {
                this.v_URL = "assets/images/default_video.jpg"
            }
            else if (this.imageType.toLowerCase() == "lps") {
                this.v_URL = "assets/images/default_learningpath.jpg"
            }
            else if (this.imageType.toLowerCase() == "course") {
                this.v_URL = "assets/images/default_course.jpg"
            }
            else if (this.imageType.toLowerCase() == "quiz") {
                this.v_URL = "assets/images/default_quiz.jpg"
            }
            else if (this.imageType.toLowerCase() == "wfs") {
                this.v_URL = "assets/images/default_workflow.jpg"
            }
            else if (this.imageType.toLowerCase() == "process") {
                this.v_URL = "assets/images/default_process.jpg"
            }
            else if (this.imageType.toLowerCase() == "defworkgroup") {
                this.v_URL = "assets/images/default_project.jpg"
            }
            else if (this.imageType.toLowerCase() == "enrollment") {
                this.v_URL = "assets/images/default_course.jpg"
            }
            else if (this.imageType.toLowerCase() == "widgetprop") {
                this.v_URL = "assets/images/default_project.jpg"
            }
            else {
                this.v_URL = "";
            }
        }
    }

    browseForImage() {
        console.log('1');
        console.log(this.imageType);
        console.log(this.imagesource);
        if (this.imageType.toLocaleLowerCase() == "widgetprop" && this.imagesource == "assets/images/default_project.jpg") {
            this.useDefaultImage();
        }

        if ($('input[type=file]') != null) {
            $('input[type=file]').trigger("click");
        }
    }

    fileChange(event): void {
      //  document.getElementById("displayimage").style.display = "block";
        const fileList: FileList = event.target.files;
        if (fileList.length > 0) {
            const file = fileList[0];
            this.uploadImage(file,file.name);
        }
    }

    uploadImage(file:File, filename:string) 
    {

        if (file != null) {
            var sizeInKB = file.size / 1000;
            this.translatedMsg = this.translationService.getTranslationFileData("APP.ImageDefaultSize");
            if (sizeInKB > this.defaultFileSize) {

                var v_Msg = new MsgBxStringVals();
                v_Msg.body = this.translatedMsg + this.defaultFileSize.toString() + this.byteValue;
                v_Msg.title = ``;
                this.controlService.openMessageBoxPopUp(v_Msg);
                
                event.preventDefault();
                return;
            }            
        }
        else {
            file = this.emptyfile;
        }
   
        this.isUploading = true;

        if (this.imageType.toLowerCase() == "user") {

            this.pinnacleService.updateCurrentUserImage(file).subscribe(res => {
                if (res != "") {
                    this.imageURL = res.toString();
                }
                else {
                    this.imageURL = "";
                }

                // emit the change event
                this.isUploading = false;

                this.imageChanged.emit({
                    imageURL: this.imageURL,
                    type: "user"
                });

            }, error => { this.isUploading = false; });
        }
        else if (this.imageType.toLowerCase() == "workgroup") {

            if (this.id.length > 0) {
                

                this.pinnacleService.updateWorkgroupImage(this.id, file).subscribe(res => {


                    if (res != "") {
                        this.imageURL = res.toString();
                        this.wgimgurl = this.sanitizer.bypassSecurityTrustUrl(this.imageURL);
                    }
                    else {
                        this.imageURL = this.wgDefault;
                        this.wgimgurl = this.sanitizer.bypassSecurityTrustUrl(this.imageURL);
                    }

                    // emit the change event
                    this.isUploading = false;

                    this.imageChanged.emit({
                        imageURL: this.imageURL,
                        type: "workgroup"
                    });

                }, error => {
                    this.isUploading = false;
                    console.log(error);
                });
            }
            else {


                this.fileData = file;
                var v_URL = "";
                if (file) {
                    
                    v_URL = URL.createObjectURL(file);
                    
                }
                else {
                    
                    v_URL = this.wgDefault;
                }
                 
                this.imageURL = v_URL;



                this.wgimgurl = this.sanitizer.bypassSecurityTrustUrl(this.imageURL);
                


                this.isUploading = false;
                

                this.imageChanged.emit({
                    fileData: this.fileData,
                    imageURL: this.imageURL
                });
            }
        }
        else if (this.imageType.toLowerCase() == "logo") {
            this.pinnacleService.updateCurrentTenantLogo(file, filename, this.level).subscribe(res => {
                if (res != "") {
                    this.imageURL = this.getUniqueImageSource(res.toString());
                }
                else {
                    if (this.partnerSettings.find(v => v.name == "PARTNER_LOGO")) {
                        this.imageURL = this.partnerSettings.find(v => v.name == "PARTNER_LOGO").settingValue.toString();
                    }
                    else {
                        this.imageURL = "";
                    }
                  //  document.getElementById("displayimage").style.display = "none";
                }

                if (this.level == "company") {
                    localStorage.setItem(ProdGenApi.getCurrentTenantId() + "_company_logo", this.getUniqueImageSource(this.imageURL));
                }
                else if (this.level == "partner") {
                    localStorage.setItem(ProdGenApi.getCurrentTenantId() + "_partner_logo", this.getUniqueImageSource(this.imageURL));
                }
                else if (this.level == "lite") {
                    localStorage.setItem(ProdGenApi.getCurrentTenantId() + "_lite_logo", this.getUniqueImageSource(this.imageURL));

                }

                // emit the change event
                this.isUploading = false;

                this.imageChanged.emit({
                    imageURL: this.imageURL,
                    type: "logo"
                });

            }, error => {
                this.isUploading = false;
                console.log(error);
            });

        }
        else if (this.imageType.toLowerCase() == "news") {

            this.pinnacleService.updateCurrentTenantNewsImage(file, filename, this.level).subscribe(res => {

                if (res != "") {
                    this.imageURL = this.getUniqueImageSource(res.toString());
                }
                else {
                    this.imageURL = "";
                   // document.getElementById("displayimage").style.display = "none";
                }

                if (this.level == "company") {
                    localStorage.setItem(ProdGenApi.getCurrentTenantId() + "_company_news_image", this.getUniqueImageSource(this.imageURL));
                }
                else if (this.level == "partner") {
                    localStorage.setItem(ProdGenApi.getCurrentTenantId() + "_partner_news_image", this.getUniqueImageSource(this.imageURL));
                }
                else if (this.level == "lite") {
                    localStorage.setItem(ProdGenApi.getCurrentTenantId() + "_lite_news_image", this.getUniqueImageSource(this.imageURL));
                }

                // emit the change event
                this.isUploading = false;

                this.imageChanged.emit({
                    imageURL: this.imageURL,
                    type: "news"
                });

            }, error => {
                this.isUploading = false;
                console.log(error);
            });
        }
        else if (this.imageType.toLowerCase() == "docs") { 
            //
            //inside of here we need to save the image locally. once we actually save the theme and its created, we then need to go back and upload the images and update the theme
            //
            this.pinnacleService.updateCurrentDocumentImage(file, filename, this.level).subscribe(res => {
                if (res != "") {
                    this.imageURL = this.getUniqueImageSource(res.toString());
                }
                else {
                    this.imageURL = "assets/images/default_cheatsheet.jpg";
                }

                // emit the change event
                this.isUploading = false;

                this.imageChanged.emit({
                    imageURL: this.imageURL,
                    type: "docs"
                });

            }, error => {
                this.isUploading = false;
                console.log(error);
            });
        }
        else if (this.imageType.toLowerCase() == "mydocs") {
            //
            //inside of here we need to save the image locally. once we actually save the theme and its created, we then need to go back and upload the images and update the theme
            //
            this.pinnacleService.updateCurrentMyDocumentImage(file, filename, this.level).subscribe(res => {
                if (res != "") {
                    this.imageURL = this.getUniqueImageSource(res.toString());
                }
                else {
                    this.imageURL = "assets/images/My Cheat Sheets Graphic.png";
                }

                // emit the change event
                this.isUploading = false;

                this.imageChanged.emit({
                    imageURL: this.imageURL,
                    type: "mydocs"
                });

            }, error => {
                this.isUploading = false;
                console.log(error);
            });
        }
        else if (this.imageType.toLowerCase() == "vids") {
            this.pinnacleService.updateCurrentVideoImage(file, filename, this.level).subscribe(res => {

                if (res != "") {
                    this.imageURL = this.getUniqueImageSource(res.toString());
                }
                else {
                    this.imageURL = "assets/images/default_video.jpg";
                }

                // emit the change event
                this.isUploading = false;

                this.imageChanged.emit({
                    imageURL: this.imageURL,
                    type: "vids"
                });

            }, error => {
                this.isUploading = false;
                console.log(error);
            });
        }
        else if (this.imageType.toLowerCase() == "lps") {
            this.pinnacleService.updateCurrentLearningPathImage(file, filename, this.level).subscribe(res => {

                if (res != "") {
                    this.imageURL = this.getUniqueImageSource(res.toString());
                }
                else {
                    this.imageURL = "assets/images/default_learningpath.jpg";
                }

                // emit the change event
                this.isUploading = false;

                this.imageChanged.emit({
                    imageURL: this.imageURL,
                    type: "lps"
                });

            }, error => {
                this.isUploading = false;
                console.log(error);
            });
        }
        else if (this.imageType.toLowerCase() == "course") {
            this.pinnacleService.updateCurrentCourseImage(file, filename, this.level).subscribe(res => {

                if (res != "") {
                    this.imageURL = this.getUniqueImageSource(res.toString());
                }
                else {
                    this.imageURL = "assets/images/default_course.jpg";
                }

                // emit the change event
                this.isUploading = false;

                this.imageChanged.emit({
                    imageURL: this.imageURL,
                    type: "course"
                });

            }, error => {
                this.isUploading = false;
                console.log(error);
            });
        }
        else if (this.imageType.toLowerCase() == "quiz") {
            this.pinnacleService.updateCurrentQuizImage(file, filename, this.level).subscribe(res => {

                if (res != "") {
                    this.imageURL = this.getUniqueImageSource(res.toString());
                }
                else {
                    this.imageURL = "assets/images/default_quiz.jpg";
                }

                // emit the change event
                this.isUploading = false;

                this.imageChanged.emit({
                    imageURL: this.imageURL,
                    type: "quiz"
                });

            }, error => {
                this.isUploading = false;
                console.log(error);
            });
        }
        else if (this.imageType.toLowerCase() == "wfs") {
            this.pinnacleService.updateCurrentWorkflowImage(file, filename, this.level).subscribe(res => {

                if (res != "") {
                    this.imageURL = this.getUniqueImageSource(res.toString());
                }
                else {
                    this.imageURL = "assets/images/default_workflow.jpg";
                }

                // emit the change event
                this.isUploading = false;

                this.imageChanged.emit({
                    imageURL: this.imageURL,
                    type: "wfs"
                });

            }, error => {
                this.isUploading = false;
                console.log(error);
            });
        }
        else if (this.imageType.toLowerCase() == "process") {
            this.pinnacleService.updateCurrentProcessImage(file, filename, this.level).subscribe(res => {

                if (res != "") {
                    this.imageURL = this.getUniqueImageSource(res.toString());
                }
                else {
                    this.imageURL = "assets/images/default_process.jpg";
                }

                // emit the change event
                this.isUploading = false;

                this.imageChanged.emit({
                    imageURL: this.imageURL,
                    type: "process"
                });

            }, error => {
                this.isUploading = false;
                console.log(error);
            });
        }
        else if (this.imageType.toLowerCase() == "defworkgroup") {
            this.pinnacleService.updateCurrentdefWorkgroupImage(file, filename, this.level).subscribe(res => {
                if (res != "") {
                    this.imageURL = this.getUniqueImageSource(res.toString());
                }
                else {
                    this.imageURL = "assets/images/default_project.jpg";
                }

                // emit the change event
                this.isUploading = false;

                this.imageChanged.emit({
                    imageURL: this.imageURL,
                    type: "defworkgroup"
                });

            }, error => {
                this.isUploading = false;
                console.log(error);
            });
        }
        else if (this.imageType.toLowerCase() == "widgetprop") {

            this.pinnacleService.updateWidgetPropertyImage(this.id, file).subscribe(res => {
                //console.log("uploaded hit! ");
                //console.log(this.id);
                if (res != "") {
                    this.imageURL = this.getUniqueImageSource(res.toString());
                }
                else {
                    this.imageURL = "assets/images/default_project.jpg";
                }

                // emit the change event
            this.isUploading = false;

            this.imageChanged.emit({imageURL: this.imageURL,
                                    type: "widgetprop"
                });

            }, error => {
                this.isUploading = false;
                console.log(error);
            });
        }
        else if (this.imageType.toLowerCase() == "enrollment") {
            this.pinnacleService.updateCurrentEnrollmentImage(file, filename, this.level).subscribe(res => {

                if (res != "") {
                    this.imageURL = this.getUniqueImageSource(res.toString());
                }
                else {
                    this.imageURL = "assets/images/default_course.jpg";
                }

                // emit the change event
                this.isUploading = false;

                this.imageChanged.emit({
                    imageURL: this.imageURL,
                    type: "enrollment"
                });

            }, error => {
                this.isUploading = false;
                console.log(error);
            });
        }

        this.getDisplayImage();
    }

}
