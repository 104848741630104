import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ProdGenApi } from './../apiService/prodgen.api';
import { TranslationService } from './../services/TranslationService';
import { SettingType_T } from './../apiService/classFiles/class.users';

import { SharedService } from '../apiService/loginservice.service';

@Component({
  selector: 'app-loginuitility',
  templateUrl: './loginuitility.component.html',
  styleUrls: ['./loginuitility.component.css']
})
export class LoginuitilityComponent implements OnInit {

  userPreferredLanguage: string = "";

    constructor(private pinnacleService: ProdGenApi, private router: Router, private tranService: TranslationService, private route: ActivatedRoute, private sharedService: SharedService) { }

    ngOnInit() {
        this.userPreferredLanguage = localStorage.getItem("selectedLanguage");
        
        if (this.userPreferredLanguage == undefined || this.userPreferredLanguage == "") {
            this.userPreferredLanguage = "en";
        }
        
    this.loadTransitionFile();
  }

  loadTransitionFile() {
 
      if ((window.location.href.startsWith("http://localhost") || window.location.href.startsWith("https://prodgenbrowser") || window.location.href.startsWith("https://portalbeta")) && this.userPreferredLanguage == "en") {
          
          this.tranService.loadTranslationFileLocal().subscribe(dataFile => {
        
            this.tranService.loadTranslationFileDataFromVariable(dataFile);
            this.onTranslationLoaded(false);

          },
          err => {
              console.log("Error");
          console.log(err);
        });
      }
      else {
        
      this.pinnacleService.getTranslatedFileFromLanguageCode(this.userPreferredLanguage).subscribe(dataFile => {
        
        this.tranService.loadTranslationFileDataFromVariable(dataFile);
        this.onTranslationLoaded(false);

      },
          fileError => {
         
          this.tranService.loadTranslationFileLocal();
          this.onTranslationLoaded(false);
        });
    }
  }

  onTranslationLoaded(emitChange: boolean) {


      //console.log("Translation loaded");
      /*
    this.pinnacleService.saveCurrentUserSetting("LanguagePreference", this.userPreferredLanguage, SettingType_T.string).subscribe();
      localStorage.setItem("selectedLanguage", this.userPreferredLanguage);

      this.pinnacleService.GetCurrentTenant().subscribe(t => { ProdGenApi.setSessionTenant(t); });
      */
    //this.pinnacleService.GetCurrentOrganization().subscribe(o => { ProdGenApi.setSessionOrganization(o); });
    
      this.sharedService.emitChange("");

    this.routeAfterLogin();
  }

    routeAfterLogin() {

        //console.log("routing");

      let v_URL = localStorage.getItem("LoginURL");

    if (v_URL == null || v_URL == "") {
      this.router.navigate(['/home']);
    }
    else {
        window.location.href = v_URL;

        // CJR - we should not be doing reloads. If this was needed, we need to figure out why and come up with a better solution
        // window.location.reload();

        localStorage.setItem("LoginURL", "");
    }

  }

}
