import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { BrowserAuthenticationService } from './../BrowserAuthenticationService';
import { ProdGenApi } from './../apiService/prodgen.api';
import { AppComponent } from '../app.component';


declare var $: any;

@Component({
  selector: 'pinnacle-lite',
  templateUrl: './partner.component.html',
    styleUrls: ['./partner.component.css'],
  providers: [ProdGenApi],
  encapsulation: ViewEncapsulation.None,
})
export class PartnerPageComponent implements OnInit {
    isAuthenticated: boolean = false;
    allCustomers: Array<PartnerUsers> = new Array<PartnerUsers>();
    filteredCustomers: Array<PartnerUsers> = new Array<PartnerUsers>();
    curDate: Date = new Date(Date.now());
    filterText: string = "";
    sortNameDir: number = 1;
    sortIDDir: number = 1;
    sortAltDir: number = 1;
    sortExpDir: number = 1;
    sortLiteDir: number = 1;
    selCustomer: PartnerUsers = new PartnerUsers();
    canSellPinnacleLite = false;
    canSellPinnacleLiteLoaded = false;
        
  
    constructor(private pinnacleService: ProdGenApi, private router: Router, private authenticationService: BrowserAuthenticationService) {

        if (authenticationService.AuthenticatePage() == true) {
          this.isAuthenticated = true;
        }

    } 

    ngOnInit() {
        window.scroll(0, 0);

        this.curDate = new Date(Date.now());
        this.loadData();

    }

    loadData() {
        this.allCustomers = new Array<PartnerUsers>();
        this.filteredCustomers = new Array<PartnerUsers>();
        let oldestDate = new Date();
        oldestDate.setFullYear(oldestDate.getFullYear() - 1);

        this.pinnacleService.GetCanPartnerSellPinnacleLite().subscribe(res => {
            this.canSellPinnacleLite = res;
            this.canSellPinnacleLiteLoaded = true;

            if (this.canSellPinnacleLite == true) {
                this.pinnacleService.getPartnerTenants().subscribe(res => {
                    for (let account of res) {
                        let cust = new PartnerUsers();
                        cust.name = account.name as string;
                        cust.orgId = account.organizationId.toString();
                        cust.altId = account.altOrganizationId;
                        cust.expiration = new Date(account.expiration);
                        cust.isPinnacleLite = account.isPinnacleLite;
                        cust.tenantId = account.tenantId as string;

                        if (cust.orgId == null) {
                            cust.orgId = "";
                        }
                        if (cust.name == null) {
                            cust.name = "";
                        }

                        if (cust.expiration == null) {
                            cust.expiration = new Date();
                        }

                        if (cust.altId == null) {
                            cust.altId = "";
                        }

                        if (cust.expiration > oldestDate) {
                            this.allCustomers.push(cust);
                        }
                    }

                    this.headerClick("NAME");
                });

            }
        });

    }

    get isPinnacleLite() {
        return AppComponent.isPinnacleLite;
    }

    // the filter text has changed. We update our filter items array checking if the filter text is used in each member. 
    // if the filter is empty, we just make a copy of the master list.
    filterTextChanged() {
        this.filteredCustomers.length = 0;
        if (this.filterText.length == 0) {
            this.filteredCustomers = this.filteredCustomers.concat(this.allCustomers);
            return;
        }

        for (let c of this.allCustomers) {
            let filter = this.filterText.toLowerCase();
            if ((c.altId != null && c.altId.toLowerCase().indexOf(filter) >= 0) ||
                (c.name != null && c.name.toLowerCase().indexOf(filter) >= 0) ||
                (c.orgId != null && c.orgId.toLowerCase().indexOf(filter) >= 0) ||
                (c.expiration != null && c.expiration.getMonth().toString().toLowerCase().indexOf(filter) >= 0) ||
                (c.expiration != null && c.expiration.getDate().toString().toLowerCase().indexOf(filter) >= 0) ||
                (c.expiration != null && c.expiration.getFullYear().toString().toLowerCase().indexOf(filter) >= 0) 
            )
            {
                this.filteredCustomers.push(c);
            }
        }
    }

    // Customer Header has been clicked. We need to sort the items. We keep track if we are sorting ascending or descending for each column
    headerClick(header: string) {
        if (header == "NAME") {
            this.sortAltDir = 1;
            this.sortExpDir = 1;
            this.sortIDDir = 1;
            this.sortLiteDir = 1;

            this.allCustomers = this.allCustomers.sort((r1, r2) => {
                if (r1.name > r2.name) {
                    return 1*this.sortNameDir;
                }
                else if (r1.name < r2.name) {
                    return -1 * this.sortNameDir;
                }
                return 0;
            });
            this.sortNameDir *= -1;

        }
        else if (header == "ALT") {
            this.sortExpDir = 1;
            this.sortIDDir = 1;
            this.sortNameDir = 1;
            this.sortLiteDir = 1;

            this.allCustomers = this.allCustomers.sort((r1, r2) => {
                if (r1.altId > r2.altId) {
                    return 1 * this.sortAltDir;
                }
                else if (r1.altId < r2.altId) {
                    return -1 * this.sortAltDir;
                }
                return 0;
            });

            this.sortAltDir *= -1;

        }
        else if (header == "PINNACLE_LITE") {
            this.sortAltDir = 1;
            this.sortExpDir = 1;
            this.sortIDDir = 1;
            this.sortNameDir = 1;

            this.allCustomers = this.allCustomers.sort((r1, r2) => {
                if (r1.isPinnacleLite < r2.isPinnacleLite) {
                    return 1 * this.sortLiteDir;
                }
                else if (r1.isPinnacleLite > r2.isPinnacleLite ) {
                    return -1 * this.sortLiteDir;
                }
                return 0;
            });

            this.sortLiteDir *= -1;
        }
        else if (header == "EXP") {
            this.sortAltDir = 1;
            this.sortIDDir = 1;
            this.sortNameDir = 1;
            this.sortLiteDir = 1;

            this.allCustomers = this.allCustomers.sort((r1, r2) => {
                if (r1.expiration < r2.expiration) {
                    return 1 * this.sortExpDir;
                }
                else if (r1.expiration> r2.expiration) {
                    return -1 * this.sortExpDir;
                }
                return 0;
            });


            this.sortExpDir *= -1;
        }

        else if (header == "ID") {
            this.sortAltDir = 1;
            this.sortExpDir = 1;
            this.sortNameDir = 1;
            this.sortLiteDir = 1;

            this.allCustomers = this.allCustomers.sort((r1, r2) => {
                if (parseInt(r1.orgId) > parseInt(r2.orgId)) {
                    return 1 * this.sortIDDir;
                }
                else if (parseInt(r1.orgId) < parseInt(r2.orgId) ) {
                    return -1 * this.sortIDDir;
                }
                return 0;
            });


            this.sortIDDir *= -1;
        }

        this.filterTextChanged();
    }

    // the customer has been clicked. We go into modify mode allowing them to add subscriptions, update the expiration, etc.
    onCustomerClick(customer: PartnerUsers) {
        this.selCustomer = customer;
    }

    onAccountAdded(event: any) {
        this.sortNameDir *= -1;
        this.loadData();
    }

    onAccountChanged(event: any) {
        this.sortNameDir *= -1;
        this.loadData();
    }
}

export class PartnerUsers {
    orgId: string;
    tenantId: string;
    altId: string;
    name:  string;
    users: number;
    expiration: Date;
    isPinnacleLite: boolean;
}
