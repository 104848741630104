// Angular imports
import { AfterViewInit, Component, OnInit, ViewChild, HostListener, Input, ElementRef, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router, Route, Params } from '@angular/router';
import { DatePipe } from '@angular/common';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { PinnacleDateTimePipe } from '../../app/pipes/pinnacleDateTime';


// DevExpress imports
import { DxSchedulerModule, DxSchedulerComponent } from 'devextreme-angular';
import DevExpress from 'devextreme/bundles/dx.all';

// Pinnacle imports
import { ProdGenApi } from '../apiService/prodgen.api';
import { BrowserAuthenticationService } from '../BrowserAuthenticationService';
import { TranslationService } from '../services/TranslationService';
import { LiveEventWrapper, LiveEventBrowserCalendarStatus, LiveEventProperty, LiveEventPropertyType } from '../../app/apiService/classFiles/class.liveevents';
import { LiveEventRegistrationForm } from '../templates/liveeventregistrationform/liveeventregistrationform.component';
import { UpcomingEventsPanelComponent } from '../templates/upcomingeventspanel/upcomingeventspanel.component';
import { ContentPrerequisite } from '../apiService/classFiles/class.content';
import { Observable } from 'rxjs';

// JQuery workaround
declare var $: any;

@Component({
    selector: 'app-liveeventcalendar',
    templateUrl: 'liveeventcalendar.component.html',
    styleUrls: ['liveeventcalendar.component.css'],
    providers: [ProdGenApi]
})

export class LiveEventCalendarComponent implements OnInit, AfterViewInit {

    schedulerHeight: string = "485px";
    upcomingEventsHeight: string = "485px";
    showComponent: boolean = false;
    isAuthenticated: boolean = false;
    scrollbarWidth: number = 0;
    currentDate: Date = new Date();
    datepipe: DatePipe;
    liveEventsLoaded: boolean = false;
    liveEventsWrapped: boolean = false;
    liveEventWrappers: Array<LiveEventWrapper>;
    selectedEvent: LiveEventWrapper = new LiveEventWrapper(
        "00000000-0000-0000-0000-000000000000",         // Event ID
        "",                                         // Event name
        "",                                         // Simple description
        "",                                         // Formatted description
        "",                                         // Keywords
        new Date(1970, 0, 1, 0, 0, 0, 0),           // Previous recording date
        "00000000-0000-0000-0000-000000000000",         // Previous recording video ID
        "00000000-0000-0000-0000-000000000000",         // Folder ID
        false,                                      // Is deleted
        "00000000-0000-0000-0000-000000000000",         // Provided by
        0,                                          // Duration (mins)
        new Array<ContentPrerequisite>(),           // Prerequisites
        new Array<LiveEventProperty>(),             // Properties
        "00000000-0000-0000-0000-000000000000",         // Session ID
        "00000000-0000-0000-0000-000000000000",         // Presenter ID
        "",                                         // Presenter display name
        "00000000-0000-0000-0000-000000000000",         // Scheduled by
        new Date(1970, 0, 1, 0, 0, 0, 0),           // Session datetime (unused)
        "",                                         // Session URL
        "",                                         // Session location
        LiveEventBrowserCalendarStatus.Internal,    // Session status
        new Date(1970, 0, 1, 0, 0, 0, 0),           // Session start datetime
        new Date(1970, 0, 1, 0, 0, 0, 0),           // Session end datetime
        false,                                      // All day session (unused)
        false,                                      // Use registration dates
        new Date(1970, 0, 1, 0, 0, 0, 0),           // Registration start date
        new Date(1970, 0, 1, 0, 0, 0, 0),           // Registration end date
        "00000000-0000-0000-0000-000000000000",
        false                                       // Session attended
    );
    eventsLoaded: boolean = false;

    @ViewChild(DxSchedulerComponent, { static: false }) scheduler: DxSchedulerComponent;
    @ViewChild(UpcomingEventsPanelComponent, { static: false }) upcomingEvents: UpcomingEventsPanelComponent;
    @ViewChild("schedulerContainer", { static: false }) schedulerContainer: ElementRef;

    @Input('panelFillType') panelFillType: string = "home"; // home, learningcenter, widget

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.centerLoadingSpinnerInCalendar();
        this.updateCalendar();
    }

    calendarColors: Array<LiveEventColor> = new Array<LiveEventColor>();
    legendColors: { [sessionStatus: string]: string } = {
        "Registered": "#9bd685",
        "Invited": "#d6c66b",
        "Required": "#eb8775",
        "External": "#6ebcdb",
        "Internal": "#298acf",
        "Waitlisted": "#9bd685"
    }

    constructor(private pinnacleService: ProdGenApi,
                private route: ActivatedRoute,
                private router: Router,
                private authenticationService: BrowserAuthenticationService,
                private translationService: TranslationService,
                private sanitizer: DomSanitizer,
                private changedetector : ChangeDetectorRef) {

        this.datepipe = new DatePipe("en-US");
        this.initializeCalendarColors();

    }

    ngOnInit(): void {
        if (this.authenticationService.AuthenticatePage()) {
            this.isAuthenticated = true;
            this.currentDate = new Date(Date.now());
            this.liveEventWrappers = [];
            if (this.panelFillType == "widget" || localStorage.getItem("showLiveEventCalendarComponent") == "true") {
                this.showComponent = true;
            }
            this.loadLiveEvents();
        }
    }

    ngAfterViewInit(): void {
        if (this.panelFillType == "widget" || localStorage.getItem("showLiveEventCalendarComponent") == "true") {
            (this.schedulerContainer.nativeElement as HTMLDivElement).style.display = "block";
        }
        this.getScrollbarWidth();
        this.centerLoadingSpinnerInCalendar();
    }

    loadLiveEvents(): void {
        if (this.showComponent) {
            if (this.scheduler != undefined && this.scheduler.currentDate != undefined)
                this.currentDate = new Date(this.scheduler.currentDate as string);

            this.eventsLoaded = false;
            if (this.upcomingEvents) {
                this.upcomingEvents.eventsLoaded = false;
            }
            this.centerLoadingSpinnerInCalendar();

            this.liveEventsLoaded = true;
            let v_propertyTypeWhiteList = [LiveEventPropertyType.EventSessionProperty];

            let startDate = new Date(
                this.currentDate.getFullYear(),
                this.currentDate.getMonth() - 2,
                this.currentDate.getDate(),
                0,
                0,
                0,
                0);
            let endDate = new Date(
                this.currentDate.getFullYear(),
                this.currentDate.getMonth() + 2,
                this.currentDate.getDate(),
                0,
                0,
                0,
                0);

            if (this.upcomingEvents) {
                this.upcomingEvents.dateRangeString = PinnacleDateTimePipe.transform(startDate, [{ shortRange: endDate }]);
            }

            let keepSessions = new Array<LiveEventWrapper>();
            let keepSessionsIDs = new Array<string>();
            for (let i = 0; i < this.liveEventWrappers.length; i++) {
                if ((this.liveEventWrappers[i].startDate >= startDate && this.liveEventWrappers[i].startDate <= endDate)
                    || (this.liveEventWrappers[i].endDate >= startDate && this.liveEventWrappers[i].endDate <= endDate)) {

                    keepSessions.push(this.liveEventWrappers[i]);
                    keepSessionsIDs.push(this.liveEventWrappers[i].sessionID);
                }
            }
            this.liveEventWrappers = keepSessions;
            this.pinnacleService.leGetLiveEventSessionWrappersInRange(startDate, endDate, keepSessionsIDs, v_propertyTypeWhiteList).subscribe(res => {
                for (let i: number = 0; i < res.length; i++) {
                    let w: LiveEventWrapper = res[i];

                    if (w.name != "SESSION_RESTRICTED" && this.sessionShouldBeAdded(w)) {
                        w.startDate = PinnacleDateTimePipe.toLocalDateTime(new Date(w.startDate));
                        w.endDate = PinnacleDateTimePipe.toLocalDateTime(new Date(w.endDate));
                        w.registrationStartDate = PinnacleDateTimePipe.toLocalDateTime(new Date(w.registrationStartDate));
                        w.registrationEndDate = PinnacleDateTimePipe.toLocalDateTime(new Date(w.registrationEndDate));
                        this.liveEventWrappers.push(w);
                    }
                }

                this.liveEventWrappers.sort((a, b) => {
                    if (a.startDate == b.startDate)
                        return a.endDate.getTime() - b.endDate.getTime();
                    return a.startDate.getTime() - b.startDate.getTime();
                });

                //this.liveEventsWrapped = true;
                this.eventsLoaded = true;
                this.upcomingEvents.allLiveEvents = this.liveEventWrappers;
                this.upcomingEvents.filterEvents();
                if (this.upcomingEvents) {
                    this.upcomingEvents.eventsLoaded = true;
                }
            }, e => { console.log(e);});
        }
    }

    sessionShouldBeAdded(session: LiveEventWrapper): boolean {
        if (this.liveEventWrappers != null) {
            for (let i = 0; i < this.liveEventWrappers.length; i++) {
                if (this.liveEventWrappers[i].sessionID == session.sessionID)
                    return false;
            }
            return true;
        }
        return false;
    }

    updateCalendar(): void {
        this.scheduler.dataSource = null;
        this.scheduler.dataSource = this.liveEventWrappers;
    }

    centerLoadingSpinnerInCalendar(): void {
        if (!this.eventsLoaded) {
            try {
                let calendarElement = document.getElementById("dxschLiveEventCalendar");
                if (calendarElement == null) return; // It's possible we're here before the calendar. It's okay to let this fail the first time.
                let rect = calendarElement.getBoundingClientRect();
                $(".imgEventsLoading").css({ "left": ((rect.width / 2) - 25) + "px", "top": ((rect.height / 2) + 54) + "px" });
            }
            catch (e) { console.log(e); }
        }
    }

    initializeCalendarColors(): void {
        this.calendarColors.push(new LiveEventColor("Registered", this.legendColors["Registered"], 0));
        this.calendarColors.push(new LiveEventColor("Invited", this.legendColors["Invited"], 1));
        this.calendarColors.push(new LiveEventColor("Required", this.legendColors["Required"], 2));
        this.calendarColors.push(new LiveEventColor("External", this.legendColors["External"], 3));
        this.calendarColors.push(new LiveEventColor("Internal", this.legendColors["Internal"], 4));
        this.calendarColors.push(new LiveEventColor("Waitlisted", this.legendColors["Waitlisted"], 5));
    }

    setSelectedSession(event): void {
        if (event.appointmentData != undefined) {
            this.selectedEvent = event.appointmentData;
        }
        else {
            this.selectedEvent = event;
        }
        event.cancel = true;
        this.scheduler.instance.hideAppointmentTooltip();
        this.upcomingEvents.setSelectedSession(this.selectedEvent);
        this.upcomingEvents.selectedEventChange.subscribe(event => this.updateSelectedSession(event));
    }

    updateSelectedSession(event): void {
        let newEvent: LiveEventWrapper = event;
        newEvent.startDate = new Date(newEvent.startDate);
        newEvent.endDate = new Date(newEvent.endDate);
        let prevEvent: number = this.liveEventWrappers.findIndex(x => x.sessionID == newEvent.sessionID);
        this.liveEventWrappers[prevEvent] = newEvent;
        this.selectedEvent = newEvent;
    }

    getScrollbarWidth(): void {
        // Hotfix for calendar scroll event in Chrome
        this.scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
    }

    cancelDoubleClick(event): void {
        event.cancel = true;
    }

    getLiveEventStatusColorString(liveEvent: LiveEventWrapper): string {
        return "liveEvent" + LiveEventBrowserCalendarStatus[liveEvent.sessionStatus] + "Color";
    }

    setShowComponent(value: boolean): void {
        if (value) {
            (this.schedulerContainer.nativeElement as HTMLDivElement).style.display = "block";
            //$(".liveEventCalendarComponent").css("display", "block");
            this.showComponent = true;
        }
        else {
            (this.schedulerContainer.nativeElement as HTMLDivElement).style.display = "none";
            //$(".liveEventCalendarComponent").css("display", "none");
            this.showComponent = false;
        }
        localStorage.setItem("showLiveEventCalendarComponent", value ? "true" : "false");
    }

    setHeights(calendarHeight: string, upcomingEventsHeight: string): void {
        this.schedulerHeight = calendarHeight;
        this.upcomingEventsHeight = upcomingEventsHeight;
        this.changedetector.detectChanges();
    }
}

class LiveEventColor {
    sessionStatus: string;
    color: string;
    id: number;

    constructor(sessionStatus: string, color: string, id: number) {
        this.sessionStatus = sessionStatus;
        this.color = color;
        this.id = id;
    }
}

var scrollbarWidth: number = 0;

$(document).ready(function () {
    scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
});

//$(document).ready(function () {
//    $("#dxschLiveEventCalendar").mouseenter(function () {
//        if ($("#registrationForm").hasClass("in") == false) {
//            $("body").css({
//                "overflow": "hidden",
//                "padding-right": scrollbarWidth + "px"
//            });
//            $("#btnSupportMain").css({
//                "margin-right": scrollbarWidth + "px"
//            });
//        }
//    });
//});

//$(document).ready(function () {
//    $("#dxschLiveEventCalendar").mouseleave(function () {
//        if ($("#registrationForm").hasClass("in") == false) {
//            $("body").css({
//                "overflow": "auto",
//                "padding-right": "0px"
//            });
//            $("#btnSupportMain").css({
//                "margin-right": "0px"
//            });
//        }
//    });
//});

//$(document).ready(function () {
//    $("body").on("hidden.bs.modal", function () {
//        $(this).removeClass("registrationFormScrollLock");
//    });
//});

//$(document).ready(function () {
//    $("body").on("show.bs.modal", function () {
//        $(this).addClass("registrationFormScrollLock");
//    });
//});