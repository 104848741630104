import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { IDatePickerConfig } from 'ng2-date-picker';
import { AppComponent } from '../../../app.component';
import { Apiv2Service } from './../../../apiService/apiv2.service';
import { Content, ContentAuthoring, ContentPrerequisite, ContentPrerequisiteInfo, ContentType_T, CourseWithContent, LearningPath } from './../../../apiService/classFiles/class.content';
import { AssignTrainingForAssessmentCompletionRequest, Enrollment } from './../../../apiService/classFiles/class.enrollments';
import { PermissionLevel_T, Workgroup } from './../../../apiService/classFiles/class.workgroups';
import { AssignAssessmentRequest, KnowledgeSmartConfiguration, KSMappedAssessment, KSUser } from './../../../apiService/classFiles/v2-organizations';
import { ProdGenApi } from './../../../apiService/prodgen.api';
import { TranslationService } from './../../../services/TranslationService';
declare var $: any;


@Component({
    selector: 'template-seriesheader',
    templateUrl: './seriesheader.component.html',
    styleUrls: ['./seriesheader.component.css']
})
export class SeriesheaderComponent implements OnInit {
    editorWorkgroups: Array<Workgroup> = new Array<Workgroup>();
    @Input('learningPath') learningPath: LearningPath;
    @Input('courses') courses: Array<CourseWithContent> = new Array<CourseWithContent>();
    @Input('currentEnrollments') currentEnrollments: Array<Enrollment> = new Array<Enrollment>();
    @Input('completedEnrollments') completedEnrollments: Array<Enrollment> = new Array<Enrollment>();
    @Input('usedProducts') usedProducts: Array<string> = new Array<string>();
    @Input('contentAuthoring') itemAuthoring: ContentAuthoring;
    currentDate: Date = new Date(Date.now() + (30 * 1000 * 60 * 60 * 24));
    learningPathContentItem: Content;
    selectedDate: string;
    showdetailcontrols: boolean = false;
    lpid: string = "";
    contentPrereqInfo: ContentPrerequisiteInfo = new ContentPrerequisiteInfo();
    prereqCourses: Array<ContentPrerequisite> = new Array<ContentPrerequisite>();
    prereqLearningPaths: Array<ContentPrerequisite> = new Array<ContentPrerequisite>();
    lpCoursesWithPrereqs: Array<String> = new Array<String>();
    invokeMe: boolean = true;
    preReqNotice: boolean = false;
    loading: boolean = true;
    notAll: boolean = false;

    messageBoxTitle: string = "";
    messageBoxBody: string = "";
    messageBoxActionText: string = "";
    messageBoxNegativeText: string = "";
    messageBoxCancelText: string = "";

    ksAssignmentId: number = -1;


    learningPathTiedToAssessment: boolean = false;
    enrollmentOptions: string = "enroll";
    expireDate: string;
    mappedAssessments: Array<KSMappedAssessment>;
    ksconfigs: KnowledgeSmartConfiguration = Apiv2Service.KnowlegeSmartConfigs;

    public datePickerConfig: IDatePickerConfig = {
        format: "MM/DD/YYYY"
    };

    constructor(private pinnacleService: ProdGenApi,private apiV2Service:Apiv2Service, private router: Router, private tranService: TranslationService) {
        //moved method to onInit
    }

    pad(num: number, size: number): string {
        let s = num + "";
        while (s.length < size) s = "0" + s;
        return s;
    }

    ngOnInit() {
        try {
            this.selectedDate = this.currentDate.getFullYear() + "-" + this.pad(this.currentDate.getMonth() + 1, 2) + "-" + this.pad(this.currentDate.getDate(), 2);
            this.expireDate = this.currentDate.getFullYear() + "-" + this.pad(this.currentDate.getMonth() + 1, 2) + "-" + this.pad(this.currentDate.getDate(), 2);
            this.pinnacleService.getCurrentUserWorkgroups([PermissionLevel_T.owner, PermissionLevel_T.editor]).subscribe(res => {
                this.editorWorkgroups = res;
                if (document.URL.includes("learningcenter/courseviewer?")) {
                    this.showdetailcontrols = false;
                }
                else {
                    this.showdetailcontrols = true;
                }
            });
            this.apiV2Service.getKSIntegrationConfigInfo().subscribe(res => {
                this.ksconfigs = res.knowledgeSmartConfiguration;

            }, err => {
                this.ksconfigs = new KnowledgeSmartConfiguration();
                this.ksconfigs.useKnowledgeSmartIntegration = false;
                this.ksconfigs.knowledgeSmartAPI_Key = "";
                this.ksconfigs.assessmentSelfEnroll = false;
            });
        } catch (err) { };
    }

    ngOnChanges(changes: SimpleChanges) {
        try {
            this.learningPath = changes['learningPath'].currentValue;
            var item = new Content();
            item.contentId = this.learningPath.learningPathId;
            item.contentType = ContentType_T.learningpath;
            item.name = this.learningPath.name;
            item.providedBy = this.learningPath.providedBy;
            this.learningPathContentItem = item;

            this.apiV2Service.getAssessmentsFromContent(this.learningPath.learningPathId.toString()).subscribe(res => {
                this.mappedAssessments = res.templateMappings;
                if (res.templateMappings.length > 0) {
                    this.learningPathTiedToAssessment = true;
                }
                else {
                    this.learningPathTiedToAssessment = false;
                }
            });
        } catch (err) { };
        try { this.courses = changes['courses'].currentValue } catch (err) { };
    }


    onEnroll() {
        let skip = false;

        for (var j = 0; j < this.courses.length; j++) {
            for (var i = 0; i < this.lpCoursesWithPrereqs.length; i++) {
                if (this.lpCoursesWithPrereqs[i] == this.courses[j].courseId) {
                    skip = true;
                    break;
                }
            }

            if (!skip) {
                this.pinnacleService.SetCourseEnrollment(this.courses[j].courseId, ($('#assignDueDateCheckbox').is(":checked")),
                    new Date(this.selectedDate)).subscribe(res => {
                        if (res.courseId != "00000000-0000-0000-0000-000000000000") {
                            this.currentEnrollments.push(res);
                        }
                    });
            }
            else {
                skip = false;
            }
        }

        this.notAll = this.lpCoursesWithPrereqs.length > 0;

        if (this.notAll) {
            this.messageBoxTitle = this.tranService.getTranslationFileData("SERIESHEADER.NotAllCourseTitle");
            this.messageBoxBody = this.tranService.getTranslationFileData("SERIESHEADER.NotAllMsg");
            this.messageBoxActionText = "";
            this.messageBoxNegativeText = "";
            this.messageBoxCancelText = this.tranService.getTranslationFileData("SERIESHEADER.OK");
            $('#messageBox').modal('show');
        }
    }

    continueEnrollmentProgress() {


        if (!this.learningPathTiedToAssessment || (this.learningPathTiedToAssessment && this.enrollmentOptions == "enroll")) {

            this.onEnroll();
        }

        else if (this.learningPathTiedToAssessment && this.enrollmentOptions != "enroll") {

            $("#LPSpinnerModal").modal("show");
            let selectedKSAssessmentId = $("#selectedAssessments").val()


            let selectedMapAssessment = new KSMappedAssessment();
            selectedMapAssessment = this.mappedAssessments.find(x => x.ksAssessmentId == selectedKSAssessmentId)
            

            let templateMapList = new Array<KSMappedAssessment>();
            templateMapList.push(selectedMapAssessment);

            let requestBody = new AssignAssessmentRequest();
            requestBody.expiryDate = new Date(this.expireDate);
            requestBody.displayName = ProdGenApi.currentUser.name;
            requestBody.hasExpiryDate = $('#assignExpireDateCheckbox').is(":checked"); //It returns true is disabled. (That's why the '!' is there).
            requestBody.status = "";
            requestBody.testID = selectedMapAssessment.ksAssessmentId;
            requestBody.userEmail = ProdGenApi.currentUser.email;
            

            this.apiV2Service.assignAssessment(requestBody).subscribe(res => {
                
                let temp: number = res.assessmentId;
                this.ksAssignmentId = temp;
                
                let requestObj = new AssignTrainingForAssessmentCompletionRequest();

                requestObj.assessmentId = this.ksAssignmentId;
                requestObj.assessmentName = selectedMapAssessment.ksAssessmentName;
                requestObj.assignmentId = selectedMapAssessment.ksAssessmentId
                requestObj.bHasDueDate = false;
                requestObj.dueDate = new Date();
                requestObj.templateMappings = templateMapList;

                AppComponent.thisFromOutside.updateKSNavChild();

                this.pinnacleService.assignTrainingForAssessmentCompletion(requestObj).subscribe(res2 => {
                    
                    //this.showSpinner = false;
                    $("#assignedToAssessmentModal").modal("show");
                    $("#LPSpinnerModal").modal("hide");
                });

            });            
        }
    }



    assessmentRedirect() {

        let KSuserInfo: KSUser = null;

        this.apiV2Service.getAssessmentUserFromEmail(ProdGenApi.currentUser.email).subscribe(res => {

            KSuserInfo = res;
            if (KSuserInfo == null) {
                //modal?? sorry you can't?
                //something went wrong...
                return;
            }

            //get the SSO Login Token
            this.apiV2Service.requestLoginToken(KSuserInfo.userID).subscribe(login => {


                //let resultlinkURL: string = "https://online.knowledgesmart.net/trafficcop.aspx?" + "userid=" + KSuserInfo.userID +
                //    "&logintoken=" + login + "&destination=userarea";

                let resultlinkURL: string =  "https://online.knowledgesmart.net/trafficcop.aspx?" + "userid=" + KSuserInfo.userID +
                    "&logintoken=" + login + "&destination=taketest&inviteID=" + this.ksAssignmentId;

                window.open(resultlinkURL, '_blank').focus();

                this.router.navigate(['/home']);

            })

        });
    }


    isDatePickerDisabled(){
        if ($('#assignDueDateCheckbox').is(":checked")) {
            $('#datepicker').prop('disabled', false);
        }
        else {
            $('#datepicker').prop('disabled', true);
        }  
    }

    isExpireDatePickerDisabled(){
        if ($('#assignExpireDateCheckbox').is(":checked")) {
            $('#expiredatepicker').prop('disabled', false);
        }
        else {
            $('#expiredatepicker').prop('disabled', true);
        }  
    }


    typeCount(contentType: string): number {
        let count: number = 0;
        for (let c of this.courses) {
            for (let content of c.content) {
                if (content.contentType == ContentType_T[contentType]) {
                    count++;
                }
            }
        }

        return count;
    }

    getContentImage(): string {
        let image: string = "";

        image = "../../../../assets/images/";

        let img = ProdGenApi.getLargeDefaultImageFromType(ContentType_T.learningpath);

        if (img.indexOf("default") == -1) {
            image = img;
        }
        else {
            image += img;
        }


        return image;
    }

    getLearningPathName() {

        let v_Title = "";
        if (this.learningPath.name != null) {
            v_Title += this.learningPath.name;
            this.lpid = this.learningPath.learningPathId as string;
        }

        return v_Title;
    }

    checkPrereqs() {
        let p_ContentId = this.learningPathContentItem.contentId;
        let p_UserId = "";
        let emptyGuid = '00000000-0000-0000-0000-000000000000';

        this.pinnacleService.getCurrentUser().subscribe(u => {
            p_UserId = u.userId;

            this.pinnacleService.GetPrerequisitesForContentNotCompleted(p_ContentId, p_UserId, 0).subscribe(cpi => {
                this.contentPrereqInfo = cpi;

                if (this.contentPrereqInfo.prerequisites != null) {

                    if (this.contentPrereqInfo.prerequisites.length > 0) {

                        this.prereqCourses = new Array<ContentPrerequisite>();
                        this.prereqLearningPaths = new Array<ContentPrerequisite>();
                        this.lpCoursesWithPrereqs = new Array<String>();

                        for (let prs of this.contentPrereqInfo.prerequisites) {
                            if (prs.prereqType == 16 && prs.prereqLPID == emptyGuid)//LP
                            {
                                if (prs.contentType == '16') {
                                    this.prereqLearningPaths.push(prs);
                                }

                                if (prs.contentType == '17') {
                                    this.lpCoursesWithPrereqs.push(prs.contentID);
                                }
                            }

                            if (prs.prereqType == 17)//Crs
                            {
                                if (prs.contentType == '16') {
                                    this.prereqCourses.push(prs);
                                }

                                if (prs.contentType == '17') {
                                    this.lpCoursesWithPrereqs.push(prs.contentID);
                                }
                            }
                        }

                        if (this.prereqLearningPaths.length > 0 || this.prereqCourses.length > 0) {
                            this.preReqNotice = true;
                            this.invokeMe = false;
                        }

                        this.prereqCourses.sort(function (a, b) {
                            if (a.prereqName < b.prereqName) { return -1; }
                            if (a.prereqName > b.prereqName) { return 1; }
                            return 0;
                        });
                        this.prereqLearningPaths.sort(function (a, b) {
                            if (a.prereqName < b.prereqName) { return -1; }
                            if (a.prereqName > b.prereqName) { return 1; }
                            return 0;
                        });

                        //}
                    }
                }
                else {

                    this.preReqNotice = false;
                    this.invokeMe = true;
                }

                this.loading = false;
            });
        });
    }

    prClick(item: ContentPrerequisite) {
        let url: string = "";

        if (item.prereqType == 16)//LP
        {
            url = "learningcenter/series?learningPathId=" + item.prereqID;
        }
        else {
            url = "learningcenter/series?learningPathId=" + item.prereqLPID + "&courseId=" + item.prereqID;
        }

        this.router.navigateByUrl(url).then(i => {
            if (i == false) {
                console.log("unable to navigate");
            }
        });
    }

    onCancel() {
        $('#enrollCourseModalSyll').modal('hide');
        this.preReqNotice = false;
        this.invokeMe = false;
    }


    isLearningPathCompleted(): boolean {

        let foundMatch = false;
        for (let c of this.courses) {
            foundMatch = false;
            for (let e of this.completedEnrollments) {
                if (e.courseId == c.courseId) {
                    foundMatch = true;
                    break;
                }
            }
            if (!foundMatch) {
                return false;
            }
        }

        return true;
    }

    getLearningPathDate(): Date {
        let lpDate = new Date();
        lpDate.setFullYear(1915);
        
        for (let c of this.courses) {
            for (let e of this.completedEnrollments) {
                if (e.courseId == c.courseId) {
                    let eDate = new Date(e.statusDate);
                    let cDate = new Date(lpDate);
                    if (cDate.getTime() <= eDate.getTime()) {   
                        lpDate = e.statusDate;
                    }
                    break;
                }
            }
        }

        return lpDate;
    }



  
}
