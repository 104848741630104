import { Component, OnInit, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ProdGenApi } from './../../apiService/prodgen.api';
import { Enrollment, EnrollmentTrackingItem, ClassSession, ClassRegistrant} from '../../apiService/classFiles/class.enrollments';
import { ContentType_T, DescriptionFilter_T, CheatSheet, Content, ClassContentItem, ContentPrerequisite } from './../../apiService/classFiles/class.content';
import { LiveEventWrapper, LiveEventProperty, LiveEventBrowserCalendarStatus, LiveEventPropertyType } from '../../apiService/classFiles/class.liveevents';
import { LiveEventRegistrationForm } from '../liveeventregistrationform/liveeventregistrationform.component';

@Component({
  selector: 'app-trainingclasscontent',
  templateUrl: './trainingclasscontent.component.html',
  styleUrls: ['./trainingclasscontent.component.css']
})
export class TrainingClassContentComponent implements OnInit {
    @Input('enrollmentItem') enrollmentItem: EnrollmentTrackingItem = new EnrollmentTrackingItem();
    @Input('enrollmentId') enrollmentId?: string;
    classItem: ClassContentItem = new ClassContentItem();
    classSession: ClassSession = new ClassSession();
    upcomingSessions: Array<ClassSession> = new Array<ClassSession>();
    selectedSessionId: string = "00000000-0000-0000-0000-000000000000";
    courseLiveEvent: LiveEventWrapper = new LiveEventWrapper(
        "00000000-0000-0000-0000-000000000000",         // Event ID
        "",                                         // Event name
        "",                                         // Simple description
        "",                                         // Formatted description
        "",                                         // Keywords
        new Date(1970, 0, 1, 0, 0, 0, 0),           // Previous recording date
        "00000000-0000-0000-0000-000000000000",         // Previous recording video ID
        "00000000-0000-0000-0000-000000000000",         // Folder ID
        false,                                      // Is deleted
        "00000000-0000-0000-0000-000000000000",         // Provided by
        0,                                          // Duration (mins)
        new Array<ContentPrerequisite>(),           // Prerequisites
        new Array<LiveEventProperty>(),             // Properties
        "00000000-0000-0000-0000-000000000000",         // Session ID
        "00000000-0000-0000-0000-000000000000",         // Presenter ID
        "",                                         // Presenter display name
        "00000000-0000-0000-0000-000000000000",         // Scheduled by
        new Date(1970, 0, 1, 0, 0, 0, 0),           // Session datetime (unused)
        "",                                         // Session URL
        "",                                         // Session location
        LiveEventBrowserCalendarStatus.Internal,    // Session status
        new Date(1970, 0, 1, 0, 0, 0, 0),           // Session start datetime
        new Date(1970, 0, 1, 0, 0, 0, 0),           // Session end datetime
        false,                                      // All day session (unused)
        false,                                      // Use registration dates
        new Date(1970, 0, 1, 0, 0, 0, 0),           // Registration start date
        new Date(1970, 0, 1, 0, 0, 0, 0),           // Registration end date
        "00000000-0000-0000-0000-000000000000",
        false                                       // Session attended
    );
    @ViewChild(LiveEventRegistrationForm, { static: false }) registrationForm: LiveEventRegistrationForm;

    constructor(private service: ProdGenApi, private _sanitizer: DomSanitizer)
    {
        this.loadData();
    }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
      try {
          this.enrollmentItem = changes['enrollmentItem'].currentValue;
          this.loadData();

	  } catch (err) { console.log(err); };

  }


    loadData()
    {
        if (this.enrollmentItem != null && this.enrollmentItem.contentId != "")
        {
            this.service.getEnrollmentClass(this.enrollmentId, this.enrollmentItem.contentId, DescriptionFilter_T.formatted).subscribe(c => {
                this.classItem = c;
                this.classItem.description = "<html><body>" + this.classItem.description + "</body></html>";
                this.classItem.description = this.classItem.description.replace("\r\n", "<br/><br/>");

                // now see if we are registered for a session
                if (this.enrollmentItem.isClassRegistered == true)
                {
                    this.service.leGetLiveEventSessionWrapper(this.classSession.sessionId as string, [LiveEventPropertyType.EventSessionProperty]).subscribe(res => {
                        this.courseLiveEvent = res;
                    });

                    //this.service.getClassSession(this.classItem.classId, this.enrollmentItem.classSessionId).subscribe(res => {
                    //    this.classSession = res;
                    //    this.selectedSessionId = res.sessionId as string;
                    //});
                }

                window.scroll(0, 0);
              });
        }
    }

    loadFutureSessions()
    {
        this.upcomingSessions = new Array<ClassSession>();
        this.selectedSessionId = this.classSession.sessionId as string;
        this.service.getClassSessions(this.classItem.classId).subscribe(res => {
            this.upcomingSessions = res;
        });
    }

    onRegister()
    {
        if (this.upcomingSessions.length > 0 && this.selectedSessionId != "00000000-0000-0000-0000-000000000000") {
            console.log(this.selectedSessionId);
            let whitelist = new Array<LiveEventPropertyType>();
            whitelist.push(LiveEventPropertyType.EventSessionProperty);
            this.service.leGetLiveEventSessionWrapper(this.selectedSessionId, whitelist).subscribe(res => {
                this.courseLiveEvent = res;
                if (this.registrationForm != undefined) {
                    this.registrationForm.selectedEvent = this.courseLiveEvent;
                    this.registrationForm.showForm();
                    this.classSession = new ClassSession();
                    this.enrollmentItem = new EnrollmentTrackingItem();
                    this.enrollmentItem.classSessionId = res.sessionID;
                    this.enrollmentItem.classSessionDate = res.sessionDateTime;
                    this.enrollmentItem.isClassRegistered = true;
                    this.selectedSessionId = res.sessionID as string;
                }
            });

            //this.service.setClassRegistration(this.classItem.classId, this.selectedSessionId, false).subscribe(registrant => {
            //    // now reget the session information
            //    this.enrollmentItem.classSessionId = this.selectedSessionId;
            //    this.service.getClassSession(this.classItem.classId, this.enrollmentItem.classSessionId).subscribe(res => {
            //        this.classSession = res;
            //        this.enrollmentItem.classSessionId = res.sessionId;
            //        this.enrollmentItem.classSessionDate = res.sessionDateTime;
            //        this.enrollmentItem.classRegId = registrant.regId;
            //        this.enrollmentItem.isClassRegistered = true;
            //        this.selectedSessionId = res.sessionId as string;
            //    });
            //});
        }
    }

    onEnterClass()
    {
        this.service.setClassRegistration(this.classItem.classId, this.classSession.sessionId, true);
    }

    getActiveDesc(): SafeHtml {
        return this._sanitizer.bypassSecurityTrustHtml(this.classItem.description as string);
  }

}
