import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { CacheOptions, CacheLocation, APICacheService } from "../../services/api-cache.service";
import { ProdGenApi } from "../../apiService/prodgen.api";
import { UserDataGridInfo } from "./UserDataGridInfo";

@Injectable()
export class UserDataGridService {

    constructor(
        private contentService: ProdGenApi,
        private cacheService: APICacheService) { }

    // Delegate fetch responsibility to ProdGenApi/APICacheService
    private get(endpoint: string, cacheOptions: CacheOptions, forceRefresh: boolean = false): Observable<any> {

        // Use the same endpoint and authentication as the rest of the app.
        let url = `${this.contentService.getBaseUrl()}${endpoint}`;
        let headers = this.contentService.getAuthHeaders();

        // Invalidate the cache if requested.
        if (forceRefresh) {
            this.cacheService.invalidateContaining(endpoint);
        }

        // Fetch and update the cache. (This cast should be a generic, but one step at a time...)
        let result = this.cacheService.httpGetCacheableResponse<any>(url, { headers: headers }, cacheOptions);
        if (result.retrievedFromCache == false) {
            this.contentService.debugLog();
        }
        return result.data;
    }

    // Fetch user information for the UserDataGridComponent.
    public getUserDataGridInfo(forceRefresh: boolean = false, includeExternalUsers: boolean = false): Observable<UserDataGridInfo[]> {
        return this.get(`users/info?includeExternalUsers=${includeExternalUsers}`, CacheOptions.with({ cacheLocation: CacheLocation.sessionStorage, maxAge: 5 }), forceRefresh);
    }

    // Fetch group information for the UserDataGridComponent.
    public getUserGroupNames(forceRefresh: boolean = false): Observable<{ [key: string]: string }> {
        return this.get("users/info/groups", CacheOptions.with({ cacheLocation: CacheLocation.sessionStorage, maxAge: 5 }), forceRefresh);
    }
}