import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';
import cssVars from 'css-vars-ponyfill';
import { AssetLibrary } from '../apiService/classFiles/class.assetlibrary';
import { Content, ContentType_T } from '../apiService/classFiles/class.content';
import { Enrollment, EnrollmentStatus_T } from '../apiService/classFiles/class.enrollments';
import { CompanyNewsItem } from '../apiService/classFiles/class.organizations';
import { Playlist, PlaylistContent, User, WidgetContainerInfo } from '../apiService/classFiles/class.users';
import { PermissionLevel_T, Workgroup } from '../apiService/classFiles/class.workgroups';
import { AppComponent, currenttheme, defpreviewtheme } from '../app.component';
import { WidgetLibraryService } from '../services/widget-library.service';
import { Category } from './../apiService/classFiles/class.categories';
import { PartnerNewsItem } from './../apiService/classFiles/class.partners';
import { SettingType_T } from './../apiService/classFiles/class.users';
import { ProdGenApi } from './../apiService/prodgen.api';
import { BrowserAuthenticationService } from './../BrowserAuthenticationService';
import { SearchComponent } from './../search/search.component';

declare var $: any;
//declare function doesFontExist(fontName: string): boolean;

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css'],
    providers: [ProdGenApi],
    encapsulation: ViewEncapsulation.None,
})
export class HomeComponent implements OnInit {
    workGroups: Array<Workgroup> = new Array<Workgroup>();
    completedCourseCount: number = 0;
    currentEnrollments: Array<Enrollment> = new Array<Enrollment>();
    activeEnrollments: Array<Enrollment> = new Array<Enrollment>();
    frequentlyUsed: Array<Content> = new Array<Content>();
    trendingContent: Array<Content> = new Array<Content>();
    //  public searchConnectors: Array<SearchConnector> = new Array<SearchConnector>();
    //categoriesSubscription: Array<Category> = new Array<Category>();

    assetLibrariesData: Array<AssetLibrary> = new Array<AssetLibrary>();
    assetLibraryLoaded: boolean = false;

    assetLibrariesSubscription: Array<AssetLibrary> = new Array<AssetLibrary>();
    assetLibrarySubscriptionLoaded: boolean = false;

    //categoriesTenant: Array<Category> = new Array<Category>();
    currentUser: User = new User();
    searchTerms: string = "";


    companyInfo: Array<CompanyNewsItem> = new Array<CompanyNewsItem>();
    recommendedAddons: Array<CompanyNewsItem> = new Array<CompanyNewsItem>();
    companyInfoImage: string = "";
    companyInfoLoaded: boolean = false;

    partnerInfo: Array<PartnerNewsItem> = new Array<PartnerNewsItem>();
    recommendedPartnerAddons: Array<PartnerNewsItem> = new Array<PartnerNewsItem>();
    partnerInfoImage: string = "";
    partnerInfoLoaded: boolean = false;

    enrollmentsLoaded: boolean = false;
    workgroupsLoaded: boolean = false;
    isAuthenticated: boolean = false;
    selectedSort: string = "sortLP";

    condensedEnrollments: Array<Enrollment> = new Array<Enrollment>();

    playlist: Playlist = new Playlist();
    playlistcontent: Array<PlaylistContent> = new Array<PlaylistContent>();
    playlistcontentitem: Content = new Content();

    canViewDownloadAddOns: boolean = true;
    displaycardcount: number = 0;
    wgcardcount: number = 0;
    wgcardType = "Large";

    useWidgets: boolean = false;
    isWidgetsDetermined: boolean = false;
    homecontainer: WidgetContainerInfo = new WidgetContainerInfo();

    rowCardCount: number = 0;

    previewtheme = defpreviewtheme;

    courseImage: string = "";
    workgroupImage: string = "";


    //For Frequently Used Share-dropdown Component
    freqUsedConstructedContent: Content = new Content();
    freqUsedCurrentHover: string = "";
    freqUsedShareClickId: string = "";
    freqUsedShareAdjustmentX: string = '0';
    freqUsedShareAdjustmentY: string = '0';

    //For Trending Share-dropdown Component
    trendingConstructedContent: Content = new Content();
    trendingCurrentHover: string = "";
    trendingShareClickId: string = "";
    trendingShareAdjustmentX: string = '0';
    trendingShareAdjustmentY: string = '0';



    constructor(private pinnacleService: ProdGenApi, private router: Router, private _sanitizer: DomSanitizer, private authenticationService: BrowserAuthenticationService, private widgetLibSvc: WidgetLibraryService) {
        try {
            this.useWidgets = localStorage.getItem("usewidgets" + ProdGenApi.getCurrentTenantId()).toString().toLowerCase() == 'true';
        } catch (e) {
            this.useWidgets = false;
        }



        if (ProdGenApi.GetUserIsExternal() == true) {
            this.router.navigate(['/external-home']);
            return;
        }

        if (authenticationService.AuthenticatePage() == true) {



            this.isAuthenticated = true;
            this.companyInfoLoaded = false;

            this.currentUser.imageURL = "../assets/images/default_user.jpg";

            this.pinnacleService.GetCurrentTenant().subscribe(
                res => {
                    ProdGenApi.setCurrentTenantId(res.tenantId as string);

                    try {
                        this.useWidgets = localStorage.getItem("usewidgets" + ProdGenApi.getCurrentTenantId()).toString().toLowerCase() == 'true';
                    } catch (e) {
                        this.useWidgets = false;
                    }

                });


            this.pinnacleService.getCurrentUser().subscribe(u => {

                //let bAllowApps = this.pinnacleService.getAllowApplicationSearch();
                //if (bAllowApps == true) {
                //    this.pinnacleService.getSearchConnectors().subscribe(res => {
                //        this.searchConnectors = res;
                //    });
                //}

                if (u.imageURL == "") {
                    u.imageURL = "../assets/images/default_user.jpg";
                }
                this.currentUser = u;

                try {
                    localStorage.setItem(this.currentUser.userId + "_user_image", JSON.stringify(this.currentUser.imageURL));
                }
                catch (err) {
                    console.log(err);
                }


                // try to load up any previous information. this will keep the screen from flickering..?
                try {
                    //this.categoriesSubscription = JSON.parse(localStorage.getItem(this.currentUser.userId + "_categories_subscription"));
                    this.assetLibrariesData = JSON.parse(localStorage.getItem(this.currentUser.userId + "_asset_library"));

                }
                catch (err) {
                    console.log(err)
                }

                try {
                    //this.categoriesSubscription = JSON.parse(localStorage.getItem(this.currentUser.userId + "_categories_subscription"));
                    this.assetLibrariesSubscription = JSON.parse(localStorage.getItem(this.currentUser.userId + "_asset_library_subscription"));
                }
                catch (err) {
                    console.log(err);
                }


                try {
                    let i = JSON.parse(localStorage.getItem(ProdGenApi.getCurrentTenantId() + "_company_news"));
                    if (i != null) {
                        this.companyInfo = i;
                    }
                }
                catch (err) {
                    console.log(err)
                }

                try {
                    let i = JSON.parse(localStorage.getItem(ProdGenApi.getCurrentTenantId() + "_company_news_image"));
                    if (i != null) {
                        this.companyInfoImage = i;
                    }
                }
                catch (err) {
                    console.log(err)
                }


                try {
                    let i = JSON.parse(localStorage.getItem(ProdGenApi.getCurrentTenantId() + "_partner_news"));
                    if (i != null) {
                        this.partnerInfo = i;
                    }
                }
                catch (err) {
                    console.log(err)
                }

                try {
                    let i = JSON.parse(localStorage.getItem(ProdGenApi.getCurrentTenantId() + "_partner_news_image"));
                    if (i != null) {
                        this.partnerInfoImage = i;
                    }
                }
                catch (err) {
                    console.log(err)
                }



                //try {
                //    this.categoriesTenant = JSON.parse(localStorage.getItem(this.currentUser.userId + "_categories_tenant"));
                //}
                //catch (err) {
                //    console.log(err)
                //}

                try {
                    this.workGroups = JSON.parse(localStorage.getItem(this.currentUser.userId + "_workgroups"));
                }
                catch (err) {
                    console.log(err)
                }


                try {
                    this.currentEnrollments = JSON.parse(localStorage.getItem(this.currentUser.userId + "_enrollments"));
                }
                catch (err) {
                    console.log(err)
                }


                try {
                    this.frequentlyUsed = JSON.parse(localStorage.getItem(this.currentUser.userId + "_frequent"));
                }
                catch (err) {
                    console.log(err)
                }

                try {
                    this.trendingContent = JSON.parse(localStorage.getItem(this.currentUser.userId + "_trending"));
                }
                catch (err) {
                    console.log(err)
                }


                /// end of pre-load
                //  });
                //  }
                //junk(): void{this.pinnacleService.GetContentItem("").subscribe(w=>{

                //any pending reset requests are marked as complete after login
                this.pinnacleService.MarkResetRequestsComplete(this.currentUser.userId).subscribe();

            });


        }
    }

    ngOnInit() {


        this.pinnacleService.getCurrentUser().subscribe(u => {
            //Custom Images for no workgroup/no courses divs
            let cImg = ProdGenApi.getLargeDefaultImageFromType(ContentType_T.course);
            let wgImg = ProdGenApi.getLargeDefaultImageFromType(ContentType_T.workgroup);


            if (cImg.indexOf("default") == -1) { this.courseImage = cImg; }
            else { this.courseImage = "../../assets/images/" + cImg; }

            if (wgImg.indexOf("default") == -1) { this.workgroupImage = wgImg; }
            else { this.workgroupImage = "../../assets/images/" + wgImg; }

            this.pinnacleService.getSettingByName("USE_WIDGETS").subscribe(res => {
                let widgetSetting = res;
                if (widgetSetting) {
                    this.useWidgets = widgetSetting.settingValue.toString().toLowerCase() == 'true';
                }
                else {
                    this.useWidgets = false;
                }

                if (this.useWidgets == false) {

                    // make the service calls we need to load data for the non-widgetized version
                    this.pinnacleService.getCurrentUserWorkgroups([PermissionLevel_T.owner, PermissionLevel_T.editor, PermissionLevel_T.user], 10).subscribe(res => {
                        for (var i = 0; i < res.length; i++) {

                            if (res[i].imageURL == null || res[i].imageURL == "") {
                                //get default workgroup image from storage
                                let defaultwgimage = "";
                                 
                                try {
                                    defaultwgimage = localStorage.getItem(ProdGenApi.getCurrentTenantId() + "_defWorkgroupImage");
                                }
                                catch (err) {
                                }

                                if (defaultwgimage != null && defaultwgimage != "") {
                                    res[i].imageURL = defaultwgimage;
                                }
                                else {
                                    res[i].imageURL = "../assets/images/default_project.jpg";
                                }
                            }
                        }
                        this.workgroupsLoaded = true;
                        this.getWorkGroupCardCount(window.innerWidth);
                        this.workGroups = res;

                        /// write to Local Storage for this user
                        try {
                            localStorage.setItem(this.currentUser.userId + "_workgroups", JSON.stringify(this.workGroups))
                        }
                        catch (err) {
                            console.log(err);
                        }

                    }
                    );

                    this.pinnacleService.getCurrentUserEnrollments().subscribe(res => {
                        for (var i = 0; i < res.length; i++) {
                            if (res[i].imageURL == null || res[i].imageURL == "") {
                                let defaultenrollmentimage = "";

                                try {
                                    defaultenrollmentimage = localStorage.getItem(ProdGenApi.getCurrentTenantId() + "_EnrollmentImage");
                                }
                                catch (err) {
                                }

                                if (defaultenrollmentimage != null && defaultenrollmentimage != "") {
                                    res[i].imageURL = defaultenrollmentimage;
                                }
                                else {
                                    res[i].imageURL = "../assets/images/default_course.jpg";
                                }

                            }
                        }
                        this.currentEnrollments = res;
                        this.activeEnrollments = res.filter(x => x.enrollmentStatus == EnrollmentStatus_T.inProgress);
                        this.getEnrollCardCount(window.innerWidth);
                        //copy and sort the array. flag the lowest squences with the number of other items with the same learning path. remove non-lowest sequence.
                        //copy and sort the array.
                        //let condensedEnrollments: Array<Enrollment> = new Array<Enrollment>();
                        this.CondenseEnrollments()

                        this.enrollmentsLoaded = true;


                        /// write to Local Storage for this user
                        try {
                            localStorage.setItem(this.currentUser.userId + "_enrollments", JSON.stringify(this.currentEnrollments))
                        }
                        catch (err) {
                            console.log(err);
                        }

                    }
                    );

                    // Get Frequently Used Content
                    this.pinnacleService.getCurrentUserFrequentContent(16, 0).subscribe(res => {
                        this.frequentlyUsed = new Array<Content>();
                        for (var i = 0; i < res.length; i++) {
                            if (res[i].contentType == ContentType_T.workflow ||
                                res[i].contentType == ContentType_T.process ||
                                res[i].contentType == ContentType_T.cheatsheet ||
                                res[i].contentType == ContentType_T.video) {
                                this.frequentlyUsed.push(res[i]);
                            }
                        }
                        this.getFrequentCardCount();
                        /// write to Local Storage for this user
                        try {
                            localStorage.setItem(this.currentUser.userId + "_frequent", JSON.stringify(this.frequentlyUsed))
                        }
                        catch (err) {
                            console.log(err);
                        }

                    }
                    );

                    // Get Trending Content
                    this.pinnacleService.getCurrentTenantTrendingContent(6).subscribe(res => {
                        this.trendingContent = res;
                        if (this.trendingContent.length == 0) {
                            // If there's no trending content, hide trending section
                        }

                        /// write to Local Storage for this user
                        try {
                            localStorage.setItem(this.currentUser.userId + "_trending", JSON.stringify(this.trendingContent))
                        }
                        catch (err) {
                            console.log(err);
                        }

                    }, e => {
                        // Some Error Response
                    }
                    );




                }
                this.widgetLibSvc.setIsUsingWidget(this.useWidgets);
                this.isWidgetsDetermined = true;
            },
                err => {
                    this.isWidgetsDetermined = true;
                });

            window.scroll(0, 0);
            this.pinnacleService.getWidgetContainerForUser("HOME_SCREEN_CONTAINER").subscribe(res => {
                this.homecontainer = res;
            });


            try {
                let selLanguage = localStorage.getItem("selectedLanguage");
                if (selLanguage != null && selLanguage.length > 0) {
                    this.pinnacleService.saveCurrentUserSetting("LanguagePreference", selLanguage, SettingType_T.string).subscribe();
                }
            }
            catch (e){ }

        });
    }

    get isPinnacleLite() {
        return AppComponent.isPinnacleLite;
    }

    onResize(event) {
        this.getEnrollCardCount(event.target.innerWidth);
        this.getWorkGroupCardCount(event.target.innerWidth);
        this.getFrequentCardCount();
    }

    getEnrollCardCount(width: number) {
        if (width > 992) {
            this.displaycardcount = Math.floor(((width / 12) * 8) / 250);//If enroll card width changes update the equation to read: Math.floor(((width / 12) * 8) / (Adjust this number))
        }
        else {
            this.displaycardcount = 3;
        }
    }

    getWorkGroupCardCount(width: number) {
        if (width > 992) {
            this.wgcardcount = Math.floor(((width / 12) * 8) / 250);
            this.wgcardType = "Large";//If WorkGroup card width changes update the equation to read: Math.floor(((width / 12) * 8) / (Adjust this number))
        }
        else {
            this.wgcardcount = 6;
            this.wgcardType = "Small";
        }
    }

    getFrequentCardCount() {
        if (document.getElementById("frequentUsedBlockContainer")) {

            let pxwidth = document.getElementById("frequentUsedBlockContainer").offsetWidth;

            this.rowCardCount = Math.floor(pxwidth / 150);//Integer is the full width of the card, including margins, borders, and padding
        }
        else {
            this.rowCardCount = 0;
        }
    }

    onCourseDropped(event) {
        let e: Enrollment = event.enrollment;
        if (e != null) {
            let index = this.currentEnrollments.findIndex(i => i.enrollmentId == e.enrollmentId);
            if (index != -1) {
                this.currentEnrollments.splice(index, 1);
                this.CondenseEnrollments();
            }
        }

    }


    hideLoadingGif(someId: string) {
        document.getElementById(someId).style.display = "none";
    }
    showMoreCaretDown(someId: string) {
        $('#' + someId).fadeIn(300);
        document.getElementById(someId + 'ShowMoreButton').style.display = "none";
        document.getElementById(someId + 'ShowLessButton').style.display = "block";

        if (someId.includes("frequentUsed")) {
            try {
                $('#' + someId + "Container").removeClass("small-card-container-singlerow");
            } catch (err) { }

        }
        else {
            try {
                $('#' + someId + "Container").removeClass("large-card-container-singlerow");
            } catch (err) { }
        }
    }
    showLessCaretUp(someId: string) {
        $('#' + someId).slideToggle(300);
        document.getElementById(someId + 'ShowLessButton').style.display = "none";
        document.getElementById(someId + 'ShowMoreButton').style.display = "block";
        if (someId.includes("frequentUsed")) {
            try {
                $('#' + someId + "Container").addClass("small-card-container-singlerow");
            } catch (err) { }

        }
        else {
            try {
                $('#' + someId + "Container").addClass("large-card-container-singlerow");
            } catch (err) { }
        }
    }

    onSearch(): void {
        let url: string = "";
        if (this.searchTerms.trim().length > 0) {
            url += "search?searchTerms=" + SearchComponent.encodeSearchParameters(this.searchTerms.trim());
            this.router.navigateByUrl(url);
        }
    }

    //connectorSearch(connector: SearchConnector) {
    //    // get the active command
    //    this.pinnacleService.getSearchConnectors().subscribe(res => {
    //        for (let c of res) {
    //            if (c.appName == connector.appName) {
    //                if (c.searchTerm.length > 0) {
    //                    this.searchTerms = c.searchTerm;
    //                    this.onSearch();
    //                }
    //            }
    //        }
    //        this.searchConnectors = res;
    //    });
    //}


    onKeySearchPress(event: any) {
        if (event.keyCode == 13) {
            this.onSearch();
        }
    }
    onKeySearchInput(event: any) {
        if (event.data == null && event.composed != null && event.composed == false) {
            this.onSearch();
        }
    }


    onSearchTermsChanged() {
    }

    categoryHREF(category: Category): string {
        let url: string = "";
        url += "search?category=" + SearchComponent.encodeSearchParameters(category.name as string);
        return url;

    }

    getContentUrl(r: PlaylistContent): string {
        let url: string = "#";
        if (r.contentType == 5) {
            url += "/workflowviewer?id=" + r.contentId + "&contentType=workflow";
        }
        else if (r.contentType == 6) {
            url += "/workflowviewer?id=" + r.contentId + "&contentType=process";
        }
        else if (r.contentType == 7) {
            url += "/workflowviewer?id=" + r.contentId + "&contentType=task";
        }
        else if (r.contentType == 8) {
            url += "/workflowviewer?id=" + r.contentId + "&contentType=step";
        }
        else if (r.contentType == 0) {
            url += "/cheatsheetviewer?id=" + r.contentId;
        }
        else if (r.contentType == 4) {
            url += "/videoviewer?id=" + r.contentId;
        }
        else if (r.contentType == 1) {
            url += "/learningcenter/series?learningPathId=" + r.contentId;
        }
        else if (r.contentType == 2) {
            if (this.currentEnrollments) {         
                if (this.currentEnrollments.findIndex(course => course.courseId == r.contentId) == -1) {
                    url += "/learningcenter/series?courseId=" + r.contentId;
                }
                else {
                    url += "/learningcenter/courseviewer?enrollmentId=" + this.currentEnrollments[this.currentEnrollments.findIndex(course => course.courseId == r.contentId)].enrollmentId;
                }
            }
        }

        return url;
    }




    getSafeHtml(htmlText: string): SafeHtml {
        if (htmlText == null) {
            htmlText = "";
        }
        return this._sanitizer.bypassSecurityTrustHtml(htmlText);
    }

    //onFacetHeaderClicked(a: AssetLibrary) {
    // this.getExpandedIconClass("#collapse_" + a.assetLibraryId);

    // var isExpanded = $("#image_" + a.assetLibraryId).hasClass("fa-minus");
    // $("#image_" + a.assetLibraryId).toggleClass("fa-minus");
    // $("#image_" + a.assetLibraryId).toggleClass("fa-plus");
    //}

    //getExpandedIconClass(elmId: string): string {

    // //return "fa fa-minus";
    // return "fa fa-plus";
    //}

    //assetLibraryProductHREF(product: AssetLibraryProduct, asset: AssetLibrary): string {
    // let url: string = "";
    // //url += "search?category=" + SearchComponent.encodeSearchParameters(product.name as string);
    //  url += "product-listing?proid=" + product.productId + "&astid=" + product.assetLibraryId + "&libname=" + asset.name + "&name=" + product.name + "&isprod=true";
    // return url;

    //}

    //assetLibraryAssetHREF(asset: AssetLibrary): string {
    // let url: string = "";
    // //url += "search?category=" + SearchComponent.encodeSearchParameters(asset.name as string);
    //  //url += "product-listing?proid=" + asset.productCollection[0].productId + "&astid=" + asset.assetLibraryId + "&name=" + "" + "&isprod=true";

    //  url += "product-listing?proid=" + asset.productCollection[0].productId + "&astid=" + asset.assetLibraryId + "&libname=" + asset.name + "&name=" + "" + "&isprod=true";
    // return url;

    //}

    SortChange(): void {
        //this.currentEnrollments[0]
        switch (this.selectedSort) {
            case "sortDueDT_EtoL": {
                sessionStorage.setItem("courseSort", this.selectedSort);
                this.condensedEnrollments.sort((a: any, b: any) => {
                    var x = a.hasDueDate;
                    var y = b.hasDueDate;
                    var w = a.dueDate;
                    var z = b.dueDate;
                    if (x < y) { return 1; }
                    if (x > y) { return -1; }
                    if (x == y) { //if the values are equal do a second level sort 

                        if (w < z) { return -1; }
                        if (w > z) { return 1; }
                        return 0;
                    }
                });
                break;
            }
            case "sortDueDT_LtoE": {
                sessionStorage.setItem("courseSort", this.selectedSort);
                this.condensedEnrollments.sort((a: any, b: any) => {
                    var x = a.hasDueDate;
                    var y = b.hasDueDate;
                    var w = a.dueDate;
                    var z = b.dueDate;
                    if (x < y) { return 1; }
                    if (x > y) { return -1; }
                    if (x == y) {

                        if (w < z) { return 1; }
                        if (w > z) { return -1; }
                        return 0;
                    }
                });
                break;
            }
            case "sortLP": {
                sessionStorage.setItem("courseSort", this.selectedSort);
                this.condensedEnrollments.sort((a: any, b: any) => {
                    var x = a.learningPathName;
                    var y = b.learningPathName;
                    var w = a.sequence;
                    var z = b.sequence;
                    if (x < y) { return -1; }
                    if (x > y) { return 1; }
                    if (x == y) {

                        if (w < z) { return -1; }
                        if (w > z) { return 1; }
                        return 0;
                    }
                });
                break;
            }
            case "sortPerCom_LtoM": {
                sessionStorage.setItem("courseSort", this.selectedSort);
                this.condensedEnrollments.sort((a: any, b: any) => {
                    var x = a.percentComplete;
                    var y = b.percentComplete;
                    if (x < y) { return -1; }
                    if (x > y) { return 1; }
                    return 0;
                });
                break;
            }
            case "sortPerCom_MtoL": {
                sessionStorage.setItem("courseSort", this.selectedSort);
                this.condensedEnrollments.sort((a: any, b: any) => {
                    var x = a.percentComplete;
                    var y = b.percentComplete;
                    if (x < y) { return 1; }
                    if (x > y) { return -1; }
                    return 0;
                });
                break;
            }
            default:
        }
    }

    CondenseEnrollments() {

        let copiedEnrollment: Enrollment = new Enrollment();
        this.condensedEnrollments.splice(0);
        for (var i = 0; i < this.currentEnrollments.length; i++) {
            copiedEnrollment = this.currentEnrollments[i];
            copiedEnrollment.childCourses = 0;
            this.condensedEnrollments.push(copiedEnrollment);
        }

        this.condensedEnrollments.sort((a: any, b: any) => {
            var x = a.learningPathId;
            var y = b.learningPathId;
            var w = a.sequence;
            var z = b.sequence;
            if (x < y) { return 1; }
            if (x > y) { return -1; }
            if (x == y) { //if the values are equal do a second level sort 

                if (w < z) { return -1; }
                if (w > z) { return 1; }
                return 0;
            }
        });

        // flag the lowest squences with the number of other items with the same learning path.

        for (var n = 0; n < this.condensedEnrollments.length; n++) {
            let d: number = 1;
            let childCourses: number = 1;
            if (n == 0) { //first item
                if (this.condensedEnrollments.length == 1) {
                    childCourses = 1;
                }
                else {
                    try {
                        while ((n + d) < this.condensedEnrollments.length && this.condensedEnrollments[n].learningPathId == this.condensedEnrollments[n + d].learningPathId) {
                            childCourses++;
                            d++;
                        }
                    }
                    catch (err) { }
                }
            }
            else if ((n == this.condensedEnrollments.length - 1) && (this.condensedEnrollments[n].learningPathId != this.condensedEnrollments[n - 1].learningPathId)) { //last item. this is either a part of another path and has already been accounted for. otherwise it is its own
                childCourses = 1;
            }
            else if (this.condensedEnrollments[n].learningPathId == this.condensedEnrollments[n - 1].learningPathId) {
                childCourses = 0;
            }
            else {

                while ((n + d) < this.condensedEnrollments.length && this.condensedEnrollments[n].learningPathId == this.condensedEnrollments[n + d].learningPathId) {

                    childCourses++;
                    d++;
                }
            }

            this.condensedEnrollments[n].childCourses = childCourses;
        }



        //remove remove non-lowest sequences (i.e. those with no child courses).
        for (var e = 0; e < this.condensedEnrollments.length; e++) {
            if (this.condensedEnrollments[e].childCourses == 0) {
                this.condensedEnrollments.splice(e, 1);
                e--;
            }
        }



        this.selectedSort = sessionStorage.getItem("courseSort");

        if (this.selectedSort == null) { this.selectedSort = "sortLP"; }

        this.SortChange();

        setTimeout(() => {
            this.checkcssVars();
        });

    }

   
    getContentImage(r: Content): string {
        let image: string = "";

        image += ProdGenApi.getIconImageFromType(r.contentType);

        if (r.bisCustom == false) {
            
        }
        else {
            image = "fas fa-id-badge";
        }

        return image;
    }


    checkcssVars() {
        cssVars({
            rootElement: document,
            variables: {
                "--preview-font-face": this.previewtheme.properties["--preview-font-face"],
                "--preview-page-background-color": this.previewtheme.properties["--preview-page-background-color"],
                "--preview-page-text-color": this.previewtheme.properties["--preview-page-text-color"],
                "--preview-page-separator-color": this.previewtheme.properties["--preview-page-separator-color"],
                "--preview-page-separator-width": this.previewtheme.properties["--preview-page-separator-width"],
                "--preview-navbar-background-color": this.previewtheme.properties["--preview-navbar-background-color"],
                "--preview-navbar-foreground-color": this.previewtheme.properties["--preview-navbar-foreground-color"],
                "--preview-hsl-background-color": this.previewtheme.properties["--preview-hsl-background-color"],
                "--preview-hsl-foreground-color": this.previewtheme.properties["--preview-hsl-foreground-color"],
                "--preview-hsl-border-color": this.previewtheme.properties["--preview-hsl-border-color"],
                "--preview-hsl-border-width": this.previewtheme.properties["--preview-hsl-border-width"],
                "--preview-hsr-background-color": this.previewtheme.properties["--preview-hsr-background-color"],
                "--preview-hsr-foreground-color": this.previewtheme.properties["--preview-hsr-foreground-color"],
                "--preview-hsr-border-color": this.previewtheme.properties["--preview-hsr-border-color"],
                "--preview-hsr-border-width": this.previewtheme.properties["--preview-hsr-border-width"],
                "--preview-large-card-background-color1": this.previewtheme.properties["--preview-large-card-background-color1"],
                "--preview-large-card-foreground-color1": this.previewtheme.properties["--preview-large-card-foreground-color1"],
                "--preview-large-card-background-color2": this.previewtheme.properties["--preview-large-card-background-color2"],
                "--preview-large-card-foreground-color2": this.previewtheme.properties["--preview-large-card-foreground-color2"],
                "--preview-large-card-background-color3": this.previewtheme.properties["--preview-large-card-background-color3"],
                "--preview-large-card-foreground-color3": this.previewtheme.properties["--preview-large-card-foreground-color3"],
                "--preview-large-card-border-color": this.previewtheme.properties["--preview-large-card-border-color"],
                "--preview-large-card-border-width": this.previewtheme.properties["--preview-large-card-border-width"],
                "--preview-large-card-border-radius": this.previewtheme.properties["--preview-large-card-border-radius"],
                "--preview-small-card-background-color1": this.previewtheme.properties["--preview-small-card-background-color1"],
                "--preview-small-card-foreground-color1": this.previewtheme.properties["--preview-small-card-foreground-color1"],
                "--preview-small-card-background-color2": this.previewtheme.properties["--preview-small-card-background-color2"],
                "--preview-small-card-foreground-color2": this.previewtheme.properties["--preview-small-card-foreground-color2"],
                "--preview-small-card-background-color3": this.previewtheme.properties["--preview-small-card-background-color3"],
                "--preview-small-card-foreground-color3": this.previewtheme.properties["--preview-small-card-foreground-color3"],
                "--preview-small-card-border-color": this.previewtheme.properties["--preview-small-card-border-color"],
                "--preview-small-card-border-width": this.previewtheme.properties["--preview-small-card-border-width"],
                "--preview-small-card-border-radius": this.previewtheme.properties["--preview-small-card-border-radius"],
                "--preview-panel-title-background-color": this.previewtheme.properties["--preview-panel-title-background-color"],
                "--preview-panel-title-foreground-color": this.previewtheme.properties["--preview-panel-title-foreground-color"],
                "--preview-panel-body-background-color": this.previewtheme.properties["--preview-panel-body-background-color"],
                "--preview-panel-body-foreground-color": this.previewtheme.properties["--preview-panel-body-foreground-color"],
                "--preview-panel-border-color": this.previewtheme.properties["--preview-panel-border-color"],
                "--preview-panel-border-width": this.previewtheme.properties["--preview-panel-border-width"],
                "--preview-panel-border-radius": this.previewtheme.properties["--preview-panel-border-radius"],
                "--preview-button-background": this.previewtheme.properties["--preview-button-background"],
                "--preview-button-foreground": this.previewtheme.properties["--preview-button-foreground"],

                "--font-face": currenttheme.properties["--font-face"],
                "--page-background-color": currenttheme.properties["--page-background-color"],
                "--page-text-color": currenttheme.properties["--page-text-color"],
                "--page-separator-color": currenttheme.properties["--page-separator-color"],
                "--page-separator-width": currenttheme.properties["--page-separator-width"],
                "--navbar-background-color": currenttheme.properties["--navbar-background-color"],
                "--navbar-foreground-color": currenttheme.properties["--navbar-foreground-color"],
                "--hsl-background-color": currenttheme.properties["--hsl-background-color"],
                "--hsl-foreground-color": currenttheme.properties["--hsl-foreground-color"],
                "--hsl-border-color": currenttheme.properties["--hsl-border-color"],
                "--hsl-border-width": currenttheme.properties["--hsl-border-width"],
                "--hsr-background-color": currenttheme.properties["--hsr-background-color"],
                "--hsr-foreground-color": currenttheme.properties["--hsr-foreground-color"],
                "--hsr-border-color": currenttheme.properties["--hsr-border-color"],
                "--hsr-border-width": currenttheme.properties["--hsr-border-width"],
                "--large-card-background-color1": currenttheme.properties["--large-card-background-color1"],
                "--large-card-foreground-color1": currenttheme.properties["--large-card-foreground-color1"],
                "--large-card-background-color2": currenttheme.properties["--large-card-background-color2"],
                "--large-card-foreground-color2": currenttheme.properties["--large-card-foreground-color2"],
                "--large-card-background-color3": currenttheme.properties["--large-card-background-color3"],
                "--large-card-foreground-color3": currenttheme.properties["--large-card-foreground-color3"],
                "--large-card-border-color": currenttheme.properties["--large-card-border-color"],
                "--large-card-border-width": currenttheme.properties["--large-card-border-width"],
                "--large-card-border-radius": currenttheme.properties["--large-card-border-radius"],
                "--small-card-background-color1": currenttheme.properties["--small-card-background-color1"],
                "--small-card-foreground-color1": currenttheme.properties["--small-card-foreground-color1"],
                "--small-card-background-color2": currenttheme.properties["--small-card-background-color2"],
                "--small-card-foreground-color2": currenttheme.properties["--small-card-foreground-color2"],
                "--small-card-background-color3": currenttheme.properties["--small-card-background-color3"],
                "--small-card-foreground-color3": currenttheme.properties["--small-card-foreground-color3"],
                "--small-card-border-color": currenttheme.properties["--small-card-border-color"],
                "--small-card-border-width": currenttheme.properties["--small-card-border-width"],
                "--small-card-border-radius": currenttheme.properties["--small-card-border-radius"],
                "--panel-title-background-color": currenttheme.properties["--panel-title-background-color"],
                "--panel-title-foreground-color": currenttheme.properties["--panel-title-foreground-color"],
                "--panel-body-background-color": currenttheme.properties["--panel-body-background-color"],
                "--panel-body-foreground-color": currenttheme.properties["--panel-body-foreground-color"],
                "--panel-border-color": currenttheme.properties["--panel-border-color"],
                "--panel-border-width": currenttheme.properties["--panel-border-width"],
                "--panel-border-radius": currenttheme.properties["--panel-border-radius"],
                "--button-background": currenttheme.properties["--button-background"],
                "--button-foreground": currenttheme.properties["--button-foreground"]
            }
        });
    }




    //For Share DropDown
    buildItem(itemID: string, itemName: string, itemType: ContentType_T): Content {
        let constructedContent = new Content();
        constructedContent.contentId = itemID;
        constructedContent.name = itemName;
        constructedContent.contentType = itemType;

        return constructedContent;
    }


    //For Share DropDown
    hoverElem(content: Content, type: string) {
        if (type == 'freqUsed') {
            this.freqUsedConstructedContent = this.buildItem(content.contentId.toString(), content.name.toString(), content.contentType);
            this.freqUsedCurrentHover = content.contentId.toString();

            if (this.freqUsedCurrentHover != this.freqUsedShareClickId) {
                this.freqUsedShareClickId = "";
            }
        }
        else if (type == 'trending') {
            this.trendingConstructedContent = this.buildItem(content.contentId.toString(), content.name.toString(), content.contentType);
            this.trendingCurrentHover = content.contentId.toString();

            if (this.trendingCurrentHover != this.trendingShareClickId) {
                this.trendingShareClickId = "";
            }
        }
    }

    //For Share DropDown
    exitHoverElem(type: string) {
        if (type == 'freqUsed' && this.freqUsedShareClickId == "") {
            this.freqUsedCurrentHover = ""
        }
        else if (type == 'trending' && this.trendingShareClickId == "") {
            this.trendingCurrentHover = "";
        }
    }


    //For Share DropDown
    shareClickFreqUsed(content: Content) {
        this.freqUsedConstructedContent = this.buildItem(content.contentId.toString(), content.name.toString(), content.contentType);
        if (this.freqUsedShareClickId == content.contentId.toString()) {
            this.freqUsedShareClickId = "";
        }
        else {
            this.freqUsedShareClickId = content.contentId.toString();
        }

        let tempdiv = document.getElementById('contentScrollArea' + 'FreqUsed');

        let box = { left: 0, top: 0 };
        box = tempdiv.getBoundingClientRect();
        


        let reservedSpotShare = document.getElementById('shareIconPlacment' + 'FreqUsed' + content.contentId);

        let box2 = { left: 0, top: 0 };
        box2 = reservedSpotShare.getBoundingClientRect();


        let x = (box.left - box2.left);
        let y = (box.top - box2.top);
        //console.log('x : ' + x + '   y : ' + y);


        if (x > -250) {
            this.freqUsedShareAdjustmentX = (15) + 'px';
        }
        else {
            this.freqUsedShareAdjustmentX = ((-1 * x) - 220) + 'px';
        }

        this.freqUsedShareAdjustmentY = ((-1 * y) + 15) + 'px';
    }

    //For Share DropDown
    onHideShareDropDownFreqUsed(event) {
        this.freqUsedShareClickId = "";
    }


    shareClickTrending(content: Content) {
        this.trendingConstructedContent = this.buildItem(content.contentId.toString(), content.name.toString(), content.contentType);
        if (this.trendingShareClickId == content.contentId.toString()) {
            this.trendingShareClickId = "";
        }
        else {
            this.trendingShareClickId = content.contentId.toString();
        }

        let tempdiv = document.getElementById('contentScrollArea' + 'Trending');

        let box = { left: 0, top: 0 };
        box = tempdiv.getBoundingClientRect();



        let reservedSpotShare = document.getElementById('shareIconPlacment' + 'Trending' + content.contentId);

        let box2 = { left: 0, top: 0 };
        box2 = reservedSpotShare.getBoundingClientRect();


        let x = (box.left - box2.left);
        let y = (box.top - box2.top);
        //console.log('x : ' + x + '   y : ' + y);


        if (x > -250) {
            this.trendingShareAdjustmentX = (15) + 'px';
        }
        else {
            this.trendingShareAdjustmentX = ((-1 * x) - 220) + 'px';
        }

        this.trendingShareAdjustmentY = ((-1 * y) + 15) + 'px';
    }

    //For Share DropDown
    onHideShareDropDownTrending(event) {
        this.trendingShareClickId = "";
    }
}
