import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ProdGenApi } from './../../apiService/prodgen.api';
import { ContentType_T, DescriptionFilter_T, CheatSheet, Content } from './../../apiService/classFiles/class.content';

@Component({
  selector: 'app-cheatsheetcontent',
  templateUrl: './cheatsheetcontent.component.html',
  styleUrls: ['./cheatsheetcontent.component.css']
})
export class CheatsheetcontentComponent implements OnInit {
    @Input('contentId') contentId: string;
    @Input('enrollmentId') enrollmentId?: string;
    cheatSheet: CheatSheet = new CheatSheet();
    actualDescription: SafeHtml;
    sourceurl: string = "";
    viewerhtmlcontent: SafeHtml;
    externalsourcetype: string = "default";
    translatedTitle: string = "";

    constructor(private service: ProdGenApi, private _sanitizer: DomSanitizer)
    {
        this.loadData();
    }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    try {
      if (changes['contentId'] != null) {
        this.contentId = changes['contentId'].currentValue;
        this.loadData();
      }

	  } catch (err) { console.log(err); };

  }


    loadData()
    {
        this.cheatSheet = new CheatSheet();
        var treeElement = document.querySelector(".treePanel") as HTMLElement;
        treeElement.innerHTML = "";

            if (this.contentId != null && this.contentId != "") {
                if (this.enrollmentId == null) {
					this.service.GetCheatSheet(this.contentId, DescriptionFilter_T.formatted).subscribe(c => {
                        this.cheatSheet = c;
                        this.setviewerhtml();


                        this.actualDescription = this.getActiveDesc();

                        window.scroll(0, 0);
                        this.getTranslatedTitle();
                  });
                }
                else
                {
                    this.service.getEnrollmentCheatSheet(this.enrollmentId, this.contentId, DescriptionFilter_T.formatted).subscribe(c => {
                        this.cheatSheet = c;
                        this.setviewerhtml();

                        window.scroll(0, 0);
                        this.actualDescription = this.getActiveDesc();
                        this.getTranslatedTitle();

					}, err => {
						if (err.status == 409)
						{
							console.log("data unavailable to subscription");
							this.cheatSheet.cheatId = this.contentId;
							this.cheatSheet.useExternalDoc = false;
							this.cheatSheet.description = "This content is unavailable with your subscription selection.";
						}
					});
                }
                
            }
        else
        {

        }

    }

    getTranslatedTitle() {
        if (this.cheatSheet != null && this.cheatSheet.name != null) {
            this.service.translateText(this.cheatSheet.name as string, "en", false).subscribe(res => {
                this.translatedTitle = res;
            });
        }
    }

    getActiveDesc(): SafeHtml {
        if (this.cheatSheet.useExternalDoc == true)
        {
            return this._sanitizer.bypassSecurityTrustResourceUrl(this.cheatSheet.externalDocURL.replace("http://", "https://") as string);
        }
      return this._sanitizer.bypassSecurityTrustHtml(this.cheatSheet.description as string);
    }

    setviewerhtml() {
        if (this.cheatSheet.useExternalDoc == false) {
            return;
        }

        let documentUrlAndSasToken = this.cheatSheet.externalDocURL.split("?");
        let documentExtension = documentUrlAndSasToken[0].split(".").pop().toLowerCase();
        var treeElement = document.querySelector(".treePanel") as HTMLElement;;

        switch (documentExtension) { //set the externalsourcetype depending on the extension
            case "pdf":
                {
                    this.externalsourcetype = documentExtension;
                    this.sourceurl = this.cheatSheet.externalDocURL.toString();
                    this.viewerhtmlcontent = this._sanitizer.bypassSecurityTrustHtml('<iframe src="' + this.sourceurl + '" style="width: 100%; height: 60vh;"></iframe>');
                }
                break;
            case "html":
            case "htm":
                {
                    this.externalsourcetype = "html";
                    this.viewerhtmlcontent = this._sanitizer.bypassSecurityTrustHtml('<iframe src="' + this.cheatSheet.externalDocURL.toString() + '"  style="width: 100%; height:60vh;" marginwidth = "0" marginheight = "0" frameborder = "0" vspace = "0" hspace = "0" >');
                }
                break;
            case "docx":
            case "dotx":
            case "xlsx":
            case "xlsb":
            case "xls":
            case "xlsm":
            case "ppt":
            case "pptx":
            case "ppsx":
            case "pps":
            case "potx":
            case "ppsm":
                {
                    this.externalsourcetype = "microsoft";
                    this.sourceurl = "https://view.officeapps.live.com/op/embed.aspx?src=" + encodeURIComponent(this.cheatSheet.externalDocURL.toString()) + "&amp;embedded=true";
                    this.viewerhtmlcontent = this._sanitizer.bypassSecurityTrustHtml('<iframe src="' + this.sourceurl + '" style="width: 100%; height: 70vh;"></iframe>');
                    //document.getElementById("DownloadACopy-Menu").remove(); //remove download button for word
                    //document.getElementById("ChromelessStatusBar.Options.DownloadACopy-Menu").remove(); //remove download button for powerpoint
                    if (documentExtension == "docx" || documentExtension == "dotx") {

                        //let url = new URL(documentUrlAndSasToken[0]);
                        let url = documentUrlAndSasToken[0];
                        url = url.replace("http://pinnacle.blob.core.windows.net", "");
                        url = url.replace("https://pinnacle.blob.core.windows.net", "");

                        var containerName = url.split("/")[1];
                        var blobName = url.substring(url.indexOf('/', 1)).substring(1);
                        var relativeAddress = url.substring(url.lastIndexOf('/'), 1).replace(containerName, "").substring(1);

                        this.service.StorageBlobSecureAccessSignaturesTree(containerName, blobName, relativeAddress)
                            .subscribe(result => {
                                var data = result;
                                if (data.length === 1) {
                                    this.externalsourcetype = "default";
                                    return;
                                }
                                else {


                                }

                                treeElement.onclick = this.changeDocumentViewer(data);

                                //Document Head
                                var documentHead = data[0];
                                var htmlHead = document.createElement("H4");

                                htmlHead.addEventListener("mouseover", function () {
                                    htmlHead.setAttribute("style", "text-decoration:underline;cursor:pointer;");
                                });
                                htmlHead.addEventListener("mouseout", function () {
                                    htmlHead.setAttribute("style", "text-decoration:none;cursor:default;");
                                });
                                htmlHead.addEventListener("click", function () {
                                    let listitems = document.getElementsByClassName("liclass");
                                    for (var i = 0; i < listitems.length; i++) {
                                        listitems[i].setAttribute("style", "text-decoration:none;cursor:default;");
                                    }
                                });
                                var documentHeadText = document.createTextNode(documentHead.Name);
                                htmlHead.appendChild(documentHeadText);
                                treeElement.appendChild(htmlHead);

                                //Horizontal Rule
                                //var rule = document.createElement("HR");
                                //treeElement.appendChild(rule);

                                //Create List
                                this.createListWithItem(data, treeElement, documentHead);
                            });
                    }
                    else {
                        this.externalsourcetype = "default";
                    }


                }
                break;
            default:
                {
                    this.externalsourcetype = "defaultdoc";
                    this.viewerhtmlcontent = this._sanitizer.bypassSecurityTrustHtml('');
                }
                break;
        }
    }

    changeDocumentViewer(data: any): any {
        return function (event: any): void {
            var srcElement = event.target;

            var srcTag = srcElement.tagName;
            var blobName = srcElement.textContent;

            if (srcTag == "DIV") {
                var item = data.filter(e => e.Name.slice(3) === blobName);
                this.sourceurl = "https://view.officeapps.live.com/op/embed.aspx?src=" + encodeURIComponent(item[0].Url) + "&amp;embedded=true";
                //this.viewerhtmlcontent = this._sanitizer.bypassSecurityTrustHtml('<iframe src="' + this.sourceurl + '" style="width: 100%; height: 80vh;"></iframe>');
                document.getElementById("viewercontainer").innerHTML = '<iframe id="frame" src="' + this.sourceurl + '" style="width: 100%; height: 70vh;"></iframe>';
                //document.getElementById("DownloadACopy-Menu").remove(); //remove download button for word
                //document.getElementById("ChromelessStatusBar.Options.DownloadACopy-Menu").remove(); //remove download button for powerpoint
            }
            else if (srcTag === "H4") {
                var item = data.filter(e => e.Name === blobName);
                this.sourceurl = "https://view.officeapps.live.com/op/embed.aspx?src=" + encodeURIComponent(item[0].Url) + "&amp;embedded=true";
                //this.viewerhtmlcontent = this._sanitizer.bypassSecurityTrustHtml('<iframe src="' + this.sourceurl + '" style="width: 100%; height: 80vh;"></iframe>');
                document.getElementById("viewercontainer").innerHTML = '<iframe id="frame" src="' + this.sourceurl + '&wdallowinteractivity=true&wddownloadbutton=false" style="width: 100%; height: 70vh;"></iframe>';
                //document.getElementById("DownloadACopy-Menu").remove(); //remove download button for word
                //document.getElementById("ChromelessStatusBar.Options.DownloadACopy-Menu").remove(); //remove download button for powerpoint
            }
        };
    }

    gettreeclasslisting(): string {
        if (this.externalsourcetype != 'microsoft') {
            return "hidden-md hidden-lg ";
        } else {
            return "col-md-3 col-lg-3 ";
        }
    }

    getclasslisting() {
        if (this.externalsourcetype == 'microsoft') {
            return "col-md-12 col-lg-9";
        }
        else {
            return "col-md-12 col-lg-12";
        }
    }

    createListWithItem(data: any, treeElement: any, item: any): void {
        //Validate List has Children
        var children = data.filter(e => e.Parent === item.Id);
        if (children.length === 0) return;

        //Create New List Object
        var ul = document.createElement("ul");
        ul.setAttribute("style", "list-style-type:none;padding-left:20px;")
        ul.setAttribute("id", "ul" + item.Id);

        //If No Parent, Append to Top Element.
        if (item.Parent === 0) {
            treeElement.appendChild(ul);
        }
        else {
            //Append New List Object to Current Item
            var treeItemLI = document.getElementById("li" + item.Id);
            ul.setAttribute("class", "collapse in");
            treeItemLI.appendChild(ul);
        }

        //Instantiate Parent Array
        const childrenThatAreParents = [];

        //Append New List Items to New List Object
        for (let child of children) {
            var li = document.createElement("li");
            li.setAttribute("id", "li" + child.Id);

            //var liText = document.createTextNode(child.Name.slice(3));

            var liText = document.createElement("div");
            liText.setAttribute("id", "div" + child.Id);
            liText.setAttribute("class", "liclass");
            liText.setAttribute("style", "display:inline-block;");
            liText.innerText = child.Name.slice(3);

            var liIcon = document.createElement("span");
            liIcon.setAttribute("class", "glyphicon glyphicon-chevron-down");
            liIcon.setAttribute("style", "display:inline-block;margin-left:-15px;padding-right:5px;padding-top:2px;");
            liIcon.setAttribute("id", "span" + child.Id);
            liIcon.addEventListener("click", function () {
                if (document.getElementById("span" + child.Id).getAttribute("class") == "glyphicon glyphicon-chevron-down") {
                    document.getElementById("span" + child.Id).setAttribute("class", "glyphicon glyphicon-chevron-right");
                }
                else {
                    document.getElementById("span" + child.Id).setAttribute("class", "glyphicon glyphicon-chevron-down");
                }
            });
            if (data.filter(e => e.Parent === child.Id).length > 0) {
                li.appendChild(liIcon);
            }
            li.appendChild(liText);
            document.getElementById("ul" + item.Id).appendChild(li);

            document.getElementById("div" + child.Id).addEventListener("mouseover", function () {
                if (document.getElementById("div" + child.Id).getAttribute("style") != "text-decoration:none;cursor:default;font-weight:bold;display:inline-block;" && document.getElementById("div" + child.Id).getAttribute("style") != "font-weight: bold; text-decoration: none; display: inline-block; cursor: default;") {
                    document.getElementById("div" + child.Id).setAttribute("style", "text-decoration:underline;cursor:pointer;display:inline-block;");
                }
            });
            document.getElementById("div" + child.Id).addEventListener("mouseout", function () {
                if (document.getElementById("div" + child.Id).getAttribute("style") != "text-decoration:none;cursor:default;font-weight:bold;display:inline-block;" && document.getElementById("div" + child.Id).getAttribute("style") != "font-weight: bold; text-decoration: none; display: inline-block; cursor: default;") {
                    document.getElementById("div" + child.Id).setAttribute("style", "text-decoration:none;cursor:default;display:inline-block;");
                }
            });
            document.getElementById("div" + child.Id).addEventListener("click", function () {
                let listitems = document.getElementsByClassName("liclass");
                for (var i = 0; i < listitems.length; i++) {
                    listitems[i].setAttribute("style", "text-decoration:none;cursor:default;display:inline-block;");
                }
                document.getElementById("div" + child.Id).setAttribute("style", "text-decoration:none;cursor:default;font-weight:bold;display:inline-block;");
            });

            //Find if Current Child Is A Parent
            if (data.filter(e => e.Parent === child.Id).length > 0) {
                //Add Child

                liIcon.setAttribute("data-toggle", "collapse");
                liIcon.setAttribute("data-target", "#ul" + child.Id);

                childrenThatAreParents.push(child);
            }
        }



        //For All New List Items Appended That Have Children, Recursively Call Method 
        for (let parent of childrenThatAreParents) {
            this.createListWithItem(data, treeElement, parent);
        }
    }


}
