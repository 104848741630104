import { Component, AfterViewInit, ViewChild, Input } from '@angular/core';

import { DxDataGridComponent } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import ContextMenu from 'devextreme/ui/context_menu';

import { TranslationService } from '../../../app/services/TranslationService';
import { UserDataGridService } from './userdatagrid.service';
import { UserDataGridInfo } from './UserDataGridInfo';
import DevExpress from 'devextreme/bundles/dx.all';

// Prevent TS from complaining about JQuery before its loaded.
declare var $: any;

@Component({
    selector: 'template-userdatagrid',
    templateUrl: './userdatagrid.component.html',
    styleUrls: ['./userdatagrid.component.css']
})
export class UserDataGridComponent implements AfterViewInit {

    // Supply a list of user IDs and a mode to initialize the
    // control with a subset of the tentant's users.
    //
    // NOTE: Currently we are not using ngOnChanges to refresh
    // when these are updated. If you change the bound value,
    // also call load() from the parent component to update.
    @Input() filterIds: string[] = [];
    @Input() filterMode: 'include' | 'exclude' = 'exclude';

    @Input() includeExternalUsers: boolean = false;

    // Show/hide the DevExtreme loading spinner with a
    // custom message.
    public showLoadingMessage(message: string): void {
        if (this.userGrid == null || this.userGrid.instance == null) return;
        this.userGrid.instance.beginCustomLoading(message);
    }
    public hideLoadingMessage(): void {
        if (this.userGrid == null || this.userGrid.instance == null) return;
        this.userGrid.instance.endCustomLoading();
    }

    // Get the users currently selected in the grid control.
    public getSelectedUsers(): UserDataGridInfo[] {
        return this.userGrid.instance.getSelectedRowsData();
    }

    // Get the IDs of the users currently selected in the grid control.
    public getSelectedUserIds(): string[] {
        let userIds: string[] = [];
        for (let user of this.getSelectedUsers()) {
            userIds.push(user.UserId);
        }
        return userIds;
    }

    // Reset the grid control.
    public reset(): void {
        this.scrollToTop();
        this.userGrid.instance.clearFilter();
        this.userGrid.instance.clearSelection();
        this.userGrid.instance.clearSorting();
        this.userGrid.instance.clearGrouping();
    }

    public load(): void {
        this.showLoadingMessage(this.translationService.getTranslationFileData('USERDATAGRID.LoadingUsers') + '...');
        this.userGridService.getUserDataGridInfo(false, this.includeExternalUsers).subscribe(data => {

            // Build a list of included users based on the filter
            // provided by filterIds and filterMode.
            let filteredUsers: UserDataGridInfo[] = [];
            if (this.filterIds == null || (this.filterIds.length == 0 && this.filterMode == 'exclude')) {
                filteredUsers = data;
            }
            else {
                data.forEach(user => {
                    let index: number = this.filterIds.findIndex(x => x.toUpperCase() == user.UserId.toUpperCase());
                    if ((this.filterMode == 'include' && index != -1)
                        || (this.filterMode == 'exclude' && index == -1)) {
                        filteredUsers.push(user);
                    }
                })
            }
            this.userGrid.dataSource = new DataSource(filteredUsers);
            this.hideLoadingMessage();

            // Update the statistics labels.
            this.totalUserCount = filteredUsers.length;
            this.includedUserCount = filteredUsers.length;
            this.selectedUserCount = 0;

            // Update the header filter dropdowns.
            this.buildEmailFilter(filteredUsers);
            this.buildDisplayNameFilter(filteredUsers);
        });

        // Load group name data
        this.userGridService.getUserGroupNames().subscribe(data => {
            this.groupFilter = [];
            for (let item in data) {
                this.groupFilter.push({
                    text: data[item],
                    value: ['GroupIdString', 'contains', item.toString()]
                });
            }
        });
    }

    /* 
     *
     * Private methods and implementation dependencies below...
     * 
     */

    @ViewChild(DxDataGridComponent, { static: false }) userGrid: DxDataGridComponent;

    constructor(
        private userGridService: UserDataGridService,
        private translationService: TranslationService) { }

    // Wait for the component to render so we can use
    // the DevExtreme loading spinner on the grid control
    ngAfterViewInit(): void {
        setTimeout(() => this.load(), 0);
    }

    // DevExtreme fields
    currentFilter: any;
    groupFilter: any;
    emailFilter: any;
    displayNameFilter: any;
    exportFileName: string = this.translationService.getTranslationFileData('USERDATAGRID.ExportFileName');
    displayNameOperations: any = ['contains', 'notcontains', 'startswith', 'endswith'];
    emailOperations: any = ['contains', 'notcontains', 'startswith', 'endswith'];
    groupOperations: any = ['contains', 'notcontains', 'startswith', 'endswith'];

    // Summary data (shown in center of filter row)
    totalUserCount: number = 0;
    includedUserCount: number = 0;
    selectedUserCount: number = 0;

    // Find distinct email domains and create
    // a filter list
    buildEmailFilter(data: any) {
        this.emailFilter = [];
        let includedDomains = [];
        for (let user in data) {
            let emailDomain = "@" + (data[user].EmailAddress as string).split('@')[1];
            if (includedDomains.indexOf(emailDomain) == -1) {
                includedDomains.push(emailDomain);
                this.emailFilter.push({
                    text: emailDomain,
                    value: ["EmailAddress", "endswith", emailDomain]
                });
            }
        }
        this.emailFilter.sort(function (a, b) {
            if ((a as any).text < (b as any).text) return -1;
            if ((a as any).text > (b as any).text) return 1;
            return 0;
        });
    }

    // Find distinct first letters of display
    // names and build a filter list
    buildDisplayNameFilter(data: any) {
        this.displayNameFilter = [];
        let includedFirstLetters = [];
        for (let user in data) {
            let firstLetter = (data[user].DisplayName as string).substring(0, 1);
            if (includedFirstLetters.indexOf(firstLetter) == -1) {
                includedFirstLetters.push(firstLetter);
                this.displayNameFilter.push({
                    text: firstLetter,
                    value: ["DisplayName", "startswith", firstLetter]
                });
            }
        }
        this.displayNameFilter.sort(function (a, b) {
            if ((a as any).text < (b as any).text) return -1;
            if ((a as any).text > (b as any).text) return 1;
            return 0;
        })
    }

    // Add custom buttons and selection statistics
    // to the filter row
    exportText = this.translationService.getTranslationFileData('USERDATAGRID.ExportAll');
    exportSelectedText = this.translationService.getTranslationFileData('USERDATAGRID.ExportSelected');
    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(
            {
                location: 'before',
                template: 'userCount',
                locateInMenu: 'auto'
            }, {
                location: 'center',
                widget: 'dxButton',
                locateInMenu: 'always',
                options: {
                    icon: 'doc',
                    text: this.translationService.getTranslationFileData("USERDATAGRID.ExportAll"),
                    hint: this.translationService.getTranslationFileData("USERDATAGRID.ExportAllTooltip"),
                    onClick: function () {
                        e.component.exportToExcel(false)
                    }
                }
            }, {
                location: 'center',
                widget: 'dxButton',
                locateInMenu: 'always',
                options: {
                    icon: 'exportselected',
                    text: this.translationService.getTranslationFileData("USERDATAGRID.ExportSelected"),
                    hint: this.translationService.getTranslationFileData("USERDATAGRID.ExportSelectedTooltip"),
                    onClick: function () {
                        e.component.exportToExcel(true)
                    }
                }
            }, {
                location: 'after',
                widget: 'dxButton',
                locateInMenu: 'auto',
                options: {
                    icon: 'filter',
                    text: this.translationService.getTranslationFileData("USERDATAGRID.ClearFilters"),
                    hint: this.translationService.getTranslationFileData("USERDATAGRID.ClearFiltersTooltip"),
                    onClick: this.clearFilters.bind(this)
                }
            }, {
                location: 'after',
                widget: 'dxButton',
                locateInMenu: 'always',
                options: {
                    icon: 'arrowup',
                    text: this.translationService.getTranslationFileData("USERDATAGRID.ScrollToTop"),
                    hint: this.translationService.getTranslationFileData("USERDATAGRID.ScrollToTopTooltip"),
                    onClick: this.scrollToTop.bind(this)
                }
            },
        );
    }
    
    // Remove search terms and applied filters
    clearFilters() {
        if (this.userGrid == null || this.userGrid.instance == null) return;
        this.userGrid.instance.clearFilter();
    }

    // Scroll the data grid to the first row
    scrollToTop() {
        if (this.userGrid == null || this.userGrid.instance == null) return;
        this.userGrid.instance.getScrollable().scrollTo(0);
    }

    // Update selection statistics when selection
    // changes
    selectionChangedHandler(e) {
        this.selectedUserCount = e.selectedRowKeys.length;
    }

    // When the grid is re-rendered, update the included user count.
    onContentReady(e) {
        this.includedUserCount = e.component.totalCount();
    }
}