import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { BrowserAuthenticationService } from './../BrowserAuthenticationService';
import { ProdGenApi } from './../apiService/prodgen.api';
import { AppComponent } from '../app.component';


declare var $: any;

@Component({
  selector: 'pinnacle-lite',
  templateUrl: './pinnaclelite.component.html',
  styleUrls: ['./pinnaclelite.component.css'],
  providers: [ProdGenApi],
  encapsulation: ViewEncapsulation.None,
})
export class PinnacleLiteComponent implements OnInit {
    isAuthenticated: boolean = false;
    features = new Array<PinnacleFeature>();
    content = new Array<PinnacleContent>();
        
  
    constructor(private pinnacleService: ProdGenApi, private router: Router, private authenticationService: BrowserAuthenticationService) {

        if (authenticationService.AuthenticatePage() == true) {
            this.isAuthenticated = true;
            this.features.push({ name:"Learning Paths", pinnacle:true, pinnacle_lite:true })
            this.features.push({ name: "Videos", pinnacle: true, pinnacle_lite: true })
            this.features.push({ name: "Search", pinnacle: true, pinnacle_lite: true })
            this.features.push({ name: "Translations", pinnacle: true, pinnacle_lite: true })
            this.features.push({ name: "Trending", pinnacle: true, pinnacle_lite: true })
            this.features.push({ name: "KnowledgeSmart Integration", pinnacle: true, pinnacle_lite: false})
            this.features.push({ name: "Reporting", pinnacle: true, pinnacle_lite: false})
            this.features.push({ name: "Cheat Sheets", pinnacle: true, pinnacle_lite: false})
            this.features.push({ name: "Workflows", pinnacle: true, pinnacle_lite: false})
            this.features.push({ name: "Courses Assigned by Managers", pinnacle: true, pinnacle_lite: false})
            this.features.push({ name: "Certificates", pinnacle: true, pinnacle_lite: false})
            this.features.push({ name: "Customization", pinnacle: true, pinnacle_lite: false })
            this.features.push({ name: "Work Groups", pinnacle: true, pinnacle_lite: false })
            this.features.push({ name: "Support", pinnacle: true, pinnacle_lite: false})
            this.features.push({ name: "Branding", pinnacle: true, pinnacle_lite: false })
            this.features.push({ name: "Autodesk Plugins", pinnacle: true, pinnacle_lite: false})
            this.features.push({ name: "Notifications", pinnacle: true, pinnacle_lite: false})
            this.features.push({ name: "Offline Content", pinnacle: true, pinnacle_lite: false})
            this.features.push({ name: "SSO/AD Sync", pinnacle: true, pinnacle_lite: false })

            let adobe = new PinnacleContent();
            adobe.publisher = "Adobe";
            adobe.products = new Array<string>();
            adobe.products.push("Illustrator");
            adobe.products.push("InDesign");
            adobe.products.push("Photoshop");

            let autodesk = new PinnacleContent();
            autodesk.publisher = "Autodesk";
            autodesk.products = new Array<string>();
            autodesk.products.push("3ds Max Design");
            autodesk.products.push("A360 Collaboration for Revit");
            autodesk.products.push("AutoCAD");
            autodesk.products.push("AutoCAD Mechanical");
            autodesk.products.push("AutoCAD Electrical");
            autodesk.products.push("AutoCAD P&ID");
            autodesk.products.push("AutoCAD Plant 3D");
            autodesk.products.push("BIM 360");
            autodesk.products.push("BIM 360 Docs");
            autodesk.products.push("BIM 360 Field");
            autodesk.products.push("BIM 360 Glue");
            autodesk.products.push("Civil 3D");
            autodesk.products.push("Dynamo");
            autodesk.products.push("Fusion 360");
            autodesk.products.push("Infraworks");
            autodesk.products.push("Inventor");
            autodesk.products.push("Inventor Cable & Harness");
            autodesk.products.push("Inventor Part Modeling");
            autodesk.products.push("Inventor Sheet Metal");
            autodesk.products.push("Inventor Tube & Pipe");
            autodesk.products.push("Map 3D");
            autodesk.products.push("Navisworks");
            autodesk.products.push("ReCap");
            autodesk.products.push("Revit");
            autodesk.products.push("Robot Structural Analysis");
            autodesk.products.push("Showcase");
            autodesk.products.push("Vehicle Tracking");

            let health = new PinnacleContent();
            health.publisher = "Health & Safety";
            health.products = new Array<string>();
            health.products.push("Workplace Safety");
            health.products.push("Construction Safety");
            health.products.push("DOT HazMat");
            health.products.push("Hazard Communication");
            health.products.push("Human Resources");
            health.products.push("HAZWOPER");

            let mcNeel = new PinnacleContent();
            mcNeel.publisher = "McNeel";
            mcNeel.products = new Array<string>();
            mcNeel.products.push("Grasshopper");
            mcNeel.products.push("Rhino");

            let microsoft = new PinnacleContent();
            microsoft.publisher = "Microsoft";
            microsoft.products = new Array<string>();
            microsoft.products.push("Access");
            microsoft.products.push("Delve");
            microsoft.products.push("Excel");
            microsoft.products.push("Office 365 Administration");
            microsoft.products.push("Office 365 Visio");
            microsoft.products.push("OneDrive");
            microsoft.products.push("OneNote");
            microsoft.products.push("Outlook");
            microsoft.products.push("Planner");
            microsoft.products.push("Power BI");
            microsoft.products.push("PowerPoint");
            microsoft.products.push("Project");
            microsoft.products.push("SharePoint");
            microsoft.products.push("Skype");
            microsoft.products.push("Teams");
            microsoft.products.push("Visio 2016");
            microsoft.products.push("Word");
            microsoft.products.push("Yammer");

            let additional = new PinnacleContent();
            additional.publisher = "Additional";
            additional.products = new Array<string>();
            additional.products.push("Bluebeam Revu");
            additional.products.push("Pinnacle Series");

            this.content.push(adobe);
            this.content.push(autodesk);
            this.content.push(health);
            this.content.push(mcNeel);
            this.content.push(microsoft);
            this.content.push(additional);
        }
    } 

    ngOnInit() {
      window.scroll(0, 0);
    }

    get isPinnacleLite() {
        return AppComponent.isPinnacleLite;
    }

    clickContentCatalog() {
        window.open("http://pinnacleseries.com/contentcatalog/#/content-category");
    }
}

export class PinnacleFeature {
    public name: string;
    public pinnacle_lite: boolean;
    public pinnacle: boolean;
}

export class PinnacleContent {
    public publisher: string;
    public products: Array<string>;
}
