import { Component, OnInit, Input, ChangeDetectorRef  } from '@angular/core';
import { Router } from '@angular/router';
import { Content, ContentType_T } from './../../apiService/classFiles/class.content';
import { ProdGenApi } from '../../apiService/prodgen.api';
import { EnrollmentFilter_T, Enrollment } from './../../apiService/classFiles/class.enrollments';

@Component({
  selector: 'template-frequent-category',
  templateUrl: './frequent-category.component.html',
  styleUrls: ['./frequent-category.component.css']
})
export class FrequentCategoryComponent implements OnInit {

  @Input('frequentItem') item: Content;
  @Input('themeRotation') themeRotation: number;
    @Input('source') sourceArea: string = "";
    @Input('activeEnrollments') activeEnrollmentsForUser: Array<Enrollment> = new Array<Enrollment>();
  
    contentType: string = "Cheat Sheet"

    

    titleHeight: number = 0;
    constructor(private router: Router, private cdr: ChangeDetectorRef, private pinnacleService: ProdGenApi) { 
  }

  ngOnInit() {
      // If there is no image and it is a workflow, just display the workflow name.
      if (!this.item.imageURL || this.item.imageURL.length == 0) {
          let img = ProdGenApi.getLargeDefaultImageFromType(this.item.contentType);
          if (img.indexOf("default") == -1) {
              this.item.imageURL = img;
          }
          else {
              this.item.imageURL = "../assets/images/" + img;
          }
      }

      if (this.item.contentType == ContentType_T.workflow) {
          this.contentType = "Workflow";
      }
      else if (this.item.contentType == ContentType_T.process) {
          this.contentType = "Process";
      }
      else if (this.item.contentType == ContentType_T.video ) {
          this.contentType = "Video";
      }
      else if (this.item.contentType == ContentType_T.cheatsheet) {
          this.contentType = "Cheat Sheet";
      }
      else if (this.item.contentType == ContentType_T.course) {
          this.contentType = "Course";
      }
      else if (this.item.contentType == ContentType_T.learningpath) {
          this.contentType = "Learning Path";
      }
      //console.log("here");
      //this.pinnacleService.getCurrentUserEnrollments([EnrollmentFilter_T.inProgress]).subscribe(res => {         
      //    this.activeEnrollmentsForUser = res;
      //});

  }

    ngAfterViewInit() {
        this.titleHeight = this.getTitleHeight(this.item.contentId);
        this.cdr.detectChanges();
    }
  
   getClassList(): string {

      if (this.sourceArea == "associatedLearning") {
          return this.associatedLearningClasses();
       }
        
      let classes: string = "col-md-4 col-sm-4 col-xs-4 col-lg-4 fu-card ";

      let themeNum: number = (this.themeRotation % 3);

      if (themeNum == 0) {
          themeNum = 1;
      }
      else if (themeNum == 1) {
          themeNum = 2;
      }
      else if (themeNum == 2) {
          themeNum = 3;
      }

       classes += "smallcardcolortheme" + themeNum;

       if (this.sourceArea == "associatedLearningOverlay") {
           classes += " associatedLearningOverlay"
       }
       
      return classes;
    }

    associatedLearningClasses(): string {
        let classes: string = "col-md-4 col-sm-4 col-xs-4 col-lg-4 fu-card associatedLearning";
        return classes;
    }


  onClickItem()
  {
      let url: string = this.getContentUrl(this.item);
      //url = "#/sea2rch?searchTerms=test";
      //let extras: NavigateExtras = new NavigateExtras();

	  this.router.navigateByUrl(url).then(i => {
		  if (i == false) {
			  console.log("unable to navigate");
		  }
	  });
  }

  getContentUrl(r: Content): string {
      let url: string = "";
      if (r.contentType == ContentType_T.workflow) {
          url += "workflowviewer?id=" + r.contentId + "&contentType=workflow"; 
      }
      else if (r.contentType == ContentType_T.process) {
          url += "workflowviewer?id=" + r.contentId + "&contentType=process";
      }
      else if (r.contentType == ContentType_T.cheatsheet) {
          url += "cheatsheetviewer?id=" + r.contentId;
      }
      else if (r.contentType == ContentType_T.video) {
          url += "videoviewer?id=" + r.contentId;
      }
      else if (r.contentType == ContentType_T.learningpath) {
          url += "learningcenter/series?learningPathId=" + r.contentId;
      }
      else if (r.contentType == ContentType_T.course) {
          //url += "learningcenter/series?courseId=" + r.contentId;

          if (this.activeEnrollmentsForUser != null) {
              if (this.activeEnrollmentsForUser.findIndex(course => course.courseId == r.contentId) == -1) {
                  url += "/learningcenter/series?courseId=" + r.contentId;
              }
              else {
                  url += "/learningcenter/courseviewer?enrollmentId=" + this.activeEnrollmentsForUser[this.activeEnrollmentsForUser.findIndex(course => course.courseId == r.contentId)].enrollmentId;
              }
          }
      }
      return url;
  }

  getContentImage(r: Content): string {
    let image: string = "";

    image = "assets/images/";

    if (r.bisCustom == false) {
        if (r.contentType == ContentType_T.cheatsheet && r.docURL != null && r.docURL != "") {
            if (r.docURL.includes(".pdf")) {
                image += "PDF Graphic.png"
            }
            else if (r.docURL.includes(".doc")) {
                image += "Word Graphic.png"
            }
            else if (r.docURL.includes(".xls")) {
                image += "Excel Graphic.png"
            }
            else if (r.docURL.includes(".ppt")) {
                image += "PowerPoint Graphic.png"
            }
            else if (r.docURL.includes(".htm")) {
                image += "HTML Graphic.png"
            }
        }
        else {
            image += ProdGenApi.getLargeDefaultImageFromType(r.contentType);
            if (image.indexOf("default") == -1) {
                image = image.replace("assets/images/", "");
            }
        }
    }
    else {

        try {
            image = localStorage.getItem(ProdGenApi.getCurrentTenantId + "_MyDocsImage");
        } catch (e) {

        }
        if (image == "" || image == null) {
            image = "assets/images/My Cheat Sheets Graphic.png";
        }
    }


    return image;
    }

    getTitleHeight(id:String): number {
        
        if (document.getElementById("titlefrequentcard_" + id)!=null) {
            return document.getElementById("titlefrequentcard_" + id).clientHeight;
        }
        return 1;
    }

    getEllipsisBGColor():string {
        let classes: string = "";        
        let themeNum: number = (this.themeRotation % 3);

        if (this.themeRotation < 3) {
            themeNum = ((this.themeRotation + 3) % 3);
        }
        
        themeNum += 1;

        classes += "smallcardcolortheme" + themeNum;
        return classes;
    }
}
