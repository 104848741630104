import { Component, AfterViewInit, ViewChild, Input } from '@angular/core';

import { DxDataGridComponent } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import ContextMenu from 'devextreme/ui/context_menu';

import { TranslationService } from '../../../app/services/TranslationService';
//import { UserDataGridService } from './userdatagrid.service';
//import { UserDataGridInfo } from './UserDataGridInfo';
import DevExpress from 'devextreme/bundles/dx.all';
import { User_Micro, User_Mini } from 'src/app/apiService/classFiles/v2-users';
import { UserDataGridInfo } from '../userdatagrid/UserDataGridInfo';
import { Group_Micro } from 'src/app/apiService/classFiles/v2-groups';

// Prevent TS from complaining about JQuery before its loaded.
declare var $: any;

@Component({
    selector: 'template-userselectiondatagrid',
    templateUrl: './userselectiondatagrid.component.html',
    styleUrls: ['./userselectiondatagrid.component.css']
})
export class UserSelectionDataGridComponent implements AfterViewInit {
    //Rachel - This is a rip off of the UserDataGridComponent
    //Basically doing the minimum to get this to take a list of Users (User_mini)
    //UserDataGridComponent would return all users with some removed




    // Supply a list of user IDs and a mode to initialize the
    // control with a subset of the tentant's users.
    //
    // NOTE: Currently we are not using ngOnChanges to refresh
    // when these are updated. If you change the bound value,
    // also call load() from the parent component to update.
    @Input('userSelectionList') userList: Array<User_Mini> = new Array<User_Mini>();
    filterIds: string[] = [];
    filterMode: 'include' | 'exclude' = 'exclude';

    userListDataGrid: Array<UserDataGridInfo> = new Array<UserDataGridInfo>();

    //@Input() includeExternalUsers: boolean = false;

    // Show/hide the DevExtreme loading spinner with a
    // custom message.
    public showLoadingMessage(message: string): void {
        if (this.userGrid == null || this.userGrid.instance == null) return;
        this.userGrid.instance.beginCustomLoading(message);
    }
    public hideLoadingMessage(): void {
        if (this.userGrid == null || this.userGrid.instance == null) return;
        this.userGrid.instance.endCustomLoading();
    }

    // Get the users currently selected in the grid control.
    public getSelectedUsers(): UserDataGridInfo[] {
        return this.userGrid.instance.getSelectedRowsData();
    }

    public getSelectedUsers_UserMini(): User_Mini[] {

        let temp = this.userGrid.instance.getSelectedRowsData();
        let userMiniList = new Array<User_Mini>();
        temp.forEach(userInfo => {

            let tempUser = this.userList.find(x => x.userId == userInfo.UserId);
            if (tempUser != undefined) {
                userMiniList.push(tempUser);
            }
        });
        return userMiniList;
    }

    

    // Get the IDs of the users currently selected in the grid control.
    public getSelectedUserIds(): string[] {
        let userIds: string[] = [];
        //for (let user of this.getSelectedUsers()) {
        //    userIds.push(user.UserId);
        //}
        return userIds;
    }

    // Reset the grid control.
    public reset(): void {
        this.scrollToTop();
        this.userGrid.instance.clearFilter();
        this.userGrid.instance.clearSelection();
        this.userGrid.instance.clearSorting();
        this.userGrid.instance.clearGrouping();
    }

    public load(): void {
        this.showLoadingMessage(this.translationService.getTranslationFileData('USERDATAGRID.LoadingUsers') + '...');
        //this.userGridService.getUserDataGridInfo(this.userList).subscribe(data => {
            let data = this.getUserDataGridInfo(this.userList);
            this.userListDataGrid = this.getUserDataGridInfo(this.userList);;
                
                    // Build a list of included users based on the filter
                    // provided by filterIds and filterMode.
                    let filteredUsers: UserDataGridInfo[] = [];


                    if (this.filterIds == null || (this.filterIds.length == 0 && this.filterMode == 'exclude')) {
                        filteredUsers = data;
                    }
                    else {
                        data.forEach(user => {
                            let index: number = this.filterIds.findIndex(x => x.toUpperCase() == user.UserId.toUpperCase());
                            if ((this.filterMode == 'include' && index != -1)
                                || (this.filterMode == 'exclude' && index == -1)) {
                                filteredUsers.push(user);
                            }
                        })
                    }
                    this.userGrid.dataSource = new DataSource(filteredUsers);
                    this.hideLoadingMessage();

                    // Update the statistics labels.
                    this.totalUserCount = filteredUsers.length;
                    this.includedUserCount = filteredUsers.length;
                    this.selectedUserCount = 0;

                    // Update the header filter dropdowns.
                    this.buildEmailFilter(filteredUsers);
                    this.buildDisplayNameFilter(filteredUsers);
    }

    public updateSelectedUsers(filterOutUsers: User_Mini[]) {
        this.showLoadingMessage(this.translationService.getTranslationFileData('USERDATAGRID.LoadingUsers') + '...');

        this.reset();

        let data = new Array<UserDataGridInfo>();

        data.push.apply(data, this.userListDataGrid);
        let filteredUsers: UserDataGridInfo[] = [];
        this.filterIds = [];
        filterOutUsers.forEach(user => {
            this.filterIds.push(user.userId);
        });

        if (this.filterIds == null || (this.filterIds.length == 0 && this.filterMode == 'exclude')) {
            filteredUsers = data;
        }
        else {
            data.forEach(user => {
                let index: number = this.filterIds.findIndex(x => x.toUpperCase() == user.UserId.toUpperCase());
                if ((this.filterMode == 'include' && index != -1)
                    || (this.filterMode == 'exclude' && index == -1)) {
                    filteredUsers.push(user);
                }
            })
        }
        this.userGrid.dataSource = new DataSource(filteredUsers);
        this.hideLoadingMessage();

        // Update the statistics labels.
        this.totalUserCount = filteredUsers.length;
        this.includedUserCount = filteredUsers.length;
        this.selectedUserCount = 0;

        // Update the header filter dropdowns.
        this.buildEmailFilter(filteredUsers);
        this.buildDisplayNameFilter(filteredUsers);

    }


    /* 
     *
     * Private methods and implementation dependencies below...
     * 
     */

    @ViewChild(DxDataGridComponent, { static: false }) userGrid: DxDataGridComponent;

    constructor(private translationService: TranslationService) { }

    // Wait for the component to render so we can use
    // the DevExtreme loading spinner on the grid control
    ngAfterViewInit(): void {
        setTimeout(() => this.load(), 0);
    }

    // DevExtreme fields
    currentFilter: any;
    groupFilter: any;
    emailFilter: any;
    displayNameFilter: any;
    exportFileName: string = this.translationService.getTranslationFileData('USERDATAGRID.ExportFileName');
    displayNameOperations: any = ['contains', 'notcontains', 'startswith', 'endswith'];
    emailOperations: any = ['contains', 'notcontains', 'startswith', 'endswith'];
    groupOperations: any = ['contains', 'notcontains', 'startswith', 'endswith'];

    // Summary data (shown in center of filter row)
    totalUserCount: number = 0;
    includedUserCount: number = 0;
    selectedUserCount: number = 0;

    // Find distinct email domains and create
    // a filter list
    buildEmailFilter(data: any) {
        this.emailFilter = [];
        let includedDomains = [];
        for (let user in data) {
            let emailDomain = "@" + (data[user].EmailAddress as string).split('@')[1];
            if (includedDomains.indexOf(emailDomain) == -1) {
                includedDomains.push(emailDomain);
                this.emailFilter.push({
                    text: emailDomain,
                    value: ["EmailAddress", "endswith", emailDomain]
                });
            }
        }
        this.emailFilter.sort(function (a, b) {
            if ((a as any).text < (b as any).text) return -1;
            if ((a as any).text > (b as any).text) return 1;
            return 0;
        });
    }

    // Find distinct first letters of display
    // names and build a filter list
    buildDisplayNameFilter(data: any) {
        this.displayNameFilter = [];
        let includedFirstLetters = [];
        for (let user in data) {
            let firstLetter = (data[user].DisplayName as string).substring(0, 1);
            if (includedFirstLetters.indexOf(firstLetter) == -1) {
                includedFirstLetters.push(firstLetter);
                this.displayNameFilter.push({
                    text: firstLetter,
                    value: ["DisplayName", "startswith", firstLetter]
                });
            }
        }
        this.displayNameFilter.sort(function (a, b) {
            if ((a as any).text < (b as any).text) return -1;
            if ((a as any).text > (b as any).text) return 1;
            return 0;
        })
    }

    // Add custom buttons and selection statistics
    // to the filter row
    exportText = this.translationService.getTranslationFileData('USERDATAGRID.ExportAll');
    exportSelectedText = this.translationService.getTranslationFileData('USERDATAGRID.ExportSelected');
    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(
            {
                location: 'before',
                template: 'userCount',
                locateInMenu: 'auto'
            }, {
                location: 'center',
                widget: 'dxButton',
                locateInMenu: 'always',
                options: {
                    icon: 'doc',
                    text: this.translationService.getTranslationFileData("USERDATAGRID.ExportAll"),
                    hint: this.translationService.getTranslationFileData("USERDATAGRID.ExportAllTooltip"),
                    onClick: function () {
                        e.component.exportToExcel(false)
                    }
                }
            }, {
                location: 'center',
                widget: 'dxButton',
                locateInMenu: 'always',
                options: {
                    icon: 'exportselected',
                    text: this.translationService.getTranslationFileData("USERDATAGRID.ExportSelected"),
                    hint: this.translationService.getTranslationFileData("USERDATAGRID.ExportSelectedTooltip"),
                    onClick: function () {
                        e.component.exportToExcel(true)
                    }
                }
            }, {
                location: 'after',
                widget: 'dxButton',
                locateInMenu: 'auto',
                options: {
                    icon: 'filter',
                    text: this.translationService.getTranslationFileData("USERDATAGRID.ClearFilters"),
                    hint: this.translationService.getTranslationFileData("USERDATAGRID.ClearFiltersTooltip"),
                    onClick: this.clearFilters.bind(this)
                }
            }, {
                location: 'after',
                widget: 'dxButton',
                locateInMenu: 'always',
                options: {
                    icon: 'arrowup',
                    text: this.translationService.getTranslationFileData("USERDATAGRID.ScrollToTop"),
                    hint: this.translationService.getTranslationFileData("USERDATAGRID.ScrollToTopTooltip"),
                    onClick: this.scrollToTop.bind(this)
                }
            },
        );
    }

    // Remove search terms and applied filters
    clearFilters() {
        if (this.userGrid == null || this.userGrid.instance == null) return;
        this.userGrid.instance.clearFilter();
    }

    // Scroll the data grid to the first row
    scrollToTop() {
        if (this.userGrid == null || this.userGrid.instance == null) return;
        this.userGrid.instance.getScrollable().scrollTo(0);
    }

    // Update selection statistics when selection
    // changes
    selectionChangedHandler(e) {
        this.selectedUserCount = e.selectedRowKeys.length;
    }

    // When the grid is re-rendered, update the included user count.
    onContentReady(e) {
        this.includedUserCount = e.component.totalCount();
    }

    public getUserDataGridInfo(userList: Array<User_Mini>): Array<UserDataGridInfo> {
        let userData = new Array<UserDataGridInfo>();

        userList.forEach(user => {
            let tmp = new UserDataGridInfo();
            tmp.UserId = user.userId;
            tmp.DisplayName = user.displayName;
            tmp.EmailAddress = user.email;

            tmp.GroupNameString = this.getGroupNameString(user.groups);
            tmp.GroupIdString = this.getGroupIdString(user.groups);

            userData.push(tmp);
        });

        return userData;
    }

    public getGroupNameString(groups: Array<Group_Micro>): string {

        let nameString = groups.map((x) => x.groupName).join(', ');
        return nameString;
    }

    public getGroupIdString(groups: Array<Group_Micro>): string {
        let idString = groups.map((x) => x.groupId).join(', ');
        return idString;
    }
}