import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { CheatSheet, Content, ContentAuthoring, ContentType_T, ContentUsedProduct, ContentUsedProductVersion, DescriptionFilter_T } from './../apiService/classFiles/class.content';
import { ProdGenApi } from './../apiService/prodgen.api';
import { BrowserAuthenticationService } from './../BrowserAuthenticationService';
import { TranslationService } from './../services/TranslationService';

declare var $: any;

@Component({
    selector: 'app-cheatsheetviewer',
    templateUrl: './cheatsheetviewer.component.html',
    styleUrls: ['./cheatsheetviewer.component.css'],
    providers: [ProdGenApi],
})
export class CheatsheetviewerComponent implements OnInit {
    contentId: string = "";
    cheatSheet: CheatSheet = new CheatSheet();
    csname: string = ""
    contentItem: Content = new Content();
    isAuthenticated: boolean = false;
    usedProducts: Array<string> = new Array<string>();
    productVersions: Array<ContentUsedProductVersion> = new Array<ContentUsedProductVersion>();
    products: Array<ContentUsedProduct> = new Array<ContentUsedProduct>();
    externalsourcetype: string = "default";
    sourceurl: string = "";
    relatedCheatSheets: Array<Content> = new Array<Content>();
    documentViewGoogle: string = "https://docs.google.com/viewer?url=";
    documentViewOffice: string = "https://view.officeapps.live.com/op/embed.aspx?src=";
    wWidth: number;

    //relatedreturned: boolean = false;
    tableofcontentsreturned: boolean = false;
    noErrs: boolean = true;
    friendlyMsg: SafeHtml;
    contentAuthoring: ContentAuthoring = new ContentAuthoring();

    constructor(private service: ProdGenApi,
        private route: ActivatedRoute,
        private _sanitizer: DomSanitizer,
        private authenticationService: BrowserAuthenticationService, private translationService: TranslationService) {

        if (this.authenticationService.AuthenticatePage()) {
            this.isAuthenticated = true;
            this.contentItem.contentType = ContentType_T.cheatsheet;
            this.cheatSheet.description = "Loading...";
            //this.relatedreturned = false;
            this.tableofcontentsreturned = false;
            
            this.route.queryParams.subscribe(queryParams => { 
                window.scroll(0,0);
                this.contentId = queryParams['id'];
            //    this.relatedreturned = false;
            //    this.service.GetContentRelatedItems(this.contentId, "cheatsheet", -1, 0, DescriptionFilter_T.unformatted).subscribe(rel => {
            //        for (let c of rel) {
            //            if (this.relatedCheatSheets.length < 4) {
            //                this.relatedCheatSheets.push(c);
            //            }
            //        }
            //        this.relatedreturned = true;
            //        this.checkSpinnerStatus();
            //    });

                this.service.GetCheatSheet(this.contentId, DescriptionFilter_T.formatted).subscribe(c => {
                    if (!$("#spinnerModal").hasClass("in")) {
                        this.showSpinner();
                    }
                    var treeElement = document.querySelector(".treePanel") as HTMLElement;
                    treeElement.innerHTML = "";
                    this.usedProducts = new Array<string>();
                    this.cheatSheet = c;
                    this.csname = this.cheatSheet.name.toString();
                    this.tableofcontentsreturned = false;
                    if (this.cheatSheet.useExternalDoc) {
                        let documentUrlAndSasToken = this.cheatSheet.externalDocURL.split("?");
                        let documentExtension = documentUrlAndSasToken[0].split(".").pop();

                        switch (documentExtension) {
                            case "pdf":
                                {

                                    this.externalsourcetype = documentExtension;
                                    //this.sourceurl = this.documentViewGoogle +
                                    //    encodeURIComponent(this.cheatSheet.externalDocURL.toString()) +
                                    //    "&amp;embedded=true";

                                    this.sourceurl = this.cheatSheet.externalDocURL.toString() ;

                                    document.getElementById("viewercontainer").innerHTML =
                                        '<iframe src="' +
                                        this.sourceurl +
                                        '" style="width: 100%; height: 80vh;"></iframe>';

                                    this.tableofcontentsreturned = true;
                                    this.checkSpinnerStatus();
                                }
                                break;
                            case "html":
                            case "htm":
                                {
                                    this.externalsourcetype = "html";
                                    document.getElementById("viewercontainer").innerHTML =
                                        '<iframe src="' +
                                        this.cheatSheet.externalDocURL.toString() +
                                        '"  style="width: 100%; height:70vh;" marginwidth = "0" marginheight = "0" frameborder = "0" vspace = "0" hspace = "0" >';

                                    this.tableofcontentsreturned = true;
                                    this.checkSpinnerStatus();
                                }
                                break;
                            case "doc":
                            case "docx":
                            case "dotx":
                            case "xlsx":
                            case "xlsb":
                            case "xls":
                            case "xlsm":
                            case "ppt":
                            case "pptx":
                            case "ppsx":
                            case "pps":
                            case "potx":
                            case "ppsm":
                                {   
                                    this.externalsourcetype = "microsoft";
                                    this.sourceurl = this.documentViewOffice + encodeURIComponent(this.cheatSheet.externalDocURL.toString()) + "&embedded=true";
                                    document.getElementById("viewercontainer").innerHTML = '<iframe id="frame" src="' + this.sourceurl + '&wdallowinteractivity=true&wddownloadbutton=true" style="width: 100%; height: 80vh;"></iframe>';

                                    if (documentExtension == "docx" || documentExtension == "doc" || documentExtension == "dotx") {

                                        //let url = new URL(documentUrlAndSasToken[0]);
                                        let url = documentUrlAndSasToken[0];
                                        url = url.replace(/^.+\.net/, '')

                                        var containerName = url.split("/")[1];
                                        var blobName = url.substring(url.indexOf('/', 1)).substring(1);
                                        var relativeAddress = url.substring(url.lastIndexOf('/'), 1).replace(containerName, "").substring(1);

                                        this.service.StorageBlobSecureAccessSignaturesTree(containerName, blobName, relativeAddress)
                                            .subscribe({
                                                next: result => {
                                                    var data = result;
                                                    if (data.length <= 1) {
                                                        this.externalsourcetype = "default";
                                                        this.tableofcontentsreturned = true;
                                                        this.checkSpinnerStatus();
                                                        return;
                                                    }
                                                    else {


                                                    }

                                                    treeElement.onclick = this.changeDocumentViewer(data);

                                                    //Document Head
                                                    var documentHead = data[0];
                                                    var htmlHead = document.createElement("div");
                                                    htmlHead.setAttribute("id", "tocHeader");
                                                    htmlHead.setAttribute("style", "text-decoration:none;cursor:default;display:inline-block;font-size:11pt;font-weight:bold");

                                                    htmlHead.addEventListener("mouseover", function () {
                                                        if (document.getElementById("tocHeader").getAttribute("style").includes("font-weight:bold") == false && document.getElementById("tocHeader").getAttribute("style").includes("font-weight: bold") == false) {
                                                            document.getElementById("tocHeader").setAttribute("style", "text-decoration:underline;cursor:pointer;display:inline-block;font-size:11pt;");
                                                        }
                                                    });
                                                    htmlHead.addEventListener("mouseout", function () {
                                                        if (document.getElementById("tocHeader").getAttribute("style").includes("font-weight:bold") == false && document.getElementById("tocHeader").getAttribute("style").includes("font-weight: bold") == false) {
                                                            document.getElementById("tocHeader").setAttribute("style", "text-decoration:none;cursor:default;display:inline-block;font-size:11pt;");
                                                        }
                                                    });
                                                    htmlHead.addEventListener("click", function () {
                                                        let listitems = document.getElementsByClassName("liclass");
                                                        for (var i = 0; i < listitems.length; i++) {
                                                            listitems[i].setAttribute("style", "text-decoration:none;cursor:default;display:inline-block;font-size:10pt;");
                                                        }
                                                        document.getElementById("tocHeader").setAttribute("style", "text-decoration:none;cursor:default;font-weight:bold;display:inline-block;font-size:11pt;");
                                                    });
                                                    var documentHeadText = document.createTextNode(documentHead.Name);
                                                    htmlHead.appendChild(documentHeadText);
                                                    treeElement.appendChild(htmlHead);

                                                    //Horizontal Rule
                                                    //var rule = document.createElement("HR");
                                                    //treeElement.appendChild(rule);

                                                    //Create List
                                                    this.createListWithItem(data, treeElement, documentHead);
                                                    this.tableofcontentsreturned = true;
                                                    this.checkSpinnerStatus();
                                                },
                                                error: (err) => {
                                                    this.hideSpinner();
                                                }
                                            });
                                    }
                                    else {                                        
                                        this.externalsourcetype = "default";
                                        this.tableofcontentsreturned = true;
                                        this.checkSpinnerStatus();
                                    }
                                }
                                break;
                            default:
                                {
                                    this.externalsourcetype = "defaultdoc";
                                    document.getElementById("viewercontainer").innerHTML = '';
                                    this.tableofcontentsreturned = true;
                                    this.checkSpinnerStatus();
                                }
                                break;
                        }

                    } else {
                        this.externalsourcetype = "defaultdoc"
                        document.getElementById("viewercontainer").innerHTML = '';
                        this.tableofcontentsreturned = true;
                        this.checkSpinnerStatus();

                    }
                   
                    this.service.GetContentUsedProducts(c.cheatId, ContentType_T.cheatsheet.toString()).subscribe(
                        products => {
                            for (var i = 0; i < products.length; i++) {
                                this.products = products;
                                this.productVersions = this.products[i].Versions;

                                if (this.productVersions.length > 0) {
                                    for (var j = 0; j < this.productVersions.length; j++) {
                                        this.usedProducts.push(this.products[i].Name +
                                            " " +
                                            this.productVersions[j].Name);
                                    }
                                } else {
                                    this.usedProducts.push(this.products[i].Name.toString());
                                }
                            }
                        });             
                    
                    this.contentItem.contentId = c.cheatId;
                    this.contentItem.contentType = ContentType_T.cheatsheet;
                    this.contentItem.description = c.description;

                    this.getAuthoringContent(this.contentItem);

                    if (c.externalDocURL != null && c.externalDocURL != "") {
                        this.contentItem.docURL = c.externalDocURL;
                    }
                    else {
                        this.contentItem.imageURL = "../assets/images/cheat_sheets.svg";
                    }


                    this.contentItem.name = c.name;
                    this.contentItem.providedBy = c.providedBy;
                    this.contentItem.bisCustom = c.bisCustom;
                    this.contentItem.bisDownloadable = c.bisDownloadable;

                    // add this to user history
                    this.service.addUserHistory(this.contentItem.contentId as string, this.contentItem.contentType)
                        .subscribe();
                },
                    err => {
                        //$("#spinnerModal").modal("hide");
                        //document.getElementById("desc").innerHTML = "You do not have access to this asset.";

                        //display friendly message to user so they are aware of what happened & have no access if they shouldn't
                        this.noErrs = false;
                        this.contentItem.name = "Error Loading Document";

                        if (err.status == 400) {
                            //bad request caused by 0 data rows and/or no content ids for user - user has no access
                            this.friendlyMsg = this._sanitizer.bypassSecurityTrustHtml(this.translationService.getTranslationFileData("APP.AssetViewing0DRs"));
                        }
                        else {
                            //for now, let's give generic error as there are multiple errors possibly thrown by services (for which I added most common to translate file in case a specific one is wanted)
                            this.friendlyMsg = this._sanitizer.bypassSecurityTrustHtml(this.translationService.getTranslationFileData("APP.AssetViewingError"));
                        }

                    });
            });
            
        }
    }

    ngOnInit() {
        window.scroll(0, 0);
    }

    getActiveDesc(): SafeHtml {
        return this._sanitizer.bypassSecurityTrustHtml(this.cheatSheet.description as string);
    }

    updateCheat(event: any) {
        this.cheatSheet = event;
        this.contentItem.contentId = event.cheatId;
        this.contentItem.contentType = ContentType_T.cheatsheet;
        this.contentItem.description = event.description;
        this.contentItem.imageURL = "../assets/images/cheat_sheets.svg";
        this.contentItem.name = event.name;
        this.contentItem.providedBy = event.providedBy;
        this.contentItem.bisCustom = event.bisCustom;
    }

    getclasslisting(): string {
        if (this.externalsourcetype != 'microsoft' ) {
            return "col-md-9 col-lg-9";
        } else {
            return "col-md-7 col-lg-7 ";
        }
    }

    gettreeclasslisting(): string {
        if (this.externalsourcetype != 'microsoft' ) {
            return "hidden-md hidden-lg ";
        } else {
            return "col-md-3 col-lg-3 ";
        }
    }
    getrelatedclasslisting(): string {
        if (this.externalsourcetype != 'microsoft' ) {
            return "col-md-3 col-lg-3 ";
        } else {
            return "col-md-2 col-lg-2 ";
        }
    }

    getcommentrelatedclass(): string {
        if (this.externalsourcetype != 'microsoft' ) {
            return "col-md-9 col-lg-7 col-lg-offset-1";
        } else {
            return "col-md-7 col-lg-7 col-lg-offset-3 col-md-offset-4";
        }
    }

    changeDocumentViewer(data: any): any {
        return function (event: any): void {
            var srcElement = event.target;

            var srcTag = srcElement.tagName;
            var blobName = srcElement.textContent;

            if (srcTag == "DIV") {
                if (srcElement.id == "tocHeader") {
                    var item = data.filter(e => e.Name === blobName);
                    var src = "https://view.officeapps.live.com/op/embed.aspx?src=" + encodeURIComponent(item[0].Url) + "&amp;embedded=true";
                    document.getElementById("viewercontainer").innerHTML = '<iframe id="frame" src="' + src + '" style="width: 100%; height: 80vh;"></iframe>';
                }
                else {
                    var item = data.filter(e => e.Name.slice(3) === blobName);
                    var src = "https://view.officeapps.live.com/op/embed.aspx?src=" + encodeURIComponent(item[0].Url) + "&amp;embedded=true";
                    document.getElementById("viewercontainer").innerHTML = '<iframe id="frame" src="' + src + '" style="width: 100%; height: 80vh;"></iframe>';
                }
            }
            else if (srcTag === "H4") {
                var item = data.filter(e => e.Name === blobName);
                var src = "https://view.officeapps.live.com/op/embed.aspx?src=" + encodeURIComponent(item[0].Url) + "&amp;embedded=true";
                document.getElementById("viewercontainer").innerHTML = '<iframe id="frame" src="' + src + '" style="width: 100%; height: 80vh;"></iframe>';
            }
        };
    }

    createListWithItem(data: any, treeElement: any, item: any): void {
        //Validate List has Children
        var children = data.filter(e => e.Parent === item.Id);
        if (children.length === 0) return;

        //Create New List Object
        var ul = document.createElement("ul");
        ul.setAttribute("style", "list-style-type:none;padding-left:20px;")
        ul.setAttribute("id", "ul" + item.Id);

        //If No Parent, Append to Top Element.
        if (item.Parent === 0) {
            treeElement.appendChild(ul);
        }
        else {
            //Append New List Object to Current Item
            var treeItemLI = document.getElementById("li" + item.Id);
            ul.setAttribute("class", "collapse in");
            treeItemLI.appendChild(ul);
        }

        //Instantiate Parent Array
        const childrenThatAreParents = [];

        //Append New List Items to New List Object
        for (let child of children) {
            var li = document.createElement("li");
            li.setAttribute("id", "li" + child.Id);

            //var liText = document.createTextNode(child.Name.slice(3));

            var liText = document.createElement("div");
            liText.setAttribute("id", "div" + child.Id);
            liText.setAttribute("class", "liclass");
            liText.setAttribute("style", "display:inline-block;font-size:10pt;");
            liText.innerText = child.Name.slice(3);

            var liIcon = document.createElement("span");
            liIcon.setAttribute("class", "glyphicon glyphicon-chevron-down");
            liIcon.setAttribute("style", "display:inline-block;margin-left:-15px;padding-right:5px;padding-top:2px;text-decoration:none;color:dimgray;transform:scale(0.75);-ms-transform:scale(0.75);");
            liIcon.setAttribute("id","span"+ child.Id);
            liIcon.addEventListener("click", function () {
                if (document.getElementById("span" + child.Id).getAttribute("class") == "glyphicon glyphicon-chevron-down") { //text-decoration:none;color:dimgray;transform:scale(0.75);-ms-transform:scale(0.75);
                    document.getElementById("span" + child.Id).setAttribute("class", "glyphicon glyphicon-chevron-right");
                    document.getElementById("span" + child.Id).setAttribute("style", "display:inline-block;margin-left:-15px;padding-right:5px;padding-top:2px;text-decoration:none;color:dimgray;transform:scale(0.75);-ms-transform:scale(0.75);");
                }
                else {
                    document.getElementById("span" + child.Id).setAttribute("class", "glyphicon glyphicon-chevron-down");
                    document.getElementById("span" + child.Id).setAttribute("style", "display:inline-block;margin-left:-15px;padding-right:5px;padding-top:2px;text-decoration:none;color:dimgray;transform:scale(0.75);-ms-transform:scale(0.75);");

                }
            });
            if (data.filter(e => e.Parent === child.Id).length > 0) {
                li.appendChild(liIcon);
            }
            li.appendChild(liText);
            document.getElementById("ul" + item.Id).appendChild(li);

            document.getElementById("div" + child.Id).addEventListener("mouseover", function () {
                if (document.getElementById("div" + child.Id).getAttribute("style") != "text-decoration:none;cursor:default;font-weight:bold;display:inline-block;font-size:10pt;" && document.getElementById("div" + child.Id).getAttribute("style") != "font-size: 10pt; font-weight: bold; text-decoration: none; display: inline-block; cursor: default;") {
                    document.getElementById("div" + child.Id).setAttribute("style", "text-decoration:underline;cursor:pointer;display:inline-block;font-size:10pt;");
                }
            });
            document.getElementById("div" + child.Id).addEventListener("mouseout", function () {
                if (document.getElementById("div" + child.Id).getAttribute("style") != "text-decoration:none;cursor:default;font-weight:bold;display:inline-block;font-size:10pt;" && document.getElementById("div" + child.Id).getAttribute("style") != "font-size: 10pt; font-weight: bold; text-decoration: none; display: inline-block; cursor: default;") {
                    document.getElementById("div" + child.Id).setAttribute("style", "text-decoration:none;cursor:default;display:inline-block;font-size:10pt;");
                }
            });
            document.getElementById("div" + child.Id).addEventListener("click", function () {
                let listitems = document.getElementsByClassName("liclass");
                for (var i = 0; i < listitems.length; i++) {
                    listitems[i].setAttribute("style", "text-decoration:none;cursor:default;display:inline-block;font-size:10pt;");
                }
                document.getElementById("tocHeader").setAttribute("style", "text-decoration:none;cursor:default;display:inline-block;font-size:11pt;");
                document.getElementById("div" + child.Id).setAttribute("style", "text-decoration:none;cursor:default;font-weight:bold;display:inline-block;font-size:10pt;");
            });

            //Find if Current Child Is A Parent
            if (data.filter(e => e.Parent === child.Id).length > 0) {
                //Add Child

                liIcon.setAttribute("data-toggle", "collapse");
                liIcon.setAttribute("data-target", "#ul"+child.Id);

                childrenThatAreParents.push(child);
            }
        }



        //For All New List Items Appended That Have Children, Recursively Call Method 
        for (let parent of childrenThatAreParents) {
            this.createListWithItem(data, treeElement, parent);
        }
    }

    sethoverstyle(li:HTMLLIElement):EventListenerObject {
        li.setAttribute("style", "text-decoration:underline;cursor:pointer;font-size:10pt;");
        return;
    }
    setblurstyle(li: HTMLLIElement): EventListenerObject {
        li.setAttribute("style", "text-decoration:none;cursor:default;font-size:10pt;");
        return;
    }

    checkSpinnerStatus() {
        if (this.tableofcontentsreturned == true ) { 
            //hide modal
            this.hideSpinner();

        }
    }

    showSpinner() {
        $("#spinnerModal").modal("show");
    }

    hideSpinner() {
        $("#spinnerModal").modal("hide");
    }

    onResize(event) {
        
    }
    

    getAuthoringContent(c: Content) {
        if (c.contentId != null) {
            this.service.getContentAuthoringData(c.contentId, c.contentType.toString()).subscribe(d => {
                if (d != null && d.lastModBy != "") {
                    this.contentAuthoring.contentId = d.contentId;//c.contentId.toString();
                    this.contentAuthoring.lastModBy = d.lastModBy;
                    this.contentAuthoring.lastModDate = d.lastModDate;
                    this.contentAuthoring.lastModDateStr = d.lastModDateStr;
                }
            });
        }
        else {
            this.contentAuthoring = new ContentAuthoring();
        }
    }
}
